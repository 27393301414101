import React, { useEffect } from 'react'
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { easeOut, motion } from "framer-motion";

// import utilityimage from '../assets/svgs/utilityimage.png';
// import commerceimage from '../assets/svgs/commerceimage.svg';
// import howitworkimagetwo from '../assets/images/howitworkimagetwo.svg';

import utilityicons from "../assets/svgs/utilityicons.svg";
import commerceicon from "../assets/svgs/commerceicon.svg";


function OtherServices() {

    const location = useLocation();

    useEffect(() => {
        const otherservice = document.getElementById('otherservice');

        if (otherservice && location.state?.scrollToSection) {
            otherservice.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location.state?.scrollToSection]);


    return (
        <Container fluid id="otherservice" className="otherservice">
            <Row>
                <center>
                    <h2 className="headertext" style={{ color: '#F14810' }}><b>Other Adept Services</b></h2>
                    <p className="whyadeptparagraph">Adept offers specialized services like Adept for Utilities (A4U) and Adept for Commerce (A4C) to streamline bill payments, manage transactions, and enhance payment efficiency.
                    </p>
                </center>
            </Row>

            <Row className="servicesrow">
                <Col xs={12} md={6} lg={6}>
                    <motion.div
                        initial={{ opacity: 0, y: 40 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, delay: 0.6, timingFunction: easeOut }}
                        viewport={{ once: true }}
                    >
                        <div className="howitworkcardbody">

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 10,
                                    alignContent: "center"
                                }}>

                                <Image src={utilityicons} fluid />

                                <h5 style={{ marginTop: 0.8 + "rem" }}>Adept for Utilities (A4U)</h5>

                            </div>

                            <div style={{ marginTop: 1.2 + "rem" }}>
                                <p
                                    className="howitworkeachparagraph"
                                    style={{
                                        fontFamily: "Jost, sans-serif",
                                        fontSize: 0.83 + "rem"
                                    }}
                                >
                                    A4U streamlines bill payments by assigning unique NUBANs to customers, ensuring
                                    instant verification and automated receipts. The platform provides a wholistic
                                    solution with various access channels, customized reporting, reconciliation,
                                    and payment reminders to prevent service disruptions.
                                </p>
                                <Link to="/#">
                                    <span style={{ color: '#F14810', fontSize: 0.8 + 'rem' }}>Learn More</span>
                                </Link>
                            </div>
                        </div>
                    </motion.div>
                </Col>
                <Col xs={12} md={6} lg={6}>
                    <motion.div
                        initial={{ opacity: 0, y: 40 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, delay: 0.8 + 0.2, timingFunction: easeOut }}
                        viewport={{ once: true }}
                    >
                        <div className="howitworkcardbody">

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 10,
                                    alignContent: "center"
                                }}>

                                <Image src={commerceicon} fluid />

                                <h5 style={{ marginTop: 0.8 + "rem" }}>Adept for Commerce (A4C)</h5>

                            </div>

                            <div style={{ marginTop: 1.2 + "rem" }}>
                                <p
                                    className="howitworkeachparagraph"
                                    style={{
                                        fontFamily: "Jost, sans-serif",
                                        fontSize: 0.83 + "rem"
                                    }}
                                >
                                    A4C provides organizations with an interface to create NUBAN Account numbers
                                    for clients and distributors, simplifying recurring payments. It tracks
                                    transactions, payment management, automated reminders and allows customizable
                                    payment structures, enhancing payment efficiency for various clients.
                                </p>
                                <Link to="/#">
                                    <span style={{ color: '#F14810', fontSize: 0.8 + 'rem' }}>Learn More</span>
                                </Link>
                            </div>
                        </div>
                    </motion.div>
                </Col>

            </Row>


            {/* <Row className="processsection">
                <Col xs={12} md={6} lg={6} style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <div className="processsectioninformation">
                        <div style={{ display: 'flex', alignItems: 'center' }}>

                            <h3 style={{ color: '#f14810' }}>
                                Adept for Utilities (A4U)</strong>
                            </h3>

                        </div>
                        <p className="processsectionparagraph" >
                            A4U enables Utility providers to assign unique NUBAN to customers for bill payments, with instant verification and automated receipts. It offers a comprehensive solution, including multiple access channels, tailored reporting, reconciliation and even reminders to prevent service interruptions.
                        </p>

                        <h5 style={{ color: '#F14810' }}><strong>Benefits</strong></h5>

                        <ul style={{ fontSize: 0.85 + 'rem', lineHeight: 205 + '%', marginLeft: -1 + 'rem' }} className="Featurelist">
                            <li>Convenience and Accessibility.</li>
                            <li>Transaction security.</li>
                            <li>Instant receipt.</li>
                            <li>Instant verification.</li>
                            <li>Instant verification.</li>
                        </ul>

                        <span style={{ color: '#F14810', fontSize: 0.9 + 'rem' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '10px',
                                }}
                            >
                                Get Started
                                <svg
                                    style={{ marginTop: 0.4 + "rem" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="12"
                                    viewBox="0 0 18 13"
                                    fill="none"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M17.757 6.5L11.9043 0.645996L10.9524 1.59657L15.1836 5.82774L-8.3421e-06 5.82774V7.17226L15.1836 7.17226L10.9524 11.4021L11.9043 12.354L17.757 6.5Z"
                                        fill="#F14810"
                                    />
                                </svg>
                            </div>
                        </span>

                    </div>

                </Col>
                <Col xs={12} md={6} lg={6}>
                    <Image src={utilityimage} fluid style={{marginTop: 5 + 'rem'}}/>
                </Col>

            </Row> */}

            {/* <Row className="processsection">
                <Col className="order-sm-2" xs={12} md={6} lg={6} style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <div className="processsectioninformation">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <h3 style={{ color: '#f14810' }}>
                                <strong>Adept for Commerce (A4C)</strong>
                            </h3>
                        </div>
                        <p className="processsectionparagraph" >
                            A4C provides organizations with an interface to generate NUBAN Account numbers to be
                            paired with their clients and distributors, which canbe used to settle all recurring
                            payments. The interface helps keeps record of every transaction, manages each client’s
                            payment, sends automated reminders to clients before and after due date. It also allows
                            the administrators create, allocate, and charge custom payment structure for different
                            clients.
                        </p>

                        <h5 style={{ color: '#F14810' }}><strong>Benefits</strong></h5>

                        <ul style={{ fontSize: 0.85 + 'rem', lineHeight: 205 + '%', marginLeft: -1 + 'rem' }} className="Featurelist">
                            <li>Deepens Collection through the use of all financial channels for collections.</li>
                            <li>24/7 customer support.</li>
                            <li>Automated Reporting.</li>
                            <li>Reminders and Notification.</li>
                            <li>Payment splitting.</li>
                        </ul>

                        <span style={{ color: '#F14810', fontSize: 0.9 + 'rem' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '10px',
                                }}
                            >
                                Get Started
                                <svg
                                    style={{ marginTop: 0.4 + "rem" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="12"
                                    viewBox="0 0 18 13"
                                    fill="none"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M17.757 6.5L11.9043 0.645996L10.9524 1.59657L15.1836 5.82774L-8.3421e-06 5.82774V7.17226L15.1836 7.17226L10.9524 11.4021L11.9043 12.354L17.757 6.5Z"
                                        fill="#F14810"
                                    />
                                </svg>
                            </div>
                        </span>

                    </div>

                </Col>
                <Col className="order-sm-1" xs={12} md={6} lg={6} >
                    <Image src={commerceimage} fluid style={{ marginTop: 5 + 'rem' }} />
                </Col>
            </Row> */}

        </Container>
    )
}

export default OtherServices