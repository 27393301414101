import React from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Image,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { Link45deg, EnvelopeAt } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";

import ToastComponent from "../../../../../../component/toast/toast.js";
import dropdownIcon from "../../../../../../assets/svgs/dropdownIcon.svg";

const AboutTheSchool = ({ setActiveStage }) => {

  const [isFormNotFilled, setIsFormNotFilled] = useState(false);

  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [selectedStateId, setSelectedStateId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    // console.log(e);
    // const { name, value } = e.target;
    // setRegistrationData({ ...registrationData, [name]: value });

    // if (name === "country") {
    //   setSelectedCountryId(e.target.value);
    // }
  };

  const handleGoToNextPage = () => {

    // if (
    //     !institutionBranding.brandColour ||
    //     !institutionBranding.logo
    // ) {
    //     setIsFormNotFilled(true);

    //     setTimeout(() => {
    //         setIsFormNotFilled(false);
    //     }, 3000);

    //     return;
    // }

    // setActiveStage("Contact Person");
    setActiveStage("school branding");
    // dispatch and store so we can get these details even when user comes back to the page
    // dispatch(storeBrandingDetails(setupInstitutionInfo));
    // dispatch(storeBrandingDetails({
    //     // institutionBranding: {...institutionBranding, logo: chosenImage}
    //     institutionBranding: { brandColour: chosenColor, logo: chosenImage }
    // }));
  };


  return (
    // <form>
    //   <div
    //     style={{
    //       display: "flex",
    //       gap: 0.8 + "rem",
    //       alignItems: "center",
    //       margin: "0 0 20px",
    //     }}
    //   >
    //     <div>
    //       <svg
    //         width="68"
    //         height="69"
    //         viewBox="0 0 68 69"
    //         fill="none"
    //         xmlns="http://www.w3.org/2000/svg"
    //       >
    //         <path
    //           d="M67.5 34.5C67.5 53.1396 52.3896 68.25 33.75 68.25C15.1104 68.25 0 53.1396 0 34.5C0 15.8604 15.1104 0.75 33.75 0.75C52.3896 0.75 67.5 15.8604 67.5 34.5ZM8.4375 34.5C8.4375 48.4797 19.7703 59.8125 33.75 59.8125C47.7297 59.8125 59.0625 48.4797 59.0625 34.5C59.0625 20.5203 47.7297 9.1875 33.75 9.1875C19.7703 9.1875 8.4375 20.5203 8.4375 34.5Z"
    //           fill="#E7EAEE"
    //           fill-opacity="0.8"
    //         />
    //         <path
    //           d="M63.2812 34.5C65.6112 34.5 67.5272 36.396 67.2367 38.7078C66.8614 41.6941 66.0876 44.6232 64.9309 47.4156C63.2348 51.5103 60.7488 55.2309 57.6149 58.3649C54.4809 61.4988 50.7603 63.9848 46.6656 65.6809C43.8732 66.8376 40.9441 67.6114 37.9578 67.9867C35.646 68.2772 33.75 66.3612 33.75 64.0312C33.75 61.7013 35.6516 59.8483 37.9493 59.4617C39.8276 59.1458 41.6687 58.618 43.4367 57.8857C46.5077 56.6136 49.2982 54.7491 51.6486 52.3986C53.9991 50.0482 55.8636 47.2577 57.1357 44.1867C57.868 42.4187 58.3958 40.5776 58.7117 38.6993C59.0983 36.4016 60.9513 34.5 63.2812 34.5Z"
    //           fill="#F14810"
    //         />
    //         <path
    //           d="M18.278 31.676V29.354L23.804 28.184V41H21.302V30.884L18.278 31.676ZM33.7438 28.4H35.9938L28.4338 43.7H26.1118L33.7438 28.4ZM36.5536 38.678L43.7716 28.4H45.5176V41H42.8716V37.832L43.0876 37.634V32.504L40.1176 36.752H44.6176L44.9236 36.482H47.4976V38.678H36.5536Z"
    //           fill="#343434"
    //         />
    //       </svg>
    //     </div>
    //     <div>
    //       <h5 style={{ marginBottom: "0px" }}>About the School</h5>
    //       <p style={{ marginBottom: "0px" }}>
    //         Provide the following information about the school
    //       </p>
    //     </div>
    //   </div>

    //   <div style={{ marginBottom: "15px" }}>
    //     <p style={{ marginBottom: "5px", fontWeight: "500" }}>
    //       School Name
    //       <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
    //     </p>
    //     <input
    //       type="text"
    //       placeholder="Enter Your School Name"
    //       style={{
    //         borderRadius: "5px",
    //         backgroundColor: "#dddddd",
    //         width: "100%",
    //       }}
    //     />
    //   </div>

    //   <p style={{ marginBottom: "5px", fontWeight: "500" }}>
    //     School Type
    //     <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
    //   </p>
    //   <div style={{ position: "relative", marginBottom: "15px" }}>
    //     <select
    //       name="schoolType"
    //       // value={schoolType}
    //       // onChange={handleOnChange}
    //       style={{ borderRadius: "5px", backgroundColor: "#dddddd" }}
    //     >
    //       <option disabled selected value="">
    //         Select School Type
    //       </option>
    //     </select>
    //     <img
    //       src={dropdownIcon}
    //       alt="dropdown icon"
    //       style={{ position: "absolute", right: "15px", top: "40%" }}
    //     />
    //   </div>

    //   <div style={{ marginBottom: "15px" }}>
    //     <p style={{ marginBottom: "5px", fontWeight: "500" }}>
    //       School Email
    //       <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
    //     </p>
    //     <input
    //       type="email"
    //       placeholder="Enter Your School Email"
    //       style={{
    //         borderRadius: "5px",
    //         backgroundColor: "#dddddd",
    //         width: "100%",
    //       }}
    //     />
    //   </div>

    //   <div style={{ marginBottom: "15px" }}>
    //     <p style={{ marginBottom: "5px", fontWeight: "500" }}>
    //       Contact Number
    //       <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
    //     </p>
    //     <input
    //       type="text"
    //       placeholder="Enter Your Contact Number"
    //       style={{
    //         borderRadius: "5px",
    //         backgroundColor: "#dddddd",
    //         width: "100%",
    //       }}
    //     />
    //   </div>

    //   <div style={{ marginBottom: "15px" }}>
    //     <p style={{ marginBottom: "5px", fontWeight: "500" }}>
    //       School Website
    //       <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
    //     </p>
    //     <input
    //       type="text"
    //       placeholder="www.yourschoolname.com"
    //       style={{
    //         borderRadius: "5px",
    //         backgroundColor: "#dddddd",
    //         width: "100%",
    //       }}
    //     />
    //   </div>

    //   <div style={{ marginBottom: "15px" }}>
    //     <p style={{ marginBottom: "5px", fontWeight: "500" }}>
    //       School Address
    //       <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
    //     </p>
    //     <input
    //       type="text"
    //       placeholder="No 65, Dalaba Street, Wuse Zone 5"
    //       style={{
    //         borderRadius: "5px",
    //         backgroundColor: "#dddddd",
    //         width: "100%",
    //       }}
    //     />
    //   </div>

    //   <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "space-between",
    //       alignItems: "center",
    //       gap: "20px",
    //     }}
    //   >
    //     <div style={{ flexGrow: "1" }}>
    //       <p style={{ marginBottom: "5px", fontWeight: "500" }}>
    //         Country
    //         <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
    //       </p>
    //       <div style={{ position: "relative", marginBottom: "15px" }}>
    //         <select
    //           name="country"
    //           // value={country}
    //           // onChange={handleOnChange}
    //           style={{ borderRadius: "5px", backgroundColor: "#dddddd" }}
    //         >
    //           <option disabled selected value="">
    //             Select Country
    //           </option>
    //         </select>
    //         <img
    //           src={dropdownIcon}
    //           alt="dropdown icon"
    //           style={{ position: "absolute", right: "15px", top: "40%" }}
    //         />
    //       </div>
    //     </div>

    //     <div style={{ flexGrow: "1" }}>
    //       <p style={{ marginBottom: "5px", fontWeight: "500" }}>
    //         State
    //         <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
    //       </p>
    //       <div style={{ position: "relative", marginBottom: "15px" }}>
    //         <select
    //           name="state"
    //           // value={state}
    //           // onChange={handleOnChange}
    //           style={{ borderRadius: "5px", backgroundColor: "#dddddd" }}
    //         >
    //           <option disabled selected value="">
    //             Select State
    //           </option>
    //         </select>
    //         <img
    //           src={dropdownIcon}
    //           alt="dropdown icon"
    //           style={{ position: "absolute", right: "15px", top: "40%" }}
    //         />
    //       </div>
    //     </div>
    //   </div>

    //   <Button
    //     variant="primary"
    //     // type="submit"
    //     style={{
    //       backgroundColor: "#F14810",
    //       borderColor: "#F14810",
    //       float: "left",
    //       margin: "20px 0"
    //     }}
    //     className="button"
    //     size="md"
    //   >
    //     <span
    //       style={{
    //         fontSize: 0.8 + "rem",
    //         marginLeft: 0.5 + "rem",
    //         marginRight: 0.5 + "rem",
    //       }}
    //       // onClick={handleGoToNextPage}
    //     >
    //       Save & Continue
    //     </span>
    //   </Button>
    // </form> 

    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <div>
        <div
          style={{
            display: "flex",
            gap: 0.8 + "rem",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <div>
            <svg width="55" height="55" viewBox="0 0 68 69" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M67.5 34.5C67.5 53.1396 52.3896 68.25 33.75 68.25C15.1104 68.25 0 53.1396 0 34.5C0 15.8604 15.1104 0.75 33.75 0.75C52.3896 0.75 67.5 15.8604 67.5 34.5ZM8.4375 34.5C8.4375 48.4797 19.7703 59.8125 33.75 59.8125C47.7297 59.8125 59.0625 48.4797 59.0625 34.5C59.0625 20.5203 47.7297 9.1875 33.75 9.1875C19.7703 9.1875 8.4375 20.5203 8.4375 34.5Z" fill="#E7EAEE" fill-opacity="0.8" />
              <path d="M63.2812 34.5C65.6112 34.5 67.5272 36.396 67.2367 38.7078C66.8614 41.6941 66.0876 44.6232 64.9309 47.4156C63.2348 51.5103 60.7488 55.2309 57.6149 58.3649C54.4809 61.4988 50.7603 63.9848 46.6656 65.6809C43.8732 66.8376 40.9441 67.6114 37.9578 67.9867C35.646 68.2772 33.75 66.3612 33.75 64.0312C33.75 61.7013 35.6516 59.8483 37.9493 59.4617C39.8276 59.1458 41.6687 58.618 43.4367 57.8857C46.5077 56.6136 49.2982 54.7491 51.6486 52.3986C53.9991 50.0482 55.8636 47.2577 57.1357 44.1867C57.868 42.4187 58.3958 40.5776 58.7117 38.6993C59.0983 36.4016 60.9513 34.5 63.2812 34.5Z" fill="#F14810" />
              <path d="M18.278 31.676V29.354L23.804 28.184V41H21.302V30.884L18.278 31.676ZM33.7438 28.4H35.9938L28.4338 43.7H26.1118L33.7438 28.4ZM36.5536 38.678L43.7716 28.4H45.5176V41H42.8716V37.832L43.0876 37.634V32.504L40.1176 36.752H44.6176L44.9236 36.482H47.4976V38.678H36.5536Z" fill="#343434" />
            </svg>
          </div>
          <div>
            <h5 style={{ marginBottom: "0px" }}>About the School</h5>
            <p className="FormParagraph" style={{ marginBottom: "0px" }}>
              Provide the following information about the school
            </p>
          </div>
        </div>
      </div>
      {/* <br /> */}

      {/* {isFormNotFilled ? (
        <span
          style={{
            color: "red",
            fontStyle: "italic",
            transition: "ease-in-out",
          }}
        >
          Please fill all fields
        </span>
      ) : null} */}

      <Form className="form">
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            School Name <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <Form.Control
            size="md"
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
            }}
            type="text"
            name="institutionName"
            // value={institutionName}
            onChange={handleOnChange}
            placeholder="Enter School Name"
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            School Type <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <Form.Select
            size="md"
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
            }}
            name="institutionType"
            // value={institutionType}
            onChange={handleOnChange}
            required
          >
            <option value="" disabled>
              Select School Type
            </option>
            {/* {institutionTypes &&
              institutionTypes.map(({id, typeName}, i) => (
                <option key={id} value={id}>
                  {typeName}
                </option>
              ))} */}
          </Form.Select>
        </Form.Group>

        {/* <div
          ref={dropdownRef}
          style={{
            marginBottom: "15px",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <p style={{ marginBottom: "5px", fontWeight: "500" }}>
            School Type{" "}
            <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
          </p>

          <label
            style={{
              width: "100%",
              backgroundColor: "rgb(221, 221, 221)",
              borderRadius: "5px",
              padding: "7px 10px",
              cursor: "pointer",
              fontSize: "14px",
            }}
            onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
          >
            {selectedItems.length > 0
              ? selectedItemsNames?.join(", ")
              : "Select School Type"}
          </label>

          {showCategoryDropdown && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                zIndex: "10",
                transition: "all",
                transitionTimingFunction: "ease-in-out",
                transitionDuration: "0.3s",
                backgroundColor: "white",
                width: "100%",
                border: "1px solid rgb(221, 221, 221)",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              {institutionTypes &&
                institutionTypes.map(({ typeName, id }) => (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "2px",
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={selectedItems.includes(id)}
                          onChange={(e) => handleItemClick({ typeName, id })}
                          style={{
                            marginRight: "5px",
                            width: "auto",
                            appearance: "auto",
                          }}
                        />
                        <span className={`custom-checkbox`}></span>
                      </label>
                    </div>
                    <span>{typeName}</span>
                  </div>
                ))}
            </div>
          )}
        </div> */}

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            School Email <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <InputGroup className="mb-3">
            <InputGroup.Text
              id="basic-addon1"
              style={{ backgroundColor: "#F14810", color: "#ffffff" }}
            >
              <EnvelopeAt size={22} />
            </InputGroup.Text>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="email"
              placeholder="Enter School Email"
              name="institutionEmail"
              // value={institutionEmail}
              onChange={handleOnChange}
              required
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            Contact Number <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <InputGroup className="mb-3">
            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="number"
              placeholder="Enter School Contact Number"
              name="schoolContactNumber"
              // value={schoolContactNumber}
              onChange={handleOnChange}
              required
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">School Website</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Text
              id="basic-addon1"
              style={{ backgroundColor: "#F14810", color: "#ffffff" }}
            >
              <Link45deg size={22} />
            </InputGroup.Text>
            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              name="website"
              // value={website}
              onChange={handleOnChange}
              placeholder="www.yourschoolwebsite.com"
              aria-label="link"
              aria-describedby="basic-addon1"
              required
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            School Address <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <Form.Control
            size="md"
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
            }}
            type="text"
            name="address"
            // value={address}
            onChange={handleOnChange}
            placeholder="Enter School Address"
            required
          />
        </Form.Group>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                Country <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <Form.Select
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                name="country"
                // value={country}
                onChange={handleOnChange}
                required
              >
                <option value="" disabled>
                  Select Country
                </option>
                {/* {countryData &&
                  countryData.map(({ countryName, id }) => (
                    // <option
                    //   key={id}
                    //   value={id}
                    // >
                    //   {countryName}
                    // </option>
                    <option key={id} value={countryName}>
                      {countryName}
                    </option>
                  ))} */}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                State <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <Form.Select
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                name="state"
                // value={state}
                onChange={handleOnChange}
                placeholder="Enter Your Phone number"
                required
              >
                <option value="" disabled>
                  Select State
                </option>
                {/* {states &&
                  states.map(({ stateName, id }) => (
                    // <option key={id} value={id}>
                    //   {stateName}
                    // </option>
                    <option key={id} value={stateName}>
                      {stateName}
                    </option>
                  ))} */}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <br />

        <div>
          <Button
            variant="primary"
            style={{
              backgroundColor: "#F14810",
              borderColor: "#F14810",
              float: "left",
            }}
            className="button"
            size="md"
            onClick={handleGoToNextPage}
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              Save & Continue
            </span>
          </Button>
        </div>
      </Form>

    </>

  );
};

export default AboutTheSchool;
