import { useState, useEffect } from "react";
import alertIcon from "../../../../../../assets/svgs/alertIcon.svg";
import { Table, Button, Form } from "react-bootstrap";

import { useSelector } from "react-redux";

import ModalComponent from "../../../../../../component/modal/modal";
import ExpandableTable from "../../../../../../component/table/expandableTable";
import {
  useGetInstitutionLevelsQuery,
  useAddInstitutionLevelMutation,
  useEditInstitutionLevelMutation,
  useDeleteInstitutionLevelMutation,
  useGetInstitutionTypeQuery,
  useDeleteClassMutation,
  useUpdateClassMutation,
  useCreateClassMutation,
  useGetAllClassesQuery,
} from "../../../../../../redux/features/institution/institutionApiSlice";
import ActionIcon from "../../../../../../component/actionbutton/ActionIcon";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import ToastComponent from "../../../../../../component/toast/toast";
import TableComponent from "../../../../../../component/table/table";
import {
  returnCategories,
  returnCategoryName,
  returnLevelName,
} from "../../../../../../utils/utils";

const InstitutionLevel = () => {
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [savedLevelsList, setSavedLevelsList] = useState([]);
  const [newData, setNewData] = useState(null);
  const [deletedElementID, setDeletedElementID] = useState(null);
  const [editedElement, setEditedElement] = useState(null);
  const [editingElement, setEditingElement] = useState(null);
  const [editingValue, setEditingValue] = useState({});
  const [elementToDelete, setElementToDelete] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [levelsCreated, setLevelsCreated] = useState(false);
  const [isCategoryBlured, setIsCategoryBlured] = useState(false);

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  const schoolTypes = useSelector(
    (state) => state?.auth?.userInfo?.institutionTypes
  );

  const {
    data: levelsData,
    refetch: levelsRefetch,
    isLoading: levelsLoading,
  } = useGetInstitutionLevelsQuery(instId);

  const {
    data: classes,
    isLoading: classesLoading,
    refetch: refetchClasses,
  } = useGetAllClassesQuery(instId);

  console.log(classes);

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingElement(null);
    setEditingValue({});
    setNewLevelData({});
    setArrayOfCategories([]);
  };

  const [createClass, { isLoading: addLoading }] = useCreateClassMutation();
  const [updateClass, { isLoading: editLoading }] = useUpdateClassMutation();
  const [deleteClass, { isLoading: deleteLoading }] = useDeleteClassMutation();

  //.......................... actions .........................

  const handleEditLevel = (el, parentType) => {
    console.log("edit was clicked for ", el);
    setEditingElement(el);
    setShowModal(true);

    // map through institutionTypes to find out the institutionTypeId of the parent element
    const schoolType = schoolTypes.find((inst) => inst.typeName == parentType);

    setEditingValue({
      name: el.name,
      noOfCategories: el.noOfCategories,
      categories: el.categories,
      institutionTypeId: schoolType.id,
    });
  };
  // console.log(classes);
  // console.log(editingValue);

  const confirmDelete = (el) => {
    console.log(el);
    setElementToDelete(el);
    setShowConfirmDelete(true);
  };

  const handleDeleteLevel = async () => {
    console.log("delete was clicked for ", elementToDelete);
    setShowConfirmDelete(false);
    try {
      const parameters = {
        classId: elementToDelete?.id,
        body: {
          name: elementToDelete.name,
          institutionTypeId: elementToDelete.institutionTypeId,
          // noOfCategories: elementToDelete.noOfCategories,
          // categories: elementToDelete.categories,
        },
      };
      await deleteClass(parameters).unwrap();
      setDeletedElementID(elementToDelete.id);
      setShowToast(true);
      setToastMessage("Level Deleted");
    } catch (error) {
      console.log(error);
      // alert(error.data);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const [arrayOfCategories, setArrayOfCategories] = useState([]);

  const [newLevelData, setNewLevelData] = useState({
    name: "",
    institutionTypeId: "",
    noOfCategories: "",
    categories: "",
  });

  const { name, institutionTypeId, noOfCategories, categories } = newLevelData;

  // const handleCreateOtherCategories = () => {
  //   // console.log(returnCategories(noOfCategories, categories));
  //   setArrayOfCategories(returnCategories(noOfCategories, categories));
  //   // setLevelsCreated(true);
  // };

  // const handleCategoriesChange = (e, i) => {
  //   const updatedArr = [...arrayOfCategories];
  //   updatedArr[i] = { ...updatedArr[i], letter: e.target.value };
  //   setArrayOfCategories(updatedArr);
  // };

  const [categoryNames, setCategoryNames] = useState([]);

  const handleCreateOtherCategories = () => {
    if (!isCategoryBlured) {
      setCategoryNames(
        returnCategories(noOfCategories, categoryNames[0]?.value)
      );
    }
  };

  const handleCategoriesChange = (e, i) => {
    const updatedArr = [...categoryNames];
    updatedArr[i] = { id: i + 1, value: e.target.value };
    setCategoryNames(updatedArr);
  };

  useEffect(() => {
    setCategoryNames(returnCategories(noOfCategories, categoryNames[0]?.value));
  }, [noOfCategories]);


  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setNewLevelData({ ...newLevelData, [name]: value });
    // setEditingValue(value);
    setEditingValue({ ...editingValue, [name]: value });
  };

  const handleCreateOrUpdateLevel = async (e, type) => {
    e.preventDefault();

    if (type === "edit") {
      if (editingValue === "") {
        setIsFormNotFilled(true);

        setTimeout(() => {
          setIsFormNotFilled(false);
        }, 3000);

        return;
      }
      // newLevelData.name = editingElement.name
      const parameters = {
        classId: editingElement?.id,
        body: {
          name: editingValue.name,
          institutionTypeId: editingValue.institutionTypeId,
          // noOfCategories: editingValue.noOfCategories,
          // categories: editingValue.categories,
        },
      };
      try {
        const res = await updateClass(parameters);
        console.log(res);
        if (res) {
          setNewLevelData({
            name: "",
          });
          setEditedElement(res);
          setEditingValue("");
          setShowModal(false);
        }
        setEditingElement(null);
        setCategoryNames([]);
        setIsCategoryBlured(false);
      } catch (error) {
        console.log(error);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
      return;
    } else {
      // if (name === "" || noOfCategories === "" || categories === "") {
      //   setIsFormNotFilled(true);

      //   setTimeout(() => {
      //     setIsFormNotFilled(false);
      //   }, 3000);

      //   return;
      // }
      try {
        const { categories, institutionTypeId, ...rest } = newLevelData;
        const obj = {
          institutionId: instId,
          body: {
            ...rest,
            // if its just a type of school i. just nursetry school then there should not be a need to ask the user select the school type
            institutionTypeId:
              schoolTypes.length > 1 ? institutionTypeId : schoolTypes[0].id,
            // categories: arrayOfCategories.map((item) => item.letter),
            categories: categoryNames.map((item) => item.value),
          },
        };

        const data = await createClass(obj).unwrap();

        if (data) {
          setNewData(data);
        }
        setNewLevelData({
          name: "",
        });
        setShowModal(false);
        setEditingElement(null);

        setCategoryNames([]);
        setIsCategoryBlured(false);
      } catch (error) {
        console.log(error.data);
        // setErrorMessage(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
    }
  };

  // console.log(newLevelData);
  // console.log(isCategoryBlured);
  // console.log(categoryNames);

  console.log(noOfCategories);
  console.log(name);
  console.log(schoolTypes);
  console.log(institutionTypeId);
  console.log(arrayOfCategories);

  useEffect(() => {
    if (classes) {
      refetchClasses();
    }
  }, [newData, deletedElementID, editedElement]);

  if (levelsLoading || addLoading || deleteLoading || editLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          marginBottom: "10px",
        }}
      >
        <h6 style={{ fontSize: "14px", fontWeight: 600, marginBottom: "0px" }}>
          Class Management
        </h6>

        <Button
          className="button"
          size="md"
          style={{
            backgroundColor: "#F14810",
            color: "#ffffff",
            border: "none",
            outline: "none",
          }}
          onClick={() => setShowModal(true)}
          title="Click this button to create a level"
        >
          <span
            style={{
              fontSize: 0.8 + "rem",
              marginLeft: 0.3 + "rem",
              marginRight: 0.3 + "rem",
            }}
          >
            Create New Class
          </span>
        </Button>
      </div>
      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "8px",
          padding: "15px",
          minHeight: "95%",
        }}
      >
        {/* delete confirmation modal */}
        <ModalComponent
          showModal={showConfirmDelete}
          closeModal={() => setShowConfirmDelete(false)}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={alertIcon}
              alt="alert Icon"
              style={{ margin: "0 auto" }}
            />
            <p
              style={{
                marginTop: "10px",
                marginBottom: "5px",
                fontWeight: "500",
                fontSize: 1.0 + "rem",
              }}
            >
              Are you sure you want to delete this level?{" "}
            </p>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Button
                onClick={() => setShowConfirmDelete(false)}
                type="button"
                variant="outline-primary"
                style={{ borderColor: "#F14810", color: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  No
                </span>
              </Button>
              <Button
                onClick={() => handleDeleteLevel()}
                type="button"
                variant="primary"
                style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Yes
                </span>
              </Button>
            </div>
          </div>
        </ModalComponent>

        {/* create class modal */}
        <ModalComponent
          title={editingElement ? "Edit Class" : "Create Class"}
          showModal={showModal}
          closeModal={handleCloseModal}
        >
          {isFormNotFilled && (
            <span
              style={{
                color: "red",
                fontStyle: "italic",
                transition: "ease-in-out",
              }}
            >
              Please fill all fields
            </span>
          )}

          <Form>
            {schoolTypes && schoolTypes?.length > 1 && (
              <>
                <Form.Label className="formClasslabel">
                  School Type{" "}
                  <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
                </Form.Label>
                <Form.Select
                  size="md"
                  className="custom-focus mb-3"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  name="institutionTypeId"
                  value={
                    editingElement
                      ? editingValue?.institutionTypeId
                      : institutionTypeId
                  }
                  onChange={handleOnChange}
                  required
                >
                  <option value="" disabled selected>
                    Select School Type
                  </option>
                  {schoolTypes.map(({ id, typeName }, i) => (
                    <option key={id} value={id}>
                      {typeName}
                    </option>
                  ))}
                </Form.Select>
              </>
            )}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                Class Name {/* {returnLevelName(schoolTypes)} Name{" "} */}
                <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
              </Form.Label>

              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                type="text"
                name="name"
                value={editingElement ? editingValue?.name : name}
                onChange={handleOnChange}
                placeholder="Enter Class Name (e.g Primary 1)"
              />
            </Form.Group>

            {/* was here before */}

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                No of Categories
                <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
              </Form.Label>

              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                type="number"
                name="noOfCategories"
                value={editingElement ? editingValue : noOfCategories}
                onChange={handleOnChange}
                onBlur={handleCreateOtherCategories}
                placeholder="Enter Number of Categories (e.g 1, 2, 3)"
              />
            </Form.Group>
            {/* Render input fields based on the number entered */}
            {categoryNames.map(({ id, value }, i) => (
              <Form.Group key={id} controlId={`categoryInput${i}`}>
                <Form.Label className="formClasslabel">
                  Category {i + 1}
                  <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
                </Form.Label>

                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                    marginBottom: "8px",
                  }}
                  type="text"
                  name={`categoryInput${i}`}
                  value={value}
                  onChange={(e) => handleCategoriesChange(e, i)}
                  onBlur={() => {
                    handleCreateOtherCategories();
                    setIsCategoryBlured(true);
                  }}
                  placeholder={`Enter Category Name (e.g A, Gold, Art)`}
                />
              </Form.Group>
            ))}
            <div style={{ marginTop: "30px" }}>
              {editingElement ? (
                <Button
                  onClick={(e) => handleCreateOrUpdateLevel(e, "edit")}
                  variant="primary"
                  style={{
                    backgroundColor: `${
                      !editingValue?.name || !editingValue?.institutionTypeId
                        ? "#FFCAB8"
                        : "#F14810"
                    }`,
                    borderColor: `${
                      !editingValue?.name || !editingValue?.institutionTypeId
                        ? "#FFCAB8"
                        : "#F14810"
                    }`,
                    cursor: `${
                      !editingValue?.name || !editingValue?.institutionTypeId
                        ? "not-allowed"
                        : "pointer"
                    }`,
                  }}
                  className="button"
                  size="md"
                >
                  <span
                    style={{
                      fontSize: 0.8 + "rem",
                      marginLeft: 0.5 + "rem",
                      marginRight: 0.5 + "rem",
                    }}
                  >
                    Edit Level
                  </span>
                </Button>
              ) : (
                <Button
                  onClick={
                    !noOfCategories ||
                    !name ||
                    // !institutionTypeId ||
                    arrayOfCategories?.find((cat) => cat.letter === "")
                      ? null
                      : (e) => handleCreateOrUpdateLevel(e, "")
                  }
                  variant="primary"
                  style={{
                    backgroundColor: `${
                      !noOfCategories ||
                      !name ||
                      // !institutionTypeId ||
                      arrayOfCategories?.find((cat) => cat.letter === "")
                        ? "#FFCAB8"
                        : "#F14810"
                    }`,
                    borderColor: `${
                      !noOfCategories ||
                      !name ||
                      // !institutionTypeId ||
                      arrayOfCategories?.find((cat) => cat.letter === "")
                        ? "#FFCAB8"
                        : "#F14810"
                    }`,
                    cursor: `${
                      !noOfCategories ||
                      !name ||
                      // !institutionTypeId ||
                      arrayOfCategories?.find((cat) => cat.letter === "")
                        ? "not-allowed"
                        : "pointer"
                    }`,
                    float: "left",
                  }}
                  className="button"
                  size="md"
                >
                  <span
                    style={{
                      fontSize: 0.8 + "rem",
                      marginLeft: 0.5 + "rem",
                      marginRight: 0.5 + "rem",
                    }}
                  >
                    Save Level
                  </span>
                </Button>
              )}
            </div>
          </Form>
        </ModalComponent>

        {/* Make sure the classes are ordered in this form */}
        {classes && Object.keys(classes).length > 0 ? (
          ["NURSERY", "PRIMARY", "SECONDARY", "TERTIARY", "OTHERS"].map((key, i) => {
            if (classes[key]) {
              return (
                <div key={key} id={key} style={{ marginBottom: "20px" }}>
                  {/* <h3 style={{ fontSize: "16px" }}>{key}</h3> */}
                  <ExpandableTable data={classes[key]} nameOfClass={key} />
                </div>
              );
            }
            return null;
          })
        ) : (
          <span style={{ fontStyle: "italic", marginBottom: "20px" }}>
            Nothing to show, create a class
          </span>
        )}
      </div>
    </>
  );
};

export default InstitutionLevel;
