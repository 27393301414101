import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { addTrailingCommas } from "../../../../../../utils/utils";
import { useEffect } from "react";
import dropdownIcon from "../../../../../../assets/svgs/dropdownIcon.svg";
import Button from "../../../../../../component/button/button";
import {
  useGetGlobalCommissionQuery,
  useGetInstitutionSettlementListQuery,
  useApplyCommissionMutation,
} from "../../../../../../redux/features/superAdmin/adminApiSlice";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import SuccessScreen from "../../../../../../component/successScreen/successScreen";
import ToastComponent from "../../../../../../component/toast/toast";

const EditReminderChargeCanvas = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showSchoolsDropdown, setShowSchoolsDropdown] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsNames, setSelectedItemsNames] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [chargeSMSValue, setChargeSMSValue] = useState(0);
  const [chargeEmailValue, setChargeEmailValue] = useState(0);

  const { data: schoolsList, isLoading: schoolListLoading } =
    useGetInstitutionSettlementListQuery();
  console.log(schoolsList);

  const handleItemClick = (obj) => {
    if (selectedItems.includes(obj.id)) {
      setSelectedItems(selectedItems.filter((i) => i !== obj.id));
      setSelectedItemsNames(
        selectedItemsNames.filter((i) => i !== obj.institutionName)
      );
    } else {
      setSelectedItems([...selectedItems, obj.id]);
      setSelectedItemsNames([...selectedItemsNames, obj.institutionName]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
      setSelectedItemsNames([]);
    } else {
      const allItemIds = schoolsList?.map((category) => category.id);
      setSelectedItems(allItemIds);
      setSelectedItemsNames(
        schoolsList?.map((category) => category.institutionName)
      );
    }
    setSelectAll(!selectAll);
  };

  const handleSetReminderCharges = async () => { };

  if (schoolListLoading) {
    return <OnboardingLoader />;
  }

  // if (applyCommissionSuccess) {
  //     return (
  //         <SuccessScreen successMessage="Rate has been applied successfully" />
  //     );
  // }

  return (
    <form onSubmit={(e) => e.preventDefault()} className="commission-canvas">
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <h6
        style={{
          color: "#F14810",
          fontFamily: "jost, sans-serif",
          fontSize: 1.0 + "rem",
          marginTop: "30px",
        }}
      >
        Bestimoh International School
      </h6>
      <p>
        Configure reminder charges for this school to cover SMS and email notifications.
        Choose the amount per message for each type of reminder.
      </p>

      <div id="SMS" style={{ marginTop: "40px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h6 style={{ marginBottom: "10px", fontSize: 0.9 + "rem" }}>
            SMS (Amount per Message)
          </h6>
        </div>
        <div style={{ display: "flex", gap: "15px", marginBottom: "30px" }}>
          <div
            style={{
              fontSize: 0.8 + "rem",
              fontFamily: "jost, sans-serif",
              backgroundColor: "#ededed",
              width: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#484d45",
              padding: "5px 10px",
            }}
          >
            NGN
          </div>
          <input
            type="number"
            //onChange={(e) => handleOnChange(e)}
            placeholder="Enter rate in amount e.g 5000"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
            }}
          />
        </div>
      </div>
      <div id="Email">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h6 style={{ marginBottom: "10px", fontSize: 0.9 + "rem" }}>
            Email (Amount per Message)
          </h6>
        </div>
        <div style={{ display: "flex", gap: "15px", marginBottom: "30px" }}>
          <div
            className="custom-focus"
            style={{
              fontSize: 0.8 + "rem",
              fontFamily: "jost, sans-serif",
              backgroundColor: "#ededed",
              width: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#484d45",
              padding: "5px 10px",
            }}
          >
            NGN
          </div>
          <input
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
            }}
            type="number"
            //onChange={(e) => handleOnChange(e)}
            placeholder="Enter rate in amount e.g 5000"
          //style={{ backgroundColor: "#ededed" }}
          />
        </div>
      </div>

      <Button
        onClick={
          selectedItems?.length === 0 || !chargeEmailValue || !chargeSMSValue
            ? null
            : () => handleSetReminderCharges()
        }
        backgroundColor={`${selectedItems?.length === 0 || !chargeEmailValue || !chargeSMSValue
            ? "#FFCAB8"
            : "rgb(241, 72, 16)"
          }`}
        cursor={`${selectedItems?.length === 0 || !chargeEmailValue || !chargeSMSValue
            ? "not-allowed"
            : "pointer"
          }`}
      >
        Save Changes
      </Button>
    </form>
  );
};

export default EditReminderChargeCanvas;
