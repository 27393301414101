import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useState } from "react";
import dropdownIcon from "../../../../../assets/svgs/dropdownIcon.svg";
import {
  useGetIntervalsQuery,
  useSetIntervalMutation,
  useGetIntervalQuery,
} from "../../../../../redux/features/superAdmin/adminApiSlice";
import ToastComponent from "../../../../../component/toast/toast";
import ModalComponent from "../../../../../component/modal/modal";
import successGIF from "../../../../../assets/svgs/successGIF.gif";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";

const SettlementInterval = () => {
  const [selectedInterval, setSelectedInterval] = useState(null);
  const [dateTime, setDateTime] = useState(null);
  const [showIntervalDropdown, setShowIntervalDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTimeDropdown, setShowTimeDropdown] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [setInterval, { isLoading: setIntervalLoading }] =
    useSetIntervalMutation();

  const { data: intervals, isLoading: intervalsLoading } =
    useGetIntervalsQuery();
  const { data: interval, isLoading: intervalLoading, refetch: refetchInterval } = useGetIntervalQuery();

  // const handleChange = (e) => {
  //   setDateTime(e.target.value)
  // }

  const handleSetInterval = async () => {
    const body = {
      intervalValue: selectedInterval,
      startDate: dateTime,
    };

    try {
      const res = await setInterval(body).unwrap();
      console.log(res);
      setShowModal(true);
      setSelectedInterval(null);
      setDateTime(null);
    } catch (error) {
      console.log(error);
      // setErrorMessage(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  console.log(interval);
  console.log(selectedInterval);

  useEffect(() => {
    
    if(interval) {
      refetchInterval()
      setSelectedInterval(interval?.intervalValue);
    }
  }, [intervalLoading, showModal]);

  if (setIntervalLoading || intervalsLoading || intervalLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <ModalComponent
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!</h3>
          <p style={{ fontSize: "18px" }}>
            Settlement Interval have been set successfully
          </p>
        </div>
      </ModalComponent>

      <h6 style={{ fontSize: "14px", fontWeight: 600 }}>Settlement Interval</h6>
      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "8px",
          padding: "15px",
          display: "flex",
          gap: "30px",
        }}
        className="details-in-settings"
      >
        <div style={{ flexBasis: "40%" }}>
          <h6>Set Settlement Interval</h6>
          <p>
            Set up the required Settlement Interval here. Clicking the "Save
            changes" button will update the existing information{" "}
          </p>
        </div>
        <div
          style={{ flexBasis: "60%", display: "flex", flexDirection: "column" }}
        >
          <div style={{ position: "relative" }}>
            <h6 style={{ marginTop: "20px" }}>Settlement Interval</h6>
            <label
              style={{
                width: "100%",
                backgroundColor: "rgb(221, 221, 221)",
                borderRadius: "5px",
                padding: "10px",
                cursor: "pointer",
                position: "relative",
              }}
              onClick={() => setShowIntervalDropdown(!showIntervalDropdown)}
            >
              {selectedInterval ? (
                <>{selectedInterval}</>
              ) : (
                "Select Interval For Settlement"
              )}

              <img
                src={dropdownIcon}
                alt="drop down icon"
                style={{ position: "absolute", right: "10px", top: "40%" }}
              />
            </label>

            {showIntervalDropdown && (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  zIndex: "10",
                  transition: "all",
                  transitionTimingFunction: "ease-in-out",
                  transitionDuration: "0.3s",
                  backgroundColor: "white",
                  width: "100%",
                  border: "1px solid rgb(221, 221, 221)",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                {intervals &&
                  intervals?.map((interval, i) => (
                    <label
                      style={{ display: "flex", marginBottom: "5px" }}
                      key={i}
                    >
                      <Form.Check
                        inline
                        name="interval"
                        onChange={() => setSelectedInterval(interval)}
                        checked={interval == selectedInterval}
                        type="radio"
                        id={`inline-radio-${i}`}
                        size={20}
                        style={{ marginRight: "5px" }}
                      />
                      {interval}
                    </label>
                  ))}
              </div>
            )}
          </div>

          <h6 style={{ marginTop: "20px" }}>
            {/* {selectedInterval === "DAILY"
              ? "Time of Day"
              : "Start Date and Time"} */}
            Start Date and Time
          </h6>
          <input
            // type={selectedInterval === "DAILY" ? "time" : "datetime-local"}
            type="datetime-local"
            style={{
              backgroundColor: "#dddddd",
              padding: "10px",
              borderRadius: "5px",
              border: "none",
              outline: "none",
            }}
            value={dateTime ? dateTime : interval?.startDate}
            onChange={(e) => setDateTime(e.target.value)}
          />

          <div
            style={{
              padding: "10px",
              marginLeft: "auto",
              marginTop: "auto",
            }}
          >
            <Button
              className="button"
              size="md"
              style={{
                backgroundColor: `${
                  selectedInterval && dateTime ? "#F14810" : "#FFCAB8"
                }`,
                cursor: `${
                  selectedInterval && dateTime ? "pointer" : "not-allowed"
                }`,
                color: "#ffffff",
                border: "none",
                outline: "none",
              }}
              onClick={
                selectedInterval && dateTime ? () => handleSetInterval() : null
              }
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.3 + "rem",
                  marginRight: 0.3 + "rem",
                }}
              >
                Save Interval
              </span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettlementInterval;
