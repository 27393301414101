import React from 'react'

const SettlementIcon = ({active}) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 33 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0757 0.706055C6.34041 0.717449 4.3414 0.825698 2.86633 1.98605C2.16192 2.54042 1.55715 3.25285 1.08656 4.08265C0 5.99885 0 8.66329 0 13.9998C0 19.3343 0 22.0007 1.08656 23.9169C1.55715 24.7467 2.16192 25.4591 2.86633 26.0135C4.3414 27.1738 6.34041 27.2821 10.0757 27.2935V19.5166C9.03818 19.1999 8.11936 18.4862 7.46343 17.4877C6.80751 16.4892 6.45161 15.2623 6.45161 13.9998C6.45161 12.7372 6.80751 11.5104 7.46343 10.5118C8.11936 9.51333 9.03818 8.79966 10.0757 8.48287V0.706055ZM12.4938 27.2935H19.7483V0.706055H12.4938V27.2935ZM22.1665 0.706055V8.48287C23.2039 8.79966 24.1228 9.51333 24.7787 10.5118C25.4346 11.5104 25.7905 12.7372 25.7905 13.9998C25.7905 15.2623 25.4346 16.4892 24.7787 17.4877C24.1228 18.4862 23.2039 19.1999 22.1665 19.5166V27.2935C25.9017 27.2821 27.9023 27.1738 29.3758 26.0135C30.0801 25.459 30.6849 24.7466 31.1556 23.9169C32.2421 22.0007 32.2421 19.3362 32.2421 13.9998C32.2421 8.66518 32.2421 5.99885 31.1556 4.08265C30.6849 3.25292 30.0801 2.54049 29.3758 1.98605C27.9007 0.825698 25.9017 0.71555 22.1665 0.706055Z"
        fill={active ? "#ffffff" : "#464646"}
      />
    </svg>
  );
}

export default SettlementIcon