import { Col, Container, Row } from "react-bootstrap";
import backArrow from "../../../../../assets/svgs/backArrow.svg";
import { useNavigate } from "react-router-dom";
import DashboardSidebar from "../dashboardSidebar/sidebar";
import DashboardTopSection from "../dashboardTopSection";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import {
  useGetSchoolMessageRemindersQuery,
  useGetInstitutionQuery,
} from "../../../../../redux/features/institution/institutionApiSlice";
import EditIcon from "../../../../../assets/svgs/editIcon";
import { useGetAdminMessageRemindersQuery } from "../../../../../redux/features/superAdmin/adminApiSlice";
import { useSelector } from "react-redux";
import { addTrailingCommas } from "../../../../../utils/utils";
import { useEffect } from "react";

const ViewMessages = () => {
  const navigate = useNavigate();

  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);
  const params = {
    institutionId: id,
    //type: "ALL"
  };
  const {
    data: remindersList,
    isLoading: remindersLoading,
    refetch: refetchReminders,
  } = useGetSchoolMessageRemindersQuery(params);

  console.log(remindersList);

  const { data: institutionDetails } = useGetInstitutionQuery(id);

  const handleShowEdit = (id) => {
    navigate(`/messaging/${id}`);
  };

  useEffect(() => {
    if (remindersList) refetchReminders();
  }, []);

  if (remindersLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div>
      <Container
        fluid
        style={{ display: "flex" }}
        className="dashboardContainer"
      >
        <DashboardSidebar institutionDetails={institutionDetails} />

        <Container fluid className="bodycontainer">
          <DashboardTopSection />

          <section>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer",
                marginTop: "15px",
                marginBottom: "15px",
              }}
              onClick={() => navigate(-1)}
            >
              <img src={backArrow} alt="Go back" />
              <h6 style={{ marginBottom: 0 }}>Sent Messages</h6>
            </div>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                height: "100%",
                display: "inline-block",
                width: "100%",
                marginBottom: 2 + "rem",
                //position: "relative",
                //margin: "40px"
              }}
            >
              {remindersList &&
                remindersList?.map(
                  ({
                    id,
                    messageType,
                    messageTitle,
                    messageBody,
                    messageDate,
                    chargesPerMessage,
                    totalCharges,
                    invoice,
                    totalNoOfStudents,
                    noOfStudentPaidStatus,
                    noOfStudentUnPaidStatus,
                    sent,
                    sentBy,
                    reminderType,
                  }) => (
                    <div
                      style={{
                        border: "2px solid #EDEDED",
                        borderRadius: "8px",
                        gap: "30px",
                        backgroundColor: "#EDEDED",
                        margin: "25px",
                        //marginTop: "25px",
                        display: "flex",
                        width: "95%",
                      }}
                    >
                      <div
                        style={{
                          borderRight: "0px solid #F5F5F5",
                          flexBasis: "50%",
                          padding: "15px 15px",
                          minWidth: "150px",
                          overflowY: "auto",
                        }}
                      >
                        <p style={{ fontWeight: "600", color: "#F14810" }}>
                          Message Title: <br />
                          <span
                            style={{
                              fontWeight: "400",
                              color: "black",
                              textJustify: "inter-word",
                              textAlignLast: "justify",
                            }}
                          >
                            {messageTitle}
                          </span>
                        </p>
                        <p style={{ fontWeight: "600", color: "#F14810" }}>
                          Message Body: <br />
                          <span
                            style={{
                              fontWeight: "400",
                              color: "black",
                              textJustify: "inter-word",
                              textAlignLast: "justify",
                            }}
                          >
                            {messageBody}
                          </span>
                        </p>
                        <div
                          style={
                            {
                              //borderRadius: "8px",
                              //display: "flex",
                              //position: "relative",
                            }
                          }
                        >
                          <Row>
                            <Col xs={6} md={6} lg={6} sm={6}>
                              <p
                                style={{ fontWeight: "600", color: "#F14810" }}
                              >
                                Message Type: <br />
                                <span
                                  style={{
                                    fontWeight: "400",
                                    color: "black",
                                    textJustify: "inter-word",
                                    textAlignLast: "justify",
                                  }}
                                >
                                  {messageType}
                                </span>
                              </p>
                            </Col>
                            <Col xs={6} md={6} lg={6} sm={6}>
                              <p
                                style={{ fontWeight: "600", color: "#F14810" }}
                              >
                                Message Date: <br />
                                <span
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  {messageDate}
                                </span>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div
                        style={{
                          flexBasis: "50%",
                          padding: "15px 15px",
                          minWidth: "150px",
                          overflowY: "auto",
                        }}
                      >
                        <p style={{ fontWeight: "600", color: "#F14810" }}>
                          Message Summary: <br />
                          <span
                            style={{
                              fontWeight: "400",
                              color: "black",
                              textJustify: "inter-word",
                              textAlignLast: "justify",
                            }}
                          ></span>
                        </p>

                        <Row>
                          <Col xs={5} md={5} lg={5} sm={5}>
                            <p style={{ fontWeight: "600" }}>
                              Message Status: <br />
                              <span
                                style={{
                                  fontWeight: "400",
                                  color: "black",
                                  textJustify: "inter-word",
                                  textAlignLast: "justify",
                                }}
                              >
                                {sent ? "Sent" : "Not Sent"}
                              </span>
                            </p>
                          </Col>
                          <Col xs={5} md={5} lg={5} sm={5}>
                            <p style={{ fontWeight: "600" }}>
                              Sent By: <br />
                              <span
                                style={{ fontWeight: "400", color: "black" }}
                              >
                                {sentBy?.firstName + " " + sentBy?.lastName}
                              </span>
                            </p>
                          </Col>
                          <Col>
                            {/* {!sent && (
                              <div style={{ display: "flex", gap: "3px" }}>
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "35px",
                                    width: "35px",
                                    borderRadius: "100%",
                                    backgroundColor: "#E7E7E7",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleShowEdit(id)}
                                >
                                  <EditIcon />
                                </span>
                              </div>
                            )} */}
                          </Col>
                        </Row>
                        {reminderType === "REMINDER" ? null : (
                          <Row>
                            <p style={{ fontWeight: "600" }}>
                              Invoice Title: <br />
                              <span
                                style={{ fontWeight: "400", color: "black" }}
                              >
                                {invoice?.title}
                              </span>
                            </p>
                          </Row>
                        )}
                        <Row>
                          <Col>
                            <p style={{ fontWeight: "600" }}>
                              Total No. of Students: <br />
                              <span
                                style={{ fontWeight: "400", color: "black" }}
                              >
                                {totalNoOfStudents}
                              </span>
                            </p>
                          </Col>
                          {/* <Col>
                                                <p style={{ fontWeight: "600" }}>
                                                    No. of Paid:{" "} <br />
                                                    <span style={{ fontWeight: "400", color: "black" }}>
                                                        {noOfStudentPaidStatus}
                                                    </span>
                                                </p>
                                            </Col>
                                            <Col>
                                                <p style={{ fontWeight: "600" }}>
                                                    No. of Unpaid:{" "} <br />
                                                    <span style={{ fontWeight: "400", color: "black" }}>
                                                        {noOfStudentUnPaidStatus}
                                                    </span>
                                                </p>
                                            </Col> */}
                          <Col>
                            <p style={{ fontWeight: "600" }}>
                              Amount per Message: <br />
                              <span
                                style={{ fontWeight: "400", color: "black" }}
                              >
                                ₦ {addTrailingCommas(chargesPerMessage)}
                              </span>
                            </p>
                          </Col>
                          <Col>
                            <p style={{ fontWeight: "600" }}>
                              Total Amount: <br />
                              <span
                                style={{ fontWeight: "400", color: "black" }}
                              >
                                ₦ {addTrailingCommas(totalCharges)}
                              </span>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          {/* <Col>
                                                <p style={{ fontWeight: "600" }}>
                                                    Amount per Message:{" "} <br />
                                                    <span style={{ fontWeight: "400", color: "black" }}>
                                                        ₦ {addTrailingCommas(chargesPerMessage)}
                                                    </span>
                                                </p>
                                            </Col>
                                            <Col>
                                                <p style={{ fontWeight: "600" }}>
                                                    Total Amount:{" "} <br />
                                                    <span style={{ fontWeight: "400", color: "black" }}>
                                                        ₦ {addTrailingCommas(totalCharges)}
                                                    </span>
                                                </p>
                                            </Col> */}
                          <Col>
                            {/* <p style={{ fontWeight: "600" }}>
                                                    No. of Unpaid:{" "} <br />
                                                    <span style={{ fontWeight: "400", color: "black" }}>
                                                        {noOfStudentUnPaidStatus}
                                                    </span>
                                                </p> */}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )
                )}
            </div>
          </section>

          <br />
          <br />
        </Container>
      </Container>
    </div>
  );
};

export default ViewMessages;
