import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { Checkmail } from "../../../assets/svgs/checkMail.svg";
import OnboardingLoader from "../../../component/onboardingLoader/onboardingLoader";
import RegistrationLayout from "../../../component/layout/registrationLayout";
import {
  useForgotPasswordMutation,
  useResendVerificationCodeMutation
} from "../../../redux/features/auth/authApiSlice";
import ModalComponent from "../../../component/modal/modal";
import ToastComponent from "../../../component/toast/toast";

function ForgotPasswordPage() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [formData, setFormData] = useState({
    email: "",
  });
  const { email } = formData;

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate()
  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/login");
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [forgotPassword, { isLoading: forgotPasswordLoading, isSuccess: forgotPasswordSuccess }] =
    useForgotPasswordMutation();

  // const [resendCode, { isLoading: resendCodeLoading, isSuccess: resendCodeSuccess }] =
  //   useResendVerificationCodeMutation();

  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = await forgotPassword({
        email
      }).unwrap();

      if (data) {
        // dispatch(userInfo(data));
        setShowModal(true);
      }
    } catch (error) {
      console.log(error);
      // setErrorMessage(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  // const handleResendCode = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const data = await resendCode({
  //       email
  //     }).unwrap();

  //     if (data) {
  //       // dispatch(userInfo(data));
  //       setShowModal(true);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     // setErrorMessage(error.data.detail);
  //     setShowToast(true);
  //     if (error?.data?.detail) {
  //       setToastMessage(error.data.detail);
  //     } else {
  //       // other errors
  //       setToastMessage(error.error);
  //     }
  //   }
  // }

  if (forgotPasswordLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <RegistrationLayout>
        <br />

        <br />

        <ModalComponent showModal={showModal} closeModal={handleCloseModal}>
          <form>
            <div style={{ marginBottom: "15px" }}>
              <center>
                <div style={{ marginBottom: "10px" }}>
                  <svg
                    width="65"
                    height="86"
                    viewBox="0 0 85 86"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="6.5"
                      y="7"
                      width="72"
                      height="72"
                      rx="36"
                      fill="#F14810"
                    />
                    <g clipPath="url(#clip0_1896_9450)">
                      <path
                        d="M57.2411 34.4877C57.2411 32.8198 55.8765 31.4551 54.2085 31.4551H29.9476C28.2797 31.4551 26.915 32.8198 26.915 34.4877M57.2411 34.4877V52.6833C57.2411 54.3513 55.8765 55.7159 54.2085 55.7159H29.9476C28.2797 55.7159 26.915 54.3513 26.915 52.6833V34.4877M57.2411 34.4877L42.0781 45.1018L26.915 34.4877"
                        stroke="white"
                        strokeWidth="3.03261"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <rect
                      x="6.5"
                      y="7"
                      width="72"
                      height="72"
                      rx="36"
                      stroke="#FFE1D8"
                      strokeWidth="12.8571"
                    />
                    <defs>
                      <clipPath id="clip0_1896_9450">
                        <rect
                          width="36"
                          height="36"
                          fill="white"
                          transform="translate(24.5 25)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <h3
                  style={{
                    borderColor: "#F14810",
                    color: "#F14810",
                    fontWeight: "bold",
                    fontFamily: "jost, sans-serif",
                  }}
                >
                  Check your mail
                </h3>
                <p
                  style={{
                    marginBottom: "5px",
                    fontWeight: "500",
                    fontSize: 1.0 + "rem",
                  }}
                >
                  Password reset link has been sent to{" "}
                  <span style={{ color: "#3174fd" }}>{formData?.email}</span>{" "}
                </p>

                <br />

                <p
                  style={{
                    marginBottom: "5px",
                    fontFamily: "jost, sans-serif",
                    fontSize: 1.0 + "rem",
                  }}
                >
                  Didn't receive the email?{" "}
                </p>

                <Button
                  variant="link"
                  style={{
                    fontFamily: "jost, sans-serif",
                    fontSize: 0.9 + "rem",
                    color: "#3174fd",
                  }}
                  onClick={handleOnSubmit}
                >
                  Click to resend
                </Button>
              </center>
            </div>
          </form>
        </ModalComponent>

        <div>
          <h3 className="FormHeader" style={{ marginTop: "15px" }}>
            Forgot Password
          </h3>
          <p className="FormParagraph">
            Fill in your registered email address to reset your password
          </p>
        </div>

        <br />

        {/* {errorMessage && (
          <span style={{ color: "red", fontStyle: "italic" }}>
            {errorMessage}
          </span>
        )} */}

        <Form className="form" onSubmit={handleOnSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Email Address <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="email"
              name="email"
              value={email}
              onChange={handleOnChange}
              placeholder="Enter Your Email Address"
              required
            />
          </Form.Group>

          <br />


          <Button
            variant="primary"
            type="submit"
            style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              Submit
            </span>
          </Button>
        </Form>

        <div>
          <p
            style={{
              fontFamily: "jost, sans-serif",
              fontSize: 0.85 + "rem",
              marginTop: 1 + "rem",
              marginRight: -2 + "rem",
            }}
          >
            Remember Password?
            <a
              href="/login"
              className="btn btn-link"
              role="button"
              data-bs-toggle="button"
              style={{
                fontFamily: "jost, sans-serif",
                fontSize: 0.85 + "rem",
                marginTop: 0.85 + "rem",
                marginBottom: 1.15 + "rem",
                paddingLeft: 0.3 + "rem",
                paddingRight: 1.2 + "rem",
              }}
            >
              <span style={{ color: "#3174fd" }}>Back to Login</span>
            </a>
          </p>
        </div>
      </RegistrationLayout>
    </>
  );
}

export default ForgotPasswordPage;
