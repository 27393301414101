import { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Image,
  Form,
  Accordion,
  InputGroup,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import stageThreeIcon from "../../../../../../assets/svgs/stageThreeRegistrationIcon.svg";
import { TelephonePlus } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddBranchMutation,
  useGetSchoolAdminUsersQuery,
} from "../../../../../../redux/features/institution/institutionApiSlice";
import {
  updateContactInfo,
  updateAboutSchool,
} from "../../../../../../redux/features/auth/authSlice";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";

// import "../../../../styles/formpage.css";
// import "../registrationStepper";
// import "./contactPerson.css";

const ContactPerson = ({ setActiveStage, setShowCreateBranchCanvas }) => {
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);

  const [primaryContactId, setPrimaryContactId] = useState("");
  const [secondaryContactId, setSecondaryContactId] = useState("");
  const [technicalContactId, setTechnicalContactId] = useState("");
  const [primaryContactExisting, setPrimaryContactExisting] = useState(false);
  const [secondaryContactExisting, setSecondaryContactExisting] =
    useState(false);
  const [technicalContactExisting, setTechnicalContactExisting] =
    useState(false);

  const dispatch = useDispatch();

  console.log(primaryContactExisting);

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const { data: allSchoolUsers, isLoading: allSchoolUsersLoading } =
    useGetSchoolAdminUsersQuery(instId);
  // console.log(allSchoolUsers);

  const [addBranch, { isLoading: addBranchLoading }] = useAddBranchMutation();

  // // get previous data if they have filled this page before
  const aboutContactPerson = useSelector(
    (state) => state.auth.contactPersonInfo
  );

  const aboutInstitutionData = useSelector(
    (state) => state.auth.aboutSchoolInfo
  );

  // const schoolBranding = useSelector(
  //   (state) => state.institution.brandingDetails
  // );

  console.log(aboutContactPerson);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dates = Array.from({ length: 31 }, (_, index) =>
    (index + 1).toString()
  );

  const [contactPersonDetails, setContactPersonDetails] = useState({
    primaryContact: {
      firstName: aboutContactPerson
        ? aboutContactPerson?.primaryContact?.firstName
        : "",
      lastName: aboutContactPerson
        ? aboutContactPerson?.primaryContact?.lastName
        : "",
      dateOfBirth: {
        dayOfMonth: aboutContactPerson
          ? aboutContactPerson?.primaryContact?.dateOfBirth?.dayOfMonth
          : "",
        month: aboutContactPerson
          ? aboutContactPerson?.primaryContact?.dateOfBirth?.month
          : "",
      },
      email: aboutContactPerson
        ? aboutContactPerson?.primaryContact?.email
        : "",
      phoneNumber: aboutContactPerson
        ? aboutContactPerson?.primaryContact?.phoneNumber
        : "",
      gender: aboutContactPerson
        ? aboutContactPerson?.primaryContact?.gender
        : "",
    },
    secondaryContact: {
      firstName: aboutContactPerson
        ? aboutContactPerson?.secondaryContact?.firstName
        : "",
      lastName: aboutContactPerson
        ? aboutContactPerson?.secondaryContact?.lastName
        : "",
      dateOfBirth: {
        dayOfMonth: aboutContactPerson
          ? aboutContactPerson?.secondaryContact?.dateOfBirth?.dayOfMonth
          : "",
        month: aboutContactPerson
          ? aboutContactPerson?.secondaryContact?.dateOfBirth?.month
          : "",
      },
      email: aboutContactPerson
        ? aboutContactPerson?.secondaryContact?.email
        : "",
      phoneNumber: aboutContactPerson
        ? aboutContactPerson?.secondaryContact?.phoneNumber
        : "",
      gender: aboutContactPerson
        ? aboutContactPerson?.secondaryContact?.gender
        : "",
    },
    technicalContact: {
      firstName: aboutContactPerson
        ? aboutContactPerson?.technicalContact?.firstName
        : "",
      lastName: aboutContactPerson
        ? aboutContactPerson?.technicalContact?.lastName
        : "",
      dateOfBirth: {
        dayOfMonth: aboutContactPerson
          ? aboutContactPerson?.technicalContact?.dateOfBirth?.dayOfMonth
          : "",
        month: aboutContactPerson
          ? aboutContactPerson?.technicalContact?.dateOfBirth?.month
          : "",
      },
      email: aboutContactPerson
        ? aboutContactPerson?.technicalContact?.email
        : "",
      phoneNumber: aboutContactPerson
        ? aboutContactPerson?.technicalContact?.phoneNumber
        : "",
      gender: aboutContactPerson
        ? aboutContactPerson?.technicalContact?.gender
        : "",
    },
    customerSupportNumber: aboutContactPerson
      ? aboutContactPerson?.customerSupportNumber
      : "",
  });

  const {
    primaryContact,
    secondaryContact,
    technicalContact,
    customerSupportNumber,
  } = contactPersonDetails;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    // handle the keys that are nested in objects. E.g contactPerson.firstName

    const [objectKey, nestedKey, deepNested] = name.split(".");
    // If we can split the name by "." it means it is nested
    // spread all the previous data in the entire object, then target the particular object that needs to be mutated. spread all of its contents, then mutate the particular key needed

    if (deepNested) {
      setContactPersonDetails((previousData) => ({
        ...previousData,
        [objectKey]: {
          ...previousData[objectKey],
          [nestedKey]: {
            ...previousData[objectKey][nestedKey],
            [deepNested]: value,
          },
        },
      }));
    } else {
      setContactPersonDetails((previousData) => ({
        ...previousData,
        [objectKey]: { ...previousData[objectKey], [nestedKey]: value },
      }));
    }

    if (name === "customerSupportNumber") {
      setContactPersonDetails({
        ...contactPersonDetails,
        customerSupportNumber: value,
      });
    }
  };

  // console.log(primaryContactId);
  // console.log(secondaryContactId);
  // console.log(technicalContactId);

  // const dispatch = useDispatch();

  // check if they filled the necessary fields
  function isContactValid(contact) {
    return (
      contact?.firstName &&
      contact?.lastName &&
      contact?.email &&
      contact?.phoneNumber
    );
  }

  const handleGoToPrevPage = () => {
    if (
      // !secondaryContact.email ||
      // !secondaryContact.phoneNumber ||
      !technicalContact.email ||
      !technicalContact.phoneNumber
    ) {
      dispatch(
        updateContactInfo({
          primaryContact,
          primaryContactId,
          secondaryContact,
          secondaryContactId,
          technicalContactId,
          customerSupportNumber,

          primaryContactExisting,
          secondaryContactExisting,
          technicalContactExisting,
        })
      );
    } else {
      // dispatch(updateContactInfo(contactPersonDetails));
      dispatch(
        updateContactInfo({
          primaryContactId,
          primaryContact,
          secondaryContactId,
          secondaryContact,
          technicalContactId,
          technicalContact,
          customerSupportNumber,

          primaryContactExisting,
          secondaryContactExisting,
          technicalContactExisting,
        })
      );
    }

    setActiveStage("about school");
  };

  const handleCreateBranch = async () => {
    // check if the technical details are filled otherwise dont send it to the backend
    const isTechnicalContactFilled =
      (technicalContactExisting && technicalContactId) ||
      (!technicalContactExisting &&
        technicalContact.email &&
        technicalContact.phoneNumber);

    const params = {
      institutionId: instId,
      body: isTechnicalContactFilled
        ? {
            ...aboutInstitutionData,
            // isNew: true,
            // ...contactPersonDetails,
            customerSupportNumber: customerSupportNumber,
            primaryContact: primaryContactExisting
              ? { id: primaryContactId }
              : { ...primaryContact },
            secondaryContact: secondaryContactExisting
              ? { id: secondaryContactId }
              : { ...secondaryContact },
            technicalContact: technicalContactExisting
              ? { id: technicalContactId }
              : { ...technicalContact },
          }
        : {
            ...aboutInstitutionData,
            // isNew: true,
            // ...contactPersonDetails,
            customerSupportNumber: customerSupportNumber,
            primaryContact: primaryContactExisting
              ? { id: primaryContactId }
              : { ...primaryContact },
            secondaryContact: secondaryContactExisting
              ? { id: secondaryContactId }
              : { ...secondaryContact },
          },
    };
    try {
      const res = await addBranch(params).unwrap();
      console.log(res);
      setShowCreateBranchCanvas(false);
      // setContactPersonDetails({});
      dispatch(updateContactInfo(null));
      dispatch(updateAboutSchool(null));
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  useEffect(() => {
    setPrimaryContactExisting(
      aboutContactPerson
        ? aboutContactPerson?.primaryContactExisting
        : primaryContactExisting
    );
    setSecondaryContactExisting(
      aboutContactPerson
        ? aboutContactPerson?.secondaryContactExisting
        : secondaryContactExisting
    );
    setTechnicalContactExisting(
      aboutContactPerson
        ? aboutContactPerson?.technicalContactExisting
        : technicalContactExisting
    );
    setPrimaryContactId(
      aboutContactPerson ? aboutContactPerson?.primaryContactId : ""
    );
    setSecondaryContactId(
      aboutContactPerson ? aboutContactPerson?.secondaryContactId : ""
    );
    setTechnicalContactId(
      aboutContactPerson ? aboutContactPerson?.technicalContactId : ""
    );
  }, [aboutContactPerson]);

  if (addBranchLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <div>
        <div style={{ display: "flex", gap: 0.8 + "rem" }}>
          <div>
            <svg
              width="56"
              height="56"
              viewBox="0 0 68 69"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M67.5 34.5C67.5 53.1396 52.3896 68.25 33.75 68.25C15.1104 68.25 0 53.1396 0 34.5C0 15.8604 15.1104 0.75 33.75 0.75C52.3896 0.75 67.5 15.8604 67.5 34.5ZM8.4375 34.5C8.4375 48.4797 19.7703 59.8125 33.75 59.8125C47.7297 59.8125 59.0625 48.4797 59.0625 34.5C59.0625 20.5203 47.7297 9.1875 33.75 9.1875C19.7703 9.1875 8.4375 20.5203 8.4375 34.5Z"
                fill="#E7EAEE"
                fill-opacity="0.8"
              />
              <path
                d="M67.5 34.5C67.5 53.1396 52.3896 68.25 33.75 68.25C15.1104 68.25 0 53.1396 0 34.5C0 15.8604 15.1104 0.75 33.75 0.75C52.3896 0.75 67.5 15.8604 67.5 34.5ZM8.4375 34.5C8.4375 48.4797 19.7703 59.8125 33.75 59.8125C47.7297 59.8125 59.0625 48.4797 59.0625 34.5C59.0625 20.5203 47.7297 9.1875 33.75 9.1875C19.7703 9.1875 8.4375 20.5203 8.4375 34.5Z"
                fill="#F14810"
              />
              <path
                d="M19.32 40L24.408 34.688C24.696 34.3787 24.9413 34.0907 25.144 33.824C25.3467 33.5573 25.5013 33.2907 25.608 33.024C25.7147 32.7573 25.768 32.48 25.768 32.192C25.768 31.9893 25.7307 31.792 25.656 31.6C25.5813 31.408 25.4693 31.2373 25.32 31.088C25.1707 30.9387 24.9893 30.8213 24.776 30.736C24.5627 30.64 24.3173 30.592 24.04 30.592C23.6453 30.592 23.304 30.688 23.016 30.88C22.7387 31.0613 22.52 31.3227 22.36 31.664C22.2107 32.0053 22.136 32.4 22.136 32.848H19.896C19.896 32.048 20.056 31.328 20.376 30.688C20.696 30.048 21.1653 29.5413 21.784 29.168C22.4133 28.784 23.1813 28.592 24.088 28.592C24.76 28.592 25.3467 28.6987 25.848 28.912C26.36 29.1147 26.7813 29.3867 27.112 29.728C27.4533 30.0587 27.704 30.4267 27.864 30.832C28.0347 31.2373 28.12 31.6427 28.12 32.048C28.12 32.6453 27.976 33.2053 27.688 33.728C27.4 34.2507 27.0213 34.7253 26.552 35.152L23.64 37.904H28.328V40H19.32ZM36.0865 28.8H38.0865L31.3665 42.4H29.3025L36.0865 28.8ZM38.6481 40L43.7361 34.688C44.0241 34.3787 44.2695 34.0907 44.4721 33.824C44.6748 33.5573 44.8295 33.2907 44.9361 33.024C45.0428 32.7573 45.0961 32.48 45.0961 32.192C45.0961 31.9893 45.0588 31.792 44.9841 31.6C44.9095 31.408 44.7975 31.2373 44.6481 31.088C44.4988 30.9387 44.3175 30.8213 44.1041 30.736C43.8908 30.64 43.6455 30.592 43.3681 30.592C42.9735 30.592 42.6321 30.688 42.3441 30.88C42.0668 31.0613 41.8481 31.3227 41.6881 31.664C41.5388 32.0053 41.4641 32.4 41.4641 32.848H39.2241C39.2241 32.048 39.3841 31.328 39.7041 30.688C40.0241 30.048 40.4935 29.5413 41.1121 29.168C41.7415 28.784 42.5095 28.592 43.4161 28.592C44.0881 28.592 44.6748 28.6987 45.1761 28.912C45.6881 29.1147 46.1095 29.3867 46.4401 29.728C46.7815 30.0587 47.0321 30.4267 47.1921 30.832C47.3628 31.2373 47.4481 31.6427 47.4481 32.048C47.4481 32.6453 47.3041 33.2053 47.0161 33.728C46.7281 34.2507 46.3495 34.7253 45.8801 35.152L42.9681 37.904H47.6561V40H38.6481Z"
                fill="#343434"
              />
            </svg>
          </div>
          <div>
            <h5 style={{ marginBottom: "0px" }}>Contact Person</h5>
            <p className="FormParagraph">
              Provide the following information about the school representatives
            </p>
          </div>
        </div>
      </div>
      <br />

      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Primary Contact Details <span style={{ color: "red" }}>*</span>
          </Accordion.Header>
          <Accordion.Body>
            <div style={{ marginTop: "15px" }}>
              <Form.Check
                inline
                label="New User"
                checked={primaryContactExisting == false}
                onClick={() => setPrimaryContactExisting(false)}
                type="radio"
                id={`primary-inline-radio-1`}
                size={25}
              />
              <Form.Check
                inline
                label="Existing User"
                checked={primaryContactExisting == true}
                onClick={() => setPrimaryContactExisting(true)}
                type="radio"
                id={`primary-inline-radio-1`}
                size={25}
              />
            </div>
            {!primaryContactExisting ? (
              <Form className="form" style={{ paddingTop: "10px" }}>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="formClasslabel">
                        First Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>

                      <Form.Control
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        type="text"
                        name="primaryContact.firstName"
                        value={primaryContact?.firstName}
                        onChange={handleOnChange}
                        placeholder="Enter First Name"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="formClasslabel">
                        Last Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>

                      <Form.Control
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        type="text"
                        name="primaryContact.lastName"
                        value={primaryContact?.lastName}
                        onChange={handleOnChange}
                        placeholder="Enter Last Name"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Form.Label className="formClasslabel">
                    Date of Birth
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </Form.Label>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Select
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        name="primaryContact.dateOfBirth.dayOfMonth"
                        value={primaryContact?.dateOfBirth?.dayOfMonth}
                        onChange={handleOnChange}
                        // required
                      >
                        <option value="" defaultValue disabled>
                          Select Day
                        </option>
                        {dates.map((date, index) => (
                          <option key={index} value={date}>
                            {date}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Select
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        name="primaryContact.dateOfBirth.month"
                        // value={primaryContact?.dateOfBirth?.month}
                        onChange={handleOnChange}
                        placeholder="Select Month"
                        required
                      >
                        <option value="" defaultValue disabled>
                          Select Month
                        </option>
                        {months.map((month, index) => (
                          <option key={index} value={month}>
                            {month}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">
                    Email Address <span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <Form.Control
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    type="email"
                    name="primaryContact.email"
                    value={primaryContact?.email}
                    onChange={handleOnChange}
                    placeholder="Enter Email Address"
                    required
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">
                    Phone Number <span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      id="basic-addon1"
                      style={{ backgroundColor: "#F14810", color: "#ffffff" }}
                    >
                      <TelephonePlus size={22} />
                    </InputGroup.Text>
                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="number"
                      name="primaryContact.phoneNumber"
                      value={primaryContact?.phoneNumber}
                      onChange={handleOnChange}
                      placeholder="Enter Phone number"
                      required
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">
                    Gender <span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <InputGroup className="mb-3" required>
                    <div key={`inline-radio`}>
                      <Form.Check
                        inline
                        label="Male"
                        name="primaryContact.gender"
                        checked={primaryContact?.gender === "male"}
                        onChange={handleOnChange}
                        value="male"
                        type="radio"
                        id={`inline-radio-1`}
                        size={25}
                        // style={{ backgroundColor: '#F14810', color: '#ffffff' }}
                      />
                      <Form.Check
                        inline
                        label="Female"
                        name="primaryContact.gender"
                        checked={primaryContact?.gender === "female"}
                        onChange={handleOnChange}
                        value="female"
                        type="radio"
                        id={`inline-radio-2`}
                        size={25}
                        style={{ variant: "outline-light" }}
                      />
                    </div>
                  </InputGroup>
                </Form.Group>
              </Form>
            ) : (
              <Form className="form" style={{ paddingTop: "10px" }}>
                <Form.Label className="formClasslabel">
                  Existing User <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Select
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  onChange={(e) => setPrimaryContactId(e.target.value)}
                  name="primaryContactId"
                  value={primaryContactId}
                >
                  <option value="" selected disabled>
                    Select Existing User
                  </option>
                  {allSchoolUsers?.map(({ id, firstName, lastName }) => (
                    <option
                      key={id}
                      selected={primaryContactId === id}
                      value={id}
                    >
                      {firstName ? firstName + " " + lastName : ""}
                    </option>
                  ))}
                </Form.Select>
              </Form>
            )}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Customer Care Details <span style={{ color: "red" }}>*</span>
          </Accordion.Header>
          <Accordion.Body>
            <Form className="form" style={{ paddingTop: "10px" }}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Phone Number <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <InputGroup className="mb-3">
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ backgroundColor: "#F14810", color: "#ffffff" }}
                  >
                    <TelephonePlus size={22} />
                  </InputGroup.Text>
                  <Form.Control
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    type="number"
                    name="customerSupportNumber"
                    value={customerSupportNumber}
                    onChange={handleOnChange}
                    placeholder="Enter Phone number"
                    required
                  />
                </InputGroup>
              </Form.Group>
            </Form>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>
            Accountant Contact Details <span style={{ color: "red" }}>*</span>
          </Accordion.Header>
          <Accordion.Body>
            <div style={{ marginTop: "15px" }}>
              <Form.Check
                inline
                label="New User"
                checked={secondaryContactExisting == false}
                onClick={() => setSecondaryContactExisting(false)}
                type="radio"
                id={`secondary-inline-radio-1`}
                size={25}
              />
              <Form.Check
                inline
                label="Existing User"
                checked={secondaryContactExisting == true}
                onClick={() => setSecondaryContactExisting(true)}
                type="radio"
                id={`secondary-inline-radio-1`}
                size={25}
              />
            </div>
            {!secondaryContactExisting ? (
              <Form className="form" style={{ paddingTop: "10px" }}>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="formClasslabel">
                        First Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>

                      <Form.Control
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        type="text"
                        name="secondaryContact.firstName"
                        value={secondaryContact?.firstName}
                        onChange={handleOnChange}
                        placeholder="Enter First Name"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="formClasslabel">
                        Last Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>

                      <Form.Control
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        type="text"
                        name="secondaryContact.lastName"
                        value={secondaryContact?.lastName}
                        onChange={handleOnChange}
                        placeholder="Enter Last Name"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Form.Label className="formClasslabel">
                    Date of Birth
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </Form.Label>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Select
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        name="secondaryContact.dateOfBirth.dayOfMonth"
                        value={secondaryContact?.dateOfBirth?.dayOfMonth}
                        onChange={handleOnChange}
                        // required
                      >
                        <option value="" defaultValue disabled>
                          Select Day
                        </option>
                        {dates.map((date, index) => (
                          <option key={index} value={date}>
                            {date}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Select
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        name="secondaryContact.dateOfBirth.month"
                        value={secondaryContact?.dateOfBirth?.month}
                        onChange={handleOnChange}
                        placeholder="Select Month"
                        required
                      >
                        <option value="" defaultValue disabled>
                          Select Month
                        </option>
                        {months.map((month, index) => (
                          <option key={index} value={month}>
                            {month}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">
                    Email Address <span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <Form.Control
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    type="email"
                    name="secondaryContact.email"
                    value={secondaryContact?.email}
                    onChange={handleOnChange}
                    placeholder="Enter Email Address"
                    required
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">
                    Phone Number <span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      id="basic-addon1"
                      style={{ backgroundColor: "#F14810", color: "#ffffff" }}
                    >
                      <TelephonePlus size={22} />
                    </InputGroup.Text>
                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="number"
                      name="secondaryContact.phoneNumber"
                      value={secondaryContact?.phoneNumber}
                      onChange={handleOnChange}
                      placeholder="Enter Phone number"
                      required
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">
                    Gender <span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <InputGroup className="mb-3" required>
                    <div key={`inline-radio`}>
                      <Form.Check
                        inline
                        label="Male"
                        name="secondaryContact.gender"
                        checked={secondaryContact?.gender === "male"}
                        onChange={handleOnChange}
                        value="male"
                        type="radio"
                        id={`inline-radio-1`}
                        size={25}
                        // style={{ backgroundColor: '#F14810', color: '#ffffff' }}
                      />
                      <Form.Check
                        inline
                        label="Female"
                        name="secondaryContact.gender"
                        checked={secondaryContact?.gender === "female"}
                        onChange={handleOnChange}
                        value="female"
                        type="radio"
                        id={`inline-radio-2`}
                        size={25}
                        style={{ variant: "outline-light" }}
                      />
                    </div>
                  </InputGroup>
                </Form.Group>
              </Form>
            ) : (
              <Form className="form" style={{ paddingTop: "10px" }}>
                <Form.Label className="formClasslabel">
                  Existing User <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Select
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  onChange={(e) => setSecondaryContactId(e.target.value)}
                  name="secondaryContactId"
                  value={secondaryContactId}
                >
                  <option value="" selected disabled>
                    Select Existing User
                  </option>
                  {allSchoolUsers?.map(({ id, firstName, lastName }) => (
                    <option
                      key={id}
                      value={id}
                      // onClick={() => setSecondaryContactId(id)}
                      selected={secondaryContactId === id}
                    >
                      {firstName ? firstName + " " + lastName : ""}
                    </option>
                  ))}
                </Form.Select>
              </Form>
            )}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>Technical Contact Details</Accordion.Header>
          <Accordion.Body>
            <div style={{ marginTop: "15px" }}>
              <Form.Check
                inline
                label="New User"
                checked={technicalContactExisting == false}
                onClick={() => setTechnicalContactExisting(false)}
                type="radio"
                id={`technical-inline-radio-1`}
                size={25}
              />
              <Form.Check
                inline
                label="Existing User"
                checked={technicalContactExisting == true}
                onClick={() => setTechnicalContactExisting(true)}
                type="radio"
                id={`technical-inline-radio-1`}
                size={25}
              />
            </div>
            {!technicalContactExisting ? (
              <Form className="form" style={{ paddingTop: "10px" }}>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="formClasslabel">
                        First Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>

                      <Form.Control
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        type="text"
                        name="technicalContact.firstName"
                        value={technicalContact?.firstName}
                        onChange={handleOnChange}
                        placeholder="Enter First Name"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="formClasslabel">
                        Last Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>

                      <Form.Control
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        type="text"
                        name="technicalContact.lastName"
                        value={technicalContact?.lastName}
                        onChange={handleOnChange}
                        placeholder="Enter Last Name"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Form.Label className="formClasslabel">
                    Date of Birth
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </Form.Label>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Select
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        name="technicalContact.dateOfBirth.dayOfMonth"
                        value={technicalContact?.dateOfBirth?.dayOfMonth}
                        onChange={handleOnChange}
                        // required
                      >
                        <option value="" defaultValue disabled>
                          Select Day
                        </option>
                        {dates.map((date, index) => (
                          <option key={index} value={date}>
                            {date}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Select
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        name="technicalContact.dateOfBirth.month"
                        value={technicalContact?.dateOfBirth?.month}
                        onChange={handleOnChange}
                        placeholder="Select Month"
                        required
                      >
                        <option value="" defaultValue disabled>
                          Select Month
                        </option>
                        {months.map((month, index) => (
                          <option key={index} value={month}>
                            {month}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">
                    Email Address <span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <Form.Control
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    type="email"
                    name="technicalContact.email"
                    value={technicalContact?.email}
                    onChange={handleOnChange}
                    placeholder="Enter Email Address"
                    required
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">
                    Phone Number <span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      id="basic-addon1"
                      style={{ backgroundColor: "#F14810", color: "#ffffff" }}
                    >
                      <TelephonePlus size={22} />
                    </InputGroup.Text>
                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="number"
                      name="technicalContact.phoneNumber"
                      value={technicalContact?.phoneNumber}
                      onChange={handleOnChange}
                      placeholder="Enter Phone number"
                      required
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">
                    Gender <span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <InputGroup className="mb-3" required>
                    <div key={`inline-radio`}>
                      <Form.Check
                        inline
                        label="Male"
                        name="technicalContact.gender"
                        checked={technicalContact?.gender === "male"}
                        onChange={handleOnChange}
                        value="male"
                        type="radio"
                        id={`inline-radio-1`}
                        size={25}
                        // style={{ backgroundColor: '#F14810', color: '#ffffff' }}
                      />
                      <Form.Check
                        inline
                        label="Female"
                        name="technicalContact.gender"
                        checked={technicalContact?.gender === "female"}
                        onChange={handleOnChange}
                        value="female"
                        type="radio"
                        id={`inline-radio-2`}
                        size={25}
                        style={{ variant: "outline-light" }}
                      />
                    </div>
                  </InputGroup>
                </Form.Group>
              </Form>
            ) : (
              <Form className="form" style={{ paddingTop: "10px" }}>
                <Form.Label className="formClasslabel">
                  Existing User <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Select
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  onChange={(e) => setTechnicalContactId(e.target.value)}
                  name="technicalContactId"
                  value={technicalContactId}
                >
                  <option value="" selected disabled>
                    Select Existing User
                  </option>
                  {allSchoolUsers?.map(({ id, firstName, lastName }) => (
                    <option
                      key={id}
                      value={id}
                      // onClick={() => setTechnicalContactId(id)}
                      selected={technicalContactId === id}
                    >
                      {firstName ? firstName + " " + lastName : ""}
                    </option>
                  ))}
                </Form.Select>
              </Form>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div style={{ display: "flex", gap: 1.1 + "rem", marginTop: "30px" }}>
        <Button
          variant="outline-primary"
          style={{ borderColor: "#F14810", color: "#F14810" }}
          className="button"
          size="md"
        >
          <span
            style={{
              fontSize: 0.8 + "rem",
              marginLeft: 0.5 + "rem",
              marginRight: 0.5 + "rem",
            }}
            onClick={() => handleGoToPrevPage()}
          >
            Previous
          </span>
        </Button>

        <Button
          variant="primary"
          style={{
            backgroundColor: "#F14810",
            borderColor: "#F14810",
            float: "left",
          }}
          className="button"
          size="md"
          // type="submit"
        >
          <span
            style={{
              fontSize: 0.8 + "rem",
              marginLeft: 0.5 + "rem",
              marginRight: 0.5 + "rem",
            }}
            onClick={handleCreateBranch}
          >
            Done
          </span>
        </Button>
      </div>
    </>
  );
};

export default ContactPerson;
