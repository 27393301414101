import React from "react";
import { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import TableComponent from "../../../../../component/table/table";
import DashboardSidebar from "../dashboardSidebar/sidebar";
import DashboardTopSection from "../dashboardTopSection";
import createStudentVector from "../../../../../assets/svgs/createAstudentIcon.svg";
import ButtonX from "../../../../../component/button/button";
import totalStudentsIcon from "../../../../../assets/svgs/totalStudentsIcon.svg";
import maleStudentIcon from "../../../../../assets/svgs/maleStudentIcon.svg";
import femaleStudentIcon from "../../../../../assets/svgs/femaleStudentIcon.svg";
import backArrow from "../../../../../assets/svgs/backArrow.svg";
import { Offcanvas, Row, Col, Container, Form } from "react-bootstrap";
import StudentInformationStepper from "./studentInformation/studentInformationStepper";
import UploadStudentsPage from "./studentInformation/components/uploadStudents/uploadStudentsPage";
import {
  useGetApprovedStudentsQuery,
  useGetStudentsRegisterQuery,
  useGetStudentRegisterDetailQuery,
  usePromoteStudentsMutation,
} from "../../../../../redux/features/institution/studentApiSlice";
import { useSelector } from "react-redux";
import "./students.css";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import { Link } from "react-router-dom";
import {
  useGetInstitutionQuery,
  useGetAllClassesQuery,
} from "../../../../../redux/features/institution/institutionApiSlice";
import { useNavigate } from "react-router-dom";
import ToastComponent from "../../../../../component/toast/toast";
import ModalComponent from "../../../../../component/modal/modal";
import { addTrailingCommas } from "../../../../../utils/utils";
import ActionIcon from "../../../../superAdmin/components/actionbutton/ActionIcon";
import StudentFullDetailsPage from "./components/studentFullDetails";
import alertIcon from "../../../../../assets/svgs/alertIcon.svg";
import successGIF from "../../../../../assets/svgs/successGIF.gif";

const ApprovedStudents = () => {
  const [students, setStudents] = useState(0);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showCreateStudentCanvas, setShowCreateStudentCanvas] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  const [showPromoteModal, setShowPromoteModal] = useState(false);
  const [allClasses, setAllClasses] = useState([]);
  const [askCreateStudentMethod, setAskCreateStudentMethod] = useState(false);

  const [showConfirmPromote, setShowConfirmPromote] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [isStudentViewed, setIsStudentViewed] = useState(false);
  const [viewedStudentDetails, setViewedStudentDetails] = useState(null);

  const handleCloseStudent = () => {
    setShowCreateStudentCanvas(false);
  };

  const handleShowStudent = () => {
    setShowCreateStudentCanvas(true);
    setShowUploadModal(false);
    setAskCreateStudentMethod(false);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const handleCloseUpload = () => {
    setShowUploadModal(false);
  };

  const handleShowUpload = () => {
    setShowUploadModal(true);
    setShowCreateStudentCanvas(false);
    setAskCreateStudentMethod(false);
  };

  const handleSelectedRows = (selectedRowsData) => {
    setSelectedRows(selectedRowsData);
  };

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  const {
    data: approvedStudents,
    refetch: refetchApprovedStudents,
    isLoading: studentsLoading,
  } = useGetApprovedStudentsQuery(instId);

  console.log(approvedStudents);

  const {
    data: studentRegister,
    refetch: refetchStudentsRegisters,
    isLoading: registersLoading,
  } = useGetStudentsRegisterQuery(instId, { skip: !approvedStudents });

  const { data: institutionDetails } = useGetInstitutionQuery(instId);
  const { data: classes, isLoading: classesLoading } =
    useGetAllClassesQuery(instId);
  const [promoteStudents, { isLoading: promoteLoading }] =
    usePromoteStudentsMutation();

  const navigate = useNavigate();
  // console.log(studentRegister);

  const columns = [
    // { name: "id", label: "ID" },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { firstname, middlename, lastname } =
            approvedStudents[tableMeta.rowIndex];
          return `${firstname} ${middlename ? middlename : " "} ${lastname}`;
        },
      },
    },
    {
      name: "institutionCategory",
      label: "Class",
      options: {
        customBodyRender: (value) =>
          value?.institutionLevel?.name + " " + value?.name,
      },
    },
    { name: "gender", label: "Gender" },
    { name: "studentNo", label: "Student No" },
    { name: "nuban", label: "Nuban" },
    { name: "bankName", label: "Bank Name" },
    {
      name: "walletBalance",
      label: "Wallet Balance",
      // options: {
      //   customBodyRender: (walletBalance) =>
      //     walletBalance ? `${"₦ " + addTrailingCommas(walletBalance)}` : "N/A",
      // },
      options: {
        customBodyRender: (value, tableMeta) => {
          const { walletBalance } = approvedStudents[tableMeta.rowIndex];
          return `${"₦ " + addTrailingCommas(walletBalance)}`;
        },
      },
    },
    { name: "phoneNo", label: "Student Phone" },
    {
      name: "parent",
      label: "Parent Name",
      options: {
        customBodyRender: (parent) =>
          parent && parent.name ? parent.name : "N/A",
      },
    },
    { name: "status", label: "Status" },
    {
      name: "actions",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <ActionIcon
            actionsArr={[
              {
                label: "View",
                action: () => {
                  setIsStudentViewed(!isStudentViewed);
                  setViewedStudentDetails(approvedStudents[tableMeta.rowIndex]);
                },
              },
            ]}
          />
        ),
      },
    },
  ];

  const [newPromotionData, setNewPromotionData] = useState({
    categoryId: "",
    // calendarId: "",
    studentIds: "",
  });

  const { categoryId, studentIds } = newPromotionData;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setNewPromotionData({ ...newPromotionData, [name]: value });
  };

  const handlePromote = async () => {
    try {
      const params = {
        institutionId: instId,
        body: {
          categoryId: categoryId,
          // calendarId: calendarId,
          studentIds: selectedRows?.map((row) => row.id),
        },
      };

      const res = await promoteStudents(params).unwrap();

      if (res) {
        console.log(res);
        // setShowToast(true);
        // setToastMessage("Student(s) promoted successfully!");
        setShowSuccessModal(true);
        setShowPromoteModal(false);
        setShowConfirmPromote(false);
        refetchApprovedStudents();
        setSelectedRows([]);
      }
    } catch (error) {
      // alert(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  useEffect(() => {
    if (approvedStudents) {
      refetchApprovedStudents();
    }
  }, [showUploadModal === false, showCreateStudentCanvas === false]);

  useEffect(() => {
    if (studentRegister) {
      refetchStudentsRegisters();
    }
  }, [showUploadModal === false, showCreateStudentCanvas === false]);

  useEffect(() => {
    if (classes) {
      const allCategories = Object.keys(classes)
        .map((key) => {
          return classes[key].map((eachClass) => {
            return eachClass.categories.map((category) => {
              return {
                name: `${eachClass.name + " - " + category.name}`,
                id: category.id,
              };
            });
          });
        })
        .flat();

      console.log(allCategories.flat());
      setAllClasses(allCategories.flat());
    }
  }, [classes]);

  if (studentsLoading || registersLoading || promoteLoading || classesLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div style={{ display: "flex" }}>
      <DashboardSidebar institutionDetails={institutionDetails} />

      <Container
        fluid
        className="bodycontainer"
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <DashboardTopSection />

        {isStudentViewed ? (
          <StudentFullDetailsPage
            studentDetails={viewedStudentDetails}
            setIsStudentViewed={setIsStudentViewed}
          />
        ) : (
          <section
            style={{
              marginTop: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                  marginBottom: "10px",
                }}
                onClick={() => navigate("/student")}
              >
                <img src={backArrow} alt="Go back" />
                <h6 style={{ marginBottom: 0 }}>Student List</h6>
              </div>

              <div style={{ display: "flex", gap: "5px" }}>
                <ButtonX
                  onClick={
                    !selectedRows || selectedRows?.length === 0
                      ? null
                      : () => setShowPromoteModal(true)
                  }
                  backgroundColor={`${
                    !selectedRows || selectedRows?.length === 0
                      ? "#FFCAB8"
                      : "#F14810"
                  }`}
                  overlay={true}
                  overlayText="Select student(s) to promote then click this button to promote"
                >
                  Promote
                </ButtonX>

                <ButtonX
                  backgroundColor="#fff"
                  border="#f14810"
                  color="#f14810"
                  onClick={() => setAskCreateStudentMethod(true)}
                >
                  Create Students
                </ButtonX>
              </div>
            </div>

            <TableComponent
              data={approvedStudents}
              title=""
              selectableRows="true"
              onRowSelectionChange={handleSelectedRows}
              columns={columns}
              search={true}
            />

            <br />
            <br />
          </section>
        )}

        {/* promote modal */}
        <ModalComponent
          title={<h6 style={{ color: "#F14810" }}>Promote Students</h6>}
          showModal={showPromoteModal}
          closeModal={() => setShowPromoteModal(false)}
        >
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">Class</Form.Label>

              <Form.Select
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                name="categoryId"
                value={categoryId}
                onChange={handleOnChange}
              >
                <option value="" defaultValue disabled>
                  Select Class
                </option>
                {allClasses?.map(({ id, name }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            {/* use this for calendar  */}
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">Class</Form.Label>

              <Form.Select
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                name="categoryId"
                value={categoryId}
                onChange={handleOnChange}
              >
                <option value="" defaultValue disabled>
                  Select Class
                </option>
                {allClasses?.map(({ id, name }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group> */}

            <div style={{ marginTop: "30px" }}>
              <ButtonX
                backgroundColor={`${!categoryId ? "#FFCAB8" : "#F14810"}`}
                border={`${!categoryId ? "#FFCAB8" : "#F14810"}`}
                color="#fff"
                onClick={(e) => setShowConfirmPromote(true)}
              >
                Save
              </ButtonX>
            </div>
          </Form>
        </ModalComponent>

        {/* promote confirmation modal */}
        <ModalComponent
          showModal={showConfirmPromote}
          closeModal={() => setShowConfirmPromote(false)}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={alertIcon}
              alt="alert Icon"
              style={{ margin: "0 auto" }}
            />
            <p
              style={{
                marginTop: "10px",
                marginBottom: "5px",
                fontWeight: "500",
                fontSize: 1.0 + "rem",
              }}
            >
              Are you sure you want to promote the selected student(s)?{" "}
            </p>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                gap: "10px",
                marginLeft: "30%",
              }}
            >
              <Button
                onClick={() => setShowConfirmPromote(false)}
                type="button"
                variant="outline-primary"
                style={{ borderColor: "#F14810", color: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  No
                </span>
              </Button>
              <Button
                onClick={() => handlePromote()}
                type="button"
                variant="primary"
                style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Yes
                </span>
              </Button>
            </div>
          </div>
        </ModalComponent>

        {/* Promote Success Modal */}
        <ModalComponent
          showModal={showSuccessModal}
          closeModal={handleCloseSuccessModal}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={successGIF}
              alt="success Gif"
              width="150px"
              style={{ margin: "0 auto" }}
            />
            <h3 style={{ color: "#F14810", fontWeight: "600" }}>
              Successful!!!
            </h3>
            <p style={{ fontSize: "18px" }}>
              Student(s) promoted successfully!
            </p>
          </div>
        </ModalComponent>

        {/* prompt for create student */}
        <ModalComponent
          showModal={askCreateStudentMethod}
          closeModal={() => setAskCreateStudentMethod(false)}
        >
          <div style={{ padding: "15px" }}>
            <h5
              style={{
                textAlign: "center",
                color: "#626262",
                fontSize: "25px",
              }}
            >
              Create Students
            </h5>
            <p style={{ textAlign: "center" }}>
              Creating students can be done in 2 ways; You can either click on
              "Upload Students" button to add existing students record to the
              platform OR click on "Create Students" button to add a new student
              record.{" "}
            </p>
            <div
              style={{ display: "flex", gap: "10px", justifyContent: "center" }}
            >
              <ButtonX
                backgroundColor="#f14810"
                border="#f14810"
                color="#fff"
                onClick={handleShowUpload}
              >
                Upload Students
              </ButtonX>
              <ButtonX
                backgroundColor="#fff"
                border="#f14810"
                color="#f14810"
                onClick={handleShowStudent}
              >
                Create Students
              </ButtonX>
            </div>
          </div>
        </ModalComponent>

        <Offcanvas
          show={showCreateStudentCanvas}
          onHide={handleCloseStudent}
          placement={"end"}
          backdrop={true}
          style={{ width: "100%", maxWidth: "500px" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Create Students</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ paddingTop: "0px" }}>
            {/* <StudentInformationPage /> */}
            <StudentInformationStepper closeCanvas={handleCloseStudent} />
          </Offcanvas.Body>
        </Offcanvas>

        <Offcanvas
          show={showUploadModal}
          onHide={handleCloseUpload}
          placement={"end"}
          backdrop={true}
          style={{ width: "100%", maxWidth: "500px" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Upload Students</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <UploadStudentsPage
              showUploadModal={showUploadModal}
              setShowUploadModal={setShowUploadModal}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </Container>

      {/* <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      /> */}
    </div>
  );
};

export default ApprovedStudents;
