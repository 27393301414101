import React, { useEffect, useState } from 'react'
import "./setInstitution.css";

import logo from "../../../assets/images/logo.svg"
import dropdownIcon from "../../../assets/svgs/dropdownIcon.svg";
import userDisplayPhoto from "../../../assets/images/userDisplayPhoto.png";
import { useGetInstitutionQuery } from '../../../redux/features/institution/institutionApiSlice';
import { useGetCountriesQuery } from "../../../redux/features/auth/authApiSlice"
import InstitutionDetails from './components/institutionDetails/institutionDetails';
import InstitutionBranding from './components/institutionBranding/institutionBranding';
import ContactPerson from './components/contactPerson/contactPerson';
import UploadCredentials from './components/uploadCredentials/uploadCredentials';
import { useSelector, useDispatch } from 'react-redux';
import { logOut } from '../../../redux/features/auth/authSlice';
import { capitalizeFirstLetter } from '../../../utils/utils';


const setupInstitutionStages = [
  // { stage: "Institution Details" },
  { stage: "Institution Branding" },
  // { stage: "Contact Person" },
  { stage: "Upload Credentials" },
];

const SetupInstitutionPage = () => {

  const [activeStage, setActiveStage] = useState("Institution Branding");
  const [feedback, setFeedback] = useState(null)
  // const [showMenu, setShowMenu] = useState(false);
  const [dropdownProfile, setDropdownProfile] = useState(false);

  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(logOut());
  };

  console.log(dropdownProfile)

  const id = useSelector((state) => state.auth.userInfo.institutionId);
  const username = useSelector((state) => state.auth.userInfo.username);

  const { data: institutionDetails } = useGetInstitutionQuery(id);
  // console.log(institutionDetails)

  useEffect(() => {
    if (institutionDetails?.status === "PENDING") {
      setFeedback(institutionDetails?.remark);
    }
  }, [institutionDetails])

  return (
    <div className="setup-your-institution-page">
      <div>
        <img src={logo} alt="Company logo" style={{ maxWidth: "145px" }} />
        <div className="stagesContainer">
          {setupInstitutionStages.map((setupStage, index) => (
            <div
              className="stageContainer"
              key={index}
            // onClick={() => setActiveStage(setupStage.stage)}
            >
              <div className="roundedBox">
                <span
                  className={`${activeStage === setupStage.stage ? "activeBox" : ""
                    }`}
                ></span>
                {index !== setupInstitutionStages.length - 1 && (
                  <div className="connectors"></div>
                )}
              </div>
              <span
                className={`stage ${activeStage === setupStage.stage ? "active" : ""
                  }`}
              >
                {setupStage.stage}
              </span>
            </div>
          ))}
        </div>
        {/* error message box */}
        {feedback && (
          <div
            style={{
              maxHeight: "150px",
              width: "100%",
              overflow: "auto",
              border: "1px solid red",
              marginTop: "auto",
              padding: "15px",
              borderRadius: "10px",
            }}
          >
            <h4>Feedback:</h4>
            <p style={{ fontSize: "16px" }}>{feedback}</p>
          </div>
        )}
      </div>

      <div className="no-scrollbar">
        <div className="dashboard-top-section" style={{
          position: "relative"
        }}>
          <div
            className="user-details"
          >
            <div
              onClick={() => setDropdownProfile(!dropdownProfile)}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer",
              }}
            >
              {institutionDetails && (
                <span>
                  Hi,
                  {capitalizeFirstLetter(
                    // institutionDetails?.createdBy?.firstName
                    username
                  )}
                </span>
              )}
              <div
                style={{ height: "40px", width: "40px", borderRadius: "100%" }}
              >
                <img
                  src={userDisplayPhoto}
                  alt="user"
                  style={{ width: "100%" }}
                />
              </div>
              <img src={dropdownIcon} alt="drop down" />
            </div>
          </div>
          {dropdownProfile && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                right: 0,
                zIndex: 10,
                width: "130px",
                backgroundColor: "#fff2ee",
                padding: "15px",
              }}
              className="profile-dropdown"
            >
              <span
                onClick={handleLogOut}
                style={{ cursor: "pointer", fontWeight: 500 }}
              >
                LogOut
              </span>
            </div>
          )}
        </div>
        <div>
          {/* {activeStage === "Institution Details" && (
            <InstitutionDetails setActiveStage={setActiveStage} />
          )} */}
          {activeStage === "Institution Branding" && (
            <InstitutionBranding setActiveStage={setActiveStage} />
          )}
          {/* {activeStage === "Contact Person" && (
            <ContactPerson setActiveStage={setActiveStage} />
          )} */}
          {activeStage === "Upload Credentials" && (
            <UploadCredentials setActiveStage={setActiveStage} />
          )}
        </div>
      </div>
    </div>
  );
}

export default SetupInstitutionPage