import "./institutionBranding.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import institutionLogo from "../../../../../assets/svgs/institutionPlaceholderLogo.svg";
import placeholderLogo from "../../../../../assets/svgs/institutionPlaceholderLogo.svg"
// import Button from "../../../../../component/button/button"; 
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { storeBrandingDetails } from "../../../../../redux/features/institution/institutionSlice";
import { useGetInstitutionQuery, useGetInstitutionLogoQuery } from "../../../../../redux/features/institution/institutionApiSlice";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import { SketchPicker } from "react-color";




const InstitutionBranding = ({ setActiveStage, color, onChange }) => {

  const [isFormNotFilled, setIsFormNotFilled] = useState(false)
  const [showPicker, setShowPicker] = useState(false)


  //  get details if they have filled this page before
  const brandingDetails = useSelector(
    (state) => state.institution.brandingDetails
  );


  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  const { data: institution, isLoading } = useGetInstitutionQuery(instId, {
    skip: !instId,
  });
  const { data: apiInstitutionLogo, isLoading: logoLoading } = useGetInstitutionLogoQuery(instId)
  console.log(brandingDetails)
  console.log(institution);
  // console.log(apiInstitutionLogo);

  const dispatch = useDispatch();

  const [chosenColor, setChosenColor] = useState(
    brandingDetails ? brandingDetails.institutionBranding.brandColour : ""
  );

  const [chosenImage, setChosenImage] = useState(
    brandingDetails
      ? brandingDetails.institutionBranding.logo
      : ""
  );

  // console.log(brandingDetails)


  const [setupInstitutionInfo, setSetupInstitutionInfo] = useState({
    institutionBranding: {
      brandColour: brandingDetails
        ? brandingDetails?.institutionBranding?.brandColour
        : chosenColor,
      logo: brandingDetails
        ? brandingDetails?.institutionBranding?.logo
        : apiInstitutionLogo?.logo,
    },
  });

  const institutionDetails = useSelector(
    (state) => state.institution.institutionDetails
  );
  // console.log(institutionDetails)


  const { institutionBranding } = setupInstitutionInfo;



  useEffect(() => {
    if (institution && apiInstitutionLogo) {
      console.log(institution);
      setSetupInstitutionInfo((prevInfo) => ({
        //  ...prevInfo,
        institutionBranding: {
          brandColour: brandingDetails
            ? brandingDetails?.institutionBranding.brandColour
            : institution?.brandColour,
          logo: brandingDetails
            ? brandingDetails?.institutionBranding.logo
            : apiInstitutionLogo?.logo,
        },
      }));
      setChosenImage(apiInstitutionLogo?.logo);
    }
  }, [institution, apiInstitutionLogo]);

  useEffect(() => {
    setChosenColor(institutionBranding?.brandColour ? institutionBranding?.brandColour : institution?.brandColour);
  }, [])


  const handleOnChange = (e) => {
    const { name, value, type, files } = e.target;
    // handle the keys that are nested in objects. E.g contactPerson.firstName

    const [objectKey, nestedKey] = name.split(".");
    // If we can split the name by "." it means it is nested
    // spread all the previous data in the entire object, then target the particular object that needs to be mutated. spread all of its contents, then mutate the particular key needed

    if (nestedKey) {
      setSetupInstitutionInfo((previousData) => ({
        ...previousData,
        [objectKey]: { ...previousData[objectKey], [nestedKey]: value },
      }));
    } else {
      setSetupInstitutionInfo({ ...setupInstitutionInfo, [name]: value });
    }

    let selectedFile = e.target.files;

    if (selectedFile.length > 0) {
      let actualFile = selectedFile[0];
      let fileName = actualFile.name;
      const reader = new FileReader();

      reader.onload = (event) => {
        const imageDetails = {
          name: fileName,
          imageUrl: event.target.result,
        };
        setChosenImage(event.target.result);
      };
      reader.readAsDataURL(actualFile);
    }
  };

  const handleColorChange = (color) => {
    setChosenColor(color?.hex);
    institutionBranding.brandColour = color?.hex;
  };

  const handleGoToPrevPage = () => {
    setActiveStage("Institution Details");
    // dispatch and store so we can get these details even when user comes back to the page
    // dispatch(storeBrandingDetails(setupInstitutionInfo));
    dispatch(
      storeBrandingDetails({
        // institutionBranding: { ...institutionBranding, logo: chosenImage },
        institutionBranding: { brandColour: chosenColor, logo: chosenImage },
      })
    );
  }

  console.log(institutionBranding)
  console.log(institutionBranding.brandColour);
  console.log(chosenColor);


  const handleGoToNextPage = () => {

    if (
      !institutionBranding.brandColour ||
      !institutionBranding.logo
    ) {
      setIsFormNotFilled(true);

      setTimeout(() => {
        setIsFormNotFilled(false);
      }, 3000);

      return;
    }

    // setActiveStage("Contact Person");
    setActiveStage("Upload Credentials");
    // dispatch and store so we can get these details even when user comes back to the page
    // dispatch(storeBrandingDetails(setupInstitutionInfo));
    dispatch(storeBrandingDetails({
      // institutionBranding: {...institutionBranding, logo: chosenImage}
      institutionBranding: { brandColour: chosenColor, logo: chosenImage }
    }));
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Fill the required fields then click this button to save your changes
    </Tooltip>
  );

  if (isLoading || logoLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div className="school-branding no-scrollbar">
      <h2>Set Up Your Institution</h2>
      <p>Institution Branding</p>

      {isFormNotFilled && (
        <span
          style={{
            color: "red",
            fontStyle: "italic",
            transition: "ease-in-out",
          }}
        >
          Please fill all fields
        </span>
      )}

      <form className="school-branding">
        <div className="logo-btn-container">
          <div className="logo-container">
            {/* {chosenImage !== "" ? (
              <img src={chosenImage} alt="Institution Logo" />
            ) : (
              <img src={institutionLogo} alt="institution Placeholder Logo" />
            )} */}

            <img
              src={chosenImage !== "" ? chosenImage : placeholderLogo}
              alt="Logo"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <label htmlFor="uploadLogo">Upload Logo</label>
          <input
            type="file"
            id="uploadLogo"
            style={{ visibility: "hidden" }}
            name="institutionBranding.logo"
            // value={institutionBranding.logo}
            onChange={handleOnChange}
          />
        </div>
        <span>Select Color</span>
        <div className="colors-container">
          {/* {colors.map((color, i) => (
            <div
              key={i}
              className={`colorBox color-${color.colorHex} ${
                color.colorHex === chosenColor ? "chosen" : ""
              }`}
              name="institutionBranding.brandColour"
              value={color.colorHex}
              onChange={handleOnChange}
              onClick={() => handleColorChange(color.colorHex)}
            ></div>
          ))} */}

          <SketchPicker
            color={chosenColor}
            value={institutionBranding.brandColour}
            name="institutionBranding.brandColour"
            onChange={handleColorChange}
            onClick={() => handleColorChange(chosenColor)}
          />

          <div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "100%",
              backgroundColor: `${
                institutionBranding.brandColour
                  ? chosenColor
                  : institution?.brandColour
              }`,
            }}
          ></div>
          <span>School Color</span>
        </div>
        <div style={{ marginTop: "30px", display: "flex", gap: "15px" }}>
          {/* <Button
            onClick={handleGoToPrevPage}
            variant="outline-primary"
            style={{ borderColor: "#F14810", color: "#F14810" }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              Previous
            </span>
          </Button> */}
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={renderTooltip}
          >
            <Button
              variant="primary"
              onClick={
                !institutionBranding.brandColour || !institutionBranding.logo
                  ? null
                  : () => handleGoToNextPage()
              }
              style={{
                backgroundColor: `${
                  !institutionBranding.brandColour || !institutionBranding.logo
                    ? "#FFCAB8"
                    : "#F14810"
                }`,
                borderColor: `${
                  !institutionBranding.brandColour || !institutionBranding.logo
                    ? "#FFCAB8"
                    : "#F14810"
                }`,
                cursor: `${
                  !institutionBranding.brandColour || !institutionBranding.logo
                    ? "not-allowed"
                    : "pointer"
                }`,
                float: "left",
              }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Save & Continue
              </span>
            </Button>
          </OverlayTrigger>

          {/* <Button onClick={handleGoToNextPage}>Save & Continue</Button> */}
        </div>
      </form>
    </div>
  );
};

export default InstitutionBranding;
