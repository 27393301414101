import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table, Container, Col, Row } from "react-bootstrap";
import logo from "../../assets/svgs/logo.svg";
import copyIcon from "../../assets/svgs/copyIcon.svg";
import "./parent.css";
import {
    useGetReminderDetailsQuery,
} from "../../redux/features/institution/parentApiSlice";
import OnboardingLoader from "../../component/onboardingLoader/onboardingLoader";
import { capitalizeWords } from "../../utils/utils";
import { Button } from "react-bootstrap";
import ToastComponent from "../../component/toast/toast";

const NotificationsPage = () => {

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const { param } = useParams();
    const {
        data: details,
        isLoading,
        refetch,
    } = useGetReminderDetailsQuery(param, {
        skip: !param,
    });




    function invertColor(color) {
        // Convert the color to RGB
        let r, g, b;

        if (color?.length === 4) {
            // Handle 3-digit hex colors (#abc)
            r = parseInt(color[1] + color[1], 16);
            g = parseInt(color[2] + color[2], 16);
            b = parseInt(color[3] + color[3], 16);
        } else if (color?.length === 7) {
            // Handle 6-digit hex colors (#aabbcc)
            r = parseInt(color.slice(1, 3), 16);
            g = parseInt(color.slice(3, 5), 16);
            b = parseInt(color.slice(5, 7), 16);
        }

        // Calculate relative luminance
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        // Decide whether to invert based on luminance
        return luminance > 0.9 ? "#000000" : color;
    }

    if (isLoading) {
        return <OnboardingLoader />;
    }

    return (
        <div style={{ backgroundColor: "#dbd6d4", minHeight: "100vh" }}>
            {/* notification toast */}
            <ToastComponent
                showToast={showToast}
                setShowToast={setShowToast}
                toastMessage={toastMessage}
            />

            <div style={{ backgroundColor: "#ffffff", padding: "20px 0" }}>
                <div
                    style={{
                        width: "85%",
                        margin: "0 auto",
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "100%",
                            overflow: "hidden",
                        }}
                    >
                        <img
                            src={details?.logo}
                            alt="Adept Logo"
                            style={{ width: "100%", height: "100%" }}
                        />
                    </div>

                    {/* <h4>{details?.institutionName}</h4> */}
                    <h4 style={{ marginBottom: "0", marginLeft: "20px" }}>
                        {capitalizeWords(details?.reminder?.institution?.institutionName)}
                    </h4>

                </div>
            </div>
            <section
                style={{
                    display: "flex",
                    gap: "30px",
                    width: "85%",
                    margin: "0 auto",
                    padding: "30px 0",
                }}
                className="parent-page-container"
            >
                <div style={{ flexBasis: "30%" }}>
                    <div>
                        <h6 style={{ fontWeight: 500 }}>Notification Details</h6>
                        <div style={{ borderRadius: "15px", backgroundColor: "white" }}>
                            <div
                                style={{
                                    padding: "15px",
                                    borderBottom: "1px solid #F3F3F3",
                                }}
                            >
                                <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                                    {capitalizeWords(details?.reminder?.messageTitle)}
                                </h5>
                                <p style={{ marginBottom: 0, color: "#535151" }}>
                                    Title
                                </p>
                            </div>

                            <div
                                style={{
                                    padding: "15px",
                                    borderBottom: "1px solid #F3F3F3",
                                }}
                            >
                                <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                                    {details?.reminder?.reminderType}
                                </h5>
                                <p style={{ marginBottom: 0, color: "#535151" }}>
                                    Notification Type
                                </p>
                            </div>
                            <div
                                style={{
                                    padding: "15px",
                                    borderBottom: "1px solid #F3F3F3",
                                }}
                            >
                                <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                                    {details?.reminder?.messageDate}
                                </h5>
                                <p style={{ marginBottom: 0, color: "#535151" }}>
                                    Notification Date
                                </p>
                            </div>
                            <div
                                style={{
                                    padding: "15px",
                                    borderBottom: "1px solid #F3F3F3",
                                }}
                            >
                                <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                                    {capitalizeWords(details?.student?.firstname)} {capitalizeWords(details?.student?.lastname)}
                                </h5>
                                <p style={{ marginBottom: 0, color: "#535151" }}>
                                    Student Name
                                </p>
                            </div>
                            <div
                                style={{
                                    padding: "15px",
                                    borderBottom: "1px solid #F3F3F3",
                                }}
                            >
                                <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                                    {capitalizeWords(details?.student?.className)}
                                </h5>
                                <p style={{ marginBottom: 0, color: "#535151" }}>
                                    Class
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ flexBasis: "70%" }}>
                    <div style={{ marginBottom: "20px" }}>
                        <h6 style={{ fontWeight: 500 }}>Notification Body</h6>
                        <div
                            style={{
                                borderRadius: "15px",
                                backgroundColor: "white",
                                padding: "30px",
                            }}
                        >
                            <p
                                style={{
                                    marginBottom: 0,
                                    fontSize: "16px"
                                }}
                            >
                                {details?.reminder?.messageBody}
                            </p>
                        </div>
                    </div>

                    
                </div>
            </section>

            <div
                style={{
                    backgroundColor: "#ffffff",
                    padding: "20px",
                    paddingLeft: "40px",
                }}
            >
                <p style={{ fontSize: "18px", marginBottom: "10px" }}>
                    Need help? Contact our support team{" "}
                    {/* <span>{details?.customerSupportNumber}</span> */}
                </p>
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <p style={{ fontSize: "16px", marginBottom: "0px" }}>Phone: </p>
                    <p style={{ fontSize: "16px", marginBottom: "0px" }}>
                        {details?.reminder?.institution?.customerSupportNumber}
                    </p>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <p style={{ fontSize: "16px", marginBottom: "0px" }}>Email: </p>
                    <p style={{ fontSize: "16px", marginBottom: "0px" }}>
                        {capitalizeWords(details?.reminder?.institution?.institutionEmail)}
                    </p>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <p style={{ fontSize: "16px", marginBottom: "0px" }}>Address: </p>
                    <p style={{ fontSize: "16px", marginBottom: "0px" }}>
                        {capitalizeWords(details?.reminder?.institution?.address)}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NotificationsPage;
