import { apiSlice } from "../../api/apiSlice";


export const authSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      invalidatesTags: ["userRegister"],
      providesTags: ["userRegister"],
      query: (body) => ({
        url: "/anon/register",
        method: "POST",
        body,
      }),
    }),
    login: builder.mutation({
      invalidatesTags: ["userLogin"],
      providesTags: ["userLogin"],
      query: (body) => ({
        url: "/anon/login",
        method: "POST",
        body,
      }),
    }),
    validateEmail: builder.mutation({
      invalidatesTags: ["validateEmail"],
      providesTags: ["validateEmail"],
      query: (body) => ({
        url: "/anon/verifyemail",
        method: "POST",
        body,
      }),
    }),
    forgotPassword: builder.mutation({
      invalidatesTags: ["forgotPassword"],
      providesTags: ["forgotPassword"],
      query: (body) => ({
        url: "/anon/forgot_password",
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation({
      invalidatesTags: ["resetPassword"],
      providesTags: ["resetPassword"],
      query: ({ email, code, body }) => ({
        url: `/anon/reset_password/${email}/${code}`,
        method: "POST",
        body,
      }),
    }),
    resendVerificationCode: builder.mutation({
      invalidatesTags: ["resendVerificationCode"],
      providesTags: ["resendVerificationCode"],
      query: (body) => ({
        url: "/anon/resend_verification_code",
        method: "POST",
        body,
      }),
    }),
    getStates: builder.query({
      invalidatesTags: ["getStates"],
      providesTags: ["getStates"],
      query: (countryId) => ({
        url: `/countries/${countryId}/states`,
        // url: `/api/getstates?countryId=${countryId}`,
        method: "GET",
      }),
    }),
    getCountries: builder.query({
      invalidatesTags: ["getCountries"],
      providesTags: ["getCountries"],
      query: () => ({
        url: "/countries",
        // url: "/api/getcountries",
        method: "GET",
      }),
    }),
    validateUserEmail: builder.query({
      invalidatesTags: ["validateUserEmail"],
      providesTags: ["validateUserEmail"],
      query: ({ email, accessToken }) => ({
        url: `/anon/validate_contact/${email}/${accessToken}`,
        method: "GET",
      }),
    }),
    setUserPassword: builder.mutation({
      invalidatesTags: ["setUserPassword"],
      providesTags: ["setUserPassword"],
      query: (body) => ({
        url: `/anon/setup_contact`,
        method: "POST",
        body,
      }),
    }),

  }),
});


export const
  {
    useRegisterMutation,
    useLoginMutation,
    useValidateEmailMutation,
    useForgotPasswordMutation,
    useGetStatesQuery,
    useGetCountriesQuery,
    useResetPasswordMutation,
    useResendVerificationCodeMutation,
    useValidateUserEmailQuery,
    useSetUserPasswordMutation,
  } = authSlice;


