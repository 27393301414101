import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { storeParentInfo } from "../../../../../../../../redux/features/institution/studentSlice";
import {
  useAddStudentRegisterMutation,
  useApproveStudentRegisterMutation,
  useCreateParentMutation,
  useGetAllParentsQuery,
} from "../../../../../../../../redux/features/institution/studentApiSlice";
import OnboardingLoader from "../../../../../../../../component/onboardingLoader/onboardingLoader";
import SuccessScreen from "../../../../../../../../component/successScreen/successScreen";
import stepOneOfTwo from "../../../../../../../../assets/svgs/stepOneOfTwo.svg";

const ParentInformationPage = ({ setActiveStage, student }) => {
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showCreateStudentCanvas, setShowCreateStudentCanvas] = useState(false);
  const [isNewParent, setIsNewParent] = useState(true);
  const [selectedParent, setSelectedParent] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredParents, setFilteredParents] = useState([]);

  const [approveStudentRegister, { isLoading: approveLoading }] =
    useApproveStudentRegisterMutation();

  const handleCloseStudent = () => {
    setShowCreateStudentCanvas(false);
  };
  const handleShowStudent = () => {
    setShowCreateStudentCanvas(true);
  };

  const dispatch = useDispatch();

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  console.log(instId);

  const { data: allParents, isLoading: parentsLoading } =
    useGetAllParentsQuery(instId);

  // get previous data if they have filled this page before
  const studentInfoData = useSelector((state) => state.student.studentInfo);
  console.log(studentInfoData);

  const studentData = useSelector((state) => state.student.studentData);

  const parentInfoData = useSelector((state) => state.student.parentInfo);

  console.log(parentInfoData);
  console.log(student);

  const [parentData, setParentData] = useState({
    // name: parentInfoData ? parentInfoData.name : "",
    // address: parentInfoData ? parentInfoData.address : "",
    // email: parentInfoData ? parentInfoData.email : "",
    // phoneNumber: parentInfoData ? parentInfoData.phoneNumber : "",

    name: student
      ? student?.newParent?.name || student?.existingParent?.name
      : parentInfoData
      ? parentInfoData?.name
      : "",
    address: student
      ? student?.newParent?.address || student?.existingParent?.address
      : parentInfoData
      ? parentInfoData?.address
      : "",
    email: student
      ? student?.newParent?.email || student?.existingParent?.email
      : parentInfoData
      ? parentInfoData?.email
      : "",
    phoneNumber: student
      ? student?.newParent?.phoneNumber || student?.existingParent?.phoneNumber
      : parentInfoData
      ? parentInfoData?.phoneNumber
      : "",
  });

  const { name, address, email, phoneNumber } = parentData;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setParentData({ ...parentData, [name]: value });

    if (name === "searchText") {
      setSearchText(value);
    }
  };

  const handleGoToNextPage = () => {
    // dispatch(storeParentInfo(parentData));
    if (isNewParent) {
      dispatch(storeParentInfo({ ...parentData, isNewParent: true }));
    } else {
      dispatch(
        storeParentInfo({
          id: selectedParent?.id,
          name: selectedParent?.name,
          email: selectedParent?.email,
          isNewParent: false,
        })
      );
    }

    setActiveStage("Student Information");
  };

  useEffect(() => {
    if (allParents) setFilteredParents(allParents);
  }, [allParents]);

  useEffect(() => {
    if (student) {
      setSelectedParent(student?.existingParent);
      setIsNewParent(student?.existingParent ? false : true);
    } else {
      setSelectedParent(parentInfoData);
      setIsNewParent(parentInfoData ? parentInfoData?.isNewParent : true);
    }
  }, [parentInfoData, student]);

  useEffect(() => {
    const filteredParents = allParents?.filter(
      (parent) =>
        parent.name.toLowerCase().includes(searchText.toLowerCase()) ||
        parent.email.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredParents(filteredParents);
  }, [searchText]);

  if (parentsLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <div>
        <div
          style={{ display: "flex", gap: 0.8 + "rem", alignItems: "center" }}
        >
          <img src={stepOneOfTwo} alt="step 2 of 2" />
          <h5>Parent Information</h5>
        </div>
      </div>

      <div style={{ marginTop: "15px" }}>
        <Form.Check
          inline
          label="New Parent"
          checked={isNewParent == true}
          onClick={() => setIsNewParent(true)}
          type="radio"
          id={`primary-inline-radio-1`}
          size={25}
        />
        <Form.Check
          inline
          label="Existing Parent"
          checked={isNewParent == false}
          onClick={() => setIsNewParent(false)}
          type="radio"
          id={`primary-inline-radio-1`}
          size={25}
        />
      </div>

      {isNewParent ? (
        <Form className="form" style={{ paddingTop: "10px" }}>
          <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Full Name <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="text"
              name="name"
              value={name}
              onChange={handleOnChange}
              placeholder="Enter Parent Full Name"
              required
            />
          </Form.Group>

          <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Address <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="text"
              name="address"
              value={address}
              onChange={handleOnChange}
              placeholder="Enter Address"
              required
            />
          </Form.Group>

          <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Email Address <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="email"
              name="email"
              value={email}
              onChange={handleOnChange}
              placeholder="Enter Email Address"
              required
            />
          </Form.Group>

          <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Phone Number <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="text"
              name="phoneNumber"
              value={phoneNumber}
              onChange={handleOnChange}
              placeholder="Enter Phone number"
              required
            />
          </Form.Group>
        </Form>
      ) : (
        <Form className="form" style={{ paddingTop: "10px" }}>
          <input
            type="search"
            name="searchText"
            value={searchText}
            onChange={handleOnChange}
            placeholder="Search for Parent"
            style={{
              borderRadius: "5px",
              backgroundColor: "#ededed",
              width: "100%",
              padding: "4px 8px",
              marginBottom: "10px",
            }}
          />

          {filteredParents?.slice(0, 10)?.length > 0 ? (
            filteredParents?.slice(0, 10)?.map(({ name, id, email }) => (
              <div
                key={id}
                style={{
                  backgroundColor: `${
                    selectedParent?.id === id ? "#ffede8" : "#fffbfa"
                  }`,
                  border: `${
                    selectedParent?.id === id
                      ? "2px solid #f8a387"
                      : "1px solid #cbcbcb"
                  }`,
                  borderRadius: "10px",
                  marginBottom: "10px",
                  padding: "10px",
                  cursor: "pointer",
                }}
                onClick={() => setSelectedParent({ id, name, email })}
              >
                <h6 style={{ marginBottom: "0px" }}>{name}</h6>
                <p style={{ marginBottom: "0px" }}>{email}</p>
              </div>
            ))
          ) : (
            <span>No parents Found</span>
          )}
        </Form>
      )}

      <br />
      <br />

      <div>
        <Button
          variant="primary"
          // type="submit"
          style={{
            backgroundColor: "#F14810",
            borderColor: "#F14810",
            float: "left",
            fontSize: "14px",
            padding: "7px 20px",
          }}
          className="button"
          size="md"
          onClick={handleGoToNextPage}
        >
          Next
        </Button>
      </div>

      <br />
      <br />
    </>
  );
};

export default ParentInformationPage;
