import React from "react";
import StickyNavbar from "../component/StickyNavbar";
import HeroSection from "../component/HeroSection";
import WhyAdept from "../component/WhyAdept";
import WhatweOffer from "../component/WhatweOffer";
import HowitWork from "../component/HowitWork";
import ContactUs from "../component/ContactUs";
import Footer from "../component/Footer";
import OtherServices from "../component/OtherServices";

function HomePage() {
  return (
    <>
      <StickyNavbar />
      <HeroSection />
      <WhyAdept />
      <WhatweOffer />
      <HowitWork />
      <OtherServices />
      <ContactUs />
      <Footer />
    </>
  );
}

export default HomePage;
