import React from "react";
import { useState, useEffect } from "react";
import { Table, Container } from "react-bootstrap";
import eyeIcon from "../../../../../assets/svgs/eyeActionIcon.svg";
import createStudentVector from "../../../../../assets/svgs/createAstudentIcon.svg";
import Button from "../../../../../component/button/button";
import { Offcanvas } from "react-bootstrap";
import StudentInformationStepper from "./studentInformation/studentInformationStepper";
import UploadStudentsPage from "./studentInformation/components/uploadStudents/uploadStudentsPage";
import { useGetStudentsRegisterQuery } from "../../../../../redux/features/institution/studentApiSlice";
import { useSelector } from "react-redux";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import { Link } from "react-router-dom";
import DashboardSidebar from "../dashboardSidebar/sidebar";
import DashboardTopSection from "../dashboardTopSection";
import { useGetInstitutionQuery } from "../../../../../redux/features/institution/institutionApiSlice";
import ViewedStudentsBatch from "./components/viewedStudentsBatch";
import TableComponent from "../../../../../component/table/table";

const UnapprovedStudentsPage = () => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showCreateStudentCanvas, setShowCreateStudentCanvas] = useState(false);
  const [isBatchViewed, setIsBatchViewed] = useState(false);
  const [unApprovedBatches, setUnapprovedBatches] = useState(null);
  const [viewedBatchDetails, setViewedBatchDetails] = useState(null);

  const handleCloseStudent = () => {
    setShowCreateStudentCanvas(false);
  };
  const handleShowStudent = () => {
    setShowCreateStudentCanvas(true);
  };

  const handleCloseUpload = () => {
    setShowUploadModal(false);
  };
  const handleShowUpload = () => {
    setShowUploadModal(true);
  };

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  const { data: institutionDetails } = useGetInstitutionQuery(instId);

  const {
    data: studentRegister,
    refetch: refetchStudentsRegisters,
    isLoading: registersLoading,
  } = useGetStudentsRegisterQuery(instId);

  console.log(studentRegister);

  const columns = [
    // { name: "id", label: "ID" },
    {
      name: "firstname",
      label: "First Name",
    },
    {
      name: "middlename",
      label: "Middle Name",
    },
    {
      name: "lastname",
      label: "Last Name",
    },
    { name: "gender", label: "Gender" },
    // { name: "status", label: "Status" },
    {
      name: "actions",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <img
            src={eyeIcon}
            alt="eye icon"
            style={{ cursor: "pointer" }}
            onClick={() => handleBatchView(studentRegister[tableMeta.rowIndex])}
          />
        ),
      },
    },
  ];

  useEffect(() => {
    if (studentRegister) {
      refetchStudentsRegisters();

      // get the ones that arent approved yet and display them
      const filteredArr = studentRegister.filter(
        (register) => register.status === "SUBMITTED"
      );
      // console.log(filteredArr)
      setUnapprovedBatches(filteredArr);
    }
  }, [
    showUploadModal === false,
    showCreateStudentCanvas,
    studentRegister,
    isBatchViewed,
  ]);

  const handleBatchView = (obj) => {
    setIsBatchViewed(!isBatchViewed);
    setViewedBatchDetails(obj);
  };

  console.log(viewedBatchDetails);

  if (registersLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div style={{ display: "flex" }}>
      <DashboardSidebar institutionDetails={institutionDetails} />

      <Container
        fluid
        className="bodycontainer"
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <DashboardTopSection />

        <ViewedStudentsBatch
          batchDetails={studentRegister}
          setIsBatchViewed={setIsBatchViewed}
        />
      </Container>
    </div>
  );
};

export default UnapprovedStudentsPage;
