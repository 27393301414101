import React from "react";
import { Routes, Route } from "react-router-dom";
import "./index.css";

// import Auth from "./routes/index.js";
// import Institution from "./routes/index.js";

import LoginPage from "./pages/auth/login/login.page.js";
import LandingPage from "./pages/auth/landing/landingPage";
import ForgotPasswordPage from "./pages/auth/forgotPassword/forgotPassword";
import ResetPasswordPage from "./pages/auth/resetPassword/resetPassword";

import RegistrationStepper from "./pages/auth/register/registrationStepper";

import SetupInstitutionPage from "./pages/institution/setupInstitution/setupInstitution";

import ContinueSetupInstitutionPage from "./pages/institution/continueSetupInstitution/setupInstitution/continueSetupInstitution";
import HomePage from "./pages/HomePage.js";
import ProtectedRoute from "./protectedRoutes";

import InstitutionOnboardSuccessPage from "./pages/institution/institutionOnboardSuccess/institutionOnboardSuccessPage";
import InstitutionAdminDashBoardPage from "./pages/institution/institutionDashboard/institutionDashBoardPage";
import ValidationPage from "./pages/institution/setupInstitution/validationPage/validationPage";
import SuperAdminDashboard from "./pages/superAdmin/superAdminDashboard";
import UnauthorizedPage from "./unauthorizedPage";
// import StudentInformationPage from "./pages/institution/institutionDashboard/components/students/studentInformation/components/studentInfo/studentInformationPage";
// import StudentInformationStepper from "./pages/institution/institutionDashboard/components/students/studentInformation/studentInformationStepper";
// import Students from "./pages/institution/institutionDashboard/components/students/students";
import InstitutionStudentPage from "./pages/institution/institutionDashboard/institutionStudentPage";
import InstitutionInvoicingPage from "./pages/institution/institutionDashboard/invoicingPage/institutionInvoicingPage";
import InvoicingBranchStudentList from "./pages/institution/institutionDashboard/invoicingPage/components/invoicingBranchStudentList.js";
import StudentInvoiceDetails from "./pages/institution/institutionDashboard/invoicingPage/components/viewInvoiceDetails";
import ParentPage from "./pages/parent/parent";
import UnapprovedStudentsPage from "./pages/institution/institutionDashboard/components/students/unapprovedStudentsPage";
import InstitutionBranchPage from "./pages/institution/institutionDashboard/institutionBranchPage";
import BranchStudentListPage from "./pages/institution/institutionDashboard/components/branches/components/branchStudentList";
// import InstitutionSchoolCalendarPage from "./pages/institution/institutionDashboard/institutionSchoolCalendarPage";
import SchoolCalendarList from "./pages/institution/institutionDashboard/components/schoolCalendar/schoolCalendarList";
import InstitutionSchoolYearPage from "./pages/institution/institutionDashboard/institutionSchoolYearPage";
import InstitutionReportPage from "./pages/institution/institutionDashboard/components/reporting/institutionReportPage";
import InstitutionSettingsPage from "./pages/institution/institutionDashboard/settingsPage/institutionSettingsPage";
import SchoolPage from "./pages/superAdmin/components/schools/school";
import ClassesPage from "./pages/institution/institutionDashboard/classes/classesPage";
import ClassDetailsPage from "./pages/institution/institutionDashboard/classes/components/classDetailsPage";
import SettlementPage from "./pages/superAdmin/components/settings/components/settlement/settlement.js";
import SettlementDetailsPage from "./pages/superAdmin/components/settings/components/settlement/components/settlementDetails.js";
import ReportingPage from "./pages/superAdmin/components/reporting/reporting";
import ReportDetailsPage from "./pages/superAdmin/components/reporting/components/reportDetailsPage";
import PaymentHistoryPage from "./pages/institution/institutionDashboard/components/reporting/components/paymentHistory";
import LevelSummaryPage from "./pages/institution/institutionDashboard/components/reporting/components/levelSummary";
import CategorySummaryPage from "./pages/institution/institutionDashboard/components/reporting/components/categorySummary";
import LevelSummaryDetailPage from "./pages/institution/institutionDashboard/components/reporting/components/levelSummaryDetail";
import CategorySummaryDetailPage from "./pages/institution/institutionDashboard/components/reporting/components/categorySummaryDetail";
import AdvanceReportPage from "./pages/superAdmin/components/reporting/components/advanceReport/advanceReportPage";
import GenerateReportPage from "./pages/superAdmin/components/reporting/components/advanceReport/components/generateReportPage";
import SuperAdminSettingsPage from "./pages/superAdmin/components/settings/superAdminSettings";
import UserManagement from "./pages/superAdmin/components/userManagement/userManagement";
import ReportHistoryPage from "./pages/superAdmin/components/reporting/components/advanceReport/components/reportHistory";
import ReportHistoryDetailPage from "./pages/superAdmin/components/reporting/components/advanceReport/components/reportHistoryDetail";
import PreviewApplicationPage from "./pages/institution/setupInstitution/components/previewApplication/previewApplicationPage.js";
import SchoolSetupPage from "./pages/institution/institutionDashboard/setupInstitution/setupInstitutionPage.js";
import InstitutionHelpPage from "./pages/institution/institutionDashboard/components/help/help";
import ApplicationsPage from "./pages/superAdmin/components/applications/applications.js";
import PendingApplications from "./pages/superAdmin/components/applications/components/pendingApplications.js";
import RejectedApplications from "./pages/superAdmin/components/applications/components/rejectedApplications.js";
import UsersSetPasswordPage from "./pages/auth/usersSetPassword/usersSetPassword.js";
import SchoolAdminUsersPage from "./pages/superAdmin/components/schools/viewSchool/schoolAdmins/schoolAdminUsers.js";
import ApplicationRejected from "./pages/institution/institutionDashboard/settingsPage/component/applicationRejected/applicationRejected.js";
import BranchesPage from "./pages/superAdmin/components/schools/viewSchool/branches/branches.js";
import UpdateApplications from "./pages/superAdmin/components/applications/components/updateApplications.js";
import CreateInvoice from "./pages/institution/institutionDashboard/invoicingPage/components/createInvoice";
// import EditInvoice from "./pages/institution/institutionDashboard/invoicingPage/components/editInvoice.js";
import SubmittedApplications from "./pages/superAdmin/components/applications/components/submittedApplications.js";
import StudentsPage from "./pages/superAdmin/components/schools/viewSchool/students/students.js";
import WalletTransactionsPage from "./pages/institution/institutionDashboard/components/reporting/components/walletTransactions.js";
import SendMessagePage from "./pages/superAdmin/components/sendMessage/sendMessagePage.js";
import ViewSentMessages from "./pages/superAdmin/components/sendMessage/viewSentMessages.js";
import ReminderManagementPage from "./pages/institution/institutionDashboard/components/reminderMgt/reminderManagement.js";
import ViewMessages from "./pages/institution/institutionDashboard/components/reminderMgt/viewMessages.js";
import ApprovedStudents from "./pages/institution/institutionDashboard/components/students/approvedStudent.js";
import ExpectedRevenuePage from "./pages/institution/institutionDashboard/components/dashboard/subPages/expectedRevenue.js";
import RevenuePage from "./pages/institution/institutionDashboard/components/dashboard/subPages/revenue.js";
import PendingPaymentPage from "./pages/institution/institutionDashboard/components/dashboard/subPages/pendingPayment.js";
import OverduePaymentPage from "./pages/institution/institutionDashboard/components/dashboard/subPages/overduePayment.js";
import NotificationsPage from "./pages/parent/notification.js";
import RemindersHomePage from "./pages/superAdmin/components/sendMessage/remindersHomePage.js";
import NotificationsSummaryPage from "./pages/superAdmin/components/sendMessage/notificationsSummary.js";

function RootApplication() {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/register" exact element={<RegistrationStepper />} />
        <Route path="/login" exact element={<LoginPage />} />
        <Route path="/forgot-password" exact element={<ForgotPasswordPage />} />

        {/* <Route path="/reset-password" exact element={<ResetPasswordPage />} /> */}
        <Route
          path="/reset_password/:email/:code"
          exact
          element={<ResetPasswordPage />}
        />

        {/* Other Users Set Password Page */}
        <Route
          path="/set_password/:email/:accessToken"
          exact
          element={<UsersSetPasswordPage />}
        />

        <Route path="/unauthorized" element={<UnauthorizedPage />}></Route>

        {/* protect this route*/}
        <Route path="/parent/invoice/:param" exact element={<ParentPage />} />
        <Route path="/parent/notifications/:param" exact element={<NotificationsPage />} />
        {/* <Route element={<ProtectedRoute allowedRoles={["PARENT"]} />}>
          
        </Route> */}
        <Route element={<ProtectedRoute allowedRoles={["ADMINISTRATOR", "TEACHER", "TECHNICAL_CONTACT", "ACCOUNTANT"]} />}>
          <Route path="/setup-institution">
            <Route path="" element={<SetupInstitutionPage />} />
            <Route
              path="preview-application"
              element={<PreviewApplicationPage />}
            />
            <Route path="validation" element={<ValidationPage />} />
            <Route path="continue" element={<ContinueSetupInstitutionPage />} />
          </Route>
          <Route path="/landing" exact element={<LandingPage />} />

          <Route path="/school-setup" exact element={<SchoolSetupPage />} />

          {/* <Route
            path="/dashboard"
            exact
            element={<InstitutionAdminDashBoardPage />}
          /> */}

          <Route path="/dashboard">
            <Route path="" exact element={<InstitutionAdminDashBoardPage />} />
            <Route
              path="/dashboard/expectedRevenue"
              exact
              element={<ExpectedRevenuePage />}
            />
            <Route path="/dashboard/revenue" exact element={<RevenuePage />} />
            <Route
              path="/dashboard/overduePayment"
              exact
              element={<OverduePaymentPage />}
            />
            <Route
              path="/dashboard/pendingPayment"
              exact
              element={<PendingPaymentPage />}
            />
          </Route>

          <Route path="/branch">
            <Route path="" exact element={<InstitutionBranchPage />} />
            <Route
              path="/branch/:Id"
              exact
              element={<BranchStudentListPage />}
            />
          </Route>

          <Route path="/reporting">
            <Route path="" exact element={<InstitutionReportPage />} />

            <Route
              path="/reporting/paymentHistory"
              element={<PaymentHistoryPage />}
            />

            <Route
              path="/reporting/walletTransactions"
              element={<WalletTransactionsPage />}
            />

            <Route path="/reporting/levelSummary">
              <Route path="" exact element={<LevelSummaryPage />} />
              <Route
                path="/reporting/levelSummary/:levelId"
                element={<LevelSummaryDetailPage />}
              />
            </Route>

            <Route path="/reporting/categorySummary">
              <Route path="" element={<CategorySummaryPage />} />
              <Route
                path="/reporting/categorySummary/:categoryId"
                element={<CategorySummaryDetailPage />}
              />
            </Route>
          </Route>

          <Route path="/settings" exact element={<InstitutionSettingsPage />} />
          <Route
            path="/settings/application-rejected"
            exact
            element={<ApplicationRejected />}
          />
          <Route path="/help" exact element={<InstitutionHelpPage />} />

          <Route path="/student">
            <Route path="" element={<InstitutionStudentPage />} />
            <Route path="unapproved" element={<UnapprovedStudentsPage />} />
            <Route path="approved" element={<ApprovedStudents />} />
          </Route>

          <Route path="/schoolYear">
            <Route path="" element={<InstitutionSchoolYearPage />} />
            <Route
              path="/schoolYear/:param"
              exact
              element={<SchoolCalendarList />}
            ></Route>
          </Route>

          <Route path="/messaging">
            <Route path="/messaging" element={<ReminderManagementPage />} />
            <Route
              path="/messaging/:reminderId"
              element={<ReminderManagementPage />}
            />
            <Route path="/messaging/viewmessages" element={<ViewMessages />} />
          </Route>

          <Route path="/classes">
            <Route path="" element={<ClassesPage />} />
            <Route path="/classes/:classId" element={<ClassDetailsPage />} />
          </Route>

          <Route path="/invoicing">
            <Route path="" exact element={<InstitutionInvoicingPage />} />
            <Route
              path="/invoicing/createinvoice"
              exact
              element={<CreateInvoice />}
            />
            <Route
              path="/invoicing/createinvoice/:invoiceId"
              exact
              element={<CreateInvoice />}
            />
            {/* <Route
              path="/invoicing/editinvoice/:invoiceId"
              exact
              element={<EditInvoice />}
            /> */}
            <Route
              path="/invoicing/:invoiceId"
              exact
              element={<InvoicingBranchStudentList />}
            />
            <Route
              path="/invoicing/:invoiceid/:studentId"
              exact
              element={<StudentInvoiceDetails />}
            />
          </Route>

          <Route
            path="/onboard-success"
            exact
            element={<InstitutionOnboardSuccessPage />}
          />
        </Route>

        <Route element={<ProtectedRoute allowedRoles={["SUPER_USER"]} />}>
          <Route path="/principal" exact element={<SuperAdminDashboard />} />
          <Route
            path="/principal/user-management"
            element={<UserManagement />}
          />

          <Route path="/principal/applications">
            <Route path="" exact element={<ApplicationsPage />} />
            <Route
              path="/principal/applications/pending"
              exact
              element={<PendingApplications />}
            />
            <Route
              path="/principal/applications/submitted"
              exact
              element={<SubmittedApplications />}
            />
            <Route
              path="/principal/applications/rejected"
              exact
              element={<RejectedApplications />}
            />
            <Route
              path="/principal/applications/institution_update"
              exact
              element={<UpdateApplications />}
            />
          </Route>

          <Route path="/principal/schools">
            <Route path="" exact element={<SchoolPage />} />
            <Route
              path="/principal/schools/schooladmin/:institutionId"
              exact
              element={<SchoolAdminUsersPage />}
            />
            <Route
              path="/principal/schools/students/:institutionId"
              exact
              element={<StudentsPage />}
            />
            <Route
              path="/principal/schools/branches/:institutionId"
              exact
              element={<BranchesPage />}
            />
          </Route>

          <Route path="/principal/reporting" element={<ReportingPage />} />

          <Route
            path="/principal/reporting/:institutionId"
            element={<ReportDetailsPage />}
          />

          <Route path="/principal/reporting">
            <Route path="" exact element={<ReportingPage />} />

            <Route
              path="/principal/reporting/:institutionId"
              element={<ReportDetailsPage />}
            />

            <Route
              path="/principal/reporting/advanceReport"
              element={<AdvanceReportPage />}
            />

            <Route
              path="/principal/reporting/advanceReport/generateReport"
              element={<GenerateReportPage />}
            />

            <Route
              path="/principal/reporting/advanceReport/reports"
              element={<ReportHistoryPage />}
            />

            <Route
              path="/principal/reporting/advanceReport/reports/:reportId"
              element={<ReportHistoryDetailPage />}
            />

            {/* <Route path="/principal/reporting/generateReport">
              <Route path="" exact element={<LevelSummaryPage />} />
              <Route
                path="/reporting/levelSummary/:levelId"
                element={<LevelSummaryDetailPage />}
              />
            </Route> */}

            {/* <Route path="/reporting/categorySummary">
              <Route path="" element={<CategorySummaryPage />} />
              <Route
                path="/reporting/categorySummary/:categoryId"
                element={<CategorySummaryDetailPage />}
              />
            </Route> */}
          </Route>
          <Route path="/principal/notifications" element={<RemindersHomePage />} />
          <Route path="/principal/messaging" element={<SendMessagePage />} />
          <Route path="/principal/notificationsummary" element={<NotificationsSummaryPage />} />

          <Route
            path="/principal/messaging/:reminderId"
            element={<SendMessagePage />}
          />
          <Route
            path="/principal/messaging/viewall"
            element={<ViewSentMessages />}
          />

          <Route path="/principal/settlement" element={<SettlementPage />} />
          <Route
            path="/principal/settlement/:id"
            element={<SettlementDetailsPage />}
          />

          <Route
            path="/principal/settings"
            element={<SuperAdminSettingsPage />}
          />
        </Route>
      </Routes>
    </>
  );
}

const App = () => {
  return (
    <div
      className="App"
      style={{ width: 100 + "%", height: "100vh", overflowY: "auto", overflowX: "hidden" }}
    >
      <RootApplication />
    </div>
  );
};

export default App;
