import React, { useState } from 'react'
import "./continueSetupInstitution.css"

import logo from "../../../../assets/svgs/logo.svg"

import dropdownIcon from "../../../../assets/svgs/dropdownIcon.svg";
import userDisplayPhoto from "../../../../assets/images/userDisplayPhoto.png";
import InstitutionLevel from './components/institutionLevel/institutionLevel';
import LevelCategoryPage from './components/institutionCategory/levelCategoryPage';
import InstitutionCalendar from './components/institutionCalendar/institutionCalendarSetup';
import FeeComponent from './components/feeComponent/feeComponentSetup';
import InstitutionFees from './components/institutionFee/institutionFeeSetup';
import { useDispatch, useSelector } from 'react-redux';
import { capitalizeFirstLetter } from '../../../../utils/utils';
import { logOut } from '../../../../redux/features/auth/authSlice';
import { useGetInstitutionQuery } from '../../../../redux/features/institution/institutionApiSlice';


const setupInstitutionStages = [
  { stage: "Institution Level" },
  { stage: "Level Category" },
  { stage: "School Calendar" },
  { stage: "Fee Component" },
  { stage: "Institution Fees" },
];

const ContinueSetupInstitutionPage = () => {

  const [activeStage, setActiveStage] = useState("Institution Level");
  const [dropdownProfile, setDropdownProfile] = useState(false);
  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(logOut());
  };

  const id = useSelector((state) => state.auth.userInfo.institutionId);
  const username = useSelector((state) => state.auth.userInfo.username);

  const { data: institutionDetails } = useGetInstitutionQuery(id);
  console.log(institutionDetails)


  return (
    <div className="setup-your-institution-page">
      <div>
        <img src={logo} alt="Company logo" style={{ maxWidth: "145px" }} />
        <div className="stagesContainer">
          {setupInstitutionStages.map((setupStage, i) => (
            <div
              className="stageContainer"
              key={i}
              // onClick={() => setActiveStage(setupStage.stage)}
            >
              <span className="roundedBox">
                <span
                  className={`${
                    activeStage === setupStage.stage ? "activeBox" : ""
                  }`}
                ></span>
                {i !== setupInstitutionStages.length - 1 && (
                  <div className="connector"></div>
                )}
              </span>
              <span
                className={`stage ${
                  activeStage === setupStage.stage ? "active" : ""
                }`}
              >
                {setupStage.stage}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="no-scrollbar">
        <div
          className="dashboard-top-section"
          style={{
            position: "relative",
          }}
        >
          <div className="user-details">
            <div
              onClick={() => setDropdownProfile(!dropdownProfile)}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer",
              }}
            >
              {institutionDetails && (
                <span>
                  Hi,{" "}
                  {capitalizeFirstLetter(
                    // institutionDetails?.createdBy?.firstName
                    username
                  )}
                </span>
              )}
              <div
                style={{ height: "40px", width: "40px", borderRadius: "100%" }}
              >
                <img
                  src={userDisplayPhoto}
                  alt="user"
                  style={{ width: "100%" }}
                />
              </div>
              <img src={dropdownIcon} alt="drop down" />
            </div>
          </div>
          {dropdownProfile && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                right: 0,
                zIndex: 10,
                width: "130px",
                backgroundColor: "#fff2ee",
                padding: "15px",
              }}
              className="profile-dropdown"
            >
              <span
                onClick={handleLogOut}
                style={{ cursor: "pointer", fontWeight: 500 }}
              >
                LogOut
              </span>
            </div>
          )}
        </div>
        <div>
          {activeStage === "Institution Level" && (
            <InstitutionLevel
              setActiveStage={setActiveStage}
              institutionDetails={institutionDetails}
            />
          )}
          {activeStage === "Level Category" && (
            <LevelCategoryPage
              setActiveStage={setActiveStage}
              institutionDetails={institutionDetails}
            />
          )}

          {activeStage === "School Calendar" && (
            <InstitutionCalendar
              setActiveStage={setActiveStage}
              institutionDetails={institutionDetails}
            />
          )}
          {activeStage === "Fee Component" && (
            <FeeComponent
              setActiveStage={setActiveStage}
              institutionDetails={institutionDetails}
            />
          )}
          {activeStage === "Institution Fees" && (
            <InstitutionFees
              setActiveStage={setActiveStage}
              institutionDetails={institutionDetails}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ContinueSetupInstitutionPage