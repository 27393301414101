import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";

// const options = {
//   responsive: true,
//   maintainAspectRatio: false,
//   aspectRatio: 1.1,

//   plugins: {
//     legend: {
//       position: "top",
//       align: "end",
//       labels: {
//         boxWidth: 6,
//         boxHeight: 6,
//         usePointStyle: "circle",
//       },
//     },
//     title: {
//       display: true,
//       text: "Financial Report",
//       align: "start",
//       padding: {
//         top: 4,
//         bottom: -10,
//       },
//       font: {
//         size: 13,
//         family: " 'Jost', sans-serif",
//         color: "#263238",
//       },
//     },
//   },
//   scales: {
//     x: {
//       ticks: {
//         color: "#606060",
//       },
//       grid: {
//         color: "#E2E2E2",
//       },
//     },
//     x: {
//       ticks: {
//         color: "#606060",
//       },
//       grid: {
//         color: "white",
//       },
//     },
//   },
// };

const labels = [
  "Grd I",
  "Grd II",
  "Grd III",
  "Grd IV",
  "Pri1",
  "Pri2",
  "Pri3",
  "Pri4",
  "Pri5",
];

const Chart = () => {

  const financialRec = useSelector((state) => state?.admin?.adminDashboardData);

  // console.log(financialRec)

  let labels = [];
  let paidLabelData = [];
  let pendingLabelData = [];
  let overdueLabelData = [];

  const financialList = financialRec?.financialReports?.map(
    (item) => {
      labels = [...labels, item.time];
      paidLabelData = [...paidLabelData, item.paidAmount];
      pendingLabelData = [...pendingLabelData, item.pendingAmount];
      overdueLabelData = [...overdueLabelData, item.totalOverdue];
    }
  );

  const data = {
    labels,
    datasets: [
      {
        label: 'paid',
        data: paidLabelData,
        borderColor: '#43d543',
        backgroundColor: '#43d543',
        borderWidth: 1.5,
        hitRadius: 0.3,
        tension: 0.4,
      },
      {
        label: 'pending',
        data: pendingLabelData,
        borderColor: '#3174FD',
        backgroundColor: '#3174FD',
        borderWidth: 1.5,
        tension: 0.4,
      },
      {
        label: 'overdue',
        data: overdueLabelData,
        borderColor: '#FD0E0E',
        backgroundColor: '#FD0E0E',
        borderWidth: 1.5,
        tension: 0.4,
      },

    ],
  };

  return (
    <div className="cardgraph">
      {/* <Line options={options} data={data} usePointStyle={true} /> */}
      <Line
        usePointStyle={true}
        data={data}
        options={{
          responsive: true,
          // maintainAspectRatio: false,
          // aspectRatio: 1.1,
          // plugins: {
          //   title: {
          //     display: true,
          //     text: 'Daily Financial Report'
          //   }
          // }
          plugins: {
            legend: {
              position: "top",
              align: "end",
              labels: {
                boxWidth: 6,
                boxHeight: 6,
                usePointStyle: "circle",
              },
            },
            title: {
              display: true,
              text: "Financial Report",
              align: "start",
              padding: {
                top: 4,
                bottom: -10,
              },
              font: {
                size: 15,
                family: " 'Jost', sans-serif",
                color: "#263238",
              },
            },
          },
          scales: {
            x: {
              ticks: {
                color: "#606060",
              },
              grid: {
                color: "#E2E2E2",
              },
            },
            x: {
              ticks: {
                color: "#606060",
              },
              grid: {
                color: "white",
              },
            },
          },

        }
        }
      />
    </div>
  );
}

export default Chart