import React, { useState } from "react";
import { Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useCreateInstitutionPeriodMutation, useUpdateInstitutionPeriodMutation } from "../../../../../../redux/features/institution/institutionApiSlice";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import SuccessScreen from "../../../../../../component/successScreen/successScreen";
import ToastComponent from "../../../../../../component/toast/toast";

const SchoolCalendarInfoPage = ({ calendarToEdit }) => {
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showCreateCalendarCanvas, setShowCreateCalendarCanvas] =
    useState(false);
  const [isToggled, setIsToggled] = useState(calendarToEdit ? calendarToEdit?.open : false);
  const [newPeriod, setNewPeriod] = useState(null);
  const [yearId, setYearId] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [
    updateInstitutionPeriod,
    { isLoading: updatePeriodLoading, isSuccess: editPeriodSuccess },
  ] = useUpdateInstitutionPeriodMutation();

  const { param } = useParams();

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  const handleCloseCalendar = () => {
    setShowCreateCalendarCanvas(false);
  };
  const handleShowCalendar = () => {
    setShowCreateCalendarCanvas(true);
  };

  const dispatch = useDispatch();

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  console.log(instId);

  // get previous data if they have filled this page before
  // const branchInfoData = useSelector(
  //     (state) => state.branch.storeBranchInfo
  // );

  const [newPeriodData, setNewPeriodData] = useState({
    // periodName: "",
    // startDate: "",
    // endDate: "",

    periodName: calendarToEdit ? calendarToEdit?.periodName : "",
    startDate: calendarToEdit ? calendarToEdit?.startDate : "",
    endDate: calendarToEdit ? calendarToEdit?.endDate : "",
  });

  const { periodName, startDate, endDate } = newPeriodData;

  const [
    createInstitutionPeriod,
    { isLoading: addPeriodLoading, isSuccess: addPeriodSuccess },
  ] = useCreateInstitutionPeriodMutation();

  const handleCalendarOnChange = (e) => {
    const { name, value } = e.target;
    setNewPeriodData({ ...newPeriodData, [name]: value });
    // setEditingValue({ ...editingValue, [name]: value });
  };

  console.log(newPeriodData)

  const handleCreateUpdatePeriod = async (e, type) => {
    e.preventDefault();

    if (periodName === "" || startDate === "" || endDate === "") {
      setIsFormNotFilled(true);

      setTimeout(() => {
        setIsFormNotFilled(false);
      }, 3000);

      return;
    }

    if(type === "edit") {
      try {
        const parameters = {
          periodId: calendarToEdit?.id,
          body: {
            periodName: periodName,
            startDate: startDate,
            endDate: endDate,
            open: isToggled,
          },
        };
        // console.log(parameters)
        const res = await updateInstitutionPeriod(parameters).unwrap();
        console.log(res)
      } catch (error) {
        console.log(error);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
    } else {
      try {
        const parameters = {
          institutionId: instId,
          body: {
            schoolYearId: param,
            periodName: periodName,
            startDate: startDate,
            endDate: endDate,
            open: isToggled,
          },
        };
        const res = await createInstitutionPeriod(parameters).unwrap();
  
        if (res) {
          console.log(res);
          setNewPeriod(res);
        }
        setShowCreateCalendarCanvas(false);
        setNewPeriodData({
          periodName: "",
          startDate: "",
          endDate: "",
        });
        setIsToggled(false);
      } catch (error) {
        console.log(error.data);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
    }

  };

  if (addPeriodLoading || updatePeriodLoading) {
    return <OnboardingLoader />;
  }

  if (addPeriodSuccess) {
    return (
      <SuccessScreen successMessage="Calender has been created successfully." />
    );
  }

  if (editPeriodSuccess) {
    return (
      <SuccessScreen successMessage="Calender edited successfully." />
    );
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <div>
        <div style={{ display: "flex", gap: 0.8 + "rem", marginTop: "20px" }}>
          <div>
            {/* <h5>Branch Information</h5> */}
            <p className="FormParagraph">
              Provide the following information about the school calendar
            </p>
          </div>
        </div>
      </div>

      {isFormNotFilled ? (
        <span
          style={{
            color: "red",
            fontStyle: "italic",
            transition: "ease-in-out",
          }}
        >
          Please fill all fields
        </span>
      ) : null}

      <Form className="form" style={{ paddingTop: "10px" }}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            Period Name <span style={{ color: "red" }}>*</span>{" "}
            <span style={{ fontWeight: 300, fontStyle: "italic" }}>
              (e.g Term or Semester )
            </span>
          </Form.Label>

          <Form.Control
            size="md"
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
            }}
            type="text"
            name="periodName"
            value={periodName}
            onChange={handleCalendarOnChange}
            placeholder="Enter Period Name"
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            Start Date <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <Form.Control
            size="md"
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
            }}
            type="date"
            name="startDate"
            value={startDate}
            onChange={handleCalendarOnChange}
            placeholder="Pick Start Date"
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            End Date <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <Form.Control
            size="md"
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
            }}
            type="date"
            name="endDate"
            value={endDate}
            onChange={handleCalendarOnChange}
            placeholder="Pick End Date"
            required
          />
        </Form.Group>

        <div
          className="calender-switch-container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <label className="switch">
            <input
              type="checkbox"
              checked={isToggled}
              // checked={editingElement ? editedToggled : isToggled}
              // onChange={editingElement ? handleEditToggle : handleToggle}
              onChange={handleToggle}
              className="toggle"
            />
            <span className="slider round"></span>
          </label>
          <label className="toggle-label">Set Calendar to Active</label>
        </div>

        <br />

        <div>
          {!calendarToEdit ? (
            <Button
              variant="primary"
              style={{
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                float: "left",
              }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
                onClick={(e) => handleCreateUpdatePeriod(e, "create")}
              >
                Save Calendar
              </span>
            </Button>
          ) : (
            <Button
              variant="primary"
              style={{
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                float: "left",
              }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
                onClick={(e) => handleCreateUpdatePeriod(e, "edit")}
              >
                Edit Calendar
              </span>
            </Button>
          )}
        </div>
      </Form>

      <br />
    </>
  );
};

export default SchoolCalendarInfoPage;
