import { useState, useEffect } from "react";
import eyeActionIcon from "../../../../../assets/svgs/eyeActionIcon.svg";
import ViewInstitution from "../components/viewInstitution/viewInstitution";
import DashboardTopSection from "../../dashboardTopSection/index";
import DashboardSidebar from "../../dashboardSideBar/sidebar";
import { useGetInstitutionListQuery } from "../../../../../redux/features/superAdmin/adminApiSlice";
import TableComponent from "../../../../../component/table/table";
import "../../dashboard/dashboard.css";
import "../../schools/schools.css";
import { Button, Offcanvas } from "react-bootstrap";
import backArrow from "../../../../../assets/svgs/backArrow.svg";
import { useNavigate } from "react-router-dom";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import ViewUpdateInstitution from "./viewUpdateInstitution/viewUpdateInstitution";

const UpdateApplications = () => {
  const navigate = useNavigate();

  const [isInstitionViewed, setIsInstitutionViewed] = useState(false);
  const [viewedInstitutionDetails, setViewedInstitutionDetails] =
    useState(null);
  const [institutionList, setInstitutionList] = useState(null);

  const [showCanvas, setShowCanvas] = useState(false);

  const { data: institutions, isLoading: institutionsLoading } =
    useGetInstitutionListQuery("INSTITUTION_UPDATE");

  console.log(institutionList);

  useEffect(() => {
    setInstitutionList(institutions);
  }, [institutionList, institutions]);

  const columns = [
    // { name: "id", label: "ID" },
    { name: "institutionName", label: "School Name" },
    // { name: "institutionType", label: "Type" },
    { name: "state", label: "State" },
    // {
    //     name: "institutionTypes",
    //     label: "institutionType",
    //     options: {
    //         customBodyRender: (type) => (type ? institutions?.institutionTypes?.map((type) => { type.typeName }) : "")
    //     },
    // },
    { name: "status", label: "Status" },
    {
      name: "actions",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <img
            src={eyeActionIcon}
            alt="eye icon"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsInstitutionViewed(!isInstitionViewed);
              setViewedInstitutionDetails(institutions[tableMeta.rowIndex]);
            }}
          />
        ),
      },
    },
  ];

  if (institutionsLoading) {
    return <OnboardingLoader />;
  }
  return (
    <div className="super-admin-dashboard schools-page no-scrollbar">
      <div>
        <DashboardSidebar />
      </div>

      <div>
        <DashboardTopSection />

        {isInstitionViewed ? (
          <ViewUpdateInstitution
            institutionDetails={viewedInstitutionDetails}
            setIsInstitutionViewed={setIsInstitutionViewed}
          />
        ) : (
          <>
            <section className="">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                  marginBottom: "10px",
                }}
                onClick={() => navigate(-1)}
              >
                <img src={backArrow} alt="Go back" />
                <h6 style={{ marginBottom: 0, fontSize: 1.3 + "rem" }}>
                  Institutions Update
                </h6>
              </div>

              <div
                className="applications"
                style={{
                  margin: "20px 0",
                }}
              >
                <TableComponent
                  data={institutions}
                  title=""
                  columns={columns}
                  pagination={10}
                />
              </div>
            </section>

            <br />
            <br />
          </>
        )}
      </div>
    </div>
  );
};

export default UpdateApplications;
