import { useState, useEffect } from "react";
import alertIcon from "../../../../../assets/svgs/alertIcon.svg";
import TableComponent from "../../../../../component/table/table";
import ButtonX from "../../../../../component/button/button";
import { useNavigate } from "react-router-dom";
import { Form, InputGroup, Button } from "react-bootstrap";
import ActionIcon from "../../../../../component/actionbutton/ActionIcon";
import {
  useGetPermissionPagesQuery,
  useAddPermissionPageMutation,
  useUpdatePermissionPageMutation,
  useDeletePermissionPageMutation,
  useGetPermissionPageQuery,
  useGetAllActionsQuery,
} from "../../../../../redux/features/superAdmin/adminApiSlice";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import ModalComponent from "../../../../../component/modal/modal";
import ToastComponent from "../../../../../component/toast/toast";

const PermissionPage = () => {
  // const navigate = useNavigate();

  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  // const [newPage, setNewPage] = useState(false);
  const [deletedElementID, setDeletedElementID] = useState(null);
  const [editedElement, setEditedElement] = useState(null);
  const [editingElement, setEditingElement] = useState(null);
  const [editingValue, setEditingValue] = useState({});
  const [editedAvailabeForInstitutions, setEditedAvailabeForInstitutions] =
    useState(null);
  const [elementToDelete, setElementToDelete] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleCloseModal = () => {
    setShowPermissionModal(false);
    setEditingElement(null);
    setEditingValue({
      editedAction: "",
      editedUrl: "",
      editedMethod: "",
      editedDescription: "",
    });
    setEditedAvailabeForInstitutions(null);
  };

  const columns = [
    // { name: "id", label: "ID" },
    { name: "name", label: "Name" },
    { name: "action", label: "Action" },
    // { name: "description", label: "Description" },
    { name: "url", label: "URL" },
    {
      name: "availabeForInstitutions",
      label: "Availabe For Institutions",
      options: {
        customBodyRender: (option) => (option ? "Yes" : "No"),
      },
    },
    {
      name: "actions",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <ActionIcon
            actionsArr={[
              {
                label: "Edit",
                action: () => {
                  handleEditPermission(permissionPages[tableMeta.rowIndex]);
                },
              },
              {
                label: "Delete",
                action: () => {
                  confirmDelete(permissionPages[tableMeta.rowIndex]);
                },
              },
            ]}
          />
        ),
      },
    },
  ];

  const {
    data: permissionPages,
    isLoading: permissionPagesLoading,
    refetch: refetchPages,
  } = useGetPermissionPagesQuery();

  const { data: allActions, isLoading: actionsLoading } =
    useGetAllActionsQuery();

  console.log(permissionPages);
  console.log(allActions);

  const [editPage, { isLoading: editLoading }] =
    useUpdatePermissionPageMutation();

  const [addPage, { isLoading: addLoading, isSuccess: addSuccess }] =
    useAddPermissionPageMutation();

  const [deletePage, { isLoading: deleteLoading }] =
    useDeletePermissionPageMutation();

  const [newPage, setNewPage] = useState({
    name: "",
    action: "",
    method: "",
    url: "",
    description: "",
    availabeForInstitutions: "",
  });

  const { action, method, url, description, availabeForInstitutions, name } =
    newPage;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setNewPage({ ...newPage, [name]: value });
    setEditingValue({ ...editingValue, [name]: value });

    if (name === "availabeForInstitutions") {
      setEditedAvailabeForInstitutions(value);
    }
  };

  const {
    editedAction,
    editedMethod,
    editedUrl,
    editedDescription,
    editedName,
  } = editingValue;

  // console.log(newPage);
  // console.log(editingValue);
  // console.log(editingElement);
  // console.log(editedAvailabeForInstitutions);

  const handleEditPermission = (el) => {
    console.log("edit was clicked for ", el);
    setEditingElement(el);
    setShowPermissionModal(true);
    setEditingValue({
      editedName: el.name,
      editedAction: el.action,
      editedUrl: el.url,
      editedMethod: el.method,
      editedDescription: el.description,
    });
    setEditedAvailabeForInstitutions(el.availabeForInstitutions);
    // // put the id as selected
    // setSelectedItems([el.institutionCategory.id]);
    // setSelectedItemsNames([el.institutionCategory.name]);
  };

  const confirmDelete = (el) => {
    setElementToDelete(el);
    setShowConfirmDelete(true);
  };

  const handleDeletePermission = async () => {
    setShowConfirmDelete(false);

    try {
      await deletePage(elementToDelete?.id).unwrap();
      setDeletedElementID(elementToDelete.id);
      setShowToast(true);
      setToastMessage("Permission deleted successfully!");
    } catch (error) {
      console.log(error);
      // alert(error.data);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleCreateOrUpdatePermission = async (e, type) => {
    e.preventDefault();

    if (type === "edit") {
      const parameters = {
        pageId: editingElement?.id,
        body: {
          action: editedAction,
          method: editedMethod,
          url: editedUrl,
          description: editedDescription,
          availabeForInstitutions: editedAvailabeForInstitutions,
          name: editedName,
        },
      };
      try {
        const res = await editPage(parameters);
        console.log(res);
        if (res) {
          setEditedElement(res);
        }
        setShowToast(true);
        setToastMessage("Permission changes updated successfully!");
        setEditingValue({});
        setEditingElement(null);
        setEditedAvailabeForInstitutions(null);
        setShowPermissionModal(false);
        setNewPage({
          name: "",
          action: "",
          url: "",
          description: "",
        });
      } catch (error) {
        console.log(error);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
      return;
    } else {
      try {
        // submit with necessary infos
        const data = await addPage({ ...newPage }).unwrap();

        if (data) {
          console.log(data);
          setNewPage(data);
        }
        setShowToast(true);
        setToastMessage("Permission added successfully!");
        setNewPage({
          name: "",
          action: "",
          url: "",
          description: "",
        });
        setShowPermissionModal(false);
      } catch (error) {
        console.log(error.data);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
    }
  };

  useEffect(() => {
    if (permissionPages) {
      refetchPages();
    }
  }, [newPage, deletedElementID, editedElement]);

  console.log(editedAction)

  if (
    permissionPagesLoading ||
    addLoading ||
    editLoading ||
    deleteLoading ||
    actionsLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <div>
      <div>
        <ToastComponent
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            marginBottom: "8px",
          }}
        >
          <h5
            style={{ fontWeight: "600", fontSize: "14px", marginBottom: "0px" }}
          >
            Permission
          </h5>

          <Button
            className="button"
            size="md"
            style={{
              backgroundColor: "#F14810",
              color: "#ffffff",
              border: "none",
              outline: "none",
              fontSize: 0.8 + "rem",
            }}
            onClick={() => setShowPermissionModal(true)}
          >
            Create Permission
          </Button>
        </div>

        <div
          style={{
            border: "1px solid #F5F5F5",
            borderRadius: "8px",
            padding: "15px",
            height: "auto",
          }}
        >
          <TableComponent
            data={permissionPages}
            title=""
            columns={columns}
            pagination={10}
          />
        </div>
      </div>

      {/* delete confirmation modal */}
      <ModalComponent
        showModal={showConfirmDelete}
        closeModal={() => setShowConfirmDelete(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img src={alertIcon} alt="alert Icon" style={{ margin: "0 auto" }} />
          <p
            style={{
              marginTop: "10px",
              marginBottom: "5px",
              fontWeight: "500",
              fontSize: 1.0 + "rem",
            }}
          >
            Are you sure you want to delete this permission?{" "}
          </p>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              gap: "10px",
              marginLeft: "30%",
            }}
          >
            <Button
              onClick={() => setShowConfirmDelete(false)}
              type="button"
              variant="outline-primary"
              style={{ borderColor: "#F14810", color: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                No
              </span>
            </Button>
            <Button
              onClick={() => handleDeletePermission()}
              type="button"
              variant="primary"
              style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Yes
              </span>
            </Button>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        title={editingElement ? "Edit Permission" : "Create Permission"}
        showModal={showPermissionModal}
        closeModal={handleCloseModal}
      >
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Name <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="text"
              name={editingElement ? "editedName" : "name"}
              value={editingElement ? editedName : name}
              onChange={handleOnChange}
              placeholder="Enter Name"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Action <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <Form.Select
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              // name="action"
              // value={action}
              name={editingElement ? "editedAction" : "action"}
              value={editingElement ? editedAction : action}
              onChange={handleOnChange}
              // required
            >
              <option value="" defaultValue disabled>
                Select Action
              </option>
              {allActions?.map((action) => (
                <option
                  key={action}
                  value={action}
                  selected={action === editedAction}
                >
                  {action}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Method <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="text"
              name={editingElement ? "editedMethod" : "method"}
              value={editingElement ? editedMethod : method}
              onChange={handleOnChange}
              placeholder="Enter Method (e.g. Post, Put, Read)"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Page URL <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="text"
              name={editingElement ? "editedUrl" : "url"}
              value={editingElement ? editedUrl : url}
              onChange={handleOnChange}
              placeholder="Enter Page URL (e.g /page/subpage)"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className="formClasslabel">Description</Form.Label>

            <Form.Control
              as="textarea"
              rows={3}
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              name={editingElement ? "editedDescription" : "description"}
              value={editingElement ? editedDescription : description}
              onChange={handleOnChange}
              placeholder="Enter description of the action"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Availabe For Institutions <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <InputGroup className="mb-3" onChange={handleOnChange}>
              <div key={`inline-radio`}>
                <Form.Check
                  inline
                  label="True"
                  name="availabeForInstitutions"
                  value="true"
                  // name={editingElement ? "editedCompulsory" : "compulsory"}
                  // value={editingElement ? editedCompulsory : "true"}
                  checked={
                    editingElement
                      ? editedAvailabeForInstitutions == true ||
                        editedAvailabeForInstitutions == "true"
                      : null
                  }
                  onChange={handleOnChange}
                  type="radio"
                  id={`inline-radio-1`}
                  size={25}
                  style={{ variant: "outline-warning" }}
                />
                <Form.Check
                  inline
                  label="False"
                  name="availabeForInstitutions"
                  value="false"
                  // name={editingElement ? "editedCompulsory" : "compulsory"}
                  // value={editingElement ? editedCompulsory : "false"}
                  checked={
                    editingElement
                      ? editedAvailabeForInstitutions == false ||
                        editedAvailabeForInstitutions == "false"
                      : null
                  }
                  onChange={handleOnChange}
                  type="radio"
                  id={`inline-radio-2`}
                  size={25}
                  style={{ variant: "outline-warning" }}
                />
              </div>
            </InputGroup>
          </Form.Group>

          <div style={{ marginTop: "30px" }}>
            {editingElement ? (
              <Button
                onClick={(e) => handleCreateOrUpdatePermission(e, "edit")}
                variant="primary"
                style={{
                  backgroundColor: "#F14810",
                  borderColor: "#F14810",
                  float: "left",
                }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Update Changes
                </span>
              </Button>
            ) : (
              <Button
                onClick={(e) => handleCreateOrUpdatePermission(e, "")}
                variant="primary"
                style={{
                  backgroundColor: "#F14810",
                  borderColor: "#F14810",
                  float: "left",
                }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Save
                </span>
              </Button>
            )}
          </div>
        </Form>
      </ModalComponent>
    </div>
  );
};

export default PermissionPage;
