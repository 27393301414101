import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({allowedRoles}) => {

  const user = useSelector((state) => state.auth.userInfo);

  // get the user role

  const role = user?.userType

  const checkAccess = user && allowedRoles.includes(role)
  

  return checkAccess ? (
    <Outlet />
  ) : user && !checkAccess ? (
    <Navigate to="/unauthorized" />
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
