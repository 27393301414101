import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";

// import {
//     useGetDashboardReportQuery
// } from '../../../../../redux/features/institution/institutionApiSlice';

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.overrides.doughnut.plugins.legend.display = false;

const termPercentage = Math.round((9 / 12) * 100, 0);

function PeriodProgress() {
  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  console.log(instId);

  // const { data: dashboardData, isLoading: dashboardDataLoading } =
  //     useGetDashboardReportQuery(instId);

  const dashboardRec = useSelector(
    (state) => state?.institution?.dashboardData
  );

  const used = dashboardRec?.activeCalendar?.progress?.slice(0, -1);
  const left = 100 - dashboardRec?.activeCalendar?.progress?.slice(0, -1);
  console.log(used, left);

  const data = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1.1,

    labels: ["Used", "Left"],
    datasets: [
      {
        label: "% Progress",
        data: [used, left],
        backgroundColor: ["#F9F9F9", "#F14810"],
        borderRadius: 0,
        weight: 0.2,
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div style={{ width: 5.6 + "rem", position: "relative" }}>
      <Doughnut
        data={data}
        options={{
          cutout: 33,
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            tooltip: {
              enabled: true, // Disable tooltips
              usePointStyle: true,
            },
          },
        }}
      />
      <div className="doughnuttext">
        <center>
          <span style={{ color: "white", fontSize: 1.2 + "rem" }}>
            <b>{dashboardRec?.activeCalendar?.progress}</b>
          </span>
        </center>
      </div>
    </div>
  );
}

export default PeriodProgress;
