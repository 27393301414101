import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  // Button,
  Image,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import successGIF from "../../../assets/svgs/successGIF.gif";
import OnboardingLoader from "../../../component/onboardingLoader/onboardingLoader";
import RegistrationLayout from "../../../component/layout/registrationLayout";
import {
  useResetPasswordMutation,
  useValidateUserEmailQuery,
  useSetUserPasswordMutation,
} from "../../../redux/features/auth/authApiSlice";
import ModalComponent from "../../../component/modal/modal";
import ToastComponent from "../../../component/toast/toast";
import Button from "../../../component/button/button";
import { logOut } from "../../../redux/features/auth/authSlice";

import { useParams, useLocation } from "react-router-dom";

import showPasswordIcon from "../../../assets/svgs/showPassword.svg";
import hidePasswordIcon from "../../../assets/svgs/hidePassword.svg";

function UsersSetPasswordPage() {
  const { email, accessToken } = useParams();

  console.log(email, accessToken, 11111);

  const [errorMessage, setErrorMessage] = useState(null);
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isMinCharsValid, setIsMinCharsValid] = useState(false);
  const [isUppercaseValid, setIsUppercaseValid] = useState(false);
  const [isLowercaseValid, setIsLowercaseValid] = useState(false);
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [isSpecialCharValid, setIsSpecialCharValid] = useState(false);

  const {
    data: validateUser,
    isLoading: validateUserLoading,
    isError: validateUserError,
  } = useValidateUserEmailQuery({ email, accessToken });

  console.log(validateUser, "User validated");

  const [formData, setFormData] = useState({
    // email: "",
    // code: "",
    password: "",
    confirmPassword: "",
  });
  // const { email, code, password } = formData;
  const { password, confirmPassword } = formData;

  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/login");
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Check each condition for the password validation
    if (name === "password") {
      setIsMinCharsValid(value.length >= 8);
      setIsUppercaseValid(/[A-Z]/.test(value));
      setIsLowercaseValid(/[a-z]/.test(value));
      setIsNumberValid(/\d/.test(value));
      setIsSpecialCharValid(/[@#$%^&+=]/.test(value));
    }
  };

  const [resetPassword, { isLoading: resetPasswordLoading }] =
    useResetPasswordMutation();

  const [setUserPassword, { isLoading: setUserPasswordLoading }] =
    useSetUserPasswordMutation();

  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const [passMatch, setPassMatch] = useState(false);

  console.log(passMatch);

  useEffect(() => {
    validatePassword();
  }, [formData]);

  const validatePassword = () => {
    if (password != "") {
      password === confirmPassword ? setPassMatch(true) : setPassMatch(false);
    }
  };

  const handleOnSubmit = async () => {
    // e.preventDefault();

    // test if the password is now valid before submitting
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}$/;

    if (!passwordRegex.test(password)) {
      setIsPasswordValid(false);
      return;
    }

    try {
      setIsPasswordValid(true);
      const params = {
        // accessToken: accessToken,
        email: email,
        oldPassword: accessToken,
        newPassword: password,
      };

      console.log(params, 7777);

      const data = await setUserPassword(params).unwrap();

      if (data) {
        // dispatch(userInfo(data));
        console.log(data);
        // setShowToast(true);
        // setToastMessage("Password has been set successfully!")
        setShowModal(true);
        // navigate("/login");
      }
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  if (validateUserLoading || setUserPasswordLoading) {
    return <OnboardingLoader />;
  }

  // if (validateUserError) {
  //     setErrorMessage(error?.data?.detail);
  //     setShowToast(true);
  //     setToastMessage(errorMessage);
  // }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      {validateUser && (
        <RegistrationLayout>
          <br />

          <br />

          {/* Success Modal */}
          <ModalComponent showModal={showModal} closeModal={handleCloseModal}>
            <div style={{ textAlign: "center" }}>
              <img
                src={successGIF}
                alt="success Gif"
                width="150px"
                style={{ margin: "0 auto" }}
              />
              <h3 style={{ color: "#F14810", fontWeight: "600" }}>
                Successful!!
              </h3>
              <p style={{ fontSize: "18px" }}>
                Password has been set successfully! Proceed to Login.
              </p>
            </div>
          </ModalComponent>

          <div>
            <h3 className="FormHeader" style={{ marginTop: "15px" }}>
              Set Password
            </h3>
            <p className="FormParagraph">
              Set a strong and memorable password for your account
            </p>
          </div>

          <br />

          <Form className="form">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                New Password <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <div style={{ position: "relative" }} className="password-box">
                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={password}
                  autoComplete="off"
                  onChange={handleOnChange}
                  placeholder="Enter Your Password"
                />
                {showPassword ? (
                  <img
                    src={hidePasswordIcon}
                    alt="showPassword Icon"
                    onClick={() => setShowPassword(false)}
                    className="hidePasswordIcon"
                  />
                ) : (
                  <img
                    src={showPasswordIcon}
                    alt="showPassord Icon"
                    onClick={() => setShowPassword(true)}
                    className="showPasswordIcon"
                  />
                )}
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                Confirm Password <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <div style={{ position: "relative" }} className="password-box">
                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={confirmPassword}
                  autoComplete="off"
                  onChange={handleOnChange}
                  placeholder="Enter Your Password"
                  required
                />
                {showConfirmPassword ? (
                  <img
                    src={hidePasswordIcon}
                    alt="showPassword Icon"
                    onClick={() => setShowConfirmPassword(false)}
                    className="hidePasswordIcon"
                  />
                ) : (
                  <img
                    src={showPasswordIcon}
                    alt="showPassord Icon"
                    onClick={() => setShowConfirmPassword(true)}
                    className="showPasswordIcon"
                  />
                )}
              </div>
            </Form.Group>

            <ul style={{ listStyle: "none", marginLeft: -2 + "rem" }}>
              <li
                className="fst-italic"
                style={{
                  color: "red",
                  fontFamily: "jost, sans-serif",
                  fontSize: 0.85 + "rem",
                }}
              >
                Password must contain:
              </li>
              <li
                className="fst-italic"
                style={{
                  fontFamily: "jost, sans-serif",
                  fontSize: 0.85 + "rem",
                  color: !isMinCharsValid ? "red" : "#2bb4b1",
                }}
              >
                Minimum of 8 characters
              </li>
              <li
                className="fst-italic"
                style={{
                  fontFamily: "jost, sans-serif",
                  fontSize: 0.85 + "rem",
                  color: !passMatch ? "red" : "#2bb4b1",
                }}
              >
                Passwords Match
              </li>

              <li
                className="fst-italic"
                style={{
                  fontFamily: "jost, sans-serif",
                  fontSize: 0.85 + "rem",
                  color:
                    !isLowercaseValid || !isUppercaseValid ? "red" : "#2bb4b1",
                }}
              >
                one uppercase letter and one lowercase letter
              </li>
              <li
                className="fst-italic"
                style={{
                  fontFamily: "jost, sans-serif",
                  fontSize: 0.85 + "rem",
                  color: !isNumberValid ? "red" : "#2bb4b1",
                }}
              >
                one number
              </li>
              <li
                className="fst-italic"
                style={{
                  fontFamily: "jost, sans-serif",
                  fontSize: 0.85 + "rem",
                  color: !isSpecialCharValid ? "red" : "#2bb4b1",
                }}
              >
                one special character
              </li>
            </ul>

            <br />

            <Button
              onClick={!passMatch ? null : () => handleOnSubmit()}
              backgroundColor={`${!passMatch ? "#FFCAB8" : "rgb(241, 72, 16)"}`}
              cursor={`${!passMatch ? "not-allowed" : "pointer"}`}
            >
              Save Password
            </Button>
          </Form>

          <div>
            <p
              style={{
                fontFamily: "jost, sans-serif",
                fontSize: 0.85 + "rem",
                marginTop: 1 + "rem",
                marginRight: -2 + "rem",
              }}
            >
              Already have password?
              <a
                href="/login"
                className="btn btn-link"
                role="button"
                data-bs-toggle="button"
                style={{
                  fontFamily: "jost, sans-serif",
                  fontSize: 0.85 + "rem",
                  marginTop: 0.85 + "rem",
                  marginBottom: 1.15 + "rem",
                  paddingLeft: 0.3 + "rem",
                  paddingRight: 1.2 + "rem",
                }}
              >
                <span style={{ color: "#3174fd" }}>Back to Login</span>
              </a>
            </p>
          </div>
        </RegistrationLayout>
      )}
    </>
  );
}

export default UsersSetPasswordPage;
