import { useState, useRef, useEffect } from "react";
import "./validateEmail.css";
import {
  useValidateEmailMutation,
  useResendVerificationCodeMutation,
} from "../../../../redux/features/auth/authApiSlice";

import Button from "../../../../component/button/button";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";
import ToastComponent from "../../../../component/toast/toast";
import { userInfo } from "../../../../redux/features/auth/authSlice";

const correctOTP = "123456"; // validate from your server
const numberOfDigits = 6;

const ValidateEmailPage = ({ setActiveStage }) => {
  const [validateEmail, { isLoading: validationLoading }] =
    useValidateEmailMutation();

  const [
    resendCode,
    { isLoading: resendCodeLoading, isSuccess: resendCodeSuccess },
  ] = useResendVerificationCodeMutation();

  const institutionData = useSelector(
    (state) => state.institution.institutionData
  );

  // console.log(institutionData.createdBy.email)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //===========start============================================================
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const [otpError, setOtpError] = useState(null);
  // const [errorMessage, setE]
  const otpBoxReference = useRef([]);
  const [isOtpValid, setIsOtpValid] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleChange = (value, index) => {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }

    if (index === numberOfDigits - 1) {
      if (isOtpValid) {
        navigate("/landing");
      }
    }
  };

  const handleBackspaceAndEnter = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  };

  useEffect(() => {
    handleValidate();
    if (otp.join("") !== "" && isOtpValid !== true) {
      setOtpError("❌ Incomplete OTP");
    } else {
      setOtpError(null);
    }
  }, [otp]);
  //===========end===========================================================

  // const handleResendCode = () => {
  //   console.log("Sending code again");
  // };

  const handleResendCode = async (e) => {
    e.preventDefault();

    try {
      const data = await resendCode({
        email: institutionData.createdBy.email,
      }).unwrap();

      if (data) {
        // dispatch(userInfo(data));
        setShowToast(true);
        setToastMessage("Sending code again...");
      }
    } catch (error) {
      console.log(error);
      // setErrorMessage(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleValidate = async () => {
    //e.preventDefault();

    try {
      // Join the codes array into a string
      const codeString = otp.join("");
      console.log(codeString);
      if (codeString.length === numberOfDigits) {
        const result = await validateEmail({
          activationCode: codeString,
          email: institutionData.createdBy.email,
        }).unwrap();

        if (result) {
          // setIsOtpValid(true);
          // console.log("success");
          dispatch(userInfo(result));
          navigate("/landing");
        }
      }
    } catch (error) {
      setIsOtpValid(false);
      // setOtpError(error.data.detail);
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  if (validationLoading || resendCodeLoading) {
    return (
      <div style={{ height: "100%" }}>
        <OnboardingLoader />
      </div>
    );
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <div className="validate-page">
        <div className="validate-email-container">
          <h2>
            Enter the 6-digit code sent to {institutionData?.createdBy?.email}
          </h2>
          {/* {otpError && (
            <span style={{ color: "red", fontStyle: "italic" }}>
              {otpError}
            </span>
          )} */}
          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
            {otp.map((digit, index) => (
              <input
                key={index}
                value={digit}
                maxLength={1}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                ref={(reference) =>
                  (otpBoxReference.current[index] = reference)
                }
                className={`border h-auto text-black p-3 rounded-md block bg-grey focus:border-2 focus:outline-none appearance-none`}
              />
            ))}
          </div>

          {/* <p
            className={`text-lg text-grey mt-4 ${otpError ? "error-show" : ""}`}
          >
            {otpError}
          </p> */}
          <div>
            <p
              style={{
                fontFamily: "jost, sans-serif",
                fontSize: 0.85 + "rem",
                marginTop: 1 + "rem",
                marginRight: -2 + "rem",
              }}
            >
              Haven’t received code,
              <span
                style={{ color: "#3174fd", cursor: "pointer" }}
                onClick={handleResendCode}
              >
                Resend
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ValidateEmailPage;
