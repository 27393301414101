import React from 'react'

const ReportingIcon = ({active}) => {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6667 0.25H2.83333C1.275 0.25 0 1.525 0 3.08333V22.9167C0 24.475 1.275 25.75 2.83333 25.75H22.6667C24.225 25.75 25.5 24.475 25.5 22.9167V3.08333C25.5 1.525 24.225 0.25 22.6667 0.25ZM8.5 20.0833H5.66667V10.1667H8.5V20.0833ZM14.1667 20.0833H11.3333V5.91667H14.1667V20.0833ZM19.8333 20.0833H17V14.4167H19.8333V20.0833Z"
        fill={active ? "#ffffff" : "#464646"}
      />
    </svg>
  );
}

export default ReportingIcon