import { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import backArrow from "../../../../../../assets/svgs/backArrow.svg";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import { useViewStudentFullDetailsQuery } from "../../../../../../redux/features/institution/studentApiSlice";

import { useSelector } from "react-redux";
import { addTrailingCommas } from "../../../../../../utils/utils";
import TableComponent from "../../../../../../component/table/table";

const StudentFullDetailsPage = ({ studentDetails, setIsStudentViewed }) => {
  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const [activePage, setActivePage] = useState("Payment History");

  const querys = {
    institutionId: id,
    studentId: studentDetails?.id,
  };

  console.log(studentDetails, 999);

  const {
    data: studentInfo,
    refetch: refetchDetails,
    isLoading: studentInfoLoading,
  } = useViewStudentFullDetailsQuery(querys);

  console.log("studentInfo", studentInfo);

  const paymentColumns = [
    // { name: "id", label: "S/N" },
    {
      name: "transactionTime",
      label: "Date",
      options: {
        //customBodyRender: (date) => (date ? date?.split("T")[0] : "N/A"),
        customBodyRender: (date) => (date ? date : "N/A"),
      },
    },
    // { name: "transactionTime", label: "Date" },
    {
      name: "paidAmount",
      label: "Paid Amount",
      options: {
        customBodyRender: (amount) => (amount ? addTrailingCommas(amount) : 0),
      },
    },
    { name: "transactionReference", label: "Transaction Reference" },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (status) => (status ? "Paid" : "Not Paid"),
      },
    },
    // {
    //     name: "actions",
    //     label: "Action",
    //     options: {
    //         customBodyRender: (value, tableMeta) => (
    //             <ActionIcon
    //                 actionsArr={[
    //                     {
    //                         label: "View",
    //                         // action: () => handleDeleteStudent(students[tableMeta.rowIndex]),
    //                         action: () => {
    //                             // setIsStudentViewed(!isStudentViewed);
    //                             // setViewedStudentDetails(students[tableMeta.rowIndex]);
    //                         },
    //                     },
    //                 ]}
    //             />
    //         ),
    //     },
    // },
  ];

  const classColumns = [
    { name: "session", label: "Session" },
    { name: "level", label: "Grade" },
    { name: "category", label: "Class" },
    // { name: "status", label: "Status" },
  ];

  if (studentInfoLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
            marginTop: "10px",
            marginBottom: "5px",
          }}
          onClick={() => setIsStudentViewed(false)}
        >
          <img src={backArrow} alt="Go back" />
          <h6 style={{ marginBottom: 0 }}>
            Student Details ( {studentDetails?.firstname}{" "}
            {studentDetails?.lastname} )
          </h6>
        </div>
      </div>

      <Container>
        <Row>
          <Col xs={12} md={4} lg={4}>
            <div
              style={{
                paddingTop: 1 + "rem",
                marginBottom: 2 + "rem",
              }}
            >
              <div
                style={{
                  border: "2px solid #EDEDED",
                  borderRadius: "8px",
                  gap: "30px",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <div
                  style={{
                    borderBottom: "2px solid #EDEDED",
                    padding: "10px",
                    gap: "10px",
                    marginBottom: "15px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        padding: "5px",
                      }}
                    >
                      <h5 style={{ marginBottom: 0, fontSize: "20px" }}>
                        ₦ {addTrailingCommas(studentInfo?.ledgerBalance)}
                      </h5>
                      <p
                        style={{
                          marginBottom: 0,
                          fontStyle: "italic",
                          fontSize: "12px",
                        }}
                      >
                        Ledger Balance
                      </p>
                    </div>

                    {/* <div
                      style={{
                        border: "2px solid #ffe6de",
                        borderRadius: "50px",
                        backgroundColor: "#ffe6de",
                        padding: "6px 10px",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "12px",
                          color: "#F14810",
                        }}
                      >
                        {studentInfo?.ledgerStatus}
                      </span>
                    </div> */}
                  </div>
                </div>

                <div style={{ padding: "10px" }}>
                  <p style={{ fontWeight: "500", color: "#F14810" }}>
                    Student Information
                  </p>
                  <div
                    style={{
                      padding: "10px",
                      paddingLeft: "15px",
                      border: "2px solid #EDEDED",
                      borderRadius: "8px",
                    }}
                  >
                    <p style={{ fontWeight: "600" }}>
                      Full Name:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {studentInfo?.firstname} {studentInfo?.middlename}{" "}
                        {studentInfo?.lastname}
                      </span>
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      Student No.:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {studentInfo?.studentNo}
                      </span>
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      Date of birth:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {studentInfo?.dateOfBirth}
                      </span>
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      Email:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {studentInfo?.email}
                      </span>
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      Phone Number:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {studentInfo?.phoneNumber}
                      </span>
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      Gender:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {studentInfo?.gender}
                      </span>
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      Branch:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {studentInfo?.branch}
                      </span>
                    </p>
                    {/* <p style={{ fontWeight: "600" }}>
                                            Grade:{" "}
                                            <span style={{ fontWeight: "400" }}>
                                                {studentInfo?.level}
                                            </span>
                                        </p> */}
                    <p style={{ fontWeight: "600" }}>
                      Class:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {studentInfo?.category}
                      </span>
                    </p>
                  </div>
                </div>

                <div style={{ padding: "10px" }}>
                  <p style={{ fontWeight: "500", color: "#F14810" }}>
                    Parent Information
                  </p>
                  <div
                    style={{
                      padding: "10px",
                      paddingLeft: "15px",
                      border: "2px solid #EDEDED",
                      borderRadius: "8px",
                    }}
                  >
                    <p style={{ fontWeight: "600" }}>
                      Full Name:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {studentInfo?.parentName}
                      </span>
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      Address:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {studentInfo?.parentAddress}
                      </span>
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      Email:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {studentInfo?.parentEmail}
                      </span>
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      Phone Number:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {studentInfo?.parentPhoneNumber}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12} md={8} lg={8}>
            <div
              style={{
                paddingTop: 1 + "rem",
                marginBottom: 2 + "rem",
              }}
            >
              <div
                style={{
                  border: "2px solid #EDEDED",
                  borderRadius: "8px",
                  gap: "30px",
                  backgroundColor: "#FFFFFF",
                  padding: "30px",
                }}
              >
                <div style={{ marginBottom: "20px" }}>
                  <span
                    onClick={() => setActivePage("Payment History")}
                    style={{
                      backgroundColor: `${
                        activePage === "Payment History" ? "#ffede8" : "#f4f4f4"
                      }`,
                      border: `1px solid ${
                        activePage === "Payment History" ? "#f89a7c" : "#dbdbdb"
                      }`,
                      marginRight: "10px",
                      padding: "8px 18px",
                      borderRadius: "20px",
                      cursor: "pointer",
                      fontWeight: "500",
                    }}
                  >
                    Payment History
                  </span>

                  <span
                    onClick={() => setActivePage("Class History")}
                    style={{
                      backgroundColor: `${
                        activePage === "Class History" ? "#ffede8" : "#f4f4f4"
                      }`,
                      border: `1px solid ${
                        activePage === "Class History" ? "#f89a7c" : "#dbdbdb"
                      }`,
                      marginRight: "10px",
                      padding: "8px 18px",
                      borderRadius: "20px",
                      cursor: "pointer",
                      fontWeight: "500",
                    }}
                  >
                    Class History
                  </span>
                </div>

                {activePage === "Payment History" && (
                  <section
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <TableComponent
                      data={studentInfo?.paymentHistory}
                      title=""
                      columns={paymentColumns}
                      search={true}
                      pagination={10}
                    />
                  </section>
                )}

                {activePage === "Class History" && (
                  <section
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <TableComponent
                      data={studentInfo?.classHistory}
                      title=""
                      columns={classColumns}
                      search={true}
                      pagination={10}
                    />
                  </section>
                )}
              </div>
            </div>
          </Col>
        </Row>

        <br />
        <br />
      </Container>
    </div>
  );
};

export default StudentFullDetailsPage;
