import { apiSlice } from "../../api/apiSlice";

export const studentSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStudentsRegister: builder.query({
      providesTags: ["getStudentsRegister"],
      invalidatesTags: ["getStudentsRegister"],
      query: (institutionId) => ({
        // url: `/api/getinstitution?institutionId=${institutionId}`,
        url: `/api/studentsregister/${institutionId}`,
        method: "GET",
      }),
    }),
    getStudentRegisterDetail: builder.query({
      providesTags: ["getStudentRegisterDetail"],
      invalidatesTags: ["getStudentRegisterDetail"],
      query: (studentStagingId) => ({
        url: `/api/studentsregister/register/${studentStagingId}`,
        method: "GET",
      }),
    }),
    addStudentRegister: builder.mutation({
      providesTags: ["addStudentRegister"],
      invalidatesTags: ["addStudentRegister"],
      query: ({ institutionId, body }) => ({
        //   url: `/api/institutions/${institutionId}/school_years`,
        url: `/api/studentsregister/${institutionId}`,
        method: "POST",
        body,
      }),
    }),
    uploadStudents: builder.mutation({
      providesTags: ["uploadStudents"],
      invalidatesTags: ["uploadStudents"],
      query: (body) => ({
        url: `/api/studentsregister`,
        method: "POST",
        body,
      }),
    }),
    createStudent: builder.mutation({
      providesTags: ["createStudent"],
      invalidatesTags: ["createStudent"],
      query: ({ institutionId, body }) => ({
        url: `/api/studentsregister/${institutionId}`,
        method: "POST",
        body,
      }),
    }),
    updateStudent: builder.mutation({
      providesTags: ["updateStudent"],
      invalidatesTags: ["updateStudent"],
      query: ({ institutionId, body }) => ({
        url: `/api/studentsregister/${institutionId}`,
        method: "PUT",
        body,
      }),
    }),
    editStudentRegister: builder.mutation({
      invalidatesTags: ["editStudentRegister"],
      providesTags: ["editStudentRegister"],
      query: ({ institutionId, body }) => ({
        url: `/api/studentsregister/register/${institutionId}`,
        method: "PUT",
        body,
        // headers: { "Content-Type": "application/json" }
      }),
    }),
    deleteStudentInRegister: builder.mutation({
      invalidatesTags: ["deleteStudentRegister"],
      providesTags: ["deleteStudentRegister"],
      query: (studentId) => ({
        url: `/api/studentsregister/register/${studentId}`,
        method: "DELETE",
      }),
    }),
    updateStudentInRegister: builder.mutation({
      invalidatesTags: ["updateStudentRegister"],
      providesTags: ["updateStudentRegister"],
      query: ({ studentId, institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/studentsregister/${studentId}`,
        method: "PUT",
        body,
      }),
    }),
    approveStudentRegister: builder.mutation({
      providesTags: ["approveStudentRegister"],
      invalidatesTags: ["approveStudentRegister"],
      query: (institutionId) => ({
        url: `/api/studentsregister/register/approve/${institutionId}`,
        method: "POST",
      }),
    }),
    updateStudentNUBAN: builder.mutation({
      invalidatesTags: ["updateStudentNUBAN"],
      providesTags: ["updateStudentNUBAN"],
      query: ({ studentRegisterId, body }) => ({
        url: `/api/studentsregister/${studentRegisterId}`,
        method: "PUT",
        body,
      }),
    }),
    getApprovedStudents: builder.query({
      providesTags: ["getApprovedStudents"],
      invalidatesTags: ["getApprovedStudents"],
      query: (institutionId) => ({
        url: `/api/students/${institutionId}`,
        method: "GET",
      }),
    }),
    generateInvoice: builder.mutation({
      providesTags: ["generateInvoice"],
      invalidatesTags: ["generateInvoice"],
      query: (institutionId) => ({
        url: `/api/invoice/${institutionId}`,
        method: "POST",
      }),
    }),
    sendInvoice: builder.mutation({
      providesTags: ["sendInvoice"],
      invalidatesTags: ["sendInvoice"],
      query: (body) => ({
        url: `/api/invoice/notify`,
        method: "POST",
        body,
      }),
    }),
    notifyOneStudent: builder.mutation({
      providesTags: ["notifyOneStudent"],
      invalidatesTags: ["notifyOneStudent"],
      query: ({ invoiceId, invoiceDetailId }) => ({
        url: `/api/invoice/notify/${invoiceId}?invoiceDetailId=${invoiceDetailId}`,
        method: "POST",
      }),
    }),
    getInvoices: builder.query({
      providesTags: ["getInvoices"],
      invalidatesTags: ["getInvoices"],
      query: (institutionId) => ({
        url: `/api/invoices/${institutionId}`,
        method: "GET",
      }),
    }),
    getStudentInvoices: builder.query({
      providesTags: ["getStudentInvoices"],
      invalidatesTags: ["getStudentInvoices"],
      query: (invoiceId) => ({
        url: `/api/invoices/students/${invoiceId}`,
        method: "GET",
      }),
    }),
    getInvoiceDetails: builder.query({
      providesTags: ["getInvoiceDetails"],
      invalidatesTags: ["getInvoiceDetails"],
      query: (invoiceDetailId) => ({
        url: `/api/invoice/details/${invoiceDetailId}`,
        method: "GET",
      }),
    }),
    getStudentsByLevel: builder.query({
      providesTags: ["getStudentsByLevel"],
      invalidatesTags: ["getStudentsByLevel"],
      query: (levelId) => ({
        url: `/api/institution_levels/${levelId}/students`,
        method: "GET",
      }),
    }),
    promoteStudents: builder.mutation({
      providesTags: ["promoteStudents"],
      invalidatesTags: ["promoteStudents"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/promote`,
        method: "POST",
        body,
      }),
    }),
    viewStudentFullDetails: builder.query({
      providesTags: ["viewStudentFullDetails"],
      invalidatesTags: ["viewStudentFullDetails"],
      query: ({ institutionId, studentId }) => ({
        url: `/api/institutions/${institutionId}/students/${studentId}`,
        method: "GET",
      }),
    }),
    updateStudentInvoice: builder.mutation({
      invalidatesTags: ["updateStudentInvoice"],
      providesTags: ["updateStudentInvoice"],
      query: ({ studentInvoiceId, body }) => ({
        url: `/api/invoice/update/${studentInvoiceId}`,
        method: "POST",
        body,
      }),
    }),
    makeOfflinePayment: builder.mutation({
      providesTags: ["makeOfflinePayment"],
      invalidatesTags: ["makeOfflinePayment"],
      query: ({ body }) => ({
        url: `/api/invoice/offlinepayment`,
        method: "POST",
        body,
      }),
    }),
    payStudentFees: builder.mutation({
      providesTags: ["payStudentFees"],
      invalidatesTags: ["payStudentFees"],
      query: ({ studentInvoiceId, body }) => ({
        url: `/api/invoice/payfees/${studentInvoiceId}`,
        method: "POST",
        body,
      }),
    }),
    sendInvoiceReminder: builder.mutation({
      providesTags: ["sendInvoiceReminder"],
      invalidatesTags: ["sendInvoiceReminder"],
      query: ({ invoiceId, body }) => ({
        url: `/api/invoice/notify`,
        // url: `/api/invoice/notify/${invoiceId}`,
        method: "POST",
        body: body,
      }),
    }),
    getInvoiceForEdit: builder.query({
      providesTags: ["getInvoiceForEdit"],
      invalidatesTags: ["getInvoiceForEdit"],
      query: ({ institutionId, invoiceId }) => ({
        url: `/api/invoice/${institutionId}/${invoiceId}`,
        method: "GET",
      }),
    }),
    // getStudentsByCategories: builder.query({
    //   providesTags: ["getStudentsByCategories"],
    //   invalidatesTags: ["getStudentsByCategories"],
    //   query: ({ institutionId, body }) => ({
    //     url: `/api/students/${institutionId}/bycategories`,
    //     method: "POST",
    //     body: body
    //   }),
    // }),
    // previewInvoiceDetails: builder.query({
    //   providesTags: ["previewInvoiceDetails"],
    //   invalidatesTags: ["previewInvoiceDetails"],
    //   query: ({ institutionId, body }) => ({
    //     url: `/api/invoice/${body.institutionId}/preview`,
    //     method: "POST",
    //     body: body
    //   }),
    // }),
    getAllParents: builder.query({
      providesTags: ["getAllParents"],
      invalidatesTags: ["getAllParents"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/parents`,
        method: "GET",
      }),
    }),
    createParent: builder.mutation({
      providesTags: ["createParent"],
      invalidatesTags: ["createParent"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/parents`,
        method: "POST",
        body,
      }),
    }),
    downloadTemplate: builder.query({
      providesTags: ["downloadTemplate"],
      invalidatesTags: ["downloadTemplate"],
      query: ({ institutionId, institutionTypeId }) => ({
        url: `/api/institutions/${institutionId}/download_template/${institutionTypeId}`,
        method: "GET",
      }),
    }),
    getOfflinePaymentTypes: builder.query({
      providesTags: ["getOfflinePaymentTypes"],
      invalidatesTags: ["getOfflinePaymentTypes"],
      query: () => ({
        url: `/api/invoice/offlinepayment`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetStudentsRegisterQuery,
  useGetStudentRegisterDetailQuery,
  useAddStudentRegisterMutation,
  useUploadStudentsMutation,
  useCreateStudentMutation,
  useEditStudentRegisterMutation,
  useDeleteStudentInRegisterMutation,
  useApproveStudentRegisterMutation,
  useUpdateStudentNUBANMutation,
  useGetApprovedStudentsQuery,
  useGenerateInvoiceMutation,
  useGetInvoicesQuery,
  useGetStudentInvoicesQuery,
  useGetInvoiceDetailsQuery,
  useSendInvoiceMutation,
  useNotifyOneStudentMutation,
  useGetStudentsByLevelQuery,
  usePromoteStudentsMutation,
  useViewStudentFullDetailsQuery,
  useMakeOfflinePaymentMutation,
  usePayStudentFeesMutation,
  useSendInvoiceReminderMutation,
  useGetInvoiceForEditQuery,
  useGetStudentsByCategoriesQuery,
  usePreviewInvoiceDetailsQuery,
  useGetAllParentsQuery,
  useCreateParentMutation,
  useDownloadTemplateQuery,
  useUpdateStudentMutation,
  useUpdateStudentInvoiceMutation,
  useUpdateStudentInRegisterMutation,
  useGetOfflinePaymentTypesQuery
} = studentSlice;
