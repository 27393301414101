import React from 'react'

const SchoolsIcon = ({fill}) => {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9.7125V20.6562C0 21.0119 0.288078 21.3 0.64375 21.3H3.8625V8.425H1.2875C0.576558 8.425 0 9.00156 0 9.7125ZM14.4844 7.78125H13.5187V6.17187C13.5187 5.99404 13.3747 5.85 13.1969 5.85H12.5531C12.3753 5.85 12.2312 5.99404 12.2312 6.17187V8.74687C12.2312 8.92471 12.3753 9.06875 12.5531 9.06875H14.4844C14.6622 9.06875 14.8062 8.92471 14.8062 8.74687V8.10312C14.8062 7.92529 14.6622 7.78125 14.4844 7.78125ZM20.0267 5.20786L13.5892 0.916059C13.3776 0.775171 13.1292 0.699997 12.875 0.699997C12.6208 0.699997 12.3724 0.775171 12.1608 0.916059L5.72334 5.20786C5.54701 5.32541 5.40243 5.48467 5.30241 5.67151C5.2024 5.85834 5.15004 6.06698 5.15 6.2789V21.3H10.3V15.5062C10.3 15.1506 10.5881 14.8625 10.9437 14.8625H14.8062C15.1619 14.8625 15.45 15.1506 15.45 15.5062V21.3H20.6V6.2793C20.6 5.84879 20.3847 5.44645 20.0267 5.20786ZM12.875 11C11.0974 11 9.65625 9.5588 9.65625 7.78125C9.65625 6.00369 11.0974 4.5625 12.875 4.5625C14.6526 4.5625 16.0937 6.00369 16.0937 7.78125C16.0937 9.5588 14.6526 11 12.875 11ZM24.4625 8.425H21.8875V21.3H25.1062C25.4619 21.3 25.75 21.0119 25.75 20.6562V9.7125C25.75 9.00156 25.1734 8.425 24.4625 8.425Z"
        // fill={active ? "#ffffff" : "#464646"}
        fill={fill}
      />
    </svg>
  );
}

export default SchoolsIcon