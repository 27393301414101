import { useNavigate, useParams } from "react-router-dom";
import backArrow from "../../../../../assets/svgs/backArrow.svg";
import DashboardSidebar from "../../components/dashboardSidebar/sidebar";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useCreateInvoiceMutation,
  useEditInvoiceMutation,
  useGetAllCategoriesInAClassQuery,
  useGetAllClassesQuery,
  useGetAllInstitutionFeesQuery,
  useGetAllStudentsQuery,
  useGetInstitutionLogoQuery,
  useGetInstitutionQuery,
  useGetStudentsByCategoriesQuery,
  usePreviewInvoiceDetailsQuery,
} from "../../../../../redux/features/institution/institutionApiSlice";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import DashboardTopSection from "../../components/dashboardTopSection";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ModalComponent from "../../../../../component/modal/modal";
import Select from "react-select";
//import { useGetStudentsByCategoriesQuery, usePreviewInvoiceDetailsQuery } from "../../../../../redux/features/institution/studentApiSlice";
import ToastComponent from "../../../../../component/toast/toast";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import { addTrailingCommas, capitalizeWords } from "../../../../../utils/utils";
import ButtonX from "../../../../../component/button/button";
import { Type } from "react-bootstrap-icons";
import MultiSelectDropdown from "../../../../../component/select/multiSelectDropdown";
import CreateRegularInvoice from "./createRegularInvoice";
import CreateSpecificInvoice from "./createSpecificInvoice";
import {
  useGetInvoiceForEditQuery,
  useGetStudentInvoicesQuery,
} from "../../../../../redux/features/institution/studentApiSlice";
import SetupFeeManagementComponent from "./setupFeeMgtComponent";
import DownloadStudentInvoiceComponent from "./downloadStudentInvoiceComponent";
import { TurnSharpLeft } from "@mui/icons-material";
import { Document, Page } from "react-pdf";
import Preview from "./preview";
// import addInvoice from "../../../../../assets/svgs/addInvoice.svg";
import addInvoice from "../../../../../assets/svgs/addInvoice.svg";

const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#EDEDED" : "#1c1a1a",
    backgroundColor: state.isSelected ? "#a0a0a0" : "#EDEDED",
    fontSize: 0.8 + "rem",
    //padding: "0px",
  }),

  control: (defaultStyles) => ({
    ...defaultStyles,
    //backgroundColor: "#212529",
    //padding: "10px",
    border: "none",
    boxShadow: "none",
    fontFamily: "jost, sans-serif",
    backgroundColor: "#EDEDED",
    fontSize: 0.8 + "rem",
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
};
const CreateInvoice = () => {
  const { invoiceId } = useParams();
  const isEditing = !!invoiceId;

  const [showRegularInvoice, setShowRegularInvoice] = useState(true);
  const [regularFee, setRegularFee] = useState({
    type: "",
    title: "",
    dueDate: "",
    branches: [],
    feesIds: [],
    selectedStudentsIds: [],
    vatSelected: false,
    sendInvoice: false,
    calenderId: "",
  });
  const {
    title,
    dueDate,
    branches,
    feesIds,
    selectedStudentsIds,
    vatSelected,
    calendarId,
  } = regularFee;
  const [selectedFeeOptions, setSelectedFeeOptions] = useState([]);
  const [selectedStudentOptions, setSelectedStudentOptions] = useState([]);
  const [showApplyToStudent, setShowApplyToStudent] = useState(false);
  const [applyVAT, setApplyVAT] = useState(false);
  const [previewInvoiceParams, setPreviewInvoiceParams] = useState({
    feesIds: [],
    vatSelected: false,
  });
  //const [previewInvoiceParams, setPreviewInvoiceParams] = useState({ });
  const [categoryParamsForStudentList, setCategoryParamsForStudentList] =
    useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showFeeComponentModal, setShowFeeComponentModal] = useState(false);
  const [invId, setInvId] = useState(invoiceId);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const navigate = useNavigate();

  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);
  const { data: institutionDetails } = useGetInstitutionQuery(id);

  const prev_querys = {
    institutionId: id,
    body: previewInvoiceParams,
  };
  console.log(prev_querys, "GRANT");
  const {
    data: invoicePreviewData,
    refetch: refetchInvoicePreview,
    isLoading: invoicePreviewLoading,
  } = usePreviewInvoiceDetailsQuery(prev_querys, {
    skip: !previewInvoiceParams,
  }); // usePreviewInvoiceDetailsQuery( id, regularFee.feeId, `${regularFee.vat}`);

  const stud_querys = {
    institutionId: id,
    body: { categoryIds: categoryParamsForStudentList },
  };

  const getinvquerys = {
    institutionId: id,
    invoiceId: invoiceId,
  };
  const {
    data: invoiceForEditData,
    refetch: refetchInvoiceForEdit,
    isLoading: invoiceForEditLoading,
  } = useGetInvoiceForEditQuery(getinvquerys);

  console.log(invoiceForEditData);

  useEffect(() => {
    if (invoiceId && invoiceForEditData?.type === "NORMAL") {
      refetchInvoiceForEdit();
      setShowRegularInvoice(true);

      setApplyVAT(invoiceForEditData?.vatSelected);

      setRegularFee({
        type: invoiceForEditData?.type,
        title: invoiceForEditData?.title,
        dueDate: invoiceForEditData?.dueDate,
        vatSelected: invoiceForEditData?.vatSelected,
        feeId: [],
        selectedStudentsId: [],
        branch: [],
      });

      const opt = [];
      const selectedfeeoptions = invoiceForEditData?.fees?.map((item) => {
        return item.id; //[...opt, { value: item.id, label: item.feeComponentName }];
      });
      regularFee.feesIds = selectedfeeoptions;
      setSelectedFeeOptions(selectedfeeoptions);

      let studentselectedids = [];
      if (invoiceForEditData?.selectedStudents?.length > 0) {
        const selectedstudentoptions =
          invoiceForEditData?.selectedStudents?.map((item) => {
            return item.id; //[...opt, { value: item.id, label: item.feeComponentName }];
            //return { id: item.id, name: item?.firstname + " " + item?.lastname + " (" + item?.studentNo + ")" }
          });
        regularFee.selectedStudentsIds = selectedstudentoptions;
        setSelectedStudentOptions(selectedstudentoptions);
        setShowApplyToStudent(true);
        studentselectedids = selectedstudentoptions;
      }
      const filteredFeesDataList = feesData?.filter((item) =>
        selectedfeeoptions?.includes(item.id)
      );
      console.log(filteredFeesDataList, 8111111111 + "filetered");

      // Extract all institutionCategoryId values into a single array
      const allCategoryIds = filteredFeesDataList?.flatMap((item) => {
        const myClasses = Object?.values(item.feeStructureClasses);
        const categoryIds = myClasses?.flatMap((myClass) =>
          myClass.map((classItem) => classItem.institutionCategoryId)
        );
        return categoryIds;
      });

      console.log("regularFeesssss", regularFee);

      setCategoryParamsForStudentList(allCategoryIds);

      setRegularFee({
        type: invoiceForEditData?.type,
        title: invoiceForEditData?.title,
        dueDate: invoiceForEditData?.dueDate,
        branches: [],
        feesIds: selectedfeeoptions,
        selectedStudentsIds: studentselectedids,
        vatSelected: invoiceForEditData?.vatSelected,
        sendInvoice: false,
        calenderId: "",
      });
      setPreviewInvoiceParams({
        feesIds: selectedfeeoptions,
        vatSelected: invoiceForEditData?.vatSelected,
      });
    }

    if (invoiceId && invoiceForEditData?.type !== "NORMAL") {
      setShowRegularInvoice(false);
    }
    console.log("2222ForEditData", invoiceForEditData);
    console.log("2222SelectedStudent", selectedStudentOptions);
  }, [invoiceForEditData]);

  // const {
  //   data: studentsData,
  //   refetch: refetchStudents,
  //   isLoading: studentsLoading,
  // } = useGetStudentsByCategoriesQuery(stud_querys);

  const {
    data: studentsData,
    refetch: refetchStudents,
    isLoading: studentsLoading,
  } = useGetAllStudentsQuery(id);

  useEffect(() => {
    if (studentsData) {
      refetchStudents();
    }
  }, [studentsData, categoryParamsForStudentList, invoiceForEditData]);

  useEffect(() => {
    // console.log("invoicePreviewData", invoicePreviewData);
    if (invoicePreviewData) {
      refetchInvoicePreview(); //&& regularFee.feeId.length > 0
    }
  }, [invoicePreviewData, previewInvoiceParams]);

  console.log("INVOICE PREV", invoicePreviewData);

  useEffect(() => {
    if (previewInvoiceParams !== null) {
      // Code that depends on the updated state
      console.log("Your state has been updated:", previewInvoiceParams);
      refetchInvoicePreview();
    }
  }, [previewInvoiceParams]);

  const {
    data: feesData,
    refetch: refetchFees,
    isLoading: feesLoading,
  } = useGetAllInstitutionFeesQuery(id);

  const feesOptions = feesData?.map((item) => {
    return { id: item?.id, name: item?.title };
  });

  const handleFeeOptionsChange = (selected) => {
    setSelectedFeeOptions(selected);
    //setSelectedFeeOptions(selected.map(item => item.id));
    console.log(selected, 9999999);

    const feeIds = selected?.map((item) => {
      return item;
    });
    regularFee.feesIds = feeIds;
    setPreviewInvoiceParams({ feesIds: feeIds });

    const filteredFeesDataList = feesData?.filter((item) =>
      feeIds?.includes(item.id)
    );
    console.log(filteredFeesDataList, 8111111111 + "filetered");

    // Extract all institutionCategoryId values into a single array
    const allCategoryIds = filteredFeesDataList?.flatMap((item) => {
      const myClasses = Object?.values(item.feeStructureClasses);
      const categoryIds = myClasses?.flatMap((myClass) =>
        myClass.map((classItem) => classItem.institutionCategoryId)
      );
      return categoryIds;
    });

    console.log("regularFeesssss", regularFee);

    //console.log(allCategoryIds);
    setCategoryParamsForStudentList(allCategoryIds);
    //onPeviewInvoiceParamsChange(previewInvoiceParams);
    // refetchInvoicePreview();
    //onPeviewInvoiceParamsChange({ feesIds: [...regularFee.feesIds, ...feesIds], vatSelected: previewInvoiceParams.vatSelected });
  };

  console.log("CategoryParamsForStudentList", categoryParamsForStudentList);

  const handlePreviewInvoiceParamsChange = (previewParams) => {
    console.log("PreviewParams ", previewParams);
    setPreviewInvoiceParams(previewParams);

    if (invoicePreviewData) refetchInvoicePreview();
  };

  const handleApplyToStudentCheckbox = (e) => {
    const { name, checked } = e.target;
    setShowApplyToStudent(checked);
    //setShowApplyToStudent(!showApplyToStudent);
    refetchStudents();
    if (!checked) {
      setSelectedStudentOptions([]);
      //regularFee.selectedStudentsIds = [];
    }
  };

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setRegularFee({ ...regularFee, [name]: value });
  };

  const studentsOptions = studentsData?.map((item) => {
    return {
      id: item?.id,
      name:
        item?.firstname +
        " " +
        item?.lastname +
        " (" +
        item?.studentNo +
        ") - " +
        item?.institutionCategory?.institutionLevel?.name +
        " " +
        item?.institutionCategory?.name,
    };
  });
  console.log("studentsOptions ", studentsData);

  const handleStudentsOptionsChange = (selected) => {
    setSelectedStudentOptions(selected);
    console.log(selected, 77777);
    // const studentIds = selected?.map((item) => {
    //   return item.id;
    // })
    // regularFee.selectedStudentsIds = studentIds
    regularFee.selectedStudentsIds = selected;
    console.log(regularFee, 66666);
  };

  const handleApplyVATToggle = (e) => {
    const { name, checked } = e.target;
    setApplyVAT(checked);
    setPreviewInvoiceParams({ ...previewInvoiceParams, vatSelected: checked });
    //regularFee.vat = checked;
    regularFee.vatSelected = checked;
    console.log(regularFee, 555555);
    //setPreviewInvoiceParams({ vatSelected: checked })
    //refetchInvoicePreview();
    //onPeviewInvoiceParamsChange({ feesIds: previewInvoiceParams.feesIds, vatSelected: checked });
  };

  const [createInvoice, { isLoading: addLoading }] = useCreateInvoiceMutation();

  const [updateInvoice, { isLoading: updateLoading }] =
    useEditInvoiceMutation();

  const handleCreateInvoice = async (type) => {
    //if normal/regular invoice, type = true else type = false
    //alert(type);
    if (type) {
      const params = {
        ...regularFee,
        type: "NORMAL",
        sendinvoice: false,
        calenderId: null,
      };
      console.log(params, 160606060);
      try {
        if (!isEditing) {
          const obj = { institutionId: id, body: params };
          const data = await createInvoice(obj).unwrap();
          if (data) {
            setInvId(data.id);
          }
        } else {
          const obj = { institutionId: id, body: params, invoiceId: invoiceId };
          const data = await updateInvoice(obj).unwrap();
        }
        // if (data) {
        //     setNewData(data);
        // }
        setRegularFee({
          type: "",
          title: "",
          dueDate: "",
          vatSelected: false,
          feesIds: [],
          selectedStudentsIds: [],
          branches: [],
        });
        //setShowModal(false);
        setSelectedFeeOptions([]);
        setSelectedStudentOptions([]);
        setApplyVAT(false);
        setShowApplyToStudent(false);
        //console.log(data, "CREATE RESP")
        setShowToast(true);
        setToastMessage("Successful");
        navigate("/invoicing");
      } catch (error) {
        console.log(error.data);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          setToastMessage(error.error);
        }
      }
    }
  };

  const handleCreateAndSendInvoice = async (type) => {
    if (type) {
      const params = {
        ...regularFee,
        type: "NORMAL",
        sendInvoice: true,
        calenderId: null,
      };
      try {
        if (!isEditing) {
          const obj = { institutionId: id, body: params };
          const data = await createInvoice(obj).unwrap();
        } else {
          const obj = { institutionId: id, body: params, invoiceId: invoiceId };
          const data = await updateInvoice(obj).unwrap();
        }

        setRegularFee({
          type: "",
          title: "",
          dueDate: new Date(),
          vatSelected: false,
          feesIds: [],
          selectedStudentsIds: [],
          branches: [],
        });

        setShowToast(true);
        setToastMessage("Successful");
        navigate("/invoicing");
      } catch (error) {
        console.log(error.data);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          setToastMessage(error.error);
        }
      }
    }
  };

  const {
    data: studentInvoicingDetail,
    isLoading: fetchLoading,
    refetch,
  } = useGetStudentInvoicesQuery(invId);

  const openFeeComponentModal = () => {
    setShowFeeComponentModal(true);
  };

  const closeFeeComponentModal = () => {
    refetchFees();
    setShowFeeComponentModal(false);
  };

  const closeDownloadModal = () => {
    setShowDownloadModal(false);
  };

  if (
    feesLoading ||
    studentsLoading ||
    addLoading ||
    invoicePreviewLoading ||
    invoiceForEditLoading
  ) {
    return <OnboardingLoader />;
  }

  // if (invoicePreviewLoading) {
  //     return <OnboardingLoader />;
  // }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <ModalComponent
        title="Create Fee Component"
        showModal={showFeeComponentModal}
        closeModal={closeFeeComponentModal}
        size="lg"
      >
        <form>
          {/* <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo.
          </p> */}
          <SetupFeeManagementComponent
            showFeeMgtModal={setShowFeeComponentModal}
          />

          <Button
            onClick={closeFeeComponentModal}
            variant="primary"
            style={{
              backgroundColor: "#F14810",
              borderColor: "#F14810",
              float: "left",
              marginTop: "20px",
            }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              Close
            </span>
          </Button>
        </form>
      </ModalComponent>

      <ModalComponent
        title="Download Invoice"
        showModal={showDownloadModal}
        closeModal={closeDownloadModal}
        size="lg"
      >
        <form>
          <div id="invoicepreview">
            {studentInvoicingDetail?.map((type, i, arr) => (
              <div>
                <DownloadStudentInvoiceComponent studentInvoiceId={type.id} />
                <br />
                <br />
              </div>
            ))}
          </div>
          <Button
            onClick={closeDownloadModal}
            variant="primary"
            style={{
              backgroundColor: "#F14810",
              borderColor: "#F14810",
              float: "left",
              marginTop: "20px",
            }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              Close
            </span>
          </Button>
          {/* <Button
            onClick={downloadNow}
            variant="primary"
            style={{
              backgroundColor: "#F14810",
              borderColor: "#F14810",
              float: "left",
              marginTop: "20px",
            }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              Download
            </span>
          </Button> */}
        </form>
      </ModalComponent>

      <Container
        fluid
        style={{ display: "flex" }}
        className="dashboardContainer"
      >
        <DashboardSidebar institutionDetails={institutionDetails} />

        <Container fluid className="bodycontainer">
          <DashboardTopSection />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // gap: "20px"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer",
                marginBottom: "10px",
                marginTop: "10px",
              }}
              onClick={() => navigate("/invoicing")}
            >
              <img src={backArrow} alt="Go back" />
              <h6 style={{ marginBottom: "0px" }}>
                {isEditing && "Edit Invoice"}
                {!isEditing && "Create Invoice"}
              </h6>
            </div>
          </div>

          <Row>
            <Col xs={12} md={6} lg={7}>
              <div>
                <div
                  style={{
                    border: "2px solid #EDEDED",
                    borderRadius: "8px",
                    gap: "30px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <div
                    style={{
                      borderBottom: "2px solid #EDEDED",
                      padding: "10px",
                      gap: "10px",
                      marginBottom: "15px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        color: "#F14810",
                      }}
                    >
                      <img src={addInvoice} alt="create invoice" />
                      <h6 style={{ fontWeight: "600", marginTop: "10px" }}>
                        {isEditing && <h6>Edit Invoice</h6>}
                        {!isEditing && <h6>Create Invoice</h6>}
                      </h6>
                    </div>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 5 }}
                    ></div>
                  </div>

                  {!isEditing && (
                    <div>
                      <Button
                        variant="primary"
                        style={{
                          border: "1px solid",
                          borderRadius: "50px",
                          backgroundColor: `${showRegularInvoice === true ? "#ffede8" : "#f4f4f4"
                            }`,
                          borderColor: "#F14810",
                          //marginTop: "8px",
                          marginLeft: "20px",
                          // width: "auto",
                          // marginBottom: 2 + 'rem',
                          // marginLeft: "15px"
                        }}
                        size="md"
                        onClick={(e) => setShowRegularInvoice(true)}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "2px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: 0.8 + "rem",
                              color: "black",
                              marginLeft: 0.5 + "rem",
                              marginRight: 0.5 + "rem",
                            }}
                          >
                            Regular Invoice
                          </span>
                        </div>
                      </Button>
                      <Button
                        variant="primary"
                        style={{
                          border: "1px solid",
                          borderRadius: "50px",
                          backgroundColor: `${showRegularInvoice === false ? "#ffede8" : "#f4f4f4"
                            }`,
                          borderColor: "#F14810",
                          marginLeft: "10px",
                        }}
                        size="md"
                        onClick={(e) => setShowRegularInvoice(false)}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "2px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: 0.8 + "rem",
                              color: "black",
                              marginLeft: 0.5 + "rem",
                              marginRight: 0.5 + "rem",
                            }}
                          >
                            Specific Invoice
                          </span>
                        </div>
                      </Button>
                    </div>
                  )}

                  {showRegularInvoice && (
                    <div style={{ padding: "20px" }}>
                      <Form className="form">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="formClasslabel">
                            Invoice Title{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>

                          <Form.Control
                            size="md"
                            className="custom-focus"
                            style={{
                              fontFamily: "jost, sans-serif",
                              backgroundColor: "#EDEDED",
                              fontSize: 0.8 + "rem",
                            }}
                            type="text"
                            name="title"
                            value={title}
                            onChange={(e) => handleOnChange(e)}
                            placeholder="Enter Invoice Title"
                            required
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formMultiSelect"
                        >
                          <Form.Label className="formClasslabel">
                            Fee Structure{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>

                          <MultiSelectDropdown
                            options={feesOptions || []}
                            selectedItems={selectedFeeOptions}
                            onSelect={handleFeeOptionsChange}
                          //selectedItemNames={selectedFeeOptionsNames}
                          />
                        </Form.Group>

                        <div style={{ marginTop: "0px", marginBottom: "12px" }}>
                          <span
                            style={{
                              color: "#F14810",
                              fontWeight: "500",
                              cursor: "pointer",
                            }}
                            onClick={openFeeComponentModal}
                          >
                            + Add Fee
                          </span>
                        </div>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="formClasslabel">
                            Invoice Due Date{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>

                          <Form.Control
                            size="md"
                            className="custom-focus"
                            style={{
                              fontFamily: "jost, sans-serif",
                              backgroundColor: "#EDEDED",
                              fontSize: 0.8 + "rem",
                            }}
                            type="date"
                            name="dueDate"
                            value={dueDate}
                            onChange={(e) => handleOnChange(e)}
                            placeholder="Select Invoice Due Date"
                            required
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.CheckboxInput"
                        >
                          <Form.Check
                            size="md"
                            className="custom-focus"
                            style={{
                              //border: "1px solid",
                              borderColor: "#F14810",
                            }}
                            type="checkbox"
                            name="applytostudent"
                            //value={lastname}
                            checked={showApplyToStudent}
                            onChange={handleApplyToStudentCheckbox}
                            placeholder="Apply to Student(s)"
                            inline={true}
                          />
                          <Form.Label className="formClasslabel">
                            Apply To Student(s)
                          </Form.Label>
                        </Form.Group>
                        {showApplyToStudent && (
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="formClasslabel">
                              Student(s)
                            </Form.Label>

                            <MultiSelectDropdown
                              options={studentsOptions || []}
                              selectedItems={selectedStudentOptions}
                              onSelect={handleStudentsOptionsChange}
                            //selectedItemNames={selectedFeeOptionsNames}
                            />
                          </Form.Group>
                        )}
                        {/* <Form.Group className="mb-3" controlId="exampleForm.CheckboxInput">

                          <Form.Check
                            size="md"
                            className="custom-focus"
                            style={{
                              //border: "1px solid",
                              borderColor: "#F14810"
                            }}
                            type="switch"
                            name="vat"
                            value={vatSelected}
                            checked={vatSelected}
                            onChange={handleApplyVATToggle}
                            //placeholder="Apply to Student(s)"
                            inline={true}
                          />
                          <Form.Label className="formClasslabel">
                            Apply VAT (7.5%)
                          </Form.Label>
                        </Form.Group> */}
                        <Row>
                          <Col>
                            <Button
                              size="md"
                              style={{
                                backgroundColor: "#F14810",
                                color: "#ffffff",
                                border: "2px solid",
                                borderColor: "#F14810",
                                outline: "none",
                                fontSize: "14px",
                                border: "2px solid #F14810",
                                textDecoration: "none",
                              }}
                              onClick={() => handleCreateAndSendInvoice(true)}
                            >
                              {isEditing && <span>Edit & Send Invoice</span>}
                              {!isEditing && <span>Create & Send Invoice</span>}
                            </Button>

                            <Button
                              size="md"
                              style={{
                                backgroundColor: "#FFF",
                                color: "#F14810",
                                fontSize: "14px",
                                border: "2px solid #F14810",
                                marginLeft: "10px",
                                textDecoration: "none",
                              }}
                              onClick={() => handleCreateInvoice(true)}
                            >
                              {isEditing && <span>Edit Invoice</span>}
                              {!isEditing && <span>Create Invoice</span>}
                            </Button>

                            {/* <ButtonX
                                backgroundColor="#FFF"
                                border="#f14810"
                                color="#f14810"
                                onClick={() => handleCreateInvoice(true)}
                                overlay={true}
                                overlayText="Save Invoice and Send Later"
                            >
                                {isEditing && (<span>Edit Invoice</span>)}
                                {!isEditing && (<span>Create Invoice</span>)}
                            </ButtonX> */}
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  )}

                  {!showRegularInvoice && (
                    <CreateSpecificInvoice
                      onPeviewInvoiceParamsChange={
                        handlePreviewInvoiceParamsChange
                      }
                    />
                  )}
                </div>
              </div>
            </Col>

            <Preview
              title={regularFee?.title}
              dueDate={regularFee?.dueDate.toString()}
              schoolName={invoicePreviewData?.schoolName}
              classes={invoicePreviewData?.classes}
              fees={invoicePreviewData?.fees}
              total={invoicePreviewData?.total}
              subTotal={invoicePreviewData?.subTotal}
              selectedStudents={invoicePreviewData?.selectedStudents}
              type={showRegularInvoice}
              invoiceId={invId}
            />
          </Row>

          <br />
          <br />
        </Container>
      </Container>
    </>
  );
};

export default CreateInvoice;
