import { Form, Row, Col, InputGroup, Button } from "react-bootstrap";
import ButtonX from "../../../../component/button/button";
import maleIcon from "../../../../assets/svgs/maleAvatar.svg";
import femaleIcon from "../../../../assets/svgs/femaleAvatar.svg";
import changeImage from "../../../../assets/svgs/changeImageIcon.svg";
import successGIF from "../../../../assets/svgs/successGIF.gif";
// import Button from "../../../../../component/button/button";
import ModalComponent from "../../../../component/modal/modal";
import {
  useGetAdminUserProfileQuery,
  useUpdateAdminUserProfileMutation,
  useChangeAdminPasswordMutation,
  useGetRolesQuery,
} from "../../../../redux/features/superAdmin/adminApiSlice";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../../../utils/utils";
import { useState } from "react";
import { useEffect } from "react";
import { TelephonePlus } from "react-bootstrap-icons";
import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";
import SuccessScreen from "../../../../component/successScreen/successScreen";
import showPasswordIcon from "../../../../assets/svgs/showPassword.svg";
import hidePasswordIcon from "../../../../assets/svgs/hidePassword.svg";

const AdminProfile = () => {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [timeOut, setTimeout] = useState("");
  const [showPasswordUpdateSuccess, setShowPasswordUpdateSuccess] =
    useState(false);
  const [showProfileUpdateSuccess, setShowProfileUpdateSuccess] =
    useState(false);

  const [isMinCharsValid, setIsMinCharsValid] = useState(false);
  const [isUppercaseValid, setIsUppercaseValid] = useState(false);
  const [isLowercaseValid, setIsLowercaseValid] = useState(false);
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [isSpecialCharValid, setIsSpecialCharValid] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    setNewPasswordData({
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    });
  };

  const userId = useSelector((state) => state?.auth?.userInfo?.userId);

  const {
    data: userProfile,
    isLoading: userProfileLoading,
    refetch: refetchProfile,
  } = useGetAdminUserProfileQuery();

  console.log("Profile", userProfile);
  // console.log("User Role", userProfile?.role?.id)

  const { data: roleList, isLoading: roleListLoading } = useGetRolesQuery();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dates = Array.from({ length: 31 }, (_, index) =>
    (index + 1).toString()
  );

  const [
    updateProfile,
    { isLoading: updateLoading, isSuccess: updateSuccess },
  ] = useUpdateAdminUserProfileMutation();

  const [
    changePassword,
    { isLoading: changePasswordLoading, isSuccess: changePasswordSuccess },
  ] = useChangeAdminPasswordMutation();

  const [personalDetails, setPersonalDetails] = useState({
    firstName: userProfile?.firstName,
    lastName: userProfile?.lastName,
    // dateOfBirth: {
    //   dayOfMonth: institutionDetails?.createdBy?.dateOfBirth?.dayOfMonth,
    //   month: institutionDetails?.createdBy?.dateOfBirth?.month,
    // },
    email: userProfile?.email,
    phoneNumber: userProfile?.phoneNumber || "",
    roleId: userProfile?.role || "",
  });

  useEffect(() => {
    setPersonalDetails({
      firstName: userProfile?.firstName,
      lastName: userProfile?.lastName,
      // dateOfBirth: {
      //   dayOfMonth: institutionDetails?.createdBy?.dateOfBirth?.dayOfMonth,
      //   month: institutionDetails?.createdBy?.dateOfBirth?.month,
      // },
      email: userProfile?.email,
      phoneNumber: userProfile?.phoneNumber || "",
      roleId: userProfile?.role || "",
    });
  }, [userProfile]);

  const { firstName, lastName, dateOfBirth, email, phoneNumber, roleId } =
    personalDetails;

  console.log("ROLE", roleId);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    const [objectKey, nestedKey] = name.split(".");
    if (nestedKey) {
      setPersonalDetails((previousData) => ({
        ...previousData,
        [objectKey]: { ...previousData[objectKey], [nestedKey]: value },
      }));
    } else {
      setPersonalDetails({ ...personalDetails, [name]: value });
    }
  };

  // Change Password Code Start --------->>>>>>>>>

  const [newPasswordData, setNewPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const { oldPassword, newPassword, confirmNewPassword } = newPasswordData;

  const handleOnChangePassword = (e) => {
    const { name, value } = e.target;
    setNewPasswordData({ ...newPasswordData, [name]: value });

    // Check each condition for the password validation
    if (name === "newPassword") {
      setIsMinCharsValid(value.length >= 8);
      setIsUppercaseValid(/[A-Z]/.test(value));
      setIsLowercaseValid(/[a-z]/.test(value));
      setIsNumberValid(/\d/.test(value));
      setIsSpecialCharValid(/[@#$%^&+=]/.test(value));
    }
  };

  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const [passMatch, setPassMatch] = useState(false);

  useEffect(() => {
    validatePassword();
  }, [newPasswordData]);

  useEffect(() => {
    if (userProfile) refetchProfile();
  }, []);

  const validatePassword = () => {
    if (newPassword != "") {
      newPassword === confirmNewPassword
        ? setPassMatch(true)
        : setPassMatch(false);
    }
  };

  const handleChangePassword = async () => {
    // test if the password is now valid before submitting
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}$/;

    if (!passwordRegex.test(newPassword)) {
      setIsPasswordValid(false);
      return;
    }

    try {
      setIsPasswordValid(true);

      const res = await changePassword({
        oldPassword,
        newPassword,
      }).unwrap();
      console.log(res);
      // setShowToast(true);
      // setToastMessage("Password changed successfully!")
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        // setToastMessage(error.data.detail);
        setErrorMessage(error.data.detail);
      } else {
        // other errors
        // setToastMessage(error.error);
        setErrorMessage(error.error);
      }
    }
  };

  // Change Password Code End --------->>>>>>>>>

  const handleSave = async () => {
    const params = {
      // userId: userProfile?.id,
      id: userProfile?.id,
      firstName: personalDetails?.firstName,
      lastName: personalDetails?.lastName,
      // dateOfBirth: {
      //   dayOfMonth: personalDetails.dateOfBirth.dayOfMonth,
      //   month: personalDetails.dateOfBirth.month,
      // },
      phoneNumber: personalDetails?.phoneNumber,
      roleId: personalDetails?.roleId,
    };
    console.log(params);
    try {
      const res = await updateProfile(params).unwrap();
      console.log(res);
      // setShowToast(true);
      // setToastMessage("Profile Information Updated successfully!")
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  if (userProfileLoading || updateLoading || changePasswordLoading) {
    return <OnboardingLoader />;
  }

  if (updateSuccess) {
    return (
      <SuccessScreen successMessage="Profile Information Updated successfully!" />
    );
  }

  if (changePasswordSuccess) {
    return (
      <SuccessScreen successMessage="Password changed successfully! Kindly use your new password to login next time." />
    );
  }

  return (
    <>
      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "8px",
          padding: "15px 30px",
          display: "flex",
          gap: "20px",
          marginBottom: "20px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              width: "90px",
              height: "90px",
              borderRadius: "100%",
              overflow: "hidden",
            }}
          >
            <img src={maleIcon} alt="user image" width="100%" />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: "-5px",
              zIndex: 10,
            }}
          ></div>
        </div>
        <div>
          <h4 style={{ marginBottom: 0, color: "#F14810" }}>
            {capitalizeFirstLetter(userProfile?.firstName) +
              " " +
              capitalizeFirstLetter(userProfile?.lastName)}
          </h4>
          <p style={{ marginBottom: 0, fontSize: "14px" }}>
            {userProfile?.email}
          </p>
        </div>
      </div>
      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "8px",
          padding: "15px",
          // display: "flex",
          gap: "30px",
        }}
      >
        <div style={{ flexBasis: "40%" }}>
          <h6>Edit Personal Details</h6>
          <p> </p>
        </div>
        <div style={{ flexBasis: "60%" }}>
          <Row>
            <Col>
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">First Name</Form.Label>

                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type="text"
                  // name="firstName"
                  // value={firstName}
                  name="firstName"
                  value={firstName}
                  onChange={handleOnChange}
                  placeholder="Enter First Name"
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">Last Name</Form.Label>

                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type="text"
                  // name="lastName"
                  // value={lastName}
                  name="lastName"
                  value={lastName}
                  onChange={handleOnChange}
                  placeholder="Enter Last Name"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Form.Label className="formClasslabel">Date of Birth</Form.Label>
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Select
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  name="dateOfBirth.dayOfMonth"
                  value={institutionDetails?.dateOfBirth?.dayOfMonth}
                  onChange={handleOnChange}
                  required
                >
                  <option value="" defaultValue disabled>
                    Select Date
                  </option>
                  {dates.map((date, index) => (
                    <option key={index} value={date}>
                      {date}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col>
              <Form.Label className="formClasslabel">Month</Form.Label>
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Select
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  name="dateOfBirth.month"
                  value={institutionDetails?.dateOfBirth?.month}
                  onChange={handleOnChange}
                  placeholder="Select Month"
                  required
                >
                  <option value="" defaultValue disabled>
                    Select Month
                  </option>
                  {months.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row> */}

          {/* <Form.Group
            className="mb-2"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="formClasslabel">
              Email Address
            </Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
                cursor: "not-allowed",
              }}
              type="email"
              disabled
              name="email"
              value={email}
              onChange={handleOnChange}
              placeholder="Enter Email Address"
              required
            />
          </Form.Group> */}

          <Row>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">Phone Number</Form.Label>

                <InputGroup className="mb-3">
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ backgroundColor: "#F14810", color: "#ffffff" }}
                  >
                    <TelephonePlus size={22} />
                  </InputGroup.Text>
                  <Form.Control
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    type="text"
                    name="phoneNumber"
                    // value={phoneNumber}
                    value={phoneNumber}
                    onChange={handleOnChange}
                    placeholder="Enter Phone number"
                    required
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">Role</Form.Label>

                <Form.Select
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  name="roleId"
                  value={roleId}
                  onChange={handleOnChange}
                  required
                >
                  <option value="" disabled selected>
                    Select Role
                  </option>
                  {roleList &&
                    roleList?.map(({ name, id }) => (
                      <option key={id} value={id} selected={roleId?.id === id}>
                        {name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <div
            style={{
              // padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Button
              className="button"
              size="md"
              style={{
                backgroundColor: "#F14810",
                color: "#ffffff",
                border: "none",
                outline: "none",
              }}
              onClick={handleSave}
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.3 + "rem",
                  marginRight: 0.3 + "rem",
                }}
              >
                Save Changes
              </span>
            </Button>
          </div>
        </div>
      </div>

      {/* Change Password Code Down -------- */}

      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "8px",
          padding: "15px",
          marginTop: 10 + "px",
          gap: "30px",
        }}
      >
        <div style={{ flexBasis: "40%" }}>
          <h6>Change Password</h6>
          <p>
            To change your password simply click "Change Password" and follow
            the steps. Keep in mind that you will have to use the newly created
            passord on your next login.{" "}
          </p>

          <div
            style={{
              // padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Button
              className="button"
              size="md"
              style={{
                backgroundColor: "#F14810",
                color: "#ffffff",
                border: "none",
                outline: "none",
              }}
              onClick={() => setShowModal(true)}
              title="Click this button to change your password"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.3 + "rem",
                  marginRight: 0.3 + "rem",
                }}
              >
                Change Password
              </span>
            </Button>
          </div>
        </div>

        {/* password update success modal */}
        {/* <ModalComponent
          showModal={showPasswordUpdateSuccess}
          closeModal={() => setShowPasswordUpdateSuccess(false)}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={successGIF}
              alt="success Gif"
              width="150px"
              style={{ margin: "0 auto" }}
            />
            <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!</h3>
            <p style={{ fontSize: "18px" }}>
              Password changed successfully! Kindly use your new password to login next time.
            </p>
          </div>
        </ModalComponent> */}

        {/* profile update success modal */}
        {/* <ModalComponent
          showModal={showProfileUpdateSuccess}
          closeModal={() => setShowProfileUpdateSuccess(false)}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={successGIF}
              alt="success Gif"
              width="150px"
              style={{ margin: "0 auto" }}
            />
            <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!</h3>
            <p style={{ fontSize: "18px" }}>
              Profile Information Updated successfully!
            </p>
          </div>
        </ModalComponent> */}

        {/* change Password modal */}
        <ModalComponent
          title="Change Password"
          showModal={showModal}
          closeModal={handleCloseModal}
        >
          {errorMessage && (
            <span style={{ color: "red", fontStyle: "italic" }}>
              {errorMessage}
            </span>
          )}

          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">Password</Form.Label>

              <div style={{ position: "relative" }} className="password-box">
                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type={showPassword ? "text" : "password"}
                  name="oldPassword"
                  value={oldPassword}
                  autoComplete="off"
                  onChange={handleOnChangePassword}
                  placeholder="Enter Old Password"
                />
                {showPassword ? (
                  <img
                    src={hidePasswordIcon}
                    alt="showPassord Icon"
                    onClick={() => setShowPassword(false)}
                    className="hidePasswordIcon"
                  />
                ) : (
                  <img
                    src={showPasswordIcon}
                    alt="showPassord Icon"
                    onClick={() => setShowPassword(true)}
                    className="showPasswordIcon"
                  />
                )}
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">New Password</Form.Label>

              <div style={{ position: "relative" }} className="password-box">
                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type={showNewPassword ? "text" : "password"}
                  name="newPassword"
                  value={newPassword}
                  autoComplete="off"
                  onChange={handleOnChangePassword}
                  placeholder="Enter New Password"
                />
                {showNewPassword ? (
                  <img
                    src={hidePasswordIcon}
                    alt="showPassord Icon"
                    onClick={() => setShowNewPassword(false)}
                    className="hidePasswordIcon"
                  />
                ) : (
                  <img
                    src={showPasswordIcon}
                    alt="showPassord Icon"
                    onClick={() => setShowNewPassword(true)}
                    className="showPasswordIcon"
                  />
                )}
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                Confirm Password
              </Form.Label>

              <div style={{ position: "relative" }} className="password-box">
                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmNewPassword"
                  value={confirmNewPassword}
                  autoComplete="off"
                  onChange={handleOnChangePassword}
                  placeholder="Confirm New Password"
                />
                {showConfirmPassword ? (
                  <img
                    src={hidePasswordIcon}
                    alt="showPassord Icon"
                    onClick={() => setShowConfirmPassword(false)}
                    className="hidePasswordIcon"
                  />
                ) : (
                  <img
                    src={showPasswordIcon}
                    alt="showPassord Icon"
                    onClick={() => setShowConfirmPassword(true)}
                    className="showPasswordIcon"
                  />
                )}
              </div>
            </Form.Group>

            <ul style={{ listStyle: "none", marginLeft: -2 + "rem" }}>
              <li
                className="fst-italic"
                style={{
                  color: "red",
                  fontFamily: "jost, sans-serif",
                  fontSize: 0.85 + "rem",
                }}
              >
                Password must contain:
              </li>

              <li
                className="fst-italic"
                style={{
                  fontFamily: "jost, sans-serif",
                  fontSize: 0.85 + "rem",
                  color: !isMinCharsValid ? "red" : "#2bb4b1",
                }}
              >
                Minimum of 8 characters
              </li>

              <li
                className="fst-italic"
                style={{
                  fontFamily: "jost, sans-serif",
                  fontSize: 0.85 + "rem",
                  color: !passMatch ? "red" : "#2bb4b1",
                }}
              >
                Passwords Match
              </li>

              <li
                className="fst-italic"
                style={{
                  fontFamily: "jost, sans-serif",
                  fontSize: 0.85 + "rem",
                  color:
                    !isLowercaseValid || !isUppercaseValid ? "red" : "#2bb4b1",
                }}
              >
                one uppercase letter and one lowercase letter
              </li>

              <li
                className="fst-italic"
                style={{
                  fontFamily: "jost, sans-serif",
                  fontSize: 0.85 + "rem",
                  color: !isNumberValid ? "red" : "#2bb4b1",
                }}
              >
                one number
              </li>

              <li
                className="fst-italic"
                style={{
                  fontFamily: "jost, sans-serif",
                  fontSize: 0.85 + "rem",
                  color: !isSpecialCharValid ? "red" : "#2bb4b1",
                }}
              >
                one special character
              </li>
            </ul>

            <div style={{ marginTop: "30px" }}>
              <ButtonX
                onClick={!passMatch ? null : () => handleChangePassword()}
                backgroundColor={`${
                  !passMatch ? "#FFCAB8" : "rgb(241, 72, 16)"
                }`}
                cursor={`${!passMatch ? "not-allowed" : "pointer"}`}
              >
                Save Changes
              </ButtonX>
            </div>
          </Form>
        </ModalComponent>
      </div>
    </>
  );
};

export default AdminProfile;
