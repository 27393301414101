import React, { useEffect, useState } from "react";
import { Container, Navbar, Image, Nav, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Link as LinkRoll } from "react-scroll";

import { Fade as Hamburger } from "hamburger-react";
import logo from "../assets/images/logo.svg";
import { logOut } from "../redux/features/auth/authSlice";

import "../index.css";
import { useSelector, useDispatch } from "react-redux";



function StickyNavbar() {
  const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
      // Scroll to the top of the page whenever the location changes
      window.scrollTo(0, 0);
    }, [location]);

    return null;
  };

  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(false);

  // const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
  // const user = useSelector(state => state?.auth?.userInfo)
  // console.log(user)

  // useEffect(()=>{
  //   if(user) {
  //       setIsUserLoggedIn(true)
  //   } else {
  //       setIsUserLoggedIn(false)
  //   }
  // }, [user])

  // const onLogOut = () => {
  //   console.log("clicked........")
  //   dispatch(logOut())
  // }

  const handleLinkClick = () => {
    setExpanded(false); // Close the Navbar when a link is clicked
  };

  const isMobile = window.innerWidth <= 767;

  const Harmburgerstyle = {
    color: "#F14810",
    outline: "none",
    border: 0,
  };

  return (
    <Navbar
      sticky="top"
      id="navbar"
      expanded={expanded}
      expand="lg"
      className="NavBarBody"
    >
      <Container fluid className="navcontainer">
        <ScrollToTop />
        <Link to="./">
          <Navbar.Brand>
            <Image src={logo} fluid />
          </Navbar.Brand>
        </Link>

        <Navbar.Toggle
          className="hamburger"
          aria-controls="basic-navbar-nav"
          style={Harmburgerstyle}
          onClick={() => setExpanded(!expanded)}
        >
          <Hamburger toggled={expanded} duration={0.8} hideOutline={false} />
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav" className="navChildrenWrap">
          <Nav className="me-auto"></Nav>
          <Nav className="navwrap">
            <LinkRoll
              to="about"
              spy={true}
              smooth={true}
              offset={isMobile ? -290 : -70}
              duration={100}
              onClick={handleLinkClick}
            >
              <NavLink className="nav-link" style={{ textDecoration: "none" }}>
                About Us
              </NavLink>
            </LinkRoll>

            <LinkRoll
              to="feature"
              spy={true}
              smooth={true}
              offset={isMobile ? -290 : -70}
              duration={100}
              onClick={handleLinkClick}
            >
              <NavLink
                className="nav-link"
                style={{ textDecoration: "none" }}
                to="/"
              >
                Features
              </NavLink>
            </LinkRoll>

            <LinkRoll
              to="howitwork"
              spy={true}
              smooth={true}
              offset={isMobile ? -290 : -70}
              duration={100}
              onClick={handleLinkClick}
            >
              <NavLink
                className="nav-link"
                style={{ textDecoration: "none" }}
                to="/"
              >
                How it works
              </NavLink>
            </LinkRoll>

            <LinkRoll
              to="contact"
              spy={true}
              smooth={true}
              offset={isMobile ? -290 : -70}
              duration={100}
              onClick={handleLinkClick}
            >
              <NavLink
                className="nav-link"
                style={{ textDecoration: "none" }}
                to="/"
              >
                Contact Us
              </NavLink>
            </LinkRoll>

            <div className="buttonbody">
                <Link to="/login">
                  <Button
                    variant="outline-primary"
                    style={{ borderColor: "#F14810", color: "#F14810" }}
                    className="button"
                    size="md"
                  >
                    <span
                      style={{
                        fontSize: 0.8 + "rem",
                        marginLeft: 0.5 + "rem",
                        marginRight: 0.5 + "rem",
                      }}
                    >
                      Login
                    </span>
                  </Button>
                </Link>
                <Link to="/register">
                  <Button
                    variant="primary"
                    style={{
                      backgroundColor: "#F14810",
                      borderColor: "#F14810",
                      marginLeft: "10px",
                    }}
                    className="button"
                    size="md"
                  >
                    <span
                      style={{
                        fontSize: 0.8 + "rem",
                        marginLeft: 0.5 + "rem",
                        marginRight: 0.5 + "rem",
                      }}
                    >
                      Register
                    </span>
                  </Button>
                </Link>
              {/* {isUserLoggedIn && (
                <Button
                  variant="primary"
                  style={{
                    backgroundColor: "#F14810",
                    borderColor: "#F14810",
                  }}
                  className="button"
                  size="md"
                  onClick={onLogOut}
                >
                  <span
                    style={{
                      fontSize: 0.8 + "rem",
                      marginLeft: 0.5 + "rem",
                      marginRight: 0.5 + "rem",
                    }}
                  >
                    LogOut
                  </span>
                </Button>
              )} */}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default StickyNavbar;
