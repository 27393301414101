import React from 'react';
import { Modal, Button } from 'react-bootstrap';


const VideoModal = ({ show, handleClose, videoId, videoTitle }) => {
    return (
        <Modal show={show} onHide={handleClose} centered size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{videoTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="embed-responsive embed-responsive-16by9">
                    {/* <iframe
                        className="embed-responsive-item"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        allowFullScreen  
                        title={videoTitle}
                    ></iframe> */}
                    <iframe className="embed-responsive-item w-100" height="460" src={`https://www.youtube.com/embed/${videoId}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
                    </iframe>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default VideoModal;
