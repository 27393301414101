import { useState, useEffect } from "react";
import "./institutionCalendarSetup.css";
import createLevelImage from "../../../../../../assets/images/createLevelImage.svg";
import alertIcon from "../../../../../../assets/svgs/alertIcon.svg";
import { Table, Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./institutionCalendarSetup.css";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";

import ModalComponent from "../../../../../../component/modal/modal";
import { useSelector } from "react-redux";
import {
  useGetInstitutionYearQuery,
  useCreateInstitutionYearMutation,
  useCreateInstitutionPeriodMutation,
  useGetInstitutionPeriodsQuery,
  useDeleteInstitutionPeriodMutation,
  useUpdateInstitutionPeriodMutation,
  // useGetInstitutionYearQuery
} from "../../../../../../redux/features/institution/institutionApiSlice";
import ActionIcon from "../actionbutton/ActionIcon";
import ToastComponent from "../../../../../../component/toast/toast";

const tableCalendarHeaders = [
  "ID",
  "Period",
  "Start Date",
  "End Date",
  "Status",
  "Action",
];

const InstitutionCalendarPage = ({ setActiveStage, institutionDetails }) => {
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showPeriods, setShowPeriods] = useState(false);
  const [showCalendars, setShowCalendars] = useState(false);
  const [showYearModal, setShowYearModal] = useState(false);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [editedToggled, setEditedToggle] = useState(false);
  const [newYearData, setNewYearData] = useState(null);
  const [yearId, setYearId] = useState(null);
  const [newPeriod, setNewPeriod] = useState(null);
  const [deletedElementID, setDeletedElementID] = useState(null);
  const [editedElement, setEditedElement] = useState(null);
  const [editingElement, setEditingElement] = useState(null);
  const [editingValue, setEditingValue] = useState({});
  const [elementToDelete, setElementToDelete] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  const handleEditToggle = () => {
    setEditedToggle((prevState) => !prevState);
  };

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const {
    data: institutionYears,
    refetch: refetchYears,
    isLoading: yearsLoading,
  } = useGetInstitutionYearQuery(instId);

  console.log(institutionYears);

  useEffect(() => {
    if (institutionYears) {
      setYearId(institutionYears[0]?.id);
    }
  }, [institutionYears]);

  console.log(yearId);

  const parameters = {
    institutionId: instId,
    schoolYearId: yearId,
  };

  const {
    data: institutionPeriods,
    refetch: refetchPeriods,
    isLoading: periodsLoading,
  } = useGetInstitutionPeriodsQuery(parameters, {
    skip: !institutionYears || !yearId,
  });

  console.log(newYearData);
  console.log(institutionPeriods);

  // const {data: years} = useGetInstitutionYearQuery(instId, {skip: !institutionPeriods})

  // console.log(years[0])

  const [
    createInstitutionYear,
    { isLoading: addYearLoading, isSuccess: addYearSuccess },
  ] = useCreateInstitutionYearMutation();
  const [createInstitutionPeriod, { isLoading: addPeriodLoading }] =
    useCreateInstitutionPeriodMutation();
  const [updateInstitutionPeriod, { isLoading: updatePeriodLoading }] =
    useUpdateInstitutionPeriodMutation();
  const [deleteInstitutionPeriod, { isLoading: deletePeriodLoading }] =
    useDeleteInstitutionPeriodMutation();

  const handleEditPeriod = (el) => {
    console.log("edit was clicked for ", el);
    setEditingElement(el);
    setShowCalendarModal(true);
    setEditingValue({
      editedPeriodName: el.periodName,
      editedStartDate: el.startDate,
      editedEndDate: el.endDate,
    });
    setEditedToggle(el.open);
  };

  const confirmDelete = (el) => {
    setElementToDelete(el);
    setShowConfirmDelete(true);
  };

  const handleDeletePeriod = async () => {
    console.log("delete was clicked for ", elementToDelete);
    setShowConfirmDelete(false);

    try {
      const parameters = {
        periodId: elementToDelete?.id,
        body: { name: elementToDelete.name },
      };
      await deleteInstitutionPeriod(parameters).unwrap();
      setDeletedElementID(elementToDelete.id);
      
    } catch (error) {
      console.log(error);
      // alert(error.data);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleCloseYearModal = () => setShowYearModal(false);

  const [yearName, setYearName] = useState({
    name: "",
  });

  // const { name: nameOfyear } = yearName;
  const { name } = yearName;

  const handleYearOnChange = (e) => {
    const { name, value } = e.target;
    setYearName({ ...yearName, [name]: value });
  };

  const handleCreateYear = async (e) => {
    e.preventDefault();

    if (name === "") {
      setIsFormNotFilled(true);

      setTimeout(() => {
        setIsFormNotFilled(false);
      }, 3000);

      return;
    }

    setShowYearModal(false);

    try {
      const parameters = {
        institutionId: instId,
        body: yearName,
      };
      // submit with institutionId also
      const data = await createInstitutionYear(parameters).unwrap();

      if (data) {
        console.log(data);
        setNewYearData(data);
        setYearId(data.id);
       

        // immediately open the calendar modal
        setShowCalendarModal(true);
      }
    } catch (error) {
      console.log(error);
      // alert(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  console.log(newYearData);
  console.log(yearId);

  const handleCloseCalendarModal = () => {
    setShowCalendarModal(false);
    setEditingElement(null);
    setEditingValue({
      editedPeriodName: "",
      editedStartDate: "",
      editedEndDate: "",
    });
  };

  console.log(institutionPeriods);

  // const [addInstitutionPeriod] = useAddInstitutionPeriodMutation();

  const [newPeriodData, setNewPeriodData] = useState({
    periodName: "",
    startDate: "",
    endDate: "",
  });

  const { startDate, endDate, periodName } = newPeriodData;

  const { editedPeriodName, editedEndDate, editedStartDate } = editingValue;

  const handleCalendarOnChange = (e) => {
    const { name, value } = e.target;
    setNewPeriodData({ ...newPeriodData, [name]: value });
    setEditingValue({ ...editingValue, [name]: value });
  };

  const handleCreateOrUpdatePeriod = async (e, type) => {
    e.preventDefault();

    if (type === "edit") {
      if (
        editedPeriodName === "" ||
        editedStartDate === "" ||
        editedEndDate === ""
      ) {
        setIsFormNotFilled(true);

        setTimeout(() => {
          setIsFormNotFilled(false);
        }, 3000);

        return;
      }

      try {
        const parameters = {
          periodId: editingElement?.id,
          body: {
            periodName: editedPeriodName,
            startDate: editedStartDate,
            endDate: editedEndDate,
            open: editedToggled,
          },
        };
        // console.log(parameters)
        const res = await updateInstitutionPeriod(parameters).unwrap();

        if (res) {
          setEditedElement(res);
          setEditingValue({});
          setEditedToggle(false);
          setEditingElement(null);
          setShowCalendarModal(false);
          
        }
      } catch (error) {
        console.log(error);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
      return;
    } else {
      if (periodName === "" || startDate === "" || endDate === "") {
        setIsFormNotFilled(true);

        setTimeout(() => {
          setIsFormNotFilled(false);
        }, 3000);

        return;
      }

      try {
        const parameters = {
          institutionId: instId,
          body: {
            schoolYearId: yearId,
            periodName: periodName,
            startDate: startDate,
            endDate: endDate,
            open: isToggled,
          },
        };
        const res = await createInstitutionPeriod(parameters).unwrap();

        if (res) {
          console.log(res);
          setNewPeriod(res);
          
        }
        setShowCalendarModal(false);
        setNewPeriodData({
          periodName: "",
          startDate: "",
          endDate: "",
        });
        setIsToggled(false);
      } catch (error) {
        console.log(error.data);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
    }
  };

  useEffect(() => {
    if (newYearData) {
      refetchYears();
    }
    if (institutionPeriods) {
      refetchPeriods();
    }
  }, [addYearSuccess, newYearData, editedElement, newPeriod, deletedElementID]);

  useEffect(() => {
    const buttonElement = document.getElementById("createYear");

    // buttonElement && buttonElement.dispatchEvent(new MouseEvent('mouseenter'));
    if (!institutionYears || institutionYears?.length === 0) {
      buttonElement &&
        buttonElement.dispatchEvent(
          new MouseEvent("mouseover", {
            view: window,
            bubbles: true,
            cancelable: true,
          })
        );
    }
  }, [institutionYears]);

  // console.log(newYearData)
  // console.log(institutionYears);
  // console.log(addYearSuccess);

  if (
    yearsLoading ||
    periodsLoading ||
    addYearLoading ||
    addPeriodLoading ||
    updatePeriodLoading ||
    deletePeriodLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <div className="institutionCalendar">
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <h2>
        <b>Set up Your Institution</b>
      </h2>

      <h5 style={{ marginBottom: "15px" }}>Period Configuration</h5>

      {/* delete confirmation modal */}
      <ModalComponent
        showModal={showConfirmDelete}
        closeModal={() => setShowConfirmDelete(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img src={alertIcon} alt="alert Icon" style={{ margin: "0 auto" }} />
          <p
            style={{
              marginTop: "10px",
              marginBottom: "5px",
              fontWeight: "500",
              fontSize: 1.0 + "rem",
            }}
          >
            Are you sure you want to delete this calendar?{" "}
          </p>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              gap: "10px",
              marginLeft: "30%",
            }}
          >
            <Button
              onClick={() => setShowConfirmDelete(false)}
              type="button"
              variant="outline-primary"
              style={{ borderColor: "#F14810", color: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                No
              </span>
            </Button>
            <Button
              onClick={() => handleDeletePeriod()}
              type="button"
              variant="primary"
              style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Yes
              </span>
            </Button>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        title="Create Year"
        showModal={showYearModal}
        closeModal={handleCloseYearModal}
      >
        {isFormNotFilled && (
          <span
            style={{
              color: "red",
              fontStyle: "italic",
              transition: "ease-in-out",
            }}
          >
            Please fill all fields
          </span>
        )}

        <form onSubmit={handleCreateYear}>
          <div style={{ marginBottom: "15px" }}>
            <p style={{ marginBottom: "5px", fontWeight: "500" }}>
              Year Name{" "}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </p>
            <input
              type="text"
              placeholder="e.g 2023/2024 Academic Session"
              name="name"
              value={name}
              onChange={handleYearOnChange}
              style={{
                borderRadius: "5px",
                backgroundColor: "#dddddd",
                width: "100%",
              }}
            />
          </div>

          <div style={{ marginTop: "30px" }}>
            <Button
              type="submit"
              variant="primary"
              style={{
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                float: "left",
              }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Save Year
              </span>
            </Button>
          </div>
        </form>
      </ModalComponent>

      {/* {institutionYears && institutionYears.length > 0 && (
        <h5>{institutionYears[0].name}</h5>
      )} */}

      {!institutionYears ||
        (institutionYears.length == 0 ? (
          <div className="">
            <OverlayTrigger
              placement="top"
              delay={{ show: 150, hide: 300 }}
              overlay={
                <Tooltip id="button-tooltip">
                  Click this button to create the current academic year
                </Tooltip>
              }
            >
              <Button
                className="button"
                size="md"
                style={{
                  backgroundColor: "#F14810",
                  color: "#ffffff",
                  border: "none",
                  outline: "none",
                }}
                onClick={() => setShowYearModal(true)}
                id="createYear"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.3 + "rem",
                    marginRight: 0.3 + "rem",
                  }}
                >
                  Create Year
                </span>
              </Button>
            </OverlayTrigger>
          </div>
        ) : (
          <h5>{institutionYears[0].name}</h5>
        ))}

      {/* <h5>School Calendar</h5> */}

      <ModalComponent
        title="Create Calendar"
        showModal={showCalendarModal}
        closeModal={handleCloseCalendarModal}
      >
        {isFormNotFilled && (
          <span
            style={{
              color: "red",
              fontStyle: "italic",
              transition: "ease-in-out",
            }}
          >
            Please fill all fields
          </span>
        )}

        <form>
          <div style={{ marginBottom: "15px" }}>
            <p style={{ marginBottom: "5px", fontWeight: "500" }}>
              Period Name{" "}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </p>
            <input
              type="text"
              placeholder="e.g first term, first semester, etc"
              name={editingElement ? "editedPeriodName" : "periodName"}
              value={editingElement ? editedPeriodName : periodName}
              onChange={handleCalendarOnChange}
              style={{
                borderRadius: "5px",
                backgroundColor: "#dddddd",
                width: "100%",
              }}
            />
          </div>
          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ marginBottom: "5px", fontWeight: "500" }}>
              Start Date{" "}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </p>
            <input
              type="date"
              placeholder="Enter Start Date"
              name={editingElement ? "editedStartDate" : "startDate"}
              value={editingElement ? editedStartDate : startDate}
              onChange={handleCalendarOnChange}
              style={{
                borderRadius: "5px",
                backgroundColor: "#dddddd",
                width: "100%",
              }}
            />
          </div>
          <div style={{ marginBottom: "15px" }}>
            <p style={{ marginBottom: "5px", fontWeight: "500" }}>
              End Date{" "}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </p>
            <input
              type="date"
              placeholder="Enter End Date"
              // name="endDate"
              // value={endDate}
              // value={editingElement ? editingElement.endDate : name}
              name={editingElement ? "editedEndDate" : "endDate"}
              value={editingElement ? editedEndDate : endDate}
              onChange={handleCalendarOnChange}
              style={{
                borderRadius: "5px",
                backgroundColor: "#dddddd",
                width: "100%",
              }}
            />
          </div>

          <div
            className="calender-switch-container"
            style={{ display: "flex", alignItems: "center" }}
          >
            <label className="switch">
              <input
                type="checkbox"
                // checked={isToggled}
                checked={editingElement ? editedToggled : isToggled}
                onChange={editingElement ? handleEditToggle : handleToggle}
                className="toggle"
              />
              <span className="slider round"></span>
            </label>
            <label className="toggle-label">Set Calendar to Active</label>
          </div>

          <div style={{ marginTop: "30px" }}>
            {editingElement ? (
              <Button
                onClick={(e) => handleCreateOrUpdatePeriod(e, "edit")}
                variant="primary"
                style={{
                  backgroundColor: "#F14810",
                  borderColor: "#F14810",
                  float: "left",
                }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Edit Calendar
                </span>
              </Button>
            ) : (
              <Button
                onClick={(e) => handleCreateOrUpdatePeriod(e, "")}
                variant="primary"
                style={{
                  backgroundColor: "#F14810",
                  borderColor: "#F14810",
                  float: "left",
                }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Save Calender
                </span>
              </Button>
            )}
          </div>
        </form>
      </ModalComponent>

      {!institutionYears ||
        (institutionYears.length == 0 ? null : (
          <Table
            // responsive
            striped
            size="sm"
            style={{
              fontFamily: "jost, sans-serif",
              fontSize: "14px",
            }}
          >
            <thead>
              <tr align="center">
                {tableCalendarHeaders.map((header, i) => (
                  <th key={i}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {institutionPeriods &&
                institutionPeriods.map(
                  ({ id, periodName, startDate, endDate, open }, i) => (
                    <tr
                      key={i}
                      className={i % 2 === 0 ? "even-row" : "odd-row"}
                    >
                      <td align="center">{id}</td>
                      <td align="center">{periodName}</td>
                      <td align="center">{startDate}</td>
                      <td align="center">{endDate}</td>
                      <td align="center">{open ? "Active" : "Inactive"}</td>
                      <td align="center">
                        <ActionIcon
                          actionsArr={[
                            {
                              label: "Edit",
                              action: () =>
                                handleEditPeriod({
                                  id,
                                  periodName,
                                  startDate,
                                  endDate,
                                  open,
                                }),
                            },
                            {
                              label: "Delete",
                              action: () =>
                                confirmDelete({
                                  id,
                                  periodName,
                                  startDate,
                                  endDate,
                                  open,
                                }),
                            },
                          ]}
                        />
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </Table>
        ))}

      {!institutionYears ||
        (institutionYears.length == 0 ? null : (
          <div style={{ float: "right", marginTop: "20px" }}>
            <OverlayTrigger
              placement="top"
              delay={{ show: 150, hide: 300 }}
              overlay={
                <Tooltip id="button-tooltip">
                  Click this button to create a calendar e.g first term, first
                  semester etc
                </Tooltip>
              }
            >
              <Button
                className="button"
                size="md"
                style={{
                  backgroundColor: `${
                    !institutionPeriods || institutionPeriods?.length === 0
                      ? "#F14810"
                      : "#777777"
                  }`,
                  color: "#ffffff",
                  border: "none",
                  outline: "none",
                }}
                onClick={() => setShowCalendarModal(true)}
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.3 + "rem",
                    marginRight: 0.3 + "rem",
                  }}
                >
                  Create Calendar
                </span>
              </Button>
            </OverlayTrigger>
          </div>
        ))}

      <div className="btns-container">
        <Button
          onClick={() => setActiveStage("Level Category")}
          variant="outline-primary"
          style={{ borderColor: "#F14810", color: "#F14810" }}
          className="button"
          size="md"
        >
          <span
            style={{
              fontSize: 0.8 + "rem",
              marginLeft: 0.5 + "rem",
              marginRight: 0.5 + "rem",
            }}
          >
            Previous
          </span>
        </Button>

        <OverlayTrigger
          placement="top"
          delay={{ show: 150, hide: 300 }}
          overlay={
            <Tooltip id="button-tooltip">
              Create a year and calendar then click this button to save your
              changes
            </Tooltip>
          }
        >
          <Button
            onClick={
              !institutionPeriods || institutionPeriods?.length === 0
                ? null
                : () => setActiveStage("Fee Component")
            }
            variant="primary"
            style={{
              backgroundColor: `${
                !institutionPeriods || institutionPeriods?.length === 0
                  ? "#FFCAB8"
                  : "#F14810"
              }`,
              borderColor: `${
                !institutionPeriods || institutionPeriods?.length === 0
                  ? "#FFCAB8"
                  : "#F14810"
              }`,
              cursor: `${
                !institutionPeriods || institutionPeriods?.length === 0
                  ? "not-allowed"
                  : "pointer"
              }`,
              float: "left",
            }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              Save & Continue
            </span>
          </Button>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default InstitutionCalendarPage;
