import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Button } from "react-bootstrap";
import uploadIcon from "../../../../../../../../assets/svgs/greyUploadIcon.svg";
import {
  useUploadStudentsMutation,
  useDownloadTemplateQuery,
  useGetStudentsRegisterQuery,
} from "../../../../../../../../redux/features/institution/studentApiSlice";
import OnboardingLoader from "../../../../../../../../component/onboardingLoader/onboardingLoader";
import SuccessScreen from "../../../../../../../../component/successScreen/successScreen";
import { useGetAllBranchesQuery } from "../../../../../../../../redux/features/institution/institutionApiSlice";
import ToastComponent from "../../../../../../../../component/toast/toast";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";

const UploadStudentsPage = ({ showUploadModal, setShowUploadModal }) => {
  const [documentUrl, setDocumentUrl] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const [institutionTypeId, setInstitutionTypeId] = useState(1);
  const [errorMessage, setErrorMessage] = useState(null);
  const [studentRegisterId, setStudentRegisterId] = useState(null);
  const [approvedRegisterId, setApprovedRegisterId] = useState(null);
  const [downloadClicked, setDownloadClicked] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const navigate = useNavigate();

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  const institutionTypes = useSelector(
    (state) => state?.auth?.userInfo?.institutionTypes
  );

  const [uploadStudents, { isLoading, isSuccess }] =
    useUploadStudentsMutation();

  const { data: branches, isLoading: branchesLoading } = useGetAllBranchesQuery(
    instId,
    { skip: showUploadModal != true }
  );

  const {
    data: studentRegister,
    refetch: refetchStudentsRegisters,
    isLoading: registersLoading,
  } = useGetStudentsRegisterQuery(instId);

  const {
    data: templateUrl,
    error: templateDownloadError,
    isLoading: templateDownloading,
  } = useDownloadTemplateQuery(
    { institutionId: instId, institutionTypeId: institutionTypeId },
    { skip: !downloadClicked }
  );

  const [branchId, setBranchId] = useState("");
  const [institutionType, setInstitutionType] = useState("");

  const handleOnChange = (e) => {
    if (e.target.name === "branchId") {
      setBranchId(e.target.value);
    }

    if (e.target.name === "institutionType") {
      setInstitutionType(e.target.value);
    }

    let selectedFile = e.target.files;

    if (selectedFile?.length > 0) {
      let actualFile = selectedFile[0];
      let fileName = actualFile.name;
      const reader = new FileReader();

      reader.onload = (event) => {
        const fileDetails = {
          name: fileName,
          fileUrl: event.target.result,
        };
        // console.log(fileDetails.name)
        // spliting because mohammed is expecting just the string
        setDocumentUrl(fileDetails.fileUrl.split("base64,")[1]);
        setDocumentName(fileDetails.name);
      };
      reader.readAsDataURL(actualFile);
    }
  };

  useEffect(() => {
    if (templateUrl && !templateDownloading) {
      window.open(templateUrl.url, "_blank");
      setDownloadClicked(false);
    }
  }, [templateDownloading, templateUrl]);

  if (templateDownloadError) {
    setShowToast(true);
    setToastMessage(templateDownloadError?.error?.detail);
  }

  if (isLoading || branchesLoading) {
    return <OnboardingLoader />;
  }

  const onUpload = async () => {
    if (!documentUrl) {
      setErrorMessage("Please select the document to upload");
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);

      return;
    }

    if (!branchId) {
      setErrorMessage("Please select the branch");

      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);

      return;
    }

    try {
      const res = await uploadStudents({
        institutionId: instId,
        institutionTypeId: institutionType,
        branchId: branchId,
        students: documentUrl,
      }).unwrap();

      setDocumentName(null);
      setDocumentUrl(null);
      setStudentRegisterId(res.id);
      setShowToast(true);
      setToastMessage("Upload Successful");
      setShowUploadModal(false);
      refetchStudentsRegisters();
      navigate("/student/unapproved");
    } catch (error) {
      // alert(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <Form className="form">
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          ></div>
          <h4 style={{ fontSize: "15px", fontWeight: 600 }}>
            Upload XLXS File
          </h4>
          <p>
            Please choose the XLSX file from your computer and utilize the
            provided uploader below to initiate the upload process.
          </p>
          {errorMessage && (
            <span style={{ color: "red", fontStyle: "italic" }}>
              {errorMessage}
            </span>
          )}
          <div
            style={{
              border: "1px dashed #545353",
              padding: "25px",
              display: "flex",
              borderRadius: "8px",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#f0f0f0",
                alignItems: "center",
              }}
            >
              <img src={uploadIcon} alt="upload icon" />
            </div>
            {/* <Button
                style={{
                  backgroundColor: "#646464",
                  color: "#ffffff",
                  border: "none",
                  outline: "none",
                }}
              >
                Browse XLXS File
              </Button> */}
            <label
              htmlFor="upload-students"
              style={{
                backgroundColor: "#646464",
                color: "#ffffff",
                padding: "7px 20px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Browse XLXS File
            </label>
            <input
              type="file"
              id="upload-students"
              style={{ visibility: "hidden", display: "none" }}
              name="studentUploadFile"
              // value={institutionBranding.logo}
              onChange={handleOnChange}
            />
          </div>
          {documentName && <span>{documentName}</span>}
          <div
            style={{
              display: "flex",
              gap: "20px",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput1"
              style={{ width: "100%" }}
            >
              <Form.Label className="formClasslabel">
                Select Institution Type <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                name="institutionType"
                value={institutionType}
                onChange={handleOnChange}
                required
              >
                <option value="" defaultValue disabled>
                  Select Type
                </option>
                {institutionTypes?.map(({ id, typeName }) => (
                  <option value={id} key={id}>
                    {typeName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput1"
              style={{ width: "100%" }}
            >
              <Form.Label className="formClasslabel">
                Select Branch <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                name="branchId"
                value={branchId}
                onChange={handleOnChange}
                required
              >
                <option value="" defaultValue disabled>
                  Select Branch
                </option>
                {branches &&
                  branches.length > 0 &&
                  branches.map(({ id, name }) => (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </div>

          <Button
            style={{
              backgroundColor: "#F14810",
              borderColor: "#F14810",
              outline: "none",
            }}
            onClick={onUpload}
          >
            Upload File
          </Button>

          <h4 style={{ fontSize: "15px", fontWeight: 600, marginTop: "25px" }}>
            Download Template File
          </h4>
          <p>
            Please download the XLSX file to your computer and fill your student
            information
          </p>
          <Form.Group
            className="mb-5"
            controlId="exampleForm.ControlInput1"
            style={{ width: "100%" }}
          >
            <Form.Label className="formClasslabel">
              Select Institution Type <span style={{ color: "red" }}>*</span>
            </Form.Label>
            {/* <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}> */}
            <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))", gap: "10px" }}>
              {institutionTypes?.map(({ id, typeName }) => (
                <Form.Check
                  key={id}
                  inline
                  label={typeName}
                  onChange={() => setInstitutionTypeId(id)}
                  checked={id == institutionTypeId}
                  type="radio"
                  id={`inline-radio-${id}`}
                  size={25}
                />
              ))}
            </div>
          </Form.Group>

          <Button
            style={{
              backgroundColor: "#777777",
              color: "#ffffff",
              outline: "none",
              border: "none",
            }}
            onClick={() => setDownloadClicked(true)}
          >
            {templateDownloading ? "Downloading..." : "Download Template"}
          </Button>
        </div>
      </Form>

      <br />
    </>
  );
};

export default UploadStudentsPage;
