import { Col, Row, Button } from "react-bootstrap";
import { addTrailingCommas } from "../../../../../utils/utils";
import { useSelector } from "react-redux";
import { useState, useRef } from "react";
import DownloadStudentInvoiceComponent from "./downloadStudentInvoiceComponent";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useGetStudentInvoicesQuery } from "../../../../../redux/features/institution/studentApiSlice";
import ModalComponent from "../../../../../component/modal/modal";
import { useParams } from "react-router-dom";
import invoiceSummary from "../../../../../assets/svgs/invoiceSummary.svg";

const Preview = ({
  title,
  dueDate,
  schoolName,
  classes,
  fees,
  subTotal,
  total,
  type,
  invId,
}) => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const specificFeeDetails = useSelector(
    (state) => state.institution.specificInvoiceDetails
  );

  const { invoiceId } = useParams();

  const {
    data: studentInvoicingDetail,
    isLoading: fetchLoading,
    refetch,
  } = useGetStudentInvoicesQuery(invoiceId, { skip: !invoiceId });

  console.log(invId);
  console.log(invoiceId);
  console.log(studentInvoicingDetail);

  const downloadInvoice = async () => {
    setShowDownloadModal(true);
  };

  const invoicesRef = useRef();

  const downloadInvoices = () => {
    setIsDownloading(true);
    const input = invoicesRef.current;
    const pdf = new jsPDF("p", "mm", "a4", true);

    studentInvoicingDetail?.forEach((invoice, index) => {
      html2canvas(input.children[index]).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imageWidth = canvas.width;
        const imageHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imageWidth, pdfHeight / imageHeight);
        const imgX = (pdfWidth - imageWidth * ratio) / 2;
        const imgY = 30;

        if (index > 0) {
          pdf.addPage(); // Add a new page for each additional invoice
        }

        // Adjusting font size and overall dimensions
        pdf.setFontSize(5);
        const adjustedImageWidth = imageWidth * 0.6;
        const adjustedImageHeight = imageHeight * 0.6;

        pdf.addImage(
          imgData,
          "PNG",
          imgX + (imageWidth - adjustedImageWidth) / 4,
          imgY,
          adjustedImageWidth * ratio,
          adjustedImageHeight * ratio
        );

        if (index === studentInvoicingDetail.length - 1) {
          // Save the PDF after rendering the last invoice
          pdf.save("invoices.pdf");
          setIsDownloading(false);
        }
      });
    });
  };

  return (
    <Col xs={12} md={6} lg={5}>
      <div id="invoice">
        <div
          style={{
            border: "2px solid #EDEDED",
            borderRadius: "8px",
            gap: "30px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <div
            style={{
              borderBottom: "2px solid #EDEDED",
              padding: "10px",
              gap: "10px",
              marginBottom: "15px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                color: "#F14810",
              }}
            >
              <img src={invoiceSummary} alt="invoice summary" />
              <h6 style={{ fontWeight: "600", marginTop: "10px" }}>
                Invoice Summary
              </h6>
            </div>

            {invoiceId && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                }}
              >
                <Button
                  className="button"
                  size="md"
                  style={{
                    backgroundColor: "#F14810",
                    color: "#ffffff",
                    border: "none",
                    outline: "none",
                    fontSize: "14px",
                  }}
                  onClick={() => {
                    downloadInvoice();
                  }}
                >
                  Preview Invoice
                </Button>
              </div>
            )}
          </div>

          <div style={{ padding: "20px" }}>
            <Row>
              <Col>
                <p style={{ fontWeight: "600" }}>
                  Invoice Title: <br />
                  <span style={{ fontWeight: "400", fontStyle: "italic" }}>
                    {!type ? specificFeeDetails?.specificTitle : title}
                  </span>
                </p>
              </Col>
              <Col>
                <p style={{ fontWeight: "600" }}>
                  Invoice Due Date: <br />
                  <span style={{ fontWeight: "400", fontStyle: "italic" }}>
                    {!type ? specificFeeDetails?.specificDueDate : dueDate}
                  </span>
                </p>
              </Col>
            </Row>
            <p style={{ fontWeight: "600" }}>
              School Name: <br />
              <span style={{ fontWeight: "400", fontStyle: "italic" }}>
                {schoolName}
              </span>
            </p>
            <p style={{ fontWeight: "600" }}>
              Class: <br />
              <span style={{ fontWeight: "400", fontStyle: "italic" }}>
                {classes?.map((type, i, arr) => (
                  <span>
                    {type.institutionLevelName} {type.institutionCategoryName}{" "}
                    {i != arr.length - 1 ? " " : ""} <br />
                  </span>
                ))}
              </span>
            </p>
            <p style={{ fontWeight: "600" }}>
              Fees: <br />
              <span style={{ fontWeight: "400" }}>
                {fees?.map(({ fees }) => {
                  return fees?.map((type) => (
                    <span>
                      <Row
                        style={{
                          marginBottom: "-20px",
                          fontStyle: "italic",
                        }}
                      >
                        <Col>{type.feeComponentName} -</Col>
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "right",
                          }}
                        >
                          ₦ {addTrailingCommas(type.amount)}
                        </Col>
                      </Row>{" "}
                      <br />
                    </span>
                  ));
                })}
              </span>
            </p>
            <p style={{ fontWeight: "600" }}>
              <Row>
                <Col>Sub Total - </Col>
                <Col style={{ display: "flex", justifyContent: "right" }}>
                  <span style={{ fontWeight: "600" }}>
                    ₦ {addTrailingCommas(subTotal)}
                  </span>
                </Col>
              </Row>
            </p>

            <div
              style={{
                borderTop: "2px solid #EDEDED",
                padding: "10px",

                width: "100%",
                justifyContent: "space-between",
                color: "#F14810",
              }}
            >
              <p style={{ fontWeight: "600", fontSize: "16px" }}>
                <Row>
                  <Col>Total - </Col>
                  <Col style={{ display: "flex", justifyContent: "right" }}>
                    <span style={{ fontWeight: "600", fontSize: "16px" }}>
                      ₦ {addTrailingCommas(total)}
                    </span>
                  </Col>
                </Row>
              </p>
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        title="Download Invoice"
        showModal={showDownloadModal}
        closeModal={() => {
          setShowDownloadModal(false);
        }}
        size="lg"
      >
        <form>
          <div id="invoicepreview" ref={invoicesRef}>
            {studentInvoicingDetail?.map((type, i, arr) => (
              <div>
                <DownloadStudentInvoiceComponent studentInvoiceId={type.id} />
                <br />
              </div>
            ))}
          </div>

          <Button
            onClick={() => setShowDownloadModal(false)}
            variant="primary"
            style={{
              backgroundColor: "#F14810",
              borderColor: "#F14810",
              float: "left",
              marginTop: "20px",
            }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              Close
            </span>
          </Button>

          <Button
            onClick={downloadInvoices}
            variant="primary"
            style={{
              backgroundColor: "#F14810",
              borderColor: "#F14810",
              float: "left",
              marginTop: "20px",
              marginLeft: "10px",
            }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              {isDownloading ? "Downloading..." : "Download"}
            </span>
          </Button>
        </form>
      </ModalComponent>
    </Col>
  );
};

export default Preview;
