import React, { useState, useRef, useEffect } from 'react';
import "./actionicon.css";

const ActionIcon = ({actionsArr}) => {
    const [showPopup, setShowPopup] = useState(false);
    const popupRef = useRef(null);

    const handleActionButtonClick = () => {
        setShowPopup(!showPopup);
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setShowPopup(false);
        }
    };

    const handleAction = (action) => {
      setShowPopup(false)
      action()
    }

    useEffect(() => {
        if (showPopup) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showPopup]);

    return (
      <div className="action-button-container">
        <svg
          onClick={handleActionButtonClick}
          width="25"
          height="25"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.0810547"
            width="36.8377"
            height="36.8377"
            rx="18.4188"
            fill="#B2B2B2"
          />
          <circle cx="11" cy="18" r="3" fill="white" />
          <circle cx="19" cy="18" r="3" fill="white" />
          <circle cx="27" cy="18" r="3" fill="white" />
        </svg>

        {showPopup && (
          <div ref={popupRef} className="popup">
            {actionsArr.map(({label, action}, i)=>(
                <button className={`${label}-button`} key={i} onClick={()=>handleAction(action)}>{label}</button>
            ))}
          </div>
        )}
      </div>
    );
};

export default ActionIcon;
