import React from "react";
import { useState, useEffect } from "react";
// import { Offcanvas } from "react-bootstrap";
import SchoolYearIcon from "../../../../../../assets/svgs/Calendar-bro.svg";
// import EyeActionIcon from "../../../../../assets/svgs/eyeActionIcon.svg";
import ButtonX from "../../../../../../component/button/button";
import { Row, Col, Button } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import ModalComponent from "../../../../../../component/modal/modal";
import { useNavigate } from "react-router-dom";
import "./schoolYear.css"
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import {capitalizeWords} from "../../../../../../utils/utils";
import {
  useGetInstitutionYearQuery,
  useCreateInstitutionYearMutation
} from "../../../../../../redux/features/institution/institutionApiSlice";


const SchoolYearPage = ({ institutionDetails, institutionLogo }) => {
  const [showYearModal, setShowYearModal] = useState(false);
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [newYearData, setNewYearData] = useState(null);
  const [yearId, setYearId] = useState(null);

  const handleCloseYearModal = () => setShowYearModal(false);

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  console.log(instId);

  const {
    data: institutionYears,
    refetch: refetchYears,
    isLoading: yearsLoading,
  } = useGetInstitutionYearQuery(instId)

  console.log(institutionYears);

  const dispatch = useDispatch();


  // const yearList = [
  //     {
  //         id: "1",
  //         name: "2022/2023 Year",
  //         // text: "120 Students",
  //         style: "branchsidecard-x1",
  //     },
  //     // {
  //     //   id: 2,
  //     //   title: "Kaduna Branch",
  //     //   text: "239 Students",
  //     //   style: "branchsidecard-x1",
  //     // },
  //     // {
  //     //   id: 3,
  //     //   title: "Niger Branch",
  //     //   text: "546 Students",
  //     //   style: "branchsidecard-x1",
  //     // },
  //     // {
  //     //   id: 4,
  //     //   title: "Abuja Branch",
  //     //   text: "732 Students",
  //     //   style: "branchsidecard-x1",
  //     // },
  // ];

  const navigate = useNavigate();

  const handleViewSchoolCalendar = (id) => {
    // console.log(details)
    navigate(`/schoolYear/${id}`);
  };

  const [yearName, setYearName] = useState({
    name: "",
  });

  const { name } = yearName;

  const handleYearOnChange = (e) => {
    const { name, value } = e.target;
    setYearName({ ...yearName, [name]: value });
  };

  const [
    createInstitutionYear,
    { isLoading: addYearLoading, isSuccess: addYearSuccess },
  ] = useCreateInstitutionYearMutation();

  const handleCreateYear = async (e) => {
    e.preventDefault();

    if (name === "") {
      setIsFormNotFilled(true);

      setTimeout(() => {
        setIsFormNotFilled(false);
      }, 3000);

      return;
    }

    setShowYearModal(false);

    const parameters = {
      institutionId: instId,
      body: yearName,
    };
    // submit with institutionId also
    const data = await createInstitutionYear(parameters).unwrap();

    if (data) {
      console.log(data);
      setNewYearData(data);
      setYearId(data.id);
    }
  };

  useEffect(() => {
    if (institutionYears) {
      refetchYears();
    }
  }, [addYearSuccess, newYearData]);

  if (yearsLoading || addYearLoading) {
    return <OnboardingLoader />;
  }

  // const [isSchoolCalendarStudentListViewed, setIsSchoolCalendarStudentListViewed] = useState(false);
  // const [viewedSchoolCalendarStudentListDetails, setViewedSchoolCalendarStudentListDetails] =
  //     useState(null);

  // const handleSchoolCalendarStudentListView = (obj) => {
  //     setIsSchoolCalendarStudentListViewed(!isSchoolCalendarStudentListViewed);
  //     setViewedSchoolCalendarStudentListDetails(obj);
  // };

  return (
    <>
      <div className="schoolYear-page">
        {institutionYears && institutionYears.length > 0 ? (
          <section
            style={{
              marginTop: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <div style={{ display: "flex", gap: "5px", paddingLeft: "5px" }}>
                <h5>Calendar Management</h5>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <ButtonX
                  backgroundColor="#f14810"
                  border="#f14810"
                  onClick={() => setShowYearModal(true)}
                >
                  Create Year
                </ButtonX>
              </div>
            </div>

            <>
              <Row style={{ marginTop: 0.4 + "rem" }}>
                <Col xs={12} md={12} lg={12} className="otherbox">
                  <Row>
                    {institutionYears &&
                      institutionYears.length > 0 &&
                      institutionYears.map(({ id, name }, i) => (
                        <Col
                          xs={4}
                          md={4}
                          lg={4}
                          id="branchsidecard-x1"
                          key={id}
                        >
                          {/* <br /> */}
                          <div
                            className="branchsidecard-x1"
                            style={{ cursor: "pointer", height: 6 + "rem" }}
                            onClick={() => handleViewSchoolCalendar(id)}
                          >
                            <div
                              className="card-inner-value"
                            >
                              <h6 style={{ marginTop: 2 + "rem" }}>
                                <b>{capitalizeWords(name)}</b>
                              </h6>
                              <p
                                className="card-inner-text"
                                style={{ marginTop: -0.61 + "rem" }}
                              ></p>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </Col>
              </Row>
            </>
          </section>
        ) : (
          <div className="create-schoolYear-container">
            <img src={SchoolYearIcon} alt="School Year image" />
            <h3>Create Year</h3>
            <p>
              This is where schools carry out academic year creation activities. Simply click on "Create Year" button to proceed {" "}
            </p>
            <div className="btns">
              <ButtonX
                onClick={() => setShowYearModal(true)}
                backgroundColor="#F14810"
                borderColor="#F14810"
                // color="#F14810"
                border="#F14810"
              >
                Create Year
              </ButtonX>
            </div>
          </div>
        )}

        <ModalComponent
          title="Create Year"
          showModal={showYearModal}
          closeModal={handleCloseYearModal}
        >
          {isFormNotFilled && (
            <span
              style={{
                color: "red",
                fontStyle: "italic",
                transition: "ease-in-out",
              }}
            >
              Please fill all fields
            </span>
          )}

          <form onSubmit={handleCreateYear}>
            <div style={{ marginBottom: "15px" }}>
              <p style={{ marginBottom: "5px", fontWeight: "500" }}>
                School Year Name{" "}
                <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
              </p>
              <input
                type="text"
                placeholder="Enter School Year Name"
                name="name"
                value={name}
                onChange={handleYearOnChange}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#dddddd",
                  width: "100%",
                }}
              />
            </div>

            <div style={{ marginTop: "30px" }}>
              <Button
                type="submit"
                variant="primary"
                style={{
                  backgroundColor: "#F14810",
                  borderColor: "#F14810",
                  float: "left",
                }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Save Year
                </span>
              </Button>
            </div>
          </form>
        </ModalComponent>
      </div>
    </>
  );
};

export default SchoolYearPage;
