import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { userInfo, logOut } from "../features/auth/authSlice";
// import { useToasts } from "react-toast-notifications";

let userEmail;
let userRefreshToken;

// logic to refreshes the token when the token expires
const baseQuery = fetchBaseQuery({
  reducerPath: "api",
  //baseUrl: "https://mock.apidog.com/m1/432030-0-default/",
  // baseUrl: "https://adeptapi.aggregatebusiness.net/",
  //baseUrl: "https://testapi.education.adept.ng/",
  baseUrl: "https://api.education.adept.ng/",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth?.userInfo?.accessToken;

    console.log(getState().auth?.userInfo?.expiresIn);

    const userId = getState().auth?.userInfo?.userId;
    userEmail = getState().auth?.userInfo?.email;
    userRefreshToken = getState().auth?.userInfo?.refreshToken;

    if (token && userId) {
      headers.set("Authorization", `Bearer ${token}`);
      headers.set("userId", `${userId}`);
      headers.set("Content-Type", "application/json");
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // const { addToast } = useToasts();

  const result = await baseQuery(args, api, extraOptions);
  console.log("baseQueryresult", result);
  console.log("baseQueryresult333333", result?.status);

  if (result?.error?.status === "ERR_INTERNET_DISCONNECTED") {
    alert("Network disconnected. Please try again");
  }

  if (
    result?.error?.status === 401 ||
    result?.error?.status === 403
    ||
    result?.error?.status === "FETCH_ERROR"
  ) {
    console.log(result);
    // console.log("token expired");
    // api.dispatch(logOut());

    const refreshResult = await baseQuery(
      {
        url: "/refreshToken",
        method: "POST",
        body: { email: userEmail, refreshToken: userRefreshToken },
      },
      api,
      extraOptions
    );

    console.log(refreshResult);

    if (refreshResult?.data) {
      // store the new token
      // console.log(refreshResult.data)
      // spread for info then change token
      try {
        api.dispatch(userInfo(refreshResult.data));
        // retry the initial query
        result = await baseQuery(args, api, extraOptions);
      } catch (error) {
        console.log(error);
        api.dispatch(logOut());
      }
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
