import React from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Image,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import {
  useGetCountriesQuery,
  useGetStatesQuery,
} from "../../../../../../redux/features/auth/authApiSlice.js";
import { updateAboutSchool } from "../../../../../../redux/features/auth/authSlice.js";
import { useGetInstitutionTypeQuery } from "../../../../../../redux/features/institution/institutionApiSlice.js";
import { useEffect, useRef, useState } from "react";
import { Link45deg, EnvelopeAt } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader.js";
import ToastComponent from "../../../../../../component/toast/toast.js";
import dropdownIcon from "../../../../../../assets/svgs/dropdownIcon.svg";

const AboutSchoolPage = ({ setActiveStage }) => {
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);

  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [selectedStateId, setSelectedStateId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsIds, setSelectedItemsIds] = useState([]);
  const [selectedItemsNames, setSelectedItemsNames] = useState([]);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);

  const dispatch = useDispatch();

  const { data: countryData } = useGetCountriesQuery();

  const { data: institutionTypesArray, isLoading: institutionTypesLoading } =
    useGetInstitutionTypeQuery();

  // const { data: states } = useGetStatesQuery(
  //   selectedCountryId ? selectedCountryId : 1,
  //   { skip: !countryData || typeof selectedCountryId != "number" }
  // );

  const { data: states, refetch: refetchStates } = useGetStatesQuery(
    selectedCountryId ? selectedCountryId : 1,
    // { skip: !countryData || typeof selectedCountryId != "number" }
    { skip: selectedCountryId === "" }
  );

  // get previous data if they have filled this page before
  const aboutInstitutionData = useSelector(
    (state) => state.auth.aboutSchoolInfo
  );

  const aboutContactPerson = useSelector(
    (state) => state.auth.contactPersonInfo
  );

  console.log(aboutContactPerson);

  const [registrationData, setRegistrationData] = useState({
    name: aboutInstitutionData ? aboutInstitutionData.name : "",
    // institutionType: aboutInstitutionData
    //   ? aboutInstitutionData.institutionType
    //   : "",
    institutionTypeIds: aboutInstitutionData
      ? aboutInstitutionData.institutionTypeIds
      : selectedItems,
    institutionTypes: aboutInstitutionData
      ? aboutInstitutionData.institutionTypes
      : selectedItemsNames,
    address: aboutInstitutionData ? aboutInstitutionData.address : "",
    state: aboutInstitutionData ? aboutInstitutionData.state : "",
    // stateId: aboutInstitutionData ? aboutInstitutionData.stateId : "",
    country: aboutInstitutionData ? aboutInstitutionData.country : "",
    // countryId: aboutInstitutionData ? aboutInstitutionData.countryId : "",
    institutionEmail: aboutInstitutionData
      ? aboutInstitutionData.institutionEmail
      : "",
    website: aboutInstitutionData ? aboutInstitutionData.website : "",
    branchContactNumber: aboutInstitutionData
      ? aboutInstitutionData.branchContactNumber
      : "",
  });

  // console.log(registrationData);
  // console.log(selectedItems);

  const {
    institutionEmail,
    name,
    address,
    institutionTypeIds,
    country,
    state,
    website,
    branchContactNumber,
  } = registrationData;

  const handleOnChange = (e) => {
    // console.log(e);
    const { name, value } = e.target;
    setRegistrationData({ ...registrationData, [name]: value });

    if (name === "country") {
      setSelectedCountryId(e.target.value);
      if (states) {
        refetchStates();
      }
    }
  };

  // console.log(country);
  // console.log(state);
  // console.log(selectedCountryId);
  // console.log(selectedStateId);

  const handleGoToNextPage = () => {
    if (
      institutionEmail === "" ||
      name === "" ||
      selectedItems.length < 1 ||
      country === "" ||
      state === "" ||
      address === ""
    ) {
      setShowToast(true);
      setToastMessage("Please fill all compulsory fields");

      return;
    }

    // check if there is special character in the name
    const regex = /^[a-zA-Z0-9\s.,-]+$/;

    if (!regex.test(name)) {
      setToastMessage("Institution name cannot contain special characters!");
      setShowToast(true);
      return;
    }

    setActiveStage("contact person");
    // dispatch(updateAboutSchool(registrationData));

    const { institutionTypeIds, institutionTypes, ...rest } = registrationData;
    const newObj = {
      ...rest,
      institutionTypeIds: selectedItemsIds,
      institutionTypes: selectedItemsNames,
      selectedItems: selectedItems,
    };

    dispatch(
      updateAboutSchool({
        ...newObj,
        selectedCountryId,
        selectedStateId,
      })
    );
  };

  useEffect(() => {
    const country = countryData?.filter(
      (country) => country.countryName === registrationData.country
    );
    if (country) {
      setSelectedCountryId(country[0]?.id);
    }

    const state = states?.filter(
      (state) => state.stateName === registrationData.state
    );
    if (state) {
      setSelectedStateId(state[0]?.id);
    }
  }, [country, countryData, states, state]);

  useEffect(() => {
    setSelectedItems(aboutInstitutionData?.selectedItems || selectedItems);
    // setSelectedItems(aboutInstitutionData?.institutionTypeIds || selectedItems);
    setSelectedItemsNames(
      aboutInstitutionData
        ? [...aboutInstitutionData?.institutionTypes]
        : selectedItemsNames
    );
  }, []);

  // console.log([...aboutInstitutionData?.institutionTypes]);

  // close dropdown on outside dropdown click
  const dropdownRef = useRef(null);

  // console.log(selectedItemsIds);
  // console.log(selectedItems);
  // console.log(selectedItemsNames);

  const handleItemClick = (obj) => {
    if (selectedItems.find((item) => item.id === obj.id)) {
      setSelectedItems(selectedItems.filter((item) => item.id !== obj.id));
    } else {
      setSelectedItems([...selectedItems, { ...obj, selected: true }]);
    }
  };

  useEffect(() => {
    const names = selectedItems?.map((item) => item?.typeName);
    setSelectedItemsNames(names);
    const ids = selectedItems?.map((item) => item?.id);
    setSelectedItemsIds(ids);
  }, [selectedItems]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowCategoryDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  if (institutionTypesLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <div>
        <div
          style={{
            display: "flex",
            gap: 0.8 + "rem",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <div>
            <svg
              width="56"
              height="56"
              viewBox="0 0 68 69"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M67.5 34.5C67.5 53.1396 52.3896 68.25 33.75 68.25C15.1104 68.25 0 53.1396 0 34.5C0 15.8604 15.1104 0.75 33.75 0.75C52.3896 0.75 67.5 15.8604 67.5 34.5ZM8.4375 34.5C8.4375 48.4797 19.7703 59.8125 33.75 59.8125C47.7297 59.8125 59.0625 48.4797 59.0625 34.5C59.0625 20.5203 47.7297 9.1875 33.75 9.1875C19.7703 9.1875 8.4375 20.5203 8.4375 34.5Z"
                fill="#E7EAEE"
                fill-opacity="0.8"
              />
              <path
                d="M56.7291 15.9513C58.5421 14.4878 61.224 14.7598 62.45 16.7411C66.3743 23.0828 68.1091 30.586 67.3096 38.0802C66.36 46.9808 61.9136 55.1396 54.9486 60.7618C47.9835 66.384 39.0703 69.0091 30.1698 68.0596C22.6755 67.2601 15.7071 63.9814 10.336 58.8076C8.65799 57.1911 8.95792 54.5122 10.7709 53.0488C12.5839 51.5853 15.2203 51.9013 16.9642 53.4464C20.8773 56.9132 25.7979 59.1078 31.0648 59.6697C37.7402 60.3818 44.4251 58.413 49.6489 54.1964C54.8727 49.9797 58.2075 43.8606 58.9197 37.1852C59.4816 31.9183 58.3745 26.6454 55.8112 22.089C54.6688 20.0584 54.9161 17.4147 56.7291 15.9513Z"
                fill="#F14810"
              />
              <path
                d="M19.278 30.676V28.354L24.804 27.184V40H22.302V29.884L19.278 30.676ZM34.7438 27.4H36.9938L29.4338 42.7H27.1118L34.7438 27.4ZM37.6256 40L43.3496 34.024C43.6736 33.676 43.9496 33.352 44.1776 33.052C44.4056 32.752 44.5796 32.452 44.6996 32.152C44.8196 31.852 44.8796 31.54 44.8796 31.216C44.8796 30.988 44.8376 30.766 44.7536 30.55C44.6696 30.334 44.5436 30.142 44.3756 29.974C44.2076 29.806 44.0036 29.674 43.7636 29.578C43.5236 29.47 43.2476 29.416 42.9356 29.416C42.4916 29.416 42.1076 29.524 41.7836 29.74C41.4716 29.944 41.2256 30.238 41.0456 30.622C40.8776 31.006 40.7936 31.45 40.7936 31.954H38.2736C38.2736 31.054 38.4536 30.244 38.8136 29.524C39.1736 28.804 39.7016 28.234 40.3976 27.814C41.1056 27.382 41.9696 27.166 42.9896 27.166C43.7456 27.166 44.4056 27.286 44.9696 27.526C45.5456 27.754 46.0196 28.06 46.3916 28.444C46.7756 28.816 47.0576 29.23 47.2376 29.686C47.4296 30.142 47.5256 30.598 47.5256 31.054C47.5256 31.726 47.3636 32.356 47.0396 32.944C46.7156 33.532 46.2896 34.066 45.7616 34.546L42.4856 37.642H47.7596V40H37.6256Z"
                fill="#343434"
              />
            </svg>
          </div>
          <div>
            <h5 style={{ marginBottom: "0px" }}>About the School</h5>
            <p className="FormParagraph" style={{ marginBottom: "0px" }}>
              Provide the following information about the school
            </p>
          </div>
        </div>
      </div>
      {/* <br /> */}

      {isFormNotFilled ? (
        <span
          style={{
            color: "red",
            fontStyle: "italic",
            transition: "ease-in-out",
          }}
        >
          Please fill all fields
        </span>
      ) : null}
      <Form className="form">
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            School Name <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <Form.Control
            size="md"
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
            }}
            type="text"
            name="name"
            value={name}
            onChange={handleOnChange}
            placeholder="Enter Your School"
            required
          />
        </Form.Group>

        <div
          style={{
            marginBottom: "15px",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <p style={{ marginBottom: "5px", fontWeight: "500" }}>
            School Type{" "}
            <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
          </p>

          <div ref={dropdownRef}>
            <label
              style={{
                width: "100%",
                backgroundColor: "#ededed",
                borderRadius: "5px",
                padding: "7px 10px",
                cursor: "pointer",
                fontSize: "14px",
              }}
              onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
            >
              {selectedItems?.length > 0
                ? selectedItemsNames?.join(", ")
                : "Select School Type"}
            </label>

            {showCategoryDropdown && (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  zIndex: "10",
                  transition: "all",
                  transitionTimingFunction: "ease-in-out",
                  transitionDuration: "0.3s",
                  backgroundColor: "white",
                  width: "100%",
                  border: "1px solid rgb(221, 221, 221)",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                {institutionTypesArray &&
                  institutionTypesArray.map(({ typeName, id }) => (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "2px",
                      }}
                    >
                      <div style={{ position: "relative" }}>
                        <label className="checkbox-container">
                          <input
                            type="checkbox"
                            // checked={selectedItems.includes(id)}
                            // onChange={(e) => handleItemClick({ typeName, id })}
                            checked={selectedItems?.find(
                              (item) => item.id === id
                            )}
                            onChange={(e) => handleItemClick({ typeName, id })}
                            style={{
                              marginRight: "5px",
                              width: "auto",
                              appearance: "auto",
                            }}
                          />
                          <span className={`custom-checkbox`}></span>
                        </label>
                      </div>
                      <span>{typeName}</span>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            School Email <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <InputGroup className="mb-3">
            <InputGroup.Text
              id="basic-addon1"
              style={{ backgroundColor: "#F14810", color: "#ffffff" }}
            >
              <EnvelopeAt size={22} />
            </InputGroup.Text>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="email"
              placeholder="Enter Your School Email"
              name="institutionEmail"
              value={institutionEmail}
              onChange={handleOnChange}
              required
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">Contact Number</Form.Label>

          <InputGroup className="mb-3">
            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="number"
              placeholder="Enter your School Contact Number"
              name="branchContactNumber"
              value={branchContactNumber}
              onChange={handleOnChange}
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">School Website</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Text
              id="basic-addon1"
              style={{ backgroundColor: "#F14810", color: "#ffffff" }}
            >
              <Link45deg size={22} />
            </InputGroup.Text>
            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              name="website"
              value={website}
              onChange={handleOnChange}
              placeholder="www.yourschoolwebsite.com"
              aria-label="link"
              aria-describedby="basic-addon1"
              required
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            School Address <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <Form.Control
            size="md"
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
            }}
            type="text"
            name="address"
            value={address}
            onChange={handleOnChange}
            placeholder="Enter Your School Address"
            required
          />
        </Form.Group>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                Country <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <Form.Select
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                name="country"
                value={country}
                onChange={handleOnChange}
                required
              >
                <option value="" disabled>
                  Select Country
                </option>
                {countryData &&
                  countryData.map(({ countryName, id }) => (
                    // <option
                    //   key={id}
                    //   value={id}
                    // >
                    //   {countryName}
                    // </option>
                    <option key={id} value={countryName}>
                      {countryName}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                State <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <Form.Select
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                name="state"
                value={state}
                onChange={handleOnChange}
                placeholder="Enter Your Phone number"
                required
              >
                <option value="" disabled>
                  Select State
                </option>
                {states &&
                  states.map(({ stateName, id }) => (
                    // <option key={id} value={id}>
                    //   {stateName}
                    // </option>
                    <option key={id} value={stateName}>
                      {stateName}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <br />

        <div>
          <Button
            variant="primary"
            // type="submit"
            style={{
              backgroundColor: "#F14810",
              borderColor: "#F14810",
              float: "left",
            }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
              onClick={handleGoToNextPage}
            >
              Save & Continue
            </span>
          </Button>
        </div>
      </Form>
      <br />
      <br />
    </>
  );
};

export default AboutSchoolPage;
