import { Bell, List } from "react-bootstrap-icons";
import { Row, Col } from "react-bootstrap";
import { Sidebar, MenuItem, Menu } from "react-pro-sidebar";
import { Navbar, Container, Image, Spinner, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import logo from "../../../../../assets/svgs/logo.svg";
import alertIcon from "../../../../../assets/svgs/alertIcon.svg";
import placeholderLogo from "../../../../../assets/svgs/institutionPlaceholderLogo.svg";
import DashboardIcon from "../../../../../assets/svgs/dashboardIcon";
import StudentIcon from "../../../../../assets/svgs/studentIcon";
import ParentIcon from "../../../../../assets/svgs/parentIcon";
import InvoicingIcon from "../../../../../assets/svgs/Invoicing.js";
import AccountIcon from "../../../../../assets/svgs/accountIcon";
import BranchIcon from "../../../../../assets/svgs/BranchIcon";
import ClassIcon from "../../../../../assets/svgs/ClassIcon";
import FeeIcon from "../../../../../assets/svgs/FeeIcon.js";
import SchoolCalendarIcon from "../../../../../assets/svgs/SchoolCalendarIcon.js";
import HelpIcon from "../../../../../assets/svgs/helpicon";
import SettingsIcon from "../../../../../assets/svgs/settingsIcon";
import ReportingIcon from "../../../../../assets/svgs/reportingIcon";
import { useSelector } from "react-redux";
import {
  useGetInstitutionLogoQuery,
  useGetSetupProgressQuery,
} from "../../../../../redux/features/institution/institutionApiSlice";
import { useLocation } from "react-router-dom";
import { capitalizeWords } from "../../../../../utils/utils";
import SettlementIcon from "../../../../../assets/svgs/settlementIcon.js";

const DashboardSidebar = ({ institutionDetails }) => {
  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const navigate = useNavigate();

  const logoChangeModal = useSelector(
    (state) => state?.institution?.logoChange
  );

  // const {
  //   data: institutionLogo,
  //   refetch,
  //   isLoading,
  // } = useGetInstitutionLogoQuery(id);

  const {
    data: institutionLogo,
    refetch,
    isLoading,
  } = useGetInstitutionLogoQuery(id);

  const { data: setupProgress, isLoading: progressLoading } =
    useGetSetupProgressQuery(id);

  // console.log(setupProgress);

  useEffect(() => {
    if (institutionLogo) {
      refetch();
      // console.log(".....................")
    }
  }, [logoChangeModal]);

  const menuItem = {
    height: 2.7 + "rem",
    fontSize: 0.87 + "rem",
  };

  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(
    window.matchMedia("(max-width: 950px)").matches
  );

  const location = useLocation();

  const goToSetupPage = () => {
    navigate("/school-setup");
  };

  return (
    <Sidebar
      backgroundColor="#FFFDFC"
      className="sidebar"
      onBackdropClick={() => setToggled(false)}
      breakPoint="always"
      toggled={toggled}
      customBreakPoint="1000px"
      onBreakPoint={setBroken}
    >
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          paddingLeft: "15px",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "100%",
            overflow: "hidden",
          }}
        >
          {isLoading ? (
            <Spinner animation="border" variant="danger" color="#F14810" />
          ) : (
            <img
              src={institutionLogo ? institutionLogo?.logo : placeholderLogo}
              style={{
                width: "100%",
                minWidth: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          )}
        </div>
        <div style={{ marginTop: 10 + "px" }}>
          <h4
            style={{
              fontSize: 1.0 + "rem",
              marginBottom: "0px",
            }}
          >
            {capitalizeWords(institutionDetails?.institutionName)}
          </h4>
          <p
            style={{
              fontSize: 0.85 + "rem",
              margin: "0px",
            }}
          >
            {institutionDetails?.address}
          </p>
        </div>
      </div>

      <Menu
        className="menu"
        style={{ marginBottom: 2 + "rem", marginTop: 2 + "rem" }}
        menuItemStyles={{
          button: ({ active }) => ({
            color: active ? "white" : "#464646",
            backgroundColor: active ? "#FD5821" : "transparent",
            "&:hover": {
              backgroundColor: active ? "#FD5821" : "#F5F5F5",
              color: active ? "white" : "#FFFFFF",
            },
          }),
        }}
      >
        {institutionDetails?.setupComplete === false && (
          <div
            className="school-setup"
            style={{
              backgroundColor: "#ffe6de",
              border: "1px solid #DBDBDB",
              borderRadius: 8 + "px",
              margin: 10 + "px",
              marginTop: -1.5 + "rem",
              marginBottom: 2 + "rem",
              padding: 4 + "px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 6,
              }}
            >
              <img src={alertIcon} style={{ width: "12%" }} />
              <p
                style={{
                  fontSize: 0.7 + "rem",
                  marginTop: 10 + "px",
                }}
              >
                Complete your school setup here to enable settlements
              </p>
            </div>

            <h6
              style={{
                marginLeft: 20 + "px",
                marginTop: -2 + "px",
              }}
            >
              {setupProgress?.progress} Completion
            </h6>

            <div>
              <Button
                variant="primary"
                style={{
                  marginLeft: 20 + "px",
                  marginBottom: 10 + "px",
                  backgroundColor: "#F14810",
                  borderColor: "#F14810",
                }}
                className="button"
                size="sm"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                  onClick={goToSetupPage}
                >
                  Complete Setup
                </span>
              </Button>
            </div>
          </div>
        )}

        <Link to="/dashboard">
          <MenuItem
            style={menuItem}
            active={
              location.pathname.startsWith("/dashboard") ? "active" : null
            }
            icon={
              <DashboardIcon
                active={
                  location.pathname.startsWith("/dashboard") ? "active" : null
                }
                size={18}
              />
            }
          >
            {" "}
            Dashboard
          </MenuItem>
        </Link>

        <Link to="/branch">
          <MenuItem
            style={menuItem}
            active={location.pathname.startsWith("/branch") ? "active" : null}
            icon={
              <BranchIcon
                active={
                  location.pathname.startsWith("/branch") ? "active" : null
                }
                size={18}
              />
            }
          >
            {" "}
            Branch Management
          </MenuItem>
        </Link>

        <Link to="/student">
          <MenuItem
            style={menuItem}
            active={location.pathname.startsWith("/student") ? "active" : null}
            icon={
              <StudentIcon
                active={
                  location.pathname.startsWith("/student") ? "active" : null
                }
                size={19}
              />
            }
          >
            {" "}
            Students Management
          </MenuItem>
        </Link>

        {/* <Link to="/schoolYear">
          <MenuItem
            style={menuItem}
            active={
              location.pathname.startsWith("/schoolYear") ? "active" : null
            }
            icon={
              <SchoolCalendarIcon
                active={
                  location.pathname.startsWith("/schoolYear") ? "active" : null
                }
                size={18}
              />
            }
          >
            {" "}
            Calendar Management
          </MenuItem>
        </Link> */}

        <Link to="/invoicing">
          <MenuItem
            style={menuItem}
            active={
              location.pathname.startsWith("/invoicing") ? "active" : null
            }
            icon={
              <InvoicingIcon
                active={
                  location.pathname.startsWith("/invoicing") ? "active" : null
                }
                size={19}
              />
            }
          >
            {" "}
            Invoicing
          </MenuItem>
        </Link>

        <Link to="/classes">
          <MenuItem
            style={menuItem}
            active={location.pathname.startsWith("/classes") ? "active" : null}
            icon={<ClassIcon size={18} />}
          >
            {" "}
            Classes
          </MenuItem>
        </Link>

        <Link to="/messaging">
          <MenuItem
            style={menuItem}
            active={
              location.pathname.startsWith("/messaging") ? "active" : null
            }
            icon={
              <SettlementIcon
                active={
                  location.pathname.startsWith("/messaging") ? "active" : null
                }
                size={18}
              />
            }
          >
            {" "}
            Reminders & Notifications
          </MenuItem>
        </Link>
        {/* <MenuItem style={menuItem} icon={<FeeIcon size={19} />}>
          {" "}
          Fees
        </MenuItem> */}
        <Link to="/reporting">
          <MenuItem
            style={menuItem}
            active={
              location.pathname.startsWith("/reporting") ? "active" : null
            }
            icon={
              <ReportingIcon
                active={
                  location.pathname.startsWith("/reporting") ? "active" : null
                }
                size={18}
              />
            }
          >
            {" "}
            Reporting
          </MenuItem>
        </Link>
        <Link to="/settings">
          <MenuItem
            style={menuItem}
            active={location.pathname.startsWith("/settings") ? "active" : null}
            icon={
              <SettingsIcon
                active={
                  location.pathname.startsWith("/settings") ? "active" : null
                }
                size={19}
              />
            }
          >
            {" "}
            Settings
          </MenuItem>
        </Link>
        <Link to="/help" style={{ margingBottom: 2 + "rem" }}>
          <MenuItem
            style={menuItem}
            active={location.pathname.startsWith("/help") ? "active" : null}
            icon={
              <HelpIcon
                active={location.pathname.startsWith("/help") ? "active" : null}
                size={18}
              />
            }
          >
            {" "}
            Help
          </MenuItem>
        </Link>
      </Menu>

      <div
        style={{
          width: "auto",
          height: "auto",
          marginTop: "auto",
          paddingTop: 1 + "rem",
          paddingBottom: 1 + "rem",
          borderTop: "1px solid #DBDBDB",
          // position: "fixed",
          // bottom: 0,
        }}
      >
        <Navbar.Brand
          className="logo"
          // style={{ marginTop: 20 + "px" }}
        >
          <div className="navbar-brand-space">{/* <br /> */}</div>

          <Image src={logo} fluid />
        </Navbar.Brand>
      </div>
    </Sidebar>
  );
};

export default DashboardSidebar;
