import React from "react";
import { useState, useEffect } from "react";
// import { Offcanvas } from "react-bootstrap";
import SchoolBranchIcon from "../../../../../../assets/svgs/schoolbranch.svg";
// import EyeActionIcon from "../../../../../assets/svgs/eyeActionIcon.svg";
import ButtonX from "../../../../../../component/button/button";
import { Row, Col, Button } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import ModalComponent from "../../../../../../component/modal/modal";
import { useNavigate } from "react-router-dom";
import "./institutionReportGroup.css";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import {
  returnLevelName,
  returnCategoryName,
} from "../../../../../../utils/utils";
import {
  useGetInstitutionYearQuery,
  useCreateInstitutionYearMutation,
} from "../../../../../../redux/features/institution/institutionApiSlice";

const InstitutionReportGroupPage = ({
  institutionDetails,
  institutionLogo,
}) => {
  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  const schoolTypes = useSelector(
    (state) => state?.auth?.userInfo?.institutionTypes
  );
  console.log(instId);

  const reportGroupList = [
    // {
    //   id: "1",
    //   title: "Branches",
    //   // text: "120 Students",
    //   style: "branchsidecard-x1",
    // },
    {
      id: 2,
      title: "Levels",
      // title: returnLevelName(schoolTypes),
      //   text: "239 Students",
      style: "branchsidecard-x1",
    },
    {
      id: 3,
      title: "Categories",
      // title: returnCategoryName(schoolTypes),
      // text: "546 Students",
      style: "branchsidecard-x1",
    },
    // {
    //   id: 4,
    //   title: "Year",
    //   //   text: "732 Students",
    //   style: "branchsidecard-x1",
    // },
    // {
    //   id: 5,
    //   title: "Calendar",
    //   //   text: "732 Students",
    //   style: "branchsidecard-x1",
    // },
    {
      id: 6,
      title: "Payment History",
      //   text: "732 Students",
      style: "branchsidecard-x1",
    },
    {
      id: 7,
      title: "Wallet Transactions",
      //   text: "732 Students",
      style: "branchsidecard-x1",
    },
  ];

  const navigate = useNavigate();

  //Navigate to Payment History Page
  const handleViewReport = (id) => {
    // console.log(id, 7777788888888)
    if (id == 1) {
      navigate(`/reporting/#`);
    } else if (id == 2) {
      navigate(`/reporting/levelSummary`);
    } else if (id == 3) {
      navigate(`/reporting/categorySummary`);
    } else if (id == 4) {
      navigate(`/reporting/#`);
    } else if (id == 5) {
      navigate(`/reporting/#`);
    } else if (id == 6) {
      navigate(`/reporting/paymentHistory`);
    } else {
      navigate(`/reporting/walletTransactions`);
    }
  };

  return (
    <>
      <div className="reportGroup-page">
        <section
          style={{
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div style={{ display: "flex", gap: "5px" }}>
              <h5>Reporting</h5>
            </div>
            {/* <div style={{ display: "flex", gap: "5px" }}>
                                <ButtonX
                                    backgroundColor="#f14810"
                                    border="#f14810"
                                    onClick={() => setShowYearModal(true)}
                                >
                                    Advanced Report
                                </ButtonX>
                            </div> */}
          </div>

          <>
            <Row style={{ marginTop: 0.4 + "rem" }}>
              <Col xs={12} md={12} lg={12} className="otherbox">
                <Row>
                  {reportGroupList.map(({ id, title }, i) => (
                    <Col
                      xs={4}
                      md={4}
                      lg={4}
                      id="reportGroupsidecard-x1"
                      key={id}
                    >
                      <div
                        className="reportGroupsidecard-x1"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleViewReport(id)}
                      >
                        <div className="card-inner-value">
                          <h6
                            style={{
                              marginTop: 1.2 + "rem",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            <b>{title}</b>
                          </h6>
                          <p
                            className="card-inner-text"
                            style={{ marginTop: -0.61 + "rem" }}
                          ></p>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </>
        </section>

        <br />
        <br />
      </div>
    </>
  );
};

export default InstitutionReportGroupPage;
