import { useState, useEffect } from "react";
import eyeActionIcon from "../../../../../../assets/svgs/eyeActionIcon.svg";
import DashboardTopSection from "../../../dashboardTopSection";
import DashboardSidebar from "../../../dashboardSideBar/sidebar";
import TableComponent from "../../../../../../component/table/table";
import Button from "../../../../../../component/button/button";
import { useNavigate } from "react-router-dom";
import { addTrailingCommas } from "../../../../../../utils/utils";
//import CreateCommissionCanvas from "./components/createCommissionCanvas";
import { Offcanvas } from "react-bootstrap";
//import GlobalCommissionCanvas from "./components/globalCommissionCanvas";
import {
  useGetInstitutionListQuery,
  useGetReminderChargesQuery,
} from "../../../../../../redux/features/superAdmin/adminApiSlice";
//import SettlementDetailsPage from "./components/settlementDetails";
import ActionIcon from "../../../actionbutton/ActionIcon";
import SetReminderChargeCanvas from "./setReminderChargesCanvas";
import EditReminderChargeCanvas from "./editReminderChargeCanvas";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";

const ReminderChargesPage = () => {
  const navigate = useNavigate();
  const [showRateCanvas, setShowRateCanvas] = useState(false);
  const [showGlobalRateCanvas, setShowGlobalRateCanvas] = useState(false);

  const {
    data: reminderChargesList,
    isLoading: reminderChargesLoading,
    refetch: refetchReminderCharges,
  } = useGetReminderChargesQuery();
  console.log(reminderChargesList);

  const {
    data: institutionsList,
    isLoading: institutionsLoading,
    refetch: refetchInstitutions,
  } = useGetInstitutionListQuery();
  console.log(institutionsList);

  const columns = [
    // { name: "id", label: "ID" },
    { name: "institutionName", label: "School Name" },
    // { name: "institutionType", label: "School Type" },
    {
      name: "reminderEmailCharge",
      label: "Email Charge",
      options: {
        customBodyRender: (charge) =>
          charge ? "₦ " + addTrailingCommas(charge) : "₦ " + 0,
      },
    },
    // {
    //   name: "balance",
    //   label: "Wallet Balance",
    //   options: {
    //     customBodyRender: (balance) =>
    //       balance ? addTrailingCommas(balance) : 0,
    //   },
    // },
    {
      name: "reminderSmsCharge",
      label: "SMS Charge",
      options: {
        customBodyRender: (charge) =>
          charge ? "₦ " + addTrailingCommas(charge) : "₦ " + 0,
      },
    },
    // {
    //     name: "commisionAmount",
    //     label: "Commission Amount",
    //     options: {
    //         customBodyRender: (commission) =>
    //             commission ? addTrailingCommas(commission) : 0,
    //     },
    // },
    // {
    //     name: "actions",
    //     label: "Action",
    //     options: {
    //         customBodyRender: (value, tableMeta) => (
    //             <ActionIcon
    //                 actionsArr={[
    //                     {
    //                         label: "Edit",
    //                         action: () => {
    //                             //handleEditPermission(permissionPages[tableMeta.rowIndex]);
    //                         },
    //                     },
    //                     {
    //                         label: "Delete",
    //                         action: () => {
    //                             //confirmDelete(permissionPages[tableMeta.rowIndex]);
    //                         },
    //                     },
    //                 ]}
    //             />
    //         ),
    //     },
    // },
  ];

  const handleDetailView = (obj) => {};
  const handleOnSetShowRateCanvas = (obj) => {
    setShowRateCanvas(obj);
  };

  useEffect(() => {
    if (reminderChargesList) {
      refetchReminderCharges();
    }
  }, [showRateCanvas, showGlobalRateCanvas]);

  if (reminderChargesLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div>
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <h6 style={{ fontSize: "14px", fontWeight: 600 }}>
            Reminder Charges
          </h6>
          <div style={{ display: "flex", gap: "5px" }}>
            <Button
              //className="button"
              //size="md"
              // style={{
              //     backgroundColor: "#F14810",
              //     color: "#ffffff",
              //     border: "none",
              //     outline: "none",
              //     fontSize: "14px",
              // }}
              backgroundColor="#F14810"
              color="#ffffff"
              onClick={() => setShowRateCanvas(true)}
            >
              Set Reminder Charge
            </Button>
          </div>
        </div>

        <div
          style={{
            border: "1px solid #F5F5F5",
            borderRadius: "8px",
            padding: "15px",
            height: "95%",
          }}
        >
          <TableComponent
            data={reminderChargesList}
            title=""
            columns={columns}
            search={true}
            pagination={true}
          />
        </div>

        <div>
          <Offcanvas
            show={showRateCanvas}
            onHide={() => setShowRateCanvas(false)}
            placement={"end"}
            backdrop={true}
            style={{ width: "100%", maxWidth: "500px" }}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Set Reminder Charges</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ paddingTop: "0px" }}>
              <SetReminderChargeCanvas
                OnsetShowRateCanvas={handleOnSetShowRateCanvas}
              />
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas
            //show={showRateCanvas}
            onHide={() => setShowRateCanvas(false)}
            placement={"end"}
            backdrop={true}
            style={{ width: "100%", maxWidth: "500px" }}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Edit Reminder Charges</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ paddingTop: "0px" }}>
              <EditReminderChargeCanvas />
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </>
    </div>
    //     <div className="schools-page no-scrollbar">
    //         {/* <div>
    //     <DashboardSidebar />
    //   </div> */}

    //         {/* <Offcanvas
    //             show={showGlobalRateCanvas}
    //             onHide={() => setShowGlobalRateCanvas(false)}
    //             placement={"end"}
    //             backdrop={true}
    //             style={{ width: "100%", maxWidth: "500px" }}
    //         >
    //             <Offcanvas.Header closeButton>
    //                 <Offcanvas.Title>Create Global Rate</Offcanvas.Title>
    //             </Offcanvas.Header>
    //             <Offcanvas.Body style={{ paddingTop: "0px" }}>
    //                 <GlobalCommissionCanvas />
    //             </Offcanvas.Body>
    //         </Offcanvas>
    //         <Offcanvas
    //             show={showRateCanvas}
    //             onHide={() => setShowRateCanvas(false)}
    //             placement={"end"}
    //             backdrop={true}
    //             style={{ width: "100%", maxWidth: "500px" }}
    //         >
    //             <Offcanvas.Header closeButton>
    //                 <Offcanvas.Title>Apply Rate</Offcanvas.Title>
    //             </Offcanvas.Header>
    //             <Offcanvas.Body style={{ paddingTop: "0px" }}>
    //                 <CreateCommissionCanvas />
    //             </Offcanvas.Body>
    //         </Offcanvas> */}
    //     </div>
  );
};

export default ReminderChargesPage;
