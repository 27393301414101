import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import DashboardSidebar from "../../dashboardSidebar/sidebar";
import DashboardTopSection from "../../dashboardTopSection/index";
import { useSelector } from "react-redux";

import { addTrailingCommas } from "../../../../../../utils/utils";
import TableComponent from "../../../../../../component/table/table";
import backArrow from "../../../../../../assets/svgs/backArrow.svg";

import {
  useGetInstitutionQuery,
  useGetClassPendingPaymentSummaryQuery,
  useGetClassPendingPaymentReportQuery,
} from "../../../../../../redux/features/institution/institutionApiSlice";

import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";

const PendingPaymentPage = () => {
  const navigate = useNavigate();

  const [classViewed, setClassViewed] = useState(null);

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const { data: institutionDetails, isLoading: detailsLoading } =
    useGetInstitutionQuery(instId);

  const { data: pendingPayments, isLoading: pendingPaymentLoading } =
    useGetClassPendingPaymentSummaryQuery(instId);

  const { data: pendingPaymentReport, isLoading: pendingPaymentReportLoading } =
    useGetClassPendingPaymentReportQuery(
      {
        institutionId: instId,
        classId: classViewed?.id,
      },
      { skip: !classViewed }
    );

  console.log(pendingPaymentReport);

  const columns = [
    // { name: "studentId", label: "ID" },
    {
      name: "studentName",
      label: "Student Name",
    },
    { name: "className", label: "Class" },
    {
      name: "pendingPayment",
      label: "Pending Payment",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { pendingPayment } = pendingPaymentReport[tableMeta.rowIndex];
          return `₦ ${addTrailingCommas(pendingPayment)}`;
        },
      },
    },
  ];

  if (pendingPaymentLoading || pendingPaymentReportLoading) {
    return <OnboardingLoader />;
  }

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar institutionDetails={institutionDetails} />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />
        <div style={{ marginTop: "40px" }}>
          {classViewed ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                  marginBottom: "20px",
                }}
                onClick={() => setClassViewed(null)}
              >
                <img src={backArrow} alt="Go back" />
                <h6 style={{ marginBottom: 0 }}>{classViewed?.className}</h6>
              </div>

              <TableComponent
                data={pendingPaymentReport}
                title=""
                columns={columns}
              />

              <br />
              <br />
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                  marginBottom: "20px",
                }}
                onClick={() => navigate(-1)}
              >
                <img src={backArrow} alt="Go back" />
                <h6 style={{ marginBottom: 0 }}>Pending Payment</h6>
              </div>

              <Row>
                <Col xs={12} md={12} lg={12} className="otherbox">
                  <Row>
                    {pendingPayments?.map(
                      ({ id, className, pendingPayment, totalStudents }) => (
                        <Col
                          xs={4}
                          md={4}
                          lg={4}
                          id="branchsidecard-x1"
                          key={id}
                          onClick={() =>
                            setClassViewed({
                              id,
                              className,
                              pendingPayment,
                              totalStudents,
                            })
                          }
                        >
                          <div
                            className="branchsidecard-x1"
                            style={{
                              cursor: "pointer",
                              minHeight: "80px",
                              padding: "15px",
                            }}
                          >
                            <h6
                              style={{ fontWeight: "600", marginBottom: "2px" }}
                            >
                              {className}
                            </h6>
                            <p style={{ color: "#a7a7a7" }}>
                              {totalStudents} Students
                            </p>

                            <div>
                              <h6
                                style={{
                                  fontWeight: "600",
                                  marginBottom: "2px",
                                }}
                              >
                                ₦ {addTrailingCommas(pendingPayment)}
                              </h6>
                              <p
                                style={{
                                  color: "#b9b9b9",
                                  marginBottom: "0px",
                                }}
                              >
                                Pending Payment
                              </p>
                            </div>
                          </div>
                        </Col>
                      )
                    )}
                  </Row>
                </Col>
              </Row>

              <br />
              <br />
            </>
          )}
        </div>
      </Container>
    </Container>
  );
};

export default PendingPaymentPage;
