// import Button from "../../../../../component/button/button";
import { InputGroup, Form, Row, Col, Button } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import {
  useGetCountriesQuery,
  useGetStatesQuery,
} from "../../../../../../redux/features/auth/authApiSlice";
import {
  useEditInstitutionMutation,
  useGetInstitutionDetailQuery,
  useGetInstitutionTypeQuery,
} from "../../../../../../redux/features/superAdmin/adminApiSlice";
import { useSelector } from "react-redux";
import ToastComponent from "../../../../../../component/toast/toast";
import { Link45deg, EnvelopeAt } from "react-bootstrap-icons";
import dropdownIcon from "../../../../../../assets/svgs/dropdownIcon.svg";
import successGIF from "../../../../../../assets/svgs/successGIF.gif";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import ModalComponent from "../../../../../../component/modal/modal";

const SchoolDetails = ({ Id }) => {
  const {
    data: institutionDetails,
    isLoading: detailsLoading,
    refetch: refetchDetails,
  } = useGetInstitutionDetailQuery(Id);

  console.log(institutionDetails);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsNames, setSelectedItemsNames] = useState([]);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);

  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [selectedStateId, setSelectedStateId] = useState("");
  const [selectAll, setSelectAll] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  console.log(selectedCountryId);

  const { data: countryData } = useGetCountriesQuery();

  const { data: states } = useGetStatesQuery(
    selectedCountryId ? selectedCountryId : 1,
    { skip: !countryData }
  );

  const { data: institutionTypes, isLoading: typesLoading } =
    useGetInstitutionTypeQuery();

  const [updateInstitutionDetails, { isLoading: updateLoading }] =
    useEditInstitutionMutation();

  const [schoolDetails, setSchoolDetails] = useState({
    institutionName: institutionDetails?.institutionName,
    // institutionType: institutionDetails?.institutionType,
    // institutionType: institutionDetails?.institutionTypes?.typeName,
    institutionEmail: institutionDetails?.institutionEmail,
    schoolContactNumber: institutionDetails?.schoolContactNumber,
    website: institutionDetails?.website,
    address: institutionDetails?.address,
    country: institutionDetails?.country,
    state: institutionDetails?.state,
  });

  const {
    institutionName,
    institutionType,
    institutionEmail,
    schoolContactNumber,
    website,
    address,
    country,
    state,
  } = schoolDetails;

  const handleCloseModal = () => {
    setShowModal(false);
    refetchDetails();
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setSchoolDetails({ ...schoolDetails, [name]: value });
  };

  const handleSave = async () => {
    const params = {
      institutionId: Id,
      body: {
        // id: Id,
        institutionName: institutionName,
        // institutionType: selectedItems,
        institutionTypeIds: selectedItems?.map((type) => type.id),
        institutionEmail: institutionEmail,
        schoolContactNumber,
        website,
        address,
        country: selectedCountryId,
        state: selectedStateId,
      },
    };

    console.log(params);

    try {
      const res = await updateInstitutionDetails(params).unwrap();
      console.log(res);
      setShowModal(true);
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  useEffect(() => {
    setSchoolDetails({
      institutionName: institutionDetails?.institutionName,
      institutionEmail: institutionDetails?.institutionEmail,
      schoolContactNumber: institutionDetails?.schoolContactNumber,
      website: institutionDetails?.website,
      address: institutionDetails?.address,
      country: institutionDetails?.country,
      state: institutionDetails?.state,
    });

    const names = institutionDetails?.institutionTypes?.map(
      (item) => item.typeName
    );

    const selected = institutionDetails?.institutionTypes?.map((item) => {
      return { id: item.id, typeName: item.typeName };
    });

    setSelectedItems(selected);
    setSelectedItemsNames(names);
  }, [institutionDetails]);

  useEffect(() => {
    const country = countryData?.filter(
      (country) => country.countryName === schoolDetails.country
    );
    if (country) {
      setSelectedCountryId(country[0]?.id);
    }

    const state = states?.filter(
      (state) => state.stateName === schoolDetails.state
    );
    if (state) {
      setSelectedStateId(state[0]?.id);
    }
  }, [country, countryData, states, state]);

  const handleItemClick = (obj) => {
    if (selectedItems.find((item) => item.id === obj.id)) {
      setSelectedItems(selectedItems.filter((item) => item.id !== obj.id));
    } else {
      setSelectedItems([...selectedItems, { ...obj }]);
    }
  };

  useEffect(() => {
    const names = selectedItems?.map((item) => item.typeName);
    setSelectedItemsNames(names);
  }, [selectedItems]);

  console.log(selectedItemsNames);
  console.log(institutionDetails);

  useEffect(() => {
    const names = selectedItems?.map((item) => item.typeName);
    setSelectedItemsNames(names);
  }, [selectedItems]);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowCategoryDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  if (detailsLoading || updateLoading || typesLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      {/* success modal */}
      <ModalComponent showModal={showModal} closeModal={handleCloseModal}>
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!</h3>
          <p style={{ fontSize: "18px" }}>
            School Details updated successfully
          </p>
        </div>
      </ModalComponent>

      {/* <h6 style={{ fontSize: "14px", fontWeight: 600 }}>Institution Details</h6> */}
      <div
        style={{
          borderTop: "1px solid #EDEDED",
          paddingTop: "20px",
        }}
      >
        <div>
          <Form className="form">
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">School Name</Form.Label>

              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                type="text"
                name="institutionName"
                value={institutionName}
                onChange={handleOnChange}
                placeholder="Enter Your School"
                required
              />
            </Form.Group>

            <div
              ref={dropdownRef}
              style={{
                marginBottom: "15px",
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <p style={{ marginBottom: "5px", fontWeight: "500" }}>
                School Type{" "}
                <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
              </p>

              <div style={{ position: "relative" }}>
                <label
                  style={{
                    width: "100%",
                    backgroundColor: "#ededed",
                    borderRadius: "5px",
                    padding: "7px 10px",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                  onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
                >
                  {selectedItems?.length > 0
                    ? selectedItemsNames?.join(", ")
                    : "Select School Type"}
                </label>
                <img
                  src={dropdownIcon}
                  alt="dropdown icon"
                  style={{ position: "absolute", right: "15px", top: "40%" }}
                />
              </div>

              {showCategoryDropdown && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    zIndex: "10",
                    transition: "all",
                    transitionTimingFunction: "ease-in-out",
                    transitionDuration: "0.3s",
                    backgroundColor: "white",
                    width: "100%",
                    border: "1px solid rgb(221, 221, 221)",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  {institutionTypes &&
                    institutionTypes.map(({ typeName, id }) => (
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginBottom: "2px",
                        }}
                        key={id}
                      >
                        <div style={{ position: "relative" }}>
                          <label className="checkbox-container">
                            <input
                              type="checkbox"
                              checked={selectedItems
                                ?.map((item) => item.id)
                                .includes(id)}
                              onChange={(e) =>
                                handleItemClick({ typeName, id })
                              }
                              style={{
                                marginRight: "5px",
                                width: "auto",
                                appearance: "auto",
                              }}
                            />
                            <span className={`custom-checkbox`}></span>
                          </label>
                        </div>
                        <span>{typeName}</span>
                      </div>
                    ))}
                </div>
              )}
            </div>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                School Email <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <InputGroup className="mb-3">
                <InputGroup.Text
                  id="basic-addon1"
                  style={{ backgroundColor: "#F14810", color: "#ffffff" }}
                >
                  <EnvelopeAt size={22} />
                </InputGroup.Text>

                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type="email"
                  placeholder="Enter Your School Email"
                  name="institutionEmail"
                  value={institutionEmail}
                  onChange={handleOnChange}
                  disabled
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                Contact Number <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <InputGroup className="mb-3">
                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type="text"
                  placeholder="Enter your School Contact Number"
                  name="schoolContactNumber"
                  value={schoolContactNumber}
                  onChange={handleOnChange}
                  required
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">School Website</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text
                  id="basic-addon1"
                  style={{ backgroundColor: "#F14810", color: "#ffffff" }}
                >
                  <Link45deg size={22} />
                </InputGroup.Text>
                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  name="website"
                  value={website}
                  onChange={handleOnChange}
                  placeholder="www.yourschoolwebsite.com"
                  aria-label="link"
                  aria-describedby="basic-addon1"
                  required
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                School Address <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                type="text"
                name="address"
                value={address}
                onChange={handleOnChange}
                placeholder="Enter Your School Address"
                required
              />
            </Form.Group>

            <Row>
              <Col>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">Country</Form.Label>

                  <Form.Select
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    name="country"
                    value={country}
                    onChange={handleOnChange}
                    required
                  >
                    <option value="" disabled>
                      Select Country
                    </option>
                    {countryData &&
                      countryData.map(({ countryName, id }) => (
                        // <option
                        //   key={id}
                        //   value={id}
                        // >
                        //   {countryName}
                        // </option>
                        <option key={id} value={countryName}>
                          {countryName}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">State</Form.Label>

                  <Form.Select
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    name="state"
                    value={state}
                    onChange={handleOnChange}
                    placeholder="Enter Your Phone number"
                    required
                  >
                    <option value="" disabled>
                      Select State
                    </option>
                    {states &&
                      states.map(({ stateName, id }) => (
                        // <option key={id} value={id}>
                        //   {stateName}
                        // </option>
                        <option key={id} value={stateName}>
                          {stateName}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingTop: "20px",
            }}
          >
            <Button
              className="button"
              size="md"
              style={{
                backgroundColor: "#F14810",
                color: "#ffffff",
                border: "none",
                outline: "none",
              }}
              onClick={handleSave}
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.3 + "rem",
                  marginRight: 0.3 + "rem",
                }}
              >
                Save Changes
              </span>
            </Button>
          </div>
        </div>
      </div>

      <br />
      <br />
    </>
  );
};

export default SchoolDetails;
