import React from "react";
import { Form, Button } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useRegisterMutation } from "../../../../redux/features/auth/authApiSlice";
import { storeInstitutionData } from "../../../../redux/features/institution/institutionSlice.js";
import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";
import stageThreeIcon from "../../../../assets/svgs/stageThreeRegistrationIcon.svg";
import showPasswordIcon from "../../../../assets/svgs/showPassword.svg";
import hidePasswordIcon from "../../../../assets/svgs/hidePassword.svg";
import ToastComponent from "../../../../component/toast/toast";

import "./setPassword.css";

const SetPasswordPage = ({ setActiveStage }) => {
  // password validation
  const [isMinCharsValid, setIsMinCharsValid] = useState(false);
  const [isUppercaseValid, setIsUppercaseValid] = useState(false);
  const [isLowercaseValid, setIsLowercaseValid] = useState(false);
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [isSpecialCharValid, setIsSpecialCharValid] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const contactPersonData = useSelector(
    (state) => state.auth.contactPersonInfo
  );

  console.log(contactPersonData);

  const aboutSchoolInfo = useSelector((state) => state.auth.aboutSchoolInfo);

  const [finalDetails, setFinalDetails] = useState({
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState(null);

  const { password } = finalDetails;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFinalDetails({ ...finalDetails, [name]: value });

    // Check each condition for the password validation
    setIsMinCharsValid(value.length >= 8);
    setIsUppercaseValid(/[A-Z]/.test(value));
    setIsLowercaseValid(/[a-z]/.test(value));
    setIsNumberValid(/\d/.test(value));
    setIsSpecialCharValid(/[@#$%^&+=]/.test(value));
  };

  const [register, { isLoading }] = useRegisterMutation();
  const dispatch = useDispatch();
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const { selectedCountryId, selectedStateId, ...rest } = aboutSchoolInfo;
  const { customerSupportNumber, ...others } = contactPersonData;

  const aboutSchoolWithIDS = {
    ...rest,
    country: selectedCountryId,
    state: selectedStateId,
  };
  const contactPersonWithCustomerSupportNumber = {
    ...others,
    customerSupportNumber: customerSupportNumber?.phoneNumber,
  };

  const trySubmit = async (e) => {
    e.preventDefault();

    // test if the password is now valid before submitting
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}$/;

    if (!passwordRegex.test(password)) {
      setIsPasswordValid(false);
      return;
    }

    try {
      setIsPasswordValid(true);
      const data = await register({
        // ...aboutSchoolInfo,
        ...aboutSchoolWithIDS,
        // ...contactPersonData,
        ...contactPersonWithCustomerSupportNumber,
        primaryContact: {
          ...contactPersonWithCustomerSupportNumber.primaryContact,
          password: password,
        },
      }).unwrap();

      console.log(data);

      if (data) {
        setToastMessage("Register successful!");
        setShowToast(true);
        dispatch(storeInstitutionData(data));
        console.log("Success............");
        setActiveStage("Validate Email");
      }
    } catch (error) {
      // setErrorMessage(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  if (isLoading) {
    console.log("Loading............", isLoading);
    return <OnboardingLoader />;
  }


  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <div>
        <h3 className="FormHeader">Register Your Institution</h3>

        <div style={{ display: "flex", gap: 0.8 + "rem" }}>
          <div>
            <img src={stageThreeIcon} alt="registration stage 3 icon" />
          </div>
          <div>
            <h5 style={{ marginBottom: "0px" }}>Set Password</h5>
            <p className="FormParagraph">
              Set a strong and memorable password for your school
            </p>
          </div>
        </div>
      </div>
      <br />
      {/* {errorMessage && (
        <span style={{ color: "red", fontStyle: "italic" }}>
          {errorMessage}
        </span>
      )} */}

      <Form className="form" onSubmit={(e) => e.preventDefault()}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            Password <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <div style={{ position: "relative" }} className="password-box">
            <Form.Control
              size="md"
              className={`custom-focus ${!isPasswordValid ? "is-invalid" : ""}`}
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type={showPassword ? "text" : "password"}
              name="password"
              value={password}
              autoComplete="off"
              onChange={handleOnChange}
              placeholder="Enter Your Password"
            />
            {showPassword ? (
              <img
                src={hidePasswordIcon}
                alt="showPassword Icon"
                onClick={() => setShowPassword(false)}
                className="hidePasswordIcon"
              />
            ) : (
              <img
                src={showPasswordIcon}
                alt="showPassord Icon"
                onClick={() => setShowPassword(true)}
                className="showPasswordIcon"
              />
            )}
          </div>
        </Form.Group>

        <ul style={{ listStyle: "none", marginLeft: -2 + "rem" }}>
          <li
            className="fst-italic"
            style={{
              color: "red",
              fontFamily: "jost, sans-serif",
              fontSize: 0.85 + "rem",
            }}
          >
            Password must contain
          </li>
          <li
            className="fst-italic"
            style={{
              fontFamily: "jost, sans-serif",
              fontSize: 0.85 + "rem",
              color: !isMinCharsValid ? "red" : "#2bb4b1",
            }}
          >
            Minimum of 8 characters
          </li>
          <li
            className="fst-italic"
            style={{
              fontFamily: "jost, sans-serif",
              fontSize: 0.85 + "rem",
              color: !isLowercaseValid || !isUppercaseValid ? "red" : "#2bb4b1",
            }}
          >
            one uppercase letter and one lowercase letter
          </li>
          <li
            className="fst-italic"
            style={{
              fontFamily: "jost, sans-serif",
              fontSize: 0.85 + "rem",
              color: !isNumberValid ? "red" : "#2bb4b1",
            }}
          >
            one number
          </li>
          <li
            className="fst-italic"
            style={{
              fontFamily: "jost, sans-serif",
              fontSize: 0.85 + "rem",
              color: !isSpecialCharValid ? "red" : "#2bb4b1",
            }}
          >
            one special character
          </li>
        </ul>

        <br />

        <div style={{ display: "flex", gap: 1.1 + "rem" }}>
          <Button
            variant="outline-primary"
            style={{ borderColor: "#F14810", color: "#F14810" }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
              onClick={() => setActiveStage("Contact Person")}
            >
              Previous
            </span>
          </Button>
          <Button
            variant="primary"
            style={{
              backgroundColor: "#F14810",
              borderColor: "#F14810",
              float: "left",
            }}
            className="button"
            size="md"
            onClick={(e) => trySubmit(e)}
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
              // onClick={trySubmit}
            >
              Finish
            </span>
          </Button>
        </div>

        {/* <br /> */}

        <div>
          <p
            style={{
              fontFamily: "jost, sans-serif",
              fontSize: 0.85 + "rem",
              marginTop: 1 + "rem",
              marginRight: -2 + "rem",
            }}
          >
            Already have an account?
            <a
              href="/login"
              className="btn btn-link"
              role="button"
              data-bs-toggle="button"
              style={{
                fontFamily: "jost, sans-serif",
                fontSize: 0.85 + "rem",
                paddingBottom: 0.7 + "rem",
                paddingLeft: 0.3 + "rem",
                paddingRight: 1.2 + "rem",
              }}
            >
              <span style={{ color: "#3174fd" }}>Login Now</span>
            </a>
          </p>
        </div>
      </Form>
    </>
  );
};

export default SetPasswordPage;
