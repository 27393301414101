import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DashboardTopSection from "../dashboardTopSection";
import DashboardSidebar from "../dashboardSideBar/sidebar";
import { useEffect, useState } from "react";
import dropdownIcon from "../../../../assets/svgs/dropdownIcon.svg";
import {
  useCreateMessageReminderMutation,
  useGetAllInstitutionListQuery,
  useGetInstitutionListQuery,
  useGetOneAdminMessageReminderQuery,
  useUpdateMessageReminderMutation,
  useGetInstitutionSettlementListQuery,
} from "../../../../redux/features/superAdmin/adminApiSlice";
import ModalComponent from "../../../../component/modal/modal";
import successGIF from "../../../../assets/svgs/successGIF.gif";
import { useNavigate, useParams } from "react-router-dom";
import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";
import ToastComponent from "../../../../component/toast/toast";
import MultiSelectDropdown from "../../../../component/select/multiSelectDropdown";
import backArrow from "../../../../assets/svgs/backArrow.svg";

const charactersPerPage = 160;

const SendMessagePage = () => {
  // console.log("MATCH ", match)
  // console.log("HISTORY ", history)

  const { reminderId } = useParams();
  const isEditing = !!reminderId;
  console.log("MATCH ", reminderId);
  console.log("HISTORY ", isEditing);
  const [showSchoolsDropdown, setShowSchoolsDropdown] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsNames, setSelectedItemsNames] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [scheduleMessage, setScheduleMessage] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();
  const [messageObject, setMessageObject] = useState({
    messageTitle: "",
    messageBody: "",
    messageType: "",
    messageScheduledDate: "",
    selectedInstitutionIds: [],
  });
  const [selectedMessageType, setSelectedMessageType] = useState({
    emailSMS: false,
    sms: false,
    email: false,
  });
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const { messageTitle, messageBody, messageType, messageScheduledDate } =
    messageObject;
  const { emailSMS, sms, email } = selectedMessageType;

  const {
    data: institutionsList,
    isLoading: institutionsLoading,
    refetch: refetchInstitutions,
  } = useGetAllInstitutionListQuery();
  console.log(institutionsList);

  const {
    data: oneReminder,
    isLoading: getOneReminderLoading,
    refetch: refetchOneReminder,
  } = useGetOneAdminMessageReminderQuery(reminderId);
  //console.log(institutionsList);

  const [createReminder, { isLoading: createReminderLoading }] =
    useCreateMessageReminderMutation();

  const [updateReminder, { isLoading: updateReminderLoading }] =
    useUpdateMessageReminderMutation();

  useEffect(() => {
    // Load record data when editing
    if (isEditing) {
      refetchOneReminder();
      if (oneReminder) {
        const selectedInstitutions = oneReminder?.selectedInstitutions || [];

        const institutionIds = selectedInstitutions.map(
          (institution) => institution.id
        );
        const institutionNames = selectedInstitutions.map(
          (institution) => institution.institutionName
        );
        console.log("AllInstitutions ", institutionIds);
        setMessageObject({
          messageTitle: oneReminder?.messageTitle,
          messageBody: oneReminder?.messageBody,
          messageType: oneReminder?.messageType,
          messageScheduledDate: oneReminder?.scheduledDate,
          selectedInstitutionIds: institutionIds,
        });
        setSelectedItems(institutionIds);
        setSelectedItemsNames(institutionNames);
        if (oneReminder?.messageType === "EMAIL_AND_SMS") {
          setSelectedMessageType({ emailSMS: true, sms: false, email: false });
          //messageObject.messageType = "EMAIL_AND_SMS";
        } else if (oneReminder?.messageType === "SMS") {
          setSelectedMessageType({ emailSMS: false, sms: true, email: false });
          //messageObject.messageType = "SMS";
        } else {
          setSelectedMessageType({ emailSMS: false, sms: false, email: true });
          //messageObject.messageType = "EMAIL";
        }
        if (oneReminder?.scheduledDate !== null) {
          setScheduleMessage(true);
          //selecte
        }
      }
    }
  }, [isEditing, reminderId, oneReminder]);

  const handleApplyToStudentCheckbox = () => {
    setScheduleMessage(!scheduleMessage);
  };

  const handleScheduleMessageToggle = (e) => {
    const { name, checked } = e.target;
    setScheduleMessage(checked);
  };

  const handleMessageTypeRadioChange = (e) => {
    const { name, checked } = e.target;
    if (name === "emailSMS") {
      setSelectedMessageType({
        emailSMS: checked,
        sms: !checked,
        email: !checked,
      });
      messageObject.messageType = "EMAIL_AND_SMS";
    } else if (name === "sms") {
      setSelectedMessageType({
        emailSMS: !checked,
        sms: checked,
        email: !checked,
      });
      messageObject.messageType = "SMS";
    } else {
      setSelectedMessageType({
        emailSMS: !checked,
        sms: !checked,
        email: checked,
      });
      messageObject.messageType = "EMAIL";
    }
  };
  console.log("setSelectedMessageType", selectedMessageType);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setMessageObject({ ...messageObject, [name]: value });
  };

  const pageCount = Math.ceil(messageBody.length / charactersPerPage);

  console.log("messageObject", messageObject);

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const handleItemClick = (obj) => {
    if (selectedItems.includes(obj.id)) {
      setSelectedItems(selectedItems.filter((i) => i !== obj.id));
      setSelectedItemsNames(
        selectedItemsNames.filter((i) => i !== obj.institutionName)
      );
      messageObject.selectedInstitutionIds =
        messageObject.selectedInstitutionIds.filter((id) => id !== obj.id);
    } else {
      setSelectedItems([...selectedItems, obj.id]);
      setSelectedItemsNames([...selectedItemsNames, obj.institutionName]);
      messageObject.selectedInstitutionIds = [...selectedItems, obj.id];
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
      setSelectedItemsNames([]);
      messageObject.selectedInstitutionIds = [];
    } else {
      const allItemIds = institutionsList?.map((category) => category.id);
      setSelectedItems(allItemIds);
      setSelectedItemsNames(
        institutionsList?.map((category) => category.institutionName)
      );
      messageObject.selectedInstitutionIds = allItemIds;
    }
    setSelectAll(!selectAll);
  };

  const [selectedSchoolOptions, setSelectedSchoolOptions] = useState([]);

  const { data: schoolsList, isLoading: schoolListLoading } =
    useGetInstitutionSettlementListQuery();

  const schoolOptions = schoolsList?.map((item) => {
    return { id: item?.id, name: item?.institutionName };
  });

  const handleSchoolsOptionsChange = (selected) => {
    setSelectedSchoolOptions(selected);
    console.log(selected, 77777);
    const schoolIds = selected?.map((item) => {
      return item;
    });
    // feeData.selectedSchoolId = schoolIds;
    // console.log("SELECTED SCHOOLS", feeData);
  };

  console.log(selectedSchoolOptions);

  const handleCreateReminderMessage = async () => {
    try {
      const { selectedInstitutionIds, ...rest } = messageObject;

      if (isEditing) {
        const params = {
          body: {
            ...rest,
            selectedInstitutionIds: selectedSchoolOptions,
            id: reminderId,
          },
        };
        const res = await updateReminder(params).unwrap();
        console.log(res);
      } else {
        const params = {
          body: { ...rest, selectedInstitutionIds: selectedSchoolOptions },
        };
        const res = await createReminder(params).unwrap();
        console.log(res);
      }

      setShowToast(true);
      setToastMessage("Message Sent successfully!");
      setMessageObject({
        messageTitle: "",
        messageBody: "",
        selectedInstitutionIds: [],
        messageScheduledDate: "",
        messageType: "",
      });
      setSelectedSchoolOptions([]);
      setScheduleMessage(false);
      setSelectedMessageType({ email: false, emailSMS: false, sms: false });
      navigate("/principal/messaging");
    } catch (error) {
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleViewMessage = () => {
    //alert("It's working");
    setMessageObject({
      messageTitle: "",
      messageBody: "",
      selectedInstitutionIds: [],
      messageScheduledDate: "",
      messageType: "",
    });
    navigate("/principal/messaging/viewall");
  };

  if (
    institutionsLoading ||
    createReminderLoading ||
    getOneReminderLoading ||
    updateReminderLoading ||
    schoolListLoading

  ) {
    return <OnboardingLoader />;
  }
  return (
    <div>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <Container
        fluid
        style={{ display: "flex" }}
        className="dashboardContainer"
      >
        <DashboardSidebar />
        <Container fluid className="bodycontainer">
          <DashboardTopSection />

          <section>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer",
                marginBottom: "10px",
              }}
              onClick={() => navigate("/principal/notifications")}
            >
              <img src={backArrow} alt="Go back" />
              {/* <h6 style={{ marginBottom: 0 }}>Send Notifications</h6> */}
              {isEditing && <h6>Edit Notifications</h6>}
              {/* <h6>Send Messages</h6> */}
              {!isEditing && <h6>Send Notifications</h6>}
            </div>

            <section
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                height: "100%",
                display: "flex",
                position: "relative",
              }}
            >
              <div
                style={{
                  borderRight: "1px solid #F5F5F5",
                  flexBasis: "40%",
                  padding: "30px 15px",
                  minWidth: "150px",
                  overflowY: "auto",
                }}
              >
                <h6 style={{ color: "#F14810" }}>Send Notifications to Schools</h6>
                <p>
                  This is where the Adept admin can structure message templates based on how they should be sent. The messages can be sent to individual schools or an entire list by either Email & SMS, or Email Only, or SMS Only.
                </p>
                <span
                  style={{
                    color: "#F14810",
                    fontSize: "15px",
                    fontWeight: "800px",
                    cursor: "pointer",
                  }}
                  onClick={handleViewMessage}
                >
                  View Notifications History
                </span>
              </div>
              <div
                style={{
                  flexBasis: "60%",
                  padding: "30px 15px",
                  minWidth: "150px",
                  overflowY: "auto",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ position: "relative", marginTop: "20px" }}>
                    <p style={{ fontSize: 0.8 + "rem", fontWeight: "500" }}>
                      School List
                    </p>
                    {/* <label
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(221, 221, 221)",
                        borderRadius: "5px",
                        padding: "10px",
                        cursor: "pointer",
                        position: "relative",
                        fontSize: 0.8 + "rem",
                        fontFamily: "jost, sans-serif",
                      }}
                      onClick={() =>
                        setShowSchoolsDropdown(!showSchoolsDropdown)
                      }
                    >
                      {selectedItems?.length > 0 ? (
                        <>
                          {selectedItemsNames?.slice(0, 3).join(", ")}
                          {selectedItemsNames?.length > 3 && " ..."}
                        </>
                      ) : (
                        "Select Schools"
                      )}

                      <img
                        src={dropdownIcon}
                        alt="drop down icon"
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "40%",
                        }}
                      />
                    </label>
                    {showSchoolsDropdown && (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          zIndex: "10",
                          transition: "all",
                          transitionTimingFunction: "ease-in-out",
                          transitionDuration: "0.3s",
                          backgroundColor: "white",
                          width: "100%",
                          border: "1px solid rgb(221, 221, 221)",
                          borderRadius: "5px",
                          padding: "10px",
                          fontSize: 0.8 + "rem",
                          fontFamily: "jost, sans-serif",
                        }}
                      >
                        <label
                          style={{ display: "flex" }}
                          // className="checkbox-container"
                        >
                          <input
                            type="checkbox"
                            // className="custom-checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                            style={{
                              marginRight: "5px",
                              width: "auto",
                              appearance: "auto",
                              fontSize: 0.8 + "rem",
                              fontFamily: "jost, sans-serif",
                            }}
                          />
                          Select All
                        </label>
                        {institutionsList &&
                          institutionsList?.map(({ institutionName, id }) => (
                            <label
                              style={{ display: "flex" }}
                              key={id}
                              // className="checkbox-container"
                            >
                              <input
                                type="checkbox"
                                // className="custom-checkbox"
                                checked={selectedItems?.includes(id)}
                                onChange={(e) =>
                                  handleItemClick({ institutionName, id })
                                }
                                style={{
                                  marginRight: "5px",
                                  width: "auto",
                                  appearance: "auto",
                                  fontSize: 0.8 + "rem",
                                  fontFamily: "jost, sans-serif",
                                }}
                              />
                              {institutionName}
                            </label>
                          ))}
                      </div>
                    )} */}

                    <MultiSelectDropdown
                      selectedItems={selectedSchoolOptions}
                      options={schoolOptions}
                      onSelect={handleSchoolsOptionsChange}
                    />
                  </div>

                  <p
                    style={{
                      marginBottom: "5px",
                      fontWeight: "500",
                      marginTop: "20px",
                    }}
                  >
                    Message Title{" "}
                    <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <input
                    type="text"
                    placeholder="Enter Message Title"
                    name="messageTitle"
                    value={messageTitle}
                    //value={editingElement ? editingValue : name}
                    onChange={(e) => handleOnChange(e)}
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#ededed",
                      width: "100%",
                      marginBottom: "15px",
                      padding: "8px",
                      outline: "none",
                      border: "none",
                      fontFamily: "jost, sans-serif",
                      fontSize: 0.8 + "rem",
                    }}
                  />

                  <p style={{ marginBottom: "5px", fontWeight: "500" }}>
                    Message Body{" "}
                    <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <textarea
                    //type="textarea"
                    rows={5}
                    placeholder="Enter Message Body"
                    name="messageBody"
                    value={messageBody}
                    // value={editingElement ? editingValue : name}
                    onChange={(e) => handleOnChange(e)}
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#ededed",
                      width: "100%",
                      marginBottom: "15px",
                      padding: "8px",
                      outline: "none",
                      border: "none",
                      fontFamily: "jost, sans-serif",
                      fontSize: 0.8 + "rem",
                    }}
                  />
                  <div style={{ fontFamily: "jost, sans-serif", fontSize: 0.8 + "rem", marginTop: 0, display: "flex", justifyContent: "flex-end" }}>
                    Characters: {messageBody?.length} | Pages: {pageCount}
                  </div>

                  <p style={{ marginBottom: "5px", fontWeight: "500" }}>
                    Message Type{" "}
                    <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "15px",
                      }}
                    >
                      <Form.Check
                        inline
                        name="emailSMS"
                        //value="emailSMS"
                        onChange={(e) => handleMessageTypeRadioChange(e)}
                        checked={emailSMS}
                        type="radio"
                        id={`inline-radio-2`}
                        size={25}
                        style={{ marginRight: "10px" }}
                      />
                      <p style={{ marginBottom: "0" }}>Email & SMS</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "15px",
                      }}
                    >
                      <Form.Check
                        inline
                        name="sms"
                        //value="sms"
                        onChange={(e) => handleMessageTypeRadioChange(e)}
                        checked={sms}
                        type="radio"
                        id={`inline-radio-2`}
                        size={25}
                        style={{ marginRight: "10px" }}
                      />
                      <p style={{ marginBottom: "0" }}>SMS Only</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Form.Check
                        inline
                        name="email"
                        //value="email"
                        onChange={(e) => handleMessageTypeRadioChange(e)}
                        checked={email}
                        type="radio"
                        id={`inline-radio-2`}
                        size={25}
                        style={{ marginRight: "10px" }}
                      />
                      <p style={{ marginBottom: "0" }}>Email Only</p>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "15px",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <Form.Check
                      inline
                      name="messageSchedule"
                      checked={scheduleMessage}
                      onClick={(e) => handleScheduleMessageToggle(e)}
                      type="switch"
                      id={`inline-radio-2`}
                      size={25}
                      style={{ marginRight: "10px" }}
                    />
                    <p style={{ marginBottom: "0" }}>Schedule Reminder</p>
                  </div>

                  {scheduleMessage && (
                    <input
                      type="datetime-local"
                      placeholder="Enter Date and Time"
                      name="messageScheduledDate"
                      value={messageScheduledDate}
                      //value={editingElement ? editingValue : name}
                      onChange={(e) => handleOnChange(e)}
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#ededed",
                        width: "100%",
                        marginBottom: "15px",
                        padding: "8px",
                        outline: "none",
                        border: "none",
                        fontFamily: "jost, sans-serif",
                        fontSize: 0.8 + "rem",
                      }}
                    />
                  )}

                  <div style={{ float: "left" }}>
                    <Button
                      className="button"
                      size="md"
                      style={{
                        backgroundColor: "#F14810",
                        color: "#ffffff",
                        border: "none",
                        outline: "none",
                        fontSize: "14px",
                        padding: "8px 20px",
                        marginTop: "20px",
                        marginLeft: "auto",
                      }}
                      onClick={() => handleCreateReminderMessage()}
                    >
                      {isEditing ? "Send Edited Message" : "Send Message"}
                    </Button>
                  </div>
                </div>
              </div>
            </section>
          </section>

          <br />
          <br />
        </Container>

        <ModalComponent
          showModal={showSuccessModal}
          closeModal={handleCloseSuccessModal}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={successGIF}
              alt="success Gif"
              width="150px"
              style={{ margin: "0 auto" }}
            />
            <h3 style={{ color: "#F14810", fontWeight: "600" }}>
              Successful!!!
            </h3>
            <p style={{ fontSize: "18px" }}>Message Sent successfully</p>
          </div>
        </ModalComponent>
      </Container>
    </div>
  );
};

export default SendMessagePage;
