import React from 'react'

function FeeIcon() {
    return (
        <div>
            <svg width="20" height="21" viewBox="0 0 27 30"  fill='currentColor' xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.80635 2.95127C6.72562 1.99045 9.96907 0.814453 13.5294 0.814453H13.5302C17.0227 0.814453 20.1889 1.9471 22.0987 2.89767C22.5578 3.12107 23.0024 3.373 23.43 3.65198L21.3657 6.67001L20.1991 7.27535C16.1817 9.30181 10.7951 9.30181 6.77843 7.27535L5.75061 6.86627L3.56729 3.65198C3.76907 3.51877 4.02287 3.36744 4.32081 3.20507C4.47136 3.1231 4.63294 3.03797 4.80635 2.95127ZM6.16521 3.87898C6.91504 4.1165 7.67865 4.30813 8.45178 4.45279C10.1527 4.76729 11.8663 4.813 13.3323 4.38895C15.1956 3.85061 17.1512 3.41316 18.9837 3.15541C17.4199 2.63993 15.529 2.23322 13.5294 2.23322C10.6422 2.23322 7.9631 3.08053 6.16521 3.87898ZM20.8376 8.54199L21.0315 8.44425C27.6177 15.4813 32.8096 29.1874 13.5294 29.1874C-5.75083 29.1874 -0.682693 15.7328 5.95555 8.44898L6.1392 8.54199C10.5579 10.771 16.4189 10.771 20.8376 8.54199ZM10.6532 14.2459C10.9619 14.1522 11.2958 14.2734 11.4742 14.5409L14.6734 19.3364H15.9883V14.9457C15.9883 14.5409 16.3153 14.2139 16.7201 14.2139C17.1249 14.2139 17.4519 14.5409 17.4519 14.9457V19.3364H18.1837C18.5884 19.3364 18.9155 19.6634 18.9155 20.0682C18.9155 20.4729 18.5884 20.7999 18.1837 20.7999H17.4519V23.7293C17.4519 24.0518 17.2415 24.3354 16.9328 24.4291C16.6241 24.5229 16.2902 24.4017 16.1118 24.1341L13.889 20.7999H11.5976V23.7271C11.5976 24.1318 11.2706 24.4588 10.8659 24.4588C10.4611 24.4588 10.1341 24.1318 10.1341 23.7271V20.7999H9.4023C8.99753 20.7999 8.67052 20.4729 8.67052 20.0682C8.67052 19.6634 8.99753 19.3364 9.4023 19.3364H10.1341V14.9457C10.1341 14.6232 10.3445 14.3397 10.6532 14.2459ZM15.9883 20.7999H15.6476L15.9883 21.3099V20.7999ZM11.5976 19.3364H12.9126L11.5976 17.3629V19.3364Z" />
            </svg>
        </div>
    )
}

export default FeeIcon