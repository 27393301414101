import React from "react";

function BranchIcon({active}) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.84483 1H3C1.89543 1 1 1.89543 1 3V25C1 26.1046 1.89543 27 3 27H12.5C13.6046 27 14.5 26.1046 14.5 25V17.7719C14.5 17.2752 14.3151 16.7962 13.9814 16.4282L10.3634 12.4395C10.0297 12.0716 9.84483 11.5926 9.84483 11.0958V3C9.84483 1.89543 8.9494 1 7.84483 1Z"
        fill={active ? "#ffffff" : "#464646"}
        stroke={active ? "#ffffff" : "#464646"}
      />
      <path
        d="M20.1552 27H25C26.1046 27 27 26.1046 27 25V3C27 1.89543 26.1046 1 25 1H15.5C14.3954 1 13.5 1.89543 13.5 3V10.2281C13.5 10.7248 13.6849 11.2038 14.0186 11.5718L17.6366 15.5605C17.9703 15.9284 18.1552 16.4074 18.1552 16.9042V25C18.1552 26.1046 19.0506 27 20.1552 27Z"
        fill={active ? "#ffffff" : "#464646"}
        stroke={active ? "#ffffff" : "#464646"}
      />
    </svg>
  );
}

export default BranchIcon;
