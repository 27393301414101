import Button from "../../../../../../component/button/button";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useApplyCommissionMutation } from "../../../../../../redux/features/superAdmin/adminApiSlice";
import ToastComponent from "../../../../../../component/toast/toast";
import SuccessScreen from "../../../../../../component/successScreen/successScreen";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import {
  useGetInstitutionSettlementListQuery,
  useGetGlobalCommissionQuery,
} from "../../../../../../redux/features/superAdmin/adminApiSlice";
import { addTrailingCommas } from "../../../../../../utils/utils";
import { useEffect } from "react";
import dropdownIcon from "../../../../../../assets/svgs/dropdownIcon.svg";

const CreateCommission = ({ Id }) => {
  const [percentageSelected, setPercentageSelected] = useState(false);
  const [amountSelected, setAmountSelected] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsNames, setSelectedItemsNames] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showSchoolsDropdown, setShowSchoolsDropdown] = useState(false);

  const [rateToShow, setRateToShow] = useState("global");
  const [rateValue, setRateValue] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const {
    data: globalCommission,
    refetch: refetchGlobalCommission,
    isLoading: globalLoading,
  } = useGetGlobalCommissionQuery();
  console.log(globalCommission);

  const [
    applyCommission,
    { isLoading: applyCommissionLoading, isSuccess: applyCommissionSuccess },
  ] = useApplyCommissionMutation();

  const handleAmountClick = () => {
    setPercentageSelected(false);
    setAmountSelected(true);
  };

  const handlePercentClick = () => {
    setAmountSelected(false);
    setPercentageSelected(true);
  };

  const handleOnChange = (e) => {
    setRateValue(e.target.value);
  };

  const handleApplySpecificRate = async () => {
    let keys = {};
    // check if the created rate was fixed or variable as that would determine the keys to be sent to the api
    if (percentageSelected) {
      keys = {
        rate: rateValue,
        type: "RATE",
      };
    } else {
      keys = {
        fixedAmount: rateValue,
        type: "FIXED",
      };
    }

    const body = [
      {
        ...keys,
        institutionId: Id,
      },
    ];

    try {
      const res = await applyCommission(body).unwrap();
      console.log(res);
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleApplyGlobalRate = async () => {
    const body = [{
      name: "GLOBAL",
      institutionId: Id,

    }]

    console.log(body);

    try {
      const res = await applyCommission(body).unwrap();
      console.log(res);
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        setToastMessage(error.error);
      }
    }
  };

  useEffect(() => {
    if (globalCommission) {
      refetchGlobalCommission();
    }
  }, []);

  if (
    applyCommissionLoading ||
    globalLoading
    // ||
    // schoolListLoading
  ) {
    return <OnboardingLoader />;
  }

  if (applyCommissionSuccess) {
    return (
      <SuccessScreen successMessage="Processing fee has been set successfully" />
    );
  }

  return (
    <form onSubmit={(e) => e.preventDefault()} className="commission-canvas">
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <p>
        Our commission system simplifies the process, enabling you to set default or specific rates for transactions. Choose between a global rate applicable to all institution transactions or set specific rates for individual institution transactions.
      </p>

      <div style={{ marginTop: "40px", display: "flex", gap: "10px" }}>
        <Button
          borderRadius="20px"
          backgroundColor={`${rateToShow === "global" ? "#ffede8" : "#f4f4f4"}`}
          border={`${rateToShow === "global" ? "#f47146" : "#dbdbdb"}`}
          color="#000000"
          onClick={() => setRateToShow("global")}
        >
          Default Rate
        </Button>
        <Button
          borderRadius="20px"
          backgroundColor={`${rateToShow === "global" ? "#f4f4f4" : "#ffede8"}`}
          border={`${rateToShow === "global" ? "#dbdbdb" : "#f47146"}`}
          color="#000000"
          onClick={() => setRateToShow("specific")}
        >
          Specific Rate
        </Button>
      </div>

      {rateToShow === "global" ? (
        <div style={{ marginTop: "20px" }}>
          <p>
            Set a default commission rate applicable to all institution transactions. Once saved, this rate will be automatically applied to all transactions unless specified otherwise.
          </p>

          <h6 style={{ marginBottom: "20px" }}>
            Global Rate:{" "}
            <span style={{ fontWeight: "400" }}>
              {globalCommission?.type === "FIXED"
                ? `₦ ${addTrailingCommas(globalCommission?.fixedAmount)}`
                : ` ${globalCommission?.rate} %`}
            </span>
          </h6>

          <Button
            onClick={() => handleApplyGlobalRate()}
            backgroundColor="rgb(241, 72, 16)"
            cursor="pointer"
          >
            Save Default Rate
          </Button>
        </div>
      ) : (
        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Form.Check
              inline
              name="rate-type"
              onClick={handlePercentClick}
              type="radio"
              id={`inline-radio-1`}
              size={25}
              style={{
                marginRight: "10px",
                borderColor: "#ff0000",
              }}
            />
            <h6 style={{ marginBottom: "0" }}>Percent Rate ( % )</h6>
          </div>

          <p style={{ marginBottom: "15px" }}>
            Specify the commission rate as a percentage of the transaction amount.
          </p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Form.Check
              inline
              name="rate-type"
              onClick={handleAmountClick}
              type="radio"
              id={`inline-radio-2`}
              size={25}
              style={{ marginRight: "10px" }}
            />
            <h6 style={{ marginBottom: "0" }}>Amount Rate ( ₦ )</h6>
          </div>

          <p style={{ marginBottom: "25px" }}>
            Specify the commission rate as a fixed amount in ₦.
          </p>

          {percentageSelected ? (
            <>
              <div
                style={{ display: "flex", gap: "15px", marginBottom: "30px" }}
              >
                <div
                  style={{
                    backgroundColor: "#ededed",
                    width: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#484d45",
                    padding: "5px 10px",
                  }}
                >
                  %
                </div>
                <input
                  type="number"
                  onChange={(e) => handleOnChange(e)}
                  placeholder="Enter rate in percentage e.g 5"
                  style={{ backgroundColor: "#ededed" }}
                />
              </div>
            </>
          ) : null}

          {amountSelected ? (
            <>
              <div
                style={{ display: "flex", gap: "15px", marginBottom: "30px" }}
              >
                <div
                  style={{
                    backgroundColor: "#ededed",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#484d45",
                    padding: "5px 10px",
                  }}
                >
                  NGN
                </div>
                <input
                  type="number"
                  onChange={(e) => handleOnChange(e)}
                  placeholder="Enter rate in amount e.g 5000"
                  style={{ backgroundColor: "#ededed" }}
                />
              </div>
            </>
          ) : null}

          <Button
            onClick={!rateValue ? null : () => handleApplySpecificRate()}
            backgroundColor={`${!rateValue ? "#FFCAB8" : "rgb(241, 72, 16)"}`}
            cursor={`${!rateValue ? "not-allowed" : "pointer"}`}
          >
            Save Specific Rate
          </Button>
        </div>
      )}
    </form>
  );
};

export default CreateCommission;
