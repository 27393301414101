import React, { useEffect, useState } from "react";
import "./institutionDashboardPage.css";

import { Container } from 'react-bootstrap';
// import Students from "./components/students/students"; \
import Branches from "./components/branches/branches";
import {
    useGetInstitutionQuery,
    useGetInstitutionLogoQuery,
} from "../../../redux/features/institution/institutionApiSlice";

import { useSelector, useDispatch } from "react-redux";
import DashboardTopSection from "./components/dashboardTopSection";
import DashboardSidebar from "./components/dashboardSidebar/sidebar";



const InstitutionBranchPage = () => {

    const [details, setDetails] = useState({});


    const id = useSelector((state) => state?.auth?.userInfo?.institutionId);

    const { data: institutionDetails } = useGetInstitutionQuery(id);
    const { data: institutionLogo } = useGetInstitutionLogoQuery(id);
    // console.log(institutionLogo?.logo);

    useEffect(() => {
        setDetails(institutionDetails);
    }, [institutionDetails, details]);

    return (
        <Container fluid style={{ display: "flex" }} className="dashboardContainer">
            <DashboardSidebar institutionDetails={institutionDetails} />

            <Container fluid className="bodycontainer">
                <DashboardTopSection />
                <Branches institutionDetails={institutionDetails} institutionLogo={institutionLogo}/>
            </Container>
        </Container>
    );
};

export default InstitutionBranchPage;
