import { Sidebar, MenuItem, Menu } from "react-pro-sidebar";
import { Navbar, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import logo from "../../../../assets/svgs/logo.svg";
import { useLocation } from "react-router-dom";
import DashboardIcon from "../../../../assets/svgs/dashboardIcon";
import AdminIcon from "../../../../assets/svgs/adminIcon";
import ApplicationIcon from "../../../../assets/svgs/applicationIcon";
import SchoolsIcon from "../../../../assets/svgs/schoolsIcon";
import SettingsIcon from "../../../../assets/svgs/settingsIcon";
import SettlementIcon from "../../../../assets/svgs/settlementIcon";
import ReportingIcon from "../../../../assets/svgs/reportingIcon";
import { Margin } from "@mui/icons-material";

const DashboardSidebar = () => {


  const menuItem = {
    height: 2.7 + "rem",
    fontSize: 0.87 + "rem",
  };

  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(
    window.matchMedia("(max-width: 950px)").matches
  );

  const location = useLocation();

  return (
    <Sidebar
      backgroundColor="#FFFDFC"
      className="sidebar"
      onBackdropClick={() => setToggled(false)}
      breakPoint="always"
      toggled={toggled}
      customBreakPoint="1000px"
      onBreakPoint={setBroken}
    >
      <Navbar.Brand className="logo">
        {/* <div className="navbar-brand-space">
          <br />
        </div> */}
        <Image src={logo} fluid style={{ margin: "10px" }} />
      </Navbar.Brand>

      <Menu
        className="menu"
        menuItemStyles={{
          button: ({ active }) => ({
            color: active ? "white" : "#464646",
            backgroundColor: active ? "#FD5821" : "transparent",
            "&:hover": {
              backgroundColor: active ? "#FD5821" : "#F5F5F5",
              color: active ? "white" : "#FFFFFF",
            },
          }),
        }}
      >
        <Link to="/principal">
          <MenuItem
            style={menuItem}
            active={location.pathname === "/principal" ? "active" : null}
            icon={
              <DashboardIcon
                active={location.pathname === "/principal" ? "active" : null}
                size={18}
              />
            }
          >
            {" "}
            Dashboard
          </MenuItem>
        </Link>

        <Link to="/principal/user-management">
          <MenuItem
            style={menuItem}
            active={
              location.pathname.startsWith("/principal/user-management")
                ? "active"
                : null
            }
            icon={
              <AdminIcon
                active={
                  location.pathname.startsWith("/principal/user-management")
                    ? "active"
                    : null
                }
                size={18}
              />
            }
          >
            {" "}
            User Management
          </MenuItem>
        </Link>

        <Link to="/principal/applications">
          <MenuItem
            style={menuItem}
            active={
              location.pathname.startsWith("/principal/applications")
                ? "active"
                : null
            }
            icon={
              <ApplicationIcon
                active={
                  location.pathname.startsWith("/principal/applications")
                    ? "active"
                    : null
                }
                size={18}
              />
            }
          >
            {" "}
            Applications
          </MenuItem>
        </Link>


        <Link to="/principal/schools">
          <MenuItem
            style={menuItem}
            active={
              location.pathname.startsWith("/principal/schools")
                ? "active"
                : null
            }
            icon={
              <SchoolsIcon
                fill={
                  location.pathname.startsWith("/principal/schools")
                    ? "#ffffff"
                    : "#464646"
                }
                size={19}
              />
            }
          >
            {" "}
            Schools
          </MenuItem>
        </Link>

        <Link to="/principal/reporting">
          <MenuItem
            style={menuItem}
            active={
              location.pathname.startsWith("/principal/reporting")
                ? "active"
                : null
            }
            icon={
              <ReportingIcon
                active={
                  location.pathname.startsWith("/principal/reporting")
                    ? "active"
                    : null
                }
                size={19}
              />
            }
          >
            {" "}
            Reporting
          </MenuItem>
        </Link>
        
        {/* //used for sending message page */}
        <Link to="/principal/notifications">
          <MenuItem
            style={menuItem}
            active={
              location.pathname.startsWith("/principal/notifications")
                ? "active"
                : null
            }
            icon={
              <SettlementIcon
                active={
                  location.pathname.startsWith("/principal/notifications")
                    ? "active"
                    : null
                }
                size={19}
              />
            }
          >
            {" "}
            Reminders & Notifications
          </MenuItem>
        </Link>

        <Link to="/principal/settings">
          <MenuItem
            style={menuItem}
            active={
              location.pathname.startsWith("/principal/settings")
                ? "active"
                : null
            }
            icon={
              <SettingsIcon
                active={
                  location.pathname.startsWith("/principal/settings")
                    ? "active"
                    : null
                }
                size={19}
              />
            }
          >
            {" "}
            Settings
          </MenuItem>
        </Link>
      </Menu>
    </Sidebar>
  );
};

export default DashboardSidebar;
