// import Button from "../../../../../../component/button/button";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { Container, Form, InputGroup } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ModalComponent from "../../../../../../component/modal/modal";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import successGIF from "../../../../../../assets/svgs/successGIF.gif";
import moneyVector from "../../../../../../assets/images/moneySettlementVector.png";
import dropdownIcon from "../../../../../../assets/svgs/dropdownIcon.svg";
import InstitutionAccountHistory from "./institutionAccountHistory";
import { Link } from "react-router-dom";
import {
  useGetBalanceEnquiryQuery,
  //useAddSettlementAccountMutation,
  useGetInstitutionQuery,
  useGetBanksQuery,
  useAccountNameEnquiryMutation,
  useVerifySettlementAcctChangeMutation,
  useChangeSettlementAccountMutation,
  useGetSettlementAcctDetailsQuery,
  useGetSettlementIntervalQuery,
  useSetSettlementIntervalMutation,
  useSettleInstitutionMutation,
} from "../../../../../../redux/features/institution/institutionApiSlice";
import ButtonX from "../../../../../../component/button/button";
import EditIcon from "../../../../../../assets/svgs/editIcon";
import ToastComponent from "../../../../../../component/toast/toast";
import { addTrailingCommas } from "../../../../../../utils/utils";
import VideoModal from "../../../../../../component/modal/videomodal";
import helpVideos from "../../../../../../constants/helpVideos.json";

const numberOfDigits = 6;
const InstitutionAccountPage = () => {
  const [isHistoryViewed, setIsHistoryViewedLocal] = useState(false);
  const [viewedHistoryDetails, setViewedHistoryDetails] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSuccessSettlementModal, setShowSuccessSettlementModal] =
    useState(false);

  const [showSuccessIntervalModal, setShowSuccessIntervalModal] =
    useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const [showBanks, setShowBanks] = useState(false);
  const [selectedBankName, setSelectedBankName] = useState(null);
  const [selectedBankCode, setSelectedBankCode] = useState(null);
  const [accountObject, setAccountObject] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [newAllBanks, setNewAllBanks] = useState([]);
  const [filteredBanks, setFilteredBanks] = useState([]);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const otpBoxReference = useRef([]);
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const [isOtpValid, setIsOtpValid] = useState(false); //showConfirmSettlementModal
  const [showConfirmSettlementModal, setShowConfirmSettlementModalModal] =
    useState(false);
  const [showEditIntervalModal, setShowEditIntervalModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [otpError, setOtpError] = useState(null);

  const [activePage, setActivePage] = useState("Edit Account");

  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleShowVideo = () => setShowVideoModal(true);
  const handleCloseVideo = () => setShowVideoModal(false);

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const handleShowOTPModal = () => {
    setShowOTPModal(true);
  };

  const handleCloseConfirmSettlementModal = () => {
    setShowConfirmSettlementModalModal(false);
  };

  const handleCloseOTPModal = () => {
    setShowOTPModal(false);
  };

  const handleShowEditIntervalModal = () => {
    setShowEditIntervalModal(true);
  };
  const handleCloseEditIntervalModal = () => {
    setShowEditIntervalModal(false);
  };

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const { data: balanceEnquiry, isLoading: balanceEnquiryLoading } =
    useGetBalanceEnquiryQuery(instId, { skip: !instId });

  console.log(balanceEnquiry);

  const { data: allBanks, isLoading: banksLoading } = useGetBanksQuery();

  const {
    data: institutionDetails,
    isLoading: detailsLoading,
    refetch: refetchDetails,
  } = useGetInstitutionQuery(instId);

  const {
    data: settlementAcctDetails,
    isLoading: settlementAcctDetailsLoading,
    refetch: refetchSettlementAcctDetails,
  } = useGetSettlementAcctDetailsQuery(instId);

  const {
    data: settlementIntervalDetails,
    isLoading: settlementIntervalLoading,
    refetch: refetchSettlementInterval,
  } = useGetSettlementIntervalQuery(instId);

  console.log(settlementAcctDetails);
  // console.log(settlementAcctDetails);

  const [changeAccount, { isLoading: changeAccountLoading }] =
    useChangeSettlementAccountMutation();

  const [verifyAccountChange, { isLoading: verifyAccountChangeLoading }] =
    useVerifySettlementAcctChangeMutation();

  const [setSettlementInterval, { isLoading: setSettlementIntervalLoading }] =
    useSetSettlementIntervalMutation();

  const [settleInstitution, { isLoading: settleInstitutionLoading }] =
    useSettleInstitutionMutation();

  const [
    accountNameEnquiry,
    { isLoading: accountNameLoading, isSuccess: accountNameSuccess },
  ] = useAccountNameEnquiryMutation();

  const [settlementDetails, setSettlementDetails] = useState({
    bankCode: "",
    accountName: "",
    accountNumber: "",
  });

  const { accountName, accountNumber, bankCode } = settlementDetails;

  const [settlementIntervalRec, setSettlementIntervalRec] = useState({
    intervalValue: "DAILY",
    startDate: "",
  });

  const { intervalValue, startDate } = settlementIntervalRec;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setSettlementDetails({ ...settlementDetails, [name]: value });

    if (name === "searchText") {
      setSearchText(value);
    }
  };

  useEffect(() => {
    if (instId) {
      refetchSettlementAcctDetails();
      setAccountObject(settlementAcctDetails);
      // setSelectedBankCode(res?.bankCode);
      // setSelectedBankName(res?.bankName);
    }
    console.log("refetchSettlementAcctDetails", settlementAcctDetails);
  }, [settlementAcctDetails, instId]);

  useEffect(() => {
    setAccountObject(null);

    if (accountNumber.length === 10) {
      let params = {
        institutionId: instId,
        body: {
          bankCode: selectedBankCode,
          accountNumber: accountNumber,
        },
      };
      console.log("bank.code Params", params);
      const fetchName = async () => {
        try {
          const res = await accountNameEnquiry(params).unwrap();
          setAccountObject(res);
        } catch (error) {
          console.log(error);
        }
      };
      fetchName();
    }
  }, [accountNumber, bankCode, selectedBankCode]);

  useEffect(() => {
    if (institutionDetails) {
      refetchDetails();
    }
  }, [showSuccessModal]);

  useEffect(() => {
    setNewAllBanks(allBanks || []);
  }, [allBanks]);

  useEffect(() => {
    const filteredBanks = allBanks?.filter((bank) =>
      bank.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredBanks(filteredBanks);
  }, [searchText]);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowBanks(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOnChangeStartDate = (e) => {
    const { name, value } = e.target;
    //setSettlementDetails({ ...settlementDetails, [name]: value });
    setSettlementIntervalRec({ ...settlementIntervalRec, [name]: value });
  };

  const handleSaveSettlementInterval = async () => {
    try {
      if (startDate) {
        const params = {
          institutionId: institutionDetails.id,
          body: { ...settlementIntervalRec, intervalValue: "DAILY" },
        };
        console.log("settlementIntervalRec", params);
        const result = await setSettlementInterval(params).unwrap();
        if (result) {
          setShowEditIntervalModal(false);
          setShowSuccessIntervalModal(true);
          setSettlementIntervalRec({});
          console.log("setSettlementInterval ", result);
          refetchSettlementInterval();
        }
      }
    } catch (error) {
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleChangeAccount = async () => {
    try {
      setOtp(new Array(numberOfDigits).fill(""));
      setShowOTPModal(true);
      const params = {
        institutionId: instId,
        body: accountObject,
      };
      const res = await changeAccount(params).unwrap();
      console.log(res);
      if (settlementAcctDetails) refetchSettlementAcctDetails();
    } catch (error) {
      alert(error.data.detail);
    }
  };

  useEffect(() => {
    handleValidate();
    // if (otp.join("") !== "" && isOtpValid !== true) {
    //   // setOtpError("❌ Incomplete OTP");
    // } else {
    //   setOtpError(null);
    // }

    if (otp.join("").length < 6) {
      setOtpError(null);
    }
  }, [otp]);

  const handleResendCode = async (e) => {
    e.preventDefault();

    try {
      const params = {
        institutionId: instId,
        body: accountObject,
      };
      const res = await changeAccount(params).unwrap();

      if (res) {
        setShowToast(true);
        setToastMessage("Sending code again...");
        setOtp(new Array(numberOfDigits).fill(""));
      }
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  //to be used for validation of OTP
  const handleValidate = async () => {
    //e.preventDefault();

    try {
      // Join the codes array into a string
      const codeString = otp.join("");
      console.log(codeString);
      if (codeString.length === numberOfDigits) {
        const params = {
          institutionId: institutionDetails.id,
          body: { validation_code: codeString },
        };
        const result = await verifyAccountChange(params).unwrap();

        if (result) {
          //dispatch(userInfo(result));
          //navigate("/landing");
          console.log("ACC OBJ", accountObject);
          const changeparams = {
            institutionId: instId,
            body: accountObject,
          };
          const res = await changeAccount(changeparams).unwrap();
          console.log(res);
          setShowSuccessModal(true);
          //setAccountObject(null);
          refetchSettlementAcctDetails();
          setShowOTPModal(false);
          setShowSuccessModal(true);
        }
      }
    } catch (error) {
      setIsOtpValid(false);
      // setOtpError(error.data.detail);
      console.log(error);
      // setShowToast(true);
      if (error?.data?.detail) {
        // setToastMessage(error.data.detail);
        setOtpError(error.data.detail);
      } else {
        // other errors
        setOtpError(error.error);
        // setToastMessage(error.error);
      }
    }
  };

  const handleOTPChange = async (value, index) => {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }

    if (index === numberOfDigits - 1) {
      if (isOtpValid) {
        //navigate("/landing");
        setIsOtpValid(true);
        //console.log("success ", result);
        setShowOTPModal(false);
        //setToastMessage(result.message);
      }
    }
  };

  const handleOTPBackspaceAndEnter = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  };

  // console.log(accountObject);

  useEffect(() => {
    setAccountObject({
      accountName: settlementAcctDetails?.accountName,
      accountNumber: settlementAcctDetails?.accountNumber,
      bankName: settlementAcctDetails?.bankName,
    });
  }, [settlementAcctDetails]);

  const handleHistoryView = (obj) => {
    setIsHistoryViewedLocal(!isHistoryViewed);
    setViewedHistoryDetails(obj);
    setActivePage("Settlement");
  };

  const handleSettleNow = () => {
    setShowConfirmSettlementModalModal(true);
  };

  const handleConfirmSettlement = async (data) => {
    try {
      if (data) {
        const params = {
          body: { institutionId: instId },
        };
        console.log("settlementIntervalRec", params);
        const result = await settleInstitution(params).unwrap();
        if (result) {
          setShowConfirmSettlementModalModal(false);
          setShowSuccessSettlementModal(true);
          setSettlementIntervalRec({});
          console.log("setSettlementInterval ", result);
        }
      } else {
        setShowConfirmSettlementModalModal(false);
      }
    } catch (error) {
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  if (
    balanceEnquiryLoading ||
    banksLoading ||
    settlementAcctDetailsLoading ||
    settlementIntervalLoading ||
    settleInstitutionLoading ||
    verifyAccountChangeLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <h6 style={{ fontSize: "14px", fontWeight: 600 }}>Collection Account</h6>
      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "10px",
          padding: "20px",
          minHeight: "95%",
        }}
      >
        {activePage !== "View History" && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#ffebe4",
                borderRadius: "5px",
                marginBottom: "20px",
                padding: "10px 20px",
                border: "1px solid #C8C7C7",
              }}
            >
              <div>
                <h3 style={{ marginBottom: "0px", fontWeight: "600" }}>
                  {/* ₦ 357, 000 */}₦{" "}
                  {addTrailingCommas(settlementAcctDetails?.balance)}
                </h3>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#5F5F5F",
                    fontWeight: "500",
                  }}
                >
                  Account Balance
                </p>
              </div>
              <img
                src={moneyVector}
                alt="money vector"
                style={{ maxWidth: "120px", maxHeight: "100px" }}
              />
            </div>

            <div style={{ marginBottom: "20px" }}>
              <span
                onClick={() => setActivePage("Edit Account")}
                style={{
                  backgroundColor: `${
                    activePage === "Edit Account" ? "#ffede8" : "#f4f4f4"
                  }`,
                  border: `1px solid ${
                    activePage === "Edit Account" ? "#f89a7c" : "#dbdbdb"
                  }`,
                  marginRight: "10px",
                  padding: "8px 18px",
                  borderRadius: "20px",
                  cursor: "pointer",
                  fontWeight: "500",
                }}
              >
                Edit Account
              </span>

              {institutionDetails?.status == "APPROVED" ||
              institutionDetails?.status == "INSTITUTION_UPDATE" ? (
                <span
                  onClick={() => setActivePage("Settlement")}
                  style={{
                    backgroundColor: `${
                      activePage === "Settlement" ? "#ffede8" : "#f4f4f4"
                    }`,
                    border: `1px solid ${
                      activePage === "Settlement" ? "#f89a7c" : "#dbdbdb"
                    }`,
                    marginRight: "10px",
                    padding: "8px 18px",
                    borderRadius: "20px",
                    cursor: "pointer",
                    fontWeight: "500",
                  }}
                >
                  Settlement
                </span>
              ) : null}

              <span
                onClick={() => {
                  setActivePage("View History");
                }}
                style={{
                  backgroundColor: `${
                    activePage === "View History" ? "#ffede8" : "#f4f4f4"
                  }`,
                  border: `1px solid ${
                    activePage === "View History" ? "#f89a7c" : "#dbdbdb"
                  }`,
                  marginRight: "10px",
                  padding: "8px 18px",
                  borderRadius: "20px",
                  cursor: "pointer",
                  fontWeight: "500",
                }}
              >
                View History
              </span>
            </div>
          </div>
        )}

        {activePage === "Edit Account" && (
          <div
            style={{ display: "flex", gap: "30px" }}
            className="account-in-settings"
          >
            <div style={{ flexBasis: "40%" }}>
              <h6>Set your Settlement Account</h6>
              <p style={{ marginBottom: "5px", color: "#6A6A6A" }}>
                Provide the bank account details to receive your settlement{" "}
              </p>

              {/* <span
                style={{
                  color: "#F14810",
                  fontWeight: "600",
                  fontSize: "12px",
                }}
              >
                View History
              </span> */}
              <br />

              <p style={{ fontWeight: "500", margin: "5px 0" }}>
                Click the button below to watch how to edit your set-up.
              </p>

              {/* <Link to="/help">
                <span style={{ color: "#F14810", fontWeight: "600" }}>
                  Watch Video
                </span>
              </Link> */}
              <span style={{
                color: "#F14810",
                fontWeight: "600",
                fontSize: "16px",
                cursor: "pointer",
              }}
                onClick={() => handleShowVideo()}
              >
                Watch Video
              </span>
            </div>

            <div style={{ flexBasis: "60%" }}>
              <Form className="form">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">Bank Name</Form.Label>
                  <div ref={dropdownRef} style={{ position: "relative" }}>
                    <div style={{ position: "relative" }}>
                      <label
                        style={{
                          width: "100%",
                          backgroundColor: "#ededed",
                          borderRadius: "5px",
                          padding: "7px 10px",
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                        onClick={() => setShowBanks(!showBanks)}
                      >
                        {selectedBankName ? selectedBankName : "Select Bank"}
                      </label>
                      <img
                        src={dropdownIcon}
                        alt="dropdown icon"
                        style={{
                          position: "absolute",
                          right: "15px",
                          top: "40%",
                        }}
                      />
                    </div>

                    {showBanks && (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          zIndex: "10",
                          transition: "all",
                          transitionTimingFunction: "ease-in-out",
                          transitionDuration: "0.3s",
                          backgroundColor: "white",
                          maxHeight: "200px",
                          overflow: "auto",
                          width: "100%",
                          border: "1px solid rgb(221, 221, 221)",
                          borderRadius: "5px",
                          padding: "5px 10px",
                        }}
                      >
                        <input
                          type="search"
                          name="searchText"
                          value={searchText}
                          onChange={handleOnChange}
                          placeholder="Search for bank"
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "#ededed",
                            width: "100%",
                            padding: "4px 8px",
                            marginBottom: "10px",
                          }}
                        />

                        {filteredBanks?.map((bank) => (
                          <div
                            style={{ width: "100%", cursor: "pointer" }}
                            onClick={() => {
                              setSelectedBankName(bank?.name);
                              //console.log("bank.code", bank?.code );
                              setSelectedBankCode(bank?.code);
                              setShowBanks(false);
                            }}
                          >
                            {bank?.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">
                    Account Number
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="text"
                      pattern="\d{10}"
                      maxLength="10"
                      name="accountNumber"
                      // value={
                      //   institutionDetails?.settlementAccountNumber
                      //     ? institutionDetails?.settlementAccountNumber
                      //     : accountNumber
                      // }
                      value={accountNumber}
                      onChange={handleOnChange}
                      placeholder="Enter your Account Number (e.g 0123456789)"
                      // disabled={institutionDetails?.settlementAccountNumber}
                      required
                    />
                  </InputGroup>
                </Form.Group>

                {accountNameLoading && (
                  <Spinner
                    animation="border"
                    variant="danger"
                    color="#F14810"
                  />
                )}

                {accountObject && (
                  <p style={{ color: "#F14810", fontWeight: "600px" }}>
                    <span style={{ padding: "1px" }}>
                      {accountObject?.accountName}
                    </span>
                    <br />
                    <span style={{ padding: "1px" }}>
                      {accountObject?.accountNumber}
                    </span>
                    <br />
                    <span style={{ padding: "1px" }}>
                      {accountObject?.bankName}
                    </span>
                  </p>
                )}

                {accountNumber.length >= 10 &&
                  accountNameSuccess == false &&
                  accountNameLoading == false && (
                    <p style={{ color: "red", fontStyle: "italic" }}>
                      Invalid account number.
                    </p>
                  )}
              </Form>

              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  marginTop: "30px",
                }}
              >
                <Button
                  className="button"
                  size="md"
                  onClick={
                    !accountObject?.accountName || accountNumber.length != 10
                      ? null
                      : () => handleChangeAccount()
                  }
                  style={{
                    backgroundColor: `${
                      !accountObject?.accountName || accountNumber.length != 10
                        ? "#FFCAB8"
                        : "#F14810"
                    }`,
                    borderColor: `${
                      !accountObject?.accountName || accountNumber.length != 10
                        ? "#FFCAB8"
                        : "#F14810"
                    }`,
                    cursor: `${
                      !accountObject?.accountName || accountNumber.length != 10
                        ? "not-allowed"
                        : "pointer"
                    }`,
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.3 + "rem",
                    marginRight: 0.3 + "rem",
                    float: "left",
                  }}
                  // disabled={addAccountLoading}
                >
                  {changeAccountLoading ? (
                    <>
                      <span>Loading...</span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </>
                  ) : (
                    <span>Save Account Details</span>
                  )}
                </Button>
              </div>
            </div>
          </div>
        )}

        {activePage === "Settlement" && (
          <div
            style={{ display: "flex", gap: "30px", marginTop: "40px" }}
            className="account-in-settings"
          >
            <div style={{ flexBasis: "40%" }}>
              <h6 style={{ marginBottom: "10px", color: "#F14810" }}>
                Automatic Settlement
              </h6>

              <div>
                <Row>
                  <Col>
                    <p
                      style={{
                        fontWeight: "500",
                        margin: "1px 0",
                        fontSize: "14px",
                      }}
                    >
                      {settlementIntervalDetails?.nextSettlementDate}
                    </p>
                    <p style={{ marginBottom: "10px", color: "#6A6A6A" }}>
                      Next Settlement Date{" "}
                    </p>
                  </Col>
                  <Col>
                    {/* <div style={{ display: "flex", gap: "3px" }}>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "35px",
                          width: "35px",
                          borderRadius: "100%",
                          backgroundColor: "#E7E7E7",
                          cursor: "pointer",
                        }}
                        onClick={() => handleShowEditIntervalModal()}
                      >
                        <EditIcon />
                      </span>
                    </div> */}
                  </Col>
                </Row>
              </div>
              <div>
                <p
                  style={{
                    fontWeight: "500",
                    margin: "1px 0",
                    fontSize: "14px",
                  }}
                >
                  {settlementIntervalDetails?.nextSettlementTime}
                </p>
                <p style={{ marginBottom: "10px", color: "#6A6A6A" }}>
                  Next Settlement Time{" "}
                </p>
              </div>
              {/* <Link to="/help">
                <span style={{ color: "#F14810", fontWeight: "600" }}>
                  Watch Video
                </span>
              </Link> */}
              <div>
                <p
                  style={{
                    fontWeight: "500",
                    margin: "1px 0",
                    fontSize: "14px",
                  }}
                >
                  {settlementIntervalDetails?.intervalValue}
                </p>
                <p style={{ marginBottom: "10px", color: "#6A6A6A" }}>
                  Interval{" "}
                </p>
              </div>
            </div>

            <div style={{ flexBasis: "60%" }}>
              <h6 style={{ marginBottom: "10px", color: "#F14810" }}>
                Settlement Details
              </h6>
              <Row>
                <Col>
                  <div>
                    <p
                      style={{
                        fontWeight: "500",
                        margin: "1px 0",
                        fontSize: "16px",
                      }}
                    >
                      ₦{" "}
                      {addTrailingCommas(
                        settlementAcctDetails?.settlementAmount
                      )}
                    </p>
                    <p style={{ marginBottom: "10px", color: "#6A6A6A" }}>
                      Settlement Amount{" "}
                    </p>
                  </div>
                </Col>
                <Col>
                  <div>
                    <p
                      style={{
                        fontWeight: "500",
                        margin: "1px 0",
                        fontSize: "16px",
                      }}
                    >
                      ₦{" "}
                      {addTrailingCommas(
                        settlementAcctDetails?.commissionAmount
                      )}
                    </p>
                    <p style={{ marginBottom: "10px", color: "#6A6A6A" }}>
                      Total Processing Fee{" "}
                    </p>
                  </div>
                </Col>
                <Col>
                  <div>
                    <p
                      style={{
                        fontWeight: "500",
                        margin: "1px 0",
                        fontSize: "16px",
                      }}
                    >
                      {settlementAcctDetails?.commissionType !== "RATE"
                        ? "₦ " + settlementAcctDetails?.commission
                        : settlementAcctDetails?.commission + " %"}
                    </p>
                    <p style={{ marginBottom: "10px", color: "#6A6A6A" }}>
                      Processing Fee Rate{" "}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <p
                      style={{
                        fontWeight: "500",
                        margin: "1px 0",
                        fontSize: "16px",
                      }}
                    >
                      {settlementAcctDetails?.bankName}
                    </p>
                    <p style={{ marginBottom: "10px", color: "#6A6A6A" }}>
                      Bank Name{" "}
                    </p>
                  </div>
                </Col>
                <Col>
                  <div>
                    <p
                      style={{
                        fontWeight: "500",
                        margin: "1px 0",
                        fontSize: "16px",
                      }}
                    >
                      {settlementAcctDetails?.accountNumber}
                    </p>
                    <p style={{ marginBottom: "10px", color: "#6A6A6A" }}>
                      Account Number{" "}
                    </p>
                  </div>
                </Col>
                <Col>
                  <div>
                    {/* <p style={{ fontWeight: "500", margin: "1px 0", fontSize: "16px" }}>
                      10%
                    </p>
                    <p style={{ marginBottom: "10px", color: "#6A6A6A" }}>
                      Processing Fee Rate{" "}
                    </p> */}
                  </div>
                </Col>
              </Row>
              <div>
                {/* <Button
                  className="button"
                  size="md"
                  onClick={() => handleSettleNow()}
                  style={{
                    backgroundColor: "#F14810",
                    borderColor: "#F14810",
                    cursor: "pointer",
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.3 + "rem",
                    marginRight: 0.3 + "rem",
                    float: "left",
                    marginTop: 0.8 + "rem",
                  }}
                >
                  {changeAccountLoading ? (
                    <>
                      <span>Loading...</span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </>
                  ) : (
                    <span>Settle Now</span>
                  )}
                </Button> */}
              </div>

              {/* <p style={{ fontWeight: "500", margin: "5px 0" }}>
                Click the button below to watch how to edit your set-up.
              </p> */}

              {/* <Link to="/help">
                <span style={{ color: "#F14810", fontWeight: "600" }}>
                  Watch Video
                </span>
              </Link> */}
            </div>
          </div>
        )}

        {activePage === "View History" && (
          <InstitutionAccountHistory setIsHistoryViewed={handleHistoryView} />
        )}
      </div>

      <ModalComponent
        title=""
        showModal={showOTPModal}
        closeModal={handleCloseOTPModal}
      >
        <div className="validate-page">
          <div className="validate-email-container">
            <h3 style={{ marginBottom: "15px" }}>
              Enter the 6-digit code sent to{" "}
              {institutionDetails?.createdBy?.email}
            </h3>
            {otpError && (
              <span style={{ color: "red", fontStyle: "italic" }}>
                {otpError}
              </span>
            )}
            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
              {otp.map((digit, index) => (
                <input
                  key={index}
                  value={digit}
                  maxLength={1}
                  onChange={(e) => handleOTPChange(e.target.value, index)}
                  onKeyUp={(e) => handleOTPBackspaceAndEnter(e, index)}
                  ref={(reference) =>
                    (otpBoxReference.current[index] = reference)
                  }
                  className={`border h-auto text-black p-2 px-1 rounded-md block bg-grey focus:border-2 focus:outline-none appearance-none`}
                />
              ))}
            </div>

            <div>
              <p
                style={{
                  fontFamily: "jost, sans-serif",
                  fontSize: 0.85 + "rem",
                  marginTop: 1 + "rem",
                  marginRight: -2 + "rem",
                }}
              >
                Haven’t received code,
                <span
                  style={{ color: "#3174fd", cursor: "pointer" }}
                  onClick={handleResendCode}
                >
                  Resend
                </span>
              </p>
            </div>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        title=""
        showModal={showConfirmSettlementModal}
        closeModal={handleCloseConfirmSettlementModal}
      >
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
            margin: "30px",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              fontWeight: "600px",
              textAlign: "center",
              marginBottom: "0px",
            }}
          >
            Are you sure you want to continue with settlement?
          </p>
        </div>
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "15px",
          }}
        >
          <ButtonX
            backgroundColor="#FFF"
            border="#f14810"
            color="#f14810"
            onClick={() => handleConfirmSettlement(false)}
            overlay={true}
            overlayText="Click to Cancel Settlement"
          >
            No
          </ButtonX>
          <ButtonX
            onClick={() => handleConfirmSettlement(true)}
            backgroundColor="#F14810"
            overlay={true}
            overlayText="Click to Confirm Settlement"
          >
            Yes
          </ButtonX>
        </div>
      </ModalComponent>

      <ModalComponent
        title="Edit Settlement Interval"
        showModal={showEditIntervalModal}
        closeModal={handleCloseEditIntervalModal}
      >
        <form>
          <div style={{ marginBottom: "15px", marginTop: "20px" }}>
            <p style={{ marginBottom: "5px", fontWeight: "500" }}>
              Settlement Start Date & Time (Interval){" "}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </p>
            <input
              type="datetime-local"
              placeholder="Enter Date and Time"
              name="startDate"
              value={startDate}
              //value={editingElement ? editingValue : name}
              onChange={(e) => handleOnChangeStartDate(e)}
              style={{
                borderRadius: "5px",
                backgroundColor: "#dddddd",
                width: "100%",
              }}
            />
          </div>
          {/* <div style={{ marginBottom: "15px" }}>
            <p style={{ marginBottom: "5px", fontWeight: "500" }}>
              Time of Day{" "}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </p>
            <input
              type="time"
              placeholder="Enter Time of day"
              name="amount"
              // value={name}
              //value={editingElement ? editingValue : name}
              //onChange={handleOnChange}
              style={{
                borderRadius: "5px",
                backgroundColor: "#dddddd",
                width: "100%",
              }}
            />
          </div> */}
          <div style={{ marginTop: "30px" }}>
            <Button
              onClick={(e) => handleSaveSettlementInterval(e)}
              variant="primary"
              style={{
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                float: "left",
              }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Save Changes
              </span>
            </Button>
          </div>
        </form>
      </ModalComponent>

      <ModalComponent
        showModal={showSuccessModal}
        closeModal={handleCloseSuccessModal}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!!</h3>
          <p style={{ fontSize: "18px" }}>
            Settlement account set successfully
          </p>
        </div>
      </ModalComponent>

      {/* settled succcessfully */}
      <ModalComponent
        showModal={showSuccessSettlementModal}
        closeModal={() => setShowSuccessSettlementModal(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!!</h3>
          <p style={{ fontSize: "18px" }}>
            Settlement has been paid successfully
          </p>
        </div>
      </ModalComponent>

      <ModalComponent
        showModal={showSuccessIntervalModal}
        closeModal={() => setShowSuccessIntervalModal(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!!</h3>
          <p style={{ fontSize: "18px" }}>
            Settlement interval has been changed successfully
          </p>
        </div>
      </ModalComponent>

      <VideoModal
        show={showVideoModal}
        handleClose={handleCloseVideo}
        videoId={helpVideos.collectionAccount.videoId}
        videoTitle={helpVideos.collectionAccount.title}
      />
    </>
  );
};

export default InstitutionAccountPage;
