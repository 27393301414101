import { React, useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";

import "../../../../../styles/formpage.css";

import ModalComponent from "../../../../../component/modal/modal";

import { useSelector } from "react-redux";
import alertIcon from "../../../../../assets/svgs/alertIcon.svg";
import ActionIcon from "../../../../../component/actionbutton/ActionIcon";
import {
  useAddInstitutionCategoryMutation,
  useEditInstitutionCategoryMutation,
  useDeleteInstitutionCategoryMutation,
  useGetInstitutionCategoriesQuery,
} from "../../../../../redux/features/institution/institutionApiSlice";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import ToastComponent from "../../../../../component/toast/toast";

function InstitutionCategory() {
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newCategory, setNewCategory] = useState([]);
  const [deletedElementID, setDeletedElementID] = useState(null);
  const [editedElement, setEditedElement] = useState(null);
  const [editingElement, setEditingElement] = useState(null);
  const [editingValue, setEditingValue] = useState("");
  const [elementToDelete, setElementToDelete] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const tableHeaders = ["ID", "Category Name", "Category Code", "Action"];

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const {
    data: categoryData,
    refetch: refetchCategories,
    isLoading: categoriesLoading,
  } = useGetInstitutionCategoriesQuery(instId);

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingValue("");
    setEditingElement(null);
  };

  const [addInstitutionCategory, { isLoading: addLoading }] =
    useAddInstitutionCategoryMutation();
  const [editInstitutionCategory, { isLoading: editLoading }] =
    useEditInstitutionCategoryMutation();
  const [deleteInstitutionCategory, { isLoading: deleteLoading }] =
    useDeleteInstitutionCategoryMutation();

  const [newCategoryData, setNewCategoryData] = useState({
    name: "",
  });

  const { name } = newCategoryData;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setNewCategoryData({ ...newCategoryData, [name]: value });
    setEditingValue(value);
  };

  const handleEditcategory = (el) => {
    console.log("edit was clicked for ", el);
    setEditingElement(el);
    setShowModal(true);
    setEditingValue(el.name);
  };

  const confirmDelete = (el) => {
    setElementToDelete(el);
    setShowConfirmDelete(true);
  };

  const handleDeletecategory = async () => {
    console.log("delete was clicked for ", elementToDelete);
    setShowConfirmDelete(false);
    try {
      const parameters = {
        categoryId: elementToDelete.id,
        body: { name: elementToDelete.name },
      };
      await deleteInstitutionCategory(parameters).unwrap();
      setDeletedElementID(elementToDelete.id);
    } catch (error) {
      console.log(error);
      // alert(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleCreateOrUpdateCategory = async (e, type) => {
    e.preventDefault();

    if (type === "edit") {
      if (editingValue === "") {
        setIsFormNotFilled(true);

        setTimeout(() => {
          setIsFormNotFilled(false);
        }, 3000);

        return;
      }
      const parameters = {
        categoryId: editingElement?.id,
        body: { name: editingValue },
      };
      try {
        const res = await editInstitutionCategory(parameters);
        if (res) {
          setEditedElement(res);
          setEditingValue("");
        }
        setEditingElement(null);
        setNewCategoryData({
          name: "",
        });
        setShowModal(false);
      } catch (error) {
        console.log(error);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
      return;
    } else {
      if (name === "") {
        setIsFormNotFilled(true);

        setTimeout(() => {
          setIsFormNotFilled(false);
        }, 3000);

        return;
      }
      try {
        console.log(newCategoryData);
        const obj = { institutionId: instId, body: newCategoryData };

        const data = await addInstitutionCategory(obj).unwrap();

        if (data) {
          setNewCategory(data);
        }
        setNewCategoryData({
          name: "",
        });
        setShowModal(false);
      } catch (error) {
        console.log(error.data);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
    }
  };

  useEffect(() => {
    if (categoryData) {
      refetchCategories();
    }
  }, [newCategory, deletedElementID, editedElement]);

  if (categoriesLoading || addLoading || editLoading || deleteLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <h6 style={{ fontSize: "14px", fontWeight: 600 }}>
        Institution Category
      </h6>
      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "8px",
          padding: "15px",
          height: "95%",
        }}
      >
        {/* delete confirmation modal */}
        <ModalComponent
          showModal={showConfirmDelete}
          closeModal={() => setShowConfirmDelete(false)}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={alertIcon}
              alt="alert Icon"
              style={{ margin: "0 auto" }}
            />
            <p
              style={{
                marginTop: "10px",
                marginBottom: "5px",
                fontWeight: "500",
                fontSize: 1.0 + "rem",
              }}
            >
              Are you sure you want to delete this category?{" "}
            </p>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                gap: "10px",
                marginLeft: "30%",
              }}
            >
              <Button
                onClick={() => setShowConfirmDelete(false)}
                type="button"
                variant="outline-primary"
                style={{ borderColor: "#F14810", color: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  No
                </span>
              </Button>
              <Button
                onClick={() => handleDeletecategory()}
                type="button"
                variant="primary"
                style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Yes
                </span>
              </Button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          title="Create Category"
          showModal={showModal}
          closeModal={handleCloseModal}
        >
          {isFormNotFilled && (
            <span
              style={{
                color: "red",
                fontStyle: "italic",
                transition: "ease-in-out",
              }}
            >
              Please fill all fields
            </span>
          )}

          <form onSubmit={(e) => e.preventDefault()}>
            <div style={{ marginBottom: "15px" }}>
              <p style={{ marginBottom: "5px", fontWeight: "500" }}>
                Category Name{" "}
                <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
              </p>
              <input
                type="text"
                placeholder="Enter Category Name (e.g A, B, Blue)"
                name="name"
                // value={name}
                value={editingElement ? editingValue : name}
                onChange={handleOnChange}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#dddddd",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginTop: "30px" }}>
              {editingElement ? (
                <Button
                  onClick={(e) => handleCreateOrUpdateCategory(e, "edit")}
                  variant="primary"
                  style={{
                    backgroundColor: "#F14810",
                    borderColor: "#F14810",
                    float: "left",
                  }}
                  className="button"
                  size="md"
                >
                  <span
                    style={{
                      fontSize: 0.8 + "rem",
                      marginLeft: 0.5 + "rem",
                      marginRight: 0.5 + "rem",
                    }}
                  >
                    Edit Category
                  </span>
                </Button>
              ) : (
                <Button
                  onClick={(e) => handleCreateOrUpdateCategory(e, "")}
                  variant="primary"
                  style={{
                    backgroundColor: "#F14810",
                    borderColor: "#F14810",
                    float: "left",
                  }}
                  className="button"
                  size="md"
                >
                  <span
                    style={{
                      fontSize: 0.8 + "rem",
                      marginLeft: 0.5 + "rem",
                      marginRight: 0.5 + "rem",
                    }}
                  >
                    Save Category
                  </span>
                </Button>
              )}
            </div>
          </form>
        </ModalComponent>

        <Table
          // responsive
          striped="true"
          size="sm"
          style={{
            fontFamily: "jost, sans-serif",
            fontSize: "14px",
          }}
        >
          <thead>
            <tr align="center">
              {categoryData &&
                categoryData.length > 0 &&
                tableHeaders.map((header, i) => <th key={i}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {categoryData &&
              categoryData.length > 0 &&
              categoryData.map((category, i) => (
                <tr key={i} className={i % 2 === 0 ? "even-row" : "odd-row"}>
                  <td align="center">{category.id}</td>
                  <td align="center">{category.name}</td>
                  <td align="center">{category.code}</td>
                  <td align="center">
                    <ActionIcon
                      actionsArr={[
                        {
                          label: "Edit",
                          action: () => handleEditcategory(category),
                        },
                        // {
                        //   label: "Delete",
                        //   action: () => confirmDelete(category),
                        // },
                      ]}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        <div style={{ float: "right", marginTop: "20px" }}>
          <Button
            className="button"
            size="md"
            style={{
              backgroundColor: "#F14810",
              color: "#ffffff",
              border: "none",
              outline: "none",
            }}
            onClick={() => setShowModal(true)}
            title="Click this button to create a category"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.3 + "rem",
                marginRight: 0.3 + "rem",
              }}
            >
              Create New Category
            </span>
          </Button>
        </div>
      </div>
    </>
  );
}

export default InstitutionCategory;
