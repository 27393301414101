import { createSlice } from "@reduxjs/toolkit";

// storing in local storage for now, will remove from local storage when the login api ensures users are registered before allowing them log in
const initialState = {
  institutionData: null,
  institutionDetails: null,
  brandingDetails: null,
  contactPersonDetails: null,
  credentials: null,
  branch: null,
  dashboardData: null,
  logoChange: null,
  selectedClassName: null,
  selectedReportTitle: null,
  categoryToManage: null,
  categoryToDelete: null,
  specificInvoiceDetails: null
};

export const institutionSlice = createSlice({
  name: "institution",
  initialState,
  reducers: {
    storeInstitutionData: (state, action) => {
      state.institutionData = action.payload;
    },
    storeInstitutionDetails: (state, action) => {
      state.institutionDetails = action.payload;
    },
    storeBrandingDetails: (state, action) => {
      state.brandingDetails = action.payload;
    },
    storeContactPersonDetails: (state, action) => {
      state.contactPersonDetails = action.payload;
    },
    storeCredentials: (state, action) => {
      state.credentials = action.payload;
    },
    storeBranchInfo: (state, action) => {
      state.branch = action.payload;
    },
    storeDashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
    storeLogoChange: (state, action) => {
      state.logoChange = action.payload;
    },
    storeSelectedClassName: (state, action) => {
      state.selectedClassName = action.payload;
    },
    storeSelectedReportTitle: (state, action) => {
      state.selectedReportTitle = action.payload;
    },
    storeCategoryToManage: (state, action) => {
      state.categoryToManage = action.payload;
    },
    storeCategoryToDelete: (state, action) => {
      state.categoryToDelete = action.payload;
    },
    storeSpecificInvoiceDetails: (state, action) => {
      state.specificInvoiceDetails = action.payload;
    },
  },
});

export const {
  storeInstitutionData,
  storeBrandingDetails,
  storeContactPersonDetails,
  storeCredentials,
  storeInstitutionDetails,
  storeBranchInfo,
  storeDashboardData,
  storeLogoChange,
  storeSelectedClassName,
  storeSelectedReportTitle,
  storeCategoryToManage,
  storeCategoryToDelete,
  storeSpecificInvoiceDetails,
} = institutionSlice.actions;

export default institutionSlice.reducer;
