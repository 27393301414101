import { Bell, List } from "react-bootstrap-icons";
import { Row, Col } from "react-bootstrap";
import { Sidebar, MenuItem, Menu } from "react-pro-sidebar";
import { Navbar, Container, Image, Spinner, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dropdownIcon from "../../../../../assets/svgs/dropdownIcon.svg";
import userDisplayPhoto from "../../../../../assets/images/userDisplayPhoto.png";
import femaleAvatar from "../../../../../assets/svgs/femaleAvatar.svg";
import { logOut } from "../../../../../redux/features/auth/authSlice";
import { Offcanvas } from "react-bootstrap";
import logo from "../../../../../assets/svgs/logo.svg";
import DashboardIcon from "../../../../../assets/svgs/dashboardIcon";
import StudentIcon from "../../../../../assets/svgs/studentIcon";
import ParentIcon from "../../../../../assets/svgs/parentIcon";
import InvoicingIcon from "../../../../../assets/svgs/Invoicing.js";
import menuIcon from "../../../../../assets/svgs/menuIcon.svg";
import AccountIcon from "../../../../../assets/svgs/accountIcon";
import BranchIcon from "../../../../../assets/svgs/BranchIcon";
import ClassIcon from "../../../../../assets/svgs/ClassIcon";
import FeeIcon from "../../../../../assets/svgs/FeeIcon.js";
import SchoolCalendarIcon from "../../../../../assets/svgs/SchoolCalendarIcon.js";
import SettingsIcon from "../../../../../assets/svgs/settingsIcon";
import { useLocation, useNavigate } from "react-router-dom";
import Profile from "./profile";
import ReportingIcon from "../../../../../assets/svgs/reportingIcon";
import SettlementIcon from "../../../../../assets/svgs/settlementIcon.js";
import HelpIcon from "../../../../../assets/svgs/helpicon";
import {
  useGetInstitutionQuery,
  useGetDashboardReportQuery,
  useGetInstitutionLogoQuery,
  useGetSetupProgressQuery,
} from "../../../../../redux/features/institution/institutionApiSlice";
import { capitalizeWords } from "../../../../../utils/utils";
import Notifications from "./notifications.js";
import alertIcon from "../../../../../assets/svgs/alertIcon.svg";
import placeholderLogo from "../../../../../assets/svgs/institutionPlaceholderLogo.svg";

const DashboardTopSection = () => {
  const [dropdownProfile, setDropdownProfile] = useState(false);
  // console.log(details)

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const { data: institutionDetails, isLoading: detailsLoading } =
    useGetInstitutionQuery(instId);

  const {
    data: institutionLogo,
    refetch,
    isLoading,
  } = useGetInstitutionLogoQuery(instId);

  const { data: setupProgress, isLoading: progressLoading } =
    useGetSetupProgressQuery(instId);

  // const { data: dashboardData, isLoading: dashboardDataLoading } =
  //   useGetDashboardReportQuery(instId);

  const date = new Date();
  const hour = date.getHours();

  // const dashboardRec = useSelector((state) => state?.institution?.dashboardData);

  const {
    data: dashboardRec,
    isLoading: dashboardDataLoading,
    refetch: refetchDashboardDetails,
  } = useGetDashboardReportQuery(instId);

  const [showProfileCanvas, setShowProfileCanvas] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const handleCloseCanvas = () => {
    setShowProfileCanvas(false);
  };
  const handleShowCanvas = () => {
    setDropdownProfile(false);
    setShowProfileCanvas(true);
  };

  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(logOut());
  };

  const goToSetupPage = () => {
    navigate("/school-setup");
  };

  const location = useLocation();
  const navigate = useNavigate();

  const username = useSelector((state) => state?.auth?.userInfo?.username);
  const gender = useSelector((state) => state?.auth?.userInfo?.gender);

  const [showMenu, setShowMenu] = useState(false);

  const menuItem = {
    height: 2.7 + "rem",
    fontSize: 0.87 + "rem",
  };

  // useEffect(() => {
  //   const handleClose = (e) => {
  //     // close menu when the click outside
  //     if (
  //       showMenu &&
  //       !e.target.className.includes("mobileDashboardNavbar") &&
  //       !e.target.className.includes("menuIcon")
  //     ) {
  //       setShowMenu(false);
  //     }
  //   };

  //   window.addEventListener("click", handleClose);

  //   return () => {
  //     window.removeEventListener("click", handleClose);
  //   };
  // }, [showMenu]);

  return (
    <>
      <Row style={{ width: 100 + "%", position: "relative" }}>
        <Col>
          <img
            src={menuIcon}
            style={{ cursor: "pointer" }}
            alt="menu icon"
            onClick={() => setShowMenu(!showMenu)}
            className="menuIcon"
          />
          <div className="session-text-container-web">
            <h5 style={{ marginBottom: "3px" }}>
              <b>
                <span>
                  {dashboardRec &&
                    capitalizeWords(dashboardRec?.activeCalendar?.schoolYear)}
                </span>
              </b>
            </h5>
            {dashboardRec?.activeCalendar?.schoolYear && (
              <h6 style={{ color: "#b9b9b9" }}>Academic Session</h6>
            )}
          </div>
        </Col>
        <Col>
          <div className="profile-container">
            <div
              className="notificationbell"
              onClick={() => setShowNotifications(!showNotifications)}
            >
              <Bell size="21" />
            </div>

            <div className="user-details">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {hour > 12 ? (
                  hour >= 16 ? (
                    <span style={{ flexShrink: "0" }}>Good Evening, </span>
                  ) : (
                    <span style={{ flexShrink: "0" }}>Good Afternoon, </span>
                  )
                ) : (
                  <span style={{ flexShrink: "0" }}>Good Morning, </span>
                )}
                <span>{username}</span>
                {/* <span>Hi, {username}</span> */}
                <div
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => setDropdownProfile(!dropdownProfile)}
                >
                  <img
                    src={gender === "male" ? userDisplayPhoto : femaleAvatar}
                    alt="user"
                    style={{ width: "100%" }}
                  />
                </div>

                <img
                  src={dropdownIcon}
                  alt="drop down"
                  onClick={() => setDropdownProfile(!dropdownProfile)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            {dropdownProfile && (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  right: 0,
                  width: "130px",
                  zIndex: 10,
                  backgroundColor: "#fff2ee",
                  padding: "15px",
                }}
                className="profile-dropdown"
              >
                <span
                  onClick={handleShowCanvas}
                  style={{ cursor: "pointer", fontWeight: 500 }}
                >
                  Profile
                </span>
                <hr />
                <span
                  onClick={handleLogOut}
                  style={{ cursor: "pointer", fontWeight: 500 }}
                >
                  LogOut
                </span>
              </div>
            )}
          </div>
        </Col>
      </Row>

      <Offcanvas
        show={showProfileCanvas}
        onHide={handleCloseCanvas}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Profile</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ paddingTop: "0px" }}>
          <Profile institutionDetails={institutionDetails} />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showNotifications}
        onHide={() => setShowNotifications(false)}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Notification</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ paddingTop: "0px" }}>
          <Notifications
            institutionDetails={institutionDetails}
            setShowNotifications={setShowNotifications}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showMenu}
        onHide={() => setShowMenu(false)}
        placement={"start"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "250px" }}
      >
        <Offcanvas.Body
          style={{
            padding: "20px 0",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="school-branding"
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              paddingLeft: "15px",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "100%",
                overflow: "hidden",
              }}
            >
              {isLoading ? (
                <Spinner animation="border" variant="danger" color="#F14810" />
              ) : (
                <img
                  src={
                    institutionLogo ? institutionLogo?.logo : placeholderLogo
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    minWidth: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              )}
            </div>
            <div style={{ marginTop: 10 + "px" }}>
              <h4>{capitalizeWords(institutionDetails?.institutionName)}</h4>
              <p>{institutionDetails?.address}</p>
            </div>
          </div>

          <Menu
            className="menu"
            style={{ marginBottom: 2 + "rem" }}
            menuItemStyles={{
              button: ({ active }) => ({
                color: active ? "white" : "#464646",
                backgroundColor: active ? "#FD5821" : "transparent",
                "&:hover": {
                  backgroundColor: active ? "#FD5821" : "#F5F5F5",
                  color: active ? "white" : "#FFFFFF",
                },
              }),
            }}
          >
            {institutionDetails?.setupComplete === false && (
              <div
                className="school-setup"
                style={{
                  backgroundColor: "#ffe6de",
                  border: "1px solid #DBDBDB",
                  borderRadius: 8 + "px",
                  margin: 10 + "px",
                  marginTop: -1.5 + "rem",
                  marginBottom: 2 + "rem",
                  padding: 4 + "px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 6,
                  }}
                >
                  <img src={alertIcon} style={{ width: "12%" }} />
                  <p
                    style={{
                      fontSize: 0.7 + "rem",
                      marginTop: 10 + "px",
                    }}
                  >
                    Complete your school setup here to enable settlements
                  </p>
                </div>

                <h6
                  style={{
                    marginLeft: 20 + "px",
                    marginTop: -2 + "px",
                  }}
                >
                  {setupProgress?.progress} Completion
                </h6>

                <div>
                  <Button
                    variant="primary"
                    style={{
                      marginLeft: 20 + "px",
                      marginBottom: 10 + "px",
                      backgroundColor: "#F14810",
                      borderColor: "#F14810",
                    }}
                    className="button"
                    size="sm"
                  >
                    <span
                      style={{
                        fontSize: 0.8 + "rem",
                        marginLeft: 0.5 + "rem",
                        marginRight: 0.5 + "rem",
                      }}
                      onClick={goToSetupPage}
                    >
                      Complete Setup
                    </span>
                  </Button>
                </div>
              </div>
            )}

            <Link to="/dashboard">
              <MenuItem
                style={menuItem}
                active={
                  location.pathname.startsWith("/dashboard") ? "active" : null
                }
                icon={
                  <DashboardIcon
                    active={
                      location.pathname.startsWith("/dashboard")
                        ? "active"
                        : null
                    }
                    size={18}
                  />
                }
              >
                {" "}
                Dashboard
              </MenuItem>
            </Link>

            <Link to="/branch">
              <MenuItem
                style={menuItem}
                active={
                  location.pathname.startsWith("/branch") ? "active" : null
                }
                icon={
                  <BranchIcon
                    active={
                      location.pathname.startsWith("/branch") ? "active" : null
                    }
                    size={18}
                  />
                }
              >
                {" "}
                Branch Management
              </MenuItem>
            </Link>

            <Link to="/student">
              <MenuItem
                style={menuItem}
                active={
                  location.pathname.startsWith("/student") ? "active" : null
                }
                icon={
                  <StudentIcon
                    active={
                      location.pathname.startsWith("/student") ? "active" : null
                    }
                    size={19}
                  />
                }
              >
                {" "}
                Students Management
              </MenuItem>
            </Link>

            <Link to="/invoicing">
              <MenuItem
                style={menuItem}
                active={
                  location.pathname.startsWith("/invoicing") ? "active" : null
                }
                icon={
                  <InvoicingIcon
                    active={
                      location.pathname.startsWith("/invoicing")
                        ? "active"
                        : null
                    }
                    size={19}
                  />
                }
              >
                {" "}
                Invoicing
              </MenuItem>
            </Link>

            <Link to="/classes">
              <MenuItem
                style={menuItem}
                active={
                  location.pathname.startsWith("/classes") ? "active" : null
                }
                icon={<ClassIcon size={18} />}
              >
                {" "}
                Classes
              </MenuItem>
            </Link>

            <Link to="/messaging">
              <MenuItem
                style={menuItem}
                active={
                  location.pathname.startsWith("/messaging") ? "active" : null
                }
                icon={
                  <SettlementIcon
                    active={
                      location.pathname.startsWith("/messaging")
                        ? "active"
                        : null
                    }
                    size={18}
                  />
                }
              >
                {" "}
                Reminder Management
              </MenuItem>
            </Link>
            {/* <MenuItem style={menuItem} icon={<FeeIcon size={19} />}>
          {" "}
          Fees
        </MenuItem> */}
            <Link to="/reporting">
              <MenuItem
                style={menuItem}
                active={
                  location.pathname.startsWith("/reporting") ? "active" : null
                }
                icon={
                  <ReportingIcon
                    active={
                      location.pathname.startsWith("/reporting")
                        ? "active"
                        : null
                    }
                    size={18}
                  />
                }
              >
                {" "}
                Reporting
              </MenuItem>
            </Link>
            <Link to="/settings">
              <MenuItem
                style={menuItem}
                active={
                  location.pathname.startsWith("/settings") ? "active" : null
                }
                icon={
                  <SettingsIcon
                    active={
                      location.pathname.startsWith("/settings")
                        ? "active"
                        : null
                    }
                    size={19}
                  />
                }
              >
                {" "}
                Settings
              </MenuItem>
            </Link>
            <Link to="/help" style={{ margingBottom: 2 + "rem" }}>
              <MenuItem
                style={menuItem}
                active={location.pathname.startsWith("/help") ? "active" : null}
                icon={
                  <HelpIcon
                    active={
                      location.pathname.startsWith("/help") ? "active" : null
                    }
                    size={18}
                  />
                }
              >
                {" "}
                Help
              </MenuItem>
            </Link>
          </Menu>

          <div
            style={{
              width: 14 + "rem",
              height: "auto",
              marginTop: "auto",
              paddingTop: 1 + "rem",
              paddingBottom: 1 + "rem",
              borderTop: "1px solid #DBDBDB",
              // position: "fixed",
              // bottom: 0,
            }}
          >
            <Navbar.Brand
              className="logo"
              // style={{ marginTop: 20 + "px" }}
            >
              <div className="navbar-brand-space">{/* <br /> */}</div>

              <Image src={logo} fluid />
            </Navbar.Brand>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DashboardTopSection;
