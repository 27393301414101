import React, { useEffect, useState } from "react";
import "../../institutionDashboardPage.css";

import { Container } from "react-bootstrap";
import InstitutionReportGroup from "../reporting/components/institutionReportGroups";
import {
  useGetInstitutionQuery,
  useGetInstitutionLogoQuery,
} from "../../../../../redux/features/institution/institutionApiSlice";

import { useSelector, useDispatch } from "react-redux";
import DashboardTopSection from "../dashboardTopSection/index";
import DashboardSidebar from "../dashboardSidebar/sidebar";

const InstitutionReportPage = () => {
  const [details, setDetails] = useState({});

  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const { data: institutionDetails } = useGetInstitutionQuery(id);
  const { data: institutionLogo } = useGetInstitutionLogoQuery(id);
  // console.log(institutionLogo?.logo);

  useEffect(() => {
    setDetails(institutionDetails);
  }, [institutionDetails, details]);

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar institutionDetails={institutionDetails} />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />
        <InstitutionReportGroup
          institutionDetails={institutionDetails}
          institutionLogo={institutionLogo}
        />
      </Container>
    </Container>
  );
};

export default InstitutionReportPage;
