import React from 'react'

const AdminIcon = ({active}) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0C5.824 0 0 5.824 0 13C0 20.176 5.824 26 13 26C20.176 26 26 20.176 26 13C26 5.824 20.176 0 13 0ZM13 5.2C15.509 5.2 17.55 7.241 17.55 9.75C17.55 12.259 15.509 14.3 13 14.3C10.491 14.3 8.45 12.259 8.45 9.75C8.45 7.241 10.491 5.2 13 5.2ZM13 23.4C10.361 23.4 7.241 22.334 5.018 19.656C7.29509 17.8694 10.1057 16.8984 13 16.8984C15.8943 16.8984 18.7049 17.8694 20.982 19.656C18.759 22.334 15.639 23.4 13 23.4Z"
        fill={active ? "#ffffff" : "#464646"}
      />
    </svg>
  );
}

export default AdminIcon