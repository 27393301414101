import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";

import Button from "../../../../../../component/button/button";
import ActionIcon from "./actionbutton/ActionIcon";
import { useSelector } from "react-redux";
import "../../students/students.css";

import backArrow from "../../../../../../assets/svgs/backArrow.svg";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import {
  useGetSchoolCalendarQuery,
  useGetInstitutionLogoQuery,
} from "../../../../../../redux/features/institution/institutionApiSlice";
import { useGenerateInvoiceMutation } from "../../../../../../redux/features/institution/studentApiSlice";
import TableComponent from "../../../../../../component/table/table";
import { addTrailingCommas } from "../../../../../../utils/utils";
import ToastComponent from "../../../../../../component/toast/toast";

const SchoolCalendarStudentListPage = ({
  calendarStudentListDetails,
  setIsSchoolCalendarStudentListViewed,
}) => {
  const [students, setStudents] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const { data: institutionLogo } = useGetInstitutionLogoQuery(instId);
  const { data: schoolCalendarDetail, isLoading } = useGetSchoolCalendarQuery(
    calendarStudentListDetails.id
  );

  const [generateInvoice, { isLoading: generatingInvoice }] =
    useGenerateInvoiceMutation();

  console.log(schoolCalendarDetail);
  console.log(calendarStudentListDetails.id);

  // const schoolCalendarDetail = [
  //   {
  //     id: 1,
  //     student: {
  //       id: 1,
  //       firstname: "Amara",
  //       lastname: "Oji",
  //       studentNo: "673",
  //       studentCode: "567",
  //       nuban: "234567891",
  //       isActive: true,
  //       status: "active",
  //       walletBalance: 300,
  //       parentName: "A O",
  //       parentAddress: "nil",
  //       parentEmail: "email@email.com",
  //       parentPhoneNo: "08066085859",
  //     },
  //   },
  //   {
  //     id: 2,
  //     student: {
  //       id: 2,
  //       firstname: "Oke",
  //       lastname: "Sam",
  //       studentNo: "673",
  //       studentCode: "567",
  //       nuban: "234567891",
  //       isActive: true,
  //       status: "active",
  //       walletBalance: 300,
  //       parentName: "O K",
  //       parentAddress: "nil",
  //       parentEmail: "email@email.com",
  //       parentPhoneNo: "0806609",
  //     },
  //   },
  // ];

  const columns = [
    { name: "id", label: "ID" },
    {
      name: "student",
      label: "Name",
      options: {
        customBodyRender: (student) =>
          student ? student?.firstname + " " + student?.lastname : "N/A",
      },
    },
    {
      name: "student",
      label: "Gender",
      options: {
        customBodyRender: (student) => (student ? student?.gender : "N/A"),
      },
    },
    {
      name: "student",
      label: "Student No",
      options: {
        customBodyRender: (student) => (student ? student?.studentNo : "N/A"),
      },
    },
    {
      name: "student",
      label: "Nuban",
      options: {
        customBodyRender: (student) => (student ? student?.nuban : "N/A"),
      },
    },
    {
      name: "student",
      label: "Bank Name",
      options: {
        customBodyRender: (student) => (student ? student?.bankName : "N/A"),
      },
    },
    {
      name: "student",
      label: "Wallet Balance",
      options: {
        customBodyRender: (student) =>
          student ? addTrailingCommas(student?.walletBalance) : 0,
      },
    },
    {
      name: "student",
      label: "Parent Name",
      options: {
        customBodyRender: (student) => (student ? student?.parentName : "N/A"),
      },
    },
    {
      name: "student",
      label: "Parent Email",
      options: {
        customBodyRender: (student) =>
          student ? student?.parentEmail : "N/A",
      },
    },
    // {
    //   name: "actions",
    //   label: "Action",
    //   options: {
    //     customBodyRender: (value, tableMeta) => (
    //       <ActionIcon
    //         actionsArr={[
    //           {
    //             label: "View",
    //             action: () => alert(`view action`),
    //           },
    //         ]}
    //       />
    //     ),
    //   },
    // },
  ];


  const handleGenerateInvoice = async () => {
    try {
      const res = await generateInvoice(instId).unwrap();
      console.log(res);
      // setInvoiceData(res);
    } catch (error) {
      console.log(error);
      // alert(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };


  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
            // marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
              // marginBottom: "10px",
              float: "left",
            }}
            onClick={() => setIsSchoolCalendarStudentListViewed(false)}
          >
            <img src={backArrow} alt="Go back" />
            <h6 style={{ marginBottom: 0 }}>
              {calendarStudentListDetails.schoolYear.name} (
              {calendarStudentListDetails.periodName})
            </h6>
          </div>

          {/* <div style={{ display: "flex", gap: "5px", float: "right" }}>
            <Button
            onClick={handleGenerateInvoice}
            >
              Generate Invoice
            </Button>
          </div> */}
        </div>

        {/* <br /> */}

        <section
          style={{
            marginTop: "50px",
          }}
        >
          <TableComponent data={schoolCalendarDetail} title="" columns={columns} />
        </section>
      </div>
    </>
  );
};

export default SchoolCalendarStudentListPage;
