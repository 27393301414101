import Button from "../../../../../../../component/button/button";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useGetInstitutionSettlementListQuery } from "../../../../../../../redux/features/superAdmin/adminApiSlice";

const EditCommissionCanvas = () => {
  const [percentageSelected, setPercentageSelected] = useState(false);
  const [amountSelected, setAmountSelected] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsNames, setSelectedItemsNames] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showSchoolsDropdown, setShowSchoolsDropdown] = useState(false);

  console.log(showSchoolsDropdown);

  const { data: schoolsList } = useGetInstitutionSettlementListQuery();
  console.log(schoolsList);


  const handleAmountClick = () => {
    setPercentageSelected(false);
    setAmountSelected(true);
  };

  const handlePercentClick = () => {
    setAmountSelected(false);
    setPercentageSelected(true);
  };


  return (
    <form onSubmit={(e) => e.preventDefault()} className="commission-canvas">
      <p>
        Our commission system simplifies the process, enabling you to set default or specific rates for transactions. Choose between a global rate applicable to all institution transactions or set specific rates for individual institution transactions.
      </p>



      <div style={{ marginTop: "20px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Form.Check
            inline
            name="rate-type"
            onClick={handlePercentClick}
            type="radio"
            id={`inline-radio-1`}
            size={25}
            style={{
              marginRight: "10px",
              borderColor: "#ff0000",
            }}
          />
          <h6 style={{ marginBottom: "0" }}>Percent Rate ( % )</h6>
        </div>

        <p style={{ marginBottom: "15px" }}>
          Specify the commission rate as a percentage of the transaction amount.
        </p>

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Form.Check
            inline
            name="rate-type"
            onClick={handleAmountClick}
            type="radio"
            id={`inline-radio-2`}
            size={25}
            style={{ marginRight: "10px" }}
          />
          <h6 style={{ marginBottom: "0" }}>Amount Rate ( ₦ )</h6>
        </div>

        <p style={{ marginBottom: "15px" }}>
          Specify the commission rate as a fixed amount in ₦.
        </p>

        {percentageSelected ? (
          <>
            <div style={{ display: "flex", gap: "15px", marginBottom: "30px" }}>
              <div
                style={{
                  backgroundColor: "#ededed",
                  width: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#484d45",
                  padding: "5px 10px",
                }}
              >
                %
              </div>
              <input
                type="text"
                placeholder="Enter rate in percentage"
                style={{ backgroundColor: "#ededed" }}
              />
            </div>
          </>
        ) : null}

        {amountSelected ? (
          <>
            <div style={{ display: "flex", gap: "15px", marginBottom: "30px" }}>
              <div
                style={{
                  backgroundColor: "#ededed",
                  width: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#484d45",
                  padding: "5px 10px",
                }}
              >
                NGN
              </div>
              <input
                type="text"
                placeholder="Enter rate in amount"
                style={{ backgroundColor: "#ededed" }}
              />
            </div>
          </>
        ) : null}

        <Button>Save Rate</Button>
      </div>
    </form>
  );
};

export default EditCommissionCanvas;
