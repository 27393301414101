import React from 'react'

const EditIcon = ({stroke}) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3242 3.17157L15.3242 6.17157M10.3242 17.1716H18.3242M2.32422 13.1716L1.32422 17.1716L5.32422 16.1716L16.9102 4.58557C17.2852 4.21052 17.4958 3.7019 17.4958 3.17157C17.4958 2.64124 17.2852 2.13263 16.9102 1.75757L16.7382 1.58557C16.3632 1.21063 15.8545 1 15.3242 1C14.7939 1 14.2853 1.21063 13.9102 1.58557L2.32422 13.1716Z"
        stroke={stroke ? stroke : "black"}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default EditIcon