import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Container, Offcanvas } from "react-bootstrap";
import EyeActionIcon from "../../../../../assets/svgs/eyeActionIcon.svg";
import Button from "../../../../../component/button/button";
import backArrow from "../../../../../assets/svgs/backArrow.svg";

import { useSelector } from "react-redux";
import ActionIcon from "./components/actionbutton/ActionIcon";
import DashboardSidebar from "../dashboardSidebar/sidebar";
import DashboardTopSection from "../dashboardTopSection";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import SchoolCalendarStudentListPage from "./components/SchoolCalendarStudentList";
import SchoolCalendarInfoPage from "./components/schoolCalendarInfo";

import {
  useGetInstitutionQuery,
  useGetInstitutionPeriodsQuery,
} from "../../../../../redux/features/institution/institutionApiSlice";
import TableComponent from "../../../../../component/table/table";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import SuccessScreen from "../../../../../component/successScreen/successScreen";

const SchoolCalendarList = () => {
  //   const { id } = useParams;
  const { param } = useParams();
  console.log(param);

  const navigate = useNavigate();

  const [showCreateCalendarCanvas, setShowCreateCalendarCanvas] =
    useState(false);
  const [calendarToEdit, setCalendarToEdit] = useState(null);

  const handleCloseCalendar = () => {
    setShowCreateCalendarCanvas(false);
    setCalendarToEdit(null);
  };

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  console.log(instId);
  //   console.log(id);

  const { data: institutionDetails } = useGetInstitutionQuery(instId);

  const params = {
    institutionId: instId,
    schoolYearId: param,
  };

  const {
    data: institutionPeriods,
    refetch: refetchPeriods,
    isLoading: periodsLoading,
    isSuccess: periodsSuccess,
  } = useGetInstitutionPeriodsQuery(params);

  console.log(institutionPeriods);

  const handleShowCalendar = () => {
    setShowCreateCalendarCanvas(true);
  };

  const columns = [
    {
      name: "schoolYear",
      label: "Year",
      options: {
        customBodyRender: (yearData) =>
          yearData && yearData.name ? yearData.name : "N/A",
      },
    },
    { name: "periodName", label: "Period" },
    { name: "startDate", label: "Start Date" },
    { name: "endDate", label: "End Date" },
    {
      name: "open",
      label: "Status",
      options: {
        customBodyRender: (open) => (open ? "Active" : "Inactive"),
      },
    },
    {
      name: "actions",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <ActionIcon
            actionsArr={[
              {
                label: "Edit",
                action: () => {
                  handleEdit(institutionPeriods[tableMeta.rowIndex]);
                  console.log(tableMeta);
                },
              },
              {
                label: "View Students",
                action: () =>
                  handleView(institutionPeriods[tableMeta.rowIndex]),
              },
            ]}
          />
        ),
      },
    },
  ];

  const handleView = (students) => {
    setIsSchoolCalendarStudentListViewed(!isSchoolCalendarStudentListViewed);
    setViewedSchoolCalendarStudentListDetails(students);
  };

  const handleEdit = (calendar) => {
    setCalendarToEdit(calendar);
    setShowCreateCalendarCanvas(true);
  };

  const [
    isSchoolCalendarStudentListViewed,
    setIsSchoolCalendarStudentListViewed,
  ] = useState(false);
  const [
    viewedSchoolCalendarStudentListDetails,
    setViewedSchoolCalendarStudentListDetails,
  ] = useState(null);

  useEffect(() => {
    if (institutionPeriods) {
      refetchPeriods();
    }
  }, [showCreateCalendarCanvas === false]);

  if (periodsLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div style={{ display: "flex" }}>
      <DashboardSidebar institutionDetails={institutionDetails} />

      <Container
        fluid
        className="bodycontainer"
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <DashboardTopSection />

        <div>
          {isSchoolCalendarStudentListViewed ? (
            <SchoolCalendarStudentListPage
              calendarStudentListDetails={
                viewedSchoolCalendarStudentListDetails
              }
              setIsSchoolCalendarStudentListViewed={
                setIsSchoolCalendarStudentListViewed
              }
            />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "20px 0 0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(-1)}
                >
                  <img src={backArrow} alt="Go back" />
                  <h6 style={{ marginBottom: 0 }}>School Year</h6>
                </div>

                <div>
                  <Button onClick={handleShowCalendar}>Create Calendar</Button>
                </div>
              </div>

              <section
                style={{
                  marginTop: "30px",
                }}
              >
                <TableComponent
                  data={institutionPeriods}
                  // data={columnDetails}
                  title=""
                  columns={columns}
                />
              </section>
            </>
          )}

          <Offcanvas
            show={showCreateCalendarCanvas}
            onHide={handleCloseCalendar}
            placement={"end"}
            backdrop={true}
            style={{ width: "100%", maxWidth: "500px" }}
          >
            <Offcanvas.Header closeButton>
              {!calendarToEdit ? (
                <Offcanvas.Title>Create School Calendar</Offcanvas.Title>
              ) : (
                <Offcanvas.Title>Edit School Calendar</Offcanvas.Title>
              )}
            </Offcanvas.Header>
            <Offcanvas.Body style={{ paddingTop: "0px" }}>
              <SchoolCalendarInfoPage calendarToEdit={calendarToEdit} />
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </Container>
    </div>
  );
};

export default SchoolCalendarList;
