import { useState, useEffect } from "react";
import "./institutionFeeSetup.css";
import alertIcon from "../../../../../../assets/svgs/alertIcon.svg";
// import Select from "react-dropdown-select";
import { Dropdown } from "react-bootstrap";
import { Table, Form, InputGroup, Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";
import actionIcon from "../../../../../../assets/svgs/institutionLevelActionIcon.svg";

import ModalComponent from "../../../../../../component/modal/modal";
import { useSelector, useDispatch } from "react-redux";
import {
  useGetAllInstitutionFeesQuery,
  useAddInstitutionFeeMutation,
  useGetInstitutionLevelsQuery,
  useGetInstitutionCategoriesQuery,
  useGetInstitutionFeeComponentQuery,
  useDeleteInstitutionFeeMutation,
  useUpdateInstitutionFeeMutation,
  useGetInstitutionFeeQuery,
  useCompleteInstitutionSetupMutation,
} from "../../../../../../redux/features/institution/institutionApiSlice";
import { updateSetupComplete } from "../../../../../../redux/features/auth/authSlice";
import ActionIcon from "../actionbutton/ActionIcon";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import { addTrailingCommas } from "../../../../../../utils/utils";
import ToastComponent from "../../../../../../component/toast/toast";

const tableHeaders = [
  "ID",
  "Fee Name",
  "Level",
  "Category",
  "Amount",
  "Remarks",
  "Compulsory",
  "Action",
];

const InstitutionFeeSetup = ({ setActiveStage, institutionDetails }) => {
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newData, setNewData] = useState(false);
  const [deletedElementID, setDeletedElementID] = useState(null);
  const [editedElement, setEditedElement] = useState(null);
  const [editingElement, setEditingElement] = useState(null);
  const [editingValue, setEditingValue] = useState({});
  const [editedCompulsory, setEditedCompulsory] = useState(null);
  const [elementToDelete, setElementToDelete] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsNames, setSelectedItemsNames] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingElement(null);
    setEditingValue({
      editedFeeComponentName: "",
      editedFeeComponentId: "",
      editedInstitutionCategoryName: "",
      // editedInstitutionCategoryId: "",
      editedInstitutionCategoryIds: [],
      editedInstitutionLevelName: "",
      editedInstitutionLevelId: "",
      editedAmount: "",
      editedRemark: "",
      // editedCompulsory: "",
    });
    setEditedCompulsory(null);
  };

  const [addInstitutionFee, { isLoading: addLoading }] =
    useAddInstitutionFeeMutation();
  const [deleteInstitutionFee, { isLoading: deleteLoading }] =
    useDeleteInstitutionFeeMutation();
  const [updateInstitutionFee, { isLoading: updateLoading }] =
    useUpdateInstitutionFeeMutation();
  const [completeSetup, { isLoading: completeLoading }] =
    useCompleteInstitutionSetupMutation();

  const dispatch = useDispatch();

  // const institutionDetails = useSelector(
  //   (state) => state.institution.institutionData
  // );

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  console.log(instId);

  const {
    data: feesData,
    refetch: refetchFees,
    isLoading: feesLoading,
  } = useGetAllInstitutionFeesQuery(instId);
  const { data: institutionLevels } = useGetInstitutionLevelsQuery(instId);
  const { data: institutionCategories } =
    useGetInstitutionCategoriesQuery(instId);
  const { data: institutionFeeComponents } =
    useGetInstitutionFeeComponentQuery(instId);

  const [newFeeData, setNewFeeData] = useState({
    feeComponentId: "",
    institutionLevelId: "",
    // institutionCategoryId: "",
    institutionCategoryIds: selectedItems,
    amount: "",
    remark: "",
    compulsory: "",
  });

  const {
    feeComponentId,
    // institutionCategoryId,
    institutionCategoryIds,
    institutionLevelId,
    amount,
    remark,
    compulsory,
  } = newFeeData;

  console.log(institutionCategoryIds);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setNewFeeData({ ...newFeeData, [name]: value });
    setEditingValue({ ...editingValue, [name]: value });

    if (name === "compulsory") {
      setEditedCompulsory(value);
    }
  };

  const {
    editedAmount,
    editedFeeComponentId,
    // editedInstitutionCategoryId,
    editedInstitutionCategoryIds,
    editedInstitutionLevelId,
    editedRemark,
  } = editingValue;

  console.log(newFeeData);
  console.log(editingValue);
  console.log(editingElement);
  console.log(editedCompulsory);

  const navigate = useNavigate();

  // console.log(feesData);

  const handleEditFee = (el) => {
    console.log("edit was clicked for ", el);
    setEditingElement(el);
    setShowModal(true);
    setEditingValue({
      editedFeeComponentId: el.feeComponent.id,
      editedFeeComponentName: el.feeComponent.name,
      editedInstitutionCategoryName: el.institutionCategory.name,
      // editedInstitutionCategoryId: el.institutionCategory.id,
      editedInstitutionCategoryIds: el.institutionCategory.ids,
      editedInstitutionLevelId: el.institutionLevel.id,
      editedInstitutionLevelName: el.institutionLevel.name,
      editedAmount: el.amount,
      editedRemark: el.remark,
      // editedCompulsory: el.compulsory,
    });
    setEditedCompulsory(el.compulsory);
    // put the id as selected
    setSelectedItems([el.institutionCategory.id]);
    setSelectedItemsNames([el.institutionCategory.name]);
  };

  const confirmDelete = (el) => {
    setElementToDelete(el);
    setShowConfirmDelete(true);
  };

  const handleDeleteFee = async () => {
    console.log("delete was clicked for ", elementToDelete);
    setShowConfirmDelete(false);

    try {
      await deleteInstitutionFee(elementToDelete?.id).unwrap();
      setDeletedElementID(elementToDelete.id);
    } catch (error) {
      console.log(error);
      // alert(error.data);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleCompleteSetupSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = await completeSetup(instId).unwrap();

      if (data) {
        console.log(data);
        dispatch(updateSetupComplete());
        navigate("/dashboard");
      }
    } catch (error) {
      console.log(error.data);
      // alert(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  console.log(editedCompulsory);

  const handleCreateOrUpdateLevel = async (e, type) => {
    e.preventDefault();

    if (type === "edit") {
      if (!editedAmount || !editedRemark || selectedItems.length < 1) {
        setIsFormNotFilled(true);

        setTimeout(() => {
          setIsFormNotFilled(false);
        }, 3000);

        return;
      }
      const parameters = {
        feeId: editingElement?.id,
        body: {
          // institutionId: instId,
          institutionLevelId: editedInstitutionLevelId,
          // institutionCategoryId: editedInstitutionCategoryId,
          institutionCategoryIds: selectedItems,
          feeComponentId: editedFeeComponentId,
          compulsory: editedCompulsory,
          remark: editedRemark,
          amount: editedAmount,
        },
        // body: {
        //   compulsory: editedCompulsory,
        //   remark: editedRemark,
        //   amount: editedAmount,
        // },
      };
      try {
        const res = await updateInstitutionFee(parameters);
        console.log(res);
        if (res) {
          setEditedElement(res);
        }
        setEditingValue({});
        setEditingElement(null);
        setEditedCompulsory(null);
        setShowModal(false);
        setNewFeeData({
          feeComponentId: "",
          // institutionCategoryId: "",
          institutionCategoryIds: [],
          institutionLevelId: "",
          amount: "",
          remark: "",
          compulsory: "",
        });
        setSelectedItems([]);
        setSelectedItemsNames([]);
        setSelectAll(false);
      } catch (error) {
        console.log(error);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
      return;
    } else {
      if (
        !feeComponentId ||
        // !institutionCategoryId ||
        selectedItems.length < 1 ||
        !institutionLevelId ||
        !amount ||
        !remark ||
        !compulsory
      ) {
        setIsFormNotFilled(true);

        setTimeout(() => {
          setIsFormNotFilled(false);
        }, 3000);

        return;
      }
      try {
        const parameters = {
          institutionId: instId,
          body: {
            ...newFeeData,
            institutionId: instId,
            institutionLevelId: +institutionLevelId,
            // institutionCategoryId: +institutionCategoryId,
            institutionCategoryIds: selectedItems,
            feeComponentId: +feeComponentId,
          },
        };

        // submit with institutionId also
        const data = await addInstitutionFee(parameters).unwrap();

        if (data) {
          console.log(data);
          setNewData(data);
        }
        setNewFeeData({
          feeComponentId: "",
          // institutionCategoryId: "",
          institutionCategoryIds: [],
          institutionLevelId: "",
          amount: "",
          remark: "",
          compulsory: "",
        });
        setSelectedItems([]);
        setSelectedItemsNames([]);
        setShowModal(false);
        setSelectAll(false);
      } catch (error) {
        console.log(error.data);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
    }
  };

  const handleItemClick = (obj) => {
    if (selectedItems.includes(obj.id)) {
      setSelectedItems(selectedItems.filter((i) => i !== obj.id));
      setSelectedItemsNames(selectedItemsNames.filter((i) => i !== obj.name));
    } else {
      setSelectedItems([...selectedItems, obj.id]);
      setSelectedItemsNames([...selectedItemsNames, obj.name]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
      setSelectedItemsNames([]);
    } else {
      const allItemIds = institutionCategories?.map((category) => category.id);
      setSelectedItems(allItemIds);
      setSelectedItemsNames(
        institutionCategories?.map((category) => category.name)
      );
    }
    setSelectAll(!selectAll);
  };

  console.log(selectedItems);
  console.log(feesData);

  useEffect(() => {
    if (feesData) {
      refetchFees();
    }
  }, [newData, deletedElementID, editedElement]);

  useEffect(() => {
    const buttonElement = document.getElementById("createFee");

    // buttonElement && buttonElement.dispatchEvent(new MouseEvent('mouseenter'));
    if (!feesData || feesData?.length === 0) {
      buttonElement &&
        buttonElement.dispatchEvent(
          new MouseEvent("mouseover", {
            view: window,
            bubbles: true,
            cancelable: true,
          })
        );
    }
  }, [feesData]);

  if (
    feesLoading ||
    addLoading ||
    updateLoading ||
    deleteLoading ||
    completeLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <div className="institutionFee">
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <h2>
        <b>Set Up Your Institution</b>
      </h2>
      <h5>Institution Fee</h5>

      {/* categories dropdowm */}

      {/* delete confirmation modal */}
      <ModalComponent
        showModal={showConfirmDelete}
        closeModal={() => setShowConfirmDelete(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img src={alertIcon} alt="alert Icon" style={{ margin: "0 auto" }} />
          <p
            style={{
              marginTop: "10px",
              marginBottom: "5px",
              fontWeight: "500",
              fontSize: 1.0 + "rem",
            }}
          >
            Are you sure you want to delete this fee?{" "}
          </p>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              gap: "10px",
              marginLeft: "30%",
            }}
          >
            <Button
              onClick={() => setShowConfirmDelete(false)}
              type="button"
              variant="outline-primary"
              style={{ borderColor: "#F14810", color: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                No
              </span>
            </Button>
            <Button
              onClick={() => handleDeleteFee()}
              type="button"
              variant="primary"
              style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Yes
              </span>
            </Button>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        title="Create Fee"
        showModal={showModal}
        closeModal={handleCloseModal}
      >
        {isFormNotFilled && (
          <span
            style={{
              color: "red",
              fontStyle: "italic",
              transition: "ease-in-out",
            }}
          >
            Please fill all fields
          </span>
        )}

        <form>
          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ marginBottom: "5px", fontWeight: "500" }}>
              Fee Component{" "}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </p>
            <select
              type="select"
              placeholder="Select Fee Component"
              // name="feeComponentId"
              // value={feeComponentId}
              // value={
              //   editingElement ? editingElement.feeComponentId : feeComponentId
              // }
              name={editingElement ? "editedFeeComponentId" : "feeComponentId"}
              value={editingElement ? editedFeeComponentId : feeComponentId}
              onChange={handleOnChange}
              style={{
                borderRadius: "5px",
                backgroundColor: "#dddddd",
                width: "100%",
              }}
            >
              <option value="" defaultValue>
                Select Fee Component
              </option>
              {institutionFeeComponents &&
                institutionFeeComponents.map(({ name, id }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
            </select>
          </div>
          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ marginBottom: "5px", fontWeight: "500" }}>
              Institution Level{" "}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </p>
            <select
              type="select"
              placeholder="Select Institution Level"
              name={
                editingElement
                  ? "editedInstitutionLevelId"
                  : "institutionLevelId"
              }
              value={
                editingElement ? editedInstitutionLevelId : institutionLevelId
              }
              // value={
              //   editingElement ? editedInstitutionLevelName : institutionLevelId
              // }
              onChange={handleOnChange}
              style={{
                borderRadius: "5px",
                backgroundColor: "#dddddd",
                width: "100%",
              }}
            >
              <option value="" defaultValue>
                Select Institution Level
              </option>
              {institutionLevels &&
                institutionLevels.map(({ name, id }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
            </select>
          </div>
          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <p style={{ marginBottom: "5px", fontWeight: "500" }}>
              Institution Category{" "}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </p>
            {/* <select
              type="select"
              placeholder="Select Institution Category"
              name={
                editingElement
                  ? "editedInstitutionCategoryId"
                  : "institutionCategoryId"
              }
              value={
                editingElement
                  ? editedInstitutionCategoryId
                  : institutionCategoryId
              }
              onChange={handleOnChange}
              style={{
                borderRadius: "5px",
                backgroundColor: "#dddddd",
                width: "100%",
              }}
            >
              <option value="" defaultValue>
                Select Level Category
              </option>
              {institutionCategories &&
                institutionCategories.map(({ name, id }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
            </select> */}

            <label
              style={{
                width: "100%",
                backgroundColor: "rgb(221, 221, 221)",
                borderRadius: "5px",
                padding: "10px",
                cursor: "pointer",
              }}
              onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
            >
              {/* {selectedItems.length > 0
                ? selectedItemsNames.join(", ")
                : "Select Level Category"} */}

              {selectedItems.length > 0 ? (
                <>
                  {selectedItemsNames.slice(0, 3).join(", ")}
                  {selectedItemsNames.length > 3 && " ..."}
                </>
              ) : (
                "Select Level Category"
              )}
            </label>

            {showCategoryDropdown && (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  zIndex: "10",
                  transition: "all",
                  transitionTimingFunction: "ease-in-out",
                  transitionDuration: "0.3s",
                  backgroundColor: "white",
                  width: "100%",
                  border: "1px solid rgb(221, 221, 221)",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                <label style={{ display: "flex" }}>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    style={{
                      marginRight: "5px",
                      width: "auto",
                      appearance: "auto",
                    }}
                  />
                  Select All
                </label>
                {institutionCategories &&
                  institutionCategories.map(({ name, id }) => (
                    <label style={{ display: "flex" }} key={id}>
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(id)}
                        onChange={(e) => handleItemClick({ name, id })}
                        style={{
                          marginRight: "5px",
                          width: "auto",
                          appearance: "auto",
                        }}
                      />
                      {name}
                    </label>
                  ))}
              </div>
            )}
          </div>
          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ marginBottom: "5px", fontWeight: "500" }}>
              Amount{" "}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </p>
            <input
              type="number"
              placeholder="e.g 10000, 20000, etc"
              // name="amount"
              // value={amount}
              // value={editingElement ? editingElement.amount : amount}
              name={editingElement ? "editedAmount" : "amount"}
              value={editingElement ? editedAmount : amount}
              onChange={handleOnChange}
              style={{
                borderRadius: "5px",
                backgroundColor: "#dddddd",
                width: "100%",
              }}
            />
          </div>
          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ marginBottom: "5px", fontWeight: "500" }}>
              Remarks{" "}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </p>
            <input
              type="text"
              placeholder="e.g School fee payment for SS1"
              // name="remark"
              // value={editingElement ? editingElement.remark : remark}
              name={editingElement ? "editedRemark" : "remark"}
              value={editingElement ? editedRemark : remark}
              onChange={handleOnChange}
              style={{
                borderRadius: "5px",
                backgroundColor: "#dddddd",
                width: "100%",
              }}
            />
          </div>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Payment compulsory <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <InputGroup className="mb-3" onChange={handleOnChange}>
              <div key={`inline-radio`}>
                <Form.Check
                  inline
                  label="Compulsory"
                  name="compulsory"
                  value="true"
                  // name={editingElement ? "editedCompulsory" : "compulsory"}
                  // value={editingElement ? editedCompulsory : "true"}
                  checked={
                    editingElement
                      ? editedCompulsory == true || editedCompulsory == "true"
                      : null
                  }
                  onChange={handleOnChange}
                  type="radio"
                  id={`inline-radio-1`}
                  size={25}
                  style={{ variant: "outline-warning" }}
                />
                <Form.Check
                  inline
                  label="Optional"
                  name="compulsory"
                  value="false"
                  // name={editingElement ? "editedCompulsory" : "compulsory"}
                  // value={editingElement ? editedCompulsory : "false"}
                  checked={
                    editingElement
                      ? editedCompulsory == false || editedCompulsory == "false"
                      : null
                  }
                  onChange={handleOnChange}
                  type="radio"
                  id={`inline-radio-2`}
                  size={25}
                  style={{ variant: "outline-warning" }}
                />
              </div>
            </InputGroup>
          </Form.Group>

          <div style={{ marginTop: "30px" }}>
            {editingElement ? (
              <Button
                onClick={(e) => handleCreateOrUpdateLevel(e, "edit")}
                variant="primary"
                style={{
                  backgroundColor: "#F14810",
                  borderColor: "#F14810",
                  float: "left",
                }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Edit Fee
                </span>
              </Button>
            ) : (
              <Button
                onClick={(e) => handleCreateOrUpdateLevel(e, "")}
                variant="primary"
                style={{
                  backgroundColor: "#F14810",
                  borderColor: "#F14810",
                  float: "left",
                }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Save Fee
                </span>
              </Button>
            )}
          </div>
        </form>
      </ModalComponent>

      {feesData && feesData.length > 0 && (
        <Table
          // responsive
          striped
          size="sm"
          style={{
            fontFamily: "jost, sans-serif",
            fontSize: "14px",
          }}
        >
          <thead>
            <tr align="center">
              {tableHeaders.map((header, i) => (
                <th key={i}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {feesData &&
              feesData.map(
                (
                  {
                    id,
                    amount,
                    institutionCategory,
                    institutionLevel,
                    feeComponent,
                    compulsory,
                    remark,
                  },
                  i
                ) => (
                  <tr key={i} className={i % 2 === 0 ? "even-row" : "odd-row"}>
                    <td align="center">{id}</td>
                    <td align="center">{feeComponent?.name}</td>
                    <td align="center">{institutionLevel?.name}</td>
                    <td align="center">{institutionCategory?.name}</td>
                    <td align="center">{addTrailingCommas(amount)}</td>
                    <td align="center">{remark}</td>
                    <td style={{ color: "blue" }} align="center">
                      {compulsory ? "Compulsory" : "Optional"}
                    </td>
                    <td align="center">
                      <ActionIcon
                        actionsArr={[
                          {
                            label: "Edit",
                            action: () =>
                              handleEditFee({
                                id,
                                amount,
                                institutionCategory,
                                institutionLevel,
                                feeComponent,
                                compulsory,
                                remark,
                              }),
                          },
                          {
                            label: "Delete",
                            action: () =>
                              confirmDelete({
                                id,
                                amount,
                                institutionCategory,
                                institutionLevel,
                                feeComponent,
                                compulsory,
                                remark,
                              }),
                          },
                        ]}
                      />
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </Table>
      )}

      <div
        className="toggle-btn-container"
        style={{ float: "right", marginTop: "20px" }}
      >
        <OverlayTrigger
          placement="top"
          delay={{ show: 150, hide: 300 }}
          overlay={
            <Tooltip id="button-tooltip">
              Click this button to create a fee. Enter the amount of the fee and
              select its appropriate level and category.
            </Tooltip>
          }
        >
          <Button
            className="button"
            size="md"
            style={{
              backgroundColor: `${
                !feesData || feesData?.length === 0 ? "#F14810" : "#777777"
              }`,
              color: "#ffffff",
              border: "none",
              outline: "none",
            }}
            onClick={() => setShowModal(true)}
            id="createFee"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.3 + "rem",
                marginRight: 0.3 + "rem",
              }}
            >
              Create Fee
            </span>
          </Button>
        </OverlayTrigger>
      </div>

      <div className="btns-container">
        <div style={{ display: "flex", gap: 1.1 + "rem" }}>
          <Button
            onClick={() => setActiveStage("Fee Component")}
            variant="outline-primary"
            style={{ borderColor: "#F14810", color: "#F14810" }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              Previous
            </span>
          </Button>

          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={
              <Tooltip id="button-tooltip">
                Create a fee then click this button to submit
              </Tooltip>
            }
          >
            <Button
              onClick={
                !feesData || feesData?.length === 0
                  ? null
                  : (e) => handleCompleteSetupSubmit(e)
              }
              variant="primary"
              style={{
                backgroundColor: `${
                  !feesData || feesData?.length === 0 ? "#FFCAB8" : "#F14810"
                }`,
                borderColor: `${
                  !feesData || feesData?.length === 0 ? "#FFCAB8" : "#F14810"
                }`,
                cursor: `${
                  !feesData || feesData?.length === 0
                    ? "not-allowed"
                    : "pointer"
                }`,
                float: "left",
              }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Submit
              </span>
            </Button>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
};

export default InstitutionFeeSetup;
