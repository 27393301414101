// import Button from "../../../../../component/button/button";
import { Button } from "react-bootstrap";
import institutionLogo from "../../../../../../assets/svgs/institutionPlaceholderLogo.svg";
import { SketchPicker } from "react-color";
import {
  useEditInstitutionMutation,
  useGetInstitutionDetailQuery,
  useDownloadLogoQuery,
} from "../../../../../../redux/features/superAdmin/adminApiSlice";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import ToastComponent from "../../../../../../component/toast/toast";
import ModalComponent from "../../../../../../component/modal/modal";
import successGIF from "../../../../../../assets/svgs/successGIF.gif";

const SchoolBranding = ({ Id }) => {
  const dispatch = useDispatch();

  const {
    data: institutionDetails,
    isLoading: detailsLoading,
    refetch: refetchDetails,
  } = useGetInstitutionDetailQuery(Id);

  console.log(institutionDetails);

  const { data: apiInstitutionLogo, isLoading: logoLoading } =
    useDownloadLogoQuery(Id);
  console.log(apiInstitutionLogo);

  // remove # from the brandColour
  let newColor = institutionDetails?.brandColour?.replace("#", "");
  // console.log(newColor);

  const handleCloseModal = () => {
    setShowModal(false);
    refetchDetails();
  };

  const [chosenColor, setChosenColor] = useState(
    // institutionDetails?.brandColour
    newColor
  );

  const [showModal, setShowModal] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // console.log(chosenColor)

  const [chosenImage, setChosenImage] = useState(
    apiInstitutionLogo ? apiInstitutionLogo?.logo : ""
  );

  // const [updateInstitutionDetails, { isLoading: updateLoading }] =
  //     useUpdateInstitutionDetailsMutation();

  const [updateInstitutionDetails, { isLoading: updateLoading }] =
    useEditInstitutionMutation();

  const [appearanceDetails, setAppearanceDetails] = useState({
    // brandColour: institutionDetails?.brandColour,
    brandColour: institutionDetails.brandColour
      ? institutionDetails?.brandColour
      : chosenColor,
    logo: apiInstitutionLogo?.logo,
  });

  const { brandColour, logo } = appearanceDetails;

  // console.log(logo);

  const handleColorChange = (color) => {
    setChosenColor(color?.hex);
    // institutionDetails.brandColour = color?.hex;
    newColor = color?.hex;
  };

  const handleOnImageChange = (e) => {
    let selectedFile = e.target.files;

    if (selectedFile.length > 0) {
      let actualFile = selectedFile[0];
      let fileName = actualFile.name;
      const reader = new FileReader();

      reader.onload = (event) => {
        const imageDetails = {
          name: fileName,
          imageUrl: event.target.result,
        };
        setChosenImage(event.target.result);
      };
      reader.readAsDataURL(actualFile);
    }
  };

  // console.log(chosenImage);

  // dispatch(storeLogoChange(showModal));

  const handleSave = async () => {
    const params = {
      institutionId: Id,
      body: {
        // id: Id,
        brandColour: chosenColor,
        logo: chosenImage,
      },
    };

    console.log(params);

    try {
      const res = await updateInstitutionDetails(params).unwrap();
      console.log(res);
      // dispatch(
      //     storeBrandingDetails({
      //         institutionBranding: { brandColour: chosenColor, logo: chosenImage },
      //     })
      // );
      setShowModal(true);
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  useEffect(() => {
    setChosenColor(newColor);

    if (apiInstitutionLogo) {
      setChosenImage(apiInstitutionLogo?.logo);
    }
  }, []);

  if (logoLoading || updateLoading || detailsLoading) {
    return <OnboardingLoader />;
  }
  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      {/* success modal */}
      <ModalComponent showModal={showModal} closeModal={handleCloseModal}>
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!</h3>
          <p style={{ fontSize: "18px" }}>
            School Branding updated successfully
          </p>
        </div>
      </ModalComponent>

      <div
        style={{
          borderTop: "2px solid #F5F5F5",
          paddingTop: "30px",
        }}
      >
        <form
          style={
            {
              // display: "flex",
              // alignItems: "flex-start",
              // justifyContent: "space-between",
              // gap: "40px",
            }
          }
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              flexShrink: 0,
            }}
          >
            <div className="logo-container">
              {/* <img src={institutionLogo} alt="institution Placeholder Logo" /> */}
              <img src={chosenImage} alt="Logo" />
            </div>
            <label
              htmlFor="uploadLogo"
              style={{
                backgroundColor: "#e7e7e7",
                padding: "5px 15px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Upload Logo
            </label>
            <input
              type="file"
              id="uploadLogo"
              style={{ visibility: "hidden", display: "none" }}
              name="institutionBranding.logo"
              // value={institutionBranding.logo}
              onChange={handleOnImageChange}
            />
          </div>
          <div
            style={{
              paddingTop: "30px",
              display: "flex",
              // justifyContent: "space-between",
              alignContent: "center",
              gap: 20,
            }}
          >
            <div>
              <span>Select Color</span>
              <SketchPicker
                color={chosenColor}
                value={institutionDetails.brandColour}
                name="institutionDetails.brandColour"
                onChange={handleColorChange}
                onClick={() => handleColorChange(chosenColor)}
              />
            </div>

            <div
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "100%",
                border: "1px solid",
                backgroundColor: `${
                  brandColour ? institutionDetails?.brandColour : chosenColor
                }`,
                marginTop: "30px",
              }}
            ></div>
            <span style={{ marginTop: "30px" }}>School Color</span>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingTop: "30px",
            }}
          >
            <Button
              className="button"
              size="md"
              style={{
                backgroundColor: "#F14810",
                color: "#ffffff",
                border: "none",
                outline: "none",
              }}
              onClick={handleSave}
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.3 + "rem",
                  marginRight: 0.3 + "rem",
                }}
              >
                Save Changes
              </span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SchoolBranding;
