import { useState, useEffect } from "react";
// import eyeActionIcon from "../../../../assets/svgs/eyeActionIcon.svg";
import { Container } from "react-bootstrap";
import DashboardTopSection from "../../../components/dashboardTopSection/index";
import DashboardSidebar from "../../../components/dashboardSidebar/sidebar";
import TableComponent from "../../../../../../component/table/table";
import backArrow from "../../../../../../assets/svgs/backArrow.svg";
// import "./settlement.css";
// import { useNavigate } from "react-router-dom";
import { addTrailingCommas } from "../../../../../../utils/utils";
import { useSelector } from "react-redux";
import { useGetSettlementHistoryQuery } from "../../../../../../redux/features/institution/institutionApiSlice";
import InstitutionAccountPage from "./institutionAccount";
import { useNavigate } from "react-router-dom";

const SettlementPage = ({ historyDetails, setIsHistoryViewed }) => {
  //const [openInstitionAccountPage, setOpenInstitionAccountPage] = useState();
  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  //const history = useHistory();
  const handleBackToCollectionAccountPage = () => {
    setIsHistoryViewed(false);
    console.log("VIEWED", setIsHistoryViewed);
    //window.history.back()
  }

  const { data: settlementHistory } = useGetSettlementHistoryQuery(instId, {
    skip: !instId,
  });
  console.log("HIST ",settlementHistory);

  const columns = [
    // { name: "id", label: "ID" },
    {
      name: "date",
      label: "Date",
      options: {
        customBodyRender: (date) => (date ? date?.split("T")[0] : "N/A"),
      },
    },
    {
      name: "settlementAmount",
      label: "Settlement Amount",
      options: {
        customBodyRender: (amount) => (amount ? "₦ " + addTrailingCommas(amount) : 0),
      },
    },
    {
      name: "commission",
      label: "Processing Fee",
      options: {
        // customBodyRender: (amount) => (amount ? addTrailingCommas(amount) : 0),
        customBodyRender: (value, tableMeta) => {
          const { commission, commissionType } =
            settlementHistory.settlements[tableMeta.rowIndex];
          return `${commissionType}` !== "RATE"
            ? "₦ " + `${commission}`
            : `${commission} %`;
        },
      },
    },
    // {
    //     name: "commissionType",
    //     label: "Commission Type",
    //     options: {
    //         customBodyRender: (commissionType) => (commissionType === "RATE" ? "₦" : "%"),
    //     },
    // },
    {
      name: "commissionAmount",
      label: "Processing Fee Amount",
      options: {
        customBodyRender: (amount) => (amount ? "₦ " + addTrailingCommas(amount) : 0),
      },
    },
    { name: "bankName", label: "Bank Name" },
    { name: "accountName", label: "Account Name" },
    { name: "accountNumber", label: "Account Number" },
    { name: "narration", label: "Narration" },
    { name: "status", label: "Status" },
  ];


  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          cursor: "pointer",
          marginBottom: "10px",
        }}
        //onClick={() => setIsHistoryViewed(true)} //handleBackToCollectionAccountPage
        onClick={() => handleBackToCollectionAccountPage()}
      >
        <img src={backArrow} alt="back arrow" />
        <h5 style={{ marginBottom: 0, fontSize: "14px", fontWeight: 600 }}>
          Institution Account
        </h5>
      </div>

      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <div
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <div>
              <h5
                style={{
                  marginBottom: "0px",
                  fontWeight: "500",
                }}
              >
                ₦ {addTrailingCommas(settlementHistory?.totalSettlementAmount)}
              </h5>
              <p style={{ marginBottom: "0px", color: "#6A6A6A" }}>
                Total Settlement Amount
              </p>
            </div>
            <div>
              <h5
                style={{
                  marginBottom: "0px",
                  fontWeight: "500",
                }}
              >
                ₦ {addTrailingCommas(settlementHistory?.totalCommissionAmount)}
              </h5>
              <p style={{ marginBottom: "0px", color: "#6A6A6A" }}>
                Total Processing Fee Amount
              </p>
            </div>
          </div>
        </div>

        {/* <div
                    style={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                    }}
                >
                    <h5
                        style={{
                            marginBottom: "0px",
                            fontWeight: "500",
                        }}
                    >
                        ₦ 20,000
                    </h5>
                    <p style={{ marginBottom: "0px", color: "#6A6A6A" }}>
                        Total Commission Amount
                    </p>
                </div> */}

        <TableComponent
          data={settlementHistory?.settlements}
          title="Settlement History"
          columns={columns}
        />
      </div>
    </>
  );
};

export default SettlementPage;
