import React, { useState } from 'react'
import AboutTheSchool from './components/aboutTheSchool'
import SchoolBranding from './components/schoolbranding'
import ContactPerson from './components/contactPerson';
import UploadDocuments from './components/uploadDocuments';


const RegisterSchoolStepper = () => {

  const [activeStage, setActiveStage] = useState("about school")

  return (
    <div>
      <div>
        {activeStage === "about school" && (
          <AboutTheSchool setActiveStage={setActiveStage} />
        )}
        {activeStage === "school branding" && (
          <SchoolBranding setActiveStage={setActiveStage} />
        )}
        {activeStage === "contact person" && (
          <ContactPerson setActiveStage={setActiveStage} />
        )}
        {activeStage === "upload documents" && (
          <UploadDocuments setActiveStage={setActiveStage} />
        )}
      </div>
    </div>
  )
}

export default RegisterSchoolStepper