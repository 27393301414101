import { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";

import ModalComponent from "../../../../../component/modal/modal";
import alertIcon from "../../../../../assets/svgs/alertIcon.svg";
import { useSelector } from "react-redux";
import {
  useAddInstitutionFeeComponentMutation,
  useUpdateInstitutionFeeComponentMutation,
  useGetInstitutionFeeComponentQuery,
  useDeleteInstitutionFeeComponentMutation,
} from "../../../../../redux/features/institution/institutionApiSlice";
import ActionIcon from "../../../../../component/actionbutton/ActionIcon";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import ToastComponent from "../../../../../component/toast/toast";

const tableHeaders = ["ID", "Name", "Code", "Action"];

const InstitutionFeeComponent = () => {
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showFeeComponents, setShowFeeComponents] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newData, setNewData] = useState(null);
  const [deletedElementID, setDeletedElementID] = useState(null);
  const [editedElement, setEditedElement] = useState(null);
  const [editingElement, setEditingElement] = useState(null);
  const [editingValue, setEditingValue] = useState("");
  const [elementToDelete, setElementToDelete] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingElement(null);
    setEditingValue("");
  };

  const [addInstitutionFeeComponent, { isLoading: addLoading }] =
    useAddInstitutionFeeComponentMutation();
  const [updateInstitutionFeeComponent, { isLoading: updateLoading }] =
    useUpdateInstitutionFeeComponentMutation();
  const [deleteInstitutionFeeComponent, { isLoading: deleteLoading }] =
    useDeleteInstitutionFeeComponentMutation();

  // const institutionDetails = useSelector(
  //   (state) => state.institution.institutionData
  // );

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const {
    data: feeComponentData,
    refetch: feeComponentRefetch,
    isLoading: feesLoading,
  } = useGetInstitutionFeeComponentQuery(instId);

  const handleEditFeeComp = (el) => {
    console.log("edit was clicked for ", el);
    setEditingElement(el);
    setShowModal(true);
    setEditingValue(el.name);
  };

  const confirmDelete = (el) => {
    setElementToDelete(el);
    setShowConfirmDelete(true);
  };

  const handleDeleteFeeComp = async () => {
    console.log("delete was clicked for ", elementToDelete);
    setShowConfirmDelete(false);

    try {
      const parameters = {
        feeCompId: elementToDelete?.id,
        body: { name: elementToDelete.name },
      };
      await deleteInstitutionFeeComponent(parameters).unwrap();
      setDeletedElementID(elementToDelete.id);
    } catch (error) {
      console.log(error);
      // alert(error.data);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const [newFeeComponentData, setNewFeeComponentData] = useState({
    name: "",
  });

  const { name } = newFeeComponentData;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setNewFeeComponentData({ ...newFeeComponentData, [name]: value });
    setEditingValue(value);
  };

  const handleCreateOrUpdateFeeComp = async (e, type) => {
    e.preventDefault();

    if (type === "edit") {
      if (editingValue === "") {
        setIsFormNotFilled(true);

        setTimeout(() => {
          setIsFormNotFilled(false);
        }, 3000);

        return;
      }
      const parameters = {
        feeCompId: editingElement?.id,
        body: { name: editingValue },
      };
      try {
        const res = await updateInstitutionFeeComponent(parameters);
        console.log(res);
        if (res) {
          setEditedElement(res);
          setShowModal(false);
          setEditingValue("");
        }
        setNewFeeComponentData({
          name: "",
        });
        setEditingElement(null);
      } catch (error) {
        console.log(error);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
      return;
    } else {
      if (name === "") {
        setIsFormNotFilled(true);

        setTimeout(() => {
          setIsFormNotFilled(false);
        }, 3000);

        return;
      }
      try {
        console.log(newFeeComponentData);
        const obj = { institutionId: instId, body: newFeeComponentData };

        const data = await addInstitutionFeeComponent(obj).unwrap();

        if (data) {
          setNewData(data);
        }
        setNewFeeComponentData({
          name: "",
        });
        setShowModal(false);
      } catch (error) {
        console.log(error.data);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
    }
  };

  useEffect(() => {
    if (feeComponentData) {
      feeComponentRefetch();
    }
  }, [newData, deletedElementID, editedElement]);

  if (feesLoading || addLoading || deleteLoading || updateLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          marginBottom: "10px",
        }}
      >
        <h6 style={{ fontSize: "14px", fontWeight: 600, marginBottom: "0px" }}>
          Fee Component
        </h6>
        <Button
          className="button"
          size="md"
          style={{
            backgroundColor: "#F14810",
            color: "#ffffff",
            border: "none",
            outline: "none",
          }}
          onClick={() => setShowModal(true)}
          title="Click this button to create a fee component"
        >
          <span
            style={{
              fontSize: 0.8 + "rem",
              marginLeft: 0.3 + "rem",
              marginRight: 0.3 + "rem",
            }}
          >
            Create Fee Component
          </span>
        </Button>
      </div>

      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "8px",
          padding: "15px",
          height: "95%",
        }}
      >
        {/* delete confirmation modal */}
        <ModalComponent
          showModal={showConfirmDelete}
          closeModal={() => setShowConfirmDelete(false)}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={alertIcon}
              alt="alert Icon"
              style={{ margin: "0 auto" }}
            />
            <p
              style={{
                marginTop: "10px",
                marginBottom: "5px",
                fontWeight: "500",
                fontSize: 1.0 + "rem",
              }}
            >
              Are you sure you want to delete this fee component?{" "}
            </p>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                gap: "10px",
                marginLeft: "30%",
              }}
            >
              <Button
                onClick={() => setShowConfirmDelete(false)}
                type="button"
                variant="outline-primary"
                style={{ borderColor: "#F14810", color: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  No
                </span>
              </Button>
              <Button
                onClick={() => handleDeleteFeeComp()}
                type="button"
                variant="primary"
                style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Yes
                </span>
              </Button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          title="Create Fee Component"
          showModal={showModal}
          closeModal={handleCloseModal}
        >
          <form>
            <div style={{ marginBottom: "15px" }}>
              <p style={{ marginBottom: "5px", fontWeight: "500" }}>
                Fee Name{" "}
                <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
              </p>
              <input
                type="text"
                placeholder="Enter Fee Name (e.g Tuition, Uniform)"
                name="name"
                // value={name}
                value={editingElement ? editingValue : name}
                onChange={handleOnChange}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#dddddd",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginTop: "30px" }}>
              {editingElement ? (
                <Button
                  onClick={(e) => handleCreateOrUpdateFeeComp(e, "edit")}
                  variant="primary"
                  style={{
                    backgroundColor: "#F14810",
                    borderColor: "#F14810",
                    float: "left",
                  }}
                  className="button"
                  size="md"
                >
                  <span
                    style={{
                      fontSize: 0.8 + "rem",
                      marginLeft: 0.5 + "rem",
                      marginRight: 0.5 + "rem",
                    }}
                  >
                    Edit Fee Component
                  </span>
                </Button>
              ) : (
                <Button
                  onClick={(e) => handleCreateOrUpdateFeeComp(e, "")}
                  variant="primary"
                  style={{
                    backgroundColor: "#F14810",
                    borderColor: "#F14810",
                    float: "left",
                  }}
                  className="button"
                  size="md"
                >
                  <span
                    style={{
                      fontSize: 0.8 + "rem",
                      marginLeft: 0.5 + "rem",
                      marginRight: 0.5 + "rem",
                    }}
                  >
                    Save Fee Component
                  </span>
                </Button>
              )}
            </div>
          </form>
        </ModalComponent>

        {feeComponentData?.length > 0 ? (
          <Table
            // responsive
            striped
            size="sm"
            style={{
              fontFamily: "jost, sans-serif",
              fontSize: "14px",
            }}
          >
            <thead>
              <tr align="center">
                <th>Fee Component</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {feeComponentData?.map((feeComponent, i) => (
                <tr key={i} className={i % 2 === 0 ? "even-row" : "odd-row"}>
                  <td align="center">{feeComponent.name}</td>
                  <td align="center">
                    <ActionIcon
                      actionsArr={[
                        {
                          label: "Edit",
                          action: () => handleEditFeeComp(feeComponent),
                        },
                        // {
                        //     label: "Delete",
                        //     action: () => confirmDelete(feeComponent),
                        // },
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <span style={{ fontStyle: "italic", marginBottom: "20px" }}>
            Nothing to show, create a fee component
          </span>
        )}
      </div>
    </>
  );
};

export default InstitutionFeeComponent;
