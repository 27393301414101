import "./superAdminDashboard.css";

import SuperAdminDashBoardPage from "./components/dashboard/dashboard";
import DashboardSidebar from "./components/dashboardSideBar/sidebar";
import DashboardTopSection from "./components/dashboardTopSection";
import { Container } from "react-bootstrap";

const SuperAdminDashboard = () => {
  return (
    // <div className="super-admin no-scrollbar">
    //   <div>
    //     <DashboardSidebar />
    //   </div>

    //   <div>
    //     <DashboardTopSection />

    //     <SuperAdminDashBoardPage />
    //   </div>
    // </div>

    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />
        <SuperAdminDashBoardPage />
      </Container>
    </Container>
  );
};

export default SuperAdminDashboard;
