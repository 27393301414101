import React from 'react'

const DeleteIcon = ({stroke}) => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1621 16C14.1621 16.7956 13.846 17.5587 13.2834 18.1213C12.7208 18.6839 11.9578 19 11.1621 19H4.16211C3.36646 19 2.6034 18.6839 2.04079 18.1213C1.47818 17.5587 1.16211 16.7956 1.16211 16V4H0.162109V1H4.66211L5.66211 0H9.66211L10.6621 1H15.1621V4H14.1621V16ZM2.16211 4V16C2.16211 16.5304 2.37282 17.0391 2.7479 17.4142C3.12297 17.7893 3.63168 18 4.16211 18H11.1621C11.6925 18 12.2013 17.7893 12.5763 17.4142C12.9514 17.0391 13.1621 16.5304 13.1621 16V4H2.16211ZM14.1621 3V2H10.1621L9.16211 1H6.16211L5.16211 2H1.16211V3H14.1621ZM4.16211 6H5.16211V16H4.16211V6ZM10.1621 6H11.1621V16H10.1621V6Z"
        fill={stroke ? stroke : "black"}
      />
    </svg>
  );
}

export default DeleteIcon