import React, { useEffect, useState } from "react";
import { Container, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
// import SliderItems from '../../../component/SliderItems.js';
// import Button from "../../../component/button/button.js";
// import logo from "../../../assets/svgs/logo.svg"
import "./landingPage.css";
import Welcome from "../../../assets/svgs/welcome.gif";
import otpconfirm from "../../../assets/svgs/OTP-confirm.svg"
// import { useGetInstitutionQuery } from "../../../redux/features/institution/institutionApiSlice";
import { useLoginMutation } from "../../../redux/features/auth/authApiSlice";
import { userInfo } from "../../../redux/features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import OnboardingLoader from "../../../component/onboardingLoader/onboardingLoader";

const LandingPage = () => {
  const id = useSelector((state) => state.auth.userInfo.institutionId);
  // const [pageToGo, setPageToGo] = useState("/setup-institution")
  // const [pageToGo, setPageToGo] = useState("/dashboard")
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // const { data: institutionInfo } = useGetInstitutionQuery(id)
  // console.log(institutionInfo)

  const date = new Date(); 
  const hour = date.getHours();

  // console.log(institutionInfo?.status);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const aboutSchoolInfo = useSelector((state) => state.auth.aboutSchoolInfo);

  // const [login, { isLoading, isSuccess, isError, error }] = useLoginMutation();

  // const { email, password } = aboutSchoolInfo;

  // const handleOnSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const userData = await login({
  //       email,
  //       password,
  //     }).unwrap();

  //     // setToastMessage("Login successful!");
  //     // setShowToast(true);

  //     if (userData) {
  //       dispatch(userInfo(userData));
  //       navigate("/dashboard");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     // setErrorMessage(error?.data?.detail)
  //     setShowToast(true);
  //     if (error?.data?.detail) {
  //       setToastMessage(error.data.detail);
  //     } else {
  //       // other errors
  //       setToastMessage(error.error);
  //     }
  //   }
  // };

  // if (isLoading) {
  //   return <OnboardingLoader />;
  // }

  // useEffect(() => {
  //   if (institutionInfo?.status === "PENDING") {
  //     setPageToGo("/setup-institution");
  //   } else if (institutionInfo?.status === "APPROVED") {
  //     setPageToGo("/setup-institution/continue");
  //   } else if (institutionInfo?.status === "SUBMITTED") {
  //     setPageToGo("/setup-institution/validation");
  //   } else if (institutionInfo?.status === null) {
  //     setPageToGo("/setup-institution");
  //   }
  // }, [institutionInfo])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // padding: "30px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <>
        <Container fluid className="landing">
          <center className="messagebody">
            <Image
              src={otpconfirm}
              style={{
                width: 12 + "rem",
                borderRadius: 100 + "%",
                marginBottom: "30px",
              }}
              fluid
            />

            <div>
              <h3>
                {hour > 12 ? hour >= 16 ? <b>Good Evening!!!</b> : <b>Good Afternoon!!!</b> : <b>Good Morning!!!</b>}
                {/* <b>Good day!!!</b> */}
              </h3>
              <p>
                Email validation successful. Click the button to proceed to
                dashboard
              </p>
            </div>

            {/* <Link to={`${handleOnSubmit}`}> */}
            <Link to="/dashboard">
              <Button
                variant="primary"
                style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.7 + "rem",
                    marginRight: 0.7 + "rem",
                  }}
                >
                  Proceed
                </span>
              </Button>
            </Link>
          </center>
        </Container>
      </>
    </div>
  );
};

export default LandingPage;
