
const HelpVideoDisplayPage = ({ videoId, videoTitle }) => {
    return (
        <>
            <h6 style={{ fontSize: "14px", fontWeight: 600, marginBottom: "20px" }}>
                {videoTitle}
            </h6>
            <div className="embed-responsive embed-responsive-16by9">               
                <iframe className="embed-responsive-item w-100" height="460" src={`https://www.youtube.com/embed/${videoId}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
                </iframe>
            </div>
        </>
    );
}

export default HelpVideoDisplayPage;