import React, { useEffect } from 'react'
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

import howitworkimageone from '../assets/images/howitworkimageone.svg';
import howitworkimagetwo from '../assets/images/howitworkimagetwo.svg';
import howitworkimagethree from '../assets/images/howitworkimagethree.svg';

function HowitWork() {



    const location = useLocation();

    useEffect(() => {
        const howitwork = document.getElementById('howitwork');

        if (howitwork && location.state?.scrollToSection) {
            howitwork.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location.state?.scrollToSection]);


    return (
        <Container fluid id="howitwork" className="Howitwork">
            <Row>
                <center>
                    <h2 className="headertext" style={{ color: '#F14810' }}><b>How it works</b></h2>
                    <p className="whyadeptparagraph">Learn how to get started with our platform and onboard your school effortlessly.
                    </p>
                </center>
            </Row>


            <Row className="processsection">
                <Col className="order-sm-2" xs={12} md={6} lg={6} style={{ alignItems: 'center', justifyContent: 'center', }}>
                    <div className="processsectioninformation">
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: -1.5 + 'rem' }}>
                            <svg width="82" height="82" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_618_3314)">
                                    <rect x="22" y="21" width="52" height="52" rx="26" fill="white" />
                                    <path d="M52.0909 49.6364C48.45 49.6364 41.1818 51.45 41.1818 55.0909V57.8182H63V55.0909C63 51.45 55.7318 49.6364 52.0909 49.6364ZM39.8182 44.1818V40.0909H37.0909V44.1818H33V46.9091H37.0909V51H39.8182V46.9091H43.9091V44.1818M52.0909 46.9091C53.5375 46.9091 54.9249 46.3344 55.9479 45.3115C56.9708 44.2886 57.5455 42.9012 57.5455 41.4545C57.5455 40.0079 56.9708 38.6205 55.9479 37.5976C54.9249 36.5747 53.5375 36 52.0909 36C50.6443 36 49.2569 36.5747 48.234 37.5976C47.211 38.6205 46.6364 40.0079 46.6364 41.4545C46.6364 42.9012 47.211 44.2886 48.234 45.3115C49.2569 46.3344 50.6443 46.9091 52.0909 46.9091Z" fill="#F14810" />
                                </g>
                                <defs>
                                    <filter id="filter0_d_618_3314" x="0.457142" y="0.942857" width="98.0571" height="98.0571" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feMorphology radius="0.742857" operator="erode" in="SourceAlpha" result="effect1_dropShadow_618_3314" />
                                        <feOffset dx="1.48571" dy="2.97143" />
                                        <feGaussianBlur stdDeviation="11.8857" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_618_3314" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_618_3314" result="shape" />
                                    </filter>
                                </defs>
                            </svg>
                            <h6><b>Register Your School</b></h6>
                        </div>
                        <p className="processsectionparagraph" >
                            Quickly register your school and start exploring our platform's features.
                        </p>
                        <Link to="/register">
                            <Button variant="dark" className="buttonx" size="md" >
                                <span style={{ fontSize: 0.8 + 'rem', marginLeft: 0.5 + 'rem', marginRight: 0.5 + 'rem' }}>Get Started</span>
                            </Button>
                        </Link>
                    </div>

                </Col>

                <Col className="order-sm-1" xs={12} md={6} lg={6}  >
                    <Image src={howitworkimageone} fluid />
                </Col>
            </Row>

            <Row className="processsection">
                <Col xs={12} md={6} lg={6} style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <div className="processsectioninformation">
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: -1.5 + 'rem' }}>
                            <svg width="82" height="82" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_618_3327)">
                                    <rect x="22" y="20.5" width="52" height="52" rx="26" fill="white" />
                                    <path d="M34 45.15V57.475C34 57.8756 34.3244 58.2 34.725 58.2H38.35V43.7H35.45C34.6493 43.7 34 44.3493 34 45.15ZM50.3125 42.975H49.225V41.1625C49.225 40.9622 49.0628 40.8 48.8625 40.8H48.1375C47.9372 40.8 47.775 40.9622 47.775 41.1625V44.0625C47.775 44.2628 47.9372 44.425 48.1375 44.425H50.3125C50.5128 44.425 50.675 44.2628 50.675 44.0625V43.3375C50.675 43.1372 50.5128 42.975 50.3125 42.975ZM56.5543 40.0768L49.3043 35.2433C49.0661 35.0847 48.7862 35 48.5 35C48.2138 35 47.9339 35.0847 47.6957 35.2433L40.4457 40.0768C40.2471 40.2092 40.0843 40.3886 39.9717 40.599C39.859 40.8094 39.8001 41.0444 39.8 41.283V58.2H45.6V51.675C45.6 51.2744 45.9244 50.95 46.325 50.95H50.675C51.0756 50.95 51.4 51.2744 51.4 51.675V58.2H57.2V41.2835C57.2 40.7986 56.9576 40.3455 56.5543 40.0768ZM48.5 46.6C46.4981 46.6 44.875 44.9769 44.875 42.975C44.875 40.9731 46.4981 39.35 48.5 39.35C50.5019 39.35 52.125 40.9731 52.125 42.975C52.125 44.9769 50.5019 46.6 48.5 46.6ZM61.55 43.7H58.65V58.2H62.275C62.6756 58.2 63 57.8756 63 57.475V45.15C63 44.3493 62.3507 43.7 61.55 43.7Z" fill="#F14810" />
                                </g>
                                <defs>
                                    <filter id="filter0_d_618_3327" x="0.457142" y="0.442857" width="98.0571" height="98.0571" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feMorphology radius="0.742857" operator="erode" in="SourceAlpha" result="effect1_dropShadow_618_3327" />
                                        <feOffset dx="1.48571" dy="2.97143" />
                                        <feGaussianBlur stdDeviation="11.8857" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_618_3327" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_618_3327" result="shape" />
                                    </filter>
                                </defs>
                            </svg>

                            <h6><b>Onboard Your School</b></h6>
                        </div>
                        <p className="processsectionparagraph" >
                            Easily onboard your school to our platform and begin accessing valuable insights.
                        </p>

                    </div>

                </Col>
                <Col xs={12} md={6} lg={6}>
                    <Image src={howitworkimagetwo} fluid />
                </Col>

            </Row>



            <Row className="processsection">
                <Col className="order-sm-2" xs={12} md={6} lg={6} style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <div className="processsectioninformation">
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: -1.5 + 'rem' }}>
                            <svg width="82" height="82" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_618_3336)">
                                    <rect x="22" y="20.5" width="52" height="52" rx="26" fill="white" />
                                    <path d="M37.3889 47.8889H45.7222C46.4861 47.8889 47.1111 47.2639 47.1111 46.5V35.3889C47.1111 34.625 46.4861 34 45.7222 34H37.3889C36.625 34 36 34.625 36 35.3889V46.5C36 47.2639 36.625 47.8889 37.3889 47.8889ZM37.3889 59H45.7222C46.4861 59 47.1111 58.375 47.1111 57.6111V52.0556C47.1111 51.2917 46.4861 50.6667 45.7222 50.6667H37.3889C36.625 50.6667 36 51.2917 36 52.0556V57.6111C36 58.375 36.625 59 37.3889 59ZM51.2778 59H59.6111C60.375 59 61 58.375 61 57.6111V46.5C61 45.7361 60.375 45.1111 59.6111 45.1111H51.2778C50.5139 45.1111 49.8889 45.7361 49.8889 46.5V57.6111C49.8889 58.375 50.5139 59 51.2778 59ZM49.8889 35.3889V40.9444C49.8889 41.7083 50.5139 42.3333 51.2778 42.3333H59.6111C60.375 42.3333 61 41.7083 61 40.9444V35.3889C61 34.625 60.375 34 59.6111 34H51.2778C50.5139 34 49.8889 34.625 49.8889 35.3889Z" fill="#F14810" />
                                </g>
                                <defs>
                                    <filter id="filter0_d_618_3336" x="0.457142" y="0.442857" width="98.0571" height="98.0571" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feMorphology radius="0.742857" operator="erode" in="SourceAlpha" result="effect1_dropShadow_618_3336" />
                                        <feOffset dx="1.48571" dy="2.97143" />
                                        <feGaussianBlur stdDeviation="11.8857" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_618_3336" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_618_3336" result="shape" />
                                    </filter>
                                </defs>
                            </svg>
                            <h6><b>Access the Data Driven Dashboard</b></h6>
                        </div>
                        <p className="processsectionparagraph" >
                            Gain instant access to our data-driven dashboard for comprehensive insights into your school's performance.
                        </p>

                    </div>

                </Col>
                <Col className="order-sm-1" xs={12} md={6} lg={6} >
                    <Image src={howitworkimagethree} fluid />
                </Col>
            </Row>
        </Container>
    )
}

export default HowitWork