import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import {
  useAddRoleMutation,
  useUpdateRoleMutation,
  useGetPermissionPagesQuery
} from "../../../../../../redux/features/superAdmin/adminApiSlice";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import ToastComponent from "../../../../../../component/toast/toast";

const CreateRoleCanvas = ({ roleToEdit, setShowCanvas }) => {

  const {
    data: permissionPages,
    isLoading: pagesLoading,
    refetch: refetchPages,
  } = useGetPermissionPagesQuery();

  console.log(roleToEdit, 666);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // const [showCanvas, setShowCanvas] = useState(false);

  const [createRole, { isLoading: createLoading }] =
    useAddRoleMutation();
  const [editRole, { isLoading: editLoading }] =
    useUpdateRoleMutation();

  const [newRole, setNewRole] = useState({
    name: "",
    description: "",
    applicationPageIds: [],
  });

  const { name, description } = newRole;

  const [newPages, setNewPages] = useState([]);

  const handleOnChange = (e, id) => {
    const { name, value } = e.target;

    setNewRole({ ...newRole, [name]: value });

    setNewPages(
      newPages?.map((page) => {
        if (page.id === id) {
          return {
            ...page,
            isActive: !page.isActive,
          };
        }
        return page;
      })
    );
  };

  const handleCreateRole = async () => {
    const body = {
      name: name,
      description: description,
      applicationPageIds: newPages
        ?.filter((page) => page.isActive !== false)
        .map((page) => page.id),
    };

    console.log(body);

    try {
      const res = await createRole(body).unwrap();
      console.log(res);
      setShowToast(true);
      setToastMessage("Role created successfully!");
      setNewRole({
        name: "",
        description: "",
        applicationPageIds: [],
      });
      setShowCanvas(false);
    } catch (error) {
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleUpdateRole = async () => {
    const params = {
      roleId: roleToEdit?.id,
      body: {
        name: name,
        description: description,
        applicationPageIds: newPages
          ?.filter((page) => page.isActive === true)
          .map((page) => page.id),
      },
    };

    try {
      const res = await editRole(params).unwrap();
      console.log(res);

      setNewRole({
        name: "",
        description: "",
        applicationPageIds: [],
      });
      setNewPages(
        permissionPages?.map((page) => {
          return { ...page, isActive: false };
        })
      );
      setShowCanvas(false);
      // setRoleToEdit(null);
      setShowToast(true);
      setToastMessage("Role updated successfully!");
    } catch (error) {
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  console.log(newPages, "Pages");

  useEffect(() => {
    setNewPages(
      permissionPages?.map((page) => {
        return { ...page, isActive: false };
      })
    );
  }, [permissionPages]);

  useEffect(() => {
    if (roleToEdit) {
      console.log(roleToEdit);
      setNewRole({ name: roleToEdit?.name, description: roleToEdit?.description });
      // setNewPermissions(roleToEdit?.applicationPages);
      setNewPages(
        permissionPages?.map((page) => {
          console.log(page);
          if (
            roleToEdit?.applicationPages?.find(
              (applicationPage) => page.id === applicationPage.id
            )
          ) {
            return { ...page, isActive: true };
          }
          return page;
        })
      );
    }
  }, [roleToEdit]);

  // useEffect(() => {
  //   setNewRole({ name: roleToEdit?.name, description: roleToEdit?.description });
  //   setNewPages(roleToEdit?.applicationPages);
  // }, [roleToEdit]);

  if (
    pagesLoading
    ||
    createLoading
    ||
    editLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <>

      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <div style={{ display: "flex", flexDirection: "column" }}>
        <p style={{ marginBottom: "5px", fontWeight: "500" }}>
          Role Title <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
        </p>
        <input
          type="text"
          placeholder="Enter role title"
          name="name"
          value={name}
          // value={editingElement ? editingValue : name}
          onChange={(e) => handleOnChange(e)}
          style={{
            borderRadius: "5px",
            backgroundColor: "#ededed",
            width: "100%",
            marginBottom: "15px",
            padding: "8px",
            outline: "none",
            border: "none",
          }}
        />

        <p style={{ marginBottom: "5px", fontWeight: "500" }}>
          Role Description <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
        </p>
        <input
          type="text"
          placeholder="Enter role description"
          name="description"
          value={description}
          // value={editingElement ? editingValue : name}
          onChange={(e) => handleOnChange(e)}
          style={{
            borderRadius: "5px",
            backgroundColor: "#ededed",
            width: "100%",
            marginBottom: "15px",
            padding: "8px",
            outline: "none",
            border: "none",
          }}
        />

        <p style={{ marginBottom: "10px", fontWeight: "500" }}>
          Assign Permission{" "}
          <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
        </p>

        <div
          style={{
            padding: "15px",
            border: "1px solid #DBDBDB",
            borderRadius: "10px",
          }}
        >
          {newPages?.map(
            ({ id, name, action, isActive, description }) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                  gap: "20px",
                  alignItems: "flex-start",
                }}
              >
                <div>
                  <h6 style={{ marginBottom: "5px" }}>{action}</h6>
                  <p style={{ marginBottom: "0px", color: "#7b7b7b" }}>
                    {description}
                  </p>
                </div>

                <div
                  className="calender-switch-container"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isActive}
                      // checked={editingElement ? editedToggled : isToggled}
                      onChange={(e) => handleOnChange(e, id)}
                      className="toggle"
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            ))}
        </div>

        <Button
          className="button"
          size="md"
          style={{
            backgroundColor: "#F14810",
            color: "#ffffff",
            border: "none",
            outline: "none",
            fontSize: "14px",
            padding: "8px 20px",
            marginTop: "20px",
            marginLeft: "auto",
          }}
          onClick={
            roleToEdit ? () => handleUpdateRole() : () => handleCreateRole()
          }
        >
          {roleToEdit ? "Edit Role" : "Save Role"}
        </Button>
      </div>
    </>
  );
};

export default CreateRoleCanvas;
