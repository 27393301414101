import { Modal } from "react-bootstrap";
import closeModalIcon from "../../assets/svgs/closeModalIcon.svg";
import "../modal/modal.css";

const ModalComponent = ({ title, showModal, size, closeModal, children }) => {
  return (
    <center>
      <Modal centered show={showModal} onHide={closeModal} size={size ? size : "md"}>
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "0px",
          }}
        >
          <h5>{title}</h5>
          <img
            src={closeModalIcon}
            alt="close modal icon"
            style={{ cursor: "pointer" }}
            onClick={closeModal}
          />
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: "10px",
          }}
        >
          {children}
        </Modal.Body>
      </Modal>
    </center>
  );
};

export default ModalComponent;
