import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import backArrow from "../../../../../../assets/svgs/backArrow.svg";
import eyeActionIcon from "../../../../../../assets/svgs/eyeActionIcon.svg";
import successGIF from "../../../../../../assets/svgs/successGIF.gif";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import {
  useDownloadDocumentQuery,
  useGetFeedbackSectionsQuery,
  useGetInstitutionUpdateQuery,
  useApproveInstitutionUpdateMutation,
  useDeclineInstitutionUpdateMutation,
} from "../../../../../../redux/features/superAdmin/adminApiSlice";

import ModalComponent from "../../../../../../component/modal/modal";
import ToastComponent from "../../../../../../component/toast/toast";
import { useNavigate } from "react-router-dom";

const ViewUpdateInstitution = ({
  institutionDetails,
  setIsInstitutionViewed,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showDeclineSuccess, setShowDeclineSuccess] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showApproveSuccess, setShowApproveSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [documentViewed, setDocumentViewed] = useState(null);

  const handleCloseDeclineModal = () => setShowDeclineModal(false);
  const handleCloseApproveModal = () => setShowApproveModal(false);

  const [declineData, setDeclineData] = useState({
    remark: "",
  });

  const { remark } = declineData;

  const [approveData, setApproveData] = useState({
    comment: "",
  });

  const { comment } = approveData;

  const [
    approveInst,
    { isLoading: approveLoading, isSuccess: approveSuccess },
  ] = useApproveInstitutionUpdateMutation();

  const [
    declineInst,
    { isLoading: declineLoading, isSuccess: declineSuccess },
  ] = useDeclineInstitutionUpdateMutation();

  // const { data: feedbackSections, isLoading: feedbackSectionsLoading } =
  //     useGetFeedbackSectionsQuery();

  // const [newFeedbackSections, setNewFeedbackSections] = useState([]);

  // useEffect(() => {
  //     setNewFeedbackSections(
  //         feedbackSections?.map((section) => {
  //             return {
  //                 name: section,
  //             };
  //         })
  //     );
  // }, [feedbackSections]);

  // const handleActionClick = (element, actionType) => {
  //     const newSectionsArray = newFeedbackSections?.map((section) => {
  //         if (section.name === element) {
  //             if (actionType === "good") {
  //                 return {
  //                     ...section,
  //                     pass: true,
  //                 };
  //             } else if (actionType === "bad") {
  //                 setShowDeclineModal(true);
  //                 setSectionTitle(element);

  //                 return {
  //                     ...section,
  //                     pass: false,
  //                 };
  //             }
  //         }

  //         return section;
  //     });

  //     setNewFeedbackSections(newSectionsArray);
  // };

  // console.log(newFeedbackSections);

  const {
    data: institutionInfo,
    refetch: refetchDetails,
    isLoading: institutionLoading,
  } = useGetInstitutionUpdateQuery(institutionDetails.id);

  console.log(institutionInfo);

  const [clickedDoc, setClickedDoc] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [actionType, setActionType] = useState(null);
  const [showPdf, setShowPdf] = useState(false);

  const querys = {
    institutionId: institutionDetails?.id,
    documentId: clickedDoc?.id,
  };

  const {
    data: documentData,
    isLoading: documentLoading,
    isSuccess: documentSuccess,
    refetch: refetchDocuments,
  } = useDownloadDocumentQuery(querys, { skip: !clickedDoc?.id });

  // console.log(documentData, 33333);

  useEffect(() => {
    if (documentData) {
      refetchDocuments();
    }
  }, [clickedDoc]);

  const openPDF = (doc) => {
    setClickedDoc(doc);
    // setShowPdf(true)
    console.log("doc", doc);
    setDocumentViewed(doc.content);
    setShowPdf(true);
  };

  const closePDF = () => {
    setShowPdf(false);
  };

  const downloadPdf = (doc) => {
    setClickedDoc(doc);
    setActionType("download");
  };

  const [show, setShow] = useState(true);

  const handleDeclineClose = () => {
    setShowDeclineModal(false);
  };

  const handleApproveClose = () => {
    setShowApproveModal(false);
  };

  console.log(actionType);

  if (documentSuccess) {
    console.log("Successsssssssssssssssssssss", actionType);
    if (actionType === "view") {
      const pdfWindow = window.open("");
      pdfWindow.document.write(
        `<iframe width="100%" height="100%" src="${documentData?.content}"></iframe>`
      );
      setClickedDoc(null);
    } else if (actionType === "download") {
      const downloadLink = document.createElement("a");
      // point to the base64 string
      downloadLink.href = documentData?.content;
      // replace this with the actual name of the pdf
      downloadLink.download = "document.pdf";
      downloadLink.click();
      setClickedDoc(null);
    }
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setDeclineData({ ...declineData, [name]: value });
    setApproveData({ ...approveData, [name]: value });
  };

  const handleDecline = async (e) => {
    e.preventDefault();

    try {
      const params = {
        institutionId: institutionDetails?.id,
        comment: declineData.remark,
      };

      console.log(params, 888888);

      const data = await declineInst(params).unwrap();

      if (data) {
        console.log(data);
        setShowDeclineModal(false);
        setShowToast(true);
        setToastMessage("Institution update declined!");
        // setShowDeclineSuccess(true);
        navigate("/principal/applications");
      }
    } catch (error) {
      console.log(error.data);
      // setErrorMessage(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleApprove = async (e) => {
    e.preventDefault();

    try {
      const params = {
        institutionId: institutionDetails?.id,
        comment: approveData.comment,
      };

      const res = await approveInst(params).unwrap();

      if (res) {
        console.log("Success");
        setShowApproveModal(false);
        setShowToast(true);
        setToastMessage("Institution update approved successfully!");
        // setShowApproveSuccess(true);
        navigate("/principal/applications");
      }
    } catch (error) {
      console.log(error.data);
      // setErrorMessage(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  // const [isAcceptClicked, setIsAcceptClicked] = useState(true);
  // const [isRejectClicked, setIsRejectClicked] = useState(true);
  // const [sectionTitle, setSectionTitle] = useState("");

  // const handleAcceptClick = () => {
  //     setIsRejectClicked(false);
  //     setIsAcceptClicked((prev) => !prev);
  // };

  // const handleRejectClick = (section) => {
  //     setSectionTitle(section);
  //     setIsAcceptClicked(false);
  //     setIsRejectClicked((prev) => !prev);
  //     setShowDeclineModal(true);
  // };

  useEffect(() => {
    setDocuments(institutionInfo?.documents);
  }, [institutionInfo]);

  if (
    documentLoading ||
    declineLoading ||
    approveLoading ||
    institutionLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <div>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      {/* decline success modal */}
      <ModalComponent
        showModal={showDeclineSuccess}
        closeModal={() => setShowDeclineSuccess(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!</h3>
          <p style={{ fontSize: "18px" }}>Decline successful</p>
        </div>
      </ModalComponent>

      {/* approve success modal */}
      <ModalComponent
        showModal={showApproveSuccess}
        closeModal={() => setShowApproveSuccess(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!</h3>
          <p style={{ fontSize: "18px" }}>
            Institution update approved successfully
          </p>
        </div>
      </ModalComponent>

      {/* decline modal */}
      <ModalComponent
        title="Decline with Comment"
        showModal={showDeclineModal}
        closeModal={handleCloseDeclineModal}
      >
        <Form onSubmit={handleDecline}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control
              as="textarea"
              rows={3}
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              name="remark"
              value={remark}
              onChange={handleOnChange}
              placeholder="Enter your Comment..."
            />
          </Form.Group>

          <div style={{ marginTop: "30px" }}>
            <Button
              type="submit"
              variant="primary"
              style={{
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                float: "left",
              }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Send
              </span>
            </Button>
          </div>
        </Form>
      </ModalComponent>

      {/* approve modal */}
      <ModalComponent
        title="Approve with Comment"
        showModal={showApproveModal}
        closeModal={handleCloseApproveModal}
      >
        <Form onSubmit={handleApprove}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className="formClasslabel">Comment</Form.Label>

            <Form.Control
              as="textarea"
              rows={3}
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              name="comment"
              value={comment}
              onChange={handleOnChange}
              placeholder="Enter your Comment..."
            />
          </Form.Group>

          <div
            style={{
              marginTop: "30px",
            }}
          >
            <Button
              type="submit"
              variant="primary"
              style={{
                backgroundColor: "#F14810",
                borderColor: "#F14810",
              }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Send
              </span>
            </Button>
          </div>
        </Form>
      </ModalComponent>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
            marginBottom: "10px",
          }}
          onClick={() => setIsInstitutionViewed(false)}
        >
          <img src={backArrow} alt="Go back" />
          <h6 style={{ marginBottom: 0 }}>
            Institution Update ( {institutionDetails?.institutionName} )
          </h6>
        </div>
      </div>

      <Container>
        <Row>
          <Col xs={12} md={6} lg={6}>
            <div style={{ paddingTop: 1.5 + "rem" }}>
              <div
                style={{
                  border: "2px solid #EDEDED",
                  borderRadius: "8px",
                  gap: "30px",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <div
                  style={{
                    borderBottom: "2px solid #EDEDED",
                    padding: "10px",
                    gap: "10px",
                    marginBottom: "15px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      color: "#F14810",
                    }}
                  >
                    <svg
                      width="51"
                      height="40"
                      viewBox="0 0 51 51"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="51" height="51" rx="25.5" fill="#FFF3EF" />
                      <path
                        d="M10 23.7625V36.8312C10 37.256 10.344 37.6 10.7687 37.6H14.6125V22.225H11.5375C10.6885 22.225 10 22.9135 10 23.7625ZM27.2969 21.4563H26.1437V19.5344C26.1437 19.322 25.9717 19.15 25.7594 19.15H24.9906C24.7783 19.15 24.6062 19.322 24.6062 19.5344V22.6094C24.6062 22.8217 24.7783 22.9938 24.9906 22.9938H27.2969C27.5092 22.9938 27.6812 22.8217 27.6812 22.6094V21.8406C27.6812 21.6283 27.5092 21.4563 27.2969 21.4563ZM33.9153 18.3832L26.2278 13.258C25.9752 13.0898 25.6785 13 25.375 13C25.0715 13 24.7748 13.0898 24.5222 13.258L16.8347 18.3832C16.6241 18.5236 16.4514 18.7137 16.332 18.9369C16.2126 19.16 16.1501 19.4091 16.15 19.6622V37.6H22.3V30.6812C22.3 30.2565 22.644 29.9125 23.0687 29.9125H27.6812C28.106 29.9125 28.45 30.2565 28.45 30.6812V37.6H34.6V19.6627C34.6 19.1486 34.3429 18.6681 33.9153 18.3832ZM25.375 25.3C23.2523 25.3 21.5312 23.579 21.5312 21.4563C21.5312 19.3335 23.2523 17.6125 25.375 17.6125C27.4977 17.6125 29.2187 19.3335 29.2187 21.4563C29.2187 23.579 27.4977 25.3 25.375 25.3ZM39.2125 22.225H36.1375V37.6H39.9812C40.406 37.6 40.75 37.256 40.75 36.8312V23.7625C40.75 22.9135 40.0615 22.225 39.2125 22.225Z"
                        fill="#F14810"
                      />
                    </svg>
                    <h6 style={{ fontWeight: "600", marginTop: "10px" }}>
                      School Details
                    </h6>
                  </div>
                </div>

                <div style={{ padding: "20px" }}>
                  <p style={{ fontWeight: "500" }}>Current Details</p>
                  <div
                    style={{
                      padding: "10px",
                      border: "2px solid #EDEDED",
                      borderRadius: "8px",
                    }}
                  >
                    <p style={{ fontWeight: "600" }}>
                      School Name:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {institutionInfo?.currentDetails?.institutionName}
                      </span>
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      School Type:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {institutionInfo?.currentDetails?.institutionTypes?.map(
                          (type, i, arr) => (
                            <span>
                              {type.typeName} {i != arr.length - 1 ? ", " : ""}
                            </span>
                          )
                        )}
                      </span>
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      School Email:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {institutionInfo?.currentDetails?.institutionEmail}
                      </span>
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      Contact Number:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {institutionInfo?.currentDetails?.schoolContactNumber}
                      </span>
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      School Website:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {institutionInfo?.currentDetails?.website}
                      </span>
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      Address:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {institutionInfo?.currentDetails?.address}
                      </span>
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      Country:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {institutionInfo?.currentDetails?.country}
                      </span>
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      State:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {institutionInfo?.currentDetails?.state}
                      </span>
                    </p>
                  </div>
                </div>

                <div style={{ padding: "20px" }}>
                  <p style={{ fontWeight: "500" }}>Proposed Update</p>
                  <div
                    style={{
                      padding: "10px",
                      border: "2px solid #EDEDED",
                      borderRadius: "8px",
                    }}
                  >
                    <p style={{ fontWeight: "600" }}>
                      School Name:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {
                          institutionInfo?.proposedDetailsUpdate
                            ?.institutionName
                        }
                      </span>
                    </p>
                    {institutionInfo?.proposedDetailsUpdate
                      ?.institutionTypes !== 0 && (
                      <p style={{ fontWeight: "600" }}>
                        School Type:{" "}
                        <span style={{ fontWeight: "400" }}>
                          {institutionInfo?.proposedDetailsUpdate?.institutionTypes?.map(
                            (type, i, arr) => (
                              <span>
                                {type.typeName}{" "}
                                {i != arr.length - 1 ? ", " : ""}
                              </span>
                            )
                          )}
                        </span>
                      </p>
                    )}
                    {institutionInfo?.proposedDetailsUpdate
                      ?.institutionEmail && (
                      <p style={{ fontWeight: "600" }}>
                        School Email:{" "}
                        <span style={{ fontWeight: "400" }}>
                          {
                            institutionInfo?.proposedDetailsUpdate
                              ?.institutionEmail
                          }
                        </span>
                      </p>
                    )}
                    {institutionInfo?.proposedDetailsUpdate
                      ?.schoolContactNumber && (
                      <p style={{ fontWeight: "600" }}>
                        Contact Number:{" "}
                        <span style={{ fontWeight: "400" }}>
                          {
                            institutionInfo?.proposedDetailsUpdate
                              ?.schoolContactNumber
                          }
                        </span>
                      </p>
                    )}
                    {institutionInfo?.proposedDetailsUpdate?.website && (
                      <p style={{ fontWeight: "600" }}>
                        School Website:{" "}
                        <span style={{ fontWeight: "400" }}>
                          {institutionInfo?.proposedDetailsUpdate?.website}
                        </span>
                      </p>
                    )}
                    {institutionInfo?.proposedDetailsUpdate?.address && (
                      <p style={{ fontWeight: "600" }}>
                        Address:{" "}
                        <span style={{ fontWeight: "400" }}>
                          {institutionInfo?.proposedDetailsUpdate?.address}
                        </span>
                      </p>
                    )}
                    {institutionInfo?.proposedDetailsUpdate?.country && (
                      <p style={{ fontWeight: "600" }}>
                        Country:{" "}
                        <span style={{ fontWeight: "400" }}>
                          {institutionInfo?.proposedDetailsUpdate?.country}
                        </span>
                      </p>
                    )}
                    {institutionInfo?.proposedDetailsUpdate?.state && (
                      <p style={{ fontWeight: "600" }}>
                        State:{" "}
                        <span style={{ fontWeight: "400" }}>
                          {institutionInfo?.proposedDetailsUpdate?.state}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>

          {institutionInfo?.currentDocuments &&
            institutionInfo?.currentDocuments?.length > 0 && (
              <Col xs={12} md={6} lg={6}>
                <div style={{ paddingTop: 1.5 + "rem" }}>
                  <div
                    style={{
                      border: "2px solid #EDEDED",
                      borderRadius: "8px",
                      gap: "30px",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <div
                      style={{
                        borderBottom: "2px solid #EDEDED",
                        padding: "10px",
                        gap: "10px",
                        marginBottom: "15px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          color: "#F14810",
                        }}
                      >
                        <svg
                          width="51"
                          height="40"
                          viewBox="0 0 51 51"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="51"
                            height="51"
                            rx="25.5"
                            fill="#FFF3EF"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M28.6 39H23.4C18.4977 39 16.0459 39 14.5236 37.4764C13 35.9541 13 33.5023 13 28.6V23.4C13 18.4977 13 16.0459 14.5236 14.5236C16.0459 13 18.5107 13 23.439 13C24.2268 13 24.8573 13 25.389 13.0221C25.3721 13.1261 25.363 13.2314 25.363 13.3393L25.35 17.0235C25.35 18.4496 25.35 19.7106 25.4865 20.7259C25.6347 21.827 25.974 22.9281 26.8736 23.8277C27.7706 24.7247 28.873 25.0653 29.9741 25.2135C30.9894 25.35 32.2504 25.35 33.6765 25.35H38.9441C39 26.0442 39 26.897 39 28.0319V28.6C39 33.5023 39 35.9541 37.4764 37.4764C35.9541 39 33.5023 39 28.6 39Z"
                            fill="#F14810"
                          />
                          <path
                            d="M35.5574 20.302L30.4094 15.6701C28.9443 14.3506 28.2124 13.6902 27.3115 13.3457L27.2998 16.8999C27.2998 19.964 27.2998 21.4967 28.2514 22.4483C29.203 23.3999 30.7357 23.3999 33.7998 23.3999H38.4538C37.9832 22.4847 37.1382 21.7255 35.5574 20.302Z"
                            fill="#F14810"
                          />
                        </svg>
                        <h6 style={{ fontWeight: "600", marginTop: "10px" }}>
                          Documents
                        </h6>
                      </div>
                    </div>

                    <div style={{ padding: "20px" }}>
                      <p style={{ fontWeight: "500" }}>Current Details</p>
                      <div
                        style={{
                          padding: "10px",
                          border: "2px solid #EDEDED",
                          borderRadius: "8px",
                        }}
                      >
                        {institutionInfo?.currentDocuments &&
                          institutionInfo?.currentDocuments?.length > 0 &&
                          institutionInfo?.currentDocuments?.map((doc, i) => (
                            <div
                              key={i}
                              style={{
                                display: "flex",
                                gap: "10px",
                                marginBottom: "10px",
                                alignItems: "center",
                              }}
                            >
                              {doc?.documentCategory?.name} :{" "}
                              <span
                                style={{
                                  color: "blue",
                                  fontStyle: "underline",
                                }}
                              >
                                {doc?.description}
                              </span>
                              <img
                                src={eyeActionIcon}
                                alt="view icon"
                                onClick={() => openPDF(doc)}
                                style={{ width: "30px", cursor: "pointer" }}
                              />
                            </div>
                          ))}
                      </div>
                    </div>

                    <div style={{ padding: "20px" }}>
                      <p style={{ fontWeight: "500" }}>Proposed Update</p>
                      <div
                        style={{
                          padding: "10px",
                          border: "2px solid #EDEDED",
                          borderRadius: "8px",
                        }}
                      >
                        {institutionInfo?.proposedDocumentsUpdate &&
                          institutionInfo?.proposedDocumentsUpdate?.length >
                            0 &&
                          institutionInfo?.proposedDocumentsUpdate?.map(
                            (doc, i) => (
                              <div
                                key={i}
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  marginBottom: "10px",
                                  alignItems: "center",
                                }}
                              >
                                {doc?.documentCategory?.name} :{" "}
                                <span
                                  style={{
                                    color: "blue",
                                    fontStyle: "underline",
                                  }}
                                >
                                  {doc?.description}
                                </span>
                                <img
                                  src={eyeActionIcon}
                                  alt="view icon"
                                  onClick={() => openPDF(doc)}
                                  style={{ width: "30px", cursor: "pointer" }}
                                />
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            )}
        </Row>
        <Row>
          <div style={{ display: "flex", gap: 1.1 + "rem", marginTop: "30px" }}>
            <Button
              variant="outline-primary"
              style={{ borderColor: "#F14810", color: "#F14810" }}
              className="button"
              size="md"
              onClick={(e) => setShowDeclineModal(true)}
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Decline
              </span>
            </Button>

            <Button
              variant="primary"
              style={{
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                float: "left",
              }}
              className="button"
              size="md"
              onClick={(e) => setShowApproveModal(true)}
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Approve
              </span>
            </Button>
          </div>
        </Row>
      </Container>

      <br />
      <br />

      {showPdf && documentData && documentData.content && (
        <Modal
          centered
          show={showPdf}
          onHide={() => setShowPdf(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Document Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "80vh" }}>
            <iframe
              width="100%"
              height="100%"
              src={`${documentData?.content}`}
              title="PDF Viewer"
            ></iframe>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default ViewUpdateInstitution;
