import React, { useEffect, useState } from "react";
import "./institutionDashboardPage.css";

import {  Container } from 'react-bootstrap';
import Students from "./components/students/students";
import {
  useGetInstitutionQuery,
  useGetInstitutionLogoQuery,
} from "../../../redux/features/institution/institutionApiSlice";

import { useSelector, useDispatch } from "react-redux";
import DashboardTopSection from "./components/dashboardTopSection";
import DashboardSidebar from "./components/dashboardSidebar/sidebar";



const InstitutionStudentPage = () => {

  const [details, setDetails] = useState({});
  

  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const { data: institutionDetails } = useGetInstitutionQuery(id);
  const { data: institutionLogo } = useGetInstitutionLogoQuery(id);
  // console.log(institutionLogo?.logo);

  useEffect(() => {
    setDetails(institutionDetails);
  }, [institutionDetails, details]);

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar institutionDetails={institutionDetails}/>

      <Container fluid className="bodycontainer">
        <DashboardTopSection />
        <Students institutionDetails={institutionDetails} institutionLogo={institutionLogo} />
      </Container>
    </Container>
  );
};

export default InstitutionStudentPage;
