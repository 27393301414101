import React from 'react'

function ClassIcon() {
    return (
        <div>
            <svg width="20" height="21" viewBox="0 0 29 24"  fill='currentColor' xmlns="http://www.w3.org/2000/svg">
                <path d="M19.0379 4.61968C19.0379 7.0125 17.2658 8.95226 15.0798 8.95226C12.8937 8.95226 11.1216 7.0125 11.1216 4.61968C11.1216 2.22687 12.8937 0.287109 15.0798 0.287109C17.2658 0.287109 19.0379 2.22687 19.0379 4.61968Z"  />
                <path d="M8.74668 7.5287C8.74668 8.99858 7.65808 10.1901 6.31524 10.1901C4.97239 10.1901 3.8838 8.99858 3.8838 7.5287C3.8838 6.05883 4.97239 4.86726 6.31524 4.86726C7.65808 4.86726 8.74668 6.05883 8.74668 7.5287Z"  />
                <path d="M26.0495 7.5287C26.0495 8.99858 24.9609 10.1901 23.618 10.1901C22.2752 10.1901 21.1866 8.99858 21.1866 7.5287C21.1866 6.05883 22.2752 4.86726 23.618 4.86726C24.9609 4.86726 26.0495 6.05883 26.0495 7.5287Z"  />
                <path d="M1 17.0631V22.3381C1 23.0969 1.61518 23.7121 2.37405 23.7121H27.1069C27.8657 23.7121 28.4809 23.0969 28.4809 22.3381V17.0631C28.0394 15.0048 25.8984 11.3932 20.8657 13.4142C19.2654 10.7944 14.608 7.12674 8.78074 13.4142C6.29752 12.4785 2.48993 12.2914 1 17.0631Z"  />
                <path d="M19.0379 4.61968C19.0379 7.0125 17.2658 8.95226 15.0798 8.95226C12.8937 8.95226 11.1216 7.0125 11.1216 4.61968C11.1216 2.22687 12.8937 0.287109 15.0798 0.287109C17.2658 0.287109 19.0379 2.22687 19.0379 4.61968Z" />
                <path d="M8.74668 7.5287C8.74668 8.99858 7.65808 10.1901 6.31524 10.1901C4.97239 10.1901 3.8838 8.99858 3.8838 7.5287C3.8838 6.05883 4.97239 4.86726 6.31524 4.86726C7.65808 4.86726 8.74668 6.05883 8.74668 7.5287Z"  />
                <path d="M26.0495 7.5287C26.0495 8.99858 24.9609 10.1901 23.618 10.1901C22.2752 10.1901 21.1866 8.99858 21.1866 7.5287C21.1866 6.05883 22.2752 4.86726 23.618 4.86726C24.9609 4.86726 26.0495 6.05883 26.0495 7.5287Z" />
                <path d="M1 17.0631V22.3381C1 23.0969 1.61518 23.7121 2.37405 23.7121H27.1069C27.8657 23.7121 28.4809 23.0969 28.4809 22.3381V17.0631C28.0394 15.0048 25.8984 11.3932 20.8657 13.4142C19.2654 10.7944 14.608 7.12674 8.78074 13.4142C6.29752 12.4785 2.48993 12.2914 1 17.0631Z"  />
            </svg>

        </div>
    )
}

export default ClassIcon