import React, { useEffect, useState } from "react";
import "./institutionDashboardPage.css";

import { Container } from "react-bootstrap";

import InstitutionDashboard from "./components/dashboard/dashboard";
import Students from "./components/students/students";
import {
  useGetInstitutionQuery,
  useGetInstitutionLogoQuery,
} from "../../../redux/features/institution/institutionApiSlice";

import { useSelector, useDispatch } from "react-redux";
import DashboardSidebar from "./components/dashboardSidebar/sidebar";
import DashboardTopSection from "./components/dashboardTopSection";

const InstitutionAdminDashBoardPage = () => {
  const [details, setDetails] = useState({});

  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const { data: institutionDetails } = useGetInstitutionQuery(id);

  useEffect(() => {
    setDetails(institutionDetails);
  }, [institutionDetails, details]);

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar institutionDetails={institutionDetails} />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />
        <InstitutionDashboard />
      </Container>
    </Container>
  );
};

export default InstitutionAdminDashBoardPage;
