import React, { useState } from "react";
import AboutTheSchool from "./aboutTheSchool";
import SchoolBranding from "./schoolbranding";
import ContactPerson from "./contactPerson";
// import UploadDocuments from './components/uploadDocuments';

const RegisterBranchStepper = ({ setShowCreateBranchCanvas }) => {
  const [activeStage, setActiveStage] = useState("about school");

  return (
    <div>
      <div>
        {activeStage === "about school" && (
          <AboutTheSchool setActiveStage={setActiveStage} />
        )}
        {/* {activeStage === "school branding" && (
          <SchoolBranding setActiveStage={setActiveStage} />
        )} */}
        {activeStage === "contact person" && (
          <ContactPerson
            setActiveStage={setActiveStage}
            setShowCreateBranchCanvas={setShowCreateBranchCanvas}
          />
        )}
      </div>
    </div>
  );
};

export default RegisterBranchStepper;
