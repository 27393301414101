import successGIF from "../../assets/svgs/successGIF.gif"

const SuccessScreen = ({successMessage}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <img src={successGIF} alt="success Gif" width="150px" />
      <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!</h3>
      <p style={{ fontSize: "18px" }}>{successMessage}</p>
    </div>
  );
}

export default SuccessScreen