import { useEffect, useState } from "react";
import "./validationPage.css";
import Button from "../../../../component/button/button";
import { Link } from "react-router-dom";
import { useGetInstitutionQuery } from "../../../../redux/features/institution/institutionApiSlice";
import { useSelector } from "react-redux";
import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";

const ValidationPage = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [isUnderReview, setIsUnderReview] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const id = useSelector((state) => state.auth.userInfo.institutionId);

  const { data: institutionDetails, isLoading, refetch } = useGetInstitutionQuery(id);
  console.log(institutionDetails);

  // pending, submitted and approved
  // remark

  useEffect(() => {
    const handleVerification = () => {
      if (institutionDetails?.status === "APPROVED") {
        setIsUnderReview(false);
        setIsVerified(true);
        return
      } else if (
        institutionDetails?.status === "SUBMITTED"
      ) {
        setIsUnderReview(true);
        setIsVerified(false)
        setFeedback(null)
        return
      } else if (
        institutionDetails?.status === "PENDING" &&
        institutionDetails?.remark
      ) {
        console.log("rejected with feedback");
        setIsVerified(false);
        setIsUnderReview(false)
        setFeedback(institutionDetails?.remark);
      }
    };
    handleVerification();

    if (institutionDetails) {
      refetch()
    }
  }, [institutionDetails]);

  // console.log(isVerified);
  // console.log(feedback);
  // console.log(isUnderReview);



  if (isLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#FFF3ef",
        display: "flex",
        flexDirection: "column",
        padding: "30px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isUnderReview && (
        <div
          style={{
            color: "#F14810",
            fontWeight: 500,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "700px",
          }}
        >
          <h4 style={{ color: "#F14810", fontSize: "40px" }}>
            Your Application has been submitted successfully !!!
          </h4>
          <p
            style={{
              textAlign: "center",
              maxWidth: "600px",
              marginBottom: "50px",
            }}
          >
            A few more steps away from your dashboard, click the button to proceed
          </p>
          <Link to="/setup-institution/continue">
            <Button>Proceed</Button>
          </Link>

        </div>
      )}

      {/* {isVerified && (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "700px",
          }}
        >
          <h4 style={{ color: "#F14810", fontSize: "40px" }}>
            Your Application has been verified !!
          </h4>
         
          <Link to="/setup-institution/continue">
            <Button>Go To Dashboard</Button>
          </Link>
        </div>
      )} */}

      {/* {feedback && (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "700px",
          }}
        >
          <h4 style={{ color: "#F14810", fontSize: "40px" }}>
            Rejected with Feedback
          </h4>
          <p style={{ fontSize: "16px" }}>{feedback}</p>
          <Link to="/setup-institution">
            <Button>Resolve Issue</Button>
          </Link>
        </div>
      )} */}
    </div>
  );
};

export default ValidationPage;
