import { useState, useEffect } from "react";
// import eyeActionIcon from "../../../../assets/svgs/eyeActionIcon.svg";
import { Container } from "react-bootstrap";
import DashboardTopSection from "../../../components/dashboardTopSection/index";
import DashboardSidebar from "../../../components/dashboardSidebar/sidebar";
import TableComponent from "../../../../../../component/table/table";
import backArrow from "../../../../../../assets/svgs/backArrow.svg";
// import "./settlement.css";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import {
  useGetInstitutionQuery,
  useGetLevelDetailQuery,
} from "../../../../../../redux/features/institution/institutionApiSlice";
import { addTrailingCommas } from "../../../../../../utils/utils";

const LevelSummaryDetailPage = () => {
  let { levelId } = useParams();
  console.log(levelId);

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  console.log(instId);

  const { data: institutionDetails, isLoading: detailsLoading } =
    useGetInstitutionQuery(instId);

  const params = {
    institutionId: instId,
    levelId: levelId,
  };

  const {
    data: levelSummaryDetail,
    // refetch: refetchBranches,
    isLoading: levelSummaryDetailLoading,
  } = useGetLevelDetailQuery(params, { skip: !instId || !levelId });

  console.log(levelSummaryDetail, 888888888);

  // if (levelSummaryDetailLoading) {
  //     console.log(levelSummaryDetail, 888888888);
  // }
  // console.log(levelSummaryDetail.levelDetailsReportList,888888888);
  //console.log(levelSummaryDetail.levelDetailsReportList,999999999);

  const navigate = useNavigate();

  const columns = [
    // { name: "studentId", label: "ID" },
    { name: "studentName", label: "Student Name" },
    { name: "class", label: "Class" },
    // {
    //   name: "name",
    //   label: "Level - Category",
    //   options: {
    //     // customBodyRender: (name) => (name ? name.name : "N/A")
    //     customBodyRender: (value, tableMeta) => {
    //       const { institutionLevel, institutionCategory } =
    //         levelSummaryDetail[tableMeta.rowIndex];
    //       return `${institutionLevel.name} - ${institutionCategory.name}`;
    //     },
    //   },
    // },
    {
      name: "totalAmount",
      label: "Total Amount",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { totalAmount } = levelSummaryDetail[tableMeta.rowIndex];
          return `${"₦ " + addTrailingCommas(totalAmount)}`;
        },
      },
    },
    {
      name: "paidAmount",
      label: "Paid Amount",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { paidAmount } = levelSummaryDetail[tableMeta.rowIndex];
          return `${"₦ " + addTrailingCommas(paidAmount)}`;
        },
      },
    },
    {
      name: "totalPendingAmount",
      label: "Outstanding Amount",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { totalPendingAmount } = levelSummaryDetail[tableMeta.rowIndex];
          return `${"₦ " + addTrailingCommas(totalPendingAmount)}`;
        },
      },
    },
    {
      name: "wallet",
      label: "Wallet Amount",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { wallet } = levelSummaryDetail[tableMeta.rowIndex];
          return `${"₦ " + addTrailingCommas(wallet)}`;
        },
      },
    },
    // { name: "totalAmount", label: "Total Amount" },
    // { name: "paidAmount", label: "Paid Amount" },
    // { name: "outstandingAmount", label: "Outstanding Amount" },
    // { name: "wallet", label: "Wallet Amount" },
    // {
    //   name: "paid",
    //   label: "Status",
    //   options: {
    //     customBodyRender: (paid) => (paid ? "Paid" : "Not Paid"),
    //   },
    // },
  ];

  if (levelSummaryDetailLoading) {
    return <OnboardingLoader />;
  }

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar institutionDetails={institutionDetails} />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />

        <>
          <div>
            <h5
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                // marginBottom: "20px",
              }}
            >
              Reporting
            </h5>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
              marginTop: "30px",
              // marginBottom: "10px",
            }}
            onClick={() => navigate(-1)}
          >
            <img src={backArrow} alt="back arrow" />
            {levelSummaryDetail && (
              <h5 style={{ marginBottom: 0 }}>
                {levelSummaryDetail[0]?.class}
              </h5>
            )}
          </div>

          <div
            style={{
              marginTop: "20px",
            }}
          >
            <TableComponent
              data={levelSummaryDetail}
              title=""
              columns={columns}
            />

            <br />
            <br />
          </div>
        </>
      </Container>
    </Container>
  );
};

export default LevelSummaryDetailPage;
