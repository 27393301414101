
import React, { useEffect } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap';

import { useLocation } from 'react-router-dom';
import parenticon from '../assets/images/parenticon.svg';
import schoolicon from '../assets/images/schoolicon.svg';
import studenticon from '../assets/images/students.svg';
import apiicon from '../assets/images/APIs.svg';

import '../index.css';

function WhyAdept() {

  const location = useLocation();

  useEffect(() => {
    const about = document.getElementById('about');

    if (about && location.state?.scrollToSection) {
      about.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.state?.scrollToSection]);


  return (
    <Container fluid id="about" className="whyAdept">
      <Row>
        <h2 className="headertext" style={{ color: '#F14810' }}><b>A4E Users</b></h2>
        <p className="whyadeptparagraph">
          Discover how A4E users benefit from our platform's intuitive features for managing payments.
        </p>

      </Row>
      <br />
      <Row >
        <Col xs={12} md={6} lg={7} >
          <div className="whyadeptcontainer">
            <div className="innerwhyadeptcontainer" >
              <Image style={{ marginTop: -1 + 'rem' }} src={schoolicon} fluid />
              <div className="schoolpart">
                <h4><b>Schools:</b></h4>
                <p style={{ fontSize: 0.8 + 'rem', marginTop: -0.5 + 'rem', }}><b>Educational institutions that will use the web application to manage payments</b></p>
                <p style={{ fontSize: 0.8 + 'rem' }}>
                  Educational institutions rely on our web application to streamline payment processes, enabling them to efficiently manage fees, monitor transactions, and generate insightful reports, ultimately optimizing administrative workflows and fostering a conducive learning environment.
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} lg={5} >
          <div className="whyadeptcontainer">
            <div className="innerwhyadeptcontainer">
              <Image style={{ marginTop: -1 + 'rem', }} src={parenticon} fluid />
              <div>
                <h4><b>Parents:</b></h4>
                <p style={{ fontSize: 0.8 + 'rem', marginTop: -0.5 + 'rem', }}><b>Users who will utilize the mobile application to make fee payments.</b></p>
                <p style={{ fontSize: 0.8 + 'rem' }}>
                  Parents enjoy the convenience of our mobile application for hassle-free fee payments, ensuring a smooth transaction experience.
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row style={{ marginTop: 2 + 'rem' }}>
        <Col xs={12} md={6} lg={5} >
          <div className="whyadeptcontainer">
            <div className="innerwhyadeptcontainer">
              <Image style={{ marginTop: -1 + 'rem', }} src={studenticon} fluid />
              <div>
                <h4><b>Students:</b></h4>
                <p style={{ fontSize: 0.8 + 'rem', marginTop: -0.5 + 'rem', }}><b>Beneficiaries of the payment system.</b></p>
                <p style={{ fontSize: 0.8 + 'rem' }}>
                  Students are the primary beneficiaries of our payment system, simplifying their fee payment process and fostering a stress-free learning environment and promoting academic excellence..
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} lg={7} >
          <div className="whyadeptcontainer">
            <div className="innerwhyadeptcontainer">
              <Image style={{ marginTop: -1 + 'rem' }} src={apiicon} fluid />
              <div className="schoolpart">
                <h4><b>LMS Startups and Organizations:</b></h4>
                <p style={{ fontSize: 0.8 + 'rem', marginTop: -0.5 + 'rem', }}><b>Beneficiaries of "Adept for School" API</b></p>
                <p style={{ fontSize: 0.8 + 'rem' }}>
                  LMS startups and organizations leverage the "Adept for School" API to seamlessly integrate payment functionalities into their platforms, providing users with a seamless experience, increasing user engagement, and driving growth, thereby establishing themselves as industry leaders in the educational technology sector.
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default WhyAdept