import React from "react";
import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

// import SliderItems from '../../../component/SliderItems.js';

// import Button from "../../../component/button/button.js";

// import logo from "../../../assets/svgs/logo.svg"
import "../../auth/landing/landingPage.css";

// import WelcomeImage from "../../../assets/svgs/welcome.gif";

const InstitutionOnboardSuccessPage = () => {
  return (
    <div>
      <>
        <Container fluid className="landing">
          <center className="messagebody">
            {/* <Image src={Welcome} style={{ width: 12 + 'rem', borderRadius: 100 + '%' }} fluid />  */}

            <div style={{ marginTop: 10 + '%' }}>
              <h3><b>Institution Onboarding started Successfully! <small>Kindly proceed to complete the process</small></b></h3>
              {/* <p>Lorem ipsum dolor sit amet consectetur. Lectus neque ut vestibulum molestie
                tincidunt elementum. Enim laoreet egestas consectetur condimentum neque a eu.
                Dictum tellus pulvinar quam praesent urna justo.
                nim laoreet egestas consectetur condimentum neque a
              </p> */}

            </div>

            <Link to="/continue-setup-institution">
              <Button variant="primary" style={{ backgroundColor: '#F14810', borderColor: '#F14810' }} className="button" size="md" >
                <span style={{ fontSize: 0.8 + 'rem', marginLeft: 0.7 + 'rem', marginRight: 0.7 + 'rem' }}>Get Started</span>
              </Button>
            </Link>

          </center>

        </Container>
      </>
    </div>

  );
};

export default InstitutionOnboardSuccessPage;
