import React from "react";
import { Button } from "react-bootstrap";

const Notifications = () => {
  return (
    <>
      {/* <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <ModalComponent showModal={showModal} closeModal={handleCloseModal}>
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!</h3>
          <p style={{ fontSize: "18px" }}>
            Institution Details have been updated successfully
          </p>
        </div>
      </ModalComponent> */}
      {/* <h6 style={{ fontSize: "14px", fontWeight: 600 }}>Notification</h6>
      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "8px",
          padding: "15px",
          display: "flex",
          gap: "30px",
        }}
        className="details-in-settings"
      >
        <div style={{ flexBasis: "40%" }}>
          <h6>Set Notification</h6>
          <p>
            Set up the various required notifications here. Clicking the "Save
            changes" button will update the existing information{" "}
          </p>
        </div>
        <div
          style={{ flexBasis: "60%", display: "flex", flexDirection: "column" }}
        >
          <article style={{ marginBottom: "15px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "15px",
                alignItems: "center",
                marginBottom: "7px",
              }}
            >
              <h6 style={{ fontSize: "14px", marginBottom: "0px" }}>
                Generated Report Notification
              </h6>
              <div
                className="calender-switch-container"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label className="switch">
                  <input type="checkbox" className="toggle" />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <p>
              Configure notifications for generated reports. Receive updates on new
              reports and important insights.
            </p>
          </article>

          <article style={{ marginBottom: "15px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "15px",
                alignItems: "center",
                marginBottom: "7px",
              }}
            >
              <h6 style={{ fontSize: "14px", marginBottom: "0px" }}>
                Generated Report Notification
              </h6>
              <div
                className="calender-switch-container"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label className="switch">
                  <input type="checkbox" className="toggle" />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet consectetur. Tellus pulvinar cras sed
              posuere duis.Velit euismod quis sed ut quis.
            </p>
          </article>

          <article style={{ marginBottom: "15px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "15px",
                alignItems: "center",
                marginBottom: "7px",
              }}
            >
              <h6 style={{ fontSize: "14px", marginBottom: "0px" }}>
                Generated Report Notification
              </h6>
              <div
                className="calender-switch-container"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label className="switch">
                  <input type="checkbox" className="toggle" />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet consectetur. Tellus pulvinar cras sed
              posuere duis.Velit euismod quis sed ut quis.
            </p>
          </article>

          <article style={{ marginBottom: "15px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "15px",
                alignItems: "center",
                marginBottom: "7px",
              }}
            >
              <h6 style={{ fontSize: "14px", marginBottom: "0px" }}>
                Generated Report Notification
              </h6>
              <div
                className="calender-switch-container"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label className="switch">
                  <input type="checkbox" className="toggle" />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet consectetur. Tellus pulvinar cras sed
              posuere duis.Velit euismod quis sed ut quis.
            </p>
          </article>

          <div
            style={{
              padding: "10px",
              marginLeft: "auto",
              marginTop: "auto"
            }}
          >
            <Button
              className="button"
              size="md"
              style={{
                backgroundColor: "#F14810",
                color: "#ffffff",
                border: "none",
                outline: "none",
              }}
              //   onClick={handleSave}
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.3 + "rem",
                  marginRight: 0.3 + "rem",
                }}
              >
                Save Changes
              </span>
            </Button>
          </div>
        </div>
      </div> */}

      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "8px",
          padding: "15px",
          display: "flex",
          gap: "30px",
        }}
        className="details-in-settings"
      >
        <div style={{ flexBasis: "40%" }}>
          <h6>Set Notification</h6>
          <p>
            Configure various notifications to receive updates. Clicking the "Save
            changes" button will update the existing notification settings.
          </p>
        </div>
        <div
          style={{
            flexBasis: "60%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <article style={{ marginBottom: "15px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "15px",
                alignItems: "center",
                marginBottom: "7px",
              }}
            >
              <h6 style={{ fontSize: "14px", marginBottom: "0px" }}>
                Generated Report Notification
              </h6>
              <div className="calender-switch-container">
                <label className="switch">
                  <input type="checkbox" className="toggle" />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <p>
              Configure notifications for generated reports. Receive updates on new
              reports and important insights.
            </p>
          </article>

          <article style={{ marginBottom: "15px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "15px",
                alignItems: "center",
                marginBottom: "7px",
              }}
            >
              <h6 style={{ fontSize: "14px", marginBottom: "0px" }}>
                Payment Reminder Notification
              </h6>
              <div className="calender-switch-container">
                <label className="switch">
                  <input type="checkbox" className="toggle" />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <p>
              Enable reminders for pending payments. Stay updated on overdue fees
              and pending transactions.
            </p>
          </article>

          <article style={{ marginBottom: "15px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "15px",
                alignItems: "center",
                marginBottom: "7px",
              }}
            >
              <h6 style={{ fontSize: "14px", marginBottom: "0px" }}>
                Event Notification
              </h6>
              <div className="calender-switch-container">
                <label className="switch">
                  <input type="checkbox" className="toggle" />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <p>
              Stay informed about upcoming events. Get notifications about school
              events and important dates.
            </p>
          </article>

          <article style={{ marginBottom: "15px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "15px",
                alignItems: "center",
                marginBottom: "7px",
              }}
            >
              <h6 style={{ fontSize: "14px", marginBottom: "0px" }}>
                Announcement Notification
              </h6>
              <div className="calender-switch-container">
                <label className="switch">
                  <input type="checkbox" className="toggle" />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <p>
              Receive important announcements. Stay updated on school news and
              announcements from the administration.
            </p>
          </article>

          <div
            style={{
              padding: "10px",
              marginLeft: "auto",
              marginTop: "auto",
            }}
          >
            <Button
              className="button"
              size="md"
              style={{
                backgroundColor: "#F14810",
                color: "#ffffff",
                border: "none",
                outline: "none",
              }}
            //   onClick={handleSave}
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.3 + "rem",
                  marginRight: 0.3 + "rem",
                }}
              >
                Save Changes
              </span>
            </Button>
          </div>
        </div>
      </div>

    </>
  );
};

export default Notifications;
