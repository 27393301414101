import React from 'react'

const SettingsIcon = ({active}) => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0027 17.375C10.8576 17.375 9.75931 16.9141 8.94956 16.0936C8.13981 15.2731 7.6849 14.1603 7.6849 13C7.6849 11.8397 8.13981 10.7269 8.94956 9.90641C9.75931 9.08594 10.8576 8.625 12.0027 8.625C13.1479 8.625 14.2461 9.08594 15.0559 9.90641C15.8656 10.7269 16.3205 11.8397 16.3205 13C16.3205 14.1603 15.8656 15.2731 15.0559 16.0936C14.2461 16.9141 13.1479 17.375 12.0027 17.375ZM21.1688 14.2125C21.2182 13.8125 21.2552 13.4125 21.2552 13C21.2552 12.5875 21.2182 12.175 21.1688 11.75L23.7719 9.7125C24.0063 9.525 24.068 9.1875 23.9199 8.9125L21.4526 4.5875C21.3046 4.3125 20.9715 4.2 20.7001 4.3125L17.6282 5.5625C16.9867 5.075 16.3205 4.65 15.5433 4.3375L15.0869 1.025C15.0618 0.877774 14.9861 0.744286 14.8732 0.648192C14.7603 0.552097 14.6175 0.4996 14.4701 0.500002H9.5354C9.22698 0.500002 8.96791 0.725002 8.91856 1.025L8.46211 4.3375C7.6849 4.65 7.01872 5.075 6.37722 5.5625L3.30539 4.3125C3.03399 4.2 2.7009 4.3125 2.55286 4.5875L0.0855318 8.9125C-0.0748444 9.1875 -0.00082446 9.525 0.233572 9.7125L2.8366 11.75C2.78726 12.175 2.75025 12.5875 2.75025 13C2.75025 13.4125 2.78726 13.8125 2.8366 14.2125L0.233572 16.2875C-0.00082446 16.475 -0.0748444 16.8125 0.0855318 17.0875L2.55286 21.4125C2.7009 21.6875 3.03399 21.7875 3.30539 21.6875L6.37722 20.425C7.01872 20.925 7.6849 21.35 8.46211 21.6625L8.91856 24.975C8.96791 25.275 9.22698 25.5 9.5354 25.5H14.4701C14.7785 25.5 15.0375 25.275 15.0869 24.975L15.5433 21.6625C16.3205 21.3375 16.9867 20.925 17.6282 20.425L20.7001 21.6875C20.9715 21.7875 21.3046 21.6875 21.4526 21.4125L23.9199 17.0875C24.068 16.8125 24.0063 16.475 23.7719 16.2875L21.1688 14.2125Z"
        fill={active ? "#ffffff" : "#464646"}
      />
    </svg>
  );
}

export default SettingsIcon