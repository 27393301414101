import { useEffect, useRef, useState } from "react";
import { useGetInvoiceDetailsQuery } from "../../../../../redux/features/institution/studentApiSlice";
import { addTrailingCommas } from "../../../../../utils/utils";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import { useGetInstitutionLogoQuery } from "../../../../../redux/features/institution/institutionApiSlice";
import { useSelector } from "react-redux";

const DownloadStudentInvoiceComponent = ({ studentInvoiceId }) => {
  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const {
    data: invoiceDetails,
    isLoading: detailsLoading,
    refetch: refetchInvoiceDetail,
  } = useGetInvoiceDetailsQuery(studentInvoiceId);

  const {
    data: institutionLogo,
    refetch,
    isLoading,
  } = useGetInstitutionLogoQuery(instId);

  const componentRef = useRef();

  useEffect(() => {
    // Ensure the component is attached to the document before capturing
    if (componentRef.current) {
      // Additional setup or actions can be performed here if needed
      refetchInvoiceDetail();
    }
  }, []);

  if (detailsLoading || isLoading) {
    return <OnboardingLoader />;
  }
  return (
    <>
      <div
        ref={componentRef}
        style={{
          margin: 5,
          padding: 20,
          boxSizing: "border-box",
          fontFamily: "'Jost', sans-serif",
          fontSize: "14px",
          border: `1px solid ${invoiceDetails?.brandColour}`,
          borderRadius: "8px",
        }}
      >
        {/* Header Table backgroundColor: '#ffede8' */}
        <table
          style={{
            width: "100%",
            border: `1px solid ${invoiceDetails?.brandColour}`,
            padding: "20px",
          }}
        >
          <tbody>
            <tr>
              <td style={{ minWidth: "85px" }}>
                <img
                  src={institutionLogo?.logo}
                  alt="school logo"
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "100%",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                />
              </td>
              <td style={{ width: "100%" }}>
                <h2
                  style={{
                    fontSize: "18px",
                    color: "black",
                    fontWeight: 700,
                    margin: "0px",
                    marginBottom: "2px",
                    maxWidth: "500px",
                  }}
                >
                  {invoiceDetails?.institutionName}
                </h2>
                <p
                  style={{
                    maxWidth: "500px",
                    margin: "0px",
                    fontSize: "14px",
                    color: "#6e6e6e",
                    fontStyle: "italic",
                  }}
                >
                  {invoiceDetails?.institutionAddress}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        {/* Main Content Table */}
        <table
          style={{
            width: "100%",
            padding: "20px",
            maxWidth: "600px",
            marginTop: "10px",
          }}
        >
          <tbody>
            {/* Row 1 */}
            <tr>
              <td>
                <h4
                  style={{
                    fontSize: "16px",
                    color: "black",
                    fontWeight: 600,
                    margin: "0",
                    marginBottom: "5px",
                  }}
                >
                  {invoiceDetails?.studentName}
                </h4>
                <p
                  style={{
                    margin: "0",
                    fontSize: "14px",
                    color: "#6e6e6e",
                    fontStyle: "italic",
                    marginBottom: "15px",
                  }}
                >
                  Student Name
                </p>
              </td>
              <td>
                <h4
                  style={{
                    fontSize: "16px",
                    color: "black",
                    fontWeight: 600,
                    margin: "0",
                    marginBottom: "5px",
                  }}
                >
                  {invoiceDetails?.studentClass}
                </h4>
                <p
                  style={{
                    margin: "0",
                    fontSize: "14px",
                    color: "#6e6e6e",
                    fontStyle: "italic",
                    marginBottom: "15px",
                  }}
                >
                  Class
                </p>
              </td>
            </tr>

            {/* Row 2 */}
            <tr>
              <td>
                <h4
                  style={{
                    fontSize: "16px",
                    color: "black",
                    fontWeight: 600,
                    margin: "0",
                    marginBottom: "5px",
                  }}
                >
                  {invoiceDetails?.parent?.name}
                </h4>
                <p
                  style={{
                    margin: "0",
                    fontSize: "14px",
                    color: "#6e6e6e",
                    fontStyle: "italic",
                    marginBottom: "15px",
                  }}
                >
                  Parent Name
                </p>
              </td>
            </tr>

            {/* Row 3 */}
            <tr>
              <td>
                <h4
                  style={{
                    fontSize: "16px",
                    color: "black",
                    fontWeight: 600,
                    margin: "0",
                    marginBottom: "5px",
                  }}
                >
                  Student Fees
                </h4>
                <p
                  style={{
                    margin: "0",
                    fontSize: "14px",
                    color: "#6e6e6e",
                    fontStyle: "italic",
                    marginBottom: "15px",
                  }}
                >
                  Invoice Name:
                </p>
              </td>
              <td>
                <h4
                  style={{
                    fontSize: "16px",
                    color: "black",
                    fontWeight: 600,
                    margin: "0",
                    marginBottom: "5px",
                  }}
                >
                  {invoiceDetails?.invoiceDate}
                </h4>
                <p
                  style={{
                    margin: "0",
                    fontSize: "14px",
                    color: "#6e6e6e",
                    fontStyle: "italic",
                    marginBottom: "15px",
                  }}
                >
                  Invoice Due Date
                </p>
              </td>
            </tr>

            {/* Row 4 */}
            <tr>
              <td>
                <h4
                  style={{
                    fontSize: "16px",
                    color: "black",
                    fontWeight: 600,
                    margin: "0",
                    marginBottom: "5px",
                  }}
                >
                  Fees:
                </h4>
              </td>
            </tr>

            {/* Row 5 */}
            {invoiceDetails?.compulsoryFees &&
              invoiceDetails?.compulsoryFees.length > 0 &&
              invoiceDetails?.compulsoryFees.map((fee, i) => (
                <tr key={i} className={i % 2 === 0 ? "even-row" : "odd-row"}>
                  {/* <td align="center">{i + 1}</td> */}
                  <td align="center">{fee.feeDescription} (Compulsory)</td>
                  <td align="center">{addTrailingCommas(fee.amount)}</td>
                  <td align="center">
                    {fee.paid && <span style={{ color: "#32CD32" }}>Paid</span>}
                    {!fee.paid && (
                      <span style={{ color: "#FF0000" }}>Not Paid</span>
                    )}
                    {/* {fee.paid ? "Paid" : "Not Paid"} */}
                  </td>
                </tr>
              ))}
            {invoiceDetails?.optionalFees &&
              invoiceDetails?.optionalFees.length > 0 &&
              invoiceDetails?.optionalFees.map((fee, i) => (
                <tr key={i} className={i % 2 === 0 ? "even-row" : "odd-row"}>
                  {/* <td align="center">{i + 1}</td> */}
                  <td align="center">{fee.feeDescription} (Optional)</td>
                  <td align="center">{addTrailingCommas(fee.amount)}</td>
                  <td align="center">
                    {fee.paid && <span style={{ color: "#32CD32" }}>Paid</span>}
                    {!fee.paid && (
                      <span style={{ color: "#FF0000" }}>Not Paid</span>
                    )}
                    {/* {fee.paid ? "Paid" : "Not Paid"} */}
                  </td>
                </tr>
              ))}

            {/* Add more rows for other fees as needed */}
            <br />
            {/* Row for Sub Total */}
            {/* <tr>
                            <td>
                                <h4 style={{ fontSize: '16px', color: 'black', fontWeight: 600, margin: '0', marginBottom: '10px' }}>
                                    Sub Total -
                                </h4>
                            </td>
                            <td>
                                <h4 style={{ fontSize: '16px', color: 'black', fontWeight: 600, margin: '0', marginBottom: '10px' }}>
                                    ₦ {addTrailingCommas(invoiceDetails?.compulsoryFeeTotal)}
                                </h4>
                            </td>
                        </tr> */}

            {/* Row for VAT */}
            {/* <tr>
                            <td>
                                <h4 style={{ fontSize: '16px', color: 'black', fontWeight: 600, margin: '0', marginBottom: '20px' }}>
                                    VAT (7.5%) -
                                </h4>
                            </td>
                            <td>
                                <h4 style={{ fontSize: '16px', color: 'black', fontWeight: 600, margin: '0', marginBottom: '20px' }}>
                                    ₦ {addTrailingCommas(invoiceDetails?.totalAmount * 0.075)}
                                </h4>
                            </td>
                        </tr> */}

            {/* Row for Total */}
            <tr>
              <td>
                <h4
                  style={{
                    fontSize: "16px",
                    color: "#F14810",
                    fontWeight: 600,
                    margin: "0",
                    marginBottom: "0",
                  }}
                >
                  Total -
                </h4>
              </td>
              <td>
                <h4
                  style={{
                    fontSize: "16px",
                    color: "#F14810",
                    fontWeight: 600,
                    margin: "0",
                    marginBottom: "0",
                  }}
                >
                  ₦ {addTrailingCommas(invoiceDetails?.totalAmount)}
                </h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DownloadStudentInvoiceComponent;
