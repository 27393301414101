// import Button from "../../../../../component/button/button";
import { InputGroup, Form, Row, Col, Button } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import {
  useGetCountriesQuery,
  useGetStatesQuery,
} from "../../../../../redux/features/auth/authApiSlice";
import {
  useUpdateInstitutionDetailsMutation,
  useGetInstitutionQuery,
  useGetInstitutionTypeQuery,
  useEditInstitutionDetailsMutation,
} from "../../../../../redux/features/institution/institutionApiSlice";
import { useSelector } from "react-redux";
import ToastComponent from "../../../../../component/toast/toast";
import successGIF from "../../../../../assets/svgs/successGIF.gif";
import dropdownIcon from "../../../../../assets/svgs/dropdownIcon.svg";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import ModalComponent from "../../../../../component/modal/modal";
import { Link } from "react-router-dom";
import { EnvelopeAt, Link45deg } from "react-bootstrap-icons";

const InstitutionDetailsPage = () => {
  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const {
    data: institutionDetails,
    isLoading: detailsLoading,
    refetch: refetchDetails,
  } = useGetInstitutionQuery(id);

  console.log(institutionDetails);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsNames, setSelectedItemsNames] = useState([]);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [selectedSchoolTypes, setSelectedSchoolTypes] = useState([]);

  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [selectedStateId, setSelectedStateId] = useState("");
  const [selectAll, setSelectAll] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  console.log(selectedCountryId);

  const { data: countryData } = useGetCountriesQuery();

  const { data: states } = useGetStatesQuery(
    selectedCountryId ? selectedCountryId : 1,
    { skip: !countryData }
  );

  const { data: institutionTypes } = useGetInstitutionTypeQuery(undefined, {
    skip: !states,
  });

  const [updateInstitutionDetails, { isLoading: updateLoading }] =
    useEditInstitutionDetailsMutation();

  // const [updateInstitutionDetails, { isLoading: updateLoading }] =
  //   useUpdateInstitutionDetailsMutation();

  const [schoolDetails, setSchoolDetails] = useState({
    institutionName: institutionDetails?.institutionName,
    // institutionType: institutionDetails?.institutionType,
    // institutionType: institutionDetails?.institutionType,
    institutionEmail: institutionDetails?.institutionEmail,
    schoolContactNumber: institutionDetails?.schoolContactNumber,
    website: institutionDetails?.website,
    address: institutionDetails?.address,
    country: institutionDetails?.country,
    state: institutionDetails?.state,
  });

  const {
    institutionName,
    institutionType,
    institutionEmail,
    schoolContactNumber,
    website,
    address,
    country,
    state,
  } = schoolDetails;

  const handleCloseModal = () => {
    setShowModal(false);
    refetchDetails();
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setSchoolDetails({ ...schoolDetails, [name]: value });

    // if (name === "country") {
    //   setSelectedCountryId(e.target.value);
    // }
  };

  const handleSave = async () => {
    const params = {
      institutionId: id,
      body: {
        institutionName: institutionName,
        // institutionType: selectedItems,
        institutionTypes: selectedItems,
        institutionEmail: institutionEmail,
        schoolContactNumber,
        website,
        address,
        // countryId: selectedCountryId,
        // stateId: selectedStateId,
        country: selectedCountryId,
        state: selectedStateId,
      },
    };

    try {
      const res = await updateInstitutionDetails(params).unwrap();
      console.log(res);
      setShowModal(true);
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  useEffect(() => {
    setSchoolDetails({
      institutionName: institutionDetails?.institutionName,
      // institutionType: institutionDetails?.institutionType,
      institutionEmail: institutionDetails?.institutionEmail,
      schoolContactNumber: institutionDetails?.schoolContactNumber,
      website: institutionDetails?.website,
      address: institutionDetails?.address,
      country: institutionDetails?.country,
      state: institutionDetails?.state,
    });
    // setSelectedItems(institutionDetails?.institutionTypes)
  }, [institutionDetails]);

  useEffect(() => {
    const country = countryData?.filter(
      (country) => country.countryName === schoolDetails.country
    );
    if (country) {
      setSelectedCountryId(country[0]?.id);
    }

    const state = states?.filter(
      (state) => state.stateName === schoolDetails.state
    );
    if (state) {
      setSelectedStateId(state[0]?.id);
    }
  }, [country, countryData, states, state]);

  useEffect(() => {
    const names = institutionDetails?.institutionTypes?.map(
      (item) => item.typeName
    );

    const selected = institutionDetails?.institutionTypes?.map((item) => {
      return { id: item.id, typeName: item.typeName };
    });

    setSelectedItems(selected);
    setSelectedItemsNames(names);
  }, [institutionDetails]);

  const handleItemClick = (obj) => {
    if (selectedItems.find((item) => item.id === obj.id)) {
      setSelectedItems(selectedItems.filter((item) => item.id !== obj.id));
    } else {
      setSelectedItems([...selectedItems, { ...obj }]);
    }
  };
  console.log("SchoolTypes", selectedItems);

  useEffect(() => {
    const names = selectedItems?.map((item) => item.typeName);
    setSelectedItemsNames(names);
  }, [selectedItems]);

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowCategoryDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  if (detailsLoading || updateLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <ModalComponent showModal={showModal} closeModal={handleCloseModal}>
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!</h3>
          <p style={{ fontSize: "18px" }}>
            School Details updated successfully!!! The update will be reviewed
            and validated by a Adept user.
          </p>
        </div>
      </ModalComponent>

      <h6 style={{ fontSize: "14px", fontWeight: 600 }}>School Details</h6>
      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "8px",
          padding: "15px",
          display: "flex",
          gap: "30px",
        }}
        className="details-in-settings"
      >
        <div style={{ flexBasis: "40%" }}>
          <h6>Edit School Details</h6>
          <p>
            Edit the School details provided in the onboarding stage here.
            Clicking the "Save changes" button will update the existing
            information{" "}
          </p>

          <p style={{ fontWeight: "500", margin: "5px 0" }}>
            Click the button below to watch how to edit your set-up.
          </p>

          <Link to="/help">
            <span style={{ color: "#F14810", fontWeight: "600" }}>
              Watch Video
            </span>
          </Link>
        </div>
        <div style={{ flexBasis: "60%" }}>
          <Form className="form">
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                School Name{" "}
                <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
              </Form.Label>

              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                type="text"
                name="institutionName"
                value={institutionName}
                onChange={handleOnChange}
                placeholder="Enter Your School"
                required
              />
            </Form.Group>

            <div
              style={{
                marginBottom: "15px",
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <p style={{ marginBottom: "5px", fontWeight: "500" }}>
                School Type{" "}
                <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
              </p>

              <div ref={dropdownRef}>
                <div style={{ position: "relative" }}>
                  <label
                    style={{
                      width: "100%",
                      backgroundColor: "#ededed",
                      borderRadius: "5px",
                      padding: "7px 10px",
                      cursor: "pointer",
                      fontSize: "14px",
                    }}
                    onClick={() =>
                      setShowCategoryDropdown(!showCategoryDropdown)
                    }
                  >
                    {selectedItems?.length > 0
                      ? selectedItemsNames?.join(", ")
                      : "Select School Type"}
                  </label>
                  <img
                    src={dropdownIcon}
                    alt="dropdown icon"
                    style={{ position: "absolute", right: "15px", top: "40%" }}
                  />
                </div>

                {showCategoryDropdown && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      zIndex: "10",
                      transition: "all",
                      transitionTimingFunction: "ease-in-out",
                      transitionDuration: "0.3s",
                      backgroundColor: "white",
                      width: "100%",
                      border: "1px solid rgb(221, 221, 221)",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    {institutionTypes &&
                      institutionTypes.map(({ typeName, id }) => (
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            marginBottom: "2px",
                          }}
                          key={id}
                        >
                          <div style={{ position: "relative" }}>
                            <label className="checkbox-container">
                              <input
                                type="checkbox"
                                checked={selectedItems
                                  ?.map((item) => item.id)
                                  .includes(id)}
                                onChange={(e) =>
                                  handleItemClick({ typeName, id })
                                }
                                style={{
                                  marginRight: "5px",
                                  width: "auto",
                                  appearance: "auto",
                                }}
                              />
                              <span className={`custom-checkbox`}></span>
                            </label>
                          </div>
                          <span>{typeName}</span>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                School Email <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <InputGroup className="mb-3">
                <InputGroup.Text
                  id="basic-addon1"
                  style={{ backgroundColor: "#F14810", color: "#ffffff" }}
                >
                  <EnvelopeAt size={22} />
                </InputGroup.Text>

                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                    cursor: "not-allowed",
                  }}
                  type="email"
                  placeholder="Enter Your School Email"
                  name="institutionEmail"
                  value={institutionEmail}
                  onChange={handleOnChange}
                  required
                  disabled={true}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                Contact Number <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <InputGroup className="mb-3">
                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type="number"
                  placeholder="Enter your School Contact Number"
                  name="schoolContactNumber"
                  value={schoolContactNumber}
                  onChange={handleOnChange}
                  required
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">School Website</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text
                  id="basic-addon1"
                  style={{ backgroundColor: "#F14810", color: "#ffffff" }}
                >
                  <Link45deg size={22} />
                </InputGroup.Text>
                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  name="website"
                  value={website}
                  onChange={handleOnChange}
                  placeholder="www.yourschoolwebsite.com"
                  aria-label="link"
                  aria-describedby="basic-addon1"
                  required
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                School Address <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                type="text"
                name="address"
                value={address}
                onChange={handleOnChange}
                placeholder="Enter Your School Address"
                required
              />
            </Form.Group>

            <Row>
              <Col>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">Country</Form.Label>

                  <Form.Select
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    name="country"
                    value={country}
                    onChange={handleOnChange}
                    required
                  >
                    <option value="" disabled>
                      Select Country
                    </option>
                    {countryData &&
                      countryData.map(({ countryName, id }) => (
                        // <option
                        //   key={id}
                        //   value={id}
                        // >
                        //   {countryName}
                        // </option>
                        <option key={id} value={countryName}>
                          {countryName}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">State</Form.Label>

                  <Form.Select
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    name="state"
                    value={state}
                    onChange={handleOnChange}
                    placeholder="Enter Your Phone number"
                    required
                  >
                    <option value="" disabled>
                      Select State
                    </option>
                    {states &&
                      states.map(({ stateName, id }) => (
                        // <option key={id} value={id}>
                        //   {stateName}
                        // </option>
                        <option key={id} value={stateName}>
                          {stateName}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>

          <div
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Button
              className="button"
              size="md"
              style={{
                backgroundColor: "#F14810",
                color: "#ffffff",
                border: "none",
                outline: "none",
              }}
              onClick={handleSave}
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.3 + "rem",
                  marginRight: 0.3 + "rem",
                }}
              >
                Save Changes
              </span>
            </Button>
          </div>

          {/* <div style={{ marginTop: "5px", float: "right" }}>
            <Button>Save Changes</Button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default InstitutionDetailsPage;
