import React, { useState, useEffect, useRef } from "react";
import dropdownIcon from "../../assets/svgs/dropdownIcon.svg";

const MultiSelectDropdown = ({
  category,
  options,
  selectedItems: externalSelectedItems = [],
  selectedItemsNames: externalSelectedItemNames = [],
  onSelect,
  dropdownId,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownOpenId, setDropdownOpenId] = useState(null);
  const [selectedItems, setSelectedItems] = useState([
    ...externalSelectedItems,
  ]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([...options]);
  const [selectedItemsNames, setSelectedItemsNames] = useState([
    ...externalSelectedItemNames,
  ]);

  useEffect(() => {
    setSelectedItems([...externalSelectedItems]);
    setSelectAll(externalSelectedItems?.length === options?.length);
  }, [externalSelectedItems, options]);

  useEffect(() => {
    // Filter institutions based on the search term
    const filtered = options?.filter(({ name }) =>
      name?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchTerm, options]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedItems(selectAll ? [] : filteredOptions?.map((item) => item.id));
    setSelectedItemsNames(filteredOptions?.map((item) => item.name));
    onSelect(selectAll ? [] : filteredOptions?.map((item) => item.id));
  };

  const handleItemClick = ({ name, id }) => {
    const updatedSelectedItems = selectedItems?.includes(id)
      ? selectedItems?.filter((itemId) => itemId !== id)
      : [...selectedItems, id];

    setSelectedItems(updatedSelectedItems);
    setSelectAll(updatedSelectedItems?.length === options?.length);
    onSelect(updatedSelectedItems);
    setSelectedItemsNames(selectedItemsNames.filter((i) => i !== name));
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <div style={{ position: "relative", marginTop: "5px" }}>
      <div ref={dropdownRef}>
        <label
          style={{
            width: "100%",
            backgroundColor: "#ededed",
            borderRadius: "5px",
            padding: "10px",
            cursor: "pointer",
            position: "relative",
            fontSize: 0.8 + "rem",
            fontFamily: "jost, sans-serif",
          }}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          {selectedItems.length > 0 ? (
            <>
              {selectedItems
                .map((itemId) => options?.find((item) => item.id === itemId))
                .map((item) => item?.name)
                .filter(Boolean)
                .join(", ")}
              {selectedItems?.length > 3 && " ..."}
            </>
          ) : (
            "Select..."
          )}
          <img
            src={dropdownIcon}
            alt="drop down icon"
            style={{ position: "absolute", right: "10px", top: "40%" }}
          />
        </label>

        {showDropdown && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              zIndex: "10",
              transition: "all",
              transitionTimingFunction: "ease-in-out",
              transitionDuration: "0.3s",
              backgroundColor: "white",
              width: "100%",
              maxHeight: "200px",
              overflow: "auto",
              border: "1px solid rgb(221, 221, 221)",
              borderRadius: "5px",
              padding: "10px",
              fontSize: 0.8 + "rem",
              fontFamily: "jost, sans-serif",
            }}
          >
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                marginBottom: "10px",
                padding: "5px",
                fontSize: 0.8 + "rem",
                width: "100%",
                boxSizing: "border-box",
              }}
            />
            <label style={{ display: "flex" }}>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
                style={{
                  marginRight: "5px",
                  width: "auto",
                  appearance: "auto",
                  fontSize: 0.8 + "rem",
                  fontFamily: "jost, sans-serif",
                }}
              />
              Select All
            </label>
            {filteredOptions?.map(({ name, id }) => (
              <label style={{ display: "flex" }} key={id}>
                <input
                  type="checkbox"
                  checked={selectedItems?.includes(id)}
                  onChange={() => handleItemClick({ name, id })}
                  style={{
                    marginRight: "5px",
                    width: "auto",
                    appearance: "auto",
                    fontSize: 0.8 + "rem",
                    fontFamily: "jost, sans-serif",
                  }}
                />
                {name}
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
