import { useState, useEffect } from "react";
// import eyeActionIcon from "../../../../assets/svgs/eyeActionIcon.svg";
import { Container } from "react-bootstrap";
import DashboardTopSection from "../../../components/dashboardTopSection/index";
import DashboardSidebar from "../../../components/dashboardSidebar/sidebar";
import TableComponent from "../../../../../../component/table/table";
import backArrow from "../../../../../../assets/svgs/backArrow.svg";
// import "./settlement.css";
// import { useNavigate } from "react-router-dom";
import { addTrailingCommas } from "../../../../../../utils/utils";
import { useSelector } from "react-redux";
import { useGetServiceAccountHistoryQuery, useGetSettlementHistoryQuery } from "../../../../../../redux/features/institution/institutionApiSlice";
import InstitutionAccountPage from "./institutionAccount";
import { useNavigate } from "react-router-dom";

const ServiceAccountHistoryPage = ({ historyDetails, setIsHistoryViewed }) => {
    //const [openInstitionAccountPage, setOpenInstitionAccountPage] = useState();
    const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

    //const history = useHistory();
    const handleBackToServiceAccountPage = () => {
        setIsHistoryViewed(false);
        console.log("VIEWED", setIsHistoryViewed);
        //window.history.back()
    }

    const { data: settlementHistory } = useGetServiceAccountHistoryQuery(instId, {
        skip: !instId,
    });
    console.log("HIST ", settlementHistory);

    const columns = [
        // { name: "id", label: "ID" },
        {
            name: "time",
            label: "Transaction Date",
            options: {
                customBodyRender: (date) => (date ? date?.split("T")[0] : "N/A"),
            },
        },
        { name: "type", label: "Transaction Type" },
        {
            name: "amount",
            label: "Transaction Amount",
            options: {
                customBodyRender: (amount) => (amount ? "₦ " + addTrailingCommas(amount) : 0),
            },
        },
        {
            name: "balanceBefore",
            label: "Balance Before",
            options: {
                customBodyRender: (amount) => (amount ? "₦ " + addTrailingCommas(amount) : 0),
            },
        },
        {
            name: "balanceAfter",
            label: "Balance After",
            options: {
                customBodyRender: (amount) => (amount ? "₦ " + addTrailingCommas(amount) : 0),
            },
        },
        { name: "source", label: "Source" },
        {
            name: "user",
            label: "User",
            options: {
                customBodyRender: (data) => (data ? data?.firstName + " " + data?.lastName : ""),
            },
        }
    ];


    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    cursor: "pointer",
                    marginBottom: "10px",
                }}
                //onClick={() => setIsHistoryViewed(true)} //handleBackToCollectionAccountPage
                onClick={() => handleBackToServiceAccountPage()}
            >
                <img src={backArrow} alt="back arrow" />
                <h5 style={{ marginBottom: 0, fontSize: "14px", fontWeight: 600 }}>
                    Service Account
                </h5>
            </div>

            <div
                style={{
                    border: "1px solid #F5F5F5",
                    borderRadius: "10px",
                    padding: "20px",
                }}
            >
                {/* <div
                    style={{
                        padding: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        alignItems: "flex-end",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "20px",
                        }}
                    >
                        <div>
                            <h5
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "500",
                                }}
                            >
                                ₦ {addTrailingCommas(settlementHistory?.totalSettlementAmount)}
                            </h5>
                            <p style={{ marginBottom: "0px", color: "#6A6A6A" }}>
                                Total Settlement Amount
                            </p>
                        </div>
                        <div>
                            <h5
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "500",
                                }}
                            >
                                ₦ {addTrailingCommas(settlementHistory?.totalCommissionAmount)}
                            </h5>
                            <p style={{ marginBottom: "0px", color: "#6A6A6A" }}>
                                Total Commission Amount
                            </p>
                        </div>
                    </div>
                </div> */}

                
                <TableComponent
                    data={settlementHistory}
                    title="Service Account History"
                    columns={columns}
                />
            </div>
        </>
    );
};

export default ServiceAccountHistoryPage;
