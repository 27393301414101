import { createSlice } from "@reduxjs/toolkit";

// store the token in localstorage so we can access it even if page reloads
const initialState = {
  userInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null,
  aboutSchoolInfo: null,
  contactPersonInfo: null,
  secondaryContactPersonInfo: null,
  technicalContactPersonInfo: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userInfo: (state, action) => {
      state.userInfo = action.payload;
      localStorage.setItem("userInfo", JSON.stringify(action.payload));
    },
    updateSetupComplete: (state) => {
      state.userInfo.setupComplete = true;
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    },
    updateAboutSchool: (state, action) => {
      state.aboutSchoolInfo = action.payload;
    },
    updateContactInfo: (state, action) => {
      state.contactPersonInfo = action.payload;
    },
    updateSecondaryContactInfo: (state, action) => {
      state.secondaryContactPersonInfo = action.payload;
    },
    updateTechnicalContactInfo: (state, action) => {
      state.technicalContactPersonInfo = action.payload;
    },
    // handle logout user
    logOut: (state) => {
      state.userInfo = null;
      window.location = '/login';
      localStorage.removeItem("userInfo");
    },
  },
});

export const {
  logOut,
  userInfo,
  updateAboutSchool,
  updateContactInfo,
  updateSecondaryContactInfo,
  updateTechnicalContactInfo,
  updateSetupComplete
} = authSlice.actions;

export default authSlice.reducer;
