import {
  Container,
  Row,
  Col,
  Navbar,
  Image,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { Link45deg, EnvelopeAt } from "react-bootstrap-icons";

import { useGetCountriesQuery } from "../../../../redux/features/auth/authApiSlice.js";
import { useGetStatesQuery } from "../../../../redux/features/auth/authApiSlice.js";
import { useGetInstitutionTypeQuery } from "../../../../redux/features/institution/institutionApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateAboutSchool } from "../../../../redux/features/auth/authSlice.js";
import ToastComponent from "../../../../component/toast/toast.js";
import "../../../../styles/formpage.css";
import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader.js";

const AboutInstitutionPage = ({ setActiveStage }) => {
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);

  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [selectedStateId, setSelectedStateId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsIds, setSelectedItemsIds] = useState([]);
  const [selectedItemsNames, setSelectedItemsNames] = useState([]);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);

  const dispatch = useDispatch();

  const { data: countryData } = useGetCountriesQuery();

  const { data: institutionTypesArray, isLoading: institutionTypesLoading } =
    useGetInstitutionTypeQuery();

  const { data: states, refetch: refetchStates } = useGetStatesQuery(
    selectedCountryId ? selectedCountryId : 1,
    // { skip: !countryData || typeof selectedCountryId != "number" }
    { skip: selectedCountryId === "" }
  );

  // get previous data if they have filled this page before
  const aboutInstitutionData = useSelector(
    (state) => state.auth.aboutSchoolInfo
  );

  console.log(aboutInstitutionData);

  const [registrationData, setRegistrationData] = useState({
    institutionName: aboutInstitutionData
      ? aboutInstitutionData.institutionName
      : "",
    // institutionType: aboutInstitutionData
    //   ? aboutInstitutionData.institutionType
    //   : "",
    institutionTypeIds: aboutInstitutionData
      ? aboutInstitutionData.institutionTypeIds
      : selectedItems,
    institutionTypes: aboutInstitutionData
      ? aboutInstitutionData.institutionTypes
      : selectedItemsNames,
    address: aboutInstitutionData ? aboutInstitutionData.address : "",
    state: aboutInstitutionData ? aboutInstitutionData.state : "",
    // stateId: aboutInstitutionData ? aboutInstitutionData.stateId : "",
    country: aboutInstitutionData ? aboutInstitutionData.country : "",
    // countryId: aboutInstitutionData ? aboutInstitutionData.countryId : "",
    institutionEmail: aboutInstitutionData
      ? aboutInstitutionData.institutionEmail
      : "",
    website: aboutInstitutionData ? aboutInstitutionData.website : "",
    schoolContactNumber: aboutInstitutionData
      ? aboutInstitutionData.schoolContactNumber
      : "",
    referralCode: aboutInstitutionData
      ? aboutInstitutionData.referralCode
      : "",
  });

  const {
    institutionEmail,
    institutionName,
    address,
    institutionTypeIds,
    country,
    state,
    website,
    schoolContactNumber,
    referralCode
  } = registrationData;

  const handleOnChange = (e) => {
    // console.log(e);
    const { name, value } = e.target;
    setRegistrationData({ ...registrationData, [name]: value });

    if (name === "country") {
      setSelectedCountryId(e.target.value);
      if (states) {
        refetchStates();
      }
    }
  };

  // console.log(country);
  console.log(states);
  // console.log(selectedCountryId);
  // console.log(selectedStateId);

  const handleGoToNextPage = () => {
    if (
      institutionEmail === "" ||
      institutionName === "" ||
      // institutionTypeIds ||
      country === "" ||
      state === "" ||
      address === "" ||
      schoolContactNumber === ""
    ) {
      // setIsFormNotFilled(true);
      setShowToast(true);
      setToastMessage("Please fill all fields");

      setTimeout(() => {
        setShowToast(false);
      }, 3000);

      return;
    }

    // check if there is special character in the name
    const regex = /^[a-zA-Z0-9\s.,-]+$/;

    console.log(!regex.test(institutionName));

    if (!regex.test(institutionName)) {
      setToastMessage("Institution name cannot contain special characters!");
      setShowToast(true);
      return;
    }

    setActiveStage("Contact Person");
    // dispatch(updateAboutSchool(registrationData));

    const { institutionTypeIds, institutionTypes, ...rest } = registrationData;
    const newObj = {
      ...rest,
      institutionTypeIds: selectedItemsIds,
      institutionTypes: selectedItemsNames,
      selectedItems: selectedItems,
    };

    dispatch(
      updateAboutSchool({
        ...newObj,
        selectedCountryId,
        selectedStateId,
      })
    );
  };

  useEffect(() => {
    const country = countryData?.filter(
      (country) => country.countryName === registrationData.country
    );
    if (country) {
      setSelectedCountryId(country[0]?.id);
    }

    const state = states?.filter(
      (state) => state.stateName === registrationData.state
    );
    if (state) {
      setSelectedStateId(state[0]?.id);
    }
  }, [country, countryData, states, state]);

  useEffect(() => {
    // setSelectedItems(aboutInstitutionData?.institutionTypeIds || selectedItems);
    setSelectedItems(aboutInstitutionData?.selectedItems || selectedItems);
    setSelectedItemsNames(
      aboutInstitutionData?.institutionTypes || selectedItemsNames
    );
  }, []);

  // close dropdown on outside dropdown click
  const dropdownRef = useRef(null);

  console.log(selectedItemsIds);

  const handleItemClick = (obj) => {
    if (selectedItems.find((item) => item.id === obj.id)) {
      setSelectedItems(selectedItems.filter((item) => item.id !== obj.id));
    } else {
      setSelectedItems([...selectedItems, { ...obj, selected: true }]);
    }
  };

  useEffect(() => {
    const names = selectedItems?.map((item) => item.typeName);
    setSelectedItemsNames(names);
    const ids = selectedItems?.map((item) => item.id);
    setSelectedItemsIds(ids);
  }, [selectedItems]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowCategoryDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // useEffect(())

  if (institutionTypesLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <div>
        <h3 className="FormHeader" style={{ marginTop: "15px" }}>
          Register Your Institution
        </h3>
        <div
          style={{
            display: "flex",
            gap: 0.8 + "rem",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <div>
            <svg
              width="55"
              height="55"
              viewBox="0 0 68 69"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M67.5 34.5C67.5 53.1396 52.3896 68.25 33.75 68.25C15.1104 68.25 0 53.1396 0 34.5C0 15.8604 15.1104 0.75 33.75 0.75C52.3896 0.75 67.5 15.8604 67.5 34.5ZM8.4375 34.5C8.4375 48.4797 19.7703 59.8125 33.75 59.8125C47.7297 59.8125 59.0625 48.4797 59.0625 34.5C59.0625 20.5203 47.7297 9.1875 33.75 9.1875C19.7703 9.1875 8.4375 20.5203 8.4375 34.5Z"
                fill="#E7EAEE"
                fillOpacity="0.8"
              />
              <path
                d="M63.2812 34.5C65.6112 34.5 67.5272 36.3961 67.2367 38.7079C66.6775 43.1579 65.2349 47.4665 62.9784 51.375C60.0162 56.5056 55.7556 60.7662 50.625 63.7284C45.4944 66.6905 39.6744 68.25 33.75 68.25C29.2368 68.25 24.7842 67.345 20.6508 65.6042C18.5035 64.6999 17.8194 62.0926 18.9844 60.0748C20.1493 58.057 22.7227 57.4031 24.9058 58.2172C27.7246 59.2683 30.7191 59.8125 33.75 59.8125C38.1933 59.8125 42.5583 58.6429 46.4062 56.4213C50.2542 54.1996 53.4496 51.0042 55.6713 47.1562C57.1867 44.5314 58.2126 41.666 58.7117 38.6993C59.0983 36.4016 60.9513 34.5 63.2812 34.5Z"
                fill="#F14810"
              />
              <path
                d="M19.278 30.676V28.354L24.804 27.184V40H22.302V29.884L19.278 30.676ZM34.7438 27.4H36.9938L29.4338 42.7H27.1118L34.7438 27.4ZM42.0896 33.862V32.62C42.4616 32.62 42.7916 32.572 43.0796 32.476C43.3796 32.38 43.6316 32.248 43.8356 32.08C44.0396 31.9 44.1956 31.702 44.3036 31.486C44.4236 31.258 44.4836 31.024 44.4836 30.784C44.4836 30.484 44.4236 30.22 44.3036 29.992C44.1836 29.764 44.0096 29.59 43.7816 29.47C43.5536 29.35 43.2596 29.29 42.8996 29.29C42.3956 29.29 41.9696 29.446 41.6216 29.758C41.2856 30.058 41.1176 30.442 41.1176 30.91H38.7236C38.7236 30.19 38.8976 29.554 39.2456 29.002C39.6056 28.438 40.0976 27.994 40.7216 27.67C41.3456 27.346 42.0716 27.184 42.8996 27.184C43.7756 27.184 44.5196 27.34 45.1316 27.652C45.7556 27.964 46.2296 28.378 46.5536 28.894C46.8776 29.41 47.0396 29.986 47.0396 30.622C47.0396 31.306 46.8236 31.894 46.3916 32.386C45.9596 32.866 45.3716 33.232 44.6276 33.484C43.8836 33.736 43.0376 33.862 42.0896 33.862ZM42.7916 40.252C42.0716 40.252 41.4296 40.144 40.8656 39.928C40.3016 39.712 39.8216 39.418 39.4256 39.046C39.0296 38.662 38.7236 38.23 38.5076 37.75C38.2916 37.258 38.1836 36.742 38.1836 36.202H40.7396C40.7396 36.61 40.8356 36.958 41.0276 37.246C41.2196 37.534 41.4776 37.756 41.8016 37.912C42.1256 38.056 42.4796 38.128 42.8636 38.128C43.2596 38.128 43.6016 38.062 43.8896 37.93C44.1896 37.786 44.4236 37.582 44.5916 37.318C44.7596 37.054 44.8436 36.724 44.8436 36.328C44.8436 36.04 44.7836 35.776 44.6636 35.536C44.5436 35.284 44.3636 35.074 44.1236 34.906C43.8956 34.738 43.6076 34.612 43.2596 34.528C42.9236 34.432 42.5336 34.384 42.0896 34.384V33.016C42.8576 33.016 43.5596 33.082 44.1956 33.214C44.8436 33.346 45.4076 33.562 45.8876 33.862C46.3676 34.15 46.7396 34.522 47.0036 34.978C47.2676 35.422 47.3996 35.962 47.3996 36.598C47.3996 37.318 47.2076 37.954 46.8236 38.506C46.4516 39.058 45.9176 39.49 45.2216 39.802C44.5376 40.102 43.7276 40.252 42.7916 40.252Z"
                fill="#343434"
              />
            </svg>
          </div>
          <div>
            <h5 style={{ marginBottom: "0px" }}>About the School</h5>
            <p className="FormParagraph" style={{ marginBottom: "0px" }}>
              Provide the following information about the school
            </p>
          </div>
        </div>
      </div>
      {/* <br /> */}

      {/* {isFormNotFilled ? (
        <span
          style={{
            color: "red",
            fontStyle: "italic",
            transition: "ease-in-out",
          }}
        >
          Please fill all fields
        </span>
      ) : null} */}
      <Form className="form">
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            School Name <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <Form.Control
            size="md"
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
            }}
            type="text"
            name="institutionName"
            value={institutionName}
            onChange={handleOnChange}
            placeholder="Enter Your School"
            required
          />
        </Form.Group>

        <div
          style={{
            marginBottom: "15px",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <p style={{ marginBottom: "5px", fontWeight: "500" }}>
            School Type{" "}
            <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
          </p>

          <div ref={dropdownRef}>
            <label
              style={{
                width: "100%",
                backgroundColor: "#ededed",
                borderRadius: "5px",
                padding: "7px 10px",
                cursor: "pointer",
                fontSize: "14px",
              }}
              onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
            >
              {selectedItems.length > 0
                ? selectedItemsNames?.join(", ")
                : "Select School Type"}
            </label>

            {showCategoryDropdown && (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  zIndex: "10",
                  transition: "all",
                  transitionTimingFunction: "ease-in-out",
                  transitionDuration: "0.3s",
                  backgroundColor: "white",
                  width: "100%",
                  border: "1px solid rgb(221, 221, 221)",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                {institutionTypesArray &&
                  institutionTypesArray.map(({ typeName, id }) => (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "2px",
                      }}
                    >
                      <div style={{ position: "relative" }}>
                        <label className="checkbox-container">
                          <input
                            type="checkbox"
                            // checked={selectedItems.includes(id)}
                            // onChange={(e) => handleItemClick({ typeName, id })}
                            checked={selectedItems?.find(
                              (item) => item.id === id
                            )}
                            onChange={(e) => handleItemClick({ typeName, id })}
                            style={{
                              marginRight: "5px",
                              width: "auto",
                              appearance: "auto",
                            }}
                          />
                          <span className={`custom-checkbox`}></span>
                        </label>
                      </div>
                      <span>{typeName}</span>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            School Email <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <InputGroup className="mb-3">
            <InputGroup.Text
              id="basic-addon1"
              style={{ backgroundColor: "#F14810", color: "#ffffff" }}
            >
              <EnvelopeAt size={22} />
            </InputGroup.Text>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="email"
              placeholder="Enter Your School Email"
              name="institutionEmail"
              value={institutionEmail}
              onChange={handleOnChange}
              required
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            Contact Number <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <InputGroup className="mb-3">
            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="number"
              placeholder="Enter your School Contact Number"
              name="schoolContactNumber"
              value={schoolContactNumber}
              onChange={handleOnChange}
              required
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">School Website</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Text
              id="basic-addon1"
              style={{ backgroundColor: "#F14810", color: "#ffffff" }}
            >
              <Link45deg size={22} />
            </InputGroup.Text>
            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              name="website"
              value={website}
              onChange={handleOnChange}
              placeholder="www.yourschoolwebsite.com"
              aria-label="link"
              aria-describedby="basic-addon1"
              required
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            School Address <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <Form.Control
            size="md"
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
            }}
            type="text"
            name="address"
            value={address}
            onChange={handleOnChange}
            placeholder="Enter Your School Address"
            required
          />
        </Form.Group>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                Country <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <Form.Select
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                name="country"
                value={country}
                onChange={handleOnChange}
                required
              >
                <option value="" disabled>
                  Select Country
                </option>
                {countryData &&
                  countryData.map(({ countryName, id }) => (
                    // <option
                    //   key={id}
                    //   value={id}
                    // >
                    //   {countryName}
                    // </option>
                    <option key={id} value={countryName}>
                      {countryName}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                State <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <Form.Select
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                name="state"
                value={state}
                onChange={handleOnChange}
                placeholder="Enter Your Phone number"
                required
              >
                <option value="" disabled>
                  Select State
                </option>
                {states &&
                  states.map(({ stateName, id }) => (
                    // <option key={id} value={id}>
                    //   {stateName}
                    // </option>
                    <option key={id} value={stateName}>
                      {stateName}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        {/* start referral code */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            Referral Code (Optional)
          </Form.Label>

          <InputGroup className="mb-3">
            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="number"
              placeholder="Enter your Referral Code"
              name="referralCode"
              value={referralCode}
              onChange={handleOnChange}
              
            />
          </InputGroup>
        </Form.Group>
        {/* end referral code */}
        <br />

        <div>
          <Button
            variant="primary"
            // type="submit"
            style={{
              backgroundColor: "#F14810",
              borderColor: "#F14810",
              float: "left",
            }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
              onClick={handleGoToNextPage}
            >
              Save & Continue
            </span>
          </Button>
        </div>
      </Form>

      <br />

      <div>
        <p
          style={{
            fontFamily: "jost, sans-serif",
            fontSize: 0.85 + "rem",
            marginTop: 1 + "rem",
            marginRight: -2 + "rem",
          }}
        >
          Already have an account?
          <a
            href="/login"
            className="btn btn-link"
            role="button"
            data-bs-toggle="button"
            style={{
              fontFamily: "jost, sans-serif",
              fontSize: 0.85 + "rem",
              paddingBottom: 0.7 + "rem",
              paddingLeft: 0.3 + "rem",
              paddingRight: 1.2 + "rem",
            }}
          >
            <span style={{ color: "#3174fd" }}>Login Now</span>
          </a>
        </p>
      </div>
    </>
  );
};

export default AboutInstitutionPage;
