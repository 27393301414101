import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto"; // Use the auto version of Chart.js
import { useDispatch, useSelector } from "react-redux";


const options = {
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 1.1,

  plugins: {
    legend: {
      position: "top",
      align: "end",
      labels: {
        boxWidth: 6,
        boxHeight: 6,
        usePointStyle: "circle",
      },
    },
    title: {
      display: true,
      text: "Financial Report",
      align: "start",
      padding: {
        top: 4,
        bottom: -10,
      },
      font: {
        size: 13,
        family: " 'Jost', sans-serif",
        color: "#263238",
      },
    },
  },
  scales: {
    x: {
      ticks: {
        color: "#606060",
      },
      grid: {
        color: "#E2E2E2",
      },
    },
    x: {
      ticks: {
        color: "#606060",
      },
      grid: {
        color: "white",
      },
    },
  },
};

const labels = [
  "Grd I",
  "Grd II",
  "Grd III",
  "Grd IV",
  "Pri1",
  "Pri2",
  "Pri3",
  "Pri4",
  "Pri5",
];

// const data = {
//   labels,
//   datasets: [
//     {
//       label: 'paid',
//       data:[230, 510, 120,  180, 243, 232, 743, 340, 490,],
//       borderColor: '#3174FD',
//       backgroundColor: '#3174FD',
//       borderWidth:1.5,
//       hitRadius:0.3,
//       tension:0.4,
//     },
//     {
//       label: 'outstanding',
//       data: [100, 230, 132, 132, 632, 732, 182, 340, 732, 548, 730],
//       borderColor: '#FD0E0E',
//       backgroundColor: '#FD0E0E',
//       borderWidth:1.5,
//       tension:0.4,
//     },
//   ],
// };

function LineDemo() {
  const instId = useSelector((state) => state?.admin?.institutionData);
  console.log(instId);

  const dashboardRec = useSelector(
    (state) => state?.admin?.dashboardData
  );

  console.log(dashboardRec);

  let labels = [];
  let paidLabelData = [];
  let outstandingLabelData = [];

  // const [labels, setLabels] = useState([])
  // const [paidLabelData, setPaidLabelData] = useState([])
  // const [outstandingLabelData, setOutstandingLabelData] = useState([])

  console.log(labels);
  console.log(paidLabelData);
  console.log(outstandingLabelData);

  // useEffect(() => {
  //   if (dashboardRec) {
  //   }
  // }, []);

  //labels, paidLabelData, outstandingLabelData

  const levelFinancialList = dashboardRec?.levelFinancialReports?.map(
    (item) => {
      labels = [...labels, item.name];
      paidLabelData = [...paidLabelData, item.paidAmount];
      outstandingLabelData = [...outstandingLabelData, item.outstandingAmount];
    }
  );

  const data = {
    labels,
    datasets: [
      {
        label: "Paid",
        data: paidLabelData,
        borderColor: "#3174FD",
        backgroundColor: "#3174FD",
        borderWidth: 1.5,
        hitRadius: 0.3,
        tension: 0.4,
      },
      {
        label: "Outstanding",
        data: outstandingLabelData,
        borderColor: "#FD0E0E",
        backgroundColor: "#FD0E0E",
        borderWidth: 1.5,
        tension: 0.4,
      },
    ],
  };

  return (
    <div className="cardgraph">
      <Line options={options} data={data} usePointStyle={true} />
    </div>
  );
}

export default LineDemo;
