import React from 'react'

const DashboardIcon = ({active}) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.38889 14.3889H9.72222C10.4861 14.3889 11.1111 13.7639 11.1111 13V1.88889C11.1111 1.125 10.4861 0.5 9.72222 0.5H1.38889C0.625 0.5 0 1.125 0 1.88889V13C0 13.7639 0.625 14.3889 1.38889 14.3889ZM1.38889 25.5H9.72222C10.4861 25.5 11.1111 24.875 11.1111 24.1111V18.5556C11.1111 17.7917 10.4861 17.1667 9.72222 17.1667H1.38889C0.625 17.1667 0 17.7917 0 18.5556V24.1111C0 24.875 0.625 25.5 1.38889 25.5ZM15.2778 25.5H23.6111C24.375 25.5 25 24.875 25 24.1111V13C25 12.2361 24.375 11.6111 23.6111 11.6111H15.2778C14.5139 11.6111 13.8889 12.2361 13.8889 13V24.1111C13.8889 24.875 14.5139 25.5 15.2778 25.5ZM13.8889 1.88889V7.44444C13.8889 8.20833 14.5139 8.83333 15.2778 8.83333H23.6111C24.375 8.83333 25 8.20833 25 7.44444V1.88889C25 1.125 24.375 0.5 23.6111 0.5H15.2778C14.5139 0.5 13.8889 1.125 13.8889 1.88889Z"
        fill={active ? "#ffffff" : "#464646"}
      />
    </svg>
  );
}

export default DashboardIcon