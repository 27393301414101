import { useState, useEffect } from "react";
// import eyeActionIcon from "../../../../assets/svgs/eyeActionIcon.svg";
import { Container } from "react-bootstrap";
import DashboardTopSection from "../../../components/dashboardTopSection/index";
import DashboardSidebar from "../../../components/dashboardSidebar/sidebar";
import TableComponent from "../../../../../../component/table/table";
import backArrow from "../../../../../../assets/svgs/backArrow.svg";
// import "./settlement.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import {
  useGetInstitutionQuery,
  useGetPaymentHistoryQuery,
} from "../../../../../../redux/features/institution/institutionApiSlice";
import { addTrailingCommas } from "../../../../../../utils/utils";

const WalletTransactionsPage = () => {
  // const [newHistoryDetails, setNewHistoryDetails] = useState(null);

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  console.log(instId);

  const { data: institutionDetails, isLoading: detailsLoading } =
    useGetInstitutionQuery(instId);

  const {
    data: payments,
    // refetch: refetchBranches,
    isLoading: paymentsLoading,
  } = useGetPaymentHistoryQuery(instId, {
    skip: !instId,
  });

  console.log(payments);

  const navigate = useNavigate();

  const columns = [
    // { name: "id", label: "ID" },
    { name: "date", label: "Date" },
    {
      name: "name",
      label: "Student Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { firstName, middleName, lastName } =
            payments[tableMeta.rowIndex];
          return `${firstName} ${middleName ? middleName : ""} ${lastName}`;
        },
      },
    },
    { name: "studentNo", label: "Student No" },
    { name: "class", label: "Class" },
    // { name: "paidAmount", label: "Paid Amount" }, 
    {
      name: "paidAmount",
      label: "Paid Amount",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { paidAmount } = payments[tableMeta.rowIndex];
          return `${"₦ " + addTrailingCommas(paidAmount)}`;
        },
      },
    },
    // { name: "feeTotal", label: "Fee Total" }, 
    // {
    //   name: "feeTotal",
    //   label: "Fee Total",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       const { feeTotal } = payments[tableMeta.rowIndex];
    //       return `${"₦ " + addTrailingCommas(feeTotal)}`;
    //     },
    //   },
    // },
    // {
    //   name: "name",
    //   label: "Level-Category",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       const { level, category } = payments[tableMeta.rowIndex];
    //       return `${level} - ${category}`;
    //     },
    //   },
    // },
    { name: "calendar", label: "Calendar" },
    { name: "paymentTrigger", label: "Payment Initiation" },
    { name: "transactionReference", label: "Transaction Ref" },
    {
      name: "paid",
      label: "Status",
      options: {
        customBodyRender: (paid) => (paid ? "Paid" : "Not Paid"),
      },
    },
    // {
    //     name: "actions",
    //     label: "Action",
    //     options: {
    //         customBodyRender: (value, tableMeta) => (
    //             <img
    //                 src={eyeActionIcon}
    //                 alt="eye icon"
    //                 style={{ cursor: "pointer" }}
    //                 onClick={() => navigate(`${institutionList[tableMeta.rowIndex].id}`)}
    //             />
    //         ),
    //     },
    // },
  ];

  if (paymentsLoading) {
    return <OnboardingLoader />;
  }

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar institutionDetails={institutionDetails} />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />

        <>
          <div>
            <h5
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                // marginBottom: "20px",
              }}
            >
              Reporting
            </h5>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
              marginTop: "30px",
              // marginBottom: "10px",
            }}
            onClick={() => navigate(-1)}
          >
            <img src={backArrow} alt="back arrow" />
            <h5 style={{ marginBottom: 0 }}>Wallet Transactions</h5>
          </div>

          <div
            style={{
              marginTop: "20px",
            }}
          >
            <TableComponent
              data={payments}
              title=""
              columns={columns}
              pagination={10}
              search={true}
            />
          </div>
        </>

        <br />
        <br />
      </Container>
    </Container>
  );
};

export default WalletTransactionsPage;//PaymentHistoryPage;