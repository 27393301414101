import React from "react";
import { Container, Row, Col, Navbar, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import SliderItems from "../../component/SliderItems.js";

import "../../styles/formpage.css";

const RegistrationLayout = ({ children }) => {
  return (
    <div className="register-container">
      <Container fluid>
        <Row>
          <Col xs={12} md={6} lg={6} className="FirstSegment">
            <div>
              <Link to="/">
                <Navbar.Brand>
                  <Image src={logo} fluid />
                </Navbar.Brand>
              </Link>
            </div>

            <SliderItems />
          </Col>
          <Col xs={12} md={6} lg={6} className="SecondSegment">
            <div className="displayOnMobile">
              <Link to="/">
                <Navbar.Brand>
                  <Image src={logo} fluid />
                </Navbar.Brand>
              </Link>
            </div>

            <main>{children}</main>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RegistrationLayout;
