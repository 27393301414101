import { useState, useEffect } from "react";
import eyeActionIcon from "../../../../assets/svgs/eyeActionIcon.svg";
import DashboardTopSection from "../dashboardTopSection";
import DashboardSidebar from "../dashboardSideBar/sidebar";
import TableComponent from "../../../../component/table/table";
import Button from "../../../../component/button/button";
import { Row, Image, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import pendingIcon from "../../../../assets/svgs/pendingRequestsIcon.svg";
import submittedIcon from "../../../../assets/svgs/submitted.svg";
import rejectedIcon from "../../../../assets/svgs/rejected.svg";
import updateIcon from "../../../../assets/svgs/institutionUpdateIcon.svg";
import { addTrailingCommas } from "../../../../utils/utils";
import "./application.css";
import { useGetApplicationCountQuery } from "../../../../redux/features/superAdmin/adminApiSlice";
import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";

const ApplicationsPage = () => {
  const navigate = useNavigate();

  const [applicationCountList, setApplicationCountList] = useState(null);

  const {
    data: applicationCount,
    refetch,
    loading: countsLoading,
  } = useGetApplicationCountQuery();
  console.log(applicationCount);

  useEffect(() => {
    if (applicationCount)
      refetch();
  }, []);


  const applicationList = [
    {
      id: 1,
      title: "Pending Applications",
      value: applicationCount
        ? // ? addTrailingCommas(applicationCount?.noOfPendingApplications)
        applicationCount?.noOfPendingApplications
        : 0,
      icon: pendingIcon,
      isMoney: false,
    },
    {
      id: 2,
      title: "Submitted Applications",
      value: applicationCount
        ? // ? addTrailingCommas(applicationCount?.noOfPendingApplications)
        applicationCount?.noOfSubmittedApplications
        : 0,
      icon: submittedIcon,
      isMoney: false,
    },
    {
      id: 3,
      title: "In-Review Applications",
      value: applicationCount
        ? // ? addTrailingCommas(applicationCount?.noOfRejectedApplications)
        applicationCount?.noOfRejectedApplications
        : 0,
      icon: rejectedIcon,
      isMoney: false,
    },
    {
      id: 4,
      title: "Institution Updates",
      value: applicationCount
        ? // ? addTrailingCommas(applicationCount?.noOfUpdateApplications)
        applicationCount?.noOfUpdateApplications
        : 0,
      icon: updateIcon,
      isMoney: false,
    },
  ];

  //Navigate to applications page
  const handleNavigate = (id) => {
    if (id == 1) {
      navigate(`/principal/applications/pending`);
    } else if (id === 2) {
      navigate(`/principal/applications/submitted`);
    } else if (id === 3) {
      navigate(`/principal/applications/rejected`);
    } else {
      navigate(`/principal/applications/institution_update`);
    }
  };

  if (countsLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div className="schools-page no-scrollbar">
      <div>
        <DashboardSidebar />
      </div>

      <div>
        <DashboardTopSection />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <h5>Applications</h5>
        </div>

        <Row style={{ marginTop: "10px" }}>
          <Col xs={12} md={12} lg={12}>
            <Row>
              {applicationList?.map(({ id, icon, value, title }, i) => (
                <Col xs={8} md={8} lg={4} key={id}>
                  <div
                    className={`overview-box-application`}
                    style={{
                      cursor: "pointer",
                      marginTop: 0.5 + "rem",
                      marginBottom: 0.5 + "rem",
                    }}
                    onClick={() => handleNavigate(id)}
                  >
                    <img src={icon} alt="icon" />
                    <div>
                      <h6 style={{ marginTop: 1 + "rem" }}>
                        <b>{value}</b>
                      </h6>
                      <p style={{ marginTop: -0.61 + "rem" }}>{title}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        <br />
        <br />
      </div>
    </div>
  );
};

export default ApplicationsPage;
