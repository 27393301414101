import React from "react";
import { useState, useEffect } from "react";
import { Container, Table, Offcanvas } from "react-bootstrap";
import TableComponent from "../../../../../../component/table/table";

// import createStudentVector from "../../../../../assets/svgs/createAstudentIcon.svg";
// import Button from "../../../../../component/button/button";
// import totalStudentsIcon from "../../../../../assets/svgs/totalStudentsIcon.svg";
// import maleStudentIcon from "../../../../../assets/svgs/maleStudentIcon.svg";
// import femaleStudentIcon from "../../../../../assets/svgs/femaleStudentIcon.svg";
// import StudentInformationStepper from "./studentInformation/studentInformationStepper";
// import UploadStudentsPage from "./studentInformation/components/uploadStudents/uploadStudentsPage";
// import {
//   useGetApprovedStudentsQuery,
//   useGetStudentsRegisterQuery,
//   useGetStudentRegisterDetailQuery,
// } from "../../../../../redux/features/institution/studentApiSlice";

import {
  useGetInstitutionDetailQuery,
} from "../../../../../../redux/features/superAdmin/adminApiSlice";

import { useSelector } from "react-redux";
import "./students.css";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import { useParams, useNavigate, Link } from "react-router-dom";
import DashboardSidebar from "../../../dashboardSideBar/sidebar";
import DashboardTopSection from "../../../dashboardTopSection";
import backArrow from "../../../../../../assets/svgs/backArrow.svg";


const StudentsPage = () => {

  const { institutionId } = useParams();

  const [students, setStudents] = useState(0);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showCreateStudentCanvas, setShowCreateStudentCanvas] = useState(false);

  const handleCloseStudent = () => {
    setShowCreateStudentCanvas(false);
  };
  const handleShowStudent = () => {
    setShowCreateStudentCanvas(true);
  };

  const handleCloseUpload = () => {
    setShowUploadModal(false);
  };
  const handleShowUpload = () => {
    setShowUploadModal(true);
  };

  const navigate = useNavigate();

  // const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  // const {
  //   data: approvedStudents,
  //   refetch: refetchApprovedStudents,
  //   isLoading: studentsLoading,
  // } = useGetApprovedStudentsQuery(instId, {
  //   skip: !institutionDetails,
  // });

  // console.log(approvedStudents)

  // const {
  //   data: studentRegister,
  //   refetch: refetchStudentsRegisters,
  //   isLoading: registersLoading,
  // } = useGetStudentsRegisterQuery(instId, { skip: !approvedStudents });

  // console.log(studentRegister);

  const {
    data: institutionDetails,
    refetch: refetchInstition,
    isLoading: institutionLoading,
  } = useGetInstitutionDetailQuery(institutionId);

  console.log(institutionDetails);

  const columns = [
    { name: "id", label: "ID" },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { firstname, lastname } = studentList[tableMeta.rowIndex];
          return `${firstname} ${lastname}`;
        },
      },
    },
    { name: "gender", label: "Gender" },
    { name: "studentNo", label: "Student No" },
    { name: "nuban", label: "Account No" },
    { name: "bankName", label: "Bank Name" },
    { name: "walletBalance", label: "Wallet Balance" },
    // { name: "phoneNo", label: "Student Phone" },
    { name: "parentName", label: "Parent Name" },
    // { name: "status", label: "Status" },
  ];


  const studentList = [
    {
      id: 1,
      firstname: "Samuel",
      lastname: "James",
      gender: "Male",
      studentNo: "0028731",
      nuban: "0000377443",
      bankName: "Madobi MFB",
      walletBalance: "166.50",
      parentName: "Moses Victor",
      parentEmail: "parent@mail.com"
    },
    {
      id: 1,
      firstname: "Jenny",
      lastname: "Silver",
      gender: "Female",
      studentNo: "0029031",
      nuban: "0000373343",
      bankName: "Madobi MFB",
      walletBalance: "165.50",
      parentName: "Moses Victor",
      parentEmail: "parent@mail.com"
    },
  ]

  // useEffect(() => {
  //   if (approvedStudents) {
  //     refetchApprovedStudents();
  //   }
  // }, [showUploadModal === false, showCreateStudentCanvas === false]);

  // useEffect(() => {
  //   if (studentRegister) {
  //     refetchStudentsRegisters();
  //   }
  // }, [showUploadModal === false, showCreateStudentCanvas === false]);


  // if (studentsLoading || registersLoading) {
  //   return <OnboardingLoader />;
  // }


  return (
    <div style={{ display: "flex" }}>
      {/* {(approvedStudents && approvedStudents.length > 0) ||
        (studentRegister && studentRegister.length > 0) ? ( */}

      <DashboardSidebar />

      <Container
        fluid
        className="bodycontainer"
        style={{ backgroundColor: "#f5f5f5" }}
      >

        <DashboardTopSection />

        <section
          style={{
            marginTop: "30px",
          }}
        >

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 1 + "rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer",
                marginBottom: "10px",
              }}
              onClick={() => navigate(-1)}
            >
              <img src={backArrow} alt="Go back" />
              <h6 style={{ marginBottom: 0 }}>
                {institutionDetails?.institutionName} ( Students )
              </h6>
            </div>

            {/* <div style={{ display: "flex", gap: "5px" }}>
              <Link to="/student">
                <Button backgroundColor="#6f6d6d">Students List</Button>
              </Link>
              <Link to="/student/unapproved">
                <Button backgroundColor="#dadada">Unapproved Batch</Button>
              </Link>
            </div>

            <div style={{ display: "flex", gap: "5px" }}>
              <Button onClick={handleShowUpload}>Upload Students</Button>
              <Button
                backgroundColor="#fff"
                border="#f14810"
                color="#f14810"
                onClick={handleShowStudent}
              >
                Create Student
              </Button>
            </div> */}

          </div>

          <TableComponent
            data={studentList}
            title=""
            columns={columns}
            pagination={10}
            search={true}
          />
        </section>

        {/* ) : (
         <div className="create-student-container">
           <img src={createStudentVector} alt="A student image" />
           <h3>Create Students</h3>
           <p>
             Creating students can be done in 2 ways; You can either click on "Upload Students" button to add existing students record to the platform OR click on "Create Students" button to add a new student record. After a successful upload or creation of student, the records can be found in the "Unapproved Batch" for processing.  {" "}
           </p>
           <div className="btns">
             <Button onClick={handleShowUpload}>Upload Students</Button>

             <Button
              onClick={handleShowStudent}
              backgroundColor="#fff"
              color="#F14810"
              border="#F14810"
            >
              Create Students
            </Button>
          </div>
        </div>
      )} */}

        {/* Register a student canvas */}
        <Offcanvas
          show={showCreateStudentCanvas}
          onHide={handleCloseStudent}
          placement={"end"}
          backdrop={true}
          style={{ width: "100%", maxWidth: "500px" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Create Students</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ paddingTop: "0px" }}>
            {/* <StudentInformationPage /> */}
            {/* <StudentInformationStepper /> */}
          </Offcanvas.Body>
        </Offcanvas>

        {/* Upload canvas */}
        <Offcanvas
          show={showUploadModal}
          onHide={handleCloseUpload}
          placement={"end"}
          backdrop={true}
          style={{ width: "100%", maxWidth: "500px" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Upload Students</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {/* <UploadStudentsPage showUploadModal={showUploadModal} /> */}
          </Offcanvas.Body>
        </Offcanvas>


      </Container>

    </div>
  );
};

export default StudentsPage;