import React, { useEffect, useState } from "react";
import "./dashboard.css";
import "../../../institutionDashboard/institutionDashboardPage.css";
import { Row, Image, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import LineDemo from "./LineDemo";
import {
  useGetDashboardReportQuery,
  useGetInstitutionQuery,
} from "../../../../../redux/features/institution/institutionApiSlice";
import Expectedrevenueicon from "../../../../../assets/svgs/expectedRevenueicon.svg";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import walleticon from "../../../../../assets/svgs/walleticon.svg";
import outstandingpaymenticon from "../../../../../assets/svgs/outstandingpaymenticon.svg";
import generatedrevenueicon from "../../../../../assets/svgs/generatedrevenueicon.svg";
import PeriodProgress from "./PeriodProgress";
import { storeDashboardData } from "../../../../../redux/features/institution/institutionSlice";
import { addTrailingCommas } from "../../../../../utils/utils";
import TableComponent from "../../../../../component/table/table";
import ModalComponent from "../../../../../component/modal/modal";
import alertIcon from "../../../../../assets/svgs/alert.svg";

const InstitutionDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const [showRejectionModal, setShowRejectionModal] = useState(false);

  // console.log(instId);

  const {
    data: dashboardData,
    isLoading: dashboardDataLoading,
    refetch,
  } = useGetDashboardReportQuery(instId);

  const {
    data: institutionDetails,
    isLoading: detailsLoading,
    refetch: refectInstitutionDetails,
  } = useGetInstitutionQuery(instId);

  console.log(dashboardData);
  console.log(dashboardData?.recentPayments);

  dispatch(storeDashboardData(dashboardData));

  // dashboardData && dashboardData.length > 0 && dashboardData

  const otherDetails = [
    {
      title: "Expected Revenue",
      amount: addTrailingCommas(dashboardData?.expectedRevenue),
      icon: Expectedrevenueicon,
      style: "Expectedrevenuestyle",
      slug: "expectedRevenue",
    },
    {
      title: "Revenue",
      amount: addTrailingCommas(dashboardData?.revenue),
      icon: generatedrevenueicon,
      style: "Generatedrevenuestyle",
      slug: "revenue",
    },
    {
      title: "Pending Payment",
      amount: addTrailingCommas(dashboardData?.pendingPayment),
      icon: walleticon,
      style: "walletstyle",
      slug: "pendingPayment",
    },
    {
      title: "Overdue Payment",
      amount: addTrailingCommas(dashboardData?.overduePayment),
      icon: outstandingpaymenticon,
      style: "outstandingstyle",
      slug: "overduePayment",
    },
  ];

  const details = [
    {
      id: "1",
      title: dashboardData?.totalStudents,
      text: "Total Students",
      style: "sidecard-x1",
    },
    {
      id: "2",
      title: "Fees Configuration",
      text: "setting up and customizing fees for your School",
      style: "sidecard-x2",
    },
    {
      id: "3",
      title: "Bank Acc Configuration",
      text: "setting up and managing bank account information",
      style: "sidecard-x3",
    },
  ];

  const columns = [
    { name: "studentName", label: "Student Name" },
    {
      name: "className",
      label: "Class",
    },
    { name: "parentName", label: "Parent Name" },
    { name: "parentPhoneNo", label: "Parent Phone" },
    { name: "datePaid", label: "Date Paid" },
    {
      name: "amountPaid",
      label: "Amount Paid",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { amountPaid } =
            dashboardData?.recentPayments[tableMeta.rowIndex];
          return `₦ ${addTrailingCommas(amountPaid)}`;
        },
      },
    },
    // {
    //   name: "outstanding",
    //   label: "Outstanding",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       const { outstanding } =
    //         dashboardData?.recentPayments[tableMeta.rowIndex];
    //       return `₦ ${addTrailingCommas(outstanding)}`;
    //     },
    //   },
    // },
  ];

  //Navigate to the clicked Page
  const handleNavigate = (id) => {
    if (id == 1) {
      navigate(`/dashboard/#`);
    } else if (id == 2) {
      navigate(`/settings`);
    } else {
      navigate(`/settings`);
    }
  };

  useEffect(() => {
    if (dashboardData) {
      refetch();
    }
  }, []);

  console.log(institutionDetails);

  useEffect(() => {
    // show application rejected modal if status is in review
    refectInstitutionDetails();
    // if (institutionDetails?.status === "IN_REVIEW") {
    //   setShowRejectionModal(true);
    // }

    if (institutionDetails?.status === "IN_REVIEW") {
      const timeoutId = setTimeout(() => {
        setShowRejectionModal(true);
      }, 1500);

      // Clear the timeout when the component unmounts or when the effect re-runs
      return () => clearTimeout(timeoutId);
    }
  }, [institutionDetails]);

  if (dashboardDataLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ModalComponent
        showModal={showRejectionModal}
        closeModal={() => {
          setShowRejectionModal(false);
          // navigate("/settings/application-rejected");
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={alertIcon}
            alt="alert icon"
            width="100px"
            style={{ margin: "0 auto" }}
          />

          <h3 style={{ color: "#F14810", fontWeight: "600", marginTop: "10px" }}>
            Application Rejected!!
          </h3>
          <p style={{ fontSize: "18px" }}>
            {" "}
            Your application has been rejected. Click this button to review and
            resend your application
          </p>
          <Link to="/settings/application-rejected">
            <button
              style={{
                backgroundColor: "#F14810",
                padding: "7px 20px",
                color: "#fff",
                outline: "none",
                border: "none",
                borderRadius: "5px",
              }}
            >
              Ok
            </button>
          </Link>
        </div>
      </ModalComponent>

      <Row style={{ marginTop: 0.4 + "rem" }}>
        <Col xs={12} md={12} lg={5}>
          <div className="sessionbox">
            <div className="sessionbox-firstpart">
              <div>
                <h5 style={{ color: "#FFFFFF" }}>
                  {dashboardData?.activeCalendar?.periodName}
                </h5>
                <p
                  style={{
                    color: "#FFFFFF",
                    marginTop: -0.3 + "rem",
                    fontSize: 0.75 + "rem",
                  }}
                >
                  Current Term in progress
                </p>
                <p
                  style={{
                    color: "#B3B3B3",
                    marginTop: -0.3 + "rem",
                    fontSize: 0.75 + "rem",
                  }}
                >
                  <span>
                    <b style={{ color: "#FFFFFF" }}>Today</b>:
                  </span>
                  {dashboardData?.activeCalendar?.todayDate}
                </p>
              </div>
              <div>
                <PeriodProgress />
              </div>
            </div>

            <div className="sessionbox-otherpart">
              <div>
                <h6 style={{ color: "#FFFFFF", fontSize: 0.8 + "rem" }}>
                  <b>Start Date:</b>
                </h6>
                <p
                  style={{
                    color: "#B3B3B3",
                    marginTop: -0.3 + "rem",
                    fontSize: 0.75 + "rem",
                  }}
                >
                  {dashboardData?.activeCalendar?.startDate}
                </p>
              </div>
              <div>
                <h6 style={{ color: "#FFFFFF", fontSize: 0.8 + "rem" }}>
                  <b>End Date:</b>
                </h6>
                <p
                  style={{
                    color: "#B3B3B3",
                    marginTop: -0.3 + "rem",
                    fontSize: 0.75 + "rem",
                  }}
                >
                  {dashboardData?.activeCalendar?.endDate}
                </p>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={12} md={12} lg={7} className="otherbox">
          <Row>
            {otherDetails.map(({ icon, amount, title, style, slug }, i) => (
              <Col xs={6} md={6} lg={6} id={style} key={i}>
                <Link to={`/dashboard/${slug}`}>
                  <div className="card">
                    <div className="card-inner">
                      <div>
                        <Image src={icon} alt="img" fluid />
                      </div>
                      <div className="card-inner-value">
                        <h5 style={{ marginTop: 1 + "rem" }}>
                          <b> ₦ {amount?.toLocaleString()}</b>
                        </h5>
                        <p style={{ marginTop: -0.61 + "rem" }}>{title}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: 2 + "rem", marginBottom: 2 + "rem" }}>
        <Col xs={12} md={12} lg={8} style={{ marginBottom: "20px" }}>
          <div>
            <LineDemo />
          </div>
        </Col>

        <Col className="col-4-other-part" xs={12} md={12} lg={4}>
          {details.map(({ id, title, text, style }, i) => (
            <div
              className={style}
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigate(id)}
              key={i}
            >
              <div className="card-inner-value">
                <h6 style={{ marginTop: 1 + "rem" }}>
                  <b>{title}</b>
                </h6>
                <p
                  className="card-inner-text"
                  style={{ marginTop: -0.61 + "rem" }}
                >
                  {text}
                </p>
              </div>
            </div>
          ))}
        </Col>
      </Row>

      <TableComponent
        title="Recent Payments"
        columns={columns}
        data={dashboardData?.recentPayments}
      />

      <br />
      <br />
    </>
  );
};

export default InstitutionDashboard;
