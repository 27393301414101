import { useState, useEffect } from "react";
import alertIcon from "../../../../../assets/svgs/alertIcon.svg";
import { Table, Button } from "react-bootstrap";

// import { useSelector } from "react-redux";

import ModalComponent from "../../../../../component/modal/modal";

import {
  useGetAdminDocumentCategoriesQuery,
  useAddDocumentCategoryMutation,
  useUpdateDocumentCategoryMutation,
  useDeleteDocumentCategoryMutation,
} from "../../../../../redux/features/superAdmin/adminApiSlice";
import ActionIcon from "../../../../../component/actionbutton/ActionIcon";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import ToastComponent from "../../../../../component/toast/toast";

const DocumentPage = () => {
  // const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(null);
  // const [savedLevelsList, setSavedLevelsList] = useState([]);
  const [newData, setNewData] = useState(null);
  const [deletedElementID, setDeletedElementID] = useState(null);
  const [editedElement, setEditedElement] = useState(null);
  const [editingElement, setEditingElement] = useState(null);
  const [editingValue, setEditingValue] = useState("");
  const [elementToDelete, setElementToDelete] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // const tableHeaders = ["S/N", "Document Name", "Action"];
  const tableHeaders = ["Document Name", "Action"];

  // const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const {
    data: documentsData,
    refetch: documentsRefetch,
    isLoading: documentsLoading,
  } = useGetAdminDocumentCategoriesQuery();

  console.log(documentsData, "Docs");

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingElement(null);
    setEditingValue("");
  };

  const [addDocument, { isLoading: addLoading }] =
    useAddDocumentCategoryMutation();
  const [editDocument, { isLoading: editLoading }] =
    useUpdateDocumentCategoryMutation();
  const [deleteDocument, { isLoading: deleteLoading }] =
    useDeleteDocumentCategoryMutation();

  //.......................... actions .........................

  const handleEditDocument = (el) => {
    console.log("edit was clicked for ", el);
    setEditingElement(el);
    setShowModal(true);
    setEditingValue(el.name);
  };

  const confirmDelete = (el) => {
    setElementToDelete(el);
    setShowConfirmDelete(true);
  };

  const handleDeleteDocument = async () => {
    console.log("delete was clicked for ", elementToDelete);
    setShowConfirmDelete(false);
    try {
      // const parameters = {
      //   id: elementToDelete?.id,
      //   // body: { name: elementToDelete.name },
      // };
      await deleteDocument(elementToDelete?.id).unwrap();
      setDeletedElementID(elementToDelete.id);
    } catch (error) {
      console.log(error);
      // alert(error.data);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const [newDocumentData, setNewDocumentData] = useState({
    name: "",
  });

  const { name } = newDocumentData;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setNewDocumentData({ ...newDocumentData, [name]: value });
    setEditingValue(value);
  };

  const handleCreateOrUpdateDocument = async (e, type) => {
    e.preventDefault();

    if (type === "edit") {
      // if (editingValue === "") {
      //     setIsFormNotFilled(true);

      //     setTimeout(() => {
      //         setIsFormNotFilled(false);
      //     }, 3000);

      //     return;
      // }

      const parameters = {
        // id: editingElement?.id,
        // body: { id: editingElement?.id, name: editingValue },
        id: editingElement?.id,
        name: editingValue,
      };

      // console.log(parameters, 66666666)

      try {
        const res = await editDocument(parameters);
        console.log(res);
        if (res) {
          setNewDocumentData({
            name: "",
          });
          setEditedElement(res);
          setEditingValue("");
          setShowModal(false);
        }
        setEditingElement(null);
      } catch (error) {
        console.log(error);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
      return;
    } else {
      // if (name === "") {
      //     setIsFormNotFilled(true);

      //     setTimeout(() => {
      //         setIsFormNotFilled(false);
      //     }, 3000);

      //     return;
      // }
      try {
        console.log(newDocumentData);
        const obj = {
          // institutionId: instId,
          name: newDocumentData.name,
        };

        // console.log(obj, 777777);

        const data = await addDocument(obj).unwrap();

        if (data) {
          setNewData(data);
        }
        setNewDocumentData({
          name: "",
        });
        setShowModal(false);
      } catch (error) {
        console.log(error.data);
        // setErrorMessage(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
    }
  };

  useEffect(() => {
    if (documentsData) {
      documentsRefetch();
    }
  }, [newData, deletedElementID, editedElement]);

  if (documentsLoading || addLoading || deleteLoading || editLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          marginBottom: "8px",
        }}
      >
        <h5
          style={{ fontWeight: "600", fontSize: "14px", marginBottom: "0px" }}
        >
          Documents
        </h5>
        <Button
          className="button"
          size="md"
          style={{
            backgroundColor: "#F14810",
            color: "#ffffff",
            border: "none",
            outline: "none",
            fontSize: 0.8 + "rem",
          }}
          onClick={() => setShowModal(true)}
          title="Click this button to create a document"
        >
          Create Document
        </Button>
      </div>

      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "8px",
          padding: "15px",
          height: "95%",
        }}
      >
        {/* delete confirmation modal */}
        <ModalComponent
          showModal={showConfirmDelete}
          closeModal={() => setShowConfirmDelete(false)}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={alertIcon}
              alt="alert Icon"
              style={{ margin: "0 auto" }}
            />
            <p
              style={{
                marginTop: "10px",
                marginBottom: "5px",
                fontWeight: "500",
                fontSize: 1.0 + "rem",
              }}
            >
              Are you sure you want to delete this document?{" "}
            </p>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                gap: "10px",
                marginLeft: "30%",
              }}
            >
              <Button
                onClick={() => setShowConfirmDelete(false)}
                type="button"
                variant="outline-primary"
                style={{ borderColor: "#F14810", color: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  No
                </span>
              </Button>
              <Button
                onClick={() => handleDeleteDocument()}
                type="button"
                variant="primary"
                style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Yes
                </span>
              </Button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          title="Create Document"
          showModal={showModal}
          closeModal={handleCloseModal}
        >
          {/* {isFormNotFilled && (
                        <span
                            style={{
                                color: "red",
                                fontStyle: "italic",
                                transition: "ease-in-out",
                            }}
                        >
                            Please fill all fields
                        </span>
                    )} */}

          <form onSubmit={(e) => e.preventDefault()}>
            <div style={{ marginBottom: "15px" }}>
              <p style={{ marginBottom: "5px", fontWeight: "500" }}>
                Document Name{" "}
                {/* <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span> */}
              </p>
              <input
                type="text"
                placeholder="Enter Document Name (e.g CAC, Licenses)"
                name="name"
                value={editingElement ? editingValue : name}
                onChange={handleOnChange}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#dddddd",
                  width: "100%",
                }}
              />
            </div>

            <div style={{ marginTop: "30px" }}>
              {editingElement ? (
                <Button
                  onClick={(e) => handleCreateOrUpdateDocument(e, "edit")}
                  variant="primary"
                  style={{
                    backgroundColor: "#F14810",
                    borderColor: "#F14810",
                    float: "left",
                  }}
                  className="button"
                  size="md"
                >
                  <span
                    style={{
                      fontSize: 0.8 + "rem",
                      marginLeft: 0.5 + "rem",
                      marginRight: 0.5 + "rem",
                    }}
                  >
                    Edit
                  </span>
                </Button>
              ) : (
                <Button
                  onClick={(e) => handleCreateOrUpdateDocument(e, "")}
                  variant="primary"
                  style={{
                    backgroundColor: "#F14810",
                    borderColor: "#F14810",
                    float: "left",
                  }}
                  className="button"
                  size="md"
                >
                  <span
                    style={{
                      fontSize: 0.8 + "rem",
                      marginLeft: 0.5 + "rem",
                      marginRight: 0.5 + "rem",
                    }}
                  >
                    Save
                  </span>
                </Button>
              )}
            </div>
          </form>
        </ModalComponent>

        <Table
          // responsive
          striped="true"
          size="sm"
          style={{
            fontFamily: "jost, sans-serif",
            fontSize: "14px",
          }}
        >
          <thead>
            <tr align="center">
              {documentsData &&
                documentsData.length > 0 &&
                tableHeaders.map((header, i) => <th key={i}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {documentsData &&
              documentsData.length > 0 &&
              documentsData.map((document, i) => (
                <tr key={i} className={i % 2 === 0 ? "even-row" : "odd-row"}>
                  {/* <td align="center">{document.id}</td> */}
                  <td align="center">{document.name}</td>
                  {/* <td align="center">{document.code}</td> */}
                  <td align="center">
                    <ActionIcon
                      actionsArr={[
                        {
                          label: "Edit",
                          action: () => handleEditDocument(document),
                        },
                        {
                          label: "Delete",
                          action: () => confirmDelete(document),
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default DocumentPage;
