import React from 'react'
import { Container, Row, Col, Image, Navbar } from 'react-bootstrap';
import { Link } from "react-router-dom";
import logo from '../assets/images/logo.svg';

function Footer() {
    return (
        <Container fluid className="footer">
            <Row>
                <Col xs={12} md={6} lg={6} >
                <Link to='./'>
                        <Navbar.Brand>
                            <Image src={logo} fluid />
                        </Navbar.Brand>
                    </Link>
                </Col>
                <Col xs={12} md={6} lg={6}  >
                    <div className="footercontent">Copyright©2023AdeptforSchool.com</div>
                </Col>
            </Row>
        </Container>
    )
}

export default Footer