import React from "react";
import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import TableComponent from "../../../../../component/table/table";

import createStudentVector from "../../../../../assets/svgs/createAstudentIcon.svg";
import Button from "../../../../../component/button/button";
import totalStudentsIcon from "../../../../../assets/svgs/totalStudentsIcon.svg";
import maleStudentIcon from "../../../../../assets/svgs/maleStudentIcon.svg";
import femaleStudentIcon from "../../../../../assets/svgs/femaleStudentIcon.svg";
import { Offcanvas, Row, Col } from "react-bootstrap";
import StudentInformationStepper from "./studentInformation/studentInformationStepper";
import UploadStudentsPage from "./studentInformation/components/uploadStudents/uploadStudentsPage";
import {
  useGetApprovedStudentsQuery,
  useGetStudentsRegisterQuery,
  useGetStudentRegisterDetailQuery,
} from "../../../../../redux/features/institution/studentApiSlice";
import { useSelector, useDispatch } from "react-redux";
import "./students.css";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import { Link } from "react-router-dom";
import {
  storeParentInfo,
  storeStudentInfo,
} from "../../../../../redux/features/institution/studentSlice";

const Students = ({ institutionDetails, institutionLogo }) => {
  const [students, setStudents] = useState(0);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showCreateStudentCanvas, setShowCreateStudentCanvas] = useState(false);

  const dispatch = useDispatch();

  const handleCloseStudent = () => {
    setShowCreateStudentCanvas(false);
    dispatch(storeParentInfo(null));
    dispatch(storeStudentInfo(null));
  };
  const handleShowStudent = () => {
    setShowCreateStudentCanvas(true);
  };

  const handleCloseUpload = () => {
    setShowUploadModal(false);
  };
  const handleShowUpload = () => {
    setShowUploadModal(true);
  };

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  const {
    data: approvedStudents,
    refetch: refetchApprovedStudents,
    isLoading: studentsLoading,
  } = useGetApprovedStudentsQuery(instId);

  console.log(approvedStudents);

  const {
    data: studentRegister,
    refetch: refetchStudentsRegisters,
    isLoading: registersLoading,
  } = useGetStudentsRegisterQuery(instId, { skip: !approvedStudents });

  // console.log(studentRegister);

  const columns = [
    { name: "id", label: "ID" },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { firstname, middlename, lastname } =
            approvedStudents[tableMeta.rowIndex];
          return `${firstname} ${middlename} ${lastname}`;
        },
      },
    },
    { name: "gender", label: "Gender" },
    { name: "studentNo", label: "Student No" },
    { name: "nuban", label: "Nuban" },
    { name: "bankName", label: "Bank Name" },
    { name: "walletBalance", label: "Wallet Balance" },
    { name: "phoneNo", label: "Student Phone" },
    {
      name: "parent",
      label: "Parent Name",
      options: {
        customBodyRender: (parent) =>
          parent && parent.name ? parent.name : "N/A",
      },
    },
    { name: "status", label: "Status" },
  ];

  useEffect(() => {
    if (approvedStudents) {
      refetchApprovedStudents();
    }
  }, [showUploadModal === false, showCreateStudentCanvas === false]);

  useEffect(() => {
    if (studentRegister) {
      refetchStudentsRegisters();
    }
  }, [showUploadModal === false, showCreateStudentCanvas === false]);

  if (studentsLoading || registersLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div className="students-page">
      {(approvedStudents && approvedStudents.length > 0) ||
      (studentRegister && studentRegister.length > 0) ? (
        <section
          style={{
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
              marginBottom: "20px",
            }}
          >
            <h6 style={{ marginBottom: 0 }}>Students</h6>
          </div>

          <Row style={{ marginTop: 0.4 + "rem" }}>
            <Col xs={12} md={12} lg={12} className="otherbox">
              <Row>
                <Col xs={4} md={4} lg={4} id="branchsidecard-x1">
                  <Link to="/student/approved">
                    <div
                      className="branchsidecard-x1"
                      style={{ cursor: "pointer", height: 6 + "rem" }}
                    >
                      <div className="card-inner-value">
                        <h6
                          style={{
                            marginTop: 2 + "rem",
                            marginBottom: "3px",
                          }}
                        >
                          <b>Student List</b>
                        </h6>
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col xs={4} md={4} lg={4} id="branchsidecard-x1">
                  <Link to="/student/unapproved">
                    <div
                      className="branchsidecard-x1"
                      style={{ cursor: "pointer", height: 6 + "rem" }}
                    >
                      <div className="card-inner-value">
                        <h6
                          style={{
                            marginTop: 2 + "rem",
                            marginBottom: "3px",
                          }}
                        >
                          <b>Pending List</b>
                        </h6>
                      </div>
                    </div>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
      ) : (
        <div className="create-student-container">
          <img src={createStudentVector} alt="A student image" />
          <h3>Create Students</h3>
          <p>
            Creating students can be done in 2 ways; You can either click on
            "Upload Students" button to add existing students record to the
            platform OR click on "Create Students" button to add a new student
            record. After a successful upload or creation of student, the
            records can be found in the "Unapproved Batch" for processing.{" "}
          </p>
          <div className="btns">
            <Button onClick={handleShowUpload}>Upload Students</Button>

            <Button
              onClick={handleShowStudent}
              backgroundColor="#fff"
              color="#F14810"
              border="#F14810"
            >
              Create Students
            </Button>
          </div>
        </div>
      )}

      <Offcanvas
        show={showCreateStudentCanvas}
        onHide={handleCloseStudent}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Create Students</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ paddingTop: "0px" }}>
          {/* <StudentInformationPage /> */}
          <StudentInformationStepper closeCanvas={handleCloseStudent} />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showUploadModal}
        onHide={handleCloseUpload}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Upload Students</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <UploadStudentsPage
            showUploadModal={showUploadModal}
            setShowUploadModal={setShowUploadModal}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Students;
