// capitalize first letter of a string
export const capitalizeFirstLetter = (str) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const capitalizeWords = (str) => {
  const words = str?.split(" ");

  // Capitalize the first letter of each word and join them back together
  const capitalizedWords = words?.map((word) => {
    // if the word is empty
    if (word.length === 0) {
      return "";
    }
    return word[0].toUpperCase() + word.slice(1);
  });

  // Join the words into a string
  return capitalizedWords?.join(" ");
};

// export const addTrailingCommas = (value) => {
//   return value?.toLocaleString();
// };

export const addTrailingCommas = (value) => {
  return value?.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};


export const returnCategories = (number, categoryExample) => {
  console.log(number);
  console.log(categoryExample);

  const alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  if (number && categoryExample) {
    if (!alphabet.includes(categoryExample?.toUpperCase())) {
      return Array.from({ length: number }, (_, i) => {
        return { id: i + 1, value: categoryExample };
      });
    }

    const startAt = alphabet.indexOf(categoryExample.toUpperCase());
    const result = [];

    for (let i = 0; i < number; i++) {
      const index = (startAt + i) % alphabet.length;
      result.push({ id: i + 1, value: alphabet[index] });
    }

    return result;
  }

  // if only number is passed create an array of empty strings
  if (number && !categoryExample) {
    let result = [];

    for (let i = 0; i < number; i++) {
      result.push({ id: i + 1, value: "" });
    }

    return result;
  }

  return [];
};

export const returnCalendars = (number) => {
  // if only number is passed create an array of empty strings
  if (number) {
    let result = [];

    for (let i = 0; i < number; i++) {
      result.push({
        id: i + 1,
        periodName: "",
        startDate: "",
        endDate: "",
        open: "",
      });
    }

    return result;
  }

  return [];
};

export const returnCategoryName = (schoolType) => {
  let school;
  // if its an array then the last type determines the category name returned. if its just a string then we return the name required

  if (typeof schoolType === "object") {
    const schoolsKeyNames = schoolType?.map(
      (schoolType) => schoolType.typeName
    );

    school = schoolsKeyNames[schoolType?.length - 1];
  } else {
    school = schoolType;
  }

  if (school === "NURSERY") return "Class";
  if (school === "PRIMARY") return "Class";
  if (school === "SECONDARY") return "Class";
  if (school === "TERTIARY") return "Department";

  return "Category";
};

export const returnLevelName = (schoolType) => {
  let school;
  // if its an array then the last type determines the category name returned. if its just a string then we return the name required

  if (typeof schoolType === "object") {
    const schoolsKeyNames = schoolType?.map(
      (schoolType) => schoolType.typeName
    );

    school = schoolsKeyNames[schoolType?.length - 1];
  } else {
    school = schoolType;
  }

  if (school === "NURSERY") return "Grade";
  if (school === "PRIMARY") return "Basic";
  if (school === "SECONDARY") return "Year";
  if (school === "TERTIARY") return "Year";

  return "Level";
};

// Function to format the title
export const formatTitle = (title) => {
  return title
    .replace(/([A-Z])/g, ' $1')  // Separate camel case
    .replace(/^./, (str) => str.toUpperCase());  // Capitalize first letter of each word
};
