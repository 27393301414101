import React, { useEffect, useState } from "react";
import "./dashboard.css";
import "../../../../institution/institutionDashboard/institutionDashboardPage.css";
import { Row, Image, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LineDemo from "./LineDemo";
import PeriodProgress from "./PeriodProgress";

import Expectedrevenueicon from "../../../../../assets/svgs/expectedRevenueicon.svg";
import walleticon from "../../../../../assets/svgs/walleticon.svg";
import outstandingpaymenticon from "../../../../../assets/svgs/outstandingpaymenticon.svg";
import generatedrevenueicon from "../../../../../assets/svgs/generatedrevenueicon.svg";

import backArrow from "../../../../../assets/svgs/backArrow.svg";
import TableComponent from "../../../../../component/table/table";

import { useGetSchoolDashboardReportQuery } from "../../../../../redux/features/superAdmin/adminApiSlice";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import { addTrailingCommas, capitalizeWords } from "../../../../../utils/utils";
import {
  storeDashboardRecord,
  storeSchoolData,
} from "../../../../../redux/features/superAdmin/adminSlice";

const SchoolDashboard = ({ institutionDetails, setIsInstitutionViewed }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activePage, setActivePage] = useState("Payment History");

  const {
    data: dashboardData,
    isLoading: dashboardDataLoading,
    refetch,
  } = useGetSchoolDashboardReportQuery(institutionDetails?.id);

  dispatch(storeDashboardRecord(dashboardData));

  console.log(dashboardData);

  // dispatch(storeInstitutionData(institutionDetails));

  const otherDetails = [
    {
      title: "Expected Revenue",
      amount: addTrailingCommas(dashboardData?.expectedRevenue),
      icon: Expectedrevenueicon,
      style: "Expectedrevenuestyle",
    },
    {
      title: "Generated Revenue",
      amount: addTrailingCommas(dashboardData?.revenue),
      icon: generatedrevenueicon,
      style: "Generatedrevenuestyle",
    },
    {
      title: "Outstanding Payment",
      amount: addTrailingCommas(dashboardData?.pendingPayment),
      icon: outstandingpaymenticon,
      style: "outstandingstyle",
    },
    {
      title: "Overdue Payment",
      amount: addTrailingCommas(dashboardData?.overduePayment),
      icon: walleticon,
      style: "walletstyle",
    },
  ];
  const details = [
    {
      id: "1",
      title: dashboardData?.noOfBranches,
      text: "Branches",
      style: "sidecard-x5",
    },
    {
      id: "2",
      title: dashboardData?.totalStudents,
      text: "Total Students",
      style: "sidecard-x4",
    },
    {
      id: "3",
      title: dashboardData?.noOfSchoolAdmins,
      text: "School Admins",
      style: "sidecard-x6",
    },
  ];

  // Navigate to the clicked Page

  const handleNavigate = (id) => {
    if (id == 1) {
      // navigate(`/principal/schools/branches/${institutionDetails?.id}`);
    } else if (id == 2) {
      // navigate(`/principal/schools/students/${institutionDetails?.id}`);
    } else {
      navigate(`/principal/schools/schooladmin/${institutionDetails?.id}`);
    }
  };

  const walletTransactionsColumns = [
    // { name: "id", label: "S/N" },
    {
      name: "name",
      label: "Student Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { firstName, middleName, lastName } =
            dashboardData?.walletTransactions[tableMeta.rowIndex];
          return `${firstName} ${middleName ? middleName : ""} ${lastName}`;
        },
      },
    },
    { name: "className", label: "Class" },
    // { name: "date", label: "Date" },
    {
      name: "time",
      label: "Time",
      options: {
        //customBodyRender: (date) => (date ? date?.split("T")[0] : "N/A"),
        customBodyRender: (time) => (time ? time : "N/A"),
      },
    },
    // { name: "paidAmount", label: "Paid Amount" },
    {
      name: "paidAmount",
      label: "Paid Amount",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { paidAmount } =
            dashboardData?.walletTransactions[tableMeta.rowIndex];
          return `${"₦ " + addTrailingCommas(paidAmount)}`;
        },
      },
    },
    // {
    //     name: "paid",
    //     label: "Status",
    //     options: {
    //         customBodyRender: (paid) => (paid ? "Paid" : "Not Paid"),
    //     },
    // },
    { name: "paymentType", label: "Payment Type" },
    { name: "transactionReference", label: "Transaction Ref" },
    // {
    //     name: "actions",
    //     label: "Action",
    //     options: {
    //         customBodyRender: (value, tableMeta) => (
    //             <img
    //                 src={eyeActionIcon}
    //                 alt="eye icon"
    //                 style={{ cursor: "pointer" }}
    //                 onClick={() => navigate(`${institutionList[tableMeta.rowIndex].id}`)}
    //             />
    //         ),
    //     },
    // },
  ];

  const paymentHistoryColumns = [
    // { name: "id", label: "S/N" },
    {
      name: "name",
      label: "Student Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { firstName, middleName, lastName } =
            dashboardData?.paymentTransactions[tableMeta.rowIndex];
          return `${firstName} ${middleName ? middleName : ""} ${lastName}`;
        },
      },
    },
    { name: "className", label: "Class" },
    // { name: "date", label: "Date" },
    {
      name: "time",
      label: "Time",
      options: {
        //customBodyRender: (date) => (date ? date?.split("T")[0] : "N/A"),
        customBodyRender: (time) => (time ? time : "N/A"),
      },
    },
    // { name: "paidAmount", label: "Paid Amount" },
    {
      name: "paidAmount",
      label: "Paid Amount",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { paidAmount } =
            dashboardData?.paymentTransactions[tableMeta.rowIndex];
          return `${"₦ " + addTrailingCommas(paidAmount)}`;
        },
      },
    },
    {
      name: "feeTotal",
      label: "Fee Total",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { feeTotal } =
            dashboardData?.paymentTransactions[tableMeta.rowIndex];
          return `${"₦ " + addTrailingCommas(feeTotal)}`;
        },
      },
    },
    // {
    //     name: "paid",
    //     label: "Status",
    //     options: {
    //         customBodyRender: (paid) => (paid ? "Paid" : "Not Paid"),
    //     },
    // },
    { name: "transactionReference", label: "Transaction Ref" },
    // {
    //     name: "actions",
    //     label: "Action",
    //     options: {
    //         customBodyRender: (value, tableMeta) => (
    //             <img
    //                 src={eyeActionIcon}
    //                 alt="eye icon"
    //                 style={{ cursor: "pointer" }}
    //                 onClick={() => navigate(`${institutionList[tableMeta.rowIndex].id}`)}
    //             />
    //         ),
    //     },
    // },
  ];

  // useEffect(() => {
  //     if (dashboardData) {
  //         refetch()
  //     }
  // }, [])

  if (dashboardDataLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          cursor: "pointer",
          marginBottom: "10px",
        }}
        onClick={() => setIsInstitutionViewed(false)}
      >
        <img src={backArrow} alt="Go back" />
        <h6 style={{ marginBottom: 0 }}>
          {institutionDetails?.institutionName}
        </h6>
      </div>

      <Row style={{ marginTop: 0.4 + "rem" }}>
        <Col xs={12} md={12} lg={12}>
          <div
            className="session-text-container-web"
            style={{ marginBottom: 1 + "rem" }}
          >
            <h5 style={{ marginBottom: "5px" }}>
              {/* <b> */}
              <span>
                {capitalizeWords(dashboardData?.activeCalendar?.schoolYear)}
              </span>
              {/* </b> */}
            </h5>
            <h6 style={{ color: "#b9b9b9" }}>Academic Session</h6>
          </div>
        </Col>

        <Col xs={12} md={12} lg={5}>
          <div className="sessionbox-admin">
            <div className="sessionbox-firstpart-admin">
              <div>
                <h5 style={{ color: "#000" }}>
                  {dashboardData?.activeCalendar?.periodName}
                </h5>
                <p
                  style={{
                    color: "#000",
                    marginTop: -0.3 + "rem",
                    fontSize: 0.75 + "rem",
                  }}
                >
                  Current Term in progress
                </p>
                <p
                  style={{
                    // color: "#B3B3B3",
                    marginTop: -0.3 + "rem",
                    fontSize: 0.75 + "rem",
                  }}
                >
                  <span>
                    <b style={{ color: "#000" }}>Today</b>:
                  </span>
                  {dashboardData?.activeCalendar?.todayDate}
                </p>
              </div>
              <div>
                <PeriodProgress />
              </div>
            </div>

            <div className="sessionbox-otherpart-admin">
              <div>
                <h6 style={{ color: "#000", fontSize: 0.8 + "rem" }}>
                  <b>Start Date:</b>
                </h6>
                <p
                  style={{
                    // color: "#B3B3B3",

                    marginTop: -0.3 + "rem",
                    fontSize: 0.75 + "rem",
                  }}
                >
                  {dashboardData?.activeCalendar?.startDate}
                </p>
              </div>
              <div>
                <h6 style={{ color: "#000", fontSize: 0.8 + "rem" }}>
                  <b>End Date:</b>
                </h6>
                <p
                  style={{
                    // color: "#B3B3B3",
                    marginTop: -0.3 + "rem",
                    fontSize: 0.75 + "rem",
                  }}
                >
                  {dashboardData?.activeCalendar?.endDate}
                </p>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={12} md={12} lg={7} className="otherbox">
          <Row>
            {otherDetails.map(({ icon, amount, title, style }, i) => (
              <Col xs={6} md={6} lg={6} id={style} key={i}>
                <div className="card">
                  <div className="card-inner">
                    <div>
                      <Image src={icon} alt="img" fluid />
                    </div>
                    <div className="card-inner-value">
                      <h5 style={{ marginTop: 1 + "rem" }}>
                        <b> ₦ {amount?.toLocaleString()}</b>
                      </h5>
                      <p style={{ marginTop: -0.61 + "rem" }}>{title}</p>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: 2 + "rem" }}>
        <Col xs={12} md={12} lg={12} className="otherbox">
          <Row>
            {details.map(({ id, title, text, style }, i) => (
              <Col xs={8} md={8} lg={4} key={id}>
                <div
                  className={style}
                  style={{
                    cursor: "pointer",
                    marginTop: 0.5 + "rem",
                    marginBottom: 0.5 + "rem",
                  }}
                  onClick={() => handleNavigate(id)}
                >
                  <div className="card-inner-value">
                    <h6 style={{ marginTop: 1 + "rem" }}>
                      <b>{title}</b>
                    </h6>
                    <p
                      className="card-inner-text"
                      style={{ marginTop: -0.61 + "rem" }}
                    >
                      {text}
                    </p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <div
        style={{
          paddingTop: 1 + "rem",
          marginBottom: 2 + "rem",
        }}
      >
        <div
          style={{
            border: "2px solid #EDEDED",
            borderRadius: "8px",
            gap: "30px",
            backgroundColor: "#FFFFFF",
            padding: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 10,
            }}
          >
            <h5>Daily Transactions</h5>

            <div style={{ marginBottom: "20px" }}>
              <span
                onClick={() => setActivePage("Wallet Transactions")}
                style={{
                  backgroundColor: `${
                    activePage === "Wallet Transactions" ? "#ffede8" : "#f4f4f4"
                  }`,
                  border: `1px solid ${
                    activePage === "Wallet Transactions" ? "#f89a7c" : "#dbdbdb"
                  }`,
                  marginRight: "10px",
                  padding: "8px 18px",
                  borderRadius: "20px",
                  cursor: "pointer",
                  fontWeight: "500",
                }}
              >
                Wallet Transactions
              </span>

              <span
                onClick={() => setActivePage("Payment History")}
                style={{
                  backgroundColor: `${
                    activePage === "Payment History" ? "#ffede8" : "#f4f4f4"
                  }`,
                  border: `1px solid ${
                    activePage === "Payment History" ? "#f89a7c" : "#dbdbdb"
                  }`,
                  marginRight: "10px",
                  padding: "8px 18px",
                  borderRadius: "20px",
                  cursor: "pointer",
                  fontWeight: "500",
                }}
              >
                Payment History
              </span>
            </div>
          </div>

          {activePage === "Wallet Transactions" && (
            <section
              style={{
                marginTop: "20px",
              }}
            >
              <TableComponent
                data={dashboardData?.walletTransactions}
                title=""
                columns={walletTransactionsColumns}
                search={true}
                pagination={10}
              />
            </section>
          )}

          {activePage === "Payment History" && (
            <section
              style={{
                marginTop: "20px",
              }}
            >
              <TableComponent
                data={dashboardData?.paymentTransactions}
                title=""
                columns={paymentHistoryColumns}
                search={true}
                pagination={10}
              />
            </section>
          )}
        </div>
      </div>
      
      <br />
      <br />
    </>
  );
};

export default SchoolDashboard;
