import { createSlice } from "@reduxjs/toolkit";

// storing in local storage for now, will remove from local storage when the login api ensures users are registered before allowing them log in
const initialState = {
  superUsers: null,
  dashboardRecord: null,
  schoolData: null,
  adminDashboardData: null,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    storeSuperUsers: (state, action) => {
      state.superUsers = action.payload;
    },
    storeSchoolData: (state, action) => {
      state.schoolData = action.payload;
    },
    storeDashboardRecord: (state, action) => {
      state.dashboardRecord = action.payload;
    },
    storeAdminDashboardData: (state, action) => {
      state.adminDashboardData = action.payload;
    },

  },
});

export const { storeSuperUsers, storeDashboardRecord, storeSchoolData, storeAdminDashboardData } = adminSlice.actions;

export default adminSlice.reducer;
