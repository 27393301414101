import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import SchoolBranchIcon from "../../../../../../assets/svgs/schoolbranch.svg";
import Button from "../../../../../../component/button/button";
import totalStudentsIcon from "../../../../../../assets/svgs/totalStudentsIcon.svg";
import maleStudentIcon from "../../../../../../assets/svgs/maleStudentIcon.svg";
import femaleStudentIcon from "../../../../../../assets/svgs/femaleStudentIcon.svg";
import { Offcanvas } from "react-bootstrap";
import BranchInfoPage from "./components/branchInfo";
import {
  useGetBranchesQuery,
  useGetInstitutionDetailQuery,
  useGetSchoolAdminsQuery,
} from "../../../../../../redux/features/superAdmin/adminApiSlice";
import { useSelector } from "react-redux";
import "./branches.css";
import { useEffect } from "react";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import SuccessScreen from "../../../../../../component/successScreen/successScreen";
import { capitalizeWords } from "../../../../../../utils/utils";
import RegisterBranchStepper from "./components/registerBranchStepper";
import DashboardSidebar from "../../../dashboardSideBar/sidebar";
import DashboardTopSection from "../../../dashboardTopSection";
import backArrow from "../../../../../../assets/svgs/backArrow.svg";

const BranchesPage = () => {

  const { institutionId } = useParams();

  // const [branches, setBranches] = useState([0]);
  const [showCreateBranchCanvas, setShowCreateBranchCanvas] = useState(false);

  const handleCloseBranch = () => {
    setShowCreateBranchCanvas(false);
  };
  const handleShowBranch = () => {
    setShowCreateBranchCanvas(true);
  };

  // const handleCloseUpload = () => {
  //     setShowUploadModal(false);
  // };
  // const handleShowUpload = () => {
  //     setShowUploadModal(true);
  // };

  const {
    data: branchList,
    refetch: refetchBranches,
    isLoading: branchesLoading,
    isSuccess: branchSuccess,
  } = useGetBranchesQuery(institutionId);

  console.log(branchList);

  const {
    data: institutionDetails,
    refetch: refetchInstition,
    isLoading: institutionLoading,
  } = useGetInstitutionDetailQuery(institutionId);

  console.log(institutionDetails);

  const navigate = useNavigate();

  const handleViewBranchStudentDetail = (id) => {
    // console.log(details)
    navigate(`/branch/${id}`);
  };

  useEffect(() => {
    if (branchList) {
      refetchBranches();
    }
  }, [showCreateBranchCanvas]);

  if (
    branchesLoading
    ||
    institutionLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <div className="branches-page" style={{ display: "flex" }}>

      <DashboardSidebar />

      <Container
        fluid
        className="bodycontainer"
        style={{ backgroundColor: "#f5f5f5" }}
      >

        <DashboardTopSection />

        {branchList && (
          <section
            style={{
              marginTop: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "30px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                }}
                onClick={() => navigate(-1)}
              >
                <img src={backArrow} alt="Go back" />
                <h5 style={{ marginBottom: 0 }}>
                  {institutionDetails?.institutionName} ( Branches )
                </h5>
              </div>

              {/* <div style={{ display: "flex", gap: "5px" }}>
                <Button
                  backgroundColor="#f14810"
                  border="#f14810"
                  onClick={handleShowBranch}
                >
                  Create Branch
                </Button>
              </div> */}
            </div>

            <>
              <Row style={{ marginTop: 0.4 + "rem" }}>
                <Col xs={12} md={12} lg={12} className="otherbox">
                  <Row>
                    {branchList &&
                      branchList.length > 0 &&
                      branchList.map(({ id, name, address }, i) => (
                        <Col xs={4} md={4} lg={4} id="branchsidecard-x1" key={id}>
                          <div
                            className="branchsidecard-x1"
                            style={{ cursor: "pointer", height: 6 + "rem" }}
                            onClick={() => handleViewBranchStudentDetail(id)}
                          >
                            <div className="card-inner-value">
                              <h6
                                style={{
                                  marginTop: 2 + "rem",
                                  marginBottom: "3px",
                                }}
                              >
                                <b>{capitalizeWords(name)}</b>
                              </h6>
                              <p
                                className="card-inner-text"
                                style={{ marginTop: -0.61 + "rem" }}
                              >
                                {capitalizeWords(address)}
                              </p>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </Col>
              </Row>
            </>
          </section>
        )}

      </Container>

      <Offcanvas
        show={showCreateBranchCanvas}
        onHide={handleCloseBranch}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Create Branch</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ paddingTop: "0px" }}>
          <RegisterBranchStepper
            setShowCreateBranchCanvas={setShowCreateBranchCanvas}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default BranchesPage;
