import React, { useState, useEffect } from "react";
import { Row, Col, Form, InputGroup, Button } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  storeStudentInfo,
  storeParentInfo,
} from "../../../../../../../../redux/features/institution/studentSlice";
import {
  useAddStudentRegisterMutation,
  useCreateStudentMutation,
  useUpdateStudentInRegisterMutation,
  useGetStudentsRegisterQuery,
} from "../../../../../../../../redux/features/institution/studentApiSlice";
import {
  useGetAllBranchesQuery,
  useGetInstitutionLevelsQuery,
  useGetInstitutionCategoriesQuery,
  useGetInstitutionPeriodsQuery,
  useGetInstitutionYearQuery,
  useGetAllClassesQuery,
  useGetCategoriesInALevelQuery,
  useUploadDocumentMutation,
} from "../../../../../../../../redux/features/institution/institutionApiSlice";
import stepTwoOfTwo from "../../../../../../../../assets/svgs/stepTwoOfTwo.svg";
import OnboardingLoader from "../../../../../../../../component/onboardingLoader/onboardingLoader";
import { capitalizeWords } from "../../../../../../../../utils/utils";
import { useNavigate } from "react-router-dom";
import ToastComponent from "../../../../../../../../component/toast/toast";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const StudentInformationPage = ({ setActiveStage, closeCanvas, student }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [allClasses, setAllClasses] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log(studentRecord);

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  // console.log(instId);

  const { data: classes, isLoading: classesLoading } =
    useGetAllClassesQuery(instId);

  // get previous data if they have filled this page before
  const studentInfoData = useSelector((state) => state.student.studentInfo);
  const parentInfo = useSelector((state) => state.student.parentInfo);
  // console.log(studentInfoData);

  // console.log(student);

  const { data: branches, isLoading: branchesLoading } =
    useGetAllBranchesQuery(instId);

  const {
    data: studentRegister,
    refetch: refetchStudentsRegisters,
    isLoading: registersLoading,
  } = useGetStudentsRegisterQuery(instId);

  // console.log(studentRegister);

  // const { data: levels, isLoading: levelsLoading } =
  //   useGetInstitutionLevelsQuery(instId);

  const { data: schoolYears } = useGetInstitutionYearQuery(instId);

  const [createStudent, { isLoading: createLoading }] =
    useCreateStudentMutation();
  const [updateStudentInRegister, { isLoading: updateLoading }] =
    useUpdateStudentInRegisterMutation();

  const [activeStudentIndex, setActiveStudentIndex] = useState(0);

  const [studentData, setStudentData] = useState(() => {
    if (student) {
      // If student is available, use its data
      return [
        {
          firstname: student?.firstname || "",
          middlename: student?.middlename || "",
          lastname: student?.lastname || "",
          studentNo: student?.studentNo || "",
          dateOfBirth: student?.dateOfBirth || "",
          email: student?.email || "",
          phoneNo: student?.phoneNo || "",
          gender: student?.gender || "",
          branchId: student?.branch?.id || "",
          studentCode: student?.studentCode || "",
          institutionCategoryId: student?.institutionCategory?.id || "",
        },
      ];
    } else if (studentInfoData && Array.isArray(studentInfoData)) {
      // If studentInfoData is an array, map over it to create an array of objects
      return studentInfoData.map((data) => ({
        firstname: data?.firstname || "",
        middlename: data?.middlename || "",
        lastname: data?.lastname || "",
        studentNo: data?.studentNo || "",
        dateOfBirth: data?.dateOfBirth || "",
        email: data?.email || "",
        phoneNo: data?.phoneNo || "",
        gender: data?.gender || "",
        branchId: data?.branch?.id || "",
        studentCode: data?.studentCode || "",
        institutionCategoryId: data?.institutionCategoryId || "",
      }));
    } else {
      // If neither student nor studentInfoData is available, initialize with an empty array
      return [];
    }
  });

  const activeStudent = studentData[activeStudentIndex];

  const {
    firstname,
    lastname,
    middlename,
    branchId,
    institutionCategoryId,
    dateOfBirth,
    institutionLevelId,
    email,
    gender,
    phoneNo,
    studentNo,
  } = activeStudent || {};

  const { data: categories, isLoading: categoriesLoading } =
    useGetCategoriesInALevelQuery(
      {
        institutionId: instId,
        levelId: institutionLevelId,
      },
      { skip: !institutionLevelId }
    );

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    const updatedStudents = [...studentData];

    updatedStudents[activeStudentIndex] = {
      ...activeStudent,
      [name]: value,
    };
    setStudentData(updatedStudents);
  };

  const handleAddStudent = () => {
    dispatch(storeStudentInfo(studentData));
    setStudentData([
      ...studentData,
      {
        firstname: "",
        middlename: "",
        lastname: "",
        studentNo: "",
        dateOfBirth: "",
        email: "",
        phoneNo: "",
        gender: "",
        branchId: "",
        studentCode: "",
        institutionLevelId: "",
        institutionCategoryId: "",
        schoolYearId: "",
        calendarId: "",
      },
    ]);

    // Set the active student to the newly added one
    setActiveStudentIndex(studentData.length);
    // window.scrollTo(0, 0);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const handleToggleStudent = (index) => {
    dispatch(storeStudentInfo(studentData));
    setActiveStudentIndex(index);
  };

  const handleGoToPrevPage = () => {
    dispatch(storeStudentInfo(studentData));
    setActiveStage("Parent Information");
  };

  const handleSubmitDetails = async () => {
    const params = {
      institutionId: instId,
      body: parentInfo?.isNewParent
        ? {
            students: studentData?.map((student) => {
              const { dateOfBirth } = student;
              let newDateFormat = dateOfBirth?.split("-");

              // yymmdd
              // 001122

              // yyyy-dd-mm

              return {
                ...student,
                dateOfBirth: newDateFormat
                  ? newDateFormat[0] +
                    "-" +
                    newDateFormat[1] +
                    "-" +
                    newDateFormat[2]
                  : "",
              };
            }),
            // students: [...studentData],
            newParent: { ...parentInfo },
          }
        : {
            students: studentData?.map((student) => {
              const { dateOfBirth } = student;
              let newDateFormat = dateOfBirth?.split("-");

              return {
                ...student,
                dateOfBirth: newDateFormat
                  ? newDateFormat[0] +
                    "-" +
                    newDateFormat[1] +
                    "-" +
                    newDateFormat[2]
                  : "",
              };
            }),
            // students: [...studentData],
            existingParentId: parentInfo?.id,
          },
    };

    try {
      const res = await createStudent(params).unwrap();
      console.log(res);
      closeCanvas();
      dispatch(storeParentInfo(null));
      dispatch(storeStudentInfo(null));
      navigate("/student/unapproved");
      refetchStudentsRegisters();
    } catch (error) {
      console.log(error);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
        setShowToast(true);
      } else {
        // other errors
        setToastMessage(error.error);
        setShowToast(true);
      }
    }
  };

  const handleEditDetails = async () => {
    const { dateOfBirth, ...rest } = studentData[0];
    let newDateFormat = dateOfBirth?.split("-");

    const params = {
      studentId: student?.id,
      institutionId: instId,
      body: parentInfo?.isNewParent
        ? {
            // students: [...studentData],
            // students: studentData?.map((student) => {
            //   const { dateOfBirth } = student;
            //   let newDateFormat = dateOfBirth?.split("-");

            //   return {
            //     ...student,
            //     dateOfBirth: newDateFormat
            //       ? newDateFormat[0] +
            //         "-" +
            //         newDateFormat[2] +
            //         "-" +
            //         newDateFormat[1]
            //       : "",
            //   };
            // }),
            newParent: { ...parentInfo },
            ...rest,
            dateOfBirth: newDateFormat
              ? newDateFormat[0] +
                "-" +
                newDateFormat[1] +
                "-" +
                newDateFormat[2]
              : "",
          }
        : {
            // students: [...studentData],
            // students: studentData?.map((student) => {
            //   const { dateOfBirth } = student;
            //   let newDateFormat = dateOfBirth?.split("-");

            //   return {
            //     ...student,
            //     dateOfBirth: newDateFormat
            //       ? newDateFormat[0] +
            //         "-" +
            //         newDateFormat[2] +
            //         "-" +
            //         newDateFormat[1]
            //       : "",
            //   };
            // }),
            existingParentId: parentInfo?.id,
            ...rest,
            dateOfBirth: newDateFormat
              ? newDateFormat[0] +
                "-" +
                newDateFormat[1] +
                "-" +
                newDateFormat[2]
              : "",
          },
    };

    try {
      const res = await updateStudentInRegister(params).unwrap();
      console.log(res);
      closeCanvas();
      dispatch(storeParentInfo(null));
      dispatch(storeStudentInfo(null));
      refetchStudentsRegisters();
    } catch (error) {
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  useEffect(() => {
    if (classes) {
      const allCategories = Object.keys(classes)
        .map((key) => {
          return classes[key].map((eachClass) => {
            return eachClass.categories.map((category) => {
              return {
                name: `${eachClass.name + " - " + category.name}`,
                id: category.id,
              };
            });
          });
        })
        .flat();

      setAllClasses(allCategories.flat());
    }
  }, [classes]);

  if (createLoading || updateLoading || classesLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <div>
        <div
          style={{ display: "flex", gap: 0.8 + "rem", alignItems: "center" }}
        >
          <img src={stepTwoOfTwo} alt="step 1 of 2" />
          <h5 style={{ marginBottom: "0px" }}>Student Information</h5>
        </div>
      </div>

      <Form className="form" style={{ marginTop: "15px" }}>
        <div style={{ display: "flex" }}>
          {studentData.length > 1 &&
            studentData.map((student, index) => (
              <div
                key={index}
                style={{
                  height: "20px",
                  width: "20px",
                  backgroundColor: `${
                    index === activeStudentIndex ? "#f14810" : "#adadad"
                  }`,
                  borderRadius: "100%",
                  color: "#ffffff",
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                  marginRight: "3px",
                  cursor: "pointer",
                }}
                onClick={() => handleToggleStudent(index)}
              >
                {index + 1}
              </div>
            ))}
        </div>

        <div>
          <Row>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  First Name <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type="text"
                  name="firstname"
                  value={firstname}
                  onChange={handleOnChange}
                  placeholder="Enter First Name"
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Middle Name
                  {/* Middle Name <span style={{ color: "red" }}>*</span> */}
                </Form.Label>

                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type="text"
                  name="middlename"
                  value={middlename}
                  onChange={handleOnChange}
                  placeholder="Enter Middle Name"
                  // required
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Last Name <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="text"
              name="lastname"
              value={lastname}
              onChange={handleOnChange}
              placeholder="Enter Last Name"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Student Number <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="text"
              name="studentNo"
              value={studentNo}
              onChange={handleOnChange}
              placeholder="Enter Student Admission Number"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Date of Birth <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <input
              type="date"
              pattern="\d{4}-\d{2}-\d{2}"
              style={{
                backgroundColor: "#ededed",
                padding: "5px 10px",
                borderRadius: "5px",
                border: "none",
                outline: "none",
                fontSize: "14px",
              }}
              name="dateOfBirth"
              value={dateOfBirth}
              onChange={handleOnChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">Email Address</Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="email"
              name="email"
              value={email}
              onChange={handleOnChange}
              placeholder="Enter Email Address"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">Phone Number</Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="text"
              name="phoneNo"
              value={phoneNo}
              onChange={handleOnChange}
              placeholder="Enter Phone number"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Gender <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <InputGroup className="mb-3" required>
              <div key={`inline-radio`}>
                <Form.Check
                  inline
                  label="Male"
                  name="gender"
                  checked={gender?.toLowerCase() === "male"}
                  onChange={handleOnChange}
                  value="male"
                  type="radio"
                  id={`inline-radio-1`}
                  size={25}
                  // style={{ backgroundColor: '#F14810', color: '#ffffff' }}
                />
                <Form.Check
                  inline
                  label="Female"
                  name="gender"
                  value="female"
                  checked={gender === "female"}
                  onChange={handleOnChange}
                  type="radio"
                  id={`inline-radio-2`}
                  size={25}
                  style={{ variant: "outline-light" }}
                />
              </div>
            </InputGroup>
          </Form.Group>

          <Row>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Branch <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Select
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  name="branchId"
                  value={branchId}
                  onChange={handleOnChange}
                  required
                >
                  <option value="" defaultValue>
                    Select Branch
                  </option>
                  {branches?.map(({ id, name }) => (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Class <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <Form.Select
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              name="institutionCategoryId"
              value={institutionCategoryId}
              onChange={handleOnChange}
              required
            >
              <option value="" selected disabled>
                Select Class
              </option>

              {allClasses?.map(({ id, name }) => (
                <option
                  value={id}
                  key={id}
                  selected={student?.institutionCategory?.id === id}
                >
                  {name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>

        <br />

        {student ? null : (
          <div style={{ marginTop: "10px" }}>
            <span
              style={{
                color: "#F14810",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={handleAddStudent}
            >
              + Add More Student to{" "}
              {parentInfo ? capitalizeWords(parentInfo?.name) : null}
            </span>
          </div>
        )}
      </Form>

      <br />

      <div style={{ display: "flex", gap: 1.1 + "rem", marginTop: "20px" }}>
        <Button
          variant="outline-primary"
          style={{
            borderColor: "#F14810",
            color: "#F14810",
            fontSize: 0.8 + "rem",
            padding: "7px 20px",
          }}
          className="button"
          size="md"
          onClick={handleGoToPrevPage}
        >
          Previous
        </Button>

        <Button
          variant="primary"
          style={{
            backgroundColor: "#F14810",
            borderColor: "#F14810",
            float: "left",
            fontSize: 0.8 + "rem",
            padding: "7px 20px",
          }}
          className="button"
          size="md"
          // type="submit"
          onClick={
            student ? () => handleEditDetails() : () => handleSubmitDetails()
          }
        >
          Submit
        </Button>
      </div>

      <br />
    </>
  );
};

export default StudentInformationPage;
