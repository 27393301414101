import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table, Container, Col, Row } from "react-bootstrap";
import logo from "../../assets/svgs/logo.svg";
import copyIcon from "../../assets/svgs/copyIcon.svg";
import "./parent.css";
import {
  useUpdateInvoiceMutation,
  useGetStudentInvoiceDetailsQuery,
  usePayFeeMutation,
} from "../../redux/features/institution/parentApiSlice";
import OnboardingLoader from "../../component/onboardingLoader/onboardingLoader";
import { addTrailingCommas, capitalizeWords } from "../../utils/utils";
import { Button } from "react-bootstrap";
import ToastComponent from "../../component/toast/toast";

const ParentPage = () => {
  const [compulsoryFees, setCompulsoryFees] = useState(null);
  const [optionalFees, setOptionalFees] = useState(null);
  const [updatedDetails, setUpdatedDetails] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [copyMessage, setCopyMessage] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const { param } = useParams();
  const [selectAllCompulsory, setSelectAllCompulsory] = useState(false);
  const {
    data: details,
    isLoading,
    refetch,
  } = useGetStudentInvoiceDetailsQuery(param, {
    skip: !param,
  });

  const [updateInvoice, { isLoading: updateLoading }] =
    useUpdateInvoiceMutation();

  const [payFee, { isLoading: paymentLoading }] = usePayFeeMutation();

  useEffect(() => {
    console.log("Page is Loading");
    console.log(details);

    if (details) {
      refetch();
      setAccountNumber(details?.nuban);
      setCompulsoryFees(details?.compulsoryFees);
      setOptionalFees(details?.optionalFees);
    }
  }, [details, updatedDetails, showToast]);

  console.log(showToast);
  console.log(isLoading);

  const tableHeaders = ["S/N", "Fee Description", "Amount", "Status"];
  const optionalTableHeaders = ["S/N", "Fee Description", "Amount", "Status"];


  const handleAllCheckBoxChangeCompulsory = () => {
    // set all selected to either true or false
    const updatedFees = compulsoryFees?.map((fee) => {
      if (fee?.paid) {
        return {
          ...fee,
          feeId: fee?.id,
          selected: true,
        };
      } else {
        return {
          ...fee,
          feeId: fee?.id,
          selected: !selectAllCompulsory,
        };
      }
    });
    // console.log(updatedFees)
    setSelectAllCompulsory(!selectAllCompulsory);
    // setUpdatedFees(updatedFees);
    setCompulsoryFees(updatedFees);
    handleUpdateInvoice(updatedFees);
  };

  const handleOnChangeCompulsory = (feeId) => {
    // reset the select all incase it was first checked then the user unchecks one item
    setSelectAllCompulsory(false);

    console.log("feeId", feeId);

    // change the selected value of the checkbox then send the updated array to the api
    const updatedFees = compulsoryFees?.map((fee) => {
      // if the status is paid, they cant uncheck the box
      if (fee?.id === feeId) {
        if (fee?.paid) {
          return {
            ...fee,
            feeId: fee?.id,
            selected: true,
          };
        }
        return { ...fee, feeId: fee?.id, selected: !fee?.selected };
      } else {
        return { ...fee, feeId: fee?.id, selected: fee?.selected };
      }
    });
    console.log("updatedFees ", updatedFees);

    // setUpdatedFees(updatedFees)
    setCompulsoryFees(updatedFees);
    handleUpdateInvoice(updatedFees);
  };

  const handleOnChange = (feeId) => {
    // reset the select all incase it was first checked then the user unchecks one item
    setSelectAll(false);

    console.log(feeId);

    // change the selected value of the checkbox then send the updated array to the api
    const updatedFees = optionalFees?.map((fee) => {
      // if the status is paid, they cant uncheck the box
      if (fee?.id === feeId) {
        if (fee?.paid) {
          return {
            ...fee,
            feeId: fee?.id,
            selected: true,
          };
        }
        return { ...fee, feeId: fee?.id, selected: !fee?.selected };
      } else {
        return { ...fee, feeId: fee?.id, selected: fee?.selected };
      }
    });
    console.log(updatedFees);

    // setUpdatedFees(updatedFees)
    setOptionalFees(updatedFees);
    handleUpdateInvoice(updatedFees);
  };

  const handleAllCheckBoxChange = () => {
    // set all selected to either true or false
    const updatedFees = optionalFees?.map((fee) => {
      if (fee?.paid) {
        return {
          ...fee,
          feeId: fee?.id,
          selected: true,
        };
      } else {
        return {
          ...fee,
          feeId: fee?.id,
          selected: !selectAll,
        };
      }
    });
    // console.log(updatedFees)
    setSelectAll(!selectAll);
    // setUpdatedFees(updatedFees);
    setOptionalFees(updatedFees);
    handleUpdateInvoice(updatedFees);
  };

  const handleUpdateInvoice = async (arr) => {
    try {
      const parameters = {
        accessToken: param,
        body: arr,
      };
      const res = await updateInvoice(parameters).unwrap();
      setUpdatedDetails(res);
      console.log(res);
    } catch (error) {
      console.log(error.data);
      setToastMessage(error.data.detail);
      setShowToast(true);
    }
  };

  const handlePayFee = async () => {
    try {
      const res = await payFee(param).unwrap();
      console.log(res);
      setToastMessage("Payment successful!");
      setShowToast(true);
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  console.log(optionalFees?.map((fee) => fee?.paid != true));

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(accountNumber);
      setCopyMessage("Account has been copied");
      setTimeout(() => {
        setCopyMessage(null);
      }, 2000);
    } catch (error) {
      setCopyMessage("error copying to clipboard");
      setTimeout(() => {
        setCopyMessage(null);
      }, 2000);
    }
  };

  function invertColor(color) {
    // Convert the color to RGB
    let r, g, b;

    if (color?.length === 4) {
      // Handle 3-digit hex colors (#abc)
      r = parseInt(color[1] + color[1], 16);
      g = parseInt(color[2] + color[2], 16);
      b = parseInt(color[3] + color[3], 16);
    } else if (color?.length === 7) {
      // Handle 6-digit hex colors (#aabbcc)
      r = parseInt(color.slice(1, 3), 16);
      g = parseInt(color.slice(3, 5), 16);
      b = parseInt(color.slice(5, 7), 16);
    }

    // Calculate relative luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Decide whether to invert based on luminance
    return luminance > 0.9 ? "#000000" : color;
  }

  if (isLoading || updateLoading || paymentLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div style={{ backgroundColor: "#dbd6d4", minHeight: "100vh" }}>
      {/* notification toast */}
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <div style={{ backgroundColor: "#ffffff", padding: "20px 0" }}>
        <div
          style={{
            width: "85%",
            margin: "0 auto",
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={details?.logo}
              alt="Adept Logo"
              style={{ width: "100%", height: "100%" }}
            />
          </div>

          {/* <h4>{details?.institutionName}</h4> */}
          <h4 style={{ marginBottom: "0", marginLeft: "20px" }}>
            {capitalizeWords(details?.institutionName)}
          </h4>

          <Button
            type="button"
            onClick={handlePayFee}
            variant="outline-primary"
            style={{
              // borderColor: `${details?.brandColour}`,
              borderColor: invertColor(details?.brandColour),
              color: "#ffffff",
              // color: invertColor("#ffffff"),
              // backgroundColor: `${details?.brandColour}`,
              backgroundColor: invertColor(details?.brandColour),
              marginLeft: "auto",
            }}
            className="button"
            size="md"
          >
            Pay from Wallet
          </Button>
        </div>
      </div>
      <section
        style={{
          display: "flex",
          gap: "30px",
          width: "85%",
          margin: "0 auto",
          padding: "30px 0",
        }}
        className="parent-page-container"
      >
        <div style={{ flexBasis: "30%" }}>
          <div>
            <h6 style={{ fontWeight: 500 }}>Student Details</h6>
            <div style={{ borderRadius: "15px", backgroundColor: "white" }}>
              <div
                style={{
                  padding: "15px",
                  borderBottom: "1px solid #F3F3F3",
                }}
              >
                <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                  {capitalizeWords(details?.studentName)}
                </h5>
                <p style={{ marginBottom: 0, color: "#535151" }}>
                  Student Name
                </p>
              </div>
              
              <div
                style={{
                  padding: "15px",
                  borderBottom: "1px solid #F3F3F3",
                }}
              >
                <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                  {details?.studentClass}
                </h5>
                <p style={{ marginBottom: 0, color: "#535151" }}>
                  Student Class
                </p>
              </div>
              <div
                style={{
                  padding: "15px",
                  borderBottom: "1px solid #F3F3F3",
                }}
              >
                <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                  {details?.parent?.name}
                </h5>
                <p style={{ marginBottom: 0, color: "#535151" }}>
                  Parent Name
                </p>
              </div>
              <div
                style={{
                  padding: "15px",
                  borderBottom: "1px solid #F3F3F3",
                }}
              >
                <Row>
                  <Col>
                    {details?.paid && (
                      <h5 style={{ marginBottom: 0, fontSize: "18px", color: "#32CD32" }}>
                        Paid{" "}
                      </h5>
                    )}
                    {!details?.paid && (
                      <h5 style={{ marginBottom: 0, fontSize: "18px", color: "#FF0000" }}>
                        Not Paid{" "}
                      </h5>
                    )}
                    <p style={{ marginBottom: 0, color: "#535151" }}>Status</p>
                  </Col>
                  <Col>
                    <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                      {details?.invoiceDate} {" "}
                    </h5>
                    <p style={{ marginBottom: 0, color: "#535151" }}>Due Date</p>
                    </Col>
                </Row>
                {/* <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                  {details?.paid ? "Paid" : "Not Paid"}
                </h5> */}
                
              </div>
              <div
                style={{
                  padding: "15px",
                  borderBottom: "1px solid #F3F3F3",
                }}
              >
                <div
                  style={{ display: "flex", gap: "15px", position: "relative" }}
                >
                  <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                    {details?.nuban}{" "}
                  </h5>
                  <img
                    src={copyIcon}
                    alt="copy icon"
                    style={{ cursor: "pointer" }}
                    onClick={handleCopyToClipboard}
                  />
                  {copyMessage && (
                    <span
                      style={{
                        backgroundColor: "#646464",
                        padding: "5px 10px",
                        position: "absolute",
                        top: "-20px",
                        left: "0px",
                        fontSize: "12px",
                        color: "#ffffff",
                        borderRadius: "8px",
                      }}
                    >
                      {copyMessage}
                    </span>
                  )}
                </div>                
                <p style={{ marginBottom: 0, color: "#535151" }}>{details?.bankName}{" "}</p>
                <p style={{ marginBottom: 0, color: "#535151" }}>
                  Account Details
                </p>
              </div>
              {/* <div
                style={{
                  padding: "15px",
                  // borderBottom: "1px solid #F3F3F3",
                }}
              >
                <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                  ₦ {addTrailingCommas(details?.collectedAmount)}
                </h5>
                <p style={{ marginBottom: 0, color: "#535151" }}>Amount Paid</p>
              </div> */}
              <div
                style={{
                  padding: "15px",
                  // borderBottom: "1px solid #F3F3F3",
                }}
              >
                <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                  ₦ {addTrailingCommas(details?.walletBalance)}
                </h5>
                <p style={{ marginBottom: 0, color: "#535151" }}>
                  Wallet Balance
                </p>
              </div>
            </div>
          </div>
        </div>

        <div style={{ flexBasis: "70%" }}>
          <div style={{ marginBottom: "20px" }}>
            <h6 style={{ fontWeight: 500 }}>Note</h6>
            <div
              style={{
                borderRadius: "15px",
                backgroundColor: "white",
                padding: "15px",
              }}
            >
              <p
                style={{
                  marginBottom: 0,
                }}
              >
                Update your invoice with the optional fees by a click on the
                checkbox row in the optional fee table and transfer the sum up
                to the above account number.
              </p>
            </div>
          </div>

          <h6 style={{ fontWeight: 500 }}>Compulsory Fees</h6>

          <Table
            // responsive
            striped
            size="sm"
            style={{
              fontFamily: "jost, sans-serif",
              fontSize: "14px",
            }}
          >
            <thead>
              <tr align="center" style={{ backgroundColor: "white" }}>
                <th style={{ position: "relative" }}>
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      checked={selectAllCompulsory}
                      onChange={handleAllCheckBoxChangeCompulsory}
                      // disable the check box if they have already paid for all the available optional fees
                      disabled={
                        compulsoryFees
                          ?.map((fee) => fee?.paid != true)
                          .includes(true)
                          ? false
                          : true
                      }
                    />
                    <span
                      className={`custom-checkbox ${compulsoryFees
                        ?.map((fee) => fee?.paid != true)
                        .includes(true)
                        ? null
                        : "paid-checkbox"
                        }`}
                    ></span>
                  </label>
                </th>
                {tableHeaders.map((header, i) => (
                  <th key={i}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {compulsoryFees?.map((fee, i) => (
                <tr key={i} className={i % 2 === 0 ? "even-row" : "odd-row"}>
                  <td align="center" style={{ position: "relative" }}>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checked={fee?.selected ? true : fee?.paid}
                        onChange={() => handleOnChangeCompulsory(fee?.id)}
                        disabled={fee?.paid}
                      />
                      <span
                        className={`custom-checkbox ${fee?.paid ? "paid-checkbox" : null
                          }`}
                      ></span>
                    </label>
                  </td>
                  <td align="center">{i + 1}</td>
                  <td align="center">{fee?.feeDescription}</td>
                  <td align="center">{addTrailingCommas(fee?.amount)}</td>
                  {/* <td align="center">{fee?.paid ? "Paid" : "Not Paid"}</td> */}
                  <td align="center">{fee.paid && (<span style={{ color: "#32CD32" }}>Paid</span>)}
                    {!fee.paid && (<span style={{ color: "#FF0000" }}>Not Paid</span>)}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <h6 style={{ textAlign: "right", fontSize: "14px" }}>
            Sub Total:{" "}
            <span style={{ fontWeight: 600 }}>
              ₦ {addTrailingCommas(details?.compulsoryFeeTotal)}
            </span>
          </h6>

          <h6 style={{ fontWeight: 500, color: "#757575" }}>Optional Fees</h6>

          <Table
            // responsive
            striped
            size="sm"
            style={{
              fontFamily: "jost, sans-serif",
              fontSize: "14px",
            }}
          >
            <thead>
              <tr align="center" style={{ backgroundColor: "white" }}>
                <th style={{ position: "relative" }}>
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleAllCheckBoxChange}
                      // disable the check box if they have already paid for all the available optional fees
                      disabled={
                        optionalFees
                          ?.map((fee) => fee?.paid != true)
                          .includes(true)
                          ? false
                          : true
                      }
                    />
                    <span
                      className={`custom-checkbox ${
                        optionalFees
                          ?.map((fee) => fee?.paid != true)
                          .includes(true)
                          ? null
                          : "paid-checkbox"
                      }`}
                    ></span>
                  </label>
                </th>
                {optionalTableHeaders.map((header, i) => (
                  <th key={i}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {optionalFees?.map((fee, i) => (
                <tr key={i} className={i % 2 === 0 ? "even-row" : "odd-row"}>
                  <td align="center" style={{ position: "relative" }}>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checked={fee?.selected}
                        onChange={() => handleOnChange(fee?.id)}
                        disabled={fee?.paid}
                      />
                      <span
                        className={`custom-checkbox ${
                          fee?.paid ? "paid-checkbox" : null
                        }`}
                      ></span>
                    </label>
                  </td>
                  <td align="center">{i + 1}</td>
                  <td align="center">{fee?.feeDescription}</td>
                  <td align="center">{addTrailingCommas(fee?.amount)}</td>
                  {/* <td align="center">{fee?.paid ? "Paid" : "Not Paid"}</td> */}
                  <td align="center">{fee.paid && (<span style={{ color: "#32CD32" }}>Paid</span>)}
                  {!fee.paid && (<span style={{ color: "#FF0000" }}>Not Paid</span>)}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <h6 style={{ textAlign: "right", fontSize: "14px" }}>
            Sub Total:{" "}
            <span style={{ fontWeight: 600 }}>
              ₦ {addTrailingCommas(details?.optionalFeeTotal)}
            </span>
          </h6>

          <h6
            style={{
              textAlign: "right",
              // color: `${details?.brandColour}`,
              marginTop: "50px",
              fontSize: "20px",
            }}
          >
            Total:{" "}
            <span style={{ fontWeight: 600, color: "#000000" }}>
              ₦ {addTrailingCommas(details?.totalAmount)}
            </span>
          </h6>
        </div>
      </section>

      <div
        style={{
          backgroundColor: "#ffffff",
          padding: "20px",
          paddingLeft: "40px",
        }}
      >
        <p style={{ fontSize: "18px", marginBottom: "10px" }}>
          Need help? Contact our support team{" "}
          {/* <span>{details?.customerSupportNumber}</span> */}
        </p>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <p style={{ fontSize: "16px", marginBottom: "0px" }}>Phone: </p>
          <p style={{ fontSize: "16px", marginBottom: "0px" }}>
            {details?.customerSupportNumber}
          </p>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <p style={{ fontSize: "16px", marginBottom: "0px" }}>Email: </p>
          <p style={{ fontSize: "16px", marginBottom: "0px" }}>
            {capitalizeWords(details?.institutionEmail)}
          </p>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <p style={{ fontSize: "16px", marginBottom: "0px" }}>Address: </p>
          <p style={{ fontSize: "16px", marginBottom: "0px" }}>
            {capitalizeWords(details?.institutionAddress)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ParentPage;
