import Button from "../../../../../../../component/button/button";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useApplyCommissionMutation } from "../../../../../../../redux/features/superAdmin/adminApiSlice";
import ToastComponent from "../../../../../../../component/toast/toast";
import SuccessScreen from "../../../../../../../component/successScreen/successScreen";
import OnboardingLoader from "../../../../../../../component/onboardingLoader/onboardingLoader";
import {
  useGetInstitutionSettlementListQuery,
  useGetGlobalCommissionQuery,
} from "../../../../../../../redux/features/superAdmin/adminApiSlice";
import { addTrailingCommas } from "../../../../../../../utils/utils";
import { useEffect } from "react";
import dropdownIcon from "../../../../../../../assets/svgs/dropdownIcon.svg";
import MultiSelectDropdown from "../../../../../../../component/select/multiSelectDropdown";

const CreateCommissionCanvas = () => {
  const [percentageSelected, setPercentageSelected] = useState(false);
  const [amountSelected, setAmountSelected] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsNames, setSelectedItemsNames] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showSchoolsDropdown, setShowSchoolsDropdown] = useState(false);

  const [selectedSchoolOptions, setSelectedSchoolOptions] = useState([]);

  const [rateToShow, setRateToShow] = useState("global");
  const [rateValue, setRateValue] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // console.log(showSchoolsDropdown);

  const { data: schoolsList, isLoading: schoolListLoading } =
    useGetInstitutionSettlementListQuery();
  console.log(schoolsList);

  const schoolOptions = schoolsList?.map((item) => {
    return { id: item?.id, name: item?.institutionName };
  });

  const {
    data: globalCommission,
    refetch: refetchGlobalCommission,
    isLoading: globalLoading,
  } = useGetGlobalCommissionQuery();
  console.log(globalCommission);

  const [
    applyCommission,
    { isLoading: applyCommissionLoading, isSuccess: applyCommissionSuccess },
  ] = useApplyCommissionMutation();

  const handleAmountClick = () => {
    setPercentageSelected(false);
    setAmountSelected(true);
  };

  const handlePercentClick = () => {
    setAmountSelected(false);
    setPercentageSelected(true);
  };

  const handleItemClick = (obj) => {
    if (selectedItems.includes(obj.id)) {
      setSelectedItems(selectedItems.filter((i) => i !== obj.id));
      setSelectedItemsNames(
        selectedItemsNames.filter((i) => i !== obj.institutionName)
      );
    } else {
      setSelectedItems([...selectedItems, obj.id]);
      setSelectedItemsNames([...selectedItemsNames, obj.institutionName]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
      setSelectedItemsNames([]);
    } else {
      const allItemIds = schoolsList?.map((category) => category.id);
      setSelectedItems(allItemIds);
      setSelectedItemsNames(
        schoolsList?.map((category) => category.institutionName)
      );
    }
    setSelectAll(!selectAll);
  };

  const [feeData, setFeeData] = useState({
    selectedSchoolId: [],
  });

  const handleSchoolsOptionsChange = (selected) => {
    setSelectedSchoolOptions(selected);
    console.log(selected, 77777);
    const schoolIds = selected?.map((item) => {
      return item;
    });
    feeData.selectedSchoolId = schoolIds;
    console.log("SELECTED SCHOOLS", feeData);
  };

  const handleOnChange = (e) => {
    setRateValue(e.target.value);
  };

  const handleApplySpecificRate = async () => {
    let keys = {};
    // check if the created rate was fixed or variable as that would determine the keys to be sent to the api
    if (percentageSelected) {
      keys = {
        rate: rateValue,
        type: "RATE",
      };
    } else {
      keys = {
        fixedAmount: rateValue,
        type: "FIXED",
      };
    }

    const body = selectedSchoolOptions?.map((schoolId) => {
      return {
        ...keys,
        institutionId: schoolId,
      };
    });

    console.log("BODY", body);

    try {
      const res = await applyCommission(body).unwrap();
      console.log(res);
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleApplyGlobalRate = async () => {
    const body = selectedSchoolOptions?.map((schoolId) => {
      return {
        name: "GLOBAL",
        institutionId: schoolId,
      };
    });

    console.log(body);

    try {
      const res = await applyCommission(body).unwrap();
      console.log(res);
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  useEffect(() => {
    if (globalCommission) {
      refetchGlobalCommission();
    }
  }, []);

  if (applyCommissionLoading || globalLoading || schoolListLoading) {
    return <OnboardingLoader />;
  }

  if (applyCommissionSuccess) {
    return <SuccessScreen successMessage="Processing fee set successfully" />;
  }

  return (
    <form onSubmit={(e) => e.preventDefault()} className="commission-canvas">
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <p>
        Our commission system simplifies the process, enabling you to set default or specific rates for transactions.
        Choose between a global rate applicable to all institution transactions or set specific rates for individual institution transactions.
      </p>

      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className="formClasslabel">School List</Form.Label>

        <MultiSelectDropdown
          selectedItems={selectedSchoolOptions}
          options={schoolOptions}
          onSelect={handleSchoolsOptionsChange}
        />
      </Form.Group>

      <div style={{ marginTop: "40px", display: "flex", gap: "10px" }}>
        <Button
          borderRadius="20px"
          backgroundColor={`${rateToShow === "global" ? "#ffede8" : "#f4f4f4"}`}
          border={`${rateToShow === "global" ? "#f47146" : "#dbdbdb"}`}
          color="#000000"
          onClick={() => setRateToShow("global")}
        >
          Global Processing Fee
        </Button>
        <Button
          borderRadius="20px"
          backgroundColor={`${rateToShow === "global" ? "#f4f4f4" : "#ffede8"}`}
          border={`${rateToShow === "global" ? "#dbdbdb" : "#f47146"}`}
          color="#000000"
          onClick={() => setRateToShow("specific")}
        >
          Specific Processing Fee
        </Button>
      </div>

      {rateToShow === "global" ? (
        <div style={{ marginTop: "20px" }}>
          <p>
            Set a default commission rate applicable to all institution transactions. Once saved, this rate will be automatically applied to all transactions unless specified otherwise.
          </p>

          <h6 style={{ marginBottom: "20px" }}>
            Global Rate:{" "}
            <span style={{ fontWeight: "400" }}>
              {globalCommission?.type === "FIXED"
                ? `₦ ${addTrailingCommas(globalCommission?.fixedAmount)}`
                : ` ${globalCommission?.rate} %`}
            </span>
          </h6>

          <Button
            onClick={
              selectedSchoolOptions.length === 0
                ? null
                : () => handleApplyGlobalRate()
            }
            backgroundColor={`${selectedSchoolOptions.length === 0
              ? "#FFCAB8"
              : "rgb(241, 72, 16)"
              }`}
            cursor={`${selectedSchoolOptions.length === 0 ? "not-allowed" : "pointer"
              }`}
          >
            Save Rate
          </Button>
        </div>
      ) : (
        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Form.Check
              inline
              name="rate-type"
              onClick={handlePercentClick}
              type="radio"
              id={`inline-radio-1`}
              size={25}
              style={{
                marginRight: "10px",
                borderColor: "#ff0000",
              }}
            />
            <h6 style={{ marginBottom: "0" }}>Percent Rate ( % )</h6>
          </div>

          <p style={{ marginBottom: "15px" }}>
            Specify the commission rate as a percentage of the transaction amount.
          </p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Form.Check
              inline
              name="rate-type"
              onClick={handleAmountClick}
              type="radio"
              id={`inline-radio-2`}
              size={25}
              style={{ marginRight: "10px" }}
            />
            <h6 style={{ marginBottom: "0" }}>Amount Rate ( ₦ )</h6>
          </div>

          <p style={{ marginBottom: "25px" }}>
            Specify the commission rate as a fixed amount in ₦.
          </p>

          {percentageSelected ? (
            <>
              <div
                style={{ display: "flex", gap: "15px", marginBottom: "30px" }}
              >
                <div
                  style={{
                    backgroundColor: "#ededed",
                    width: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#484d45",
                    padding: "5px 10px",
                  }}
                >
                  %
                </div>
                <input
                  type="number"
                  onChange={(e) => handleOnChange(e)}
                  placeholder="Enter rate in percentage e.g 5"
                  style={{ backgroundColor: "#ededed" }}
                />
              </div>
            </>
          ) : null}

          {amountSelected ? (
            <>
              <div
                style={{ display: "flex", gap: "15px", marginBottom: "30px" }}
              >
                <div
                  style={{
                    backgroundColor: "#ededed",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#484d45",
                    padding: "5px 10px",
                  }}
                >
                  ₦
                </div>
                <input
                  type="number"
                  onChange={(e) => handleOnChange(e)}
                  placeholder="Enter rate in amount e.g 5000"
                  style={{ backgroundColor: "#ededed" }}
                />
              </div>
            </>
          ) : null}

          <Button
            onClick={
              selectedSchoolOptions.length === 0 || !rateValue
                ? null
                : () => handleApplySpecificRate()
            }
            backgroundColor={`${selectedSchoolOptions.length === 0 || !rateValue
              ? "#FFCAB8"
              : "rgb(241, 72, 16)"
              }`}
            cursor={`${selectedSchoolOptions.length === 0 || !rateValue
              ? "not-allowed"
              : "pointer"
              }`}
          >
            Save Rate
          </Button>
        </div>
      )}
    </form>
  );
};

export default CreateCommissionCanvas;
