import React from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./institutionReportGroup.css";
import DashboardTopSection from "../dashboardTopSection";
import DashboardSidebar from "../dashboardSideBar/sidebar";


const RemindersHomePage = () => {


    const reportGroupList = [
       
        {
            id: 1,
            title: "Send Notifications",
            //   text: "732 Students",
            style: "branchsidecard-x1",
        },
        {
            id: 2,
            title: "Reminders & Notifications Summary",
            //   text: "732 Students",
            style: "branchsidecard-x1",
        },
    ];

    const navigate = useNavigate();

    //Navigate to Payment History Page
    const handleViewReport = (id) => {
        // console.log(id, 7777788888888)
        if (id == 1) {
            navigate(`/principal/messaging`);
        } else if (id == 2) {
            navigate(`/principal/notificationsummary`);
        }
        
    };

    return (
        <>
            <Container fluid style={{ display: "flex" }} className="dashboardContainer">
                <DashboardSidebar />

                <Container fluid className="bodycontainer">
                    <DashboardTopSection />
                    <div className="reportGroup-page">
                        <section
                            style={{
                                marginTop: "30px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "20px",
                                }}
                            >
                                <div style={{ display: "flex", gap: "5px" }}>
                                    <h5>Reminders & Notifications</h5>
                                </div>
                                {/* <div style={{ display: "flex", gap: "5px" }}>
                                <ButtonX
                                    backgroundColor="#f14810"
                                    border="#f14810"
                                    onClick={() => setShowYearModal(true)}
                                >
                                    Advanced Report
                                </ButtonX>
                            </div> */}
                            </div>

                            <>
                                <Row style={{ marginTop: 0.4 + "rem" }}>
                                    <Col xs={12} md={12} lg={12} className="otherbox">
                                        <Row>
                                            {reportGroupList.map(({ id, title }, i) => (
                                                <Col
                                                    xs={4}
                                                    md={4}
                                                    lg={4}
                                                    id="reportGroupsidecard-x1"
                                                    key={id}
                                                >
                                                    <div
                                                        className="reportGroupsidecard-x1"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleViewReport(id)}
                                                    >
                                                        <div className="card-inner-value">
                                                            <h6
                                                                style={{
                                                                    marginTop: 1.2 + "rem",
                                                                    fontSize: "18px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                <b>{title}</b>
                                                            </h6>
                                                            <p
                                                                className="card-inner-text"
                                                                style={{ marginTop: -0.61 + "rem" }}
                                                            ></p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Row>
                            </>
                        </section>

                        <br />
                        <br />
                    </div>
                </Container>
            </Container>
        </>
    );
};

export default RemindersHomePage;
