import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import "./table.css";
import ActionIcon from "../actionbutton/ActionIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  storeCategoryToManage,
  storeCategoryToDelete,
} from "../../redux/features/institution/institutionSlice";

function TableComponent({
  data,
  title,
  columns,
  selectableRows,
  onRowSelectionChange,
  filter,
  search,
  download,
  viewColumns,
  pagination,
  clearSelectedRows,
}) {
  // const [expandedRows, setExpandedRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const dispatch = useDispatch();

  const options = {
    filter: filter,
    search: search,
    download: download,
    viewColumns: viewColumns,
    pagination: pagination ? pagination : true,
    filterType: "textField",
    responsive: "standard",
    selectableRows: `${selectableRows ? "multiple" : "none"}`,
    // rowsPerPageOptions: !rowsPerPageOptions
    //   ? [10, 50, 100]
    //   : rowsPerPageOptions,
    rowsPerPageOptions: [10, 50, 100],
    print: false,

    onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
      const selectedRowData = allRowsSelected.map(
        (rowInfo) => data[rowInfo.dataIndex]
      );
      setSelectedRows(selectedRowData);
      if (onRowSelectionChange) {
        onRowSelectionChange(selectedRowData);
      }
    },
  };

  // Function to clear selected rows
  const handleClearSelectedRows = () => {
    setSelectedRows([]);
  };

  console.log(clearSelectedRows);
  console.log(selectedRows);

  useEffect(() => {
    if (clearSelectedRows) {
      setSelectedRows([]);
    }
  }, [clearSelectedRows]);

  return (
    <div className="my-table">
      <Paper>
        <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          options={options}
        />
      </Paper>
    </div>
  );
}

export default TableComponent;
