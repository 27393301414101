import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import {
  useGetInstitutionQuery,
  useGetInstitutionLogoQuery,
} from "../../../../redux/features/institution/institutionApiSlice";
import { useSelector, useDispatch } from "react-redux";
import InvoicingBatchList from "./components/invoicingBatchList";
import DashboardSidebar from "../components/dashboardSidebar/sidebar";
import DashboardTopSection from "../components/dashboardTopSection";
import "./institutionInvoicingPage.css"

const InstitutionInvoicingPage = () => {
  const [details, setDetails] = useState({});
  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const { data: institutionDetails } = useGetInstitutionQuery(id);
  const { data: institutionLogo } = useGetInstitutionLogoQuery(id);
  // console.log(institutionLogo?.logo);

  // useEffect(() => {
  //     setDetails(institutionDetails);
  // }, [institutionDetails, details]);

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar institutionDetails={institutionDetails}/>

      <Container fluid className="bodycontainer">
        <DashboardTopSection />
        <InvoicingBatchList institutionDetails={institutionDetails} institutionLogo={institutionLogo}/>
      </Container>
    </Container>
  );
};

export default InstitutionInvoicingPage;
