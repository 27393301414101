import { React, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Image,
  Form,
  Accordion,
  InputGroup,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { TelephonePlus } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
// import { updateContactInfo } from "../../../../redux/features/auth/authSlice";

// import "../../../../styles/formpage.css";
// import "../registrationStepper";
// import "./contactPerson.css";

const ContactPerson = ({ setActiveStage }) => {
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);

  // const formerDetails = useSelector((state) => state.auth.aboutSchoolInfo);
  // console.log(formerDetails);

  // // get previous data if they have filled this page before
  // const aboutContactPerson = useSelector(
  //     (state) => state.auth.contactPersonInfo
  // );

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dates = Array.from({ length: 31 }, (_, index) =>
    (index + 1).toString()
  );

  // const [contactPersonDetails, setContactPersonDetails] = useState({
  //     primaryContact: {
  //         firstName: aboutContactPerson
  //             ? aboutContactPerson?.primaryContact?.firstName
  //             : "",
  //         lastName: aboutContactPerson
  //             ? aboutContactPerson?.primaryContact?.lastName
  //             : "",
  //         dateOfBirth: {
  //             dayOfMonth: aboutContactPerson
  //                 ? aboutContactPerson?.primaryContact?.dateOfBirth?.dayOfMonth
  //                 : "",
  //             month: aboutContactPerson
  //                 ? aboutContactPerson?.primaryContact?.dateOfBirth?.month
  //                 : "",
  //         },
  //         email: aboutContactPerson
  //             ? aboutContactPerson?.primaryContact?.email
  //             : "",
  //         phoneNumber: aboutContactPerson
  //             ? aboutContactPerson?.primaryContact?.phoneNumber
  //             : "",
  //         gender: aboutContactPerson
  //             ? aboutContactPerson?.primaryContact?.gender
  //             : "",
  //     },
  //     secondaryContact: {
  //         firstName: aboutContactPerson
  //             ? aboutContactPerson?.secondaryContact?.firstName
  //             : "",
  //         lastName: aboutContactPerson
  //             ? aboutContactPerson?.secondaryContact?.lastName
  //             : "",
  //         dateOfBirth: {
  //             dayOfMonth: aboutContactPerson
  //                 ? aboutContactPerson?.secondaryContact?.dateOfBirth?.dayOfMonth
  //                 : "",
  //             month: aboutContactPerson
  //                 ? aboutContactPerson?.secondaryContact?.dateOfBirth?.month
  //                 : "",
  //         },
  //         email: aboutContactPerson
  //             ? aboutContactPerson?.secondaryContact?.email
  //             : "",
  //         phoneNumber: aboutContactPerson
  //             ? aboutContactPerson?.secondaryContact?.phoneNumber
  //             : "",
  //         gender: aboutContactPerson
  //             ? aboutContactPerson?.secondaryContact?.gender
  //             : "",
  //     },
  //     technicalContact: {
  //         firstName: aboutContactPerson
  //             ? aboutContactPerson?.technicalContact?.firstName
  //             : "",
  //         lastName: aboutContactPerson
  //             ? aboutContactPerson?.technicalContact?.lastName
  //             : "",
  //         dateOfBirth: {
  //             dayOfMonth: aboutContactPerson
  //                 ? aboutContactPerson?.technicalContact?.dateOfBirth?.dayOfMonth
  //                 : "",
  //             month: aboutContactPerson
  //                 ? aboutContactPerson?.technicalContact?.dateOfBirth?.month
  //                 : "",
  //         },
  //         email: aboutContactPerson
  //             ? aboutContactPerson?.technicalContact?.email
  //             : "",
  //         phoneNumber: aboutContactPerson
  //             ? aboutContactPerson?.technicalContact?.phoneNumber
  //             : "",
  //         gender: aboutContactPerson
  //             ? aboutContactPerson?.technicalContact?.gender
  //             : "",
  //     },
  //     customerSupportNumber: {
  //         phoneNumber: aboutContactPerson
  //             ? aboutContactPerson?.customerSupportNumber?.phoneNumber
  //             : "",
  //     },
  //     // customerSupportNumber: "",
  // });

  // const {
  //     primaryContact,
  //     secondaryContact,
  //     technicalContact,
  //     customerSupportNumber,
  // } = contactPersonDetails;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    // handle the keys that are nested in objects. E.g contactPerson.firstName

    const [objectKey, nestedKey, deepNested] = name.split(".");
    // If we can split the name by "." it means it is nested
    // spread all the previous data in the entire object, then target the particular object that needs to be mutated. spread all of its contents, then mutate the particular key needed

    // if (deepNested) {
    //     setContactPersonDetails((previousData) => ({
    //         ...previousData,
    //         [objectKey]: {
    //             ...previousData[objectKey],
    //             [nestedKey]: {
    //                 ...previousData[objectKey][nestedKey],
    //                 [deepNested]: value,
    //             },
    //         },
    //     }));
    // } else {
    //     setContactPersonDetails((previousData) => ({
    //         ...previousData,
    //         [objectKey]: { ...previousData[objectKey], [nestedKey]: value },
    //     }));
    // }
  };

  // console.log(contactPersonDetails);
  // console.log(customerSupportNumber);

  // const dispatch = useDispatch();

  // check if they filled the necessary fields
  // function isContactValid(contact) {
  //     return (
  //         contact?.firstName &&
  //         contact?.lastName &&
  //         contact?.email &&
  //         contact?.phoneNumber
  //     );
  // }

  const handleGoToNextPage = () => {
    // if (
    //     !isContactValid(primaryContact) ||
    //     !isContactValid(secondaryContact) ||
    //     !customerSupportNumber?.phoneNumber
    // ) {
    //     setIsFormNotFilled(true);

    //     setTimeout(() => {
    //         setIsFormNotFilled(false);
    //     }, 3000);

    //     return;
    // }

    setActiveStage("upload documents");
    // if they didnt fill technical details then dont add it to what is sent
    // if (
    //     !secondaryContact.email ||
    //     !secondaryContact.phoneNumber ||
    //     !technicalContact.email ||
    //     !technicalContact.phoneNumber
    // ) {
    //     dispatch(
    //         updateContactInfo({
    //             primaryContact,
    //             secondaryContact,
    //             customerSupportNumber,
    //             // technicalContact,
    //         })
    //     );
    // } else {
    //     dispatch(updateContactInfo(contactPersonDetails));
    // }
  };

  return (
    <>
      <div>
        <div style={{ display: "flex", gap: 0.8 + "rem" }}>
          <div>
            <svg
              width="55"
              height="55"
              viewBox="0 0 68 69"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M67.5 34.5C67.5 53.1396 52.3896 68.25 33.75 68.25C15.1104 68.25 0 53.1396 0 34.5C0 15.8604 15.1104 0.75 33.75 0.75C52.3896 0.75 67.5 15.8604 67.5 34.5ZM8.4375 34.5C8.4375 48.4797 19.7703 59.8125 33.75 59.8125C47.7297 59.8125 59.0625 48.4797 59.0625 34.5C59.0625 20.5203 47.7297 9.1875 33.75 9.1875C19.7703 9.1875 8.4375 20.5203 8.4375 34.5Z"
                fill="#E7EAEE"
                fill-opacity="0.8"
              />
              <path
                d="M63.2812 34.5C65.6112 34.5 67.5272 36.3961 67.2367 38.7079C66.5853 43.8919 64.7362 48.8743 61.8121 53.2505C58.1036 58.8007 52.8326 63.1265 46.6656 65.6809C40.4986 68.2354 33.7126 68.9038 27.1657 67.6015C20.6188 66.2993 14.6052 63.0849 9.88515 58.3649C5.16512 53.6448 1.95075 47.6312 0.648497 41.0843C-0.653754 34.5374 0.0146082 27.7514 2.56907 21.5844C5.12352 15.4174 9.44935 10.1464 14.9995 6.4379C19.3757 3.51383 24.3581 1.66474 29.5421 1.01332C31.8539 0.722823 33.75 2.6388 33.75 4.96875C33.75 7.2987 31.8484 9.1517 29.5507 9.53824C26.0387 10.1291 22.6746 11.4573 19.6871 13.4534C15.5245 16.2348 12.2801 20.1881 10.3643 24.8133C8.44846 29.4386 7.94718 34.5281 8.92387 39.4382C9.90056 44.3484 12.3113 48.8586 15.8514 52.3986C19.3914 55.9387 23.9016 58.3494 28.8118 59.3261C33.7219 60.3028 38.8114 59.8015 43.4367 57.8857C48.0619 55.9699 52.0152 52.7255 54.7966 48.5629C56.7927 45.5754 58.1209 42.2113 58.7118 38.6993C59.0983 36.4016 60.9513 34.5 63.2812 34.5Z"
                fill="#F14810"
              />
              <path
                d="M21.824 34.862V33.62C22.196 33.62 22.526 33.572 22.814 33.476C23.114 33.38 23.366 33.248 23.57 33.08C23.774 32.9 23.93 32.702 24.038 32.486C24.158 32.258 24.218 32.024 24.218 31.784C24.218 31.484 24.158 31.22 24.038 30.992C23.918 30.764 23.744 30.59 23.516 30.47C23.288 30.35 22.994 30.29 22.634 30.29C22.13 30.29 21.704 30.446 21.356 30.758C21.02 31.058 20.852 31.442 20.852 31.91H18.458C18.458 31.19 18.632 30.554 18.98 30.002C19.34 29.438 19.832 28.994 20.456 28.67C21.08 28.346 21.806 28.184 22.634 28.184C23.51 28.184 24.254 28.34 24.866 28.652C25.49 28.964 25.964 29.378 26.288 29.894C26.612 30.41 26.774 30.986 26.774 31.622C26.774 32.306 26.558 32.894 26.126 33.386C25.694 33.866 25.106 34.232 24.362 34.484C23.618 34.736 22.772 34.862 21.824 34.862ZM22.526 41.252C21.806 41.252 21.164 41.144 20.6 40.928C20.036 40.712 19.556 40.418 19.16 40.046C18.764 39.662 18.458 39.23 18.242 38.75C18.026 38.258 17.918 37.742 17.918 37.202H20.474C20.474 37.61 20.57 37.958 20.762 38.246C20.954 38.534 21.212 38.756 21.536 38.912C21.86 39.056 22.214 39.128 22.598 39.128C22.994 39.128 23.336 39.062 23.624 38.93C23.924 38.786 24.158 38.582 24.326 38.318C24.494 38.054 24.578 37.724 24.578 37.328C24.578 37.04 24.518 36.776 24.398 36.536C24.278 36.284 24.098 36.074 23.858 35.906C23.63 35.738 23.342 35.612 22.994 35.528C22.658 35.432 22.268 35.384 21.824 35.384V34.016C22.592 34.016 23.294 34.082 23.93 34.214C24.578 34.346 25.142 34.562 25.622 34.862C26.102 35.15 26.474 35.522 26.738 35.978C27.002 36.422 27.134 36.962 27.134 37.598C27.134 38.318 26.942 38.954 26.558 39.506C26.186 40.058 25.652 40.49 24.956 40.802C24.272 41.102 23.462 41.252 22.526 41.252ZM35.3786 28.4H37.6286L30.0686 43.7H27.7466L35.3786 28.4ZM38.1884 38.678L45.4064 28.4H47.1524V41H44.5064V37.832L44.7224 37.634V32.504L41.7524 36.752H46.2524L46.5584 36.482H49.1324V38.678H38.1884Z"
                fill="#343434"
              />
            </svg>
          </div>
          <div>
            <h5>Contact Person</h5>
            <p className="FormParagraph">
              Provide the following information about the school representatives
            </p>
          </div>
        </div>
      </div>
      <br />

      {/* {isFormNotFilled ? (
                <span
                    style={{
                        color: "red",
                        fontStyle: "italic",
                        transition: "ease-in-out",
                    }}
                >
                    Please fill all primary and customer care contact fields
                </span>
            ) : null} */}

      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Primary Contact Details <span style={{ color: "red" }}>*</span>
          </Accordion.Header>
          <Accordion.Body>
            {/* {isFormNotFilled ? (
              <span
                style={{
                  color: "red",
                  fontStyle: "italic",
                  transition: "ease-in-out",
                  transitionDuration: "0.3s",
                }}
              >
                Please fill all fields
              </span>
            ) : null} */}
            <Form className="form" style={{ paddingTop: "10px" }}>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      First Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="text"
                      name="primaryContact.firstName"
                      // value={primaryContact.firstName}
                      onChange={handleOnChange}
                      placeholder="Enter First Name"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      Last Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="text"
                      name="primaryContact.lastName"
                      // value={primaryContact.lastName}
                      onChange={handleOnChange}
                      placeholder="Enter Last Name"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Form.Label className="formClasslabel">
                  Date of Birth
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Form.Label>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Select
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      name="primaryContact.dateOfBirth.dayOfMonth"
                      // value={primaryContact?.dateOfBirth?.dayOfMonth}
                      onChange={handleOnChange}
                      // required
                    >
                      <option value="" defaultValue disabled>
                        Select Day
                      </option>
                      {dates.map((date, index) => (
                        <option key={index} value={date}>
                          {date}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Select
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      name="primaryContact.dateOfBirth.month"
                      // value={primaryContact?.dateOfBirth?.month}
                      onChange={handleOnChange}
                      placeholder="Select Month"
                      required
                    >
                      <option value="" defaultValue disabled>
                        Select Month
                      </option>
                      {months.map((month, index) => (
                        <option key={index} value={month}>
                          {month}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Email Address <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type="email"
                  name="primaryContact.email"
                  // value={primaryContact.email}
                  onChange={handleOnChange}
                  placeholder="Enter Email Address"
                  required
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Phone Number <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <InputGroup className="mb-3">
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ backgroundColor: "#F14810", color: "#ffffff" }}
                  >
                    <TelephonePlus size={22} />
                  </InputGroup.Text>
                  <Form.Control
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    type="number"
                    name="primaryContact.phoneNumber"
                    // value={primaryContact.phoneNumber}
                    onChange={handleOnChange}
                    placeholder="Enter Phone number"
                    required
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Gender <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <InputGroup className="mb-3" required>
                  <div key={`inline-radio`}>
                    <Form.Check
                      inline
                      label="Male"
                      name="primaryContact.gender"
                      // checked={primaryContact.gender === "male"}
                      onChange={handleOnChange}
                      value="male"
                      type="radio"
                      id={`inline-radio-1`}
                      size={25}
                      // style={{ backgroundColor: '#F14810', color: '#ffffff' }}
                    />
                    <Form.Check
                      inline
                      label="Female"
                      name="primaryContact.gender"
                      // checked={primaryContact.gender === "female"}
                      onChange={handleOnChange}
                      value="female"
                      type="radio"
                      id={`inline-radio-2`}
                      size={25}
                      style={{ variant: "outline-light" }}
                    />
                  </div>
                </InputGroup>
              </Form.Group>
            </Form>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Customer Care Details <span style={{ color: "red" }}>*</span>
          </Accordion.Header>
          <Accordion.Body>
            <Form className="form" style={{ paddingTop: "10px" }}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Phone Number <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <InputGroup className="mb-3">
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ backgroundColor: "#F14810", color: "#ffffff" }}
                  >
                    <TelephonePlus size={22} />
                  </InputGroup.Text>
                  <Form.Control
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    type="number"
                    name="customerSupportNumber.phoneNumber"
                    // value={customerSupportNumber?.phoneNumber}
                    onChange={handleOnChange}
                    placeholder="Enter Phone number"
                    required
                  />
                </InputGroup>
              </Form.Group>
            </Form>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>
            Accountant Contact Details <span style={{ color: "red" }}>*</span>
          </Accordion.Header>
          <Accordion.Body>
            {/* {isFormNotFilled ? (
              <span
                style={{
                  color: "red",
                  fontStyle: "italic",
                  transition: "ease-in-out",
                  transitionDuration: "0.3s",
                }}
              >
                Please fill all fields
              </span>
            ) : null} */}
            <Form className="form" style={{ paddingTop: "10px" }}>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      First Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="text"
                      name="secondaryContact.firstName"
                      // value={secondaryContact.firstName}
                      onChange={handleOnChange}
                      placeholder="Enter First Name"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      Last Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="text"
                      name="secondaryContact.lastName"
                      // value={secondaryContact.lastName}
                      onChange={handleOnChange}
                      placeholder="Enter Last Name"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Form.Label className="formClasslabel">
                  Date of Birth
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Form.Label>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Select
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      name="secondaryContact.dateOfBirth.dayOfMonth"
                      // value={secondaryContact?.dateOfBirth?.dayOfMonth}
                      onChange={handleOnChange}
                      // required
                    >
                      <option value="" defaultValue disabled>
                        Select Day
                      </option>
                      {dates.map((date, index) => (
                        <option key={index} value={date}>
                          {date}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Select
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      name="secondaryContact.dateOfBirth.month"
                      // value={secondaryContact?.dateOfBirth?.month}
                      onChange={handleOnChange}
                      placeholder="Select Month"
                      required
                    >
                      <option value="" defaultValue disabled>
                        Select Month
                      </option>
                      {months.map((month, index) => (
                        <option key={index} value={month}>
                          {month}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Email Address <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type="email"
                  name="secondaryContact.email"
                  // value={secondaryContact.email}
                  onChange={handleOnChange}
                  placeholder="Enter Email Address"
                  required
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Phone Number <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <InputGroup className="mb-3">
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ backgroundColor: "#F14810", color: "#ffffff" }}
                  >
                    <TelephonePlus size={22} />
                  </InputGroup.Text>
                  <Form.Control
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    type="number"
                    name="secondaryContact.phoneNumber"
                    // value={secondaryContact.phoneNumber}
                    onChange={handleOnChange}
                    placeholder="Enter Phone number"
                    required
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Gender <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <InputGroup className="mb-3" required>
                  <div key={`inline-radio`}>
                    <Form.Check
                      inline
                      label="Male"
                      name="secondaryContact.gender"
                      // checked={secondaryContact.gender === "male"}
                      onChange={handleOnChange}
                      value="male"
                      type="radio"
                      id={`inline-radio-1`}
                      size={25}
                      // style={{ backgroundColor: '#F14810', color: '#ffffff' }}
                    />
                    <Form.Check
                      inline
                      label="Female"
                      name="secondaryContact.gender"
                      // checked={secondaryContact.gender === "female"}
                      onChange={handleOnChange}
                      value="female"
                      type="radio"
                      id={`inline-radio-2`}
                      size={25}
                      style={{ variant: "outline-light" }}
                    />
                  </div>
                </InputGroup>
              </Form.Group>
            </Form>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>Technical Contact Details</Accordion.Header>
          <Accordion.Body>
            {/* {isFormNotFilled ? (
              <span
                style={{
                  color: "red",
                  fontStyle: "italic",
                  transition: "ease-in-out",
                  transitionDuration: "0.3s",
                }}
              >
                Please fill all fields
              </span>
            ) : null} */}
            <Form className="form" style={{ paddingTop: "10px" }}>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      First Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="text"
                      name="technicalContact.firstName"
                      // value={technicalContact.firstName}
                      onChange={handleOnChange}
                      placeholder="Enter First Name"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      Last Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="text"
                      name="technicalContact.lastName"
                      // value={technicalContact.lastName}
                      onChange={handleOnChange}
                      placeholder="Enter Last Name"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Form.Label className="formClasslabel">
                  Date of Birth
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Form.Label>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Select
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      name="technicalContact.dateOfBirth.dayOfMonth"
                      // value={technicalContact?.dateOfBirth?.dayOfMonth}
                      onChange={handleOnChange}
                      // required
                    >
                      <option value="" defaultValue disabled>
                        Select Day
                      </option>
                      {dates.map((date, index) => (
                        <option key={index} value={date}>
                          {date}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Select
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      name="technicalContact.dateOfBirth.month"
                      // value={technicalContact?.dateOfBirth?.month}
                      onChange={handleOnChange}
                      placeholder="Select Month"
                      required
                    >
                      <option value="" defaultValue disabled>
                        Select Month
                      </option>
                      {months.map((month, index) => (
                        <option key={index} value={month}>
                          {month}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Email Address <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type="email"
                  name="technicalContact.email"
                  // value={technicalContact.email}
                  onChange={handleOnChange}
                  placeholder="Enter Email Address"
                  required
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Phone Number <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <InputGroup className="mb-3">
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ backgroundColor: "#F14810", color: "#ffffff" }}
                  >
                    <TelephonePlus size={22} />
                  </InputGroup.Text>
                  <Form.Control
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    type="number"
                    name="technicalContact.phoneNumber"
                    // value={technicalContact.phoneNumber}
                    onChange={handleOnChange}
                    placeholder="Enter Phone number"
                    required
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Gender <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <InputGroup className="mb-3" required>
                  <div key={`inline-radio`}>
                    <Form.Check
                      inline
                      label="Male"
                      name="technicalContact.gender"
                      // checked={technicalContact.gender === "male"}
                      onChange={handleOnChange}
                      value="male"
                      type="radio"
                      id={`inline-radio-1`}
                      size={25}
                      // style={{ backgroundColor: '#F14810', color: '#ffffff' }}
                    />
                    <Form.Check
                      inline
                      label="Female"
                      name="technicalContact.gender"
                      // checked={technicalContact.gender === "female"}
                      onChange={handleOnChange}
                      value="female"
                      type="radio"
                      id={`inline-radio-2`}
                      size={25}
                      style={{ variant: "outline-light" }}
                    />
                  </div>
                </InputGroup>
              </Form.Group>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div style={{ display: "flex", gap: 1.1 + "rem", marginTop: "30px" }}>
        <Button
          variant="outline-primary"
          style={{ borderColor: "#F14810", color: "#F14810" }}
          className="button"
          size="md"
        >
          <span
            style={{
              fontSize: 0.8 + "rem",
              marginLeft: 0.5 + "rem",
              marginRight: 0.5 + "rem",
            }}
            onClick={() => setActiveStage("school branding")}
          >
            Previous
          </span>
        </Button>

        <Button
          variant="primary"
          style={{
            backgroundColor: "#F14810",
            borderColor: "#F14810",
            float: "left",
          }}
          className="button"
          size="md"
          // type="submit"
        >
          <span
            style={{
              fontSize: 0.8 + "rem",
              marginLeft: 0.5 + "rem",
              marginRight: 0.5 + "rem",
            }}
            onClick={handleGoToNextPage}
          >
            Save & Continue
          </span>
        </Button>
      </div>
    </>
  );
};

export default ContactPerson;
