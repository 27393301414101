import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./button.css";

const Button = ({
  children,
  type = "button",
  backgroundColor = "#F14810",
  border = "none",
  color = "white",
  cursor,
  overlay,
  borderRadius,
  overlayText,
  ...props
}) => {
  const generalButtonStyles = {
    backgroundColor: backgroundColor,
    padding: "5px 15px",
    border: border != "none" ? `1px solid ${border}` : "none",
    outline: "none",
    borderRadius: borderRadius ? borderRadius : "5px",
    color: color,
    cursor: cursor ? cursor : "pointer",
    minWidth: "80px",
  };

  return (
    <>
      {overlay ? (
        <OverlayTrigger
          placement="top"
          delay={{ show: 150, hide: 300 }}
          overlay={<Tooltip id="button-tooltip">{overlayText}</Tooltip>}
        >
          <button type={type} style={generalButtonStyles} {...props}>
            {children}
          </button>
        </OverlayTrigger>
      ) : (
        <button type={type} style={generalButtonStyles} {...props}>
          {children}
        </button>
      )}
    </>
  );
};

export default Button;
