import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import DashboardSidebar from "../../dashboardSidebar/sidebar";
import DashboardTopSection from "../../dashboardTopSection/index";

import Button from "../../../../../../component/button/button";
import ActionIcon from "./actionbutton/ActionIcon";
import { useSelector } from "react-redux";
import "../../students/students.css";

import TableComponent from "../../../../../../component/table/table";
import backArrow from "../../../../../../assets/svgs/backArrow.svg";

import {
  useGetInstitutionQuery,
  useGetStudentsByBranchQuery,
} from "../../../../../../redux/features/institution/institutionApiSlice";

import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";

const BranchStudentListPage = () => {
  const { Id } = useParams();

  const navigate = useNavigate();

  const [students, setStudents] = useState([]);

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const { data: institutionDetails, isLoading: detailsLoading } =
    useGetInstitutionQuery(instId);

  const { data: branchStudents, isLoading: studentsLoading } =
    useGetStudentsByBranchQuery(Id, { skip: !Id });
  console.log(students);

  const columns = [
    // { name: "id", label: "ID" },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { firstname, middlename, lastname } =
            students[tableMeta.rowIndex];
          return `${firstname} ${middlename ? middlename : ""} ${lastname}`;
        },
      },
    },
    { name: "gender", label: "Gender" },
    { name: "studentNo", label: "Student No" },
    { name: "nuban", label: "Nuban" },
    { name: "bankName", label: "Bank Name" },
    {
      name: "parent",
      label: "Parent Name",
      options: {
        customBodyRender: (parent) =>
          parent && parent.name ? parent.name : "N/A",
      },
    },
    {
      name: "parent",
      label: "Parent Email",
      options: {
        customBodyRender: (parent) =>
          parent && parent.email ? parent.email : "N/A",
      },
    },
    // {
    //   name: "actions",
    //   label: "Action",
    //   options: {
    //     customBodyRender: (value, tableMeta) => (
    //       <ActionIcon
    //         actionsArr={[
    //           {
    //             label: "Delete",
    //             // action: () => handleDeleteStudent(students[tableMeta.rowIndex]),
    //             action: () => {
    //               setIdOfStudentToDelete(students[tableMeta.rowIndex]);
    //               setShowConfirmDelete(true);
    //             },
    //           },
    //         ]}
    //       />
    //     ),
    //   },
    // },
  ];

  useEffect(() => {
    setStudents(branchStudents?.content);
  }, [branchStudents]);

  if (detailsLoading || studentsLoading) {
    return <OnboardingLoader />;
  }

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar institutionDetails={institutionDetails} />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />
        <>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "40px",
                // marginBottom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                  // marginBottom: "10px",
                  // margin: "20px 0",
                  float: "left",
                }}
                onClick={() => navigate(-1)}
              >
                <img src={backArrow} alt="Go back" />
                <h6 style={{ marginBottom: 0 }}>
                  Branch Student List
                  {/* ( {institutionInfo?.institutionName} ) */}
                </h6>
              </div>

              {/* <div style={{ display: "flex", gap: "5px", float: "right" }}>
                <Button
                // onClick={handleShowUpload}
                >
                  Approve List
                </Button>
              </div> */}
            </div>

            {/* <br /> */}

            <section
              style={{
                marginTop: "30px",
              }}
            >
              <TableComponent
                data={students}
                title=""
                columns={columns}
                pagination={10}
                search={true}
              />
            </section>
          </div>
        </>
      </Container>
    </Container>
  );
};

export default BranchStudentListPage;
