import "./onboardingLoader.css";
import spinner from "../../assets/svgs/spinner.svg";

const OnboardingLoader = () => {
  return (
    <div className="onboardingLoader">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          justifyContent: "center",
        }}
      >
        <center>
          <img src={spinner} alt="spinner" style={{ width: "75px" }} />
        </center>
        <p>Please wait a moment...</p>
      </div>
    </div>
  );
};

export default OnboardingLoader;
