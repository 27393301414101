import React from 'react';
import { Image, Carousel } from 'react-bootstrap';
import FirstCarouselImage from '../assets/images/FirstCarouselImage.svg';
import SecondCarouselImage from '../assets/images/SecondCarouselImage.svg';
import ThirdCarouselImage from '../assets/images/ThirdCarouselImage.svg'

import '../styles/formpage.css';


function SliderItems() {
    return (
        <div className="innerCarouselPage">
            <div>
                <Carousel indicators={true} controls={false} style={{ width: 80 + '%' }}>
                    <Carousel.Item interval={6000}>

                        <div className="textbodyx">
                            <div>
                                <Image src={FirstCarouselImage} fluid />
                            </div>

                            <div className="carouselTextbody">
                                <h4 className="headinglogin">Register & Customize your School</h4>
                                <p className="paragraphlogin"> Multifaceted process that empowers educational institutions to establish their digital presence, customize their institution profile. It plays a vital role in modernizing and streamlining educational payment operations in today's digital age.
                                </p>
                            </div> 
                        </div>

                    </Carousel.Item>
                    <Carousel.Item interval={6000}>

                        <div className="textbodyx">
                            <div>
                                <Image src={SecondCarouselImage} fluid />
                            </div>

                            <div className="carouselTextbody">
                                <h4 className="headinglogin">Payment overview dashboard</h4>
                                <p className="paragraphlogin"> We provide  a comprehensive and insightful view of various financial aspects within the system. The dashboard typically focuses on payment-related information and serves several essential functions.
                                </p>
                            </div>
                        </div>

                    </Carousel.Item>
                    <Carousel.Item interval={6000}>

                        <div className="textbodyx">
                            <div>
                                <Image src={ThirdCarouselImage} fluid />
                            </div>

                            <div className="carouselTextbody">
                                <h4 className="headinglogin">Modification of payment </h4>
                                <p className="paragraphlogin"> Involves altering various aspects of a payment arrangement to accommodate changing circumstances, correct errors, or renegotiate terms. It requires clear communication, negotiation, and proper documentation to ensure that all parties involved are on the same page and that financial obligations are met.
                                </p>
                            </div>
                        </div>

                    </Carousel.Item>
                </Carousel> 
                <div>
                    <a
                        href="/"
                        className="btn btn-link"
                        role="button"
                        data-bs-toggle="button"
                        style={{
                            fontFamily: "jost, sans-serif",
                            fontSize: 1 + "rem",
                            marginTop: 1.5 + "rem",
                            // marginBottom: 1.15 + "rem",
                            paddingLeft: 0.1 + "rem",
                            // paddingRight: 1.2 + "rem",
                        }}
                    >
                        <span style={{ color: "#F14810", fontWeight: "400" }}>Learn More</span>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default SliderItems;
