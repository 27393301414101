import { useState, useEffect } from "react";
import eyeActionIcon from "../../../../assets/svgs/eyeActionIcon.svg";
import DashboardTopSection from "../dashboardTopSection";
import DashboardSidebar from "../dashboardSideBar/sidebar";
import TableComponent from "../../../../component/table/table";
import Button from "../../../../component/button/button";
import { useNavigate } from "react-router-dom";
import { addTrailingCommas } from "../../../../utils/utils";
import { useGetSuperReportSummaryQuery } from "../../../../redux/features/superAdmin/adminApiSlice";
import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";

const ReportingPage = () => {
  const navigate = useNavigate();

  const { data: reports, isLoading } = useGetSuperReportSummaryQuery();

  console.log(reports);

  // const institutionList = [
  //   {
  //     id: 1,
  //     institutionName: "Dprince Academy",
  //     institutionType: "Secondary",
  //     expectedRevenue: 25000,
  //     generatedRevenue: 17000,
  //     outstandingPayment: 8000,
  //     walletBalance: 10000,
  //   },
  //   {
  //     id: 2,
  //     institutionName: "Dprince Academy",
  //     institutionType: "Secondary",
  //     expectedRevenue: 25000,
  //     generatedRevenue: 17000,
  //     outstandingPayment: 8000,
  //     walletBalance: 10000,
  //   },
  //   {
  //     id: 3,
  //     institutionName: "Dprince Academy",
  //     institutionType: "Secondary",
  //     expectedRevenue: 25000,
  //     generatedRevenue: 17000,
  //     outstandingPayment: 8000,
  //     walletBalance: 10000,
  //   },
  // ];

  const columns = [
    // { name: "institutionId", label: "ID" },
    { name: "institutionName", label: "School Name" },
    // { name: "institutionType", label: "Type" },
    {
      name: "expectedRevenue",
      label: "Expected Revenue",
      options: {
        customBodyRender: (amount) => (amount ? addTrailingCommas(amount) : 0),
      },
    },
    {
      name: "generatedRevenue",
      label: "Generated Revenue",
      options: {
        customBodyRender: (amount) => (amount ? addTrailingCommas(amount) : 0),
      },
    },
    {
      name: "outstandingPayment",
      label: "Outstanding Payment",
      options: {
        customBodyRender: (amount) => (amount ? addTrailingCommas(amount) : 0),
      },
    },
    {
      name: "walletBalance",
      label: "Wallet",
      options: {
        customBodyRender: (amount) => (amount ? addTrailingCommas(amount) : 0),
      },
    },
    {
      name: "actions",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <img
            src={eyeActionIcon}
            alt="eye icon"
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`${reports[tableMeta.rowIndex].institutionId}`)
            }
          />
        ),
      },
    },
  ];

  const handleViewAdvanceReport = () => {
    // console.log(id, 7777788888888)
    navigate(`/principal/reporting/advanceReport`);
  };

  if (isLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div className="schools-page no-scrollbar">
      <div>
        <DashboardSidebar />
      </div>

      <div>
        <DashboardTopSection />

        <section
          style={{
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h5>Reporting</h5>
            {/* <div style={{ display: "flex", gap: "5px" }}>
              <Button
                backgroundColor="#f14810"
                border="#f14810"
                onClick={() => handleViewAdvanceReport()}
              >
                Advanced Report
              </Button>
            </div> */}
          </div>
        </section>

        <TableComponent
          data={reports}
          title=""
          columns={columns}
          pagination={10}
        />

        <br />
        <br />
      </div>
    </div>
  );
};

export default ReportingPage;
