// import Button from "../../../../../../component/button/button";
import { Button, Spinner } from "react-bootstrap";
import { Container, Form, InputGroup } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ModalComponent from "../../../../../component/modal/modal";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import successGIF from "../../../../../assets/svgs/successGIF.gif";
import dropdownIcon from "../../../../../assets/svgs/dropdownIcon.svg";
import moneyVector from "../../../../../assets/images/moneySettlementVector.png";
import { Link } from "react-router-dom";
// import InstitutionAccountHistory from "./institutionAccountHistory";

import {
  useGetBalanceEnquiryQuery,
  useAddSettlementAccountMutation,
  useGetInstitutionQuery,
  useGetBanksQuery,
  useAccountNameEnquiryMutation,
  useGetSetupProgressQuery
} from "../../../../../redux/features/institution/institutionApiSlice";

const InstitutionAccountPage = () => {
  const [isHistoryViewed, setIsHistoryViewed] = useState(false);
  const [viewedHistoryDetails, setViewedHistoryDetails] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showBanks, setShowBanks] = useState(false);
  const [selectedBankName, setSelectedBankName] = useState(null);
  const [selectedBankCode, setSelectedBankCode] = useState(null);
  const [accountObject, setAccountObject] = useState(null);
  const [newAllBanks, setNewAllBanks] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredBanks, setFilteredBanks] = useState([]);

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const { data: balanceEnquiry, isLoading: balanceEnquiryLoading } =
    useGetBalanceEnquiryQuery(instId, { skip: !instId });

  // console.log(balanceEnquiry);

  const { data: allBanks, isLoading: banksLoading } = useGetBanksQuery();
  // console.log(allBanks);

  const {
    data: setupProgress,
    // isLoading: progressLoading,
    refetch: refetchProgress,
  } = useGetSetupProgressQuery(instId);

  const {
    data: institutionDetails,
    isLoading: detailsLoading,
    refetch: refetchDetails,
  } = useGetInstitutionQuery(instId);

  console.log(institutionDetails);

  const [addAccount, { isLoading: addAccountLoading }] =
    useAddSettlementAccountMutation();

  const [
    accountNameEnquiry,
    { isLoading: accountNameLoading, isSuccess: accountNameSuccess },
  ] = useAccountNameEnquiryMutation();

  const [settlementDetails, setSettlementDetails] = useState({
    bankCode: "",
    accountName: "",
    accountNumber: "",
  });

  const { accountName, accountNumber, bankCode } = settlementDetails;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setSettlementDetails({ ...settlementDetails, [name]: value });

    if (name === "searchText") {
      setSearchText(value);
    }
  };

  useEffect(() => {
    setAccountObject(null);

    if (accountNumber.length === 10) {
      let params = {
        institutionId: instId,
        body: {
          bankCode: selectedBankCode,
          accountNumber: accountNumber,
        },
      };
      const fetchName = async () => {
        try {
          const res = await accountNameEnquiry(params).unwrap();
          setAccountObject(res);
        } catch (error) {
          console.log(error);
        }
      };
      fetchName();
    }
  }, [accountNumber, selectedBankCode]);

  console.log(selectedBankCode)

  useEffect(() => {
    if (institutionDetails) {
      refetchDetails();
    }
  }, [showSuccessModal]);

  useEffect(() => {
    setNewAllBanks(allBanks || []);
  }, [allBanks]);

  useEffect(() => {
    const filteredBanks = allBanks?.filter((bank) =>
      bank.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredBanks(filteredBanks);
  }, [searchText]);

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowBanks(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleAddAccount = async () => {
    try {
      const params = {
        institutionId: instId,
        body: accountObject,
      };
      const res = await addAccount(params).unwrap();
      console.log(res);
      setShowSuccessModal(true);
      setAccountObject(null);
      refetchProgress()
    } catch (error) {
      alert(error.data.detail);
    }
  };

  if (balanceEnquiryLoading || banksLoading) {
    return <OnboardingLoader />;
  }

  const handleHistoryView = (obj) => {
    setIsHistoryViewed(!isHistoryViewed);
    setViewedHistoryDetails(obj);
  };

  return (
    <>
      <h6 style={{ fontSize: "14px", fontWeight: 600 }}>Settlement Account</h6>
      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "10px",
          padding: "20px",
          height: "90%",
        }}
      >
        <div
          style={{ display: "flex", gap: "30px" }}
          className="account-in-settings"
        >
          <div style={{ flexBasis: "40%" }}>
            <h6>Set your Settlement Account</h6>
            <p style={{ marginBottom: "10px", color: "#6A6A6A" }}>
              Provide the bank account details to receive your settlement{" "}
            </p>

            <p style={{ fontWeight: "500", margin: "5px 0" }}>
              Click the button below to watch how to edit your set-up.
            </p>

            <Link to="/help">
              <span style={{ color: "#F14810", fontWeight: "600" }}>
                Watch Video
              </span>
            </Link>
          </div>

          <div style={{ flexBasis: "60%" }}>
            <Form className="form">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">Bank Name</Form.Label>
                <div ref={dropdownRef} style={{ position: "relative" }}>
                  <div style={{ position: "relative" }}>
                    <label
                      style={{
                        width: "100%",
                        backgroundColor: "#ededed",
                        borderRadius: "5px",
                        padding: "7px 10px",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      onClick={() => setShowBanks(!showBanks)}
                    >
                      {selectedBankName ? selectedBankName : "Select Bank"}
                    </label>
                    <img
                      src={dropdownIcon}
                      alt="dropdown icon"
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "40%",
                      }}
                    />
                  </div>

                  {showBanks && (
                    <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        zIndex: "10",
                        transition: "all",
                        transitionTimingFunction: "ease-in-out",
                        transitionDuration: "0.3s",
                        backgroundColor: "white",
                        maxHeight: "200px",
                        overflow: "auto",
                        width: "100%",
                        border: "1px solid rgb(221, 221, 221)",
                        borderRadius: "5px",
                        padding: "5px 10px",
                      }}
                    >
                      <input
                        type="search"
                        name="searchText"
                        value={searchText}
                        onChange={handleOnChange}
                        placeholder="Search for bank"
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "#ededed",
                          width: "100%",
                          padding: "4px 8px",
                          marginBottom: "10px",
                        }}
                      />

                      {filteredBanks?.map((bank) => (
                        <div
                          style={{ width: "100%", cursor: "pointer" }}
                          onClick={() => {
                            setSelectedBankName(bank?.name);
                            setSelectedBankCode(bank?.code);
                            setShowBanks(false);
                          }}
                        >
                          {bank?.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Form.Group>

              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Account Number
                </Form.Label>
                <InputGroup className="mb-2">
                  <Form.Control
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    type="text"
                    pattern="\d{10}"
                    maxLength="10"
                    name="accountNumber"
                    // value={
                    //   institutionDetails?.settlementAccountNumber
                    //     ? institutionDetails?.settlementAccountNumber
                    //     : accountNumber
                    // }
                    value={accountNumber}
                    onChange={handleOnChange}
                    placeholder="Enter your Account Number (e.g 0123456789)"
                    // disabled={institutionDetails?.settlementAccountNumber}
                    required
                  />
                </InputGroup>
              </Form.Group>

              {accountNameLoading && (
                <Spinner animation="border" variant="danger" color="#F14810" />
              )}

              {accountObject && <p>{accountObject?.accountName}</p>}

              {accountNumber.length >= 10 &&
                accountNameSuccess == false &&
                accountNameLoading == false && (
                  <p style={{ color: "red", fontStyle: "italic" }}>
                    Invalid account number.
                  </p>
                )}
            </Form>

            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginTop: "30px",
              }}
            >
              <Button
                className="button"
                size="md"
                onClick={
                  !accountObject?.accountName || accountNumber.length != 10
                    ? null
                    : () => handleAddAccount()
                }
                style={{
                  backgroundColor: `${
                    !accountObject?.accountName || accountNumber.length != 10
                      ? "#FFCAB8"
                      : "#F14810"
                  }`,
                  borderColor: `${
                    !accountObject?.accountName || accountNumber.length != 10
                      ? "#FFCAB8"
                      : "#F14810"
                  }`,
                  cursor: `${
                    !accountObject?.accountName || accountNumber.length != 10
                      ? "not-allowed"
                      : "pointer"
                  }`,
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.3 + "rem",
                  marginRight: 0.3 + "rem",
                  float: "left",
                }}
                // disabled={addAccountLoading}
              >
                {addAccountLoading ? (
                  <>
                    <span>Loading...</span>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </>
                ) : (
                  <span>Set Account Details</span>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        showModal={showSuccessModal}
        closeModal={handleCloseSuccessModal}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!!</h3>
          <p style={{ fontSize: "18px" }}>
            Settlement account set successfully
          </p>
        </div>
      </ModalComponent>
    </>
  );
};

export default InstitutionAccountPage;
