import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ButtonX from "../../../../../../../component/button/button";
import uploadIcon from "../../../../../../../assets/svgs/uploadIcon.svg";
import alertIcon from "../../../../../../../assets/svgs/alert.svg";

import { useNavigate } from "react-router-dom";
import ModalComponent from "../../../../../../../component/modal/modal";
import { Table, Button, Modal } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import uploadBtn from "../../../../../../../assets/svgs/uploadbtn.svg";
import actionIcon from "../../../../../../../assets/svgs/institutionLevelActionIcon.svg";
import OnboardingLoader from "../../../../../../../component/onboardingLoader/onboardingLoader";
import SuccessScreen from "../../../../../../../component/successScreen/successScreen";
import ToastComponent from "../../../../../../../component/toast/toast";
import ActionIcon from "../../../../../../../component/actionbutton/ActionIcon";

// import {
//   useGetAllDocumentCategoriesQuery,
//   useUploadDocumentMutation,
//   useUpdateUploadDocumentMutation,
//   useDeleteAdminDocumentMutation,
//   useDownloadDocumentQuery,
//   useGetAdminDocumentCategoriesQuery,
//   useGetAdminUploadedDocumentsQuery,
// } from "../../../../../../../redux/features/superAdmin/adminApiSlice";

import {
  useUpdateInstitutionDetailsMutation,
  useGetDocumentCategoriesQuery,
  useGetInstitutionDocumentListQuery,
  useGetInstitutionDocumentQuery,
  useDeleteDocumentMutation,
  useEditDocumentMutation,
  useUploadDocumentMutation,
} from "../../../../../../../redux/features/institution/institutionApiSlice";

const tableHeaders = [
  // "S/N",
  "Document Name",
  "Action",
];

const SchoolDocuments = ({ Id }) => {
  const [showModal, setShowModal] = useState(false);
  const [documentViewed, setDocumentViewed] = useState(null);
  const [docUploaded, setDocUploaded] = useState(null);
  const [docDeleted, setDocDeleted] = useState(null);
  const [documentToDeleteId, setDocumentToDeleteId] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [clickedDoc, setClickedDoc] = useState(null);

  const [deleteDocument, { isLoading: deleteLoading }] =
    useDeleteDocumentMutation();

  const [uploadDocument, { isLoading: docUploadLoading }] =
    useUploadDocumentMutation();

  const [editDocument, { isLoading: editLoading }] = useEditDocumentMutation();

  const {
    data: documentCategories,
    isLoading: documentCategoriesLoading,
    refetch: refetchDocCategories,
  } = useGetDocumentCategoriesQuery(Id);

  const querys = {
    institutionId: Id,
    documentId: clickedDoc?.id,
  };

  console.log(clickedDoc, "CLickDOC");

  const {
    data: uploadedDocuments,
    isLoading: uploadedDocumentsLoading,
    refetch: refetchUploadedDocs,
  } = useGetInstitutionDocumentListQuery(Id);

  console.log(uploadedDocuments, "UploadedDOcuments");

  const {
    data: downloadDocuments,
    isLoading: downloadDocumentsLoading,
    refetch: refetchDownloadDocs,
  } = useGetInstitutionDocumentQuery(querys, {skip: !clickedDoc});

  console.log(documentCategories);

  const [newCredentialInfo, setNewCredentialInfo] = useState({
    documentCategory: "",
    documentCategoryId: "",
    content: "",
    description: "",
  });

  const { documentCategory, content, description, documentCategoryId } =
    newCredentialInfo;

  const handleOnChange = (e, id) => {
    const { name, value, type } = e.target;

    console.log(id);

    setNewCredentialInfo({ ...newCredentialInfo, [name]: value });

    if (type === "file") {
      let selectedFile = e.target.files;

      if (selectedFile.length > 0) {
        let actualFile = selectedFile[0];

        // check if its a pdf
        if (
          actualFile.type === "application/pdf" ||
          actualFile.type === "image/jpeg"
        ) {
          let fileName = actualFile.name;
          const reader = new FileReader();

          reader.onload = (event) => {
            const docDetails = {
              name: fileName,
              docUrl: event.target.result,
            };
            // find the credential on which the upload icon was clicked
            const credential = documentCategories?.find((doc) => doc.id === id);
            submitCredential(credential, docDetails);
          };
          reader.readAsDataURL(actualFile);
        } else {
          setShowToast(true);
          setToastMessage("Upload a pdf or jpeg file");
        }
      }
    }
  };

  const submitCredential = async (credential, docDetails) => {
    const { id, ...rest } = credential;

    const params = {
      institutionId: Id,
      body: {
        ...rest,
        documentCategoryId: id,
        description: credential?.name,
        content: docDetails?.docUrl,
      },
    };

    try {
      const res = await uploadDocument(params).unwrap();
      console.log(res);
      setDocUploaded(res);
      setShowToast(true);
      setToastMessage("Document Uploaded Successfully!");
      refetchDocCategories();
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        setToastMessage(error.error);
      }
    }
  };

  const confirmDelete = (id) => {
    setDocumentToDeleteId(id);
    setShowConfirmDelete(true);
  };

  const handleViewCredential = (id) => {
    const doc = uploadedDocuments?.find(
      // (doc) => doc.documentCategory.id === id
      (document) => document?.documentCategory?.id === id
    );
    console.log(doc, "DOC");
    setClickedDoc(id);
    setDocumentViewed(doc.content);
    setShowPdfModal(true);
  };

  const handleDeleteCredential = async () => {
    console.log("delete was clicked for ", documentToDeleteId);
    setShowConfirmDelete(false);

    try {
      const parameters = {
        institutionId: Id,
        documentId: uploadedDocuments?.find(
          (doc) => doc.documentCategory.id === documentToDeleteId
        )?.id,
      };
      await deleteDocument(parameters).unwrap();
      setDocDeleted(documentToDeleteId);
      setShowToast(true);
      setToastMessage("Document Deleted");
      refetchDocCategories();
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  useEffect(() => {
    if (uploadedDocuments) {
      refetchUploadedDocs();
    }
  }, [docUploaded, docDeleted]);

  if (
    deleteLoading ||
    editLoading ||
    documentCategoriesLoading ||
    uploadedDocumentsLoading ||
    docUploadLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <div className="upload-credentials no-scrollbar">
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      {/* delete confirmation modal */}
      <ModalComponent
        showModal={showConfirmDelete}
        closeModal={() => setShowConfirmDelete(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img src={alertIcon} alt="alert Icon" style={{ margin: "0 auto" }} />
          <p
            style={{
              marginTop: "10px",
              marginBottom: "5px",
              fontWeight: "500",
              fontSize: 1.0 + "rem",
            }}
          >
            Are you sure you want to delete this document?{" "}
          </p>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              gap: "10px",
              marginLeft: "30%",
            }}
          >
            <Button
              onClick={() => setShowConfirmDelete(false)}
              type="button"
              variant="outline-primary"
              style={{ borderColor: "#F14810", color: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                No
              </span>
            </Button>
            <Button
              onClick={() => handleDeleteCredential()}
              type="button"
              variant="primary"
              style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Yes
              </span>
            </Button>
          </div>
        </div>
      </ModalComponent>

      {documentViewed && (
        <Modal
          centered
          show={showPdfModal}
          onHide={() => setShowPdfModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Document Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "80vh" }}>
            <iframe
              width="100%"
              height="100%"
              src={`${documentViewed}`}
              title="PDF Viewer"
            ></iframe>
          </Modal.Body>
        </Modal>
      )}

      <div
        style={{
          borderTop: "1px solid #EDEDED",
          paddingTop: "10px",
        }}
      >
        <div
          className="school-setup"
          style={{
            border: "1px solid #DBDBDB",
            borderRadius: 8 + "px",
            marginTop: 2 + "rem",
            marginBottom: 2 + "rem",
            padding: 4 + "px",
            paddingLeft: 1.5 + "rem",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 10,
            }}
          >
            <img src={alertIcon} style={{ width: "12%" }} />
            <p
              style={{
                fontSize: 0.7 + "rem",
                marginTop: 10 + "px",
              }}
            >
              Click the upload icon in the action colum to upload the listed
              document only in PDF or JPEG
            </p>
          </div>
        </div>

        <Table
          striped
          size="sm"
          style={{
            fontFamily: "jost, sans-serif",
            fontSize: "14px",
            overflow: "auto",
          }}
        >
          <thead>
            <tr align="center">
              {tableHeaders &&
                tableHeaders.length > 0 &&
                tableHeaders.map((header, i) => <th key={i}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {documentCategories?.map(({ name, id, uploaded }, i) => (
              <tr key={i} className={i % 2 === 0 ? "even-row" : "odd-row"}>
                <td align="center">{name}</td>

                <td align="center">
                  {uploaded ? (
                    <ActionIcon
                      actionsArr={[
                        {
                          label: "View",
                          action: () => handleViewCredential(id),
                        },
                        {
                          label: "Delete",
                          action: () => confirmDelete(id),
                        },
                      ]}
                    />
                  ) : (
                    <label
                      htmlFor={`uploadIcon-${id}`}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={uploadIcon} alt="upload icon" />
                      <input
                        type="file"
                        id={`uploadIcon-${id}`}
                        style={{ display: "none" }}
                        onChange={(e) => handleOnChange(e, id)}
                      />
                    </label>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default SchoolDocuments;
