import { useState, useEffect } from "react";
import alertIcon from "../../../../../assets/svgs/alertIcon.svg";
import { Table, Button, Form, Row, Col } from "react-bootstrap";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";

import ModalComponent from "../../../../../component/modal/modal";
import { useSelector } from "react-redux";
import {
  useGetInstitutionYearQuery,
  useCreateInstitutionYearMutation,
  useCreateInstitutionPeriodMutation,
  useGetInstitutionPeriodsQuery,
  useDeleteInstitutionPeriodMutation,
  useUpdateInstitutionPeriodMutation,
  useGetDashboardReportQuery,
  // useGetInstitutionYearQuery
} from "../../../../../redux/features/institution/institutionApiSlice";
import ToastComponent from "../../../../../component/toast/toast";
import ExpandableCalendarTable from "../../../../../component/table/expandableCalendarTable";
import { returnCalendars } from "../../../../../utils/utils";

const tableCalendarHeaders = [
  "Period",
  "Start Date",
  "End Date",
  "Status",
  "Action",
];

const InstitutionCalendar = () => {
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showPeriods, setShowPeriods] = useState(false);
  const [showCalendars, setShowCalendars] = useState(false);
  const [showYearModal, setShowYearModal] = useState(false);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [editedToggled, setEditedToggle] = useState(false);
  const [newYearData, setNewYearData] = useState(null);
  const [yearId, setYearId] = useState(null);
  const [newPeriod, setNewPeriod] = useState(null);
  const [deletedElementID, setDeletedElementID] = useState(null);
  const [editedElement, setEditedElement] = useState(null);
  const [editingElement, setEditingElement] = useState(null);
  const [editingValue, setEditingValue] = useState({});
  const [elementToDelete, setElementToDelete] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  const handleEditToggle = () => {
    setEditedToggle((prevState) => !prevState);
  };

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const {
    data: institutionYears,
    refetch: refetchYears,
    isLoading: yearsLoading,
  } = useGetInstitutionYearQuery(instId);

  // console.log(institutionYears);

  useEffect(() => {
    if (institutionYears) {
      setYearId(institutionYears[0]?.id);
    }
  }, [institutionYears]);

  console.log(institutionYears);
  console.log(yearId);

  const parameters = {
    institutionId: instId,
    // schoolYearId: yearId,
  };

  const {
    data: institutionPeriods,
    refetch: refetchPeriods,
    isLoading: periodsLoading,
  } = useGetInstitutionPeriodsQuery(parameters);

  const {
    data: dashboardRec,
    isLoading: dashboardDataLoading,
    refetch: refetchDashboardDetails,
  } = useGetDashboardReportQuery(instId);

  // console.log(newYearData);
  // console.log(institutionPeriods);

  // const {data: years} = useGetInstitutionYearQuery(instId, {skip: !institutionPeriods})

  // console.log(years[0])

  const [
    createInstitutionYear,
    { isLoading: addYearLoading, isSuccess: addYearSuccess },
  ] = useCreateInstitutionYearMutation();
  const [createInstitutionPeriod, { isLoading: addPeriodLoading }] =
    useCreateInstitutionPeriodMutation();
  const [updateInstitutionPeriod, { isLoading: updatePeriodLoading }] =
    useUpdateInstitutionPeriodMutation();
  const [deleteInstitutionPeriod, { isLoading: deletePeriodLoading }] =
    useDeleteInstitutionPeriodMutation();

  const handleEditPeriod = (el) => {
    console.log("edit was clicked for ", el);
    setEditingElement(el);
    setShowCalendarModal(true);
    setEditingValue({
      editedPeriodName: el.periodName,
      editedStartDate: el.startDate,
      editedEndDate: el.endDate,
    });
    setEditedToggle(el.open);
  };

  const confirmDelete = (el) => {
    setElementToDelete(el);
    setShowConfirmDelete(true);
  };

  const handleDeletePeriod = async () => {
    console.log("delete was clicked for ", elementToDelete);
    setShowConfirmDelete(false);

    try {
      const parameters = {
        periodId: elementToDelete?.id,
        body: { name: elementToDelete.name },
      };
      await deleteInstitutionPeriod(parameters).unwrap();
      setDeletedElementID(elementToDelete.id);
    } catch (error) {
      console.log(error);
      // alert(error.data);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleCloseYearModal = () => setShowYearModal(false);

  const [yearName, setYearName] = useState({
    name: "",
  });

  // const { name: nameOfyear } = yearName;
  const { name } = yearName;

  const handleYearOnChange = (e) => {
    const { name, value } = e.target;
    setYearName({ ...yearName, [name]: value });
  };

  const handleCreateYear = async (e) => {
    e.preventDefault();

    if (name === "") {
      setIsFormNotFilled(true);

      setTimeout(() => {
        setIsFormNotFilled(false);
      }, 3000);

      return;
    }

    setShowYearModal(false);

    const parameters = {
      institutionId: instId,
      body: yearName,
    };
    // submit with institutionId also
    const data = await createInstitutionYear(parameters).unwrap();

    if (data) {
      console.log(data);
      setNewYearData(data);
      setYearId(data.id);
      refetchYears();
    }
  };

  console.log(newYearData);
  console.log(yearId);

  const handleCloseCalendarModal = () => {
    setShowCalendarModal(false);
    setEditingElement(null);
    setEditingValue({
      editedPeriodName: "",
      editedStartDate: "",
      editedEndDate: "",
    });
  };

  // console.log(institutionPeriods);

  // const [addInstitutionPeriod] = useAddInstitutionPeriodMutation();

  const [newPeriodData, setNewPeriodData] = useState({
    periodName: "",
    startDate: "",
    endDate: "",
  });

  const { startDate, endDate, periodName } = newPeriodData;

  const { editedPeriodName, editedEndDate, editedStartDate } = editingValue;

  const handleCalendarOnChange = (e) => {
    const { name, value } = e.target;
    setNewPeriodData({ ...newPeriodData, [name]: value });
    setEditingValue({ ...editingValue, [name]: value });
  };

  const [calendars, setCalendars] = useState([]);
  const [schoolYearName, setSchoolYearName] = useState("");
  const [numberOfcalendars, setNumberOfCalendars] = useState("");

  const handleCategoriesChange = (e, i) => {
    const updatedArr = [...calendars];
    updatedArr[i] = { id: i + 1, [name]: e.target.value };
    setCalendars(updatedArr);
  };

  useEffect(() => {
    setCalendars(returnCalendars(numberOfcalendars));
  }, [numberOfcalendars]);

  useEffect(() => {
    if (institutionPeriods) {
      refetchPeriods();
      console.log("Refetching");
    }
  }, [newPeriod]);

  console.log(institutionPeriods);
  console.log(newPeriod);

  const handleCalendarChange = (e, id) => {
    const { name, value } = e.target;
    setCalendars(
      calendars?.map((calendar) => {
        if (calendar.id === id) {
          return { ...calendar, [name]: value };
        }
        return calendar;
      })
    );
  };

  const handleCreatePeriod = async () => {
    try {
      const parameters = {
        institutionId: instId,
        body: {
          schoolYearName: schoolYearName,
          calendars: calendars?.map((calendar) => {
            const { id, ...rest } = calendar;
            return { ...rest };
          }),
        },
      };
      const res = await createInstitutionPeriod(parameters).unwrap();

      if (res) {
        console.log(res);
        setNewPeriod(res);
        // refetchPeriods();
        setShowCalendarModal(false);
        setIsToggled(false);
        setShowToast(true);
        setToastMessage("Calendar has been created");
      }

      if (dashboardRec) refetchDashboardDetails();
      // if (institutionPeriods) {
      //   refetchPeriods();
      // }
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  useEffect(() => {
    if (newYearData) {
      refetchYears();
    }
    if (institutionPeriods) {
      refetchPeriods();
    }
  }, [addYearSuccess, newYearData, editedElement, newPeriod, deletedElementID]);

  console.log(calendars);
  // console.log(institutionYears);
  // console.log(addYearSuccess);

  if (
    yearsLoading ||
    periodsLoading ||
    addYearLoading ||
    addPeriodLoading ||
    updatePeriodLoading ||
    deletePeriodLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          marginBottom: "10px",
        }}
      >
        <h6 style={{ fontSize: "14px", fontWeight: 600, marginBottom: 0 }}>
          Calendar Management
        </h6>
        <Button
          className="button"
          size="md"
          style={{
            backgroundColor: "#F14810",
            color: "#ffffff",
            border: "none",
            outline: "none",
          }}
          onClick={() => setShowCalendarModal(true)}
          title="Click this button to create a calendar"
        >
          <span
            style={{
              fontSize: 0.8 + "rem",
              marginLeft: 0.3 + "rem",
              marginRight: 0.3 + "rem",
            }}
          >
            Create Calendar
          </span>
        </Button>
      </div>

      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "8px",
          padding: "15px",
          minHeight: "95%",
        }}
      >
        {/* delete confirmation modal */}
        <ModalComponent
          showModal={showConfirmDelete}
          closeModal={() => setShowConfirmDelete(false)}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={alertIcon}
              alt="alert Icon"
              style={{ margin: "0 auto" }}
            />
            <p
              style={{
                marginTop: "10px",
                marginBottom: "5px",
                fontWeight: "500",
                fontSize: 1.0 + "rem",
              }}
            >
              Are you sure you want to delete this calendar?{" "}
            </p>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                gap: "10px",
                marginLeft: "30%",
              }}
            >
              <Button
                onClick={() => setShowConfirmDelete(false)}
                type="button"
                variant="outline-primary"
                style={{ borderColor: "#F14810", color: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  No
                </span>
              </Button>
              <Button
                onClick={() => handleDeletePeriod()}
                type="button"
                variant="primary"
                style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Yes
                </span>
              </Button>
            </div>
          </div>
        </ModalComponent>

        {/* create year modal */}
        <ModalComponent
          title="Create Year"
          showModal={showYearModal}
          closeModal={handleCloseYearModal}
        >
          <Form onSubmit={handleCreateYear}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                Password{" "}
                <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
              </Form.Label>

              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                type="text"
                name="name"
                value={name}
                onChange={handleYearOnChange}
                placeholder="Enter Year Name (e.g 2021/2022 Academic Year/Session)"
              />
            </Form.Group>

            <div style={{ marginTop: "30px" }}>
              <Button
                type="submit"
                variant="primary"
                style={{
                  backgroundColor: "#F14810",
                  borderColor: "#F14810",
                  float: "left",
                }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Save Year
                </span>
              </Button>
            </div>
          </Form>
        </ModalComponent>

        <ModalComponent
          title="Create Calendar"
          showModal={showCalendarModal}
          closeModal={handleCloseCalendarModal}
        >
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                School Year{" "}
                <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
              </Form.Label>

              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                type="text"
                value={schoolYearName}
                onChange={(e) => setSchoolYearName(e.target.value)}
                placeholder="Enter School Year"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                No of Session/Term{" "}
                <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
              </Form.Label>

              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                type="number"
                value={numberOfcalendars}
                onChange={(e) => setNumberOfCalendars(e.target.value)}
                placeholder="Enter Session/Term Name"
              />
            </Form.Group>

            {calendars?.map(({ id, periodName, startDate, endDate, open }) => (
              <div
                key={id}
                style={{
                  padding: "10px",
                  border: "1px solid #bdbdbd",
                  borderRadius: "8px",
                  marginBottom: "15px",
                }}
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">
                    Session/Term Name{" "}
                    <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                      *
                    </span>
                  </Form.Label>

                  <Form.Control
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    type="text"
                    name="periodName"
                    value={periodName}
                    onChange={(e) => handleCalendarChange(e, id)}
                    placeholder="Enter Session/Term Name"
                  />
                </Form.Group>

                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="formClasslabel">
                        Start Date{" "}
                        <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                          *
                        </span>
                      </Form.Label>

                      <Form.Control
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        type="date"
                        name="startDate"
                        value={startDate}
                        onChange={(e) => handleCalendarChange(e, id)}
                        placeholder="Enter Start Date"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="formClasslabel">
                        End Date{" "}
                        <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                          *
                        </span>
                      </Form.Label>

                      <Form.Control
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        type="date"
                        name="endDate"
                        value={endDate}
                        onChange={(e) => handleCalendarChange(e, id)}
                        // onChange={(e) => handleHolidayChange(e, id)}
                        placeholder="Enter End Date"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div
                  className="calender-switch-container"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <label className="switch">
                    <input
                      type="checkbox"
                      // checked={isToggled}
                      checked={open}
                      onChange={() =>
                        setCalendars(
                          calendars?.map((calendar) => {
                            if (id === calendar.id) {
                              return { ...calendar, open: !calendar.open };
                            }
                            return calendar;
                          })
                        )
                      }
                      className="toggle"
                    />
                    <span className="slider round"></span>
                  </label>
                  <label className="toggle-label">Set Calendar to Active</label>
                </div>
              </div>
            ))}

            <div style={{ marginTop: "30px" }}>
              <Button
                // onClick={(e) => handleCreateOrUpdatePeriod(e, "")}
                onClick={handleCreatePeriod}
                variant="primary"
                style={{
                  backgroundColor: "#F14810",
                  borderColor: "#F14810",
                  float: "left",
                }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Save Calendar
                </span>
              </Button>
            </div>
          </Form>
        </ModalComponent>

        {institutionPeriods?.length > 0 ? (
          <ExpandableCalendarTable data={institutionPeriods} />
        ) : (
          <span style={{ fontStyle: "italic", marginBottom: "20px" }}>
            Nothing to show, create a calendar
          </span>
        )}

        <br />
      </div>
    </>
  );
};

export default InstitutionCalendar;
