import "./dashboard.css";
import totalRequestsIcon from "../../../../assets/svgs/pendingRequestsIcon.svg";
import totalIncomeIcon from "../../../../assets/svgs/totalIncomeIcon.svg";
import totalRevenueIcon from "../../../../assets/svgs/totalRevenueIcon.svg";
import totalGeneratedVector from "../../../../assets/svgs/totalGeneratedVector.svg";
import totalExpectedVector from "../../../../assets/svgs/totalExpectedVector.svg";
import totalOutstandingVector from "../../../../assets/svgs/totalOutstandingVector.svg";
import totalOutstandingIcon from "../../../../assets/svgs/totalOutstandingIcon.svg";
import totalSchoolsIcon from "../../../../assets/svgs/totalSchoolsIcon.svg";
import eyeActionIcon from "../../../../assets/svgs/eyeActionIcon.svg";
import calenderIcon from "../../../../assets/svgs/calenderIcon.svg";
import clockIcon from "../../../../assets/svgs/clockIcon.svg";
import walletAndCashIcon from "../../../../assets/svgs/walletAndCashIcon.svg";
import { Table } from "react-bootstrap";
import userDisplayPhoto from "../../../../assets/images/userDisplayPhoto.png";
import { useState, useEffect } from "react";
import ViewInstitution from "../applications/components/viewInstitution/viewInstitution";
import {
  useGetInstitutionListQuery,
  useGetDashboardDetailsQuery,
} from "../../../../redux/features/superAdmin/adminApiSlice";
import TableComponent from "../../../../component/table/table";
import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";
import { addTrailingCommas } from "../../../../utils/utils";
import { logOut } from "../../../../redux/features/auth/authSlice";
import { useDispatch } from "react-redux";
import Chart from "./chart";
import { storeAdminDashboardData } from "../../../../redux/features/superAdmin/adminSlice";
import { useNavigate, Link } from "react-router-dom";

const SuperAdminDashBoardPage = () => {
  const [isInstitionViewed, setIsInstitutionViewed] = useState(false);
  const [viewedInstitutionDetails, setViewedInstitutionDetails] =
    useState(null);
  const [institutionList, setInstitutionList] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get only pending applications
  // const {
  //   data: institutions,
  //   refetch,
  //   isLoading,
  //   error: institutionListError,
  // } = useGetInstitutionListQuery("SUBMITTED");

  const {
    data: dashboardDetails,
    refetch: refetchDetails,
    isLoading: detailsLoading,
    error: dashboardDetailsError,
  } = useGetDashboardDetailsQuery();

  console.log(dashboardDetails);

  dispatch(storeAdminDashboardData(dashboardDetails));

  // useEffect(() => {
  //   if (institutions) {
  //     refetch();
  //     refetchDetails();
  //     setInstitutionList(institutions);
  //   }
  // }, [institutions, isInstitionViewed]);

  const otherDetails = [
    {
      id: 1,
      title: "Today's Collections",
      value: dashboardDetails ? addTrailingCommas(dashboardDetails?.todaysCollection) : 0,
      icon: totalIncomeIcon,
      isMoney: true,
    },
    {
      id: 2,
      title: "No of Approved Schools",
      value: dashboardDetails?.noOfInstitutions,
      icon: totalSchoolsIcon,
      isMoney: false,
    },
    {
      id: 3,
      title: "No of Recent Applications",
      value: dashboardDetails?.pendingApplications,
      icon: totalRequestsIcon,
      isMoney: false,
    },
  ];

  //Navigate to the clicked Page
  const handleNavigate = (id) => {
    if (id == 1) {
      navigate(`/principal/reporting`);
    } else if (id == 2) {
      navigate(`/principal/schools`);
    } else {
      navigate(`/principal/applications/submitted`);
    }
  };



  const revenueBreakdown = [
    {
      icon: totalIncomeIcon,
      // title: "Total Expected Revenue",
      title: "Total Pending Payments",
      value: dashboardDetails
        ? addTrailingCommas(
          dashboardDetails?.totalPendingPayments
        )
        : 0,
      vector: totalExpectedVector,
    },
    {
      icon: totalRevenueIcon,
      // title: "Total Generated Revenue",
      title: "Previous Amount Settled",
      value: dashboardDetails
        ? addTrailingCommas(
          dashboardDetails?.previousAmountSettled
        )
        : 0,
      vector: totalGeneratedVector,
    },
    {
      icon: totalOutstandingIcon,
      // title: "Total Outstanding Payment",
      title: "Total Overdue",
      value: dashboardDetails
        ? addTrailingCommas(
          dashboardDetails?.totalOverdue
        )
        : 0,
      vector: totalOutstandingVector,
    },
  ];

  const columns = [
    // { name: "id", label: "ID" },
    { name: "institutionName", label: "School Name" },
    { name: "noOfPayments", label: "No. of Payments" },
    {
      name: "totalCollected",
      label: "Total Collected",
      options: {
        customBodyRender: (totalCollected) =>
          totalCollected ? `₦ ` + addTrailingCommas(totalCollected) : 0,
      },
    },
    // {
    //   name: "pendingCollection",
    //   label: "Pending Collection",
    //   options: {
    //     customBodyRender: (pendingCollection) =>
    //       pendingCollection ? `₦ ` + addTrailingCommas(pendingCollection) : 0,
    //   },
    // },
    // {
    //   name: "overdueCollection",
    //   label: "Overdue Collection",
    //   options: {
    //     customBodyRender: (overdueCollection) =>
    //       overdueCollection ? `₦ ` + addTrailingCommas(overdueCollection) : 0,
    //   },
    // },
    // {
    //   name: "commission",
    //   label: "Commission",
    //   options: {
    //     customBodyRender: (commission) =>
    //       commission ? commission + ` %` : 0,
    //   },
    // },
    // {
    //   name: "totalSettled",
    //   label: "Total Settled",
    //   options: {
    //     customBodyRender: (totalSettled) =>
    //       totalSettled ? `₦ ` + addTrailingCommas(totalSettled) : 0,
    //   },
    // },
  ];

  const handleInstitutionView = (obj) => {
    setIsInstitutionViewed(!isInstitionViewed);
    setViewedInstitutionDetails(obj);
  };

  if (
    dashboardDetailsError?.status === 401
    // ||
    // institutionListError?.status === 401
  ) {
    console.log("Errrrrrror",
      dashboardDetailsError,
      // institutionListError
    );
    dispatch(logOut());
  }

  if (
    // isLoading
    // ||
    detailsLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <div className="super-admin-dashboard no-scrollbar">
      {/* {isInstitionViewed ? (
        <ViewInstitution
          institutionDetails={viewedInstitutionDetails}
          setIsInstitutionViewed={setIsInstitutionViewed}
        />
      ) : ( */}
      <>
        <div className="overview no-scrollbar">
          {otherDetails.map(({ id, icon, value, title, isMoney }, i) => (
            <div
              className={`overview-box ${i === 0 ? "main" : null}`}
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigate(id)}
              key={i}
            >
              <img src={icon} alt="icon" />
              <div>
                {i === 0 && (
                  <span style={{ color: "#dbd6d4" }}>Today's Collections</span>
                )}
                {isMoney ? (
                  <h3>₦ {addTrailingCommas(value)}</h3>
                ) : (
                  // <h3>{addTrailingCommas(value)}</h3>
                  <h3>{value}</h3>
                )}
                {i === 0 ? (
                  <button
                    style={{
                      backgroundColor: "#605F5F",
                      color: "#dbd6d4",
                      marginTop: "10px",
                      border: "none",
                      outline: "none",
                      borderRadius: "5px",
                      fontSize: "14px",
                      padding: "7px 15px",
                      cursor: "pointer",
                    }}
                  >
                    View Details
                  </button>
                ) : (
                  <p>{title}</p>
                )}
                {/* {i === 0 ? null : (
                    <p>{title}</p>
                  )}{" "} */}
              </div>
              {i === 0 && (
                <img
                  src={walletAndCashIcon}
                  alt="wallet/cash icon"
                  style={{
                    marginLeft: "auto",
                  }}
                />
              )}
            </div>
          ))}
        </div>

        <section className="application-activities">
          {/* <div className="applications">
              <TableComponent
                data={institutions}
                title="Recent Applications"
                columns={columns}
              />
            </div> */}
          <div className="chart">
            <Chart />
          </div>
          <div className="activities">
            {/* <h4>Recent Activities Logs</h4> */}
            {/* {activities.map(({ name, userImage, text, date, time }, i) => (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "start",
                    padding: "0 20px",
                    marginBottom: "15px",
                  }}
                >
                  <div className="image-container">
                    <img
                      src={userImage}
                      alt="user Image"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div>
                    <h6 style={{ marginBottom: "0px" }}>{name}</h6>
                    <p style={{ marginBottom: "5px" }}>{text}</p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        color: "#0046BC",
                      }}
                    >
                      <img src={calenderIcon} alt="calender" />
                      <p style={{ margin: "0px" }}>{date}</p>
                      <img src={clockIcon} alt="clock" />
                      <p style={{ margin: "0px" }}>{time}</p>
                    </div>
                  </div>
                </div>
              ))} */}

            {revenueBreakdown.map(({ icon, title, value, vector }, i) => (
              <div className="overview-box" key={i}>
                <img src={icon} alt="icon" />
                <div>
                  <h3>₦ {addTrailingCommas(value)}</h3>
                  <p>{title}</p>
                </div>
                <img
                  src={vector}
                  alt="vector"
                  style={{ position: "absolute", top: "0px", right: "0px" }}
                />
              </div>
            ))}
          </div>
        </section>

        <TableComponent
          data={dashboardDetails?.dailyTransactions}
          title="Daily Transactions"
          columns={columns}
        />

        <br />
        <br />
      </>
      {/* )} */}
    </div>
  );
};

export default SuperAdminDashBoardPage;
