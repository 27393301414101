import { Toast } from "react-bootstrap";

const ToastComponent = ({ showToast, setShowToast, toastMessage }) => {
  return (
    <Toast
      onClose={() => setShowToast(false)}
      show={showToast}
      delay={6000}
      autohide
      style={{
        position: "fixed",
        zIndex: 100,
        top: 20,
        right: 20,
        minWidth: "250px",
        backgroundColor: "#ffffff",
        animation: `${showToast ? "slide-in" : "slide-out"} 0.5s ease-in-out`,
      }}
    >
      <Toast.Body>{toastMessage}</Toast.Body>
    </Toast>
  );
};

export default ToastComponent;
