import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../../../../redux/features/auth/authSlice";
import notificationIcon from "../../../../assets/svgs/notificationIcon.svg";
import dropdownIcon from "../../../../assets/svgs/dropdownIcon.svg";
import menuIcon from "../../../../assets/svgs/menuIcon.svg";
import userDisplayPhoto from "../../../../assets/images/userDisplayPhoto.png";
import femaleAvatar from "../../../../assets/svgs/femaleAvatar.svg";
import { Offcanvas } from "react-bootstrap";
import { Sidebar, MenuItem, Menu } from "react-pro-sidebar";
import { Navbar, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../../../assets/svgs/logo.svg";
import { useLocation } from "react-router-dom";
import DashboardIcon from "../../../../assets/svgs/dashboardIcon";
import ReportingIcon from "../../../../assets/svgs/reportingIcon";
import SettlementIcon from "../../../../assets/svgs/settlementIcon";
import AdminIcon from "../../../../assets/svgs/adminIcon";
import SchoolsIcon from "../../../../assets/svgs/schoolsIcon";
import SettingsIcon from "../../../../assets/svgs/settingsIcon";
import ApplicationIcon from "../../../../assets/svgs/applicationIcon";
import AdminProfile from "./profile";


const DashboardTopSection = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [dropdownProfile, setDropdownProfile] = useState(false);
  console.log(showMenu);
  const dispatch = useDispatch();

  const [showProfileCanvas, setShowProfileCanvas] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const handleCloseCanvas = () => {
    setShowProfileCanvas(false);
  };

  const handleShowCanvas = () => {
    setDropdownProfile(false);
    setShowProfileCanvas(true);
  };

  const handleLogOut = () => {
    dispatch(logOut());
  };
  const username = useSelector((state) => state?.auth?.userInfo?.username);

  console.log(showMenu);

  const menuItem = {
    height: 2.7 + "rem",
    fontSize: 0.87 + "rem",
  };

  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(
    window.matchMedia("(max-width: 950px)").matches
  );

  const location = useLocation();

  const date = new Date();
  const hour = date.getHours();

  // const username = "Admin";
  // const gender = (sex) => sex ? "male" : "female";
  const gender = "male";

  // useEffect(() => {
  //   const handleClose = (e) => {
  //     // close menu when they click outside
  //     if (e.target.className) {
  //       if (
  //         showMenu &&
  //         !e.target.className.includes("mobileDashboardNavbar") &&
  //         !e.target.className.includes("menuIcon")
  //       ) {
  //         setShowMenu(false);
  //       }
  //     }
  //   };

  //   window.addEventListener("click", handleClose);

  //   return () => {
  //     window.removeEventListener("click", handleClose);
  //   };
  // }, [showMenu]);

  return (
    <div className="top-section">
      {/* <h3 style={{ paddingLeft: "auto" }}>Welcome {username}</h3> */}
      <h3 style={{ paddingLeft: "auto" }}>Welcome</h3>
      <img
        src={menuIcon}
        alt="menu icon"
        onClick={() => setShowMenu(!showMenu)}
        className="menuIcon"
      />
      <div className="user-details">
        {/* <img src={notificationIcon} alt="notification icon" /> */}

        {/* <div
          onClick={() => setDropdownProfile(!dropdownProfile)}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
          }}
        >
          {hour > 12 ? hour >= 16 ? <span>Good Evening, </span> : <span>Good Afternoon, </span> : <span>Good Morning, </span>}
          <span>{username}</span>
          <img src={userDisplayPhoto} alt="user" height="40px" />
          <img src={dropdownIcon} alt="drop down" />
        </div> */}

        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {hour > 12 ? (
              hour >= 16 ? (
                <span style={{ flexShrink: "0" }}>Good Evening, </span>
              ) : (
                <span style={{ flexShrink: "0" }}>Good Afternoon, </span>
              )
            ) : (
              <span style={{ flexShrink: "0" }}>Good Morning, </span>
            )}
            <span>{username}</span>
            {/* <span>Hi, {username}</span> */}
            <div
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "100%",
                cursor: "pointer",
              }}
              onClick={() => setDropdownProfile(!dropdownProfile)}
            >
              <img
                src={gender === "male" ? userDisplayPhoto : femaleAvatar}
                alt="user"
                style={{ width: "100%" }}
              />
            </div>

            <img
              src={dropdownIcon}
              alt="drop down"
              onClick={() => setDropdownProfile(!dropdownProfile)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>

        {dropdownProfile && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              right: 0,
              width: "130px",
              zIndex: 10,
              backgroundColor: "#fff2ee",
              padding: "15px",
            }}
            className="profile-dropdown"
          >
            <span
              onClick={handleShowCanvas}
              style={{ cursor: "pointer", fontWeight: 500 }}
            >
              Profile
            </span>
            <hr />
            <span
              onClick={handleLogOut}
              style={{ cursor: "pointer", fontWeight: 500 }}
            >
              LogOut
            </span>
          </div>
        )}

      </div>

      <Offcanvas
        show={showProfileCanvas}
        onHide={handleCloseCanvas}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Profile</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ paddingTop: "0px" }}>
          {/* <AdminProfile institutionDetails={institutionDetails} /> */}
          <AdminProfile />
        </Offcanvas.Body>
      </Offcanvas>


      <Offcanvas
        show={showMenu}
        onHide={() => setShowMenu(false)}
        placement={"start"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "200px" }}
      >
        <Offcanvas.Body style={{ padding: "20px 0" }}>
          <Navbar.Brand className="logo">
            <div className="navbar-brand-space">
              <br />
            </div>
            <Image src={logo} fluid />
          </Navbar.Brand>

          <Menu
            className="menu"
            menuItemStyles={{
              button: ({ active }) => ({
                color: active ? "white" : "#464646",
                backgroundColor: active ? "#FD5821" : "transparent",
                "&:hover": {
                  backgroundColor: active ? "#FD5821" : "#F5F5F5",
                  color: active ? "white" : "#FFFFFF",
                },
              }),
            }}
          >
            <Link to="/principal">
              <MenuItem
                style={menuItem}
                active={location.pathname === "/principal" ? "active" : null}
                icon={
                  <DashboardIcon
                    active={
                      location.pathname === "/principal" ? "active" : null
                    }
                    size={18}
                  />
                }
              >
                {" "}
                Dashboard
              </MenuItem>
            </Link>

            <Link to="/principal/user-management">
              <MenuItem
                style={menuItem}
                active={
                  location.pathname.startsWith("/principal/user-management")
                    ? "active"
                    : null
                }
                icon={
                  <AdminIcon
                    active={
                      location.pathname.startsWith("/principal/user-management")
                        ? "active"
                        : null
                    }
                    size={18}
                  />
                }
              >
                {" "}
                User Management
              </MenuItem>
            </Link>

            <Link to="/principal/applications">
              <MenuItem
                style={menuItem}
                active={
                  location.pathname.startsWith("/principal/applications")
                    ? "active"
                    : null
                }
                icon={
                  <ApplicationIcon
                    active={
                      location.pathname.startsWith("/principal/applications")
                        ? "active"
                        : null
                    }
                    size={18}
                  />
                }
              >
                {" "}
                Applications
              </MenuItem>
            </Link>

            <Link to="/principal/schools">
              <MenuItem
                style={menuItem}
                active={
                  location.pathname.startsWith("/principal/schools")
                    ? "active"
                    : null
                }
                icon={
                  <SchoolsIcon
                    fill={
                      location.pathname.startsWith("/principal/schools")
                        ? "#ffffff"
                        : "#464646"
                    }
                    size={19}
                  />
                }
              >
                {" "}
                Schools
              </MenuItem>
            </Link>

            <Link to="/principal/reporting">
              <MenuItem
                style={menuItem}
                active={
                  location.pathname.startsWith("/principal/reporting")
                    ? "active"
                    : null
                }
                icon={
                  <ReportingIcon
                    active={
                      location.pathname.startsWith("/principal/reporting")
                        ? "active"
                        : null
                    }
                    size={19}
                  />
                }
              >
                {" "}
                Reporting
              </MenuItem>
            </Link>

            {/* //used for sending message page */}
            <Link to="/principal/messaging">
              <MenuItem
                style={menuItem}
                active={
                  location.pathname.startsWith("/principal/messaging")
                    ? "active"
                    : null
                }
                icon={
                  <SettlementIcon
                    active={
                      location.pathname.startsWith("/principal/messaging")
                        ? "active"
                        : null
                    }
                    size={19}
                  />
                }
              >
                {" "}
                Send Message
              </MenuItem>
            </Link>

            <Link to="/principal/settings">
              <MenuItem
                style={menuItem}
                active={
                  location.pathname.startsWith("/principal/settings")
                    ? "active"
                    : null
                }
                icon={
                  <SettingsIcon
                    active={
                      location.pathname.startsWith("/principal/settings")
                        ? "active"
                        : null
                    }
                    size={19}
                  />
                }
              >
                {" "}
                Settings
              </MenuItem>
            </Link>

          </Menu>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default DashboardTopSection;
