import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
Form,
// Button,
Image
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import successGIF from "../../../assets/svgs/successGIF.gif";
import OnboardingLoader from "../../../component/onboardingLoader/onboardingLoader";
import RegistrationLayout from "../../../component/layout/registrationLayout";
import { useResetPasswordMutation } from "../../../redux/features/auth/authApiSlice";
import ModalComponent from "../../../component/modal/modal";
import ToastComponent from "../../../component/toast/toast";
import Button from "../../../component/button/button";
import { logOut } from "../../../redux/features/auth/authSlice";

import { useParams, useLocation } from "react-router-dom";

import showPasswordIcon from "../../../assets/svgs/showPassword.svg";
import hidePasswordIcon from "../../../assets/svgs/hidePassword.svg";

function ResetPasswordPage() {
  const { email, code } = useParams();

  console.log(email, code, 11111);

  const [errorMessage, setErrorMessage] = useState(null);
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [isMinCharsValid, setIsMinCharsValid] = useState(false);
  const [isUppercaseValid, setIsUppercaseValid] = useState(false);
  const [isLowercaseValid, setIsLowercaseValid] = useState(false);
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [isSpecialCharValid, setIsSpecialCharValid] = useState(false);


  const [formData, setFormData] = useState({
    // email: "",
    // code: "",
    password: "",
    // confirmPassword,
  });
  // const { email, code, password } = formData;
  const
    {
      password,
      confirmPassword
    } = formData;

  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/login");
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Check each condition for the password validation
    if (name === "password") {
      setIsMinCharsValid(value.length >= 8);
      setIsUppercaseValid(/[A-Z]/.test(value));
      setIsLowercaseValid(/[a-z]/.test(value));
      setIsNumberValid(/\d/.test(value));
      setIsSpecialCharValid(/[@#$%^&+=]/.test(value));
    }
  };

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const [passMatch, setPassMatch] = useState(true);

  useEffect(() => {
    validatePassword();
  }, [formData]);

  const validatePassword = () => {
    if (password != "") {
      password === confirmPassword
        ? setPassMatch(true)
        : setPassMatch(false);
    }
  };


  const handleOnSubmit = async () => {
    // e.preventDefault();

    // test if the password is now valid before submitting
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}$/;

    if (!passwordRegex.test(password)) {
      setIsPasswordValid(false);
      return;
    }

    try {
      setIsPasswordValid(true);
      const params = {
        email: email,
        code: code,
        body: {
          password: password
        }
      }
      const data = await resetPassword(params).unwrap();

      if (data) {
        // dispatch(userInfo(data));
        console.log(data);
        setShowModal(true);
      }
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  if (isLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <RegistrationLayout>
        <br />

        <br />

        <ModalComponent showModal={showModal} closeModal={handleCloseModal}>
          <div style={{ textAlign: "center" }}>
            <img
              src={successGIF}
              alt="success Gif"
              width="150px"
              style={{ margin: "0 auto" }}
            />
            <h3 style={{ color: "#F14810", fontWeight: "600" }}>
              Successful!!
            </h3>
            <p style={{ fontSize: "18px" }}>
              Password has been reset successfully
            </p>
          </div>
        </ModalComponent>

        <div>
          <h3 className="FormHeader" style={{ marginTop: "15px" }}>
            Reset Password
          </h3>
          <p className="FormParagraph">
            Set a strong and memorable password for your account
          </p>
        </div>

        <br />

        <Form className="form" autoComplete="off">

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              New Password <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <div style={{ position: "relative" }} className="password-box">
              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                autoComplete="off"
                onChange={handleOnChange}
                placeholder="Enter Your Password"
              />
              {showPassword ? (
                <img
                  src={hidePasswordIcon}
                  alt="showPassword Icon"
                  onClick={() => setShowPassword(false)}
                  className="hidePasswordIcon"
                />
              ) : (
                <img
                  src={showPasswordIcon}
                  alt="showPassord Icon"
                  onClick={() => setShowPassword(true)}
                  className="showPasswordIcon"
                />
              )}
            </div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Confirm Password <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <div style={{ position: "relative" }} className="password-box">
              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                type={showPassword ? "text" : "password"}
                name="confirmPassword"
                value={confirmPassword}
                autoComplete="off"
                onChange={handleOnChange}
                placeholder="Enter Your Password"
                required
              />
              {showPassword ? (
                <img
                  src={hidePasswordIcon}
                  alt="showPassword Icon"
                  onClick={() => setShowPassword(false)}
                  className="hidePasswordIcon"
                />
              ) : (
                <img
                  src={showPasswordIcon}
                  alt="showPassord Icon"
                  onClick={() => setShowPassword(true)}
                  className="showPasswordIcon"
                />
              )}
            </div>
          </Form.Group>


          <ul style={{ listStyle: "none", marginLeft: -2 + "rem" }}>
            <li
              className="fst-italic"
              style={{
                color: "red",
                fontFamily: "jost, sans-serif",
                fontSize: 0.85 + "rem",
              }}
            >
              Password must contain:
            </li>
            <li
              className="fst-italic"
              style={{
                fontFamily: "jost, sans-serif",
                fontSize: 0.85 + "rem",
                color: !isMinCharsValid ? "red" : "#2bb4b1",
              }}
            >
              Minimum of 8 characters
            </li>
            <li
              className="fst-italic"
              style={{
                fontFamily: "jost, sans-serif",
                fontSize: 0.85 + "rem",
                color: !passMatch ? "red" : "#2bb4b1",
              }}
            >
              Passwords Match
            </li>

            <li
              className="fst-italic"
              style={{
                fontFamily: "jost, sans-serif",
                fontSize: 0.85 + "rem",
                color: !isLowercaseValid || !isUppercaseValid ? "red" : "#2bb4b1",
              }}
            >
              one uppercase letter and one lowercase letter
            </li>
            <li
              className="fst-italic"
              style={{
                fontFamily: "jost, sans-serif",
                fontSize: 0.85 + "rem",
                color: !isNumberValid ? "red" : "#2bb4b1",
              }}
            >
              one number
            </li>
            <li
              className="fst-italic"
              style={{
                fontFamily: "jost, sans-serif",
                fontSize: 0.85 + "rem",
                color: !isSpecialCharValid ? "red" : "#2bb4b1",
              }}
            >
              one special character
            </li>
          </ul>

          <br />

          {/* <Button
            variant="primary"
            style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
            className="button"
            size="md"
            onClick={
              !passMatch
                ? null
                : () => handleOnSubmit()
            }
            backgroundColor={`${!passMatch
              ? "#FFCAB8"
              : "rgb(241, 72, 16)"
              }`}
            cursor={`${!passMatch
              ? "not-allowed"
              : "pointer"
              }`}
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              Save Password
            </span>
          </Button>  */}
          <Button
            onClick={!passMatch ? null : () => handleOnSubmit()}
            backgroundColor={`${!passMatch ? "#FFCAB8" : "rgb(241, 72, 16)"}`}
            cursor={`${!passMatch ? "not-allowed" : "pointer"}`}
          >
            Save Password
          </Button>

        </Form>

        <div>
          <p
            style={{
              fontFamily: "jost, sans-serif",
              fontSize: 0.85 + "rem",
              marginTop: 1 + "rem",
              marginRight: -2 + "rem",
            }}
          >
            Remember Password?
            <a
              href="/login"
              className="btn btn-link"
              role="button"
              data-bs-toggle="button"
              style={{
                fontFamily: "jost, sans-serif",
                fontSize: 0.85 + "rem",
                marginTop: 0.85 + "rem",
                marginBottom: 1.15 + "rem",
                paddingLeft: 0.3 + "rem",
                paddingRight: 1.2 + "rem",
              }}
            >
              <span style={{ color: "#3174fd" }}>Back to Login</span>
            </a>
          </p>
        </div>
      </RegistrationLayout>
    </>
  );
}

export default ResetPasswordPage;
