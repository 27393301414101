import "./uploadCredentials.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import uploadIcon from "../../../../../../assets/svgs/uploadIcon.svg";
import alertIcon from "../../../../../../assets/svgs/alertIcon.svg";
import {
  useGetDocumentCategoriesQuery,
  useGetInstitutionDocumentListQuery,
  useGetInstitutionDocumentQuery,
  useDeleteDocumentMutation,
  useEditDocumentMutation,
  useUpdateInstitutionMutation,
  useUploadDocumentMutation,
  useGetUploadedDocumentsQuery,
  useGetSetupProgressQuery,
} from "../../../../../../redux/features/institution/institutionApiSlice";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../../../../../../component/modal/modal";
import { Table, Button, Modal } from "react-bootstrap";
import cautionIcon from "../../../../../../assets/svgs/cautionIcon.svg";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import ToastComponent from "../../../../../../component/toast/toast";
import { Link } from "react-router-dom";
import ActionIcon from "../../../../../../component/actionbutton/ActionIcon";

const UploadCredentials = ({ setActiveStage }) => {
  const [showModal, setShowModal] = useState(false);
  const [documentViewed, setDocumentViewed] = useState(null);
  const [docUploaded, setDocUploaded] = useState(null);
  const [docDeleted, setDocDeleted] = useState(null);
  const [documentToDeleteId, setDocumentToDeleteId] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const [deleteDocument, { isLoading: deleteLoading }] =
    useDeleteDocumentMutation();

  const [uploadDocument, { isLoading: docUploadLoading }] =
    useUploadDocumentMutation();

  const [editDocument, { isLoading: editLoading }] = useEditDocumentMutation();

  const { data: documentCategories, isLoading: documentCategoriesLoading } =
    useGetDocumentCategoriesQuery(instId);
  const {
    data: uploadedDocuments,
    isLoading: uploadedDocumentsLoading,
    refetch: refetchUploadedDocs,
  } = useGetUploadedDocumentsQuery(instId);

  const {
    data: setupProgress,
    // isLoading: progressLoading,
    refetch: refetchProgress,
  } = useGetSetupProgressQuery(instId);

  console.log(uploadedDocuments);
  console.log(documentCategories);

  const [newCredentialInfo, setNewCredentialInfo] = useState({
    documentCategory: "",
    documentCategoryId: "",
    content: "",
    description: "",
  });

  const { documentCategory, content, description, documentCategoryId } =
    newCredentialInfo;

  const handleOnChange = (e, id) => {
    const { name, value, type } = e.target;

    console.log(id);

    setNewCredentialInfo({ ...newCredentialInfo, [name]: value });

    if (type === "file") {
      let selectedFile = e.target.files;

      if (selectedFile.length > 0) {
        let actualFile = selectedFile[0];

        // check if its a pdf
        if (
          actualFile.type === "application/pdf" ||
          actualFile.type === "image/jpeg"
        ) {
          let fileName = actualFile.name;
          const reader = new FileReader();

          reader.onload = (event) => {
            const docDetails = {
              name: fileName,
              docUrl: event.target.result,
            };
            // find the credential on which the upload icon was clicked
            const credential = documentCategories?.find((doc) => doc.id === id);
            submitCredential(credential, docDetails);
          };
          reader.readAsDataURL(actualFile);
          setShowToast(true);
          setToastMessage("Upload Successful");
        } else {
          setShowToast(true);
          setToastMessage("Upload a pdf or jpeg file");
        }
      }
    }
  };

  const submitCredential = async (credential, docDetails) => {
    const { id, ...rest } = credential;

    const params = {
      institutionId: instId,
      body: {
        ...rest,
        documentCategoryId: id,
        description: credential?.name,
        content: docDetails?.docUrl,
      },
    };

    try {
      const res = await uploadDocument(params).unwrap();
      console.log(res);
      setDocUploaded(res);
      setShowToast(true);
      setToastMessage("Document uploaded successfully!");
      refetchProgress();
    } catch (error) {
      console.log(error);
      setShowToast(true);

      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        setToastMessage(error.error);
      }
    }
  };

  const confirmDelete = (id) => {
    setDocumentToDeleteId(id);
    setShowConfirmDelete(true);
  };

  const handleViewCredential = (id) => {
    const doc = uploadedDocuments?.find(
      (doc) => doc.documentCategory.id === id
    );
    setDocumentViewed(doc.content);
    setShowPdfModal(true);
  };

  const handleDeleteCredential = async () => {
    console.log("delete was clicked for ", documentToDeleteId);
    setShowConfirmDelete(false);

    try {
      const parameters = {
        institutionId: instId,
        documentId: uploadedDocuments?.find(
          (doc) => doc.documentCategory.id === documentToDeleteId
        )?.id,
      };
      await deleteDocument(parameters).unwrap();
      setDocDeleted(documentToDeleteId);
      setShowToast(true);
      setToastMessage("Document deleted successfully!");
      refetchProgress();
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  useEffect(() => {
    if (uploadedDocuments) {
      refetchUploadedDocs();
    }
  }, [docUploaded, docDeleted]);

  if (
    deleteLoading ||
    editLoading ||
    documentCategoriesLoading ||
    uploadedDocumentsLoading ||
    docUploadLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <div className="upload-credentials no-scrollbar">
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      {/* delete confirmation modal */}
      <ModalComponent
        showModal={showConfirmDelete}
        closeModal={() => setShowConfirmDelete(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img src={alertIcon} alt="alert Icon" style={{ margin: "0 auto" }} />
          <p
            style={{
              marginTop: "10px",
              marginBottom: "5px",
              fontWeight: "500",
              fontSize: 1.0 + "rem",
            }}
          >
            Are you sure you want to delete this document?{" "}
          </p>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              onClick={() => setShowConfirmDelete(false)}
              type="button"
              variant="outline-primary"
              style={{ borderColor: "#F14810", color: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                No
              </span>
            </Button>
            <Button
              onClick={() => handleDeleteCredential()}
              type="button"
              variant="primary"
              style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Yes
              </span>
            </Button>
          </div>
        </div>
      </ModalComponent>

      {documentViewed && (
        <Modal
          centered
          show={showPdfModal}
          onHide={() => setShowPdfModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Document Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "80vh" }}>
            <iframe
              width="100%"
              height="100%"
              src={`${documentViewed}`}
              title="PDF Viewer"
            ></iframe>
          </Modal.Body>
        </Modal>
      )}

      <h6 style={{ fontSize: "14px", fontWeight: 600 }}>Documents</h6>

      {documentCategories?.length > 0 ? (
        <div
          style={{
            border: "1px solid #F5F5F5",
            minHeight: "95%",
            borderRadius: "8px",
            padding: "15px",
          }}
        >
          <div
            style={{
              marginBottom: "20px",
              border: "1px solid #F5F5F5",
              padding: "15px",
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <img src={cautionIcon} alt="caution" />
            <div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <div
                  style={{
                    height: "2px",
                    width: "2px",
                    borderRadius: "100%",
                    backgroundColor: "#000",
                  }}
                ></div>
                <span style={{ fontSize: "14px", fontWeight: "500" }}>
                  Upload the following documents
                </span>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <div
                  style={{
                    height: "2px",
                    width: "2px",
                    borderRadius: "100%",
                    backgroundColor: "#000",
                  }}
                ></div>
                <span style={{ fontSize: "14px", fontWeight: "500" }}>
                  Upload only pdf & Jpeg files
                </span>
              </div>
            </div>
          </div>

          <Table
            // responsive
            striped
            size="sm"
            style={{
              fontFamily: "jost, sans-serif",
              fontSize: "14px",
              overflow: "auto",
            }}
          >
            <thead>
              <tr align="center">
                <th>Document Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {documentCategories?.map(({ name, id }, i) => (
                <tr key={i} className={i % 2 === 0 ? "even-row" : "odd-row"}>
                  <td align="center">{name}</td>

                  <td align="center">
                    {uploadedDocuments?.find(
                      (doc) => doc.documentCategory.id === id
                    ) ? (
                      <ActionIcon
                        actionsArr={[
                          {
                            label: "View",
                            action: () => handleViewCredential(id),
                          },
                          {
                            label: "Delete",
                            action: () => confirmDelete(id),
                          },
                        ]}
                      />
                    ) : (
                      <label
                        htmlFor={`uploadIcon-${id}`}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={uploadIcon} alt="upload icon" />
                        <input
                          type="file"
                          id={`uploadIcon-${id}`}
                          style={{ display: "none" }}
                          onChange={(e) => handleOnChange(e, id)}
                        />
                      </label>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div style={{ marginTop: "20px" }}>
            <p style={{ fontWeight: "500", margin: "5px 0" }}>
              Click the button below to watch how to edit your set-up.
            </p>

            <Link to="/help">
              <span style={{ color: "#F14810", fontWeight: "600" }}>
                Watch Video
              </span>
            </Link>
          </div>
        </div>
      ) : (
        <span style={{ fontStyle: "italic" }}>
          There are no documents to be uploaded yet
        </span>
      )}
    </div>
  );
};

export default UploadCredentials;
