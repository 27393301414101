// import "./institutionBranding.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import institutionLogo from "../../../../../../assets/svgs/institutionPlaceholderLogo.svg";
// import Button from "../../../../../component/button/button"; 
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// import { storeBrandingDetails } from "../../../../../redux/features/institution/institutionSlice";
// import { useGetInstitutionQuery, useGetInstitutionLogoQuery } from "../../../../../redux/features/institution/institutionApiSlice";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import { SketchPicker } from "react-color";




const SchoolBranding = ({ setActiveStage, color, onChange }) => {

    const [isFormNotFilled, setIsFormNotFilled] = useState(false)
    const [showPicker, setShowPicker] = useState(false)


    //  get details if they have filled this page before
    // const brandingDetails = useSelector(
    //     (state) => state.institution.brandingDetails
    // );


    // const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
    // const { data: institution, isLoading } = useGetInstitutionQuery(instId, {
    //     skip: !instId,
    // });
    // const { data: apiInstitutionLogo, isLoading: logoLoading } = useGetInstitutionLogoQuery(instId)
    // console.log(brandingDetails)
    // console.log(institution);
    // console.log(apiInstitutionLogo);

    // const dispatch = useDispatch();

    const [chosenColor, setChosenColor] = useState(
        // brandingDetails ? brandingDetails.institutionBranding.brandColour : ""
    );

    const [chosenImage, setChosenImage] = useState(
        // brandingDetails
        //     ? brandingDetails.institutionBranding.logo
        //     : ""
    );

    // console.log(brandingDetails)


    // const [setupInstitutionInfo, setSetupInstitutionInfo] = useState({
    //     institutionBranding: {
    //         brandColour: brandingDetails
    //             ? brandingDetails?.institutionBranding?.brandColour
    //             : chosenColor,
    //         logo: brandingDetails
    //             ? brandingDetails?.institutionBranding?.logo
    //             : apiInstitutionLogo?.logo,
    //     },
    // });

    // const institutionDetails = useSelector(
    //     (state) => state.institution.institutionDetails
    // );
    // console.log(institutionDetails)


    // const { institutionBranding } = setupInstitutionInfo;



    // useEffect(() => {
    //     if (institution && apiInstitutionLogo) {
    //         console.log(institution);
    //         setSetupInstitutionInfo((prevInfo) => ({
    //             //  ...prevInfo,
    //             institutionBranding: {
    //                 brandColour: brandingDetails
    //                     ? brandingDetails.institutionBranding.brandColour
    //                     : institution?.brandColour,
    //                 logo: brandingDetails
    //                     ? brandingDetails.institutionBranding.logo
    //                     : apiInstitutionLogo.logo,
    //             },
    //         }));
    //         setChosenImage(apiInstitutionLogo.logo);
    //     }
    // }, [institution, apiInstitutionLogo]);

    // useEffect(() => {
    //     setChosenColor(institutionBranding.brandColour ? institutionBranding?.brandColour : institution?.brandColour);
    // }, [])


    const handleOnChange = (e) => {
        const { name, value, type, files } = e.target;
        // handle the keys that are nested in objects. E.g contactPerson.firstName

        const [objectKey, nestedKey] = name.split(".");
        // If we can split the name by "." it means it is nested
        // spread all the previous data in the entire object, then target the particular object that needs to be mutated. spread all of its contents, then mutate the particular key needed

        // if (nestedKey) {
        //     setSetupInstitutionInfo((previousData) => ({
        //         ...previousData,
        //         [objectKey]: { ...previousData[objectKey], [nestedKey]: value },
        //     }));
        // } else {
        //     setSetupInstitutionInfo({ ...setupInstitutionInfo, [name]: value });
        // }

        // let selectedFile = e.target.files;

        // if (selectedFile.length > 0) {
        //     let actualFile = selectedFile[0];
        //     let fileName = actualFile.name;
        //     const reader = new FileReader();

        //     reader.onload = (event) => {
        //         const imageDetails = {
        //             name: fileName,
        //             imageUrl: event.target.result,
        //         };
        //         setChosenImage(event.target.result);
        //     };
        //     reader.readAsDataURL(actualFile);
        // }
    };

    const handleColorChange = (color) => {
        setChosenColor(color?.hex);
        // institutionBranding.brandColour = color?.hex;
    };

    const handleGoToPrevPage = () => {
        setActiveStage("about school");
        // dispatch and store so we can get these details even when user comes back to the page
        // dispatch(storeBrandingDetails(setupInstitutionInfo));
        // dispatch(
        //     storeBrandingDetails({
        //         // institutionBranding: { ...institutionBranding, logo: chosenImage },
        //         institutionBranding: { brandColour: chosenColor, logo: chosenImage },
        //     })
        // );
    }

    // console.log(institutionBranding)
    // console.log(institutionBranding.brandColour);
    // console.log(chosenColor);


    const handleGoToNextPage = () => {

        // if (
        //     !institutionBranding.brandColour ||
        //     !institutionBranding.logo
        // ) {
        //     setIsFormNotFilled(true);

        //     setTimeout(() => {
        //         setIsFormNotFilled(false);
        //     }, 3000);

        //     return;
        // }

        // setActiveStage("Contact Person");
        setActiveStage("contact person");
        // dispatch and store so we can get these details even when user comes back to the page
        // dispatch(storeBrandingDetails(setupInstitutionInfo));
        // dispatch(storeBrandingDetails({
        //     // institutionBranding: {...institutionBranding, logo: chosenImage}
        //     institutionBranding: { brandColour: chosenColor, logo: chosenImage }
        // }));
    };

    // const renderTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Fill the required fields then click this button to save your changes
    //     </Tooltip>
    // );

    // if (isLoading || logoLoading) {
    //     return <OnboardingLoader />;
    // }

    return (
        <div className="school-branding no-scrollbar">
            <div>
                <div
                    style={{
                        display: "flex",
                        gap: 0.8 + "rem",
                        // alignItems: "center",
                        marginBottom: "15px",
                    }}
                >
                    <div>
                        <svg width="55" height="55" viewBox="0 0 68 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M67.5 34.5C67.5 53.1396 52.3896 68.25 33.75 68.25C15.1104 68.25 0 53.1396 0 34.5C0 15.8604 15.1104 0.75 33.75 0.75C52.3896 0.75 67.5 15.8604 67.5 34.5ZM8.4375 34.5C8.4375 48.4797 19.7703 59.8125 33.75 59.8125C47.7297 59.8125 59.0625 48.4797 59.0625 34.5C59.0625 20.5203 47.7297 9.1875 33.75 9.1875C19.7703 9.1875 8.4375 20.5203 8.4375 34.5Z" fill="#E7EAEE" fill-opacity="0.8" />
                            <path d="M63.2812 34.5C65.6112 34.5 67.5272 36.396 67.2367 38.7078C66.8614 41.6941 66.0876 44.6232 64.9309 47.4156C63.2348 51.5103 60.7488 55.2309 57.6149 58.3649C54.4809 61.4988 50.7603 63.9848 46.6656 65.6809C42.5708 67.377 38.1821 68.25 33.75 68.25C29.3179 68.25 24.9292 67.377 20.8344 65.6809C16.7397 63.9848 13.0191 61.4988 9.88514 58.3649C6.75117 55.2309 4.26516 51.5103 2.56906 47.4156C1.41243 44.6232 0.638583 41.6941 0.263331 38.7078C-0.0271591 36.396 1.8888 34.5 4.21875 34.5C6.5487 34.5 8.40172 36.4016 8.78825 38.6993C9.10424 40.5776 9.63196 42.4187 10.3643 44.1867C11.6364 47.2577 13.5009 50.0482 15.8514 52.3986C18.2018 54.7491 20.9923 56.6136 24.0633 57.8857C27.1344 59.1578 30.4259 59.8125 33.75 59.8125C37.0741 59.8125 40.3656 59.1578 43.4367 57.8857C46.5077 56.6136 49.2982 54.7491 51.6486 52.3986C53.9991 50.0482 55.8636 47.2577 57.1357 44.1867C57.868 42.4187 58.3958 40.5776 58.7117 38.6993C59.0983 36.4016 60.9513 34.5 63.2812 34.5Z" fill="#F14810" />
                            <path d="M17.36 41L23.084 35.024C23.408 34.676 23.684 34.352 23.912 34.052C24.14 33.752 24.314 33.452 24.434 33.152C24.554 32.852 24.614 32.54 24.614 32.216C24.614 31.988 24.572 31.766 24.488 31.55C24.404 31.334 24.278 31.142 24.11 30.974C23.942 30.806 23.738 30.674 23.498 30.578C23.258 30.47 22.982 30.416 22.67 30.416C22.226 30.416 21.842 30.524 21.518 30.74C21.206 30.944 20.96 31.238 20.78 31.622C20.612 32.006 20.528 32.45 20.528 32.954H18.008C18.008 32.054 18.188 31.244 18.548 30.524C18.908 29.804 19.436 29.234 20.132 28.814C20.84 28.382 21.704 28.166 22.724 28.166C23.48 28.166 24.14 28.286 24.704 28.526C25.28 28.754 25.754 29.06 26.126 29.444C26.51 29.816 26.792 30.23 26.972 30.686C27.164 31.142 27.26 31.598 27.26 32.054C27.26 32.726 27.098 33.356 26.774 33.944C26.45 34.532 26.024 35.066 25.496 35.546L22.22 38.642H27.494V41H17.36ZM36.2223 28.4H38.4723L30.9123 43.7H28.5903L36.2223 28.4ZM39.0321 38.678L46.2501 28.4H47.9961V41H45.3501V37.832L45.5661 37.634V32.504L42.5961 36.752H47.0961L47.4021 36.482H49.9761V38.678H39.0321Z" fill="#343434" />
                        </svg>
                    </div>
                    <div>
                        <h5 style={{ marginBottom: "0px" }}>School Branding</h5>
                        <p className="FormParagraph">
                            Provide the school logo and color
                        </p>
                    </div>
                </div>
            </div>
            {/* {isFormNotFilled && (
                <span
                    style={{
                        color: "red",
                        fontStyle: "italic",
                        transition: "ease-in-out",
                    }}
                >
                    Please fill all fields
                </span>
            )} */}

            <form className="school-branding">
                <div className="logo-btn-container">
                    <div className="logo-container">
                        {chosenImage !== "" ? (
                            <img src={chosenImage} alt="School Logo" />
                        ) : (
                            <img src={institutionLogo} alt="School Placeholder Logo" />
                        )}
                    </div>
                    <label htmlFor="uploadLogo">Upload Logo</label>
                    <input
                        type="file"
                        id="uploadLogo"
                        style={{ visibility: "hidden" }}
                        name="institutionBranding.logo"
                        // value={institutionBranding.logo}
                        onChange={handleOnChange}
                    />
                </div>
                <span>Select Color</span>
                <div className="colors-container">
                    {/* {colors.map((color, i) => (
            <div
              key={i}
              className={`colorBox color-${color.colorHex} ${
                color.colorHex === chosenColor ? "chosen" : ""
              }`}
              name="institutionBranding.brandColour"
              value={color.colorHex}
              onChange={handleOnChange}
              onClick={() => handleColorChange(color.colorHex)}
            ></div>
          ))} */}

                    <SketchPicker
                        color={chosenColor}
                        // value={institutionBranding.brandColour}
                        name="schoolBranding.brandColour"
                        onChange={handleColorChange}
                        onClick={() => handleColorChange(chosenColor)}
                    />

                    <div
                        style={{
                            width: "30px",
                            height: "30px",
                            border: "1px solid",
                            borderRadius: "100%",
                            backgroundColor: `${chosenColor}`,
                            // backgroundColor: `${institutionBranding.brandColour ? chosenColor : institution?.brandColour}`,
                        }}
                    ></div>
                    <span>School Color</span>
                </div>
                <div style={{ marginTop: "30px", display: "flex", gap: "15px" }}>
                    <Button
                        onClick={handleGoToPrevPage}
                        variant="outline-primary"
                        style={{ borderColor: "#F14810", color: "#F14810" }}
                        className="button"
                        size="md"
                    >
                        <span
                            style={{
                                fontSize: 0.8 + "rem",
                                marginLeft: 0.5 + "rem",
                                marginRight: 0.5 + "rem",
                            }}
                        >
                            Previous
                        </span>
                    </Button>
                    <Button
                        variant="primary"
                        style={{
                            backgroundColor: "#F14810",
                            borderColor: "#F14810",
                            float: "left",
                        }}
                        className="button"
                        size="md"
                        onClick={handleGoToNextPage}
                    >
                        <span
                            style={{
                                fontSize: 0.8 + "rem",
                                marginLeft: 0.5 + "rem",
                                marginRight: 0.5 + "rem",
                            }}
                        >
                            Save & Continue
                        </span>
                    </Button>

                    {/* <OverlayTrigger
                        placement="top"
                        delay={{ show: 150, hide: 300 }}
                        overlay={renderTooltip}
                    >
                        <Button
                            variant="primary"
                            onClick={
                                !institutionBranding.brandColour || !institutionBranding.logo
                                    ? null
                                    : () => handleGoToNextPage()
                            }
                            style={{
                                backgroundColor: `${!institutionBranding.brandColour || !institutionBranding.logo
                                    ? "#FFCAB8"
                                    : "#F14810"
                                    }`,
                                borderColor: `${!institutionBranding.brandColour || !institutionBranding.logo
                                    ? "#FFCAB8"
                                    : "#F14810"
                                    }`,
                                cursor: `${!institutionBranding.brandColour || !institutionBranding.logo
                                    ? "not-allowed"
                                    : "pointer"
                                    }`,
                                float: "left",
                            }}
                            className="button"
                            size="md"
                        >
                            <span
                                style={{
                                    fontSize: 0.8 + "rem",
                                    marginLeft: 0.5 + "rem",
                                    marginRight: 0.5 + "rem",
                                }}
                            >
                                Save & Continue
                            </span>
                        </Button>
                    </OverlayTrigger> */}

                    {/* <Button onClick={handleGoToNextPage}>Save & Continue</Button> */}
                </div>
            </form>
        </div>
    );
};

export default SchoolBranding;
