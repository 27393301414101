import React from 'react'

const StudentIcon = ({active}) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 26 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 10.3281C19.5 13.7697 16.5912 16.5573 13 16.5573C9.40875 16.5573 6.5 13.7697 6.5 10.3281L6.67875 8.86427L1.625 6.4349L13 0.984375L24.375 6.4349V14.2214H22.75V7.21354L19.3213 8.86427L19.5 10.3281ZM13 19.6719C20.1825 19.6719 26 22.4594 26 25.901V29.0156H0V25.901C0 22.4594 5.8175 19.6719 13 19.6719Z"
        fill={active ? "#ffffff" : "#464646"}
      />
    </svg>
  );
}

export default StudentIcon