import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";


// import all reducers
import authReducer from "../features/auth/authSlice"
import institutionReducer from "../features/institution/institutionSlice"
import studentReducer from "../features/institution/studentSlice"
import adminReducer from "../features/superAdmin/adminSlice"

export const store = configureStore({
  // all our reducers will be written in here
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    institution: institutionReducer,
    student: studentReducer,
    admin: adminReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat(apiSlice.middleware),
  devTools: true,
});