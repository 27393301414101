import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Image,
  Form,
  InputGroup,
} from "react-bootstrap";

// import { Link } from "react-router-dom";

// import SliderItems from "../../../component/SliderItems.js";

// import logo from "../../../assets/svgs/logo.svg";

import StudentInformationPage from "./components/studentInfo/studentInformationPage.js";
import ParentInformationPage from "./components/parentInfo/parentInformationPage.js";
import StudentLevelPage from "./components/studentLevel/studentLevelPage.js";
import {
  storeParentInfo,
  storeStudentData,
  storeStudentInfo,
  storeStudentLevelInfo,
} from "../../../../../../redux/features/institution/studentSlice.js";
import { useDispatch, useSelector } from "react-redux";

const studentRegisterStages = [
  { stage: "Student Information" },
  { stage: "Parent Information" },
  { stage: "Student Level" },
];

const StudentInformationStepper = ({ closeCanvas, student }) => {
  const [activeStage, setActiveStage] = useState("Parent Information");
  const dispatch = useDispatch();

  return (
    <div>
      <>
        <Container fluid>
          <div>
            {activeStage === "Parent Information" && (
              <ParentInformationPage
                setActiveStage={setActiveStage}
                student={student}
              />
            )}
            {activeStage === "Student Information" && (
              <StudentInformationPage
                setActiveStage={setActiveStage}
                student={student}
                closeCanvas={closeCanvas}
              />
            )}
            {/* {activeStage === "Student Level" && (
              <StudentLevelPage
                setActiveStage={setActiveStage}
                student={student}
              />
            )} */}
          </div>
          {/* </Col>
          </Row> */}
        </Container>
      </>
    </div>
  );
};

export default StudentInformationStepper;
