import { createSlice } from "@reduxjs/toolkit";

// store the info incase user clicks next and previous
const initialState = {
  studentInfo: null,
  parentInfo: null,
};

export const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    storeStudentInfo: (state, action) => {
      state.studentInfo = action.payload;
    },
    storeParentInfo: (state, action) => {
      state.parentInfo = action.payload;
    },
  },
});

export const { storeStudentInfo, storeParentInfo } = studentSlice.actions;

export default studentSlice.reducer;
