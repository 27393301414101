import React from "react";

const HelpIcon = ({ active }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M12.935 20.8C13.39 20.8 13.7748 20.6427 14.0894 20.3281C14.404 20.0135 14.5609 19.6291 14.56 19.175C14.56 18.72 14.4031 18.3352 14.0894 18.0206C13.7757 17.706 13.3909 17.5491 12.935 17.55C12.48 17.55 12.0956 17.7073 11.7819 18.0219C11.4682 18.3365 11.3109 18.7209 11.31 19.175C11.31 19.63 11.4673 20.0148 11.7819 20.3294C12.0965 20.644 12.4809 20.8009 12.935 20.8ZM11.765 15.795H14.17C14.17 15.08 14.2515 14.5167 14.4144 14.105C14.5773 13.6933 15.0375 13.13 15.795 12.415C16.3583 11.8517 16.8025 11.3152 17.1275 10.8056C17.4525 10.296 17.615 9.68413 17.615 8.97C17.615 7.75667 17.1708 6.825 16.2825 6.175C15.3942 5.525 14.3433 5.2 13.13 5.2C11.895 5.2 10.8927 5.525 10.1231 6.175C9.3535 6.825 8.81747 7.605 8.515 8.515L10.66 9.36C10.7683 8.97 11.0123 8.5475 11.3919 8.0925C11.7715 7.6375 12.3509 7.41 13.13 7.41C13.8233 7.41 14.3433 7.5998 14.69 7.9794C15.0367 8.359 15.21 8.77587 15.21 9.23C15.21 9.66333 15.08 10.0698 14.82 10.4494C14.56 10.829 14.235 11.1809 13.845 11.505C12.8917 12.35 12.3067 12.9892 12.09 13.4225C11.8733 13.8558 11.765 14.6467 11.765 15.795ZM13 26C11.2017 26 9.51167 25.6585 7.93 24.9756C6.34833 24.2927 4.9725 23.3666 3.8025 22.1975C2.6325 21.0275 1.70647 19.6517 1.0244 18.07C0.342333 16.4883 0.000866667 14.7983 0 13C0 11.2017 0.341467 9.51167 1.0244 7.93C1.70733 6.34833 2.63337 4.9725 3.8025 3.8025C4.9725 2.6325 6.34833 1.70647 7.93 1.0244C9.51167 0.342333 11.2017 0.000866667 13 0C14.7983 0 16.4883 0.341467 18.07 1.0244C19.6517 1.70733 21.0275 2.63337 22.1975 3.8025C23.3675 4.9725 24.294 6.34833 24.9769 7.93C25.6598 9.51167 26.0009 11.2017 26 13C26 14.7983 25.6585 16.4883 24.9756 18.07C24.2927 19.6517 23.3666 21.0275 22.1975 22.1975C21.0275 23.3675 19.6517 24.294 18.07 24.9769C16.4883 25.6598 14.7983 26.0009 13 26Z"
                fill={active ? "#ffffff" : "#464646"}
            />
        </svg>
    );
};

export default HelpIcon;
