import { useState, useEffect } from "react";
import "./institutionLevel.css";
import createLevelImage from "../../../../../../assets/images/createLevelImage.svg";
import alertIcon from "../../../../../../assets/svgs/alertIcon.svg";
import { Table, Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { useSelector } from "react-redux";

import ModalComponent from "../../../../../../component/modal/modal";

import {
  useGetInstitutionLevelsQuery,
  useAddInstitutionLevelMutation,
  useEditInstitutionLevelMutation,
  useDeleteInstitutionLevelMutation,
} from "../../../../../../redux/features/institution/institutionApiSlice";
import ActionIcon from "../actionbutton/ActionIcon.js";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import ToastComponent from "../../../../../../component/toast/toast";

const InstitutionLevel = ({ setActiveStage, institutionDetails }) => {
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [savedLevelsList, setSavedLevelsList] = useState([]);
  const [newData, setNewData] = useState(null);
  const [deletedElementID, setDeletedElementID] = useState(null);
  const [editedElement, setEditedElement] = useState(null);
  const [editingElement, setEditingElement] = useState(null);
  const [editingValue, setEditingValue] = useState("");
  const [elementToDelete, setElementToDelete] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const columns = [
    {
      id: "id",
      label: "ID",
      minWidth: 100,
      sticky: "left",
    },
    {
      id: "name",
      label: "Level Name",
      minWidth: 100,
    },
    {
      id: "code",
      label: "Level Code",
      minWidth: 100,
    },
    {
      id: "action",
      label: "Action",
      minWidth: 100,
    },
  ];

  const tableHeaders = ["ID", "Level Name", "Level Code", "Action"];

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const {
    data: levelsData,
    refetch: levelsRefetch,
    isLoading: levelsLoading,
  } = useGetInstitutionLevelsQuery(instId);

  console.log(levelsData);

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingElement(null);
    setEditingValue("");
  };

  const [addInstitutionLevel, { isLoading: addLoading }] =
    useAddInstitutionLevelMutation();
  const [editInstitutionLevel, { isLoading: editLoading }] =
    useEditInstitutionLevelMutation();
  const [deleteInstitutionLevel, { isLoading: deleteLoading }] =
    useDeleteInstitutionLevelMutation();

  //.......................... actions .........................

  const handleEditLevel = (el) => {
    console.log("edit was clicked for ", el);
    setEditingElement(el);
    setShowModal(true);
    setEditingValue(el.name);
  };

  const confirmDelete = (el) => {
    setElementToDelete(el);
    setShowConfirmDelete(true);
  };

  const handleDeleteLevel = async () => {
    console.log("delete was clicked for ", elementToDelete);
    setShowConfirmDelete(false);
    try {
      const parameters = {
        levelId: elementToDelete?.id,
        body: { name: elementToDelete.name },
      };
      await deleteInstitutionLevel(parameters).unwrap();
      setDeletedElementID(elementToDelete.id);
      
    } catch (error) {
      console.log(error);
      // alert(error.data);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const [newLevelData, setNewLevelData] = useState({
    name: "",
  });

  const { name } = newLevelData;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setNewLevelData({ ...newLevelData, [name]: value });
    setEditingValue(value);
  };

  const handleCreateOrUpdateLevel = async (e, type) => {
    e.preventDefault();

    if (type === "edit") {
      if (editingValue === "") {
        setIsFormNotFilled(true);

        setTimeout(() => {
          setIsFormNotFilled(false);
        }, 3000);

        return;
      }
      // newLevelData.name = editingElement.name
      const parameters = {
        levelId: editingElement?.id,
        body: { name: editingValue },
      };
      try {
        const res = await editInstitutionLevel(parameters);
        console.log(res);
        if (res) {
          setNewLevelData({
            name: "",
          });
          setEditedElement(res);
          setEditingValue("");
          setShowModal(false);
          
        }
        setEditingElement(null);
      } catch (error) {
        console.log(error);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
      return;
    } else {
      if (name === "") {
        setIsFormNotFilled(true);

        setTimeout(() => {
          setIsFormNotFilled(false);
        }, 3000);

        return;
      }
      try {
        console.log(newLevelData);
        const obj = { institutionId: instId, body: newLevelData };

        const data = await addInstitutionLevel(obj).unwrap();

        if (data) {
          setNewData(data);
          
        }
        setNewLevelData({
          name: "",
        });
        setShowModal(false);
      } catch (error) {
        console.log(error.data);
        // setErrorMessage(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
    }
  };

  useEffect(() => {
    if (levelsData) {
      levelsRefetch();
    }
  }, [newData, deletedElementID, editedElement]);

  useEffect(() => {
    const buttonElement = document.getElementById("createLevel");

    // buttonElement && buttonElement.dispatchEvent(new MouseEvent('mouseenter'));
    if (!levelsData || levelsData?.length === 0) {
      buttonElement &&
        buttonElement.dispatchEvent(
          new MouseEvent("mouseover", {
            view: window,
            bubbles: true,
            cancelable: true,
          })
        );
    }
  }, [levelsData]);

  if (levelsLoading || addLoading || deleteLoading || editLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div className="institutionLevel no-scrollbar">
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <h2>
        <b>Set up Your Institution</b>
      </h2>
      <h5>Institution Level</h5>

      {/* delete confirmation modal */}
      <ModalComponent
        showModal={showConfirmDelete}
        closeModal={() => setShowConfirmDelete(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img src={alertIcon} alt="alert Icon" style={{ margin: "0 auto" }} />
          <p
            style={{
              marginTop: "10px",
              marginBottom: "5px",
              fontWeight: "500",
              fontSize: 1.0 + "rem",
            }}
          >
            Are you sure you want to delete this level?{" "}
          </p>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              gap: "10px",
              marginLeft: "30%",
            }}
          >
            <Button
              onClick={() => setShowConfirmDelete(false)}
              type="button"
              variant="outline-primary"
              style={{ borderColor: "#F14810", color: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                No
              </span>
            </Button>
            <Button
              onClick={() => handleDeleteLevel()}
              type="button"
              variant="primary"
              style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Yes
              </span>
            </Button>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        title="Create Level"
        showModal={showModal}
        closeModal={handleCloseModal}
      >
        {isFormNotFilled && (
          <span
            style={{
              color: "red",
              fontStyle: "italic",
              transition: "ease-in-out",
            }}
          >
            Please fill all fields
          </span>
        )}

        <form onSubmit={(e) => e.preventDefault()}>
          <div style={{ marginBottom: "15px" }}>
            <p style={{ marginBottom: "5px", fontWeight: "500" }}>
              Level Name{" "}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </p>
            <input
              type="text"
              placeholder="e.g Primary 1, SS1, 100 Level, etc"
              name="name"
              value={editingElement ? editingValue : name}
              onChange={handleOnChange}
              style={{
                borderRadius: "5px",
                backgroundColor: "#dddddd",
                width: "100%",
              }}
            />
          </div>

          <div style={{ marginTop: "30px" }}>
            {editingElement ? (
              <Button
                onClick={(e) => handleCreateOrUpdateLevel(e, "edit")}
                variant="primary"
                style={{
                  backgroundColor: "#F14810",
                  borderColor: "#F14810",
                  float: "left",
                }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Edit Level
                </span>
              </Button>
            ) : (
              <Button
                onClick={(e) => handleCreateOrUpdateLevel(e, "")}
                variant="primary"
                style={{
                  backgroundColor: "#F14810",
                  borderColor: "#F14810",
                  float: "left",
                }}
                className="button"
                size="md"
                title="Click this button to save the level"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Save Level
                </span>
              </Button>
            )}
          </div>
        </form>
      </ModalComponent>

      <Table
        // responsive
        striped="true"
        size="sm"
        style={{
          fontFamily: "jost, sans-serif",
          fontSize: "14px",
        }}
      >
        <thead>
          <tr align="center">
            {levelsData &&
              levelsData.length > 0 &&
              tableHeaders.map((header, i) => <th key={i}>{header}</th>)}
          </tr>
        </thead>
        <tbody>
          {levelsData &&
            levelsData.length > 0 &&
            levelsData.map((level, i) => (
              <tr key={i} className={i % 2 === 0 ? "even-row" : "odd-row"}>
                <td align="center">{level.id}</td>
                <td align="center">{level.name}</td>
                <td align="center">{level.code}</td>
                <td align="center">
                  <ActionIcon
                    actionsArr={[
                      {
                        label: "Edit",
                        action: () => handleEditLevel(level),
                      },
                      {
                        label: "Delete",
                        action: () => confirmDelete(level),
                      },
                    ]}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      <div style={{ float: "right", marginTop: "20px" }}>
        <OverlayTrigger
          placement="top"
          delay={{ show: 150, hide: 300 }}
          overlay={
            <Tooltip id="button-tooltip">
              Click this button to create a level e.g Primary 1, SS1, 100 Level
              etc
            </Tooltip>
          }
          // trigger={["hover"]}
        >
          <Button
            className="button"
            size="md"
            id="createLevel"
            style={{
              color: "#ffffff",
              border: "none",
              outline: "none",
              backgroundColor: `${
                !levelsData || levelsData?.length === 0 ? "#F14810" : "#777777"
              }`,
            }}
            onClick={() => setShowModal(true)}
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.3 + "rem",
                marginRight: 0.3 + "rem",
              }}
            >
              Create New Level
            </span>
          </Button>
        </OverlayTrigger>
      </div>

      <div className="btns-container">
        <OverlayTrigger
          placement="top"
          delay={{ show: 150, hide: 300 }}
          overlay={
            <Tooltip id="button-tooltip">
              Create a level then click this button to save your changes
            </Tooltip>
          }
        >
          <Button
            onClick={
              !levelsData || levelsData?.length === 0
                ? null
                : () => setActiveStage("Level Category")
            }
            variant="primary"
            style={{
              backgroundColor: `${
                !levelsData || levelsData?.length === 0 ? "#FFCAB8" : "#F14810"
              }`,
              borderColor: `${
                !levelsData || levelsData?.length === 0 ? "#FFCAB8" : "#F14810"
              }`,
              cursor: `${
                !levelsData || levelsData?.length === 0
                  ? "not-allowed"
                  : "pointer"
              }`,
              float: "left",
            }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              Save & Continue
            </span>
          </Button>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default InstitutionLevel;
