import { Button, Col, Form, Row } from "react-bootstrap";
import MultiSelectDropdown from "../../../../../component/select/multiSelectDropdown";
import { useEffect, useState } from "react";
import {
  useCreateInvoiceMutation,
  useEditInvoiceMutation,
  useGetAllClassesQuery,
  useGetAllInstitutionFeesQuery,
  useGetStudentsByCategoriesQuery,
  useGetAllStudentsQuery,
} from "../../../../../redux/features/institution/institutionApiSlice";
import { useGetInvoicesQuery } from "../../../../../redux/features/institution/studentApiSlice";
import { useGetInvoiceForEditQuery } from "../../../../../redux/features/institution/studentApiSlice";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import ToastComponent from "../../../../../component/toast/toast";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addTrailingCommas } from "../../../../../utils/utils";
import Preview from "./preview";
import { storeSpecificInvoiceDetails } from "../../../../../redux/features/institution/institutionSlice";
import ModalComponent from "../../../../../component/modal/modal";
import SetupFeeManagementComponent from "./setupFeeMgtComponent";

const CreateSpecificInvoice = ({ onPeviewInvoiceParamsChange }) => {
  const { invoiceId } = useParams();
  const dispatch = useDispatch();
  const isEditing = !!invoiceId;

  const [specificFee, setSpecificFee] = useState({
    specificType: "",
    specificTitle: "",
    specificDueDate: "",
    specificBranches: [],
    specificFeesIds: [],
    specificSelectedStudentsIds: [],
    specificVatSelected: false,
    specificSendInvoice: false,
    specificCalenderId: "",
  });
  const {
    specificTitle,
    specificDueDate,
    branspecificBranchesches,
    specificFeesIds,
    specificSelectedStudentsIds,
    specificVatSelected,
    specificCalenderId,
  } = specificFee;
  const [selectedSpecificFeeOptions, setSelectedSpecificFeeOptions] = useState(
    []
  );
  const [selectedSpecificStudentOptions, setSelectedSpecificStudentOptions] =
    useState([]);
  const [showApplyToSpecificStudent, setShowApplyToSpecificStudent] =
    useState(false);
  const [applySpecificVAT, setApplySpecificVAT] = useState(false);
  const [selectedClassOptions, setSelectedClassOptions] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [previewInvoiceParams, setPreviewInvoiceParams] = useState({
    feesIds: [],
    selectedStudents: [],
    vatSelected: false,
  });
  const [categoryParamsForStudentList, setCategoryParamsForStudentList] =
    useState([]);
  const [showFeeComponentModal, setShowFeeComponentModal] = useState(false);

  const navigate = useNavigate();
  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const {
    data: feesData,
    refetch: refetchFees,
    isLoading: feesLoading,
  } = useGetAllInstitutionFeesQuery(id);

  const specificFeesOptions = feesData?.map((item) => {
    return { id: item?.id, name: item?.title };
  });

  console.log("specificFee", specificFee);

  const openFeeComponentModal = () => {
    setShowFeeComponentModal(true);
  };

  const closeFeeComponentModal = () => {
    refetchFees();
    setShowFeeComponentModal(false);
  };

  const handleOnSpecificChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // setSpecificFee({ ...specificFee, [name]: value });
    // dispatch(storeSpecificInvoiceDetails(specificFee));

    setSpecificFee((prevSpecificFee) => {
      const updatedSpecificFee = { ...prevSpecificFee, [name]: value };
      // Dispatch the action after updating the state
      dispatch(
        storeSpecificInvoiceDetails({
          ...updatedSpecificFee,
          ...invoiceForEditData,
        })
      );

      return updatedSpecificFee;
    });
  };

  const {
    data: classesData,
    refetch: refetchClasses,
    isLoading: classesLoading,
  } = useGetAllClassesQuery(id);

  // useEffect(() => {
  //   if (classesData) {
  //     refetchClasses();
  //   }
  // }, [classesData]);

  const AllClasses = [
    ...Object.values(classesData || {})?.filter((array) =>
      Array.isArray(array)
    ),
  ].flat();

  const classesOptions = AllClasses?.flatMap((item) =>
    item?.categories?.map((category) => ({
      name: `${item.name} - ${category.name}`,
      id: category.id,
    }))
  );

  const spe_stud_querys = {
    institutionId: id,
    body: { categoryIds: categoryParamsForStudentList },
  };

  const {
    data: studentsData,
    refetch: refetchStudents,
    isLoading: studentsLoading,
  } = useGetStudentsByCategoriesQuery(spe_stud_querys, {
    skip: categoryParamsForStudentList?.length < 1,
  });

  const { data: Allstudents, refetch: refetchAllStudents } =
    useGetAllStudentsQuery(id);

  useEffect(() => {
    if (studentsData && categoryParamsForStudentList?.length > 0) {
      refetchStudents();
    }
  }, [categoryParamsForStudentList]);

  console.log(studentsData);

  const studentsSpecificOptions = studentsData?.map((item) => {
    return {
      id: item?.id,
      name:
        item?.firstname + " " + item?.lastname + " (" + item?.studentNo + ")",
    };
  });

  const handleSpecificFeeOptionsChange = (selected) => {
    setSelectedSpecificFeeOptions(selected);

    const feeIds = selected?.map((item) => {
      return item;
    });

    setSpecificFee((prevSpecificFee) => ({
      ...prevSpecificFee,
      specificFeesIds: feeIds,
    }));

    // Add other details to the preview
    setPreviewInvoiceParams({ ...previewInvoiceParams, feesIds: feeIds });
    onPeviewInvoiceParamsChange({
      // feesIds: [...specificFee?.specificFeesIds, ...feeIds],
      ...previewInvoiceParams,
      feesIds: [...feeIds],
      vatSelected: previewInvoiceParams.vatSelected,
    });

    dispatch(
      storeSpecificInvoiceDetails({ ...specificFee, ...invoiceForEditData })
    );
  };

  console.log(categoryParamsForStudentList);
  console.log(Allstudents);

  const handleOnClassChange = (selected) => {
    setSelectedClassOptions(selected);
    setCategoryParamsForStudentList([...selected]);
  };

  const handleStudentsSpecificOptionsChange = (selected) => {
    setSelectedSpecificStudentOptions(selected);
    // console.log("SelectedSpecificStudentOptions", selected);
    const studentIds = selected?.map((item) => {
      return item;
    });
    // specificFee.specificSelectedStudentsIds = studentIds;
    // console.log("specificFee", specificFee);

    setSpecificFee((prevSpecificFee) => ({
      ...prevSpecificFee,
      specificSelectedStudentsIds: studentIds,
    }));

    setPreviewInvoiceParams({
      ...previewInvoiceParams,
      selectedStudents: studentIds,
    });

    onPeviewInvoiceParamsChange({
      ...previewInvoiceParams,
      selectedStudents: studentIds,
      vatSelected: previewInvoiceParams.vatSelected,
    });

    dispatch(
      storeSpecificInvoiceDetails({ ...specificFee, ...invoiceForEditData })
    );
  };

  // console.log(previewInvoiceParams);

  const handleApplySpecificVATToggle = (e) => {
    const { name, checked } = e.target;
    setApplySpecificVAT(checked);
    setPreviewInvoiceParams({ ...previewInvoiceParams, vatSelected: checked });
    //regularFee.vat = checked;
    specificFee.specificVatSelected = checked;
    console.log("specificFeeObject ", specificFee);
    //refetchInvoicePreview();
    onPeviewInvoiceParamsChange({
      ...previewInvoiceParams,
      vatSelected: checked,
    });
  };

  const { refetch: refetchInvoices } = useGetInvoicesQuery(id);

  const [createIvoice, { isLoading: addLoading }] = useCreateInvoiceMutation();
  const [editInvoice, { isLoading: editLoading }] = useEditInvoiceMutation();

  const handleCreateSpecificInvoice = async () => {
    const params = {
      type: "STUDENT_SPECIFIC",
      title: specificFee?.specificTitle,
      dueDate: specificFee?.specificDueDate,
      branches: [],
      feesIds: specificFee?.specificFeesIds,
      selectedStudentsIds: specificFee?.specificSelectedStudentsIds,
      vat: specificFee?.specificVatSelected,
      sendInvoice: false,
      calenderId: "",
    };

    try {
      let obj;
      let data;

      if (invoiceForEditData) {
        obj = { institutionId: id, invoiceId: invoiceId, body: params };
        data = await editInvoice(obj).unwrap();
      } else {
        obj = { institutionId: id, body: params };
        data = await createIvoice(obj).unwrap();
      }

      if (data) {
        refetchInvoices();
      }

      setSpecificFee({
        specificType: "",
        specificTitle: "",
        specificDueDate: "",
        specificBranches: [],
        specificFeesIds: [],
        specificSelectedStudentsIds: [],
        specificVatSelected: false,
        specificSendInvoice: false,
        specificCalenderId: "",
      });
      setSelectedSpecificFeeOptions([]);
      setSelectedSpecificStudentOptions([]);
      setApplySpecificVAT(false);
      setShowApplyToSpecificStudent(false);
      setShowToast(true);
      setToastMessage("Successful");
      navigate("/invoicing");
    } catch (error) {
      console.log(error.data);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        setToastMessage(error.error);
      }
    }
  };

  const handleCreateAndSendSpecificInvoice = async () => {
    const params = {
      type: "STUDENT_SPECIFIC",
      title: specificFee.specificTitle,
      dueDate: specificFee.specificDueDate,
      branches: [],
      feesIds: specificFee.specificFeesIds,
      selectedStudentsIds: specificFee.specificSelectedStudentsIds,
      vatSelected: specificFee.specificVatSelected,
      sendInvoice: true,
      calenderId: "",
    };

    try {
      let obj;
      let data;
      if (invoiceForEditData) {
        obj = { institutionId: id, invoiceId: invoiceId, body: params };
        data = await editInvoice(obj).unwrap();
      } else {
        obj = { institutionId: id, body: params };
        data = await createIvoice(obj).unwrap();
      }

      if (data) {
        refetchInvoices();
      }

      setSpecificFee({
        specificType: "",
        specificTitle: "",
        specificDueDate: "",
        specificBranches: [],
        specificFeesIds: [],
        specificSelectedStudentsIds: [],
        specificVatSelected: false,
        specificSendInvoice: false,
        specificCalenderId: "",
      });
      //setShowModal(false);
      setSelectedSpecificFeeOptions([]);
      setSelectedSpecificStudentOptions([]);
      setApplySpecificVAT(false);
      setShowApplyToSpecificStudent(false);
      setShowToast(true);
      setToastMessage("Successful");
      navigate("/invoicing");
      console.log(data, "CREATE RESP");
    } catch (error) {
      console.log(error.data);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        setToastMessage(error.error);
      }
    }
  };

  const getinvquerys = {
    institutionId: id ?? 0,
    invoiceId: invoiceId ?? 0,
  };

  const {
    data: invoiceForEditData,
    refetch: refetchInvoiceForEdit,
    isLoading: invoiceForEditLoading,
  } = useGetInvoiceForEditQuery(getinvquerys);

  console.log(invoiceForEditData);
  console.log(specificFee);

  console.log(specificSelectedStudentsIds);
  console.log(studentsSpecificOptions);

  console.log(selectedSpecificStudentOptions);

  useEffect(() => {
    if (invoiceForEditData) {
      // refetchInvoiceForEdit();
      // }

      setApplySpecificVAT(invoiceForEditData?.vatSelected);

      const opt = [];
      const selectedfeeoptions = invoiceForEditData?.fees?.map((item) => {
        return item.id; //[...opt, { value: item.id, label: item.feeComponentName }];
      });

      // specificFee.feesIds = selectedfeeoptions;

      setSpecificFee((prevSpecificFee) => ({
        ...prevSpecificFee,
        feesIds: selectedfeeoptions,
      }));
      // setSelectedFeeOptions(selectedfeeoptions);

      setSelectedSpecificFeeOptions(selectedfeeoptions);

      let studentselectedids = [];
      if (invoiceForEditData?.selectedStudents?.length > 0) {
        const selectedstudentoptions =
          invoiceForEditData?.selectedStudents?.map((item) => {
            return item.id; //[...opt, { value: item.id, label: item.feeComponentName }];
            //return { id: item.id, name: item?.firstname + " " + item?.lastname + " (" + item?.studentNo + ")" }
          });
        // specificFee.selectedStudentsIds = selectedstudentoptions;
        setSpecificFee((prevSpecificFee) => ({
          ...prevSpecificFee,
          selectedStudentsIds: selectedstudentoptions,
        }));
        // setSelectedStudentOptions(selectedstudentoptions);
        setSelectedSpecificStudentOptions(selectedstudentoptions);
        // setShowApplyToStudent(true);
        studentselectedids = selectedstudentoptions;
      }
      const filteredFeesDataList = feesData?.filter((item) =>
        selectedfeeoptions?.includes(item.id)
      );
      console.log(filteredFeesDataList, 8111111111 + "filetered");

      // Extract all institutionCategoryId values into a single array
      const allCategoryIds = filteredFeesDataList?.flatMap((item) => {
        const myClasses = Object.values(item.feeStructureClasses);
        const categoryIds = myClasses.flatMap((myClass) =>
          myClass.map((classItem) => classItem.institutionCategoryId)
        );
        return categoryIds;
      });

      // console.log("regularFeesssss", specificFee);

      setCategoryParamsForStudentList(allCategoryIds);

      setSpecificFee({
        type: invoiceForEditData?.type,
        specificTitle: invoiceForEditData?.title,
        specificDueDate: invoiceForEditData?.dueDate,
        branspecificBranchesches: [],
        specificFeesIds: selectedfeeoptions,
        specificSelectedStudentsIds: studentselectedids,
        specificVatSelected: invoiceForEditData?.vat,
        sendInvoice: false,
        specificCalenderId: "",
      });

      setPreviewInvoiceParams({
        ...previewInvoiceParams,
        vatSelected: invoiceForEditData?.vatSelected,
      });
    }
  }, [invoiceForEditData]);

  useEffect(() => {
    const AllClasses = [
      ...Object.values(classesData || {})?.filter((array) =>
        Array.isArray(array)
      ),
    ].flat();

    const classesOptions = AllClasses?.flatMap((item) =>
      item?.categories?.map((category) => ({
        name: `${item.name} - ${category.name}`,
        id: category.id,
      }))
    );

    // get the classes that was selected before based on the categoryId of the students that are found in the invoiceForEditData

    const selectedClassesIds = invoiceForEditData?.selectedStudents?.map(
      ({ institutionCategory }) => institutionCategory?.id
    );

    console.log(selectedClassesIds);

    const actualSelectedClasses = classesOptions?.filter((cla) =>
      selectedClassesIds?.includes(cla.id)
    );

    console.log(actualSelectedClasses);

    // get all the students that are in these selected classes

    const selectedIds = actualSelectedClasses?.map((cla) => cla.id);
    console.log(selectedIds);

    setSelectedClassOptions(selectedIds);

    const feeIds = invoiceForEditData?.fees?.map((fee) => fee.id);

    setPreviewInvoiceParams({ ...previewInvoiceParams, feesIds: feeIds });
    onPeviewInvoiceParamsChange({
      // feesIds: [...specificFee?.specificFeesIds, ...feeIds],
      ...previewInvoiceParams,
      feesIds: feeIds,
      vatSelected: previewInvoiceParams.vatSelected,
    });

    dispatch(
      storeSpecificInvoiceDetails({ ...specificFee, ...invoiceForEditData })
    );
  }, [invoiceForEditData, classesData]);

  console.log(previewInvoiceParams);

  if (feesLoading || addLoading || classesLoading || editLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <ModalComponent
        title="Create Fee Component"
        showModal={showFeeComponentModal}
        closeModal={closeFeeComponentModal}
        size="lg"
      >
        <form>
          {/* <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo.
          </p> */}
          <SetupFeeManagementComponent
            showFeeMgtModal={setShowFeeComponentModal}
          />

          <Button
            onClick={closeFeeComponentModal}
            variant="primary"
            style={{
              backgroundColor: "#F14810",
              borderColor: "#F14810",
              float: "left",
              marginTop: "20px",
            }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              Close
            </span>
          </Button>
        </form>
      </ModalComponent>

      <div style={{ padding: "20px" }}>
        <Form className="form">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Invoice Title <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="text"
              name="specificTitle"
              value={specificTitle}
              onChange={(e) => handleOnSpecificChange(e)}
              placeholder="Enter Invoice Title"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Fee Structure <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <MultiSelectDropdown
              options={specificFeesOptions || []}
              selectedItems={selectedSpecificFeeOptions}
              onSelect={handleSpecificFeeOptionsChange}
            />
          </Form.Group>

          <div style={{ marginTop: "0px", marginBottom: "12px" }}>
            <span
              style={{
                color: "#F14810",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={openFeeComponentModal}
            >
              + Add Fee
            </span>
          </div>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Invoice Due Date <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="date"
              name="specificDueDate"
              value={specificDueDate}
              onChange={(e) => handleOnSpecificChange(e)}
              placeholder="Select Invoice Due Date"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Class <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <MultiSelectDropdown
              options={classesOptions || []}
              selectedItems={selectedClassOptions}
              onSelect={handleOnClassChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">Student(s)</Form.Label>
            <MultiSelectDropdown
              options={studentsSpecificOptions || []}
              // options={Allstudents || []}
              selectedItems={selectedSpecificStudentOptions}
              onSelect={handleStudentsSpecificOptionsChange}
            />
          </Form.Group>

          {/* <Form.Group className="mb-3" controlId="exampleForm.CheckboxInput">
            <Form.Check
              size="md"
              className="custom-focus"
              style={{
                borderColor: "#F14810",
              }}
              type="switch"
              name="vat"
              value={specificVatSelected}
              onChange={handleApplySpecificVATToggle}
              inline={true}
            />
            <Form.Label className="formClasslabel">Apply VAT (7.5%)</Form.Label>
          </Form.Group> */}
          <Row>
            <Col>
              <Button
                className="button"
                size="md"
                style={{
                  backgroundColor: "#F14810",
                  color: "#ffffff",
                  border: "2px solid",
                  borderColor: "#F14810",
                  outline: "none",
                  fontSize: "14px",
                }}
                onClick={() => handleCreateAndSendSpecificInvoice()}
              >
                {isEditing && <span>Edit & Send Invoice</span>}
                {!isEditing && <span>Create & Send Invoice</span>}
              </Button>
              <Button
                size="md"
                style={{
                  backgroundColor: "#FFF",
                  color: "#F14810",
                  fontSize: "14px",
                  border: "2px solid",
                  borderColor: "#F14810",
                  marginLeft: "10px",
                  textDecoration: "none",
                }}
                onClick={() => handleCreateSpecificInvoice()}
              >
                {isEditing && <span>Edit Invoice</span>}
                {!isEditing && <span>Create Invoice</span>}
              </Button>
            </Col>
          </Row>
        </Form>

        <br />
        <br />
      </div>
    </>
  );
};

export default CreateSpecificInvoice;
