import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DashboardSidebar from "../../components/dashboardSidebar/sidebar";
import { useGetInstitutionQuery } from "../../../../../redux/features/institution/institutionApiSlice";
import { useSelector } from "react-redux";
import DashboardTopSection from "../../components/dashboardTopSection";
import backArrow from "../../../../../assets/svgs/backArrow.svg";
import { Table } from "react-bootstrap";
import {
  useGetInvoiceDetailsQuery,
  useGetOfflinePaymentTypesQuery,
  useMakeOfflinePaymentMutation,
  usePayStudentFeesMutation,
  useSendInvoiceReminderMutation,
  useUpdateStudentInvoiceMutation,
} from "../../../../../redux/features/institution/studentApiSlice";
import { useEffect, useState } from "react";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import { addTrailingCommas, capitalizeWords } from "../../../../../utils/utils";
import ButtonX from "../../../../../component/button/button";
import { useUpdateInvoiceMutation } from "../../../../../redux/features/institution/parentApiSlice";
import ModalComponent from "../../../../../component/modal/modal";
import ToastComponent from "../../../../../component/toast/toast";
import alertIcon from "../../../../../assets/svgs/alert.svg";

const StudentInvoiceDetails = () => {
  const { studentId } = useParams();

  const navigate = useNavigate();
  const [compulsoryFees, setCompulsoryFees] = useState(null);
  const [optionalFees, setOptionalFees] = useState(null);
  const [selectAllCompulsory, setSelectAllCompulsory] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [updatedDetails, setUpdatedDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [offlineAmount, setOfflineAmount] = useState("");
  const [offlinePaymentType, setofflinePaymentType] = useState("");
  const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false);
  const [showConfirmAddMoney, setShowConfirmAddMoney] = useState(false);
  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);


  const { data: institutionDetails, isLoading } = useGetInstitutionQuery(id);
  const {
    data: invoiceDetails,
    isLoading: detailsLoading,
    refetch: refetchInvoiceDetail,
  } = useGetInvoiceDetailsQuery(studentId);

  const {
    data: paymentTypes,
    isLoading: paymentTypesLoading,
    refetch: refetchPaymentTypes,
  } = useGetOfflinePaymentTypesQuery();
  console.log(invoiceDetails);
  const [updateInvoice, { isLoading: updateLoading }] =
    useUpdateInvoiceMutation();

  const [makeOfflinePayment, { isLoading: offlinePaymentLoading }] =
    useMakeOfflinePaymentMutation();

  const [payStudentFees, { isLoading: payFeesLoading }] =
    usePayStudentFeesMutation();

  const [sendInvoiceReminder, { isLoading: sendInvoiceLoading }] =
    useSendInvoiceReminderMutation();

  const [updateStudentInvoice, { isLoading: updateInvoiceLoading }] =
    useUpdateStudentInvoiceMutation();

  const tableHeaders = ["S/N", "Fee Description", "Amount", "Status"];

  useEffect(() => {
    setCompulsoryFees(invoiceDetails?.compulsoryFees);
    setOptionalFees(invoiceDetails?.optionalFees);
  }, [invoiceDetails]);

  const handleOnChangeCompulsory = (feeId) => {
    // reset the select all incase it was first checked then the user unchecks one item
    setSelectAllCompulsory(false);

    console.log("feeId", feeId);

    // change the selected value of the checkbox then send the updated array to the api
    const updatedFees = compulsoryFees?.map((fee) => {
      // if the status is paid, they cant uncheck the box
      if (fee?.id === feeId) {
        if (fee?.paid) {
          return {
            ...fee,
            feeId: fee?.id,
            selected: true,
          };
        }
        return { ...fee, feeId: fee?.id, selected: !fee?.selected };
      } else {
        return { ...fee, feeId: fee?.id, selected: fee?.selected };
      }
    });
    console.log("updatedFees ", updatedFees);

    // setUpdatedFees(updatedFees)
    setCompulsoryFees(updatedFees);
    handleUpdateInvoice(updatedFees);
  };
  const handleAllCheckBoxChangeCompulsory = () => {
    // set all selected to either true or false
    const updatedFees = compulsoryFees?.map((fee) => {
      if (fee?.paid) {
        return {
          ...fee,
          feeId: fee?.id,
          selected: true,
        };
      } else {
        return {
          ...fee,
          feeId: fee?.id,
          selected: !selectAllCompulsory,
        };
      }
    });
    // console.log(updatedFees)
    setSelectAllCompulsory(!selectAllCompulsory);
    // setUpdatedFees(updatedFees);
    setCompulsoryFees(updatedFees);
    handleUpdateInvoice(updatedFees);
  };

  const handleOnChangeOptional = (feeId) => {
    // reset the select all incase it was first checked then the user unchecks one item
    setSelectAll(false);

    console.log("feeId", feeId);

    // change the selected value of the checkbox then send the updated array to the api
    const updatedFees = optionalFees?.map((fee) => {
      // if the status is paid, they cant uncheck the box
      if (fee?.id === feeId) {
        if (fee?.paid) {
          return {
            ...fee,
            feeId: fee?.id,
            selected: true,
          };
        }
        return { ...fee, feeId: fee?.id, selected: !fee?.selected };
      } else {
        return { ...fee, feeId: fee?.id, selected: fee?.selected };
      }
    });
    console.log("updatedFees ", updatedFees);

    // setUpdatedFees(updatedFees)
    setOptionalFees(updatedFees);
    handleUpdateInvoice(updatedFees);
  };

  const handleAllCheckBoxChangeOptional = () => {
    // set all selected to either true or false
    const updatedFees = optionalFees?.map((fee) => {
      if (fee?.paid) {
        return {
          ...fee,
          feeId: fee?.id,
          selected: true,
        };
      } else {
        return {
          ...fee,
          feeId: fee?.id,
          selected: !selectAll,
        };
      }
    });
    // console.log(updatedFees)
    setSelectAll(!selectAll);
    // setUpdatedFees(updatedFees);
    setOptionalFees(updatedFees);
    handleUpdateInvoice(updatedFees);
  };

  const handleUpdateInvoice = async (arr) => {
    console.log("UUUU", [arr]);
    try {
      const updArray = arr?.map((item) => ({
        feeId: item.id,
        selected: item.selected,
      }));
      const parameters = {
        studentInvoiceId: studentId,
        body: updArray,
      };
      const res = await updateStudentInvoice(parameters).unwrap();
      setUpdatedDetails(res);
      console.log(res);
      refetchInvoiceDetail();
    } catch (error) {
      console.log(error.data);
      setToastMessage(error.data.detail);
      setShowToast(true);
    }
  };

  const handleShowOfflinePaymentModal = () => {
    setShowModal(true);
  };

  const handleOnOfflinePaymentAmountEntered = (e) => {
    setOfflineAmount(e.target.value);
  };

  const handleOnOfflinePaymentTypeEntered = (e) => {
    setofflinePaymentType(e.target.value);
  };

  const handleOnSaveOfflinePayment = async () => {
    try {
      const parameters = {
        //accessToken: param,
        body: { studentInvoiceId: studentId, amount: offlineAmount, paymentType: offlinePaymentType },
      };
      const res = await makeOfflinePayment(parameters).unwrap();
      //setUpdatedDetails(res);
      console.log(res);
      //alert(res + " Success")
      setOfflineAmount(0);
      setShowModal(false);
      setToastMessage("Fees Payment Successful");
      setShowToast(true);
      setShowConfirmAddMoney(false);
      refetchInvoiceDetail();
    } catch (error) {
      console.log(error?.data);
      setToastMessage(error?.data?.detail);
      setShowToast(true);
    }
  };

  const handleOnPayStudentFees = async () => {
    setShowConfirmPaymentModal(true);
    // try {
    //   const parameters = {
    //     studentInvoiceId: studentId,
    //     body: { },
    //   };
    //   const res = await payStudentFees(parameters).unwrap();

    //   console.log(res);
    //   //alert(res + " Success")
    //   setOfflineAmount(0);
    //   setShowModal(false);
    //   setToastMessage("₦ " + addTrailingCommas(offlineAmount) + " has been added to student wallet");
    //   setShowToast(true);
    //   refetchInvoiceDetail();
    // } catch (error) {
    //   console.log(error.data);
    //   setToastMessage(error.data.detail);
    //   setShowToast(true);
    // }
  };

  const handleOnSendInvoiceReminder = async () => {
    try {
      const parameters = {
        invoiceId: invoiceDetails.id,
        body: {
          studentInvoiceIds: [studentId],
          sendOnlyEmail: false,
          sendOnlySms: false,
        },
      };
      const res = await sendInvoiceReminder(parameters).unwrap();

      console.log(res);
      setToastMessage("Reminder has been sent");
      setShowToast(true);
      refetchInvoiceDetail();
    } catch (error) {
      console.log(error.data);
      setToastMessage(error.data.detail);
      setShowToast(true);
    }
  };

  const handleCloseConfirmPaymentModal = () => {
    setShowConfirmPaymentModal(false);
  };

  const handleConfirmPayment = async (data) => {
    try {
      if (data) {
        const parameters = {
          studentInvoiceId: studentId,
          body: {},
        };
        const res = await payStudentFees(parameters).unwrap();

        console.log(res);
        //alert(res + " Success")
        setOfflineAmount(0);
        setShowModal(false);
        setToastMessage(
          "₦ " +
          addTrailingCommas(invoiceDetails?.totalAmount) +
          " has been paid"
        );
        setShowToast(true);
        refetchInvoiceDetail();
        setShowConfirmPaymentModal(false);
      } else {
        setShowConfirmPaymentModal(false);
      }
    } catch (error) {
      console.log(error.data);
      setToastMessage(error.data.detail);
      setShowToast(true);
      setShowConfirmPaymentModal(false);
    }
  };
  // fetch student details with their Id
  if (
    detailsLoading ||
    isLoading ||
    updateInvoiceLoading ||
    sendInvoiceLoading ||
    offlinePaymentLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar institutionDetails={institutionDetails} />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />
        <>
          <ToastComponent
            showToast={showToast}
            setShowToast={setShowToast}
            toastMessage={toastMessage}
          />

          <ModalComponent
            title="Make Offline Payment"
            showModal={showModal}
            closeModal={() => {
              setShowModal(false);
              // setAssignInfo({});
              // dispatch(storeCategoryToManage(null));
            }}
          >
            <p>
              <span>To make offline payment, select the offline payment type (e.g. Cash, Transfer, etc) and specify the amount.</span>
              <br />
              <span>This action will increase the student wallet balance.</span>
            </p>
            <Form>
              <div>
                <Form.Label className="formClasslabel">
                  Offline Payment Type{" "}
                  <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
                </Form.Label>
                <Form.Select
                  size="md"
                  className="custom-focus mb-3"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  name="offlinePaymentType"
                  value={offlinePaymentType}
                  onChange={(e) => handleOnOfflinePaymentTypeEntered(e)}
                  required
                >
                  <option value="" disabled selected>
                    Select Payment Type
                  </option>
                  {paymentTypes.map((item, i) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <Form.Label className="formClasslabel">
               Amount{" "}
                <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
              </Form.Label>
              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                  marginBottom: "16px",
                }}
                type="number"
                name="offlineAmount"
                value={offlineAmount}
                onChange={(e) => handleOnOfflinePaymentAmountEntered(e)}
                placeholder="Enter Amount (e.g 50000)"
              />

              <Button
                onClick={() => setShowConfirmAddMoney(true)}
                variant="primary"
                style={{
                  backgroundColor: `${!offlineAmount ? "#FFCAB8" : "#F14810"}`,
                  borderColor: `${!offlineAmount ? "#FFCAB8" : "#F14810"}`,
                  cursor: `${!offlineAmount ? "not-allowed" : "pointer"}`,
                  padding: "6px 18px",
                  fontSize: "14px",
                  marginTop: "20px",
                }}
                className="button"
                size="md"
              >
                Save to Wallet
              </Button>
            </Form>
          </ModalComponent>

          {/*confirm add money to wallet  */}
          <ModalComponent
            showModal={showConfirmAddMoney}
            closeModal={() => {
              setShowConfirmAddMoney(false);
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={alertIcon}
                alt="alert Icon"
                style={{ margin: "0 auto" }}
              />
              <p
                style={{
                  marginTop: "15px",
                  marginBottom: "5px",
                  fontWeight: "500",
                  fontSize: 1.0 + "rem",
                }}
              >
                Are you sure you want to add this amount to wallet?{" "}
              </p>
              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => setShowConfirmAddMoney(false)}
                  type="button"
                  variant="outline-primary"
                  className="button"
                  size="md"
                  style={{
                    fontSize: "14px",
                    padding: "6px 18px",
                    marginTop: "20px",
                    borderColor: "#F14810",
                    color: "#F14810",
                  }}
                >
                  No
                </Button>

                <Button
                  onClick={handleOnSaveOfflinePayment}
                  type="button"
                  variant="primary"
                  style={{
                    fontSize: "14px",
                    padding: "6px 18px",
                    marginTop: "20px",
                    backgroundColor: "#F14810",
                    borderColor: "#F14810",
                    color: "white",
                  }}
                  className="button"
                  size="md"
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalComponent>

          <ModalComponent
            title=""
            showModal={showConfirmPaymentModal}
            closeModal={handleCloseConfirmPaymentModal}
          >
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                justifyContent: "center",
                margin: "30px",
              }}
            >
              <p style={{ fontSize: "16px", fontWeight: "600px" }}>
                Are you sure you want to pay for the selected fees?
              </p>
            </div>
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <ButtonX
                backgroundColor="#FFF"
                border="#f14810"
                color="#f14810"
                onClick={() => handleConfirmPayment(false)}
                overlay={true}
                overlayText="Click to Cancel Payment"
              >
                No
              </ButtonX>
              <ButtonX
                onClick={() => handleConfirmPayment(true)}
                backgroundColor="#F14810"
                overlay={true}
                overlayText="Click to Confirm Payment"
              >
                Yes
              </ButtonX>
            </div>
          </ModalComponent>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
              margin: "20px 0",
            }}
            onClick={() => navigate(-1)}
          >
            <img src={backArrow} alt="Go back" />
            <h6 style={{ marginBottom: 0 }}>Invoicing Details</h6>
            
            <div style={{ gap: "10px", display: 'flex', justifyContent: 'flex-end', float: "right" }}>
              <Button
                
              //onClick={handleGenerateInvoices}
              >
                Offline Payment
              </Button>
              <Button
                
              //onClick={handleGenerateInvoices}
              >
                Send Invoice
              </Button>
            </div>
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <div
              className="back-arrow"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer",
                float: "left",
              }}
              //onClick={() => navigate(`/invoicing/${invoiceDetails.id}`)}
              onClick={() => navigate(-1)}
            >
              <img src={backArrow} alt="Go back" />
              <h6
                style={{
                  marginBottom: 0,
                }}
              >
                Invoice Details for {invoiceDetails?.studentName}
              </h6>
            </div>

            <div style={{ display: "flex", gap: "5px", float: "right" }}>
              <ButtonX
                backgroundColor="#FFF"
                border="#f14810"
                color="#f14810"
                onClick={handleShowOfflinePaymentModal}
                overlay={true}
                overlayText="Add student offline payment amount to student wallet"
              >
                Offline Payment
              </ButtonX>
              <ButtonX
                onClick={handleOnSendInvoiceReminder}
                backgroundColor="#F14810"
                overlay={true}
                overlayText="Send Reminder to student"
              >
                Send Reminder
              </ButtonX>
            </div>
          </div>
          <section style={{ display: "flex", gap: "30px" }}>
            <div style={{ flexBasis: "30%" }}>
              <h6 style={{ fontWeight: 500 }}>Student Details</h6>
              <div style={{ borderRadius: "15px", backgroundColor: "white" }}>
                <div
                  style={{
                    padding: "15px",
                    borderBottom: "1px solid #F3F3F3",
                  }}
                >
                  <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                    {capitalizeWords(invoiceDetails?.studentName)}
                  </h5>
                  <p style={{ marginBottom: 0, color: "#535151" }}>
                    Student Name
                  </p>
                </div>
                <div
                  style={{
                    padding: "15px",
                    borderBottom: "1px solid #F3F3F3",
                  }}
                >
                  <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                    {capitalizeWords(invoiceDetails?.parent?.name)}
                  </h5>
                  <p style={{ marginBottom: 0, color: "#535151" }}>
                    Parent Name
                  </p>
                </div>
                <div
                  style={{
                    padding: "15px",
                    borderBottom: "1px solid #F3F3F3",
                  }}
                >
                  <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                    {invoiceDetails?.studentClass}
                  </h5>
                  <p style={{ marginBottom: 0, color: "#535151" }}>
                    Student Class
                  </p>
                </div>

                <div
                  style={{
                    padding: "15px",
                    borderBottom: "1px solid #F3F3F3",
                  }}
                >
                  <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                    {addTrailingCommas(invoiceDetails?.nuban)}
                  </h5>

                  <p
                    style={{
                      marginBottom: 0,
                      fontSize: "15px",
                      color: "#535151",
                    }}
                  >
                    {addTrailingCommas(invoiceDetails?.bankName)}
                  </p>
                  {/* <p style={{ marginBottom: 0, color: "#535151" }}>Madobi MFB</p> */}
                  {/* <p style={{ marginBottom: 0, color: "#535151" }}>
                    Account Number
                  </p> */}
                </div>
                {/* <div
                  style={{
                    padding: "15px",
                    borderBottom: "1px solid #F3F3F3",
                  }}
                >
                  <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                    {addTrailingCommas(invoiceDetails?.bankName)}
                  </h5>
                  <p style={{ marginBottom: 0, color: "#535151" }}>
                    Bank Name
                  </p>
                  <p style={{ marginBottom: 0, color: "#535151" }}>Madobi MFB</p>
                </div> */}
                <div
                  style={{
                    padding: "15px",
                    borderBottom: "1px solid #F3F3F3",
                  }}
                >
                  <Row>
                    <Col>
                      <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                        ₦ {addTrailingCommas(invoiceDetails?.walletBalance)}
                      </h5>
                      <p style={{ marginBottom: 0, color: "#535151" }}>
                        Wallet Balance
                      </p>
                    </Col>
                    <Col
                      style={{
                        borderLeft: "1px solid #F3F3F3",
                        marginLeft: "60px",
                      }}
                    >
                      <Button
                        className="button"
                        size="md"
                        style={{
                          backgroundColor: "#F14810",
                          color: "#ffffff",
                          border: "none",
                          outline: "none",
                          fontSize: "14px",
                        }}
                        onClick={() => handleOnPayStudentFees()}
                      >
                        Pay Now
                      </Button>
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    padding: "15px",
                    borderBottom: "1px solid #F3F3F3",
                  }}
                >
                  <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                    ₦ {addTrailingCommas(invoiceDetails?.totalAmount)}
                  </h5>
                  <p style={{ marginBottom: 0, color: "#535151" }}>
                    Total Amount
                  </p>
                </div>
                <div
                  style={{
                    padding: "15px",
                    borderBottom: "1px solid #F3F3F3",
                  }}
                >
                  {/* <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                    {invoiceDetails?.paid ? "Paid" : "Not Paid"}{" "}
                  </h5> */}
                  <Row>
                    <Col>
                      {invoiceDetails?.paid && (
                        <h5
                          style={{
                            marginBottom: 0,
                            fontSize: "18px",
                            color: "#32CD32",
                          }}
                        >
                          Paid{" "}
                        </h5>
                      )}
                      {!invoiceDetails?.paid && (
                        <h5
                          style={{
                            marginBottom: 0,
                            fontSize: "18px",
                            color: "#FF0000",
                          }}
                        >
                          Not Paid{" "}
                        </h5>
                      )}
                      <p style={{ marginBottom: 0, color: "#535151" }}>
                        Status
                      </p>
                    </Col>
                    <Col style={{ borderLeft: "1px solid #F3F3F3" }}>
                      <h5 style={{ marginBottom: 0, fontSize: "18px" }}>
                        {invoiceDetails?.invoiceDate}
                      </h5>
                      <p style={{ marginBottom: 0, color: "#535151" }}>
                        Due Date
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <div style={{ flexBasis: "70%" }}>
              <h6 style={{ fontWeight: 500, color: "#F14810" }}>
                Compulsory Fees
              </h6>

              <Table
                // responsive
                striped
                size="sm"
                style={{
                  fontFamily: "jost, sans-serif",
                  fontSize: "14px",
                }}
              >
                <thead>
                  <tr align="center" style={{ backgroundColor: "white" }}>
                    <th style={{ position: "relative" }}>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={selectAllCompulsory}
                          onChange={handleAllCheckBoxChangeCompulsory}
                          // disable the check box if they have already paid for all the available optional fees
                          disabled={
                            compulsoryFees
                              ?.map((fee) => fee?.paid != true)
                              .includes(true)
                              ? false
                              : true
                          }
                        />
                        <span
                          className={`custom-checkbox ${compulsoryFees
                            ?.map((fee) => fee?.paid != true)
                            .includes(true)
                            ? null
                            : "paid-checkbox"
                            }`}
                        ></span>
                      </label>
                    </th>
                    {tableHeaders.map((header, i) => (
                      <th key={i}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {compulsoryFees &&
                    compulsoryFees.length > 0 &&
                    compulsoryFees.map((fee, i) => (
                      <tr
                        key={i}
                        className={i % 2 === 0 ? "even-row" : "odd-row"}
                      >
                        <td align="center" style={{ position: "relative" }}>
                          <label className="checkbox-container">
                            <input
                              type="checkbox"
                              checked={fee?.selected ? true : fee?.paid}
                              onChange={() => handleOnChangeCompulsory(fee?.id)}
                              disabled={fee?.paid}
                            />
                            <span
                              className={`custom-checkbox ${fee?.paid ? "paid-checkbox" : null
                                }`}
                            ></span>
                          </label>
                        </td>
                        <td align="center">{i + 1}</td>
                        <td align="center">{fee.feeDescription}</td>
                        <td align="center">{addTrailingCommas(fee.amount)}</td>
                        <td align="center">
                          {fee.paid && (
                            <span style={{ color: "#32CD32" }}>Paid</span>
                          )}
                          {!fee.paid && (
                            <span style={{ color: "#FF0000" }}>Not Paid</span>
                          )}
                          {/* {fee.paid ? "Paid" : "Not Paid"} */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>

              <h6 style={{ textAlign: "right" }}>
                Sub Total:{" "}
                <span style={{ fontWeight: 600 }}>
                  ₦ {addTrailingCommas(invoiceDetails?.compulsoryFeeTotal)}
                </span>
              </h6>

              <h6 style={{ fontWeight: 500, color: "#757575" }}>
                Optional Fees
              </h6>

              <Table
                // responsive
                striped
                size="sm"
                style={{
                  fontFamily: "jost, sans-serif",
                  fontSize: "14px",
                }}
              >
                <thead>
                  <tr align="center" style={{ backgroundColor: "white" }}>
                    <th style={{ position: "relative" }}>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleAllCheckBoxChangeOptional}
                          // disable the check box if they have already paid for all the available optional fees
                          disabled={
                            optionalFees
                              ?.map((fee) => fee?.paid != true)
                              .includes(true)
                              ? false
                              : true
                          }
                        />
                        <span
                          className={`custom-checkbox ${optionalFees
                            ?.map((fee) => fee?.paid != true)
                            .includes(true)
                            ? null
                            : "paid-checkbox"
                            }`}
                        ></span>
                      </label>
                    </th>
                    {tableHeaders.map((header, i) => (
                      <th key={i}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {optionalFees &&
                    optionalFees.length > 0 &&
                    optionalFees.map((fee, i) => (
                      <tr
                        key={i}
                        className={i % 2 === 0 ? "even-row" : "odd-row"}
                      >
                        <td align="center" style={{ position: "relative" }}>
                          <label className="checkbox-container">
                            <input
                              type="checkbox"
                              checked={fee?.selected ? true : fee?.paid}
                              onChange={() => handleOnChangeOptional(fee?.id)}
                              disabled={fee?.paid}
                            />
                            <span
                              className={`custom-checkbox ${fee?.paid ? "paid-checkbox" : null
                                }`}
                            ></span>
                          </label>
                        </td>
                        <td align="center">{i + 1}</td>
                        <td align="center">{fee.feeDescription}</td>
                        <td align="center">{addTrailingCommas(fee.amount)}</td>
                        <td align="center">
                          {fee.paid && (
                            <span style={{ color: "#32CD32" }}>Paid</span>
                          )}
                          {!fee.paid && (
                            <span style={{ color: "#FF0000" }}>Not Paid</span>
                          )}
                          {/* {fee.paid ? "Paid" : "Not Paid"} */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>

              <h6 style={{ textAlign: "right" }}>
                Sub Total:{" "}
                <span style={{ fontWeight: 600 }}>
                  ₦ {addTrailingCommas(invoiceDetails?.optionalFeeTotal)}
                </span>
              </h6>

              {/* <h6
                style={{
                  textAlign: "right",
                  color: "#F14810",
                  marginTop: "50px",
                  fontSize: "20px",
                }}
              >
                Total:{" "}
                <span style={{ fontWeight: 600, color: "#000000" }}>
                  ₦ {addTrailingCommas(invoiceDetails?.totalAmount)}
                </span>
              </h6> */}
            </div>
          </section>

          <br />
          <br />
        </>
      </Container>
    </Container>
  );
};

export default StudentInvoiceDetails;
