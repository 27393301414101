import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { addTrailingCommas } from "../../../../../../utils/utils";
import { useEffect } from "react";
import dropdownIcon from "../../../../../../assets/svgs/dropdownIcon.svg";
import Button from "../../../../../../component/button/button";
import {
  useGetGlobalCommissionQuery,
  useGetInstitutionSettlementListQuery,
  useApplyCommissionMutation,
  useGetInstitutionListQuery,
  useSetReminderChargesMutation,
  useGetAllInstitutionListQuery,
} from "../../../../../../redux/features/superAdmin/adminApiSlice";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import SuccessScreen from "../../../../../../component/successScreen/successScreen";
import ToastComponent from "../../../../../../component/toast/toast";
import MultiSelectDropdown from "../../../../../../component/select/multiSelectDropdown";

const SetReminderChargeCanvas = ({ OnsetShowRateCanvas }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showSchoolsDropdown, setShowSchoolsDropdown] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsNames, setSelectedItemsNames] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showRateCanvas, setShowRateCanvas] = useState(false);

  const [selectedSchoolOptions, setSelectedSchoolOptions] = useState([]);

  // const [chargeSMSValue, setChargeSMSValue] = useState(0);
  // const [chargeEmailValue, setChargeEmailValue] = useState(0);
  const [reminderCharge, setReminderCharge] = useState({
    emailCharge: 0,
    smsCharge: 0,
    institutionIds: [],
  });
  const { emailCharge, smsCharge } = reminderCharge;

  const {
    data: institutionsList,
    isLoading: institutionsLoading,
    refetch: refetchInstitutions,
  } = useGetAllInstitutionListQuery();
  console.log(institutionsList, "institutionsList");

  const schoolOptions = institutionsList?.map((item) => {
    return { id: item?.id, name: item?.institutionName };
  });

  const [createCharges, { isLoading: createChargeLoading }] =
    useSetReminderChargesMutation();

  const handleItemClick = (obj) => {
    if (selectedItems.includes(obj.id)) {
      setSelectedItems(selectedItems.filter((i) => i !== obj.id));
      setSelectedItemsNames(
        selectedItemsNames.filter((i) => i !== obj.institutionName)
      );
      reminderCharge.institutionIds = reminderCharge.institutionIds.filter(
        (id) => id !== obj.id
      );
    } else {
      setSelectedItems([...selectedItems, obj.id]);
      setSelectedItemsNames([...selectedItemsNames, obj.institutionName]);
      reminderCharge.institutionIds = [...selectedItems, obj.id];
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
      setSelectedItemsNames([]);
      reminderCharge.institutionIds = [];
    } else {
      const allItemIds = institutionsList?.map((category) => category.id);
      setSelectedItems(allItemIds);
      setSelectedItemsNames(
        institutionsList?.map((category) => category.institutionName)
      );
      reminderCharge.institutionIds = allItemIds;
    }
    setSelectAll(!selectAll);
  };

  const handleSchoolsOptionsChange = (selected) => {
    setSelectedSchoolOptions(selected);
    console.log(selected, 77777);
    const schoolIds = selected?.map((item) => {
      return item;
    });
    reminderCharge.institutionIds = schoolIds;
    console.log(reminderCharge, 66666);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setReminderCharge({ ...reminderCharge, [name]: value });
  };
  console.log("reminderCharge", reminderCharge);

  const handleSetReminderCharges = async () => {
    const params = {
      body: reminderCharge,
    };

    console.log(params);
    try {
      const res = await createCharges(params).unwrap();
      console.log(res);
      setShowToast(true);
      setToastMessage("Reminder Charge Added successfully!");
      setReminderCharge({
        smsCharge: 0,
        emailCharge: 0,
        institutionIds: [],
      });
      setSelectedSchoolOptions([]);
      //   OnsetShowRateCanvas(false);
    } catch (error) {
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  if (institutionsLoading || createChargeLoading) {
    return <OnboardingLoader />;
  }

  // if (applyCommissionSuccess) {
  //     return (
  //         <SuccessScreen successMessage="Rate has been applied successfully" />
  //     );
  // }

  return (
    <form onSubmit={(e) => e.preventDefault()} className="commission-canvas">
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <p style={{ marginTop: "30px" }}>
        Configure reminder charges for schools to cover SMS and email notifications.
        Choose the amount per message for each type of reminder.
      </p>

      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className="formClasslabel">School List</Form.Label>

        <MultiSelectDropdown
          selectedItems={selectedSchoolOptions}
          options={schoolOptions}
          onSelect={handleSchoolsOptionsChange}
        />
      </Form.Group>

      <div id="SMS" style={{ marginTop: "40px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h6 style={{ marginBottom: "10px", fontSize: 0.9 + "rem" }}>
            SMS (Amount per Message)
          </h6>
        </div>
        <div style={{ display: "flex", gap: "15px", marginBottom: "30px" }}>
          <div
            style={{
              fontSize: 0.8 + "rem",
              fontFamily: "jost, sans-serif",
              backgroundColor: "#ededed",
              width: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#484d45",
              padding: "5px 10px",
            }}
          >
            NGN
          </div>
          <input
            type="number"
            name="smsCharge"
            value={smsCharge}
            onChange={(e) => handleOnChange(e)}
            placeholder="Enter rate in amount e.g 5000"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
            }}
          />
        </div>
      </div>
      <div id="Email">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h6 style={{ marginBottom: "10px", fontSize: 0.9 + "rem" }}>
            Email (Amount per Message)
          </h6>
        </div>
        <div style={{ display: "flex", gap: "15px", marginBottom: "30px" }}>
          <div
            className="custom-focus"
            style={{
              fontSize: 0.8 + "rem",
              fontFamily: "jost, sans-serif",
              backgroundColor: "#ededed",
              width: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#484d45",
              padding: "5px 10px",
            }}
          >
            NGN
          </div>
          <input
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
            }}
            type="number"
            name="emailCharge"
            value={emailCharge}
            onChange={(e) => handleOnChange(e)}
            placeholder="Enter rate in amount e.g 5000"
          //style={{ backgroundColor: "#ededed" }}
          />
        </div>
      </div>

      <Button
        onClick={
          selectedSchoolOptions?.length === 0 || !emailCharge || !smsCharge
            ? null
            : () => handleSetReminderCharges()
        }
        backgroundColor={`${selectedSchoolOptions?.length === 0 || !emailCharge || !smsCharge
          ? "#FFCAB8"
          : "rgb(241, 72, 16)"
          }`}
        cursor={`${selectedSchoolOptions?.length === 0 || !emailCharge || !smsCharge
          ? "not-allowed"
          : "pointer"
          }`}
      >
        Save Changes
      </Button>
    </form>
  );
};

export default SetReminderChargeCanvas;
