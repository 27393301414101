// import Button from "../../../../../component/button/button";
import { Button } from "react-bootstrap";
import institutionLogo from "../../../../../assets/svgs/institutionPlaceholderLogo.svg";
import { SketchPicker } from "react-color";
import {
  useGetInstitutionLogoQuery,
  useGetInstitutionQuery,
  useUpdateInstitutionDetailsMutation,
} from "../../../../../redux/features/institution/institutionApiSlice";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import ToastComponent from "../../../../../component/toast/toast";
import ModalComponent from "../../../../../component/modal/modal";
import successGIF from "../../../../../assets/svgs/successGIF.gif";
import placeholderLogo from "../../../../../assets/svgs/institutionPlaceholderLogo.svg";
import { storeLogoChange } from "../../../../../redux/features/institution/institutionSlice";
import { Link } from "react-router-dom";
import { storeBrandingDetails } from "../../../../../redux/features/institution/institutionSlice";

const Appearance = () => {
  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const dispatch = useDispatch();

  const {
    data: institutionDetails,
    isLoading: detailsLoading,
    refetch: refetchDetails,
  } = useGetInstitutionQuery(id);

  console.log(institutionDetails);

  // remove # from the brandColour
  let newColor = institutionDetails?.brandColour?.replace("#", "");
  // console.log(newColor);

  const handleCloseModal = () => {
    setShowModal(false);
    refetchDetails();
  };

  const [chosenColor, setChosenColor] = useState(
    // institutionDetails?.brandColour
    newColor
  );

  const [showModal, setShowModal] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // console.log(chosenColor)

  const { data: apiInstitutionLogo, isLoading: logoLoading } =
    useGetInstitutionLogoQuery(id);

  const [chosenImage, setChosenImage] = useState(
    apiInstitutionLogo ? apiInstitutionLogo?.logo : ""
  );

  const [updateInstitutionDetails, { isLoading: updateLoading }] =
    useUpdateInstitutionDetailsMutation();

  // const [updateInstitionBranding, { isLoading: updateBrandingLoading }] =
  //   useUpdateInstitutionMutation();

  const [appearanceDetails, setAppearanceDetails] = useState({
    // brandColour: institutionDetails?.brandColour,
    brandColour: chosenColor,
    logo: apiInstitutionLogo?.logo,
  });

  const { brandColour, logo } = appearanceDetails;

  // console.log(logo);

  const handleColorChange = (color) => {
    setChosenColor(color?.hex);
    // institutionDetails.brandColour = color?.hex;
    newColor = color?.hex;
  };

  const handleOnImageChange = (e) => {
    let selectedFile = e.target.files;

    if (selectedFile.length > 0) {
      let actualFile = selectedFile[0];
      let fileName = actualFile.name;
      const reader = new FileReader();

      reader.onload = (event) => {
        const imageDetails = {
          name: fileName,
          imageUrl: event.target.result,
        };
        setChosenImage(event.target.result);
      };
      reader.readAsDataURL(actualFile);
    }
  };

  // console.log(chosenImage);

  dispatch(storeLogoChange(showModal));

  const handleSave = async () => {
    const params = {
      institutionId: id,
      body: {
        brandColour: chosenColor,
        logo: chosenImage,
      },
    };

    try {
      const res = await updateInstitutionDetails(params).unwrap();
      console.log(res);
      dispatch(
        storeBrandingDetails({
          institutionBranding: { brandColour: chosenColor, logo: chosenImage },
        })
      );
      setShowModal(true);
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  useEffect(() => {
    setChosenColor(newColor);

    if (apiInstitutionLogo) {
      setChosenImage(apiInstitutionLogo?.logo);
    }
  }, []);

  if (logoLoading || updateLoading || detailsLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <ModalComponent showModal={showModal} closeModal={handleCloseModal}>
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!</h3>
          <p style={{ fontSize: "18px" }}>
            Institution Appearance Details created successfully
          </p>
        </div>
      </ModalComponent>

      <h6 style={{ fontSize: "14px", fontWeight: 600 }}>Appearance</h6>
      <div
        style={{
          border: "1px solid #F5F5F5",
          minHeight: "95%",
          borderRadius: "8px",
          padding: "15px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: "20px",
        }}
        className="appearance-container"
      >
        <div
          style={{ flexBasis: "40%", marginBottom: "20px" }}
          className="left-box-in-settings"
        >
          <h6>Change Appearance</h6>
          <p>
            Edit the institution appearance (logo and branding color) provided
            at the onboarding stage here. Clicking the "Save changes" button
            will update the existing information{" "}
          </p>

          <p style={{ fontWeight: "500", margin: "5px 0" }}>
            Click the button below to watch how to edit your set-up.
          </p>

          <Link to="/help">
            <span style={{ color: "#F14810", fontWeight: "600" }}>
              Watch Video
            </span>
          </Link>
        </div>

        <form
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-evenly",
            gap: "20px",
            flexBasis: "60%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              flexShrink: 0,
            }}
          >
            <div
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "100%",
                overflow: "hidden",
              }}
            >
              {/* <img src={institutionLogo} alt="institution Placeholder Logo" /> */}
              <img
                src={chosenImage ? chosenImage : placeholderLogo}
                alt="Logo"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <label
              htmlFor="uploadLogo"
              style={{
                backgroundColor: "#e7e7e7",
                padding: "5px 15px",
                borderRadius: "5px",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              Upload Logo
            </label>
            <input
              type="file"
              id="uploadLogo"
              style={{ visibility: "hidden", display: "none" }}
              name="institutionBranding.logo"
              // value={institutionBranding.logo}
              onChange={handleOnImageChange}
            />
          </div>
          <div>
            <span>Select Color</span>
            <SketchPicker
              color={chosenColor}
              value={institutionDetails.brandColour}
              name="institutionDetails.brandColour"
              onChange={handleColorChange}
              onClick={() => handleColorChange(chosenColor)}
            />

            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginTop: "50px",
              }}
            >
              <Button
                className="button"
                size="md"
                style={{
                  backgroundColor: "#F14810",
                  color: "#ffffff",
                  border: "none",
                  outline: "none",
                }}
                onClick={handleSave}
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.3 + "rem",
                    marginRight: 0.3 + "rem",
                  }}
                >
                  Save Changes
                </span>
              </Button>
            </div>

            {/* <div style={{ marginTop: "50px", float: "right" }}>
              <Button>Save Changes</Button>
            </div> */}
          </div>
        </form>
      </div>
    </>
  );
};

export default Appearance;
