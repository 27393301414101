import "./uploadCredentials.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ButtonX from "../../../../../component/button/button";
import uploadIcon from "../../../../../assets/svgs/uploadIcon.svg";
import alertIcon from "../../../../../assets/svgs/alertIcon.svg";
import {
  useGetDocumentCategoriesQuery,
  useGetInstitutionDocumentListQuery,
  useGetInstitutionDocumentQuery,
  useDeleteDocumentMutation,
  useEditDocumentMutation,
  useUpdateInstitutionMutation,
} from "../../../../../redux/features/institution/institutionApiSlice";

import { storeCredentials } from "../../../../../redux/features/institution/institutionSlice";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../../../../../component/modal/modal";
import { Table, Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import actionIcon from "../../../../../assets/svgs/institutionLevelActionIcon.svg";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import ActionIcon from "../actionbutton/ActionIcon";
import ToastComponent from "../../../../../component/toast/toast";

const tableHeaders = [
  "ID",
  "Document Name",
  "Document Category",
  "Action Icon",
];

const UploadCredentials = ({ setActiveStage }) => {
  //  get details if they have filled this page before
  const credentials = useSelector((state) => state.institution.credentials);

  const [chosenDocument, setChosenDocument] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [isCredentialNotUploaded, setIsNotCredentialUploaded] = useState(false);
  const [isNotPDF, setIsNotPDF] = useState(false);
  const [allCredentials, setAllCredentials] = useState([]);
  const [deletedElementID, setDeletedElementID] = useState(null);
  const [editedElementID, setEditedElementID] = useState(null);
  const [editingElement, setEditingElement] = useState(null);
  const [editingValue, setEditingValue] = useState({});
  const [newDocList, setNewDocList] = useState([]);
  const [elementToDelete, setElementToDelete] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingElement(null);
    setEditingValue({
      editedDescription: "",
      editedCategory: "",
      editedCategoryId: "",
      editedContent: "",
    });
  };

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateInstitutionMutation, { isLoading, isSuccess }] =
    useUpdateInstitutionMutation();

  const [deleteDocument, { isLoading: deleteLoading }] =
    useDeleteDocumentMutation();
  const [editDocument, { isLoading: editLoading }] = useEditDocumentMutation();

  const {
    data: docList,
    refetch: refetchDOCS,
    isLoading: docListLoading,
  } = useGetInstitutionDocumentListQuery(instId);
  const { data: documentCategories, isLoading: documentCategoriesLoading } =
    useGetDocumentCategoriesQuery(undefined, { skip: !showModal });

  const [newCredentialInfo, setNewCredentialInfo] = useState({
    documentCategory: "",
    documentCategoryId: "",
    content: "",
    description: "",
  });

  const { documentCategory, content, description, documentCategoryId } =
    newCredentialInfo;

  const [savedCredentials, setSavedCredentials] = useState(
    credentials ? credentials : []
  );

  // console.log(savedCredentials);
  // console.log(allCredentials);
  // console.log(documentCategories);

  useEffect(() => {
    const newDocList = docList?.map((obj) => {
      const { documentCategory, ...rest } = obj;

      return {
        ...rest,
        documentCategoryId: documentCategory.id,
        documentCategory: documentCategory.name,
      };
    });

    setNewDocList(newDocList);
  }, [docList]);

  console.log(docList);
  console.log(newDocList);
  console.log(savedCredentials);
  console.log(allCredentials);

  useEffect(() => {
    if (newDocList) {
      // setAllCredentials([...docList, ...savedCredentials]);
      setAllCredentials([...newDocList, ...savedCredentials]);
    } else {
      setAllCredentials([...savedCredentials]);
    }
    refetchDOCS();
  }, [
    docList,
    newDocList,
    savedCredentials,
    deletedElementID,
    editedElementID,
  ]);

  const handleOnChange = (e) => {
    const { name, value, type } = e.target;

    setNewCredentialInfo({ ...newCredentialInfo, [name]: value });
    setEditingValue({ ...editingValue, [name]: value });

    if (type === "file") {
      let selectedFile = e.target.files;

      if (selectedFile.length > 0) {
        let actualFile = selectedFile[0];

        // check if its a pdf
        if (actualFile.type === "application/pdf") {
          setIsNotPDF(false);

          console.log("This is a pdf file");
          let fileName = actualFile.name;
          const reader = new FileReader();

          reader.onload = (event) => {
            const docDetails = {
              name: fileName,
              docUrl: event.target.result,
            };
            // console.log(docDetails)
            setChosenDocument(docDetails);
          };
          reader.readAsDataURL(actualFile);
        } else {
          console.log("This is not a pdf file");
          setIsNotPDF(true)
        }
      }
    }

    if (name === "documentCategory") {
      const category = documentCategories?.filter(
        (category) => category.id == value
      );
      if (category) {
        setNewCredentialInfo({
          ...newCredentialInfo,
          documentCategory: category[0].name,
          documentCategoryId: category[0].id,
        });

        console.log(documentCategory);
      }
    }
  };

  // console.log(allCredentials);
  // console.log(savedCredentials);
  // console.log(editingValue)

  // store the credentials if they leave this page to the prev 
  const handleGoToPrevPage = () => {
    // setActiveStage("Contact Person"); 
    setActiveStage("Institution Branding");
    dispatch(storeCredentials(savedCredentials));
  };

  const handleEditCredential = (el) => {
    console.log("edit was clicked for ", el);
    setShowModal(true);
    setEditingElement(el);
    setEditingValue({
      editedDescription: el.description,
      editedCategory: el.documentCategory,
      editedCategoryId: el.documentCategoryId,
      editedContent: el.content,
    });
  };

  const confirmDelete = (el) => {
    setElementToDelete(el);
    setShowConfirmDelete(true);
  };

  const handleDeleteCredential = async () => {
    console.log("delete was clicked for ", elementToDelete);
    setShowConfirmDelete(false);

    // backend credentials
    if (elementToDelete.id) {
      try {
        const parameters = {
          institutionId: instId,
          documentId: elementToDelete.id,
        };
        await deleteDocument(parameters).unwrap();
        setDeletedElementID(elementToDelete.id);
        const newArr = allCredentials.filter(
          (credential) => credential.description !== elementToDelete.description
        );
        setAllCredentials(newArr);
      } catch (error) {
        // console.log(error);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
    } else {
      const newArr = savedCredentials.filter(
        (credential) => credential.description !== elementToDelete.description
      );
      setSavedCredentials(newArr);
    }
  };

  const saveorEditCredential = async (e, type) => {
    e.preventDefault();

    if (type === "edit") {
      // edit for backend document
      if (editingElement.id) {
        const parameters = {
          institutionId: instId,
          documentId: editingElement.id,
          body: {
            documentCategory: editedCategoryId,
            content: editedContent,
            description: editedDescription,
            type: "CREDENTIAL",
          },
        };

        try {
          const res = await editDocument(parameters).unwrap();
          console.log(res);
          setEditedElementID(editingElement.id);
          setShowModal(false);
        } catch (error) {
          // alert(error.data.detail);
          setShowToast(true);
          if (error?.data?.detail) {
            setToastMessage(error.data.detail);
          } else {
            // other errors
            setToastMessage(error.error);
          }
        }
      } else {
        if (!editedCategory || !editedDescription || !editedContent) {
          setIsFormNotFilled(true);

          setTimeout(() => {
            setIsFormNotFilled(false);
          }, 3000);

          return;
        }

        const updatedCredentials = savedCredentials.map((credential) => {
          if (credential.description === editingElement.description) {
            return {
              ...credential,
              description: editedDescription,
              documentCategory: editedCategory,
              documentCategoryId: editedCategoryId,
              content: editedContent,
            };
          }

          return credential;
        });

        // Update the state with the updatedCredentials array
        setSavedCredentials(updatedCredentials);
        setShowModal(false);
      }
    } else {
      if (content === "" || documentCategory === "" || description === "") {
        setIsFormNotFilled(true);

        setTimeout(() => {
          setIsFormNotFilled(false);
        }, 3000);

        return;
      }

      setShowModal(false);
      // create a object with the fields needed for credential then push it into the credentials array
      setSavedCredentials([
        ...savedCredentials,
        { ...newCredentialInfo, content: chosenDocument.docUrl },
      ]);

      // reset the modal fields to empty
      setNewCredentialInfo({
        documentCategory: "",
        documentCategoryId: "",
        content: "",
        description: "",
      });
      setChosenDocument(null);
    }
  };

  const aboutContactPerson = useSelector(
    (state) => state.institution.contactPersonDetails
  );

  // console.log(aboutContactPerson);

  // get all data from previous pages and merge with this one in the format the backend wants
  const institutionDetails = useSelector(
    (state) => state.institution.institutionDetails
  );
  const contactPersonDetails = useSelector(
    (state) => state.institution.contactPersonDetails
  );
  const brandingDetails = useSelector(
    (state) => state.institution.brandingDetails
  );

  const { editedCategory, editedCategoryId, editedContent, editedDescription } =
    editingValue;

  // replace the country and state names with their IDS which is what the backend needs
  // const { selectedCountryId, selectedStateId, ...rest } = institutionDetails;

  // const institutionDetailsWithIDS = {
  //   ...rest,
  //   country: institutionDetails.selectedCountryId,
  //   state: institutionDetails.selectedStateId,
  // };

  const onSubmit = async () => {
    if (allCredentials.length < 1) {
      setIsNotCredentialUploaded(true);

      setTimeout(() => {
        setIsNotCredentialUploaded(false);
      }, 3000);

      return;
    }

    try {
      // const submitData = {
      //   // ...institutionDetailsWithIDS,
      //   ...brandingDetails,
      //   // ...contactPersonDetails,
      //   institutionCredentials: allCredentials,
      // };

      // console.log(submitData);
      // // go to validation page
      // const res = await updateInstitutionMutation({
      //   ...submitData,
      // }).unwrap();

      // if (res) {
      //   console.log(res);
      //   navigate("/setup-institution/preview-application");
      // } 
      dispatch(storeCredentials(savedCredentials));
      navigate("/setup-institution/preview-application");
    } catch (error) {
      console.log(error);
      // setErrorMessage(error.data)
      // alert(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  if (
    isLoading ||
    deleteLoading ||
    editLoading ||
    docListLoading ||
    documentCategoriesLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <div className="upload-credentials no-scrollbar">
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <h2>Set Up Your Institution</h2>
      <p>Upload Credentials</p>

      {/* delete confirmation modal */}
      <ModalComponent
        showModal={showConfirmDelete}
        closeModal={() => setShowConfirmDelete(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img src={alertIcon} alt="alert Icon" style={{ margin: "0 auto" }} />
          <p
            style={{
              marginTop: "10px",
              marginBottom: "5px",
              fontWeight: "500",
              fontSize: 1.0 + "rem",
            }}
          >
            Are you sure you want to delete this credential?{" "}
          </p>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              gap: "10px",
              marginLeft: "30%",
            }}
          >
            <Button
              onClick={() => setShowConfirmDelete(false)}
              type="button"
              variant="outline-primary"
              style={{ borderColor: "#F14810", color: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                No
              </span>
            </Button>
            <Button
              onClick={() => handleDeleteCredential()}
              type="button"
              variant="primary"
              style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Yes
              </span>
            </Button>
          </div>
        </div>
      </ModalComponent>

      {isCredentialNotUploaded && (
        <p
          style={{
            color: "red",
            fontStyle: "italic",
            transition: "ease-in-out",
          }}
        >
          Please upload credential
        </p>
      )}

      <ModalComponent
        title="Upload Credential"
        showModal={showModal}
        closeModal={handleCloseModal}
      >
        {isFormNotFilled ? (
          <span
            style={{
              color: "red",
              fontStyle: "italic",
              transition: "ease-in-out",
            }}
          >
            Please fill all fields
          </span>
        ) : null}
        {isNotPDF ? (
          <span
            style={{
              color: "red",
              fontStyle: "italic",
              transition: "ease-in-out",
            }}
          >
            Please upload a PDF document
          </span>
        ) : null}

        <form className="school-credentials">
          <p>
            File Name{" "}
            <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
          </p>
          <input
            type="text"
            // name="description"
            // value={description}
            name={editingElement ? "editedDescription" : "description"}
            value={editingElement ? editedDescription : description}
            onChange={handleOnChange}
            placeholder="Enter File Name"
            style={{
              borderRadius: "5px",
              backgroundColor: "#ededed",
              width: "100%",
            }}
          />
          <p>
            File Category{" "}
            <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
          </p>
          <select
            // name="documentCategory"
            // value={documentCategory}
            name={editingElement ? "editedCategory" : "documentCategory"}
            value={editingElement ? editedCategory : documentCategory}
            onChange={handleOnChange}
            style={{
              borderRadius: "5px",
              backgroundColor: "#ededed",
              width: "100%",
            }}
          >
            <option defaultValue disabled value="">
              Select Document Category
            </option>
            {documentCategories &&
              documentCategories.map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
          </select>
          <p>
            Upload File{" "}
            <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
          </p>
          <label htmlFor="uploadCredentials">
            <img src={uploadIcon} alt="upload icon" />{" "}
            <span>Upload Credentials</span>
          </label>
          <input
            type="file"
            name="content"
            // value={document}
            onChange={handleOnChange}
            id="uploadCredentials"
            style={{ visibility: "hidden", display: "none" }}
          />
          {chosenDocument && (
            <span style={{ marginTop: "5px" }}>{chosenDocument.name}</span>
          )}
        </form>

        <div style={{ marginTop: "30px" }}>
          {editingElement ? (
            <Button
              onClick={isNotPDF ? null : (e) => saveorEditCredential(e, "edit")}
              variant="primary"
              style={{
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                float: "left",
              }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Edit Credential
              </span>
            </Button>
          ) : (
            <Button
              onClick={isNotPDF ? null : (e) => saveorEditCredential(e, "")}
              variant="primary"
              style={{
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                float: "left",
              }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Save Credential
              </span>
            </Button>
          )}
        </div>
      </ModalComponent>

      <Table
        // responsive
        striped
        size="sm"
        style={{
          fontFamily: "jost, sans-serif",
          fontSize: "14px",
          overflow: "auto",
        }}
      >
        <thead>
          <tr align="center">
            {allCredentials &&
              allCredentials.length > 0 &&
              tableHeaders.map((header, i) => <th key={i}>{header}</th>)}
          </tr>
        </thead>
        <tbody>
          {allCredentials &&
            allCredentials.length > 0 &&
            allCredentials.map(
              (
                {
                  description,
                  documentCategory,
                  documentCategoryId,
                  id,
                  content,
                },
                i
              ) => (
                <tr key={i} className={i % 2 === 0 ? "even-row" : "odd-row"}>
                  <td align="center">{i + 1}</td>
                  <td align="center">{description}</td>
                  <td align="center">
                    {documentCategory?.name
                      ? documentCategory.name
                      : documentCategory}
                  </td>
                  <td align="center">
                    <ActionIcon
                      actionsArr={[
                        {
                          label: "Edit",
                          action: () =>
                            handleEditCredential({
                              description,
                              id,
                              documentCategory,
                              documentCategoryId,
                              content,
                            }),
                        },
                        {
                          label: "Delete",
                          action: () => confirmDelete({ description, id }),
                        },
                      ]}
                    />
                    {/* <img src={actionIcon} alt="action icon" /> */}
                  </td>
                </tr>
              )
            )}
        </tbody>
      </Table>

      <ButtonX
        onClick={() => setShowModal(true)}
        backgroundColor={`${!allCredentials || allCredentials.length < 1 ? "#F14810" : "#646464"
          }`}
        overlay={true}
        overlayText="Click this button to upload a credential"
      >
        Upload Credential
      </ButtonX>

      <div style={{ marginTop: "30px", display: "flex", gap: "15px" }}>
        <Button
          onClick={handleGoToPrevPage}
          variant="outline-primary"
          style={{ borderColor: "#F14810", color: "#F14810" }}
          className="button"
          size="md"
        >
          <span
            style={{
              fontSize: 0.8 + "rem",
              marginLeft: 0.5 + "rem",
              marginRight: 0.5 + "rem",
            }}
          >
            Previous
          </span>
        </Button>
        <OverlayTrigger
          placement="top"
          delay={{ show: 150, hide: 300 }}
          overlay={
            <Tooltip id="button-tooltip">
              Upload your credentials then click this button to preview your application
            </Tooltip>
          }
        >
          <Button
            variant="primary"
            onClick={
              !allCredentials || allCredentials.length < 1
                ? null
                : () => onSubmit()
            }
            style={{
              backgroundColor: `${!allCredentials || allCredentials.length < 1
                ? "#FFCAB8"
                : "#F14810"
                }`,
              borderColor: `${!allCredentials || allCredentials.length < 1
                ? "#FFCAB8"
                : "#F14810"
                }`,
              cursor: `${!allCredentials || allCredentials.length < 1
                ? "not-allowed"
                : "pointer"
                }`,
              float: "left",
            }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              Preview Application
            </span>
          </Button>
        </OverlayTrigger>

        {/* <ButtonX
          onClick={handleGoToPrevPage}
          backgroundColor="#ffffff"
          border="#f14810"
          color="#f14810"
        >
          Previous
        </ButtonX>
        <ButtonX onClick={onSubmit}>Submit</ButtonX> */}
      </div>
    </div>
  );
};

export default UploadCredentials;
