import { SignalWifiStatusbarNullOutlined } from "@mui/icons-material";
import Button from "../../../../../../../component/button/button";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import {
  useSetGlobalCommissionMutation,
  useApplyCommissionMutation,
} from "../../../../../../../redux/features/superAdmin/adminApiSlice";
import ToastComponent from "../../../../../../../component/toast/toast";
import OnboardingLoader from "../../../../../../../component/onboardingLoader/onboardingLoader";
import SuccessScreen from "../../../../../../../component/successScreen/successScreen";
import { useGetInstitutionSettlementListQuery } from "../../../../../../../redux/features/superAdmin/adminApiSlice";


const GlobalCommissionCanvas = () => {
  const [percentageSelected, setPercentageSelected] = useState(false);
  const [amountSelected, setAmountSelected] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsNames, setSelectedItemsNames] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showSchoolsDropdown, setShowSchoolsDropdown] = useState(false);
  const [createdRate, setCreatedRate] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [setGlobalCommission, { isLoading: globalCommissionLoading }] =
    useSetGlobalCommissionMutation();

  const [
    applyCommission,
    { isLoading: applyCommissionLoading, isSuccess: applyCommissionSuccess },
  ] = useApplyCommissionMutation();

  const { data: schoolsList } = useGetInstitutionSettlementListQuery();
  console.log(schoolsList);

  console.log(createdRate);

  const [rateValue, setRateValue] = useState(0);

  const handleOnChange = (e) => {
    setRateValue(e.target.value);
  };

  const handleSaveRate = async () => {
    let body = {};
    // change the type key based on the fixed or variable selection
    if (percentageSelected) {
      body = {
        type: "RATE",
        name: "GLOBAL",
        rate: rateValue,
      };
    } else {
      body = {
        type: "FIXED",
        name: "GLOBAL",
        fixedAmount: rateValue,
      };
    }

    try {
      const res = await setGlobalCommission(body).unwrap();
      // console.log(res);
      setCreatedRate(res);
      setShowToast(true);
      setToastMessage("Global processing fee created successfully");
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleApplyRate = async () => {
    let keys = {};
    // check if the created rate was fixed or variable as that would determine the keys to be sent to the api
    if (createdRate?.rate) {
      keys = {
        rate: createdRate?.rate,
        type: "RATE",
      };
    } else {
      keys = {
        fixedAmount: createdRate?.fixedAmount,
        type: "FIXED",
      };
    }

    const body = selectedItems?.map((schoolId) => {
      return {
        ...keys,
        institutionId: schoolId,
      };
    });
    // console.log(body)

    try {
      const res = await applyCommission(body).unwrap();
      console.log(res);
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleAmountClick = () => {
    setPercentageSelected(false);
    setAmountSelected(true);
  };

  const handlePercentClick = () => {
    setAmountSelected(false);
    setPercentageSelected(true);
  };

  const handleItemClick = (obj) => {
    if (selectedItems.includes(obj.id)) {
      setSelectedItems(selectedItems.filter((i) => i !== obj.id));
      setSelectedItemsNames(selectedItemsNames.filter((i) => i !== obj.institutionName));
    } else {
      setSelectedItems([...selectedItems, obj.id]);
      setSelectedItemsNames([...selectedItemsNames, obj.institutionName]);
    }
  };

  // console.log(selectedItems);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
      setSelectedItemsNames([]);
    } else {
      const allItemIds = schoolsList?.map((category) => category.id);
      setSelectedItems(allItemIds);
      setSelectedItemsNames(schoolsList?.map((category) => category.institutionName));
    }
    setSelectAll(!selectAll);
  };

  // close the schools dropdown when anywhere other than the dropdown is clicked
  useEffect(() => {
    const handleClose = (e) => {
      // close menu when they click outside
      console.log(e.target)
      // if (e.target.className) {
      //   if (
      //     showMenu &&
      //     !e.target.className.includes("mobileDashboardNavbar") &&
      //     !e.target.className.includes("menuIcon")
      //   ) {
      //     setShowMenu(false);
      //   }
      // }
    };

    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, []);

  if (globalCommissionLoading || applyCommissionLoading) {
    return <OnboardingLoader />;
  }

  if (applyCommissionSuccess) {
    return (
      <SuccessScreen successMessage="Global processing fee set successfully" />
    );
  }

  return (
    <form onSubmit={(e) => e.preventDefault()} className="commission-canvas">
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      {createdRate ? (
        <p>
          Select the list of schools to apply the created rate to from the
          dropdown below.
        </p>
      ) : (
        <p>
          Choose if the global rate should be a fixed amount or a percentage.
        </p>
      )}

      {!createdRate && (
        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Form.Check
              inline
              name="rate-type"
              onClick={handlePercentClick}
              type="radio"
              id={`inline-radio-1`}
              size={25}
              style={{
                marginRight: "10px",
                borderColor: "#ff0000",
              }}
            />
            <h6 style={{ marginBottom: "0" }}>Percent Rate ( % )</h6>
          </div>

          <p style={{ marginBottom: "15px" }}>
            Specify the commission rate as a percentage of the transaction amount.
          </p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Form.Check
              inline
              name="rate-type"
              onClick={handleAmountClick}
              type="radio"
              id={`inline-radio-2`}
              size={25}
              style={{ marginRight: "10px" }}
            />
            <h6 style={{ marginBottom: "0" }}>Amount Rate ( ₦ )</h6>
          </div>

          <p style={{ marginBottom: "15px" }}>
            Specify the commission rate as a fixed amount in ₦.
          </p>

          {percentageSelected ? (
            <>
              <div
                style={{ display: "flex", gap: "15px", marginBottom: "30px" }}
              >
                <div
                  style={{
                    backgroundColor: "#ededed",
                    width: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#484d45",
                    padding: "5px 10px",
                  }}
                >
                  %
                </div>
                <input
                  type="number"
                  // name="percentage"
                  // value={percentage}
                  onChange={(e) => handleOnChange(e)}
                  placeholder="Enter rate in percentage e.g 5"
                  style={{ backgroundColor: "#ededed" }}
                />
              </div>
            </>
          ) : null}

          {amountSelected ? (
            <>
              <div
                style={{ display: "flex", gap: "15px", marginBottom: "30px" }}
              >
                <div
                  style={{
                    backgroundColor: "#ededed",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#484d45",
                    padding: "5px 10px",
                  }}
                >
                  NGN
                </div>
                <input
                  type="number"
                  // name="amountRate"
                  // value={amountRate}
                  onChange={(e) => handleOnChange(e)}
                  placeholder="Enter rate in amount e.g 5000"
                  style={{ backgroundColor: "#ededed" }}
                />
              </div>
            </>
          ) : null}

          <Button
            backgroundColor={`${!rateValue ? "#FFCAB8" : "rgb(241, 72, 16)"}`}
            onClick={rateValue ? () => handleSaveRate() : null}
            cursor={`${rateValue ? "pointer" : "not-allowed"}`}
          >
            Save Rate
          </Button>
        </div>
      )}

      {createdRate && (
        <>
          <div style={{ position: "relative", marginTop: "20px" }}>
            <h6>School List</h6>
            <label
              style={{
                width: "100%",
                backgroundColor: "rgb(221, 221, 221)",
                borderRadius: "5px",
                padding: "10px",
                cursor: "pointer",
              }}
              onClick={() => setShowSchoolsDropdown(!showSchoolsDropdown)}
            >
              {/* {selectedItems.length > 0
                ? selectedItemsNames.join(", ")
                : "Select Schools"} */}

              {selectedItems.length > 0 ? (
                <>
                  {selectedItemsNames.slice(0, 3).join(", ")}
                  {selectedItemsNames.length > 3 && " ..."}
                </>
              ) : (
                "Select Schools"
              )}
            </label>

            {showSchoolsDropdown && (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  zIndex: "10",
                  transition: "all",
                  transitionTimingFunction: "ease-in-out",
                  transitionDuration: "0.3s",
                  backgroundColor: "white",
                  width: "100%",
                  border: "1px solid rgb(221, 221, 221)",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                <label style={{ display: "flex" }}>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    style={{
                      marginRight: "5px",
                      width: "auto",
                      appearance: "auto",
                    }}
                  />
                  Select All
                </label>
                {schoolsList &&
                  schoolsList?.map(({ institutionName, id }) => (
                    <label style={{ display: "flex" }} key={id}>
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(id)}
                        onChange={(e) =>
                          handleItemClick({ institutionName, id })
                        }
                        style={{
                          marginRight: "5px",
                          width: "auto",
                          appearance: "auto",
                        }}
                      />
                      {institutionName}
                    </label>
                  ))}
              </div>
            )}
          </div>
          <div style={{ marginTop: "20px" }}>
            <Button
              onClick={
                selectedItems.length === 0 ? null : () => handleApplyRate()
              }
              backgroundColor={`${selectedItems.length === 0 ? "#FFCAB8" : "rgb(241, 72, 16)"
                }`}
              cursor={`${selectedItems.length === 0 ? "not-allowed" : "pointer"
                }`}
            >
              Set Rate
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

export default GlobalCommissionCanvas;
