import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useCreateFeeMutation,
  useDeleteFeeMutation,
  useGetAllClassesQuery,
  useGetAllFeeComponentsQuery,
  useGetAllInstitutionFeesQuery,
  useUpdateFeeMutation,
  useAddInstitutionFeeComponentMutation,
} from "../../../../../redux/features/institution/institutionApiSlice";
import { Button, Form } from "react-bootstrap";
import ModalComponent from "../../../../../component/modal/modal";
import ToastComponent from "../../../../../component/toast/toast";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import { useDispatch, useSelector } from "react-redux";
import alertIcon from "../../../../../assets/svgs/alertIcon.svg";

const SetupFeeManagementComponent = ({ showFeeMgtModal }) => {
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showFeeComponentModal, setShowFeeComponentModal] = useState(false);
  const [newData, setNewData] = useState(false);
  const [deletedElementID, setDeletedElementID] = useState(null);
  const [editedElement, setEditedElement] = useState(null);
  const [editingElement, setEditingElement] = useState(null);
  const [editingValue, setEditingValue] = useState({});
  const [editedCompulsory, setEditedCompulsory] = useState(null);
  const [elementToDelete, setElementToDelete] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [createCompModal, setCreateCompModal] = useState(false);
  const [componentName, setComponentName] = useState("");

  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedClassesIds, setSelectedClassesIds] = useState([]);
  const [selectedClassesNames, setSelectedClassesNames] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showClassesDropdown, setShowClassesDropdown] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const [newAllFeeComponents, setNewAllFeeComponents] = useState([]);
  const [newFeesData, setNewFeesData] = useState([]);
  const [filteredComponents, setFilteredComponents] = useState([]);

  const [showComponentsTable, setShowComponentsTable] = useState(false);

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const [createFee, { isLoading: createFeeLoading }] = useCreateFeeMutation();
  const [deleteFee, { isLoading: deleteLoading }] = useDeleteFeeMutation();
  const [updateFee, { isLoading: updateLoading }] = useUpdateFeeMutation();
  const [addInstitutionFeeComponent, { isLoading: addLoading }] =
    useAddInstitutionFeeComponentMutation();

  const { data: classes, isLoading: classesLoading } =
    useGetAllClassesQuery(instId);

  console.log(selectedItems);

  const {
    data: allFeeComponents,
    refetch: refetchFeeComponents,
    isLoading: feeComponentsLoading,
  } = useGetAllFeeComponentsQuery(instId);

  const dispatch = useDispatch();

  const {
    data: feesData,
    refetch: refetchFees,
    isLoading: feesLoading,
  } = useGetAllInstitutionFeesQuery(instId);

  // console.log(feesData);

  const openFeeComponentModal = () => {
    setShowModal(false);
    // showFeeMgtModal(false)
    setShowFeeComponentModal(true);
  };

  //   console.log(first)

  const closeFeeComponentModal = () => {
    setShowFeeComponentModal(false);
    setShowModal(true);
    setSearchText("");
  };

  const [feeDetails, setFeeDetails] = useState({
    categoryIds: [],
    title: "",
    fees: [],
  });

  const { title } = feeDetails;

  const handleOnChange = (e, id) => {
    const { name, value } = e.target;
    setFeeDetails({ ...feeDetails, [name]: value });
    setEditingValue({ ...editingValue, [name]: value });

    if (name === "searchText") {
      setSearchText(value);
    }

    if (name === "amount") {
      setSelectedItems(
        selectedItems.map((item) => {
          if (item.id === id) {
            return { ...item, amount: value };
          }
          return item;
        })
      );
    }

    if (e.target.type === "radio") {
      setSelectedItems(
        selectedItems.map((item) => {
          if (item.id === id) {
            return { ...item, compulsory: value };
          }
          return item;
        })
      );
    }
  };

  const { editedTitle } = editingValue;

  const [dataFromServer, setDataFromServer] = useState([]);

  const handleCreateOrUpdateFee = async (e, type) => {
    e.preventDefault();

    if (type === "edit") {
      const parameters = {
        institutionId: instId,
        feeId: editingElement?.id,
        body: {
          institutionId: instId,
          title: editedTitle,
          categoryIds: selectedClassesIds,
          fees: selectedItems?.map((fee) => {
            const { id, code, createdAt, updatedAt, ...rest } = fee;

            if (fee.id == fee.feeComponentId) {
              return { ...rest };
            }
            return { id, ...rest };
          }),
        },
      };
      try {
        const res = await updateFee(parameters);

        console.log(res);
        if (res) {
          setEditedElement(res);
        }
        setEditingValue({});
        setEditingElement(null);
        setEditedCompulsory(null);
        setShowModal(false);
        setFeeDetails({ categoryIds: [], title: "", fees: [] });
        setSelectedItems([]);
        setSelectedClasses([]);
        setSelectAll(false);
        setShowToast(true);
        setToastMessage("Fee has been updated");
      } catch (error) {
        console.log(error);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
      return;
    } else {
      try {
        const parameters = {
          institutionId: instId,
          body: {
            institutionId: instId,
            title: title,
            categoryIds: selectedClassesIds,
            // fees: selectedItems,
            fees: selectedItems?.map((fee) => {
              // remove id first
              // const { id, ...rest } = fee;
              const { id, code, createdAt, updatedAt, ...rest } = fee;

              // return { ...rest, feeComponentId: id };
              return { ...rest };
              // return { ...fee, feeComponentId: fee.id };
            }),
          },
        };

        // submit with institutionId also
        const data = await createFee(parameters).unwrap();

        if (data) {
          console.log(data);
          setNewData(data);
        }
        setFeeDetails({ categoryIds: [], title: "", fees: [] });
        setSelectedItems([]);
        setSelectedClasses([]);
        setShowModal(false);
        setSelectAll(false);
        setShowToast(true);
        setToastMessage("Fee has been created");
      } catch (error) {
        console.log(error.data);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
    }
  };

  useEffect(() => {
    setDataFromServer(() => {
      if (classes) {
        return Object.keys(classes)?.map((key) =>
          classes[key].map((value) => {
            value.categories.map((classDetail) => ({
              ...classDetail,
              checked: false,
            }));
            return {
              ...value,
              categories: value.categories.map((category) => ({
                ...category,
                checked: false,
              })),
              checked: false,
            };
          })
        );
      } else {
        return [];
      }
    });
  }, [classes]);

  const handleClickCheckBox = (e, selectedCheckbox) => {
    setDataFromServer((prev) =>
      prev.map((groupedData) => {
        return groupedData?.map((schoolData) => {
          if (schoolData?.id === selectedCheckbox?.id)
            return {
              ...schoolData,
              checked: e.target.checked,
              categories: schoolData?.categories?.map((categoryData) => ({
                ...categoryData,
                checked: e.target.checked,
              })),
            };
          return schoolData;
        });
      })
    );
  };

  const handleClickChildCheckBox = (e, parentId, categoryCheckbox) => {
    if (e.target.checked) {
      setDataFromServer((prev) =>
        prev.map((groupedData) => {
          return groupedData?.map((schoolData) => {
            if (schoolData?.id === parentId) {
              return {
                ...schoolData,
                checked:
                  schoolData?.categories.filter(
                    (category) => !category?.checked
                  ).length === 1
                    ? true
                    : false,
                categories: schoolData?.categories?.map((categoryData) => {
                  if (categoryCheckbox?.id === categoryData?.id) {
                    return {
                      ...categoryData,
                      checked: e.target.checked,
                    };
                  }
                  return categoryData;
                }),
              };
            }
            return { ...schoolData };
          });
        })
      );
      return;
    }
    setDataFromServer((prev) =>
      prev.map((groupedData) => {
        return groupedData?.map((schoolData) => {
          if (schoolData?.id === parentId) {
            return {
              ...schoolData,
              checked: false,
              categories: schoolData?.categories?.map((categoryData) => {
                if (categoryCheckbox?.id === categoryData?.id) {
                  return {
                    ...categoryData,
                    checked: false,
                  };
                }
                return categoryData;
              }),
            };
          }
          return schoolData;
        });
      })
    );
  };

  useEffect(() => {
    setSelectedClasses(
      dataFromServer
        .flat()
        .map((mergedData) => {
          if (mergedData?.categories.every((category) => !category?.checked))
            return;
          return {
            ...mergedData,
            categories: mergedData?.categories.filter(
              (category) => category?.checked
            ),
          };
        })
        .filter((element) => element !== undefined)
    );
  }, [dataFromServer]);

  useEffect(() => {
    const names = selectedClasses.map((eachClass) => {
      return eachClass.categories.map((cat) => {
        return `${eachClass.name}${cat.name}`;
      });
    });

    setSelectedClassesNames(names);

    const ids = selectedClasses.map((eachClass) => {
      return eachClass.categories.map((cat) => cat.id);
    });

    // join all the id that are in the arrays gotten from the mapping above
    setSelectedClassesIds(ids.join().split(","));
  }, [selectedClasses]);

  useEffect(() => {
    setNewFeesData(
      feesData?.map((fee) => {
        return { ...fee, tableOpen: false };
      })
    );
  }, [feesData]);

  useEffect(() => {
    if (feesData) {
      refetchFees();
    }
    if (allFeeComponents) {
      refetchFeeComponents();

      const selectedIds = selectedItems?.map((item) => item?.feeComponentId);
      // const selectedIds = selectedItems?.map((item) =>
      //   item?.feeComponentId ? item?.feeComponentId : item?.id
      // );
      console.log(selectedIds);

      setNewAllFeeComponents(
        allFeeComponents?.map((feeComponent) => {
          if (selectedIds.includes(feeComponent.id)) {
            return {
              ...feeComponent,
              selected: true,
              feeComponentId: feeComponent.id,
            };
          } else {
            return {
              ...feeComponent,
              selected: false,
              compulsory: null,
              amount: null,
              feeComponentId: feeComponent.id,
            };
          }
        })
      );
    }
  }, [newData, deletedElementID, editedElement, allFeeComponents, feesData]);

  useEffect(() => {
    if (editingElement) {
      const selectedIds = selectedItems?.map((item) => item?.feeComponentId);
      // console.log(selectedIds);

      setNewAllFeeComponents(
        allFeeComponents?.map((feeComponent) => {
          return {
            ...feeComponent,
            // selected: false,
            selected: selectedIds.includes(feeComponent?.id),
            compulsory: null,
            amount: null,
            feeComponentId: feeComponent.id,
          };
        })
      );
    }
  }, [editingElement]);

  useEffect(() => {
    setFilteredComponents(newAllFeeComponents || []);
  }, [newAllFeeComponents]);

  useEffect(() => {
    const filtered = newAllFeeComponents?.filter((feeComponent) =>
      feeComponent.name.toLowerCase().includes(searchText.toLowerCase())
    );

    // set the selected to true if i find any of these items already in the selected components

    const newComps = filtered?.map((comp) => ({
      ...comp,
      selected: selectedItems?.some(
        (selectedComp) => selectedComp.id === comp.id
      ),
    }));

    // console.log(newComps);

    setFilteredComponents(newComps);
  }, [searchText]);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowClassesDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleItemClick = (obj) => {
    console.log(obj);
    // console.log(selectedItems);

    if (
      selectedItems.find(
        // (item) => item.id === obj.id || item.feeComponentId === obj.id
        (item) => item.feeComponentId === obj.feeComponentId
      )
      // obj.selected
    ) {
      setSelectedItems(
        selectedItems.filter(
          (item) => item.feeComponentId !== obj.feeComponentId
        )
      );
      setFilteredComponents(
        filteredComponents?.map((component) => {
          if (component.feeComponentId === obj.feeComponentId) {
            return { ...component, selected: false };
          }
          return component;
        })
      );
    } else {
      setSelectedItems([...selectedItems, { ...obj, selected: true }]);
      setFilteredComponents(
        filteredComponents?.map((component) => {
          if (component.feeComponentId === obj.feeComponentId) {
            return { ...component, selected: true };
          }
          return component;
        })
      );
    }
  };

  const handleDeleteFeeComponent = (id) => {
    setSelectedItems(
      selectedItems?.filter((item) => item.feeComponentId !== id)
    );
    setFilteredComponents(
      filteredComponents?.map((component) => {
        if (component.feeComponentId === id) {
          return {
            ...component,
            selected: false,
          };
        }
        return component;
      })
    );
  };

  console.log(allFeeComponents);
  console.log(newAllFeeComponents);

  // useEffect(() => {}, [allFeeComponents]);

  const handleCreateComp = async () => {
    try {
      const obj = {
        institutionId: instId,
        body: {
          name: componentName,
        },
      };
      const data = await addInstitutionFeeComponent(obj).unwrap();
      setCreateCompModal(false);
      setShowToast(true);
      setToastMessage("Fee Component has been created");
      setSelectedItems([
        ...selectedItems,
        { ...data, selected: true, feeComponentId: data.id },
      ]);
      // const selectedIds = selectedItems?.map((item) => item.id);

      // const formerAllComponents = newAllFeeComponents?.map((comp) => {
      //   if (selectedIds.includes(comp.id)) {
      //     return { ...comp, selected: true };
      //   }
      //   return comp;
      // });

      // setNewAllFeeComponents([
      //   ...formerAllComponents,
      //   { ...data, selected: true },
      // ]);

      if (allFeeComponents) {
        refetchFeeComponents();
      }
    } catch (error) {
      console.log(error.data);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        setToastMessage(error.error);
      }
    }
  };

  if (
    feesLoading ||
    createFeeLoading ||
    updateLoading ||
    deleteLoading ||
    feeComponentsLoading
  ) {
    return <OnboardingLoader />;
  }
  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <div>
        {isFormNotFilled && (
          <span
            style={{
              color: "red",
              fontStyle: "italic",
              transition: "ease-in-out",
            }}
          >
            Please fill all fields
          </span>
        )}

        <form>
          <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo.
          </p>

          <h4 style={{ marginBottom: "10px", fontSize: "14px" }}>Fee Title</h4>
          <input
            type="text"
            placeholder="Enter Fee Title"
            onChange={(e) => handleOnChange(e)}
            name={editingElement ? "editedTitle" : "title"}
            value={editingElement ? editedTitle : title}
            style={{
              borderRadius: "5px",
              backgroundColor: "#dddddd",
              width: "100%",
            }}
          />

          <h4 style={{ margin: "15px 0 10px", fontSize: "14px" }}>
            Fee Component
          </h4>

          {selectedItems?.map(
            (
              {
                feeComponentName,
                name,
                id,
                amount,
                compulsory,
                feeComponentId,
              },
              i
            ) => (
              <div key={id}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5 style={{ margin: "10px 0", fontSize: "12px" }}>
                    {feeComponentName || name}
                  </h5>
                  <span
                    style={{
                      color: "#F14810",
                      marginTop: "10px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontStyle: "italic",
                    }}
                    onClick={() => handleDeleteFeeComponent(feeComponentId)}
                  >
                    delete
                  </span>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "15px" }}
                >
                  <input
                    type="number"
                    placeholder="Enter Amount"
                    name="amount"
                    value={amount}
                    onChange={(e) => handleOnChange(e, id)}
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#dddddd",
                      width: "100%",
                      // minWidth: "300px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Form.Check
                      inline
                      label="Compulsory"
                      name={`compulsory-${id}`}
                      value={true}
                      checked={compulsory == "true" || compulsory == true}
                      onChange={(e) => handleOnChange(e, id)}
                      type="radio"
                      id={`inline-radio-1-${id}`}
                      size={25}
                      style={{
                        variant: "outline-warning",
                      }}
                    />
                    <Form.Check
                      inline
                      label="Optional"
                      name={`compulsory-${id}`}
                      value={false}
                      checked={compulsory == "false" || compulsory == false}
                      // checked={selectedItems[i].compulsory == false}
                      onChange={(e) => handleOnChange(e, id)}
                      type="radio"
                      id={`inline-radio-2-${id}`}
                      size={25}
                      style={{ variant: "outline-warning" }}
                    />
                  </div>
                </div>
              </div>
            )
          )}

          <div style={{ marginTop: "10px" }}>
            <span
              style={{
                color: "#F14810",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={openFeeComponentModal}
            >
              + Add fee Component
            </span>
          </div>

          <h4 style={{ margin: "15px 0 10px", fontSize: "14px" }}>Apply to</h4>

          <div ref={dropdownRef}>
            <label
              style={{
                width: "100%",
                backgroundColor: "rgb(221, 221, 221)",
                borderRadius: "5px",
                padding: "7px 10px",
                cursor: "pointer",
                fontSize: "14px",
              }}
              onClick={() => setShowClassesDropdown(!showClassesDropdown)}
            >
              {selectedClassesNames?.length > 0 ? (
                <>
                  {selectedClassesNames.flat().slice(0, 3).join(", ")}
                  {selectedClassesNames.flat().length > 3 && " ..."}
                </>
              ) : (
                "Select Classes to apply to"
              )}
            </label>

            {showClassesDropdown && (
              <div
                style={{
                  transition: "all",
                  transitionTimingFunction: "ease-in-out",
                  transitionDuration: "0.3s",
                  backgroundColor: "white",
                  maxHeight: "150px",
                  overflow: "auto",
                  width: "100%",
                  border: "1px solid rgb(221, 221, 221)",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              >
                {dataFromServer?.length > 0 ? (
                  dataFromServer?.map((data) =>
                    data.map((classDetails) => (
                      <div key={classDetails?.id}>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            padding: "5px",
                            // borderBottom: "1px solid #DBDBDB",
                          }}
                        >
                          <div style={{ position: "relative" }}>
                            <label className="checkbox-container">
                              <input
                                type="checkbox"
                                className="selectAllCheckbox"
                                onChange={(e) =>
                                  handleClickCheckBox(e, classDetails)
                                }
                                checked={classDetails?.checked}
                              />

                              <span className={"custom-checkbox"}></span>
                            </label>
                          </div>
                          <span style={{ width: "100%" }}>
                            {classDetails?.name}
                          </span>
                        </div>

                        {classDetails?.categories?.map((category) => {
                          // console.log(category);

                          return (
                            <div
                              style={{ marginLeft: "20px" }}
                              key={category?.id}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  // borderBottom: "1px solid #DBDBDB",
                                  marginLeft: "20px",
                                  fontSize: "14px",
                                }}
                              >
                                <div style={{ position: "relative" }}>
                                  <label className="checkbox-container">
                                    <input
                                      type="checkbox"
                                      // checked={
                                      //   // category?.checked ||
                                      //   // selectedClassesIds.includes(category?.id)
                                      //   selectedClassesIds?.find(
                                      //     (id) => id == category.id
                                      //   )
                                      // }
                                      checked={category?.checked}
                                      onChange={(e) =>
                                        handleClickChildCheckBox(
                                          e,
                                          classDetails?.id,
                                          category
                                        )
                                      }
                                    />
                                    <span className={"custom-checkbox"}></span>
                                  </label>
                                </div>
                                <span style={{ width: "100%" }}>
                                  {category?.name}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ))
                  )
                ) : (
                  <span style={{ fontStyle: "italic" }}>
                    There are no classes yet
                  </span>
                )}
              </div>
            )}
          </div>

          <Button
            onClick={
              editingElement
                ? (e) => handleCreateOrUpdateFee(e, "edit")
                : (e) => handleCreateOrUpdateFee(e)
            }
            variant="primary"
            style={{
              backgroundColor: "#F14810",
              borderColor: "#F14810",
              float: "left",
              marginTop: "20px",
              marginRight: "15px",
            }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              {editingElement ? "Edit Fee" : "Save Fee"}
            </span>
          </Button>
        </form>
      </div>

      {/* create fee comp */}
      <ModalComponent
        title="Create Fee Component"
        showModal={createCompModal}
        closeModal={() => setCreateCompModal(false)}
      >
        <form>
          <div style={{ marginBottom: "15px" }}>
            <p style={{ marginBottom: "5px", fontWeight: "500" }}>
              Fee Name{" "}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </p>
            <input
              type="text"
              placeholder="Enter Fee Name (e.g Tuition, Uniform)"
              //   name="componentName"
              //   value={componentName}
              //   onChange={handleCreateCompChange}
              onChange={(e) => setComponentName(e.target.value)}
              style={{
                borderRadius: "5px",
                backgroundColor: "#dddddd",
                width: "100%",
              }}
            />
          </div>
          <div style={{ marginTop: "30px" }}>
            <Button
              onClick={() => handleCreateComp()}
              variant="primary"
              style={{
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                float: "left",
              }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Save Fee Component
              </span>
            </Button>
          </div>
        </form>
      </ModalComponent>

      {/* fee component modal */}
      <ModalComponent
        title="Select Fee Component"
        showModal={showFeeComponentModal}
        closeModal={closeFeeComponentModal}
      >
        <form>
          <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo.
          </p>

          <div style={{ margin: "10px 0" }}>
            <span
              style={{
                color: "#F14810",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={() => {
                setCreateCompModal(true);
                closeFeeComponentModal();
              }}
            >
              + Create Fee Component
            </span>
          </div>

          <input
            type="search"
            name="searchText"
            value={searchText}
            onChange={handleOnChange}
            placeholder="Search for Fee Component"
            style={{
              borderRadius: "5px",
              backgroundColor: "#dddddd",
              width: "100%",
              // marginBottom: "20px",
            }}
          />

          <div
            style={{
              transition: "all",
              transitionTimingFunction: "ease-in-out",
              transitionDuration: "0.3s",
              backgroundColor: "white",
              width: "100%",
              maxHeight: "200px",
              overflow: "auto",
              border: "1px solid rgb(221, 221, 221)",
              borderRadius: "5px",
              padding: "0 10px 10px",
              marginTop: "10px",
            }}
          >
            {filteredComponents?.map((component) => {
              return (
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    padding: "10px",
                    borderBottom: "1px solid #DBDBDB",
                  }}
                  key={component?.id}
                >
                  <div style={{ position: "relative" }}>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checked={component?.selected}
                        onChange={(e) => handleItemClick(component)}
                      />
                      <span className={`custom-checkbox`}></span>
                    </label>
                  </div>
                  <span style={{ width: "100%" }}>{component?.name}</span>
                </div>
              );
            })}
          </div>

          <Button
            onClick={closeFeeComponentModal}
            variant="primary"
            style={{
              backgroundColor: "#F14810",
              borderColor: "#F14810",
              float: "left",
              marginTop: "20px",
            }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              Done
            </span>
          </Button>
        </form>
      </ModalComponent>
    </>
  );
};

export default SetupFeeManagementComponent;
