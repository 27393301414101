import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Image,
  Form,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import SliderItems from "../../../component/SliderItems.js";

import logo from "../../../assets/svgs/logo.svg";

import AboutInstitutionPage from "./aboutInstitution/aboutInstitutionPage.js";
import ContactPersonPage from "./contactPerson/contactPersonPage.js";
import SetPasswordPage from "./setPassword/setPasswordPage.js";
import ValidateEmailPage from "./validateEmail/validateEmail.js";

const registrationStages = [
  { stage: "About the School" },
  { stage: "Primary Contact Person" },
  { stage: "Secondary Contact Person" },
  { stage: "technical Contact Person" },
  { stage: "Set Password" },
  { stage: "Validate Email" },
];

const RegistrationStepper = () => {
  const [activeStage, setActiveStage] = useState("About the School");

  return (
    <div>
      <>
        <Container fluid>
          <Row>
            <Col xs={12} md={6} lg={6} className="FirstSegment">
              <div>
                <Link to="/">
                  <Navbar.Brand>
                    <Image src={logo} fluid />
                  </Navbar.Brand>
                </Link>
              </div>

              <SliderItems />
            </Col>
            <Col xs={12} md={6} lg={6} className="SecondSegment">
              <div className="displayOnMobile">
                <Link to="/">
                  <Navbar.Brand>
                    <Image src={logo} fluid />
                  </Navbar.Brand>
                </Link>
              </div>
              <br />
              <div>
                {activeStage === "About the School" && (
                  <AboutInstitutionPage setActiveStage={setActiveStage} />
                )}
                {activeStage === "Contact Person" && (
                  <ContactPersonPage setActiveStage={setActiveStage} />
                )}
                {activeStage === "Set Password" && (
                  <SetPasswordPage setActiveStage={setActiveStage} />
                )}
                {activeStage === "Validate Email" && (
                  <ValidateEmailPage setActiveStage={setActiveStage} />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    </div>
  );
};

export default RegistrationStepper;
