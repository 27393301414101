import { apiSlice } from "../../api/apiSlice";

export const parentSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateInvoice: builder.mutation({
      providesTags: ["updateInvoice"],
      invalidatesTags: ["updateInvoice"],
      query: ({ accessToken, body }) => ({
        url: `/parent/invoice/update/${accessToken}`,
        method: "POST",
        body,
        headers: { "Content-Type": "application/json" },
      }),
    }),
    payFee: builder.mutation({
      providesTags: ["payFee"],
      invalidatesTags: ["payFee"],
      query: (accessToken) => ({
        url: `/parent/invoice/payfees/${accessToken}`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }),
    }),
    getStudentInvoiceDetails: builder.query({
      providesTags: ["studentInvoiceDetails"],
      invalidatesTags: ["studentInvoiceDetails"],
      query: (accessToken) => ({
        url: `/parent/invoice/details/${accessToken}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
    }),
    getReminderDetails: builder.query({
      providesTags: ["getReminderDetails"],
      invalidatesTags: ["getReminderDetails"],
      query: (accessToken) => ({
        url: `/parent/reminder/${accessToken}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
    }),
  }),
});

export const {useUpdateInvoiceMutation, useGetStudentInvoiceDetailsQuery, usePayFeeMutation, useGetReminderDetailsQuery} = parentSlice
