import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Notifications = ({ setShowNotifications }) => {
  const [showAllNotifications, setShowAllNotifications] = useState(true);

  const allNotifications = [
    {
      id: 1,
      title: "School Set Up",
      description: "Your Application was rejected. Click to see more details",
      time: "Today, 02:38AM",
      goto: "/settings/application-rejected",
    },
  ];

  return (
    <div>
      <div style={{ marginTop: "10px", marginBottom: "15px" }}>
        <Button
          className="button"
          size="md"
          style={{
            backgroundColor: `${showAllNotifications ? "#F14810" : "#ffede8"}`,
            color: `${showAllNotifications ? "#ffffff" : "#F14810"}`,
            border: "2px solid #F14810",
            outline: "none",
            padding: "7px 20px",
            marginRight: "10px",
          }}
          onClick={() => setShowAllNotifications(true)}
        >
          All
        </Button>

        <Button
          className="button"
          size="md"
          style={{
            backgroundColor: `${showAllNotifications ? "#ffede8" : "#F14810"}`,
            color: `${showAllNotifications ? "#F14810" : "#ffffff"}`,
            border: "2px solid #F14810",
            outline: "none",
            padding: "8px 20px",
          }}
          onClick={() => setShowAllNotifications(false)}
        >
          Unread
        </Button>
      </div>

      {/* {showAllNotifications ? (
        <>
          {allNotifications?.map(({ id, title, description, time, goto }) => (
            <Link to={goto} onClick={() => setShowNotifications(false)}>
              <div key={id}>
                <h6 style={{ marginBottom: "5px" }}>{title}</h6>
                <p style={{ marginBottom: "5px", lineHeight: "16px" }}>
                  {description}
                </p>
                <p style={{ marginBottom: "0px", color: "#a5a5a5" }}>{time}</p>
              </div>
            </Link>
          ))}
        </>
      ) : (
        <></>
      )} */}
    </div>
  );
};

export default Notifications;
