import { useState, useEffect, useRef } from "react";
import alertIcon from "../../../../../assets/svgs/alertIcon.svg";

import { Table, Form, InputGroup, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../../continueSetupInstitution/setupInstitution/components/institutionFee/institutionFeeSetup.css";
import ModalComponent from "../../../../../component/modal/modal";
import { useSelector, useDispatch } from "react-redux";
import {
  useGetAllInstitutionFeesQuery,
  useAddInstitutionFeeMutation,
  useGetInstitutionLevelsQuery,
  useGetInstitutionCategoriesQuery,
  useGetInstitutionFeeComponentQuery,
  useDeleteInstitutionFeeMutation,
  useUpdateInstitutionFeeMutation,
  useCompleteInstitutionSetupMutation,
  useGetAllClassesQuery,
  useGetAllFeeComponentsQuery,
  useCreateFeeMutation,
  useUpdateFeeMutation,
  useDeleteFeeMutation,
} from "../../../../../redux/features/institution/institutionApiSlice";
import ActionIcon from "../../../../../component/actionbutton/ActionIcon";
import dropdownIcon from "../../../../../assets/svgs/dropdownIcon.svg";
import EditIcon from "../../../../../assets/svgs/editIcon";
// import editIcon from "../../../../../assets/svgs/editIcon.svg";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import { addTrailingCommas } from "../../../../../utils/utils";
import ToastComponent from "../../../../../component/toast/toast";
import TableComponent from "../../../../../component/table/table";
import DeleteIcon from "../../../../../assets/svgs/deleteIcon";

const InstitutionFee = () => {
  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showFeeComponentModal, setShowFeeComponentModal] = useState(false);
  const [newData, setNewData] = useState(false);
  const [deletedElementID, setDeletedElementID] = useState(null);
  const [editedElement, setEditedElement] = useState(null);
  const [editingElement, setEditingElement] = useState(null);
  const [editingValue, setEditingValue] = useState({});
  const [editedCompulsory, setEditedCompulsory] = useState(null);
  const [elementToDelete, setElementToDelete] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedClassesIds, setSelectedClassesIds] = useState([]);
  const [selectedClassesNames, setSelectedClassesNames] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showClassesDropdown, setShowClassesDropdown] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const [newAllFeeComponents, setNewAllFeeComponents] = useState([]);
  const [newFeesData, setNewFeesData] = useState([]);
  const [filteredComponents, setFilteredComponents] = useState([]);

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingElement(null);
    setEditingValue({
      editedTitle: "",
    });
    setFeeDetails({});
    setEditedCompulsory(null);
    setSelectedItems([]);
    setFilteredComponents(
      allFeeComponents?.map((feeComponent) => {
        return {
          ...feeComponent,
          feeComponentId: feeComponent.id,
          selected: false,
        };
      })
    );
    setSelectedClasses([]);
    setSelectedClassesNames([]);
  };

  const [createFee, { isLoading: createFeeLoading }] = useCreateFeeMutation();
  const [deleteFee, { isLoading: deleteLoading }] = useDeleteFeeMutation();
  const [updateFee, { isLoading: updateLoading }] = useUpdateFeeMutation();

  const { data: classes, isLoading: classesLoading } =
    useGetAllClassesQuery(instId);

  const {
    data: allFeeComponents,
    refetch: refetchFeeComponents,
    isLoading: feeComponentsLoading,
  } = useGetAllFeeComponentsQuery(instId);

  const dispatch = useDispatch();

  const {
    data: feesData,
    refetch: refetchFees,
    isLoading: feesLoading,
  } = useGetAllInstitutionFeesQuery(instId);

  const openFeeComponentModal = () => {
    setShowModal(false);
    setShowFeeComponentModal(true);
  };

  const closeFeeComponentModal = () => {
    setShowFeeComponentModal(false);
    setShowModal(true);
    setSearchText("");
  };

  const [feeDetails, setFeeDetails] = useState({
    categoryIds: [],
    title: "",
    fees: [],
  });

  const { title } = feeDetails;

  const handleOnChange = (e, id) => {
    const { name, value } = e.target;
    setFeeDetails({ ...feeDetails, [name]: value });
    setEditingValue({ ...editingValue, [name]: value });

    if (name === "searchText") {
      setSearchText(value);
    }

    if (name === "amount") {
      setSelectedItems(
        selectedItems.map((item) => {
          if (item.id === id) {
            return { ...item, amount: value };
          }
          return item;
        })
      );
    }

    if (e.target.type === "radio") {
      setSelectedItems(
        selectedItems.map((item) => {
          if (item.id === id) {
            return { ...item, compulsory: value };
          }
          return item;
        })
      );
    }
  };

  const { editedTitle } = editingValue;

  const navigate = useNavigate();

  const handleEditFee = (id) => {
    // find fee to edit
    const el = feesData?.find((fee) => fee.id === id);
    setEditingElement(el);
    setShowModal(true);
    setEditingValue({
      editedTitle: el?.title,
    });
    setSelectedItems(
      el?.fees.map((el) => {
        return { ...el, selected: true };
      })
    );

    const toBeModifiedKeys = Object.keys(el?.feeStructureClasses);

    setDataFromServer((prev) => {
      return prev.map((schoolData) => {
        return schoolData.map((classDetails) => {
          const foundParent = toBeModifiedKeys?.find(
            (key) => key === classDetails?.name
          );

          return {
            ...classDetails,
            checked:
              classDetails?.categories.length ===
              el?.feeStructureClasses?.[foundParent]?.length,
            categories: classDetails?.categories.map((category) => {
              return {
                ...category,
                checked: !!el?.feeStructureClasses?.[foundParent]?.find(
                  (cat) => cat?.institutionCategoryId === category?.id
                ),
              };
            }),
          };
        });
      });
    });
  };

  const handleOpenComponentTable = (id) => {
    setNewFeesData(
      newFeesData.map((fee) => {
        if (fee.id === id) {
          return { ...fee, tableOpen: !fee.tableOpen };
        }
        return fee;
      })
    );
  };

  const [dataFromServer, setDataFromServer] = useState([]);

  const confirmDelete = (id) => {
    // find fee to delete
    const el = feesData?.find((fee) => fee.id === id);
    setElementToDelete(el);
    setShowConfirmDelete(true);
  };

  const handleDeleteFee = async () => {
    setShowConfirmDelete(false);

    try {
      await deleteFee(elementToDelete?.id).unwrap();
      setDeletedElementID(elementToDelete.id);
    } catch (error) {
      console.log(error);
      // alert(error.data);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleDeleteFeeComponent = (id) => {
    setSelectedItems(
      selectedItems?.filter((item) => item.feeComponentId !== id)
    );
    setFilteredComponents(
      filteredComponents?.map((component) => {
        if (component.feeComponentId === id) {
          return {
            ...component,
            selected: false,
          };
        }
        return component;
      })
    );
  };

  const handleCreateOrUpdateFee = async (e, type) => {
    e.preventDefault();

    if (type === "edit") {
      const parameters = {
        institutionId: instId,
        feeId: editingElement?.id,
        body: {
          institutionId: instId,
          title: editedTitle,
          categoryIds: selectedClassesIds,
          fees: selectedItems?.map((fee) => {
            const { id, code, createdAt, updatedAt, ...rest } = fee;

            if (fee.id == fee.feeComponentId) {
              return { ...rest };
            }
            return { id, ...rest };
          }),
        },
      };
      try {
        const res = await updateFee(parameters);

        setEditedElement(res);
        setEditingValue({});
        setEditingElement(null);
        setEditedCompulsory(null);
        setShowModal(false);
        setFeeDetails({ categoryIds: [], title: "", fees: [] });
        setSelectedItems([]);
        setSelectedClasses([]);
        setSelectAll(false);
        setShowToast(true);
        setToastMessage("Fee has been updated");
      } catch (error) {
        console.log(error);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
      return;
    } else {
      try {
        const parameters = {
          institutionId: instId,
          body: {
            institutionId: instId,
            title: title,
            categoryIds: selectedClassesIds,
            // fees: selectedItems,
            fees: selectedItems?.map((fee) => {
              // remove id first
              // const { id, ...rest } = fee;
              const { id, code, createdAt, updatedAt, ...rest } = fee;

              // return { ...rest, feeComponentId: id };
              return { ...rest };
              // return { ...fee, feeComponentId: fee.id };
            }),
          },
        };

        // submit with institutionId also
        const data = await createFee(parameters).unwrap();

        setNewData(data);
        setFeeDetails({ categoryIds: [], title: "", fees: [] });
        setSelectedItems([]);
        setSelectedClasses([]);
        // reset the checked property to false on all the classes
        setDataFromServer(
          Object.keys(classes)?.map((key) =>
            classes[key].map((value) => {
              value.categories.map((classDetail) => ({
                ...classDetail,
                checked: false,
              }));
              return {
                ...value,
                categories: value.categories.map((category) => ({
                  ...category,
                  checked: false,
                })),
                checked: false,
              };
            })
          )
        );
        setShowModal(false);
        setSelectAll(false);
        setShowToast(true);
        setToastMessage("Fee has been created");
      } catch (error) {
        console.log(error.data);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
    }
  };

  useEffect(() => {
    setDataFromServer(() => {
      if (classes) {
        return Object.keys(classes)?.map((key) =>
          classes[key].map((value) => {
            value.categories.map((classDetail) => ({
              ...classDetail,
              checked: false,
            }));
            return {
              ...value,
              categories: value.categories.map((category) => ({
                ...category,
                checked: false,
              })),
              checked: false,
            };
          })
        );
      } else {
        return [];
      }
    });
  }, [classes]);

  const handleClickCheckBox = (e, selectedCheckbox) => {
    setDataFromServer((prev) =>
      prev.map((groupedData) => {
        return groupedData?.map((schoolData) => {
          if (schoolData?.id === selectedCheckbox?.id)
            return {
              ...schoolData,
              checked: e.target.checked,
              categories: schoolData?.categories?.map((categoryData) => ({
                ...categoryData,
                checked: e.target.checked,
              })),
            };
          return schoolData;
        });
      })
    );
  };

  const handleClickChildCheckBox = (e, parentId, categoryCheckbox) => {
    if (e.target.checked) {
      setDataFromServer((prev) =>
        prev.map((groupedData) => {
          return groupedData?.map((schoolData) => {
            if (schoolData?.id === parentId) {
              return {
                ...schoolData,
                checked:
                  schoolData?.categories.filter(
                    (category) => !category?.checked
                  ).length === 1
                    ? true
                    : false,
                categories: schoolData?.categories?.map((categoryData) => {
                  if (categoryCheckbox?.id === categoryData?.id) {
                    return {
                      ...categoryData,
                      checked: e.target.checked,
                    };
                  }
                  return categoryData;
                }),
              };
            }
            return { ...schoolData };
          });
        })
      );
      return;
    }
    setDataFromServer((prev) =>
      prev.map((groupedData) => {
        return groupedData?.map((schoolData) => {
          if (schoolData?.id === parentId) {
            return {
              ...schoolData,
              checked: false,
              categories: schoolData?.categories?.map((categoryData) => {
                if (categoryCheckbox?.id === categoryData?.id) {
                  return {
                    ...categoryData,
                    checked: false,
                  };
                }
                return categoryData;
              }),
            };
          }
          return schoolData;
        });
      })
    );
  };

  useEffect(() => {
    setSelectedClasses(
      dataFromServer
        .flat()
        .map((mergedData) => {
          if (mergedData?.categories?.every((category) => !category?.checked))
            return;
          return {
            ...mergedData,
            categories: mergedData?.categories?.filter(
              (category) => category?.checked
            ),
          };
        })
        .filter((element) => element !== undefined)
    );
  }, [dataFromServer]);

  useEffect(() => {
    const names = selectedClasses?.map((eachClass) => {
      return eachClass.categories.map((cat) => {
        return `${eachClass.name}${cat.name}`;
      });
    });

    setSelectedClassesNames(names);

    const ids = selectedClasses?.map((eachClass) => {
      return eachClass.categories.map((cat) => cat.id);
    });

    // join all the id that are in the arrays gotten from the mapping above
    setSelectedClassesIds(ids?.join().split(","));
  }, [selectedClasses]);

  useEffect(() => {
    setNewFeesData(
      feesData?.map((fee) => {
        return { ...fee, tableOpen: false };
      })
    );
  }, [feesData]);

  useEffect(() => {
    if (feesData) {
      refetchFees();
    }
    if (allFeeComponents) {
      refetchFeeComponents();

      const selectedIds = selectedItems?.map((item) => item?.feeComponentId);

      setNewAllFeeComponents(
        allFeeComponents?.map((feeComponent) => {
          return {
            ...feeComponent,
            selected: false,
            compulsory: null,
            amount: null,
            feeComponentId: feeComponent.id,
          };
        })
      );
    }
  }, [newData, deletedElementID, editedElement, allFeeComponents, feesData]);

  useEffect(() => {
    if (editingElement) {
      const selectedIds = selectedItems?.map((item) => item?.feeComponentId);

      setNewAllFeeComponents(
        allFeeComponents?.map((feeComponent) => {
          return {
            ...feeComponent,
            // selected: false,
            selected: selectedIds.includes(feeComponent?.id),
            compulsory: null,
            amount: null,
            feeComponentId: feeComponent.id,
          };
        })
      );
    }
  }, [editingElement]);

  useEffect(() => {
    setFilteredComponents(newAllFeeComponents || []);
  }, [newAllFeeComponents]);

  useEffect(() => {
    const filtered = newAllFeeComponents?.filter((feeComponent) =>
      feeComponent.name.toLowerCase().includes(searchText.toLowerCase())
    );

    // set the selected to true if i find any of these items already in the selected components

    const newComps = filtered?.map((comp) => ({
      ...comp,
      selected: selectedItems?.some(
        (selectedComp) => selectedComp.id === comp.id
      ),
    }));

    // console.log(newComps);

    setFilteredComponents(newComps);
  }, [searchText]);

  console.log(filteredComponents);
  // console.log(newAllFeeComponents);
  // console.log(selectedItems);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowClassesDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleItemClick = (obj) => {
    if (
      selectedItems.find((item) => item.feeComponentId === obj.feeComponentId)
    ) {
      setSelectedItems(
        selectedItems.filter(
          (item) => item.feeComponentId !== obj.feeComponentId
        )
      );
      setFilteredComponents(
        filteredComponents?.map((component) => {
          if (component.feeComponentId === obj.feeComponentId) {
            return { ...component, selected: false };
          }
          return component;
        })
      );
    } else {
      setSelectedItems([...selectedItems, { ...obj, selected: true }]);
      setFilteredComponents(
        filteredComponents?.map((component) => {
          if (component.feeComponentId === obj.feeComponentId) {
            return { ...component, selected: true };
          }
          return component;
        })
      );
    }
  };

  if (
    feesLoading ||
    createFeeLoading ||
    updateLoading ||
    deleteLoading ||
    feeComponentsLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      {/* delete confirmation modal */}
      <ModalComponent
        showModal={showConfirmDelete}
        closeModal={() => setShowConfirmDelete(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img src={alertIcon} alt="alert Icon" style={{ margin: "0 auto" }} />
          <p
            style={{
              marginTop: "10px",
              marginBottom: "5px",
              fontWeight: "500",
              fontSize: 1.0 + "rem",
            }}
          >
            Are you sure you want to delete this fee?{" "}
          </p>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              onClick={() => setShowConfirmDelete(false)}
              type="button"
              variant="outline-primary"
              style={{ borderColor: "#F14810", color: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                No
              </span>
            </Button>
            <Button
              onClick={() => handleDeleteFee()}
              type="button"
              variant="primary"
              style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Yes
              </span>
            </Button>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        title={editingElement ? "Edit Fee" : "Create Fee"}
        showModal={showModal}
        closeModal={handleCloseModal}
        size="lg"
      >
        {isFormNotFilled && (
          <span
            style={{
              color: "red",
              fontStyle: "italic",
              transition: "ease-in-out",
            }}
          >
            Please fill all fields
          </span>
        )}

        <form>
          <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo.
          </p>

          <h4 style={{ marginBottom: "10px", fontSize: "14px" }}>Fee Title</h4>
          <input
            type="text"
            placeholder="Enter Fee Title"
            onChange={(e) => handleOnChange(e)}
            name={editingElement ? "editedTitle" : "title"}
            value={editingElement ? editedTitle : title}
            style={{
              borderRadius: "5px",
              backgroundColor: "#dddddd",
              width: "100%",
            }}
          />

          <h4 style={{ margin: "15px 0 10px", fontSize: "14px" }}>
            Fee Component
          </h4>

          {selectedItems?.map(
            (
              {
                feeComponentName,
                name,
                id,
                amount,
                compulsory,
                feeComponentId,
              },
              i
            ) => (
              <div key={id}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5 style={{ margin: "10px 0", fontSize: "12px" }}>
                    {feeComponentName || name}
                  </h5>
                  <span
                    style={{
                      color: "#F14810",
                      marginTop: "10px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontStyle: "italic",
                    }}
                    onClick={() => handleDeleteFeeComponent(feeComponentId)}
                  >
                    delete
                  </span>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "15px" }}
                >
                  <input
                    type="number"
                    placeholder="Enter Amount"
                    name="amount"
                    value={amount}
                    onChange={(e) => handleOnChange(e, id)}
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#dddddd",
                      width: "100%",
                      // minWidth: "300px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Form.Check
                      inline
                      label="Compulsory"
                      name={`compulsory-${id}`}
                      value={true}
                      checked={compulsory == "true" || compulsory == true}
                      onChange={(e) => handleOnChange(e, id)}
                      type="radio"
                      id={`inline-radio-1-${id}`}
                      size={25}
                      style={{
                        variant: "outline-warning",
                      }}
                    />
                    <Form.Check
                      inline
                      label="Optional"
                      name={`compulsory-${id}`}
                      value={false}
                      checked={compulsory == "false" || compulsory == false}
                      // checked={selectedItems[i].compulsory == false}
                      onChange={(e) => handleOnChange(e, id)}
                      type="radio"
                      id={`inline-radio-2-${id}`}
                      size={25}
                      style={{ variant: "outline-warning" }}
                    />
                  </div>
                </div>
              </div>
            )
          )}

          <div style={{ marginTop: "10px" }}>
            <span
              style={{
                color: "#F14810",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={openFeeComponentModal}
            >
              + Add fee Component
            </span>
          </div>

          <h4 style={{ margin: "15px 0 10px", fontSize: "14px" }}>Apply to</h4>

          <div ref={dropdownRef}>
            <label
              style={{
                width: "100%",
                backgroundColor: "rgb(221, 221, 221)",
                borderRadius: "5px",
                padding: "7px 10px",
                cursor: "pointer",
                fontSize: "14px",
              }}
              onClick={() => setShowClassesDropdown(!showClassesDropdown)}
            >
              {selectedClassesNames?.length > 0 ? (
                <>
                  {selectedClassesNames.flat().slice(0, 3).join(", ")}
                  {selectedClassesNames.flat().length > 3 && " ..."}
                </>
              ) : (
                "Select Classes to apply to"
              )}
            </label>

            {showClassesDropdown && (
              <div
                style={{
                  transition: "all",
                  transitionTimingFunction: "ease-in-out",
                  transitionDuration: "0.3s",
                  backgroundColor: "white",
                  maxHeight: "150px",
                  overflow: "auto",
                  width: "100%",
                  border: "1px solid rgb(221, 221, 221)",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              >
                {dataFromServer?.length > 0 ? (
                  dataFromServer?.map((data) =>
                    data.map((classDetails) => (
                      <div key={classDetails?.id}>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            padding: "5px",
                            // borderBottom: "1px solid #DBDBDB",
                          }}
                        >
                          <div style={{ position: "relative" }}>
                            <label className="checkbox-container">
                              <input
                                type="checkbox"
                                className="selectAllCheckbox"
                                onChange={(e) =>
                                  handleClickCheckBox(e, classDetails)
                                }
                                checked={classDetails?.checked}
                              />

                              <span className={"custom-checkbox"}></span>
                            </label>
                          </div>
                          <span style={{ width: "100%" }}>
                            {classDetails?.name}
                          </span>
                        </div>

                        {classDetails?.categories?.map((category) => {
                          return (
                            <div
                              style={{ marginLeft: "20px" }}
                              key={category?.id}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  // borderBottom: "1px solid #DBDBDB",
                                  marginLeft: "20px",
                                  fontSize: "14px",
                                }}
                              >
                                <div style={{ position: "relative" }}>
                                  <label className="checkbox-container">
                                    <input
                                      type="checkbox"
                                      // checked={
                                      //   // category?.checked ||
                                      //   // selectedClassesIds.includes(category?.id)
                                      //   selectedClassesIds?.find(
                                      //     (id) => id == category.id
                                      //   )
                                      // }
                                      checked={category?.checked}
                                      onChange={(e) =>
                                        handleClickChildCheckBox(
                                          e,
                                          classDetails?.id,
                                          category
                                        )
                                      }
                                    />
                                    <span className={"custom-checkbox"}></span>
                                  </label>
                                </div>
                                <span style={{ width: "100%" }}>
                                  {category?.name}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ))
                  )
                ) : (
                  <span style={{ fontStyle: "italic" }}>
                    There are no classes yet
                  </span>
                )}
              </div>
            )}
          </div>

          <Button
            onClick={
              editingElement
                ? (e) => handleCreateOrUpdateFee(e, "edit")
                : (e) => handleCreateOrUpdateFee(e)
            }
            variant="primary"
            style={{
              backgroundColor: "#F14810",
              borderColor: "#F14810",
              float: "left",
              marginTop: "20px",
            }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              {editingElement ? "Edit Fee" : "Save Fee"}
            </span>
          </Button>
        </form>
      </ModalComponent>

      {/* fee component modal */}
      <ModalComponent
        title="Select Fee Component"
        showModal={showFeeComponentModal}
        closeModal={closeFeeComponentModal}
      >
        <form>
          <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo.
          </p>

          <input
            type="search"
            name="searchText"
            value={searchText}
            onChange={handleOnChange}
            placeholder="Search for Fee Component"
            style={{
              borderRadius: "5px",
              backgroundColor: "#dddddd",
              width: "100%",
              // marginBottom: "20px",
            }}
          />

          <div
            style={{
              transition: "all",
              transitionTimingFunction: "ease-in-out",
              transitionDuration: "0.3s",
              backgroundColor: "white",
              width: "100%",
              maxHeight: "200px",
              overflow: "auto",
              border: "1px solid rgb(221, 221, 221)",
              borderRadius: "5px",
              padding: "0 10px 10px",
              marginTop: "10px",
            }}
          >
            {filteredComponents?.map((component) => {
              return (
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    padding: "10px",
                    borderBottom: "1px solid #DBDBDB",
                  }}
                  key={component?.id}
                >
                  <div style={{ position: "relative" }}>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checked={component?.selected}
                        onChange={(e) => handleItemClick(component)}
                      />
                      <span className={`custom-checkbox`}></span>
                    </label>
                  </div>
                  <span style={{ width: "100%" }}>{component?.name}</span>
                </div>
              );
            })}
          </div>

          <Button
            onClick={closeFeeComponentModal}
            variant="primary"
            style={{
              backgroundColor: "#F14810",
              borderColor: "#F14810",
              float: "left",
              marginTop: "20px",
            }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              Done
            </span>
          </Button>
        </form>
      </ModalComponent>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          gap: "20px",
          marginBottom: "10px",
        }}
      >
        <h6 style={{ fontSize: "16px", fontWeight: 600, marginBottom: "0px" }}>
          Fee Management
        </h6>
        <Button
          className="button"
          size="md"
          style={{
            backgroundColor: "#F14810",
            color: "#ffffff",
            border: "none",
            outline: "none",
          }}
          onClick={() => setShowModal(true)}
          title="Click this button to create a fee structure"
        >
          <span
            style={{
              fontSize: 0.8 + "rem",
              marginLeft: 0.3 + "rem",
              marginRight: 0.3 + "rem",
            }}
          >
            Create Fee
          </span>
        </Button>
      </div>

      <div
        style={{
          border: "1px solid #E7E1E1",
          borderRadius: "8px",
          padding: "15px",
          minHeight: "95%",
          // overflow: "hidden"
        }}
      >
        {newFeesData?.length > 0 ? (
          newFeesData?.map(
            ({ id, title, fees, tableOpen, feeStructureClasses }) => (
              <div
                style={{
                  border: "1px solid #E7E1E1",
                  borderRadius: "8px",
                  padding: "8px 15px",
                  marginBottom: "20px",
                }}
                key={id}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "space-between",
                    alignItems: "end",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#585858",
                      fontStyle: "italic",
                      marginBottom: "0px",
                    }}
                  >
                    Fee Title
                  </p>
                  <div style={{ display: "flex", gap: "3px" }}>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "35px",
                        width: "35px",
                        borderRadius: "100%",
                        backgroundColor: "#E7E7E7",
                        cursor: "pointer",
                      }}
                      onClick={() => handleEditFee(id)}
                    >
                      <EditIcon />
                    </span>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "35px",
                        width: "35px",
                        borderRadius: "100%",
                        backgroundColor: "#E7E7E7",
                        cursor: "pointer",
                      }}
                      onClick={() => confirmDelete(id)}
                    >
                      <DeleteIcon />
                    </span>
                  </div>
                </div>

                <h4
                  style={{
                    marginBottom: "20px",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {title}
                </h4>

                <p
                  style={{
                    fontSize: "14px",
                    color: "#585858",
                    fontStyle: "italic",
                    marginBottom: "5px",
                  }}
                >
                  Applies To
                </p>

                <section className="classesFeesAppliesTo">
                  {Object.keys(feeStructureClasses)?.map((key) => (
                    <div
                      style={{
                        backgroundColor: "#FFEDE8",
                        border: "1px solid #FD845B",
                        padding: "5px 15px",
                        borderRadius: "10px",
                      }}
                    >
                      <span style={{ fontWeight: "500", marginBottom: "10px" }}>
                        {key}:{" "}
                      </span>
                      <div>
                        {feeStructureClasses[key].map((category, i) => (
                          <span
                            key={category?.id}
                            style={{ color: "#525252", fontStyle: "italic" }}
                          >
                            {category?.institutionCategoryName}
                            {i < feeStructureClasses[key].length - 1 && ", "}
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </section>

                <span
                  style={{
                    color: "#F14810",
                    fontSize: "14px",
                    fontWeight: "500",
                    cursor: "pointer",
                    transition: "all ease-in-out",
                  }}
                  onClick={() => handleOpenComponentTable(id)}
                >
                  {tableOpen ? "Hide" : "View"} Components
                </span>

                {tableOpen && (
                  <Table
                    // responsive
                    striped
                    size="sm"
                    style={{
                      fontFamily: "jost, sans-serif",
                      fontSize: "14px",
                      marginTop: "20px",
                    }}
                  >
                    <thead>
                      <tr align="center">
                        <th>Fee Component</th>
                        <th>Amount</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fees &&
                        fees?.length > 0 &&
                        fees.map(
                          ({ amount, feeComponentName, compulsory }, i) => (
                            <tr
                              key={i}
                              className={i % 2 === 0 ? "even-row" : "odd-row"}
                            >
                              <td align="center">{feeComponentName}</td>
                              <td align="center">
                                ₦ {addTrailingCommas(amount)}
                              </td>
                              <td align="center">
                                {compulsory ? "Compulsory" : "Optional"}
                              </td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </Table>
                )}
              </div>
            )
          )
        ) : (
          <span style={{ fontStyle: "italic", marginBottom: "20px" }}>
            Nothing to show, create a fee
          </span>
        )}
      </div>
    </>
  );
};

export default InstitutionFee;
